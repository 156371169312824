import { keywordColor } from "@common/types/keyword-color.type";

/**
 * Assumes h is in the set [0, 360], s and l are in the set [0, 100].
 */
export type hslColor = { h: number; s: number; l: number }

const trimLeft = /^\s+/;
const trimRight = /\s+$/;
const tinyCounter = 0;
const mathRound = Math.round;
const mathMin = Math.min;
const mathMax = Math.max;
const mathRandom = Math.random;

export const NUMERIC_REGEX = /[^0-9]/g;
export const MAX_RGB = 255;
export const MIN_RGB = 0;

/**
 * Converts an RGBA color plus alpha transparency to hex.
 * @param r contained in the set [0, 255].
 * @param g contained in the set [0, 255].
 * @param b contained in the set [0, 255].
 * @param a alpha layer contained in [0, 1].
 * @param allow4Char allows to return 4 char hex.
 * @return returns a 4 or 8 character rgba hex.
 */
export function rgbaToHex(r: number, g: number, b: number, a: number, allow4Char?: boolean): string {
  const hex = [
    pad2(mathRound(r).toString(16)),
    pad2(mathRound(g).toString(16)),
    pad2(mathRound(b).toString(16)),
    pad2(convertDecimalToHex(a.toString()))
  ];

  // Return a 4 character hex if possible
  if (allow4Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1) && hex[3].charAt(0) == hex[3].charAt(1)) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0) + hex[3].charAt(0);
  }

  return hex.join("");
}

/**
 * Force a hex value to have 2 characters.
 * @param c hex value.
 * @return hex value string with 2 characters.
 */
export function pad2(c: string): string {
  return c.length == 1 ? '0' + c : '' + c;
}

/**
 * Converts a decimal to a hex value.
 * @param decimal decimal value.
 * @return hex value.
 */
export function convertDecimalToHex(decimal: string): string {
  return Math.round(parseFloat(decimal) * 255).toString(16);
}

/**
 * Converts a hex value to a decimal.
 * @param hexValue hex value.
 * @return decimal value.
 */
function convertHexToDecimal(hexValue: string): number {
  return (parseIntFromHex(hexValue) / 255);
}

/**
 * Parse a base-16 hex value into a base-10 integer
 * @param hexValue hex value
 * @return int value
 */
function parseIntFromHex(hexValue: string) {
  return parseInt(hexValue, 16);
}

/**
 * Converts an RGB color to hex
 * @param r contained in the set [0, 255]
 * @param g contained in the set [0, 255]
 * @param b contained in the set [0, 255]
 * @param allow3Char allows to return 3 char hex
 * @return returns a 3 or 6 character rgba hex
 */
export function rgbToHex(r: number, g: number, b: number, allow3Char?: boolean): string {
  const hex = [
    pad2(mathRound(r).toString(16)),
    pad2(mathRound(g).toString(16)),
    pad2(mathRound(b).toString(16))
  ];

  // Return a 3 character hex if possible
  if (allow3Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1)) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
  }

  return hex.join("");
}

// Actual matching.
// Parentheses and commas are optional, but not required.
// Whitespace can take the place of commas or opening parent
const CSS_INTEGER = "[-\\+]?\\d+%?";
const CSS_NUMBER = "[-\\+]?\\d*\\.\\d+%?";
const CSS_UNIT = "(?:" + CSS_NUMBER + ")|(?:" + CSS_INTEGER + ")";
const PERMISSIVE_MATCH3 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";
const PERMISSIVE_MATCH4 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";

export const matchers = {
  CSS_UNIT: new RegExp(CSS_UNIT),
  rgb: new RegExp("rgb" + PERMISSIVE_MATCH3),
  rgba: new RegExp("rgba" + PERMISSIVE_MATCH4),
  hsl: new RegExp("hsl" + PERMISSIVE_MATCH3),
  hsla: new RegExp("hsla" + PERMISSIVE_MATCH4),
  hsv: new RegExp("hsv" + PERMISSIVE_MATCH3),
  hsva: new RegExp("hsva" + PERMISSIVE_MATCH4),
  hex3: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
  hex6: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
  hex4: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
  hex8: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/
};

/**
 * Parsing string of hex color, keyword, rgb or rgba to rgba object.
 * @param color can be hex color, keyword, rgb, rgba.
 * @return rgba object.
 */
export function stringInputToObject(color: string): { r: number, g: number, b: number, a: number } {
  color = color.replace(trimLeft, '').replace(trimRight, '').toLowerCase();

  // Try to match string input using regular expressions.
  // Keep most of the number bounding out of this function - don't worry about [0,1] or [0,100] or [0,360]
  // Just return an object and let the conversion functions handle that.
  // This way the result will be the same whether the tinycolor is initialized with string or object.
  let match;
  if ((match = matchers.rgb.exec(color))) {
    return { r: match[1], g: match[2], b: match[3], a: 1 };
  }
  if ((match = matchers.rgba.exec(color))) {
    return { r: match[1], g: match[2], b: match[3], a: match[4] };
  }

  const lowerCaseColor = color.toLowerCase();
  if ([...lowerCaseColor].every(char => (char >= 'a' && char <= 'z'))) {
    const keyword = Object.keys(keywordColor).find(key => key.toLowerCase() === lowerCaseColor);
    if (keyword) {
      const rgb = keywordColor[keyword];
      return { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 };
    }
  }

  if ((match = matchers.hex8.exec(color))) {
    return {
      r: parseIntFromHex(match[1]),
      g: parseIntFromHex(match[2]),
      b: parseIntFromHex(match[3]),
      a: convertHexToDecimal(match[4]),
    };
  }
  if ((match = matchers.hex6.exec(color))) {
    return {
      r: parseIntFromHex(match[1]),
      g: parseIntFromHex(match[2]),
      b: parseIntFromHex(match[3]),
      a: 1
    };
  }
  if ((match = matchers.hex4.exec(color))) {
    return {
      r: parseIntFromHex(match[1] + '' + match[1]),
      g: parseIntFromHex(match[2] + '' + match[2]),
      b: parseIntFromHex(match[3] + '' + match[3]),
      a: convertHexToDecimal(match[4] + '' + match[4]),
    };
  }
  if ((match = matchers.hex3.exec(color))) {
    return {
      r: parseIntFromHex(match[1] + '' + match[1]),
      g: parseIntFromHex(match[2] + '' + match[2]),
      b: parseIntFromHex(match[3] + '' + match[3]),
      a: 1
    };
  }

  return null;
}

/**
 * Converts an rgb(a)/hex(a) color string to HSL.
 * @param color can be hex color, keyword, rgb, rgba.
 */
export function colorToHsl(color: string): hslColor {
  const colorObj = stringInputToObject(color);
  if (!colorObj) {
    throw new Error("Could not parse color");
  }
  const r = colorObj.r / 255;
  const g = colorObj.g / 255;
  const b = colorObj.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
}

/**
 * Converts an HSL color to hex.
 * @return Returns a 6 character hex.
 */
export function hslToHex(hsl: hslColor): string {
  const { h, s, l } = hsl;

  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

/**
 * Set lightness to color
 * @param hexColor color to which set lightness
 * @param l lightness in hsl format
 * @return hex format color string
 */
export function setColorLightness(hexColor: string, l: number): string {
  const hsl = colorToHsl(hexColor);
  hsl.l = l;
  return hslToHex(hsl);
}
