import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyGridModule } from '@portal-core/ui/grid/directives/empty-grid/empty-grid.module';
import { GridComponentModule } from '@portal-core/ui/grid/components/grid/grid.module';
import { GridCellModule } from '@portal-core/ui/grid/directives/grid-cell/grid-cell.module';
import { GridColumnsDialogModule } from '@portal-core/ui/grid/components/grid-columns-dialog/grid-columns-dialog.module';
import { GridHeaderMenuModule } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.module';
import { ContextMenuItemsModule } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.module';
import { ExpandedRowDetailModule } from '@portal-core/ui/grid/directives/expanded-row-detail/expanded-row-detail.module';
import { HelpGridModule } from '@portal-core/ui/grid/directives/help-grid/help-grid.module';
import { FilterBarModule } from '@portal-core/ui/page-filters/components/filter-bar/filter-bar.module';
import { DataGridComponent } from './data-grid.component';

@NgModule({
  declarations: [
    DataGridComponent
  ],
  imports: [
    CommonModule,
    ContextMenuItemsModule,
    EmptyGridModule,
    ExpandedRowDetailModule,
    FilterBarModule,
    GridCellModule,
    GridColumnsDialogModule,
    GridComponentModule,
    GridHeaderMenuModule,
    HelpGridModule
  ],
  exports: [
    DataGridComponent
  ]
})
export class DataGridModule { }
