import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { ProjectFilePickerInputModule } from '@portal-core/project-files/components/project-file-picker-input/project-file-picker-input.module';
import { ProjectFilesTreeModule } from '@portal-core/project-files/components/project-files-tree/project-files-tree.module';
import { ReviewFileListModule } from '@portal-core/reviews/review-files/components/review-file-list/review-file-list.module';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { ReviewPackageFilesFormComponent } from './review-package-files-form.component';

@NgModule({
  declarations: [ReviewPackageFilesFormComponent],
  exports: [ReviewPackageFilesFormComponent],
  imports: [
    CommonModule,
    DropdownArrowModule,
    ErrorsExpansionPanelModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSnackBarModule,
    PopupModule,
    ProjectFilesTreeModule,
    ProjectFilePickerInputModule,
    ReviewFileListModule
  ]
})
export class ReviewPackageFilesFormModule { }
