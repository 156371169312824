import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormControlErrorMessageComponent } from './form-control-error-message.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule
  ],
  declarations: [
    FormControlErrorMessageComponent
  ],
  exports: [
    FormControlErrorMessageComponent
  ]
})
export class FormControlErrorMessageModule { }
