import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { PermissionsCategory } from '@portal-core/permissions/models/permissions-category.model';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getPermissionCategories$(): Observable<PermissionsCategory[]> {
    return this.http.get<Page>(`${this.apiService.centralApiBaseUri}/api/permissioncategories`).pipe(map(gridItems => gridItems.Items));
  }

  @SharePendingRequest()
  getComputedPermissionsOnLicenseLevel$(userId: string, licenseId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${userId}/${licenseId}/OnLicenseLevel`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  @SharePendingRequest()
  getComputedPermissionsOnProjectLevel$(userId: string, projectId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${userId}/${projectId}/OnProjectLevel`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  @SharePendingRequest()
  getUserPermissionsOnLicenseLevel$(userId: string, licenseId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/user/${userId}/license/${licenseId}`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  @SharePendingRequest()
  getUserPermissionsOnProjectLevel$(userId: string, projectId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/user/${userId}/project/${projectId}`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  @SharePendingRequest()
  getTeamPermissionsOnLicenseLevel$(teamId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/team/${teamId}`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  @SharePendingRequest()
  getTeamPermissionsOnProjectLevel$(teamId: number, projectId: number): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/team/${teamId}/project/${projectId}`).pipe(
      map(permissions => Array.isArray(permissions) ? permissions : [])
    );
  }

  saveUserPermissionsOnLicenseLevel$(userId: string, licenseId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.http.post<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${userId}/${licenseId}/saveOnLicenseLevel`, permissions);
  }

  saveUserPermissionsOnProjectLevel$(userId: string, projectId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.http.post<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${userId}/${projectId}/saveOnProjectLevel`, permissions);
  }

  saveTeamPermissionsOnLicenseLevel$(teamId: number, licenseId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.http.post<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${teamId}/${licenseId}/saveTeamOnLicenseLevel`, permissions);
  }

  saveTeamPermissionsOnProjectLevel$(teamId: number, projectId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.http.post<Permissions[]>(`${this.apiService.centralApiBaseUri}/api/permissions/${teamId}/${projectId}/saveTeamOnProjectLevel`, permissions);
  }
}
