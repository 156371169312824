import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Params } from '@angular/router';
import { RoutingHandler } from '@portal-core/routing/interfaces/routing-handler';

/** Injection token that can be used to specify RoutingService options. */
export const MC_ROUTING_OPTIONS = new InjectionToken<RoutingHandler>('RoutingService-options');

/**
 * RoutingService
 * Used by portal-core components to get a route for the current application.
 */
@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(@Optional() @Inject(MC_ROUTING_OPTIONS) private routingHandler: RoutingHandler) { }

  /** Returns the url path for a branch. Returns undefined if the path is not defined by the application. Returns null if the application has no branch url. */
  getBranchPath(licenseId: number, projectId: number, branchName: string): any[] | null | undefined {
    return this.getRoutePath('getBranchPath', licenseId, projectId, branchName);
  }

  /** Returns the url path for a project page. Returns undefined if the path is not defined by the application. Returns null if the application has no project url. */
  getProjectPath(licenseId: number, projectId: number, subPage: string): any[] | null | undefined {
    return this.getRoutePath('getProjectPath', licenseId, projectId, subPage);
  }

  /** Returns the url path for a sites page. Returns undefined if the path is not defined by the application. Return null if the application has no content security policy url. */
  getContentSecurityPolicyPath(contentSecurityPolicyId: number): any[] | null | undefined {
    return this.getRoutePath('getContentSecurityPolicyPath', contentSecurityPolicyId);
  }

  /** Returns the url path for a review file. Returns undefined if the path is not defined by the application. Returns null if the application has no review file url. */
  getReviewFilePath(licenseId: number, reviewPackageId: number, reviewFileId: number): any[] | null | undefined {
    return this.getRoutePath('getReviewFilePath', licenseId, reviewFileId);
  }

  /** Returns the url path for a review package. Returns undefined if the path is not defined by the application. Returns null if the application has no review package url. */
  getReviewPackagePath(licenseId: number, reviewPackageId: number): any[] | null | undefined {
    return this.getRoutePath('getReviewPackagePath', licenseId, reviewPackageId);
  }

  /** Returns the url path for a branch. Returns undefined if the path is not defined by the application. Returns null if the application has no branch url. */
  getTocPath(licenseId: number, projectId: number, branchName: string, tocPath: string): any[] | null | undefined {
    return this.getRoutePath('getTocPath', licenseId, projectId, branchName, tocPath);
  }

  /** Calls a method on the RoutingHandler with the given name and returns its results as route commands. */
  private getRoutePath(name: keyof RoutingHandler, ...args: any[]): any[] | null | undefined {
    const path = this.getHandler(name)?.(...args);
    if (path === null) {
      return null;
    } else if (path) {
      return [path];
    } else {
      return undefined;
    }
  }

  /** Calls a method on the RoutingHandler with the given name and returns its results as query parameters. */
  private getRouteQueryParams(name: keyof RoutingHandler, ...args: any[]): Params | null | undefined {
    return this.getHandler(name)?.(...args);
  }

  /** Returns a method from RoutingHandler with the given name with this bound to the RoutingHandler. */
  private getHandler(name: keyof RoutingHandler): Function {
    return this.routingHandler?.[name]?.bind(this.routingHandler);
  }
}
