export enum LicenseBulkImportStep {
  Unknown = 0,
  CheckingSeatAvailability = 1,
  AddingCentralUsers = 2,
  AddingSourceControlUsers = 3,
  AddingLicenseUsers = 4,
  AddingTeamLicenseUsers = 5,
  SettingUserPermissions = 6,
  SendingEmails = 7,
  ImportComplete = 8
}
