import { Directive } from '@angular/core';

/**
 * Main content of a switch board, needed as it's used as a selector in mc-switch-board.
 */
@Directive({
  selector: 'mc-switch-board-content, [mcSwitchBoardContent]',
  // tslint:disable-next-line: use-host-property-decorator
  host: { class: 'mc-switch-board-content mc-spring-board-body' }
})
export class SwitchBoardContentDirective { }
