import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';

@CollectionStateSelectors({
  stateType: ProcessNotificationState
})
@State<ProcessNotificationState>({
  name: ProcessNotificationState.source,
})
@Injectable()
export class ProcessNotificationState extends CollectionStateBase {
  static source = 'ProcessNotification';
  getSource(): string {
    return ProcessNotificationState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProcessNotificationDataService extends CollectionDataServiceBase<ProcessNotification> {
  constructor(protected store: Store) {
    super(store, ProcessNotificationState);
  }
}
