import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';

@Injectable({
  providedIn: 'root'
})
export class TaskBoardsFilterService {
  refreshed$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentTaskBoard$: BehaviorSubject<TaskBoard> = new BehaviorSubject<TaskBoard>(<TaskBoard>{});

  refreshPage() {
    this.refreshed$.next({});
  }

  updateTaskBoard(taskBoard: TaskBoard) {
    this.currentTaskBoard$.next(taskBoard);
  }
}
