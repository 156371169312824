import { AfterViewInit, Directive, EventEmitter, OnInit, Output } from '@angular/core';

/*
 * Other lifecycle events should be added to this directive as they are needed.
 */
@Directive({
  selector: '[mcLifeCycle]'
})
export class LifeCycleDirective implements OnInit, AfterViewInit {
  @Output() afterViewInit: EventEmitter<void> = new EventEmitter<void>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onInit: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
    this.onInit.emit();
  }

  ngAfterViewInit() {
    this.afterViewInit.emit();
  }
}
