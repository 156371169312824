import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class ReviewFileUsersPageFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'UserId',
        title: 'Reviewer',
        type: PageDataType.String
      },
      {
        name: 'ReviewFileId',
        title: 'Review File',
        type: PageDataType.Int
      },
      {
        name: 'Status',
        title: 'Status',
        type: PageDataType.Select,
        filterEnabled: true,
        sortEnabled: true,
        selectOptions: [
          { id: ReviewFileUserStatus.Todo.toString(), text: 'To Do' },
          { id: ReviewFileUserStatus.InProgress.toString(), text: 'In Progress' },
          { id: ReviewFileUserStatus.Submitted.toString(), text: 'Submitted' }
        ]
      }
    ]);
  }
}
