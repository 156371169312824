<div class="mc-avatar-tooltip-container" [matTooltip]="license?.KeyLabel" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !license">
  <button *ngIf="showAvatar && !isImageDisabled()" type="button" class="mc-avatar-container" (click)="onAvatarClicked()">
    <img *ngIf="license?.Avatar" class="mc-avatar-image" alt="License Avatar" [src]="license?.Avatar.StorageId | mcMadCapImageUrl : license?.Avatar.Extension">
    <span *ngIf="!license?.Avatar" class="mc-avatar-initials">{{ license?.KeyLabel?.substring(0,1) | uppercase }}</span>
  </button>
  <div *ngIf="showAvatar && isImageDisabled()" class="mc-avatar-container">
    <img *ngIf="license?.Avatar" class="mc-avatar-image" alt="License Avatar" [src]="license?.Avatar.StorageId | mcMadCapImageUrl : license?.Avatar.Extension">
    <span *ngIf="!license?.Avatar" class="mc-avatar-initials">{{ license?.KeyLabel?.substring(0,1) | uppercase }}</span>
  </div>

  <a *ngIf="showName && nameLinksToPage" [href]="href" class="mc-avatar-name">{{ license?.KeyLabel }}<span *ngIf="showId" class="mc-text-muted"> ({{ license?.Id }})</span></a>
  <mc-inline-button *ngIf="showName && !nameLinksToPage" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">{{ license?.KeyLabel }}<span *ngIf="showId" class="mc-text-muted"> ({{ license?.Id }})</span></mc-inline-button>
</div>