import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';

@Pipe({
  name: 'mcReviewFileUserStatusColor'
})
export class ReviewFileUserStatusColorPipe implements PipeTransform {
  transform(status: ReviewFileUserStatus | null | undefined, luminosity: '' | 'muted' | 'vivid' = ''): string {
    switch (status) {
      case ReviewFileUserStatus.Todo:
        return `caution${luminosity ? '-' + luminosity : ''}`;
      case ReviewFileUserStatus.Submitted:
        return `info${luminosity ? '-' + luminosity : ''}`;
      case ReviewFileUserStatus.InProgress:
        return `info-2${luminosity ? '-' + luminosity : ''}`;
    }
  }
}
