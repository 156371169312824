import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { TaskBoardAvatarModule } from '@portal-core/task-boards/components/task-board-avatar/task-board-avatar.module';
import { TaskBoardDeleteFormModule } from '@portal-core/task-boards/components/task-board-delete-form/task-board-delete-form.module';
import { TaskBoardSettingsFormModule } from '@portal-core/task-boards/components/task-board-settings-form/task-board-settings-form.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { TaskBoardProfileDialogComponent } from './task-board-profile-dialog.component';

@NgModule({
  declarations: [
    TaskBoardProfileDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    MatListModule,
    SwitchBoardModule,
    LoaderModule,
    ErrorsExpansionPanelModule,
    PreventDefaultModule,
    RouterModule,
    TaskBoardAvatarModule,
    TaskBoardDeleteFormModule,
    TaskBoardSettingsFormModule
  ],
  exports: [
    TaskBoardProfileDialogComponent
  ]
})
export class TaskBoardProfileDialogModule { }
