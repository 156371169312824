import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';

@CollectionStateSelectors({
  stateType: TranslationPackagesState
})
@State<TranslationPackagesState>({
  name: TranslationPackagesState.source
})
@Injectable()
export class TranslationPackagesState extends CollectionStateBase {
  static source: string = 'TranslationPackages';
  getSource(): string {
    return TranslationPackagesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TranslationPackagesDataService extends CollectionDataServiceBase<TranslationPackage> {
  constructor(protected store: Store) {
    super(store, TranslationPackagesState);
  }
}
