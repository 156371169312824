export enum ProjectFileTemplate {
  Empty,
  NewTopic,
  TopicForEndnotes,
  TopicForGlossary,
  TopicForIndex,
  TopicForListOfConcepts,
  TopicForListOfElements,
  TopicForListOfImages,
  TopicForListOfTables,
  TopicForMiniTOC,
  TopicForTOC,
  Snippet,
  ConditionTagSet,
  VariableSet,
  BrandingStylesheet,
  Target,
}
