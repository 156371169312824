import { cloneDeep, isDate } from 'lodash';
import { toBooleanString } from '@common/util/bool';
import { isEnumValue } from '@common/util/enum';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilterOptions } from '@common/paged-data/types/page-filter-options.type';
import { getFilterCount, isPageFilterOptions } from '@common/paged-data/util/filter-group';

/**
 * FilterBuilder
 * A helper class for building page filters.
 * Create a filter group by chaining together calls to add or remove filters.
 * Filters are added as filter groups to a root filter group.
 * The root filter group is returned by the value property.
 */
export class FilterBuilder {
  /** The page filter group that is being built. */
  private pageFilterGroup: PageFilterGroup;

  /** Returns the total number of filters (PageFilterOptions) built up. */
  get filterCount(): number {
    return getFilterCount(this.pageFilterGroup);
  }

  /** Returns the total number of page filter groups built up. */
  get filterGroupCount(): number {
    return this.pageFilterGroup?.FilterGroups?.length ?? 0;
  }

  /** Returns the page filter group that has been built. */
  get value(): PageFilterGroup {
    // If the filter is empty then return undefined
    return this.filterCount > 0 ? this.pageFilterGroup : undefined;
  }

  /**
   * Creates a FilterBuilder initialized with the given page filter group or an empty filter group if none is provided.
   * @param pageFilterGroup Optional page filter group to initialize the builder with.
   */
  constructor(pageFilterGroup: PageFilterGroup) {
    pageFilterGroup = cloneDeep(pageFilterGroup);

    if (!isEnumValue(PageFilterGroupType, pageFilterGroup.Type)) {
      pageFilterGroup.Type = PageFilterGroupType.Custom;
    }

    if (!isEnumValue(PageFilterOperator, pageFilterGroup.Operator)) {
      pageFilterGroup.Operator = PageFilterOperator.And;
    }

    if (!Array.isArray(pageFilterGroup.Filters)) {
      pageFilterGroup.Filters = [];
    }

    if (!Array.isArray(pageFilterGroup.FilterGroups)) {
      pageFilterGroup.FilterGroups = [];
    }

    this.pageFilterGroup = pageFilterGroup as PageFilterGroup;
  }

  /**
   * Adds a Boolean page filter group. Defaults to using the Equals PageFilterType.
   * @param columnName The name of the column to create the filter for.
   * @param value The value of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  bool(columnName: string, value: boolean, options?: Partial<PageFilterOptions>): FilterBuilder {
    const propertyName = options?.PropertyName ?? columnName;
    const dataType = options?.PropertyType ?? PageDataType.Boolean;
    const filterType = options?.FilterType ?? PageFilterType.Equals;
    let newFilter: PageFilterGroup;

    // Only create a filter for this column if all the inputs are valid
    if (value === true) {
      newFilter = {
        Id: columnName,
        Type: PageFilterGroupType.Bool,
        Operator: PageFilterOperator.And,
        Filters: [{
          FilterType: filterType,
          PropertyName: propertyName,
          PropertyType: dataType,
          PropertyValue: toBooleanString(value)
        }]
      };
    }

    this.applyFilter(columnName, newFilter);

    return this;
  }

  /**
   * Adds a custom page filter group.
   * If the filter provided is a PageFilterOptions object then it is added in a PageFilterGroup using the And operator.
   * @param filter A filter group or filter options to add.
   * @returns this instance of the FilterBuilder for chaining.
   */
  custom(filter: PageFilterGroup | PageFilterOptions): FilterBuilder {
    if (isPageFilterOptions(filter)) {
      filter = {
        Id: filter.PropertyName,
        Type: PageFilterGroupType.Custom,
        Operator: PageFilterOperator.And,
        Filters: [filter]
      };
    }

    this.applyFilter(filter.Id, filter);
    return this;
  }

  /**
   * Adds a Date page filter group. Defaults to using the Equals PageFilterType unless two values are provided for which the Between filter type is used.
   * @param columnName The name of the column to create the filter for.
   * @param value The value of the filter. If two values are provided then this is the from date value.
   * @param toValue The to date value of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  date(columnName: string, value: Date | ISO8601DateString, toValue?: Date | ISO8601DateString, options?: Partial<PageFilterOptions>): FilterBuilder {
    value = isDate(value) ? value.toISOString() : value;
    toValue = isDate(toValue) ? toValue.toISOString() : toValue;

    const hasValidFromAndToValues = typeof value === 'string' && value && typeof toValue === 'string' && toValue;
    const propertyName = options?.PropertyName ?? columnName;
    const dataType = options?.PropertyType ?? PageDataType.Date;
    // If there are two valid values then default to the Between filter otherwise default to the Equals filter
    const filterType = options?.FilterType ?? (hasValidFromAndToValues ? PageFilterType.Between : PageFilterType.Equals);
    const timezoneOffset = options?.TimeZoneOffset ?? new Date().getTimezoneOffset();
    let newFilter: PageFilterGroup;

    // Only create a filter for this column if all the inputs are valid
    if (filterType === PageFilterType.Between) {
      if (hasValidFromAndToValues) {
        newFilter = {
          Id: columnName,
          Type: PageFilterGroupType.Date,
          Operator: PageFilterOperator.And,
          Filters: [{
            FilterType: PageFilterType.GreaterThan,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: value,
            TimeZoneOffset: timezoneOffset
          }, {
            FilterType: PageFilterType.LessThan,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: toValue,
            TimeZoneOffset: timezoneOffset
          }]
        };
      }
    } else if (filterType === PageFilterType.Today) {
      newFilter = {
        Id: columnName,
        Type: PageFilterGroupType.Date,
        Operator: PageFilterOperator.And,
        Filters: [{
          FilterType: filterType,
          PropertyName: propertyName,
          PropertyType: dataType,
          PropertyValue: new Date().toISOString(),
          TimeZoneOffset: timezoneOffset
        }]
      };
    } else {
      // Only create a filter for this column if all the inputs are valid
      if (isEnumValue(PageFilterType, filterType) && typeof value === 'string' && value) {
        newFilter = {
          Id: columnName,
          Type: PageFilterGroupType.Date,
          Operator: PageFilterOperator.And,
          Filters: [{
            FilterType: filterType,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: value,
            TimeZoneOffset: timezoneOffset
          }]
        };
      }
    }

    this.applyFilter(columnName, newFilter);

    return this;
  }

  /**
   * Adds an Int or Double page filter group. Defaults to using the Equals PageFilterType unless two values are provided for which the Between filter type is used.
   * @param columnName The name of the column to create the filter for.
   * @param value The value of the filter. If two values are provided then this is the from number value.
   * @param toValue The to number value of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  number(columnName: string, numberType: 'double' | 'int', value: number, toValue?: number, options?: Partial<PageFilterOptions>): FilterBuilder {
    const hasValidFromAndToValues = typeof value === 'number' && typeof toValue === 'number';
    const propertyName = options?.PropertyName ?? columnName;
    const dataType = options?.PropertyType ?? (numberType === 'double' ? PageDataType.Double : PageDataType.Int);
    // If there are two valid values then default to the Between filter otherwise default to the Equals filter
    const filterType = options?.FilterType ?? (hasValidFromAndToValues ? PageFilterType.Between : PageFilterType.Equals);
    let newFilter: PageFilterGroup;

    // Only create a filter for this column if all the inputs are valid
    if (filterType === PageFilterType.Between) {
      if (hasValidFromAndToValues) {
        newFilter = {
          Id: columnName,
          Type: PageFilterGroupType.Number,
          Operator: PageFilterOperator.And,
          Filters: [{
            FilterType: PageFilterType.GreaterThan,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: value
          }, {
            FilterType: PageFilterType.LessThan,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: toValue
          }]
        };
      }
    } else {
      if (typeof value === 'number') {
        newFilter = {
          Id: columnName,
          Type: PageFilterGroupType.Number,
          Operator: PageFilterOperator.And,
          Filters: [{
            FilterType: filterType,
            PropertyName: propertyName,
            PropertyType: dataType,
            PropertyValue: value
          }]
        };
      }
    }

    this.applyFilter(columnName, newFilter);

    return this;
  }

  /**
   * Adds a Select page filter group. Defaults to using the Equals PageFilterType.
   * @param columnName The name of the column to create the filter for.
   * @param values The values of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  select(columnName: string, values: (string | number)[], options?: Partial<PageFilterOptions>[]): FilterBuilder {
    let newFilter: PageFilterGroup;

    if (Array.isArray(values) && values.length > 0) {
      newFilter = {
        Id: columnName,
        Type: PageFilterGroupType.Select,
        Operator: PageFilterOperator.Or,
        Filters: values.map((value, index) => {
          return {
            FilterType: options?.[index].FilterType ?? PageFilterType.Equals,
            PropertyName: options?.[index].PropertyName ?? columnName,
            PropertyType: options?.[index].PropertyType ?? PageDataType.Select,
            PropertyValue: value
          };
        })
      };
    }

    this.applyFilter(columnName, newFilter);

    return this;
  }

  /**
   * Adds a String page filter group. Defaults to using the Contains PageFilterType.
   * @param columnName The name of the column to create the filter for.
   * @param value The value of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  string(columnName: string, value: string, options?: Partial<PageFilterOptions>): FilterBuilder {
    const propertyName = options?.PropertyName ?? columnName;
    const dataType = options?.PropertyType ?? PageDataType.String;
    const filterType = options?.FilterType ?? PageFilterType.Contains;
    let newFilter: PageFilterGroup;

    if (value) {
      newFilter = {
        Id: columnName,
        Type: PageFilterGroupType.String,
        Operator: PageFilterOperator.And,
        Filters: [{
          FilterType: filterType,
          PropertyName: propertyName,
          PropertyType: dataType,
          PropertyValue: value
        }]
      };
    }

    this.applyFilter(columnName, newFilter);

    return this;
  }

  /**
   * Adds a String page filter group for multiple columns. Defaults to using the Contains PageFilterType.
   * This is useful for adding filters for multiple columns using a search box.
   * @param columnNames The names of the columns to create the filter for.
   * @param value The value of the filter.
   * @param options Optional overrides for the created PageFilterOptions.
   * @returns this instance of the FilterBuilder for chaining.
   */
  search(columnNames: string[], value: string, options: Partial<PageFilterOptions>[]): FilterBuilder {
    const filterGroupId = columnNames.join(':');
    let newFilter: PageFilterGroup;

    if (value) {
      newFilter = {
        Id: filterGroupId,
        Type: PageFilterGroupType.Search,
        Operator: PageFilterOperator.Or,
        Filters: options.map((filterOptions, index) => {
          return {
            FilterType: filterOptions?.FilterType ?? PageFilterType.Contains,
            PropertyName: filterOptions.PropertyName ?? columnNames[index],
            PropertyType: filterOptions.PropertyType ?? PageDataType.String,
            PropertyValue: value
          };
        })
      };
    }

    this.applyFilter(filterGroupId, newFilter);

    return this;
  }

  /**
   * Removes the filters for the given column names.
   * @param columnName A column name or array of column names to remove the filters for.
   * @returns
   */
  remove(columnName: string | string[]): FilterBuilder {
    columnName = Array.isArray(columnName) ? columnName : [columnName];

    columnName.forEach(name => {
      const index = this.findFilterGroupIndex(name);
      if (index !== -1) {
        this.pageFilterGroup.FilterGroups.splice(index, 1);
      }
    });

    return this;
  }

  /**
   * Returns the index into the FilterGroups array of the filter being built.
   * @param columnName The name of the column to find the filter of.
   * @returns The index into the FilterGroups array of the filter being built.
   */
  findFilterGroupIndex(columnName: string): number {
    let existingIndex = -1;

    if (columnName && Array.isArray(this.pageFilterGroup?.FilterGroups)) {
      existingIndex = this.pageFilterGroup.FilterGroups.findIndex(subFilterGroup => subFilterGroup.Id === columnName);
    }

    return existingIndex;
  }

  /**
   * Searches for and returns a filter group from the filter being built.
   * @param columnName The name of the column to find the filter of.
   * @returns The filter group found in the filter being built.
   */
  findFilterGroup(columnName: string): PageFilterGroup {
    const index = this.findFilterGroupIndex(columnName);
    if (index !== -1) {
      return this.pageFilterGroup.FilterGroups[index];
    }
  }

  /**
   * Applies a filter to the filter group being built.
   * If a filter for the same name, data type, and filter type exists then that filter is replaced. If the new filter is nullish then the existing filter is removed.
   * If there is no existing filter then the new filter is added if it is not nullish.
   * @param columnName The name of the column to apply the filter for.
   * @param pageDataType The type of data for the filter.
   * @param pageFilterType The type of filter.
   * @param filterGroup The filter group to apply.
   */
   private applyFilter(columnName: string, filterGroup: PageFilterGroup) {
    const existingIndex = this.findFilterGroupIndex(columnName);

    // If the filter already exists for this column
    if (existingIndex !== -1) {
      // If this column has a filter then replace the existing filter
      if (filterGroup) {
        this.pageFilterGroup.FilterGroups[existingIndex] = filterGroup;
      } else {
        // Else just remove the existing filter
        this.pageFilterGroup.FilterGroups.splice(existingIndex, 1);
      }
    } else {
      // If there is a filter for this column then add it
      if (filterGroup) {
        this.pageFilterGroup.FilterGroups.push(filterGroup);
      }
    }
  }
}
