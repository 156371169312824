import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileSizeService {
  static readonly units: string[] = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  format(bytes: string | number = 0, precision: number = 2, divider: string = ' '): string {
    if (typeof bytes === 'string') {
      bytes = Number(bytes);
    }

    if (!Number.isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return bytes.toFixed(precision) + divider + FileSizeService.units[unit];
  }
}
