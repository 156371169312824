import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { GridRowsRangeComponent } from '@portal-core/ui/grid/components/grid-rows-range/grid-rows-range.component';

@NgModule({
  declarations: [
    GridRowsRangeComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  exports: [
    GridRowsRangeComponent
  ]
})
export class GridRowsRangeModule { }

