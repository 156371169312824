import { DOMParser } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';

const clipboardParserKey = new PluginKey('clipboardParser');

export interface ClipboardParserPluginOptions {
  clipboardParser?: DOMParser;
}

/**
 * A ProseMirror plugin for specifying a clipboard parser for the editor to use.
 */
export function clipboardParserPlugin(options: ClipboardParserPluginOptions): Plugin {
  return new Plugin({
    key: clipboardParserKey,

    props: {
      clipboardParser: options.clipboardParser
    }
  });
}
