import { Injectable } from '@angular/core';
import { Selector, State, StateContext, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { AddItems, RemoveItems, Reset } from '@portal-core/data/collection/types/collection-state.type';
import { TaskAsset } from '@portal-core/tasks/models/task-asset.model';
import { Observable, map } from 'rxjs';

@CollectionStateSelectors({
  stateType: TaskAssetsState
})
@State<TaskAssetsState>({
  name: TaskAssetsState.source,
})
@Injectable()
export class TaskAssetsState extends CollectionStateBase {
  static source = 'TaskAssets';
  ByTaskId: { [taskId: number]: number[] } = {};
  ByCommentId: { [commentId: number]: number[] } = {};

  getSource(): string {
    return TaskAssetsState.source;
  }

  getAddItemsState(ctx: StateContext<CollectionStateBase>, action: AddItems): any {
    let taskId;
    const assets = action.payload.items;
    for (const prop in assets) {
      if (assets[prop]
        && typeof assets[prop] !== 'function') {
        const asset = (<TaskAsset>assets[prop]);
        const commentId = asset.TaskCommentId;
        if (!taskId) {
          taskId = asset.TaskId;
        }

        this.updateAsset(asset, taskId, this.ByTaskId);
        this.updateAsset(asset, commentId, this.ByCommentId);
      }
    }
    return this;
  }

  getRemoveItemsState(ctx: StateContext<CollectionStateBase>, action: RemoveItems): any {
    const assets = action.payload.itemIds;
    for (const prop in assets) {
      if (assets[prop]
        && typeof assets[prop] !== 'function') {
        const assetId = (<number>assets[prop]);

        Object.entries(this.ByTaskId).forEach(([key, value]) => {
          value.some(id => {
            if (id === assetId) {
              value.splice(value.indexOf(assetId), 1);
            }
            return id === assetId;
          });
        });
        Object.entries(this.ByCommentId).forEach(([key, value]) => {
          value.some(id => {
            if (id === assetId) {
              value.splice(value.indexOf(assetId), 1);
            }
            return id === assetId;
          });
        });
      }
    }
    return this;
  }

  getResetState(ctx: StateContext<CollectionStateBase>, action: Reset): any {
    this.ByCommentId = {};
    this.ByTaskId = {};
    return this;
  }

  updateAssets(assets: Dictionary<TaskAsset> | TaskAsset[], taskId: number) {
    if (Object.keys(assets).length > 0) {
      for (const prop in assets) {
        if (assets[prop]
          && typeof assets[prop] !== 'function') {
          const asset = (<TaskAsset>assets[prop]);
          const commentId = asset.TaskCommentId;

          this.updateAsset(asset, taskId, this.ByTaskId);
          this.updateAsset(asset, commentId, this.ByCommentId);
        }
      }
    } else {
      this.updateAsset(undefined, taskId, this.ByTaskId);
    }
  }

  private updateAsset(asset: TaskAsset, id: number, collection: { [id: number]: number[] }) {
    if (!(id in collection)) {
      collection[id] = [];
    }
    if (asset !== undefined
      && collection[id].indexOf(asset.Id) === -1) {
      collection[id].push(asset.Id);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskAssetsDataService extends CollectionDataServiceBase<TaskAsset> {
  constructor(protected store: Store) {
    super(store, TaskAssetsState);
  }

  @Selector([TaskAssetsState])
  static getByTaskIdFn$(state: TaskAssetsState) {
    return (taskId: number) => {
      if (state.ByTaskId === undefined
        || !(taskId in state.ByTaskId)) {
        return undefined;
      }
      const assets = [];
      for (const prop in state.ByTaskId[taskId]) {
        if (state.ByTaskId[taskId][prop]
          && typeof (state.ByTaskId[taskId][prop]) !== 'function') {
          const asset = state.items[(state.ByTaskId[taskId][prop])];
          if (asset !== undefined) {
            assets.push(asset);
          }
        }
      }
      return assets;
    };
  }

  @Selector([TaskAssetsState])
  static getByCommentIdFn$(state: TaskAssetsState) {
    return (commentId: number) => {
      if (state.ByCommentId === undefined
        || !(commentId in state.ByCommentId)) {
        return undefined;
      }
      const assets = [];
      for (const prop in state.ByCommentId[commentId]) {
        if (state.ByCommentId[commentId][prop]
          && typeof (state.ByCommentId[commentId][prop]) !== 'function') {
          const asset = state.items[(state.ByCommentId[commentId][prop])];
          if (asset !== undefined) {
            assets.push(asset);
          }
        }
      }
      return assets;
    };
  }

  addAssets$(items: Dictionary<TaskAsset> | TaskAsset[]): Observable<any> {
    return super.addItems$(items);
  }

  getItemsByTaskId$(taskId: number): Observable<TaskAsset[]> {
    return this.store.select(TaskAssetsDataService.getByTaskIdFn$).pipe(map(getByTaskIdFn => getByTaskIdFn(taskId)));
  }

  getItemsByCommentId$(commentId: number): Observable<TaskAsset[]> {
    return this.store.select(TaskAssetsDataService.getByCommentIdFn$).pipe(map(getByCommentIdFn => getByCommentIdFn(commentId)));
  }
}
