import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ThemeService } from '@portal-core/general/services/theme.service';
import { ProjectInfo } from '@portal-core/project-info/models/project-info.model';
import { DoughnutChartHoleType } from '@portal-core/ui/charts/enums/doughnut-chart-hole-type.enum';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'mc-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectOverviewComponent implements OnChanges {
  @Input() projectInfo: ProjectInfo;

  DoughnutChartHoleType: typeof DoughnutChartHoleType = DoughnutChartHoleType;

  chartOptions: ChartOptions<'doughnut'> = {
    cutout: '92.5%'
  };
  sourceBuildsChartData: ChartData;
  sourceFilesChartData: ChartData;

  constructor(private themeService: ThemeService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectInfo) {
      if (this.projectInfo) {
        const totalStorageSize = this.projectInfo.TotalProjectStorage;
        this.sourceBuildsChartData = this.getStorageItemChartData(totalStorageSize, this.projectInfo.TotalBuildsSize, 'Builds', 'usage-builds');
        this.sourceFilesChartData = this.getStorageItemChartData(totalStorageSize, this.projectInfo.TotalSourceFileSize, 'Project Files', 'usage-source-files');
      } else {
        this.sourceBuildsChartData = null;
        this.sourceFilesChartData = null;
      }
    }
  }

  private getStorageItemChartData(totalStorageSize: number = 0, typeStorageSize: number = 0, label: string, palette: string): ChartData {
    return {
      datasets: [{
        data: [typeStorageSize, totalStorageSize - typeStorageSize],
        backgroundColor: [this.themeService.getColor(palette), this.themeService.getColor(palette, 'lighter')],
        borderWidth: [0, 0]
      }],
      labels: [label]
    };
  }
}
