import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BranchesSelectListModule } from '@portal-core/branches/components/branches-select-list/branches-select-list.module';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { BuildsSelectListModule } from '@portal-core/builds/components/builds-select-list/builds-select-list.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectsSelectListModule } from '@portal-core/projects/components/projects-select-list/projects-select-list.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { TargetsSelectListModule } from '@portal-core/targets/components/targets-select-list/targets-select-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { BuildPickerComponent } from './build-picker.component';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { BuildAsyncModule } from '@portal-core/builds/pipes/build-async/build-async.module';
import { BranchAsyncModule } from '@portal-core/branches/pipes/branch-async/branch-async.module';
import { TargetAsyncModule } from '@portal-core/targets/pipes/target-async/target-async.module';

@NgModule({
  declarations: [BuildPickerComponent],
  imports: [
    AutocompleteInputModule,
    BranchAvatarModule,
    BranchesSelectListModule,
    BuildAvatarModule,
    BuildsSelectListModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    ProjectAvatarModule,
    ProjectsSelectListModule,
    ReactiveFormsModule,
    TargetAvatarModule,
    TargetsSelectListModule,
    ProjectAsyncModule,
    BuildAsyncModule,
    BranchAsyncModule,
    TargetAsyncModule
  ],
  exports: [BuildPickerComponent]
})
export class BuildPickerModule {}
