import { depthAtStartOfFragment } from '@common/prosemirror/model/fragment';
import { FoundNodeInfo, nodeInterior } from '@common/prosemirror/model/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { omit } from 'lodash';
import { Fragment, Node, NodeRange, NodeType, ProseMirrorNode, ResolvedPos, Slice } from 'prosemirror-model';
import { ReplaceAroundStep, Transform, canJoin, liftTarget } from 'prosemirror-transform';

// Pulled from pm code
function canCut(node: Node, start: number, end: number): boolean {
  return (start === 0 || node.canReplace(start, node.childCount)) &&
    (end === node.childCount || node.canReplace(0, end));
}

// Returns the depth of the nearest node that matches nodeType that the range can be lifted to
export function liftTargetFromNodeType(range: NodeRange, nodeType: NodeType) {
  const content = range.parent.content.cutByIndex(range.startIndex, range.endIndex);

  const fromNodeInfo = resolvedPosFind(range.$from, node => node.type === nodeType);

  if (fromNodeInfo) {
    for (let depth = fromNodeInfo.depth - 1; depth >= 0; --depth) {
      const node = range.$from.node(depth),
        index = range.$from.index(depth),
        endIndex = range.$to.indexAfter(depth);

      if (depth < range.depth && node.canReplace(index, endIndex, content)) {
        return depth;
      }

      if (depth === 0 || node.type.spec.isolating || !canCut(node, index, endIndex)) {
        break;
      }
    }
  }
}

// Split the content in the given range off from its parent, if there is sibling content before or after it, and move it up the tree to the depth specified by target.
// You'll probably want to use liftTargetFromNodeType to compute target, to make sure the lift is valid.
export function liftInlineNode(tr: Transform, range: NodeRange, target: number): Transform {
  const $from = range.$from;
  const $to = range.$to;
  const depth = range.depth;
  const gapStart = $from.before(depth + 1);
  const gapEnd = $to.after(depth + 1);
  let start = gapStart;
  let end = gapEnd;

  // Build the slice that will wrap around the range
  let insert = Fragment.empty;

  let before = Fragment.empty,
    openStart = 0;
  for (let d = depth; d > target; d -= 1) {
    if (d > target && $from.parentOffset > 0) {
      before = Fragment.from($from.node(d).copy(before));
      openStart += 1;
    }

    if (d > target + 1) {
      insert = Fragment.from($from.node(d).copy(insert));
    }
  }

  let after = Fragment.empty,
    openEnd = 0;
  for (let d = depth; d > target; d -= 1) {
    if (d > target && $to.pos < $to.end()) {
      after = Fragment.from($to.node(d).copy(after));
      openEnd += 1;
    }
  }

  // If at the beginning of the inline node then move the start back to the beginning of the target node so that the target node is removed at the start
  if ($from.parentOffset === 0) {
    start -= (depth - target);
  }

  // If at the end of the inline node then move the end position to the end of the target node so that the target node is removed
  if ($to.pos >= $to.end()) {
    end += (depth - target);
  }

  // Make the slice
  const slice = new Slice(before.append(insert).append(after), openStart, openEnd);
  // console.log('liftInlineNode [' + depth + ' -> ' + target + ']', slice);
  // console.log('liftInlineNode', '[', start, end, '] [', gapStart, gapEnd, ']', before.size, '-', openStart, '(' + depthAtStartOfFragment(insert) + ')');

  // Make the transformation
  return tr.step(new ReplaceAroundStep(start, end, gapStart, gapEnd, slice, openStart + depthAtStartOfFragment(insert), true));
}

// Sets the attributes of a node by adding/setting the given attrs to the node's existing attributes
export function updateNodeAttrs(tr: Transform, node: Node, pos: number, attrs: object): Transform {
  return tr.setNodeMarkup(pos, null, Object.assign({}, node.attrs, attrs));
}

// This code is taken from prosemirror-transform's canChangeType https://github.com/ProseMirror/prosemirror-transform/blob/master/src/structure.js#L133
function canChangeType(doc: ProseMirrorNode, pos: number, type: NodeType) {
  const $pos = doc.resolve(pos);
  const index = $pos.index();
  return $pos.parent.canReplaceWith(index, index + 1, type);
}

// Transforms the text block nodes in the current selection to the given NodeType
// When changing the block type of each node the node's attrs are copied over to the new node.
// Optionally include a list of attrs to not copy over.
// This code is a slight modification of prosemirror-transform's setBlockType https://github.com/ProseMirror/prosemirror-transform/blob/master/src/structure.js#L116
export function setBlockType(tr: Transform, from: number, to: number = from, type: NodeType, excludeAttrs?: string[]): Transform {
  if (!type.isTextblock) {
    throw new RangeError('Type given to setBlockType should be a textblock');
  }

  const mapFrom = tr.steps.length;

  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (node.isTextblock && !node.hasMarkup(type) && canChangeType(tr.doc, tr.mapping.slice(mapFrom).map(pos), type)) {
      // Ensure all markup that isn't allowed in the new node type is cleared
      tr.clearIncompatible(tr.mapping.slice(mapFrom).map(pos, 1), type);

      const mapping = tr.mapping.slice(mapFrom);
      const startM = mapping.map(pos, 1), endM = mapping.map(pos + node.nodeSize, 1);

      tr.step(new ReplaceAroundStep(startM, endM, startM + 1, endM - 1, new Slice(Fragment.from(type.create(omit(node.attrs, excludeAttrs), null, node.marks)), 0, 0), 1, true));

      return false;
    }
  });

  return tr;
}

/**
 * Unbinds the node from the document, also it auto joins nodes on bounds of the the unbind node.
 * @param isJoinable - function that returns true if the left and right nodes can be joined after unbinding, it calls only for nodes when their join is possible in general.
 */
export function unbind(tr: Transform, node: ProseMirrorNode, pos: number, isJoinable?: (left: FoundNodeInfo, right: FoundNodeInfo) => boolean): Transform {
  const range = nodeInterior(tr.doc, node, pos);
  const target = liftTarget(range);
  if (!target)
    throw new RangeError('Unable to unbind the node at the specified position');
  tr.lift(range, target);
  tryJoin(tr, tr.mapping.map(pos), isJoinable);
  tryJoin(tr, tr.mapping.map(pos + node.nodeSize), isJoinable);
  return tr;
}

/**
 * Tries to join adjusted nodes at the specified position.
 * @param isJoinable - function that returns true if the left and right nodes can be joined, it calls only for nodes when their join is possible in general e.g. they have same markup.
 */
export function tryJoin(tr: Transform, pos: number, isJoinable?: (left: FoundNodeInfo, right: FoundNodeInfo) => boolean): Transform {
  if (canJoin(tr.doc, pos)) /* ask prosemirror to add depth argument, like in canSplit */ {
    const depth = joinDepth(tr.doc, pos, isJoinable);
    if (depth) tr.join(pos, depth);
  }
  return tr;
}

/**
 * Gets the possible depth for join adjusted nodes at specified position.
 */
function joinDepth(doc: ProseMirrorNode, pos: number, isJoinable?: (left: FoundNodeInfo, right: FoundNodeInfo) => boolean): number {
  const getDepth = ($left: ResolvedPos, $right: ResolvedPos): number => {
    const left = $left.nodeBefore, right = $right.nodeAfter;
    if (left && right && !left.isLeaf && left.canAppend(right) && left.sameMarkup(right) &&
      (!isJoinable || isJoinable({ node: left, pos: $left.pos }, { node: right, pos: $right.pos }))) {
      const $prev = doc.resolve($left.pos - 1);
      const $next = doc.resolve($right.pos + 1);
      return getDepth($prev, $next) + 1;
    }
    return 0;
  }

  const $pos = doc.resolve(pos);
  return getDepth($pos, $pos);
}
