import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BuildProfileDialogModule } from '@portal-core/builds/components/build-profile-dialog/build-profile-dialog.module';
import { LicenseProfileDialogModule } from '@portal-core/licenses/components/license-profile-dialog/license-profile-dialog.module';
import { MessageCenterDialogCardModule } from '@portal-core/messages/components/message-center/message-center-dialog-card.module';
import { OutputAnalyticsReportFilterDialogModule } from '@portal-core/output-analytics/components/output-analytics-report-filter-dialog/output-analytics-report-filter-dialog.module';
import { ProjectProfileDialogModule } from '@portal-core/projects/components/project-profile-dialog/project-profile-dialog.module';
import { ReviewPackageProfileDialogModule } from '@portal-core/reviews/review-packages/components/review-package-profile-dialog/review-package-profile-dialog.module';
import { SiteProfileDialogModule } from '@portal-core/sites/components/site-profile-dialog/site-profile-dialog.module';
import { TaskBoardProfileDialogModule } from '@portal-core/task-boards/components/task-board-profile-dialog/task-board-profile-dialog.module';
import { TaskFilterDialogModule } from '@portal-core/tasks/components/task-filter-dialog/task-filter-dialog.module';
import { TeamProfileDialogModule } from '@portal-core/teams/components/team-profile-dialog/team-profile-dialog.module';
import { TranslationPackageLanguageBranchesProfileDialogModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-profile-dialog/translation-package-language-branch-profile-dialog.module';
import { TranslationPackageProfileDialogModule } from '@portal-core/translation-packages/components/translation-package-profile-dialog/translation-package-profile-dialog.module';
import { UserProfileDialogModule } from '@portal-core/users/components/user-profile-dialog/user-profile-dialog.module';

/*
 * Import dialogs modules here instead of in individual component modules in order to prevent circular module dependencies.
 * This module is imported directly into the app modules in portal-client and portal-admin. This way, dialog components are
 * injected in the root of the app.  Dialog components can be included in the components which use them WITHOUT having to import the
 * dialog component modules anywhere else. See entity avatar components and the corresponding entity dialog components as examples
 */
@NgModule({
  imports: [
    BuildProfileDialogModule,
    CommonModule,
    LicenseProfileDialogModule,
    MessageCenterDialogCardModule,
    OutputAnalyticsReportFilterDialogModule,
    ProjectProfileDialogModule,
    SiteProfileDialogModule,
    TaskBoardProfileDialogModule,
    TaskFilterDialogModule,
    TeamProfileDialogModule,
    UserProfileDialogModule,
    TranslationPackageLanguageBranchesProfileDialogModule,
    TranslationPackageProfileDialogModule,
    ReviewPackageProfileDialogModule
  ]
})
export class DialogsModule { }
