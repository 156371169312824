<form novalidate (ngSubmit)="onSubmit()" class="mc-spring-board">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Delete <b>{{ reviewPackage?.Name }}?</b>
    </p>
    <p class="mc-form-text">Deleting a review package cannot be undone.</p>
    <p class="mc-form-title">
      Type "<b>{{ reviewPackage?.Name }}</b>" in the text box below to delete the review package.
    </p>
    <mat-form-field appearance="standard" color="primary-vivid" class="mc-form-field">
      <input type="text" matInput name="confirm" #confirmInput placeholder="Confirm Delete" />
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="warn" [disabled]="confirmInput.value !== reviewPackage?.Name || (savingState.loading$ | async)">Delete</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>