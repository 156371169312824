<div class="mc-d-flex mc-flex-row mc-justify-content-center mc-align-items-center mc-review-file-reviewer-status-bar-chart-wrapper">
  <mc-chart type="bar" [options]="chartOptions" [data]="chartData$ | async"></mc-chart>

  <div class="mc-review-file-reviewer-status-bar-chart-aside-title mc-d-flex mc-align-items-center">
    <ng-container *ngIf="analytics?.TotalFileCount && chartCounts">
      {{chartCounts.Submitted}}/{{analytics.TotalFileCount}} Files {{ ReviewFileUserStatus.Submitted | mcReviewFileUserStatusText }}
    </ng-container>
  </div>
</div>

<div class="mc-d-flex mc-flex-row mc-align-items-center mc-review-file-reviewer-status-bar-chart-count-wrapper" [ngClass]="'mc-justify-content-' + keyJustification">
  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.ToDo">
    <span class="mc-dot mc-dot-medium" [ngClass]="[ReviewFileUserStatus.Todo | mcReviewFileUserStatusColorClass : 'bg']"></span>
    <span class="mc-review-file-reviewer-status-bar-chart-dot-label"><b>{{ chartCounts.ToDo }}</b> {{ ReviewFileUserStatus.Todo | mcReviewFileUserStatusText }} </span>
  </div>

  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.InProgress">
    <span class="mc-dot mc-dot-medium" [ngClass]="[ReviewFileUserStatus.InProgress | mcReviewFileUserStatusColorClass : 'bg']"></span>
    <span class="mc-review-file-reviewer-status-bar-chart-dot-label"><b> {{ chartCounts.InProgress }}</b> {{ ReviewFileUserStatus.InProgress | mcReviewFileUserStatusText }} </span>
  </div>

  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.Submitted">
    <span class="mc-dot mc-dot-medium" [ngClass]="[ReviewFileUserStatus.Submitted | mcReviewFileUserStatusColorClass : 'bg']"></span>
    <span class="mc-review-file-reviewer-status-bar-chart-dot-label"><b>{{ chartCounts.Submitted }}</b> {{ ReviewFileUserStatus.Submitted | mcReviewFileUserStatusText }} </span>
  </div>
</div>