import { Pipe, PipeTransform } from '@angular/core';

/*
 * Converts seconds to following format 'HH hours mm minutes ss seconds'
 *
 * Usage:
 *   value | mcDuration
 * Example:
 *   {{ 61 | mcDuration }}
 *   formats to: '1 minute 1 second'
 *   {{ 61 | mcDuration: 'short' }}
 *   formats to: '00:01:01'
*/
@Pipe({
  name: 'mcDuration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number, durationFormat?: string): string {
    switch (durationFormat) {
      case 'short':
        return this.formatShortDuration(value);
      case 'long':
      default:
        return this.formatLongDuration(value);
    }
  }

  formatLongDuration(value: number): string {
    let formatedTime = '';

    let minutes = Math.floor(value / 60).toFixed(0);
    const seconds = (value % 60).toFixed(0);
    const hours = Math.floor(Number(minutes) / 60).toFixed(0);
    minutes = (Number(minutes) % 60).toFixed(0);

    if (hours !== '0') {
      formatedTime += hours + ' hour' + ((Number(hours) > 1) ? 's' : '');
    }

    if (minutes !== '0') {
      if (formatedTime.length > 0) {
        formatedTime += ' ';
      }
      formatedTime += minutes + ' minute' + ((Number(minutes) > 1) ? 's' : '');
    }

    if (seconds !== '0') {
      if (formatedTime.length > 0) {
        formatedTime += ' ';
      }
      formatedTime += seconds + ' second' + ((Number(seconds) > 1) ? 's' : '');
    }

    // If the formated time hasn't been set then that means the duration is zero
    if (!formatedTime) {
      formatedTime = '0 seconds';
    }

    return formatedTime;
  }

  formatShortDuration(value: number): string {
    let formatedTime = '';

    let minutes = Math.floor(value / 60).toFixed(0);
    const seconds = (value % 60).toFixed(0);
    const hours = Math.floor(Number(minutes) / 60).toFixed(0);
    minutes = (Number(minutes) % 60).toFixed(0);

    if (Number(hours) < 10) {
      formatedTime += '0';
    }
    formatedTime += hours + ':';

    if (Number(minutes) < 10) {
      formatedTime += '0';
    }
    formatedTime += minutes + ':';

    if (Number(seconds) < 10) {
      formatedTime += '0';
    }
    formatedTime += seconds;

    return formatedTime;
  }
}
