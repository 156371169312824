<div class="mc-task-comment-container"
  [ngClass]="level === taskCommentLevel.Child ? 'mc-task-comment-child-container': level === taskCommentLevel.ChildOfChild ? 'mc-task-comment-child-of-child-container' : ''">
  <div>
    <mc-license-user-avatar [licenseUser]="licenseUser$ | async" [size]="'normal'" [showName]="false" [matTooltip]="(licenseUser$ | async)?.User.FullName"></mc-license-user-avatar>
  </div>
  <div class="mc-task-comment-content-container">
    <div class="mc-task-comment-header-container">
      <span class="mc-task-comment-header-container-text text-heavy-weight">{{(licenseUser$ | async)?.User?.FullName}}</span>
      <span class="mc-task-comment-header-container-text text-light-weight text-small">{{comment.CreatedOn | date: 'shortTime'}}</span>
      <button *ngIf="canEditAllTasks" mat-icon-button class="mc-button-small" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="project-titan" fontIcon="icon-kebab-solid"></mat-icon>
      </button>
    </div>
    <mc-comment-box [comment]="comment" [reduceSize]="level === taskCommentLevel.Child || level === taskCommentLevel.ChildOfChild" [isNewComment]="false" [canEditAllTasks]="canEditAllTasks"
      [checkIsDirty]="checkIsDirty" (isDirtyChanged)="onCommentBoxDirtyChanged()">
    </mc-comment-box>
  </div>
</div>
<mat-menu #menu="matMenu" class="popup-card" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <button mat-menu-item *ngIf="level === taskCommentLevel.Parent" (click)="onReplyClicked()">Reply</button>
    <button mat-menu-item (click)="onDeleteClicked()">Delete</button>
  </ng-template>
</mat-menu>
