export enum LicenseUserClientSideInfoKey {
  BoardTaskPage = 0,
  CalendarTaskPage = 1,
  ArchiveTaskPage = 2,
  BacklogTaskPage = 3,
  HomeDashboard = 4,
  ProjectDashboard = 5,
  GridTaskPage = 6,
  AllTaskPages = 7,
  HasVisitedPages = 8,
  GridTaskBoardPage = 9
}
