import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogModule } from '@portal-core/general/components/confirm-dialog/confirm-dialog.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PermissionsTreeModule } from '@portal-core/permissions/components/permissions-tree/permissions-tree.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { PermissionsFormComponent } from './permissions-form.component';

@NgModule({
  declarations: [PermissionsFormComponent],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    PermissionsTreeModule,
    ProjectAvatarModule,
    ProjectAsyncModule
  ],
  exports: [PermissionsFormComponent]
})
export class PermissionsFormModule { }
