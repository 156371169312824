import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { stringInputToObject } from '@common/util/colors';
import { BASIC_COLORS } from '@portal-core/ui/color-picker/helpers/color-picker-helpers';
import { Color } from '@portal-core/ui/color-picker/models/color.model';

@Component({
  selector: 'ngx-mat-color-collection',
  templateUrl: './color-collection.component.html',
  styleUrls: ['./color-collection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'ngx-mat-color-collection'
  }
})
export class NgxMatColorCollectionComponent {
  @Output() colorChanged: EventEmitter<Color> = new EventEmitter<Color>();

  @Input()
  set color(c: any) {
    if (!c) {
      this.selectedColor = null;
    }

    if (c) {
      this.selectedColor = c.toHexString();
    }
  }

  selectedColor: any;

  colors1: string[] = BASIC_COLORS.slice(0, 7);
  colors2: string[] = BASIC_COLORS.slice(8, 16);

  select(hex: string) {
    this.selectedColor = hex;
    if (this.selectedColor === null) {
      this.colorChanged.emit(null);
    } else {
      const { r, g, b, a } = stringInputToObject(hex);
      this.colorChanged.emit(new Color(r, g, b, a));
    }
  }
}
