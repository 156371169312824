import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DropdownArrowComponent } from './dropdown-arrow.component';

@NgModule({
  declarations: [
    DropdownArrowComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule
  ],
  exports: [
    DropdownArrowComponent
  ]
})
export class DropdownArrowModule { }
