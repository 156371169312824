import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { ChangeTaskPosition } from '@portal-core/tasks/models/change-task-position.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { TasksService } from '@portal-core/tasks/services/tasks.service';
import { map, Observable, of } from 'rxjs';

export class TaskUtils {
  // resorting task positions to make sure none overlap with one another
  //  if none overlap, we skip updating the position for that task
  static repositionTasks$(tasksService: TasksService, selectedTasks: Task[], tasks: Task[]): Observable<any> {
    if (!tasks) {
      return of({});
    }
    const tasksUpdated = [];
    let incPos = 0;
    tasks.filter(t => !selectedTasks.some(st => t.Id === st.Id) && !t.IsDeleted)
      .forEach((task, index) => {
        while (selectedTasks.some(st => !st.IsDeleted && index + incPos === st.Position)) {
          incPos++;
        }
        if (task.Position !== index + incPos) {
          task.Position = index + incPos;
          tasksUpdated.push(task);
        }
      });

    if (tasksUpdated.length === 0) {
      return of({});
    }
    return tasksService.changePositions$(
      tasksUpdated.map(t =>
        <ChangeTaskPosition>{
          TaskId: t.Id,
          Position: t.Position
        })).pipe(map(() => tasksService.updateItems$(tasksUpdated)));
  }

  static getPastDue(task: Task): boolean {
    return (task.Milestone === TaskMilestone.InProgress
      || task.Milestone === TaskMilestone.ToDo)
      && task.Status === TaskStatus.Active
      && task.DueDate
      ? new Date(task.DueDate) < new Date()
      : false;
  }
}
