import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { RangeModule } from '@portal-core/general/pipes/range/range.module';
import { ProjectFilesTreeItemModule } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.module';
import { ProjectFilesTreeFolderComponent } from './project-files-tree-folder.component';

@NgModule({
  declarations: [
    ProjectFilesTreeFolderComponent
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    ErrorsExpansionPanelModule,
    MatProgressBarModule,
    PreventDefaultModule,
    ProjectFilesTreeItemModule,
    RangeModule,
    StopPropagationModule
  ],
  exports: [
    ProjectFilesTreeFolderComponent
  ]
})
export class ProjectFilesTreeFolderModule { }
