import { Injectable } from '@angular/core';
import { PathService } from '@portal-core/general/services/path.service';
import { TreeService } from '@portal-core/general/services/tree.service';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';

@Injectable()
/**
 *  Only meant to be used by ProjectFilesTreeComponent as a provider.
 *  This service is used to manipulate the checklists in the checklist version of the tree
 *  used in ProjectFilesTreeComponent.
**/
export class ProjectFilesChecklistService {

  constructor(private treeService: TreeService, private pathService: PathService) { }

  checkParentNodes(node: ProjectFileNode) {
    this.treeService.forEachAncestor(node, 'parentNode', parent => {
      if (parent.children.every(child => child.flatFileNode.selected === TriSelectedState.Selected)) {
        parent.flatFileNode.selected = TriSelectedState.Selected;
      } else if (parent.children.every(child => child.flatFileNode.selected === TriSelectedState.NotSelected)) {
        parent.flatFileNode.selected = TriSelectedState.NotSelected;
      } else {
        parent.flatFileNode.selected = TriSelectedState.Indeterminate;
      }
    });
  }

  // add required paths to a new pathFilters object starting with the root nodes
  buildPathFilters(fileNodesMap: Map<string, ProjectFileNode>, pathFilters: string[], ignoreFolders: boolean = false): string[] {
    let newPathFilters = [];
    fileNodesMap.forEach(node => {
      if (node.flatFileNode.selected === TriSelectedState.Selected && !this.checkSubPath(newPathFilters, node.path)) {
        if (!node.flatFileNode.expandable || !ignoreFolders) {
          newPathFilters.push(node.path);
        }
      };
    });
    pathFilters?.forEach(pathFilter => {
      const flatFileNode = fileNodesMap.get(pathFilter)?.flatFileNode;
      if (!flatFileNode && !this.checkSubPath(newPathFilters, pathFilter) && pathFilter != '') {
        newPathFilters.push(pathFilter);
      }
    })

    return newPathFilters;
  }

  checkSubPath(newPathFilters: string[], pathFilter: string): boolean {
    for (let i = 0; i < newPathFilters.length; i++) {
      if (pathFilter.includes(newPathFilters[i])) {
        return true;
      }
    }
    return false;
  }

  // Set node.Selected based on pathFilters array
  setSelection(node: ProjectFileNode, pathFilters: string[], ignoreInitialFilters: boolean = false) {
    if (!node?.flatFileNode || ignoreInitialFilters || !pathFilters) return;

    // if there are no paths then select everything
    if (pathFilters.length === 1 && pathFilters[0] === '') {
      node.flatFileNode.selected = TriSelectedState.Selected;
      return;
    }

    // A node is selected if its path OR its ancestor's path is in the filter paths (aka the node's path is within a filter's path)
    if (pathFilters.some(filterPath => this.pathService.isWithinPath(node.path, filterPath))) {
      node.flatFileNode.selected = TriSelectedState.Selected;
      // A node is indeterminate if a descendent is in the filter path (aka a filterPath is within the node's path)
    } else if (pathFilters.some(filterPath => this.pathService.isWithinPath(filterPath, node.path))) {
      node.flatFileNode.selected = TriSelectedState.Indeterminate;
      // Else the node is not selected
    } else {
      node.flatFileNode.selected = TriSelectedState.NotSelected;
    }
  }
}
