import { splitBlock } from '@common/prosemirror/commands/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { NodeType } from 'prosemirror-model';
import { Command, EditorState, NodeSelection, Selection } from 'prosemirror-state';

/**
 * Creates a command that appends a multiple choice item after the node at the current selection.
 * If the node is empty then the node's content is split instead of appending a new item.
 * @param sourceType The NodeType that allows a multiple choice item to be appended after it.
 * @param itemType The NodeType for the multiple choice's item node.
 */
export function appendMultipleChoiceItem(sourceTypes: NodeType[], itemType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    if (state.selection instanceof NodeSelection) {
      const node = state.selection.node;

      if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
        return false;
      }
    }

    // If the selection is not inside a multiple choice question then return false
    const itemResolvedPosInfo = resolvedPosFind($from, node => sourceTypes.includes(node.type));
    if (!itemResolvedPosInfo) {
      return false;
    }

    // If this is an empty multiple choice item THEN try splitting its content
    if ($from.parent.content.size === 0 && $from.node(-1).childCount === $from.indexAfter(-1)) {
      return splitBlock(state, dispatch);
      // Else if the cursor is not at the end of the multiple choice item THEN do nothing AND let the next command handle this
    } else if ($to.pos !== $to.end()) {
      return false;
      // Else the cursor is at the end of the multiple choice SO insert a new multiple choice item after the current item
    } else {
      if (dispatch) {
        const tr = state.tr;
        const insertPos = $to.after() + ($to.depth - itemResolvedPosInfo.depth);
        tr.insert(insertPos, itemType.createAndFill());
        tr.setSelection(Selection.near(tr.doc.resolve(insertPos)));
        dispatch(tr.scrollIntoView());
      }

      return true;
    }
  };
}
