import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, Directive, Inject, Injector, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

/**
 * mcPopupContent
 * When used within mc-popup this directive marks an ng-template as lazy rendered popup content.
 */
@Directive({
  selector: '[mcPopupContent]'
})
export class PopupContentDirective implements OnDestroy {
  /** The portal for the directive's ng-template. */
  private portal: TemplatePortal<any>;
  /** The outlet that the popup content is attached to. */
  private outlet: DomPortalOutlet;

  /** Emits when the popup content has been attached. */
  readonly attached: Subject<void> = new Subject<void>();

  constructor(
    private template: TemplateRef<any>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
    private cdr?: ChangeDetectorRef
  ) { }

  /** Attaches the content with a particular context. */
  attach(context: any = {}) {
    if (!this.portal) {
      this.portal = new TemplatePortal(this.template, this.viewContainerRef);
    }

    this.detach();

    if (!this.outlet) {
      const contentElement = this.document.createElement('div');
      contentElement.classList.add('mc-popup-lazy-content');
      this.outlet = new DomPortalOutlet(contentElement, this.componentFactoryResolver, this.appRef, this.injector);
    }

    const element: HTMLElement = this.template.elementRef.nativeElement;

    // Because we support opening the same popup from different triggers (which in turn have their
    // own `OverlayRef` panel), we have to re-insert the host element every time, otherwise we
    // risk it staying attached to a pane that's no longer in the DOM.
    element.parentNode.insertBefore(this.outlet.outletElement, element);

    // When `PopupContent` is used in an `OnPush` component, the insertion of the popup
    // content via `createEmbeddedView` does not cause the content to be seen as "dirty"
    // by Angular. This causes the `@ContentChildren` for elements within the popup to
    // not be updated by Angular. By explicitly marking for check here, we tell Angular that
    // it needs to check for new elements and update the `@ContentChild` in `PopupComponent`.
    // @breaking-change 9.0.0 Make change detector ref required
    if (this.cdr) {
      this.cdr.markForCheck();
    }

    this.portal.attach(this.outlet, context);
    this.attached.next();
  }

  /** Detaches the content. */
  detach() {
    if (this.portal?.isAttached) {
      this.portal.detach();
    }
  }

  /** Cleans up the outlet resources. */
  ngOnDestroy() {
    if (this.outlet) {
      this.outlet.dispose();
    }
  }
}
