import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { FormValidationConfig } from '@portal-core/forms/util/form-validation-config';
import { FormValidationContext } from '@portal-core/forms/util/form-validation-context';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-user-password-form',
  templateUrl: './user-password-form.component.html',
  styleUrls: ['./user-password-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class UserPasswordFormComponent implements OnInit {
  @Input() licenseUser: LicenseUser;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('formDirective', { static: true }) formDirective: FormGroupDirective;

  get dirty(): boolean {
    return this.passwordForm?.dirty;
  }

  savingState: LoadingState<string> = new LoadingState<string>();
  showCancelButton: boolean;
  passwordForm: UntypedFormGroup;
  formValidationConfig: FormValidationConfig;
  formValidationContext: FormValidationContext = new FormValidationContext();
  validationSubscription: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private formsService: FormsService
  ) { }

  ngOnInit() {
    this.showCancelButton = this.cancel.observers.length > 0;
    this.buildForm();
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      this.authService.changePassword$(this.licenseUser.User.Id, formGroup.value['currentPassword'], formGroup.value['newPassword']).subscribe(() => {
        this.savingState.update(false);
        this.formDirective.resetForm();
        this.snackBar.open('Your password has been updated.', 'OK', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.formsService.updateValidationContext(this.passwordForm, this.formValidationConfig, this.formValidationContext, error);
        this.savingState.update(false, 'Unable to update your password.', this.errorService.getErrorMessages(error));
      });
    }
  }

  protected buildForm() {
    // config for error codes that become validation errors
    this.formValidationConfig = new FormValidationConfig({
      incorrect: { code: ErrorCode.UserIncorrectCredentialsError, useServerMessage: true },
      oldPassword: ErrorCode.UserOldPasswordHistoryError
    }, {
      currentPassword: ['incorrect'],
      newPassword: ['oldPassword']
    });

    this.passwordForm = this.formBuilder.group({
      currentPassword: new UntypedFormControl('', [
        Validators.required,
        ...this.formsService.createErrorCodeValidators(this.formValidationConfig, this.formValidationContext, 'currentPassword')
      ]),
      newPassword: new UntypedFormControl(''),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        this.formsService.createMatchValueValidator('newPassword')
      ])
    });

    this.authService.getMinPasswordLength$(this.licenseUser.User.UserName).subscribe(minPasswordLength => {
      this.passwordForm.controls.newPassword.setValidators([
        Validators.required,
        this.formsService.minPasswordLengthValidator(minPasswordLength),
        this.formsService.createMismatchValueValidator('currentPassword'),
        ...this.formsService.createErrorCodeValidators(this.formValidationConfig, this.formValidationContext, 'newPassword')
      ]);
      this.passwordForm.controls.newPassword.updateValueAndValidity();
    });

    this.validationSubscription = this.formsService.initErrorCodeValidation(this.passwordForm, this.formValidationConfig, this.formValidationContext);
  }
}
