import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDialogModule } from '@portal-core/general/components/confirm-dialog/confirm-dialog.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { CommentBoxModule } from '@portal-core/tasks/components/comment-box/comment-box.module';
import { TaskAssetIconModule } from '@portal-core/tasks/components/task-asset-icon/task-asset-icon.module';
import { TaskAssetModule } from '@portal-core/tasks/components/task-asset/task-asset.module';
import { TaskCommentGroupModule } from '@portal-core/tasks/components/task-comment-group/task-comment-group.module';
import { TaskCommentIconModule } from '@portal-core/tasks/components/task-comment-icon/task-comment-icon.module';
import { TaskProfileDialogComponent } from '@portal-core/tasks/components/task-profile-dialog/task-profile-dialog.component';
import { RichTextBlockModule } from '@portal-core/text-editor/components/rich-text-block/rich-text-block.module';
import { RichTextEditorModule } from '@portal-core/text-editor/components/rich-text-editor/rich-text-editor.module';
import { GridModule } from '@portal-core/ui/grid/grid.module';
import { TaskProfileDialogAttachmentsComponent } from './task-profile-dialog-attachments/task-profile-dialog-attachments.component';
import { TaskProfileDialogDetailsComponent } from './task-profile-dialog-details/task-profile-dialog-details.component';
import { TaskProfileDialogDiscussionComponent } from './task-profile-dialog-discussion/task-profile-dialog-discussion.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTooltipModule,
    GridModule,
    LoaderModule,
    ProjectAvatarModule,
    LicenseUserAvatarModule,
    TaskCommentGroupModule,
    TaskAssetModule,
    CommentBoxModule,
    ConfirmDialogModule,
    MatProgressBarModule,
    TaskAssetIconModule,
    TaskCommentIconModule,
    RichTextBlockModule,
    RichTextEditorModule,
    LicenseUserForLicenseAsyncModule
  ],
  declarations: [
    TaskProfileDialogComponent,
    TaskProfileDialogDetailsComponent,
    TaskProfileDialogDiscussionComponent,
    TaskProfileDialogAttachmentsComponent
  ],
  exports: [TaskProfileDialogComponent]
})
export class TaskProfileDialogModule { }
