import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RemoveLeadingSlashPipe } from '@portal-core/project-files/pipes/remove-leading-slash/remove-leading-slash.pipe';

@NgModule({
  declarations: [
    RemoveLeadingSlashPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RemoveLeadingSlashPipe
  ]
})
export class RemoveLeadingSlashModule { }
