import { Overlay } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Inject, NgZone, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { PopupContentDirective } from '@portal-core/ui/popup/directives/popup-content/popup-content.directive';
import { PopupBase } from '@portal-core/ui/popup/util/popup-base';

/**
 * mc-popup
 * Defines the template for a popup that can be opened with a PopupTriggerDirective.
 */
@Component({
  selector: 'mc-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent extends PopupBase<any> {
  /** A reference to the ng-template in the popup's template file. This template contains the popup's content. */
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  /** A reference to the popup's content that will be rendered lazily. This will be undefined if the popup content does not contain lazy content. */
  @ContentChild(PopupContentDirective) lazyContent: PopupContentDirective;

  constructor(@Inject(DOCUMENT) document: Document, ngZone: NgZone, overlay: Overlay, elementOverlayService: ElementOverlayService, viewContainerRef: ViewContainerRef) {
    super(document, ngZone, overlay, elementOverlayService, viewContainerRef);
  }

  /** Returns a reference to the popup's content template. */
  protected getPopupContent(): TemplateRef<any> {
    return this.templateRef;
  }

  /** Returns a reference to the popup's lazy content.  */
  protected getPopupLazyContent(): PopupContentDirective {
    return this.lazyContent;
  }

  /** Returns the mc-popup class as the selector for the popup transform origin. */
  protected getPopupTransformOrigin(): string {
    return '.mc-popup';
  }
}
