export class FileWithPath extends File {
    readonly relativePath: string;

    get filePath() {
        return this.relativePath + this.name;
    }

    constructor(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag, relativePath: string = '') {
        super(fileBits, fileName, options);
        this.relativePath = relativePath;
    }
}