import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { CommitAvatarModule } from '@portal-core/commits/components/commit-avatar/commit-avatar.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { ReviewPackageNotificationListModule } from '@portal-core/notifications/components/review-package-notification-list/review-package-notification-list.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { TocAvatarModule } from '@portal-core/project-files/components/toc-avatar/toc-avatar.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { ReviewPackageAvatarModule } from '@portal-core/reviews/review-packages/components/review-package-avatar/review-package-avatar.module';
import { ReviewPackageDeletionFormModule } from '@portal-core/reviews/review-packages/components/review-package-deletion-form/review-package-deletion-form.module';
import { ReviewPackageFilesFormModule } from '@portal-core/reviews/review-packages/components/review-package-files-form/review-package-files-form.module';
import { ReviewPackageOverviewModule } from '@portal-core/reviews/review-packages/components/review-package-overview/review-package-overview.module';
import { ReviewPackageReviewersFormModule } from '@portal-core/reviews/review-packages/components/review-package-reviewers-form/review-package-reviewers-form.module';
import { ReviewPackageSettingsFormModule } from '@portal-core/reviews/review-packages/components/review-package-settings-form/review-package-settings-form.module';
import { ReviewPackageLinkModule } from '@portal-core/reviews/review-packages/pipes/review-package-link/review-package-link.module';
import { ReviewPackageSourceTextModule } from '@portal-core/reviews/review-packages/pipes/review-package-source-text/review-package-source-text.module';
import { ReviewPackageStatusTextModule } from '@portal-core/reviews/review-packages/pipes/review-package-status-text/review-package-status-text.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { ReviewPackageProfileComponent } from './review-package-profile.component';

@NgModule({
  declarations: [ReviewPackageProfileComponent],
  exports: [ReviewPackageProfileComponent],
  imports: [
    CommonModule,
    SwitchBoardModule,
    ReviewFileAvatarModule,
    ReviewPackageAvatarModule,
    ProjectAvatarModule,
    MatDialogModule,
    BranchAvatarModule,
    CommitAvatarModule,
    LicenseUserAvatarModule,
    ProfileTabModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    MatTabsModule,
    ReviewPackageFilesFormModule,
    ReviewPackageLinkModule,
    ReviewPackageSettingsFormModule,
    ReviewPackageNotificationListModule,
    ReviewPackageOverviewModule,
    ReviewPackageReviewersFormModule,
    ReviewPackageDeletionFormModule,
    ReviewPackageSourceTextModule,
    ReviewPackageStatusTextModule,
    TocAvatarModule
  ]
})
export class ReviewPackageProfileModule { }
