import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationPackageLanguageBranchAsyncPipe } from '@portal-core/translation-package-language-branches/pipes/translation-package-language-branch-async/translation-package-language-branch-async.pipe';

@NgModule({
  declarations: [TranslationPackageLanguageBranchAsyncPipe],
  imports: [CommonModule],
  exports: [TranslationPackageLanguageBranchAsyncPipe],
  providers: [TranslationPackageLanguageBranchAsyncPipe]
})
export class TranslationPackageLanguageBranchAsyncModule { }
