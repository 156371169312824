import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Observable, Subject } from 'rxjs';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ListOptionComponent } from '@portal-core/ui/list/components/list-option/list-option.component';
import { DataListBase } from '@portal-core/ui/list/util/data-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';

/**
 * SelectListComponent
 *
 * An infinite list component that connects to a collection data store with selectable options as the list items.
 */
@Component({
  selector: 'mc-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectListComponent extends DataListBase {
  /** The list control is used to fetch and store the items in the data list. And to get values for the list's options. */
  @Input() listControl: SelectionListControl = null;

  /** Emits the changed options when there is a selection change. */
  @Output() selectionChange: EventEmitter<ListOptionComponent[]> = new EventEmitter<ListOptionComponent[]>();

  private selectionChangeSource: Subject<ListOptionComponent[]> = new Subject<ListOptionComponent[]>();
  selectionChange$: Observable<ListOptionComponent[]> = this.selectionChangeSource.asObservable();

  constructor(protected errorService: ErrorService, protected viewportRuler: ViewportRuler) {
    super(errorService, viewportRuler);
  }

  onSelectionChanged(options: ListOptionComponent[]) {
    this.selectionChangeSource.next(options);
    this.selectionChange.emit(options);
  }
}
