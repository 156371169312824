import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { setListType, unwrapFromList } from '@common/prosemirror/commands/list';
import { removeMarks } from '@common/prosemirror/commands/mark';
import { filterMarks, findMark } from '@common/prosemirror/model/mark';
import { hasNodeAttrs } from '@common/prosemirror/model/node';
import { RichTextSchema } from '@common/rich-text/rich-text-schema';
import { ensureAbsoluteUrl } from '@common/util/path';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { RichTextLinkPropertiesDialogComponent, RichTextLinkPropertiesDialogData, RichTextLinkPropertiesDialogResult } from '@portal-core/text-editor/components/rich-text-link-properties-dialog/rich-text-link-properties-dialog.component';
import { EditorToolbarComponent } from '@portal-core/ui/editor/components/editor-toolbar/editor-toolbar.component';
import { EditorToolbarDropdownMenuClosedEvent } from '@portal-core/ui/editor/types/editor-toolbar-dropdown-menu-closed-event.type';
import { EditorToolbarItem } from '@portal-core/ui/editor/types/editor-toolbar-item.type';
import { EditorToolbarControl } from '@portal-core/ui/editor/util/editor-toolbar-control';
import { setBlockType, toggleMark } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { Mark, MarkType, NodeType } from 'prosemirror-model';
import { liftListItem, sinkListItem, wrapInList } from 'prosemirror-schema-list';
import { Command, EditorState, NodeSelection } from 'prosemirror-state';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'mc-rich-text-editor-toolbar',
  templateUrl: './rich-text-editor-toolbar.component.html',
  styleUrls: ['./rich-text-editor-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorToolbarComponent implements OnInit {
  @Input() getEditorState: () => EditorState;
  @Input() schema: RichTextSchema;
  @Output() dispatch: EventEmitter<Command | Command[]> = new EventEmitter<Command | Command[]>();
  @Output() dropdownMenuClosed: EventEmitter<EditorToolbarDropdownMenuClosedEvent<Command>> = new EventEmitter<EditorToolbarDropdownMenuClosedEvent<Command>>();

  @ViewChild('toolbar', { static: true }) toolbar: EditorToolbarComponent<Command, EditorState>;
  @ViewChild('linkMenuTrigger') linkMenuTrigger: MatMenuTrigger;
  @ViewChild('linkTextInput') linkTextInput: ElementRef<HTMLInputElement>;

  @PropertyObservable('schema') schema$: Observable<RichTextSchema>;

  toolbarControl$: Observable<EditorToolbarControl<Command>>;

  constructor(private dialog: MatDialog) { }

  openRichTextLinkPropertiesDialog(editorState: EditorState) {
    const selection = editorState.selection;

    const from = selection.$from.start();
    // Find a link mark that the selection is within
    const selectedLinkInfo = findMark(selection.$from.parent, (mark, child, pos, size) => {
      return mark.type.name === 'link' && selection.from >= from + pos && selection.from <= from + pos + size;
    });

    const content = editorState.selection.content().content;
    const text = selectedLinkInfo?.node.textContent ?? content.textBetween(0, content.size);

    this.dialog.open<RichTextLinkPropertiesDialogComponent, RichTextLinkPropertiesDialogData, RichTextLinkPropertiesDialogResult>(RichTextLinkPropertiesDialogComponent, {
      ...RichTextLinkPropertiesDialogComponent.DialogConfig,
      data: {
        text,
        link: selectedLinkInfo?.mark?.attrs.href
      }
    }).afterClosed().subscribe((linkInfo) => {
      // edit link
      if (selectedLinkInfo) {
        const markFrom = from + selectedLinkInfo.pos;
        const markTo = markFrom + selectedLinkInfo.size;
        const linkMark = selectedLinkInfo.mark;
        this.onEditLink(editorState, linkInfo.link, linkInfo.text, markFrom, markTo, linkMark);
      }
      // insert link
      else if (linkInfo) {
        this.onInsertLink(linkInfo.link, linkInfo.text);
      }

    });

    // to use as a command
    return true;
  }

  ngOnInit() {
    this.toolbarControl$ = this.schema$.pipe(
      map(schema => {
        if (schema) {
          const bold = toggleMark(schema.marks.strong);
          const italics = toggleMark(schema.marks.em);
          const underline = toggleMark(schema.marks.u);
          const unlink = removeMarks(schema.marks.link);
          const paragraph = setBlockType(schema.nodes.paragraph);
          const h1 = setBlockType(schema.nodes.heading, { level: 1 });
          const h2 = setBlockType(schema.nodes.heading, { level: 2 });
          const h3 = setBlockType(schema.nodes.heading, { level: 3 });
          const h4 = setBlockType(schema.nodes.heading, { level: 4 });
          const h5 = setBlockType(schema.nodes.heading, { level: 5 });
          const h6 = setBlockType(schema.nodes.heading, { level: 6 });
          const bulletList = [unwrapFromList(schema.nodes.list_item, schema.nodes.bullet_list), setListType(schema.nodes.list_item, schema.nodes.bullet_list), wrapInList(schema.nodes.bullet_list)];
          const orderedList = [unwrapFromList(schema.nodes.list_item, schema.nodes.ordered_list), setListType(schema.nodes.list_item, schema.nodes.ordered_list), wrapInList(schema.nodes.ordered_list)];
          const indentListItem = sinkListItem(schema.nodes.list_item);
          const outdentListItem = liftListItem(schema.nodes.list_item);

          return new EditorToolbarControl([
            {
              type: 'button',
              icon: 'icon-undo',
              tooltip: 'Undo',
              command: undo,
              isDisabled: (editorState: EditorState) => !undo(editorState)
            }, {
              type: 'button',
              icon: 'icon-redo',
              tooltip: 'Redo',
              command: redo,
              isDisabled: (editorState: EditorState) => !redo(editorState)
            }, {
              type: 'divider',
            }, {
              type: 'button',
              group: 'font',
              icon: 'icon-bold',
              tooltip: 'Toggle Bold',
              dropdownText: 'Bold',
              command: bold,
              isDisabled: (editorState: EditorState) => !bold(editorState),
              isActive: (editorState: EditorState) => this.markIsActive(editorState, schema.marks.strong),
            }, {
              type: 'button',
              group: 'font',
              icon: 'icon-italics',
              tooltip: 'Toggle Italic',
              dropdownText: 'Italic',
              command: italics,
              isDisabled: (editorState: EditorState) => !italics(editorState),
              isActive: (editorState: EditorState) => this.markIsActive(editorState, schema.marks.em),
            }, {
              type: 'button',
              group: 'font',
              icon: 'icon-underline',
              tooltip: 'Toggle Underline',
              dropdownText: 'Underline',
              command: underline,
              isDisabled: (editorState: EditorState) => !underline(editorState),
              isActive: (editorState: EditorState) => this.markIsActive(editorState, schema.marks.u),
            }, {
              type: 'divider',
            },
            {
              type: 'button',
              icon: 'icon-link',
              tooltip: 'Add or Edit Link',
              command: (editorState: EditorState) => this.openRichTextLinkPropertiesDialog(editorState),
              isDisabled: (editorState: EditorState) => !underline(editorState),
              isActive: (editorState: EditorState) => this.markIsActive(editorState, schema.marks.u),
            },
            {
              type: 'button',
              icon: 'icon-unlink',
              tooltip: 'Remove Link',
              command: unlink,
              isDisabled: (editorState: EditorState) => !this.markIsActive(editorState, schema.marks.link)
            }, {
              type: 'divider',
            }, {
              type: 'button',
              group: 'block',
              text: 'P',
              tooltip: 'Wrap in Paragraph',
              dropdownText: 'Paragraph',
              command: paragraph,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.paragraph)
            }, {
              type: 'button',
              group: 'block',
              text: 'H1',
              tooltip: 'Change to Heading 1',
              dropdownText: 'Heading 1',
              command: h1,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 1 })
            }, {
              type: 'button',
              group: 'block',
              text: 'H2',
              tooltip: 'Change to Heading 2',
              dropdownText: 'Heading 2',
              command: h2,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 2 })
            }, {
              type: 'button',
              group: 'block',
              text: 'H3',
              tooltip: 'Change to Heading 3',
              dropdownText: 'Heading 3',
              command: h3,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 3 })
            }, {
              type: 'button',
              group: 'block',
              text: 'H4',
              tooltip: 'Change to Heading 4',
              dropdownText: 'Heading 4',
              command: h4,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 4 })
            }, {
              type: 'button',
              group: 'block',
              text: 'H5',
              tooltip: 'Change to Heading 5',
              dropdownText: 'Heading 5',
              command: h5,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 5 })
            }, {
              type: 'button',
              group: 'block',
              text: 'H6',
              tooltip: 'Change to Heading 6',
              dropdownText: 'Heading 6',
              command: h6,
              isActive: (editorState: EditorState) => this.blockNodeIsActive(editorState, schema.nodes.heading, { level: 6 })
            }, {
              type: 'divider',
            }, {
              type: 'button',
              group: 'lists',
              icon: 'icon-list',
              tooltip: 'Insert Bullet List',
              dropdownText: 'Bullet List',
              command: bulletList
            }, {
              type: 'button',
              group: 'lists',
              icon: 'icon-ordered',
              tooltip: 'Insert Ordered List',
              dropdownText: 'Ordered List',
              command: orderedList
            }, {
              type: 'divider',
              group: 'lists',
              dropdownOnly: true
            }, {
              type: 'button',
              group: 'lists',
              icon: 'icon-margin-right',
              tooltip: 'Decrease Indent',
              dropdownText: 'Decrease Indent',
              command: outdentListItem,
              isDisabled: (editorState: EditorState) => !outdentListItem(editorState)
            }, {
              type: 'button',
              group: 'lists',
              icon: 'icon-margin-left',
              tooltip: 'Increase Indent',
              dropdownText: 'Increase Indent',
              command: indentListItem,
              isDisabled: (editorState: EditorState) => !indentListItem(editorState)
            }
          ], [
            {
              name: 'block',
              priority: 1,
              icon: 'icon-paragraph'
            }, {
              name: 'lists',
              priority: 2,
              icon: 'icon-list'
            }, {
              name: 'font',
              priority: 4,
              icon: 'icon-font-styles'
            }
          ]);
        }
      })
    );
  }

  onItemClicked(item: EditorToolbarItem<Command>) {
    if (item.command) {
      this.dispatch.emit(item.command);
    }
  }

  onInsertLink(href: string, newText: string,) {
    this.dispatch.emit((editorState, dispatch) => {
      const selection = editorState.selection;
      const tr = editorState.tr;

      tr.replaceSelectionWith(editorState.schema.text(newText));
      tr.addMark(selection.from, selection.from + newText.length, this.schema.marks.link.create({ href: ensureAbsoluteUrl(href) }));

      dispatch(tr);

      return true;
    });
  }

  onEditLink(editorState: EditorState, linkHref: string, linkText: string, markFrom: number, markTo: number, linkMark: Mark) {
    if (markFrom && linkMark) {
      const node = editorState.doc.nodeAt(markFrom);

      if (node?.isText) {
        // Build a set of marks without the link mark
        const newMarks = filterMarks(node, mark => mark.type.name !== 'link').map(markInfo => markInfo.mark);
        // Add a new link mark
        newMarks.push(editorState.schema.marks.link.create({ href: ensureAbsoluteUrl(linkHref) }));

        // Replace the link's text node with a new text node that has the new marks
        const tr = editorState.tr;
        tr.replaceRangeWith(markFrom, markTo, editorState.schema.text(linkText, newMarks));
        this.dispatch.emit((editorState, dispatch) => { dispatch(tr); return true; });
      }
    }
  }

  updateState(editorState: EditorState) {
    this.toolbar.updateState(editorState);
  }

  protected blockNodeIsActive(editorState: EditorState, nodeType: NodeType, attrs?: Dictionary): boolean {
    const $from = editorState.selection.$from;
    const to = editorState.selection.to;
    const node = (editorState.selection as NodeSelection).node;

    if (node) {
      return node.type === nodeType;
    }

    return to <= $from.end() && $from.parent.type === nodeType && (attrs ? hasNodeAttrs($from.parent, attrs) : true);
  }

  protected markIsActive(editorState: EditorState, markType: MarkType): boolean {
    const { from, $from, to, empty } = editorState.selection;

    if (empty) {
      return !!markType.isInSet(editorState.storedMarks || $from.marks());
    } else {
      return editorState.doc.rangeHasMark(from, to, markType);
    }
  }
}
