import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

/**
 * mc-central-avatar
 * A component for displaying the central avatar image and name.
 */
@Component({
  selector: 'mc-central-avatar',
  templateUrl: './central-avatar.component.html',
  styleUrls: ['./central-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentralAvatarComponent extends AvatarBase {
  @Input() name?: string = 'Central';

  hasAvatarImage(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return false;
  }

  isLink(): boolean {
    return false;
  }
}

