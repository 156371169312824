import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { WebhookChannel } from '@portal-core/slack/models/webhook-channel.model';
import { SlackWebhooksApiService } from '@portal-core/slack/services/slack-webhooks-api.service';
import { SlackWebhookChannelsDataService } from '@portal-core/slack/services/slack-webhooks-channels-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class SlackWebhookChannelsService extends CollectionServiceBase<WebhookChannel> {
  constructor(
    private slackWebhooksApiService: SlackWebhooksApiService,
    protected slackWebhookChannelsDataService: SlackWebhookChannelsDataService,
    protected dataService: DataService
  ) {
    super(slackWebhookChannelsDataService, dataService);
  }

  getChannels$(licenseId: number, options: GetDataOptions = null): Observable<WebhookChannel[]> {
    return this.dataService.getDataListItems$<WebhookChannel>('Licenses', licenseId, this.slackWebhookChannelsDataService, {
      fetch: () => this.slackWebhooksApiService.getChannels$(licenseId)
    }, options);
  }

  confirmAuthChannel$(licenseId: number, code: string): Observable<WebhookChannel | any> {
    return this.slackWebhooksApiService.confirmAuthChannel$(licenseId, code).pipe(
      tap(channel => {
        this.addItems$([channel]);
        this.slackWebhookChannelsDataService.addItemsToListById$('Licenses', licenseId, [channel]);
      })
    );
  }

  deleteChannel$(channelId: number): Observable<MadCloudResult> {
    return this.slackWebhooksApiService.deleteChannel$(channelId).pipe(
      tap(() => {
        this.removeItems$([channelId]);
      })
    );
  }

}
