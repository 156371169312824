import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { extname } from '@common/util/path';
import { MaxAvatarImageFileSizeBytes } from '@portal-core/data/common/constants/max-avatar-image-file-size.constant';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { FileWithPath } from '@portal-core/general/classes/file-with-path';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { Observable } from 'rxjs';

export interface UnpackedFile {
  file: File;
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private fileSizeService: FileSizeService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) { }

  getFileExtension(filePath: string): string {
    return extname(filePath);
  }

  isOfType(file: File, fileTypes: string | string[], allowMissingType: boolean = true): boolean {
    if (!Array.isArray(fileTypes)) {
      fileTypes = [fileTypes];
    }

    if (allowMissingType && !file.type) {
      return true;
    }

    // Allows '*' char to allow types from input element accept attribute
    return file.type && fileTypes.some(fileType => file.type.includes(fileType.endsWith('*') ? fileType.slice(0, -1) : fileType));
  }

  hasExtension(file: File, fileExtensions: string | string[]): boolean {
    if (!Array.isArray(fileExtensions)) {
      fileExtensions = [fileExtensions];
    }

    const fileName = file.name?.toLowerCase();
    return fileName && fileExtensions.some(fileExtension => fileName.endsWith(`.${fileExtension}`));
  }

  isSmallerOrEqualTo(file: File, fileSizeBytes: number): boolean {
    return file.size <= fileSizeBytes;
  }

  readAsDataURL$(file: File): Observable<string> {
    return new Observable(observer => {
      try {
        // Make sure the file exists
        if (!file) {
          throw new Error('File does not exist.');
        }

        // Read the file data
        const reader = new FileReader();
        reader.onloadend = function () {
          observer.next(reader.result as string);
          observer.complete();
        };

        reader.readAsDataURL(file);
      } catch (ex) {
        observer.error(ex);
        observer.complete();
      }
    });
  }

  readFilesAsBase64(files: FileList | File[]): Promise<string[]> {
    const fileContents: Promise<string>[] = Array.from(files).map(file => {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = () => {
          let content = reader.result as string;
          // Remove header
          const parsedContent = /base64,(.+)/.exec(content);
          resolve(Array.isArray(parsedContent) && parsedContent.length === 2 ? parsedContent[1] : '');
        };
        reader.onerror = () => {
          // Throw unsupported file error
        }
        reader.readAsDataURL(file);
      });
    });

    return Promise.all(fileContents);
  }

  /**
   * Returns an Observable of an object containing the image to upload and the path as a data url for display purposes.
   * Note that the observable completes after one value is returned.
   */
  unpackImage$(imageFile: File, maxFileSize?: number, supportedExtensions?: string[]): Observable<UnpackedFile> {
    return new Observable(observer => {
      try {
        // Make sure the file exists
        if (!imageFile) {
          throw new Error('File does not exist.');
        }

        // Check for the file size constraints
        if (typeof maxFileSize === 'number' && imageFile.size > maxFileSize) {
          throw new Error(`File must be smaller than ${this.fileSizeService.format(maxFileSize, 0)}`);
        }

        // Make sure the file is an image
        if (!imageFile.type.includes('image')) {
          throw new Error('File must be an image.');
        }

        if (Array.isArray(supportedExtensions) && !this.hasExtension(imageFile, supportedExtensions)) {
          throw new Error(`File type must be one of the following: ${supportedExtensions.map(ext => '.' + ext).join(', ')}.`)
        }

        // Read the file data
        const reader = new FileReader();
        reader.onloadend = function () {
          const result: UnpackedFile = {
            file: imageFile,
            path: reader.result as string
          };

          observer.next(result);
          observer.complete();
        };

        reader.readAsDataURL(imageFile);
      } catch (ex) {
        observer.error(ex);
        observer.complete();
      }
    });
  }

  /**
   * Returns an Observable of an object containing the image to upload and the path as a data url for display purposes.
   * Note that the observable completes after one value is returned.
   * Uses the default max file size for an avatar image when validating the image.
   */
  unpackAvatarImage$(imageFile: File): Observable<UnpackedFile> {
    return this.unpackImage$(imageFile, MaxAvatarImageFileSizeBytes);
  }

  exportToCsv(fileName: string, rows: object[]) {
    const csvContent = this.generateCsvFileContent(rows);
    this.downloadData(csvContent, 'text/csv;charset=utf-8;', fileName);
  }

  private generateCsvFileContent(rows: object[], separator: string = ','): string {
    try {
      if (!Array.isArray(rows) || rows.length === 0) {
        return '';
      }
      const headers = Object.keys(rows[0]);
      return `${headers.join(separator)}\n${rows.map(row => {
        return headers.map(key => {
          let cell = row[key] ?? '';
          if (cell instanceof Date) {
            cell = cell.toLocaleString();
          }
          // Creating matching double quotes
          cell = cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            // Wrap in quotations if it contains csv specific characters
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n')}`;
    } catch (error) {
      this.handleError(error);
    }
  }
  downloadData(data: any, contentType: string, fileName: string) {
    try {
      const blob = new Blob([data], { type: contentType });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      this.handleError(error);
    }
  }

  downloadZip(data: any, fileName: string) {
    try {
      const link = document.createElement('a');
      link.href = decodeURI(data);
      link.download = fileName;
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: 'Error Downloading File',
        message: 'An unexpected error happened while downloading the file',
        errors: this.errorService.getErrorMessages(error)
      }
    });
  }

  getFilesFromDrop(itemList: DataTransferItemList): Promise<FileWithPath[]> {
    const entries: FileSystemEntry[] = Array.from(itemList)
      .filter(item => item.kind === 'file')
      .map(item => item.webkitGetAsEntry());

    return new Promise((resolve, reject) => {
      Promise.all(this.getFilesFromEntries(entries)).then(nestedFiles => {
        // Flatten the nested arrays
        resolve(nestedFiles.flat(Infinity) as FileWithPath[]);
      }, (error: DOMException) => {
        this.handleError(error);
        reject(error);
      });
    })
  }

  private getFilesFromEntries(entries: FileSystemEntry[]): Promise<any/*Can be nested arrays*/>[] {
    return entries.map(entry => {
      return new Promise((resolve, reject) => {
        if (entry.isFile) {
          resolve(this.getFileFromEntry((entry as FileSystemFileEntry)));
        } else if (entry.isDirectory) {
          this.getEntriesFromDirectory((entry as FileSystemDirectoryEntry).createReader()).then(subDirectoryEntries => {
            resolve(Promise.all(this.getFilesFromEntries(subDirectoryEntries)));
          }, reject);
        }
      });
    });
  }

  private getEntriesFromDirectory(directoryReader: FileSystemDirectoryReader, fileEntries: FileSystemEntry[] = []): Promise<FileSystemEntry[]> {
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(newEntries => {
        fileEntries.push(...newEntries);
        // Reads up to 100 files at a time, try calling the read again if limit reached
        // Limited by Chrome to 100 https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries
        if (newEntries.length === 100) {
          resolve(this.getEntriesFromDirectory(directoryReader, fileEntries));
        } else {
          resolve(fileEntries);
        }
      }, reject);
    });
  }

  private getFileFromEntry(fileEntry: FileSystemFileEntry): Promise<FileWithPath> {
    return new Promise((resolve, reject) => {
      fileEntry.file(file => {
        // Grab the relative path only
        const relativePath = fileEntry.fullPath.substring(1, fileEntry.fullPath.lastIndexOf(fileEntry.name));
        resolve(this.createFileWithPath(file, file.name, relativePath));
      }, reject);
    });
  }

  createFileWithPath(file: File, name?: string, path: string = ''): FileWithPath {
    if (file) {
      return new FileWithPath(
        [file],
        name ?? file.name,
        {
          lastModified: file.lastModified,
          type: file.type
        },
        path
      );
    }
  }

  /**
   * Convert base64 image to file
   * @param base64Image Base64 image data to convert into the file
   */
  convertBase64ImageToFile(base64Image: string): File {
    // Split into content type and content
    const parts = base64Image.match('data:(image/(.+));base64,(.*)');
    if (parts.length !== 4) {
      return null;
    }
    const imageType = parts[1];
    const extension = parts[2];

    // Decode Base64 string
    const decodedData = atob(parts[3]);

    // Create btye array of size same as data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return file image after conversion
    return new File([uInt8Array], `image.${extension}`, { type: imageType });
  }
}
