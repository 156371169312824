import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { SiteProfileDialogComponent } from '@portal-core/sites/components/site-profile-dialog/site-profile-dialog.component';
import { Site } from '@portal-core/sites/models/site.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-site-avatar',
  templateUrl: './site-avatar.component.html',
  styleUrls: ['./site-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SiteAvatarComponent extends AvatarBase implements OnInit {
  @Input() site: Site;

  currentUserIsAuthor: boolean = false;
  userCanManageSites: boolean = false;
  private isAuthorSubscription: Subscription;
  private userCanManageSitesSubscription: Subscription;

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private currentService: CurrentService, private permissionsService: PermissionsService) {
    super();
  }

  ngOnInit() {
    this.isAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      this.currentUserIsAuthor = isAuthor;
      this.cdr.markForCheck();
    });

    this.userCanManageSitesSubscription = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageSites).subscribe(userCanManageSites => {
      this.userCanManageSites = userCanManageSites;
      this.cdr.markForCheck();
    });
  }

  onAvatarClicked() {
    this.openSiteProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasAvatarSVG(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return this.currentUserIsAuthor && this.userCanManageSites;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.site;
  }

  isLink(): boolean {
    // Only authors can view sites and only users with permission can open the settings dialog
    return super.isLink() && this.currentUserIsAuthor && this.userCanManageSites;
  }

  private openSiteProfileDialog() {
    this.dialog.open(SiteProfileDialogComponent, {
      ...SiteProfileDialogComponent.DialogConfig,
      data: {
        siteId: this.site.Id
      }
    });
  }
}
