import { Component, ViewEncapsulation, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Component({
  selector: 'mc-task-comment-icon',
  templateUrl: './task-comment-icon.component.html',
  styleUrls: ['./task-comment-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskCommentIconComponent implements OnInit, OnChanges {

  @Input() count: number;
  @Input() size = 'normal';
  @Input() displayParenthesis: boolean;
  sizeClass: string;
  constructor() { }

  ngOnInit(): void {
    this.sizeClass = `mc-task-comment-icon-${this.size}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['size']) {
      this.sizeClass = `mc-task-comment-icon-${this.size}`;
    }
  }
}
