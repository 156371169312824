import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { GridDateRangeModule } from '@portal-core/ui/grid/components/grid-date-range/grid-date-range.module';
import { GridHeaderCellModule } from '@portal-core/ui/grid/components/grid-header-cell/grid-header-cell.module';
import { GridRowsRangeModule } from '@portal-core/ui/grid/components/grid-rows-range/grid-rows-range.module';
import { IndeterminatePaginatorModule } from '@portal-core/ui/grid/components/indeterminate-paginator/indeterminate-paginator.module';
import { ContextMenuItemsModule } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.module';
import { EmptyGridModule } from '@portal-core/ui/grid/directives/empty-grid/empty-grid.module';
import { ExpandedRowDetailModule } from '@portal-core/ui/grid/directives/expanded-row-detail/expanded-row-detail.module';
import { GridCellModule } from '@portal-core/ui/grid/directives/grid-cell/grid-cell.module';
import { GridHeaderMenuModule } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.module';
import { HelpGridModule } from '@portal-core/ui/grid/directives/help-grid/help-grid.module';
import { LazyRenderModule } from '@portal-core/ui/lazy-render/directives/lazy-render/lazy-render.module';
import { GridComponent } from './grid.component';

@NgModule({
  imports: [
    CommonModule,
    ContextMenuItemsModule,
    EmptyGridModule,
    HelpGridModule,
    ErrorsExpansionPanelModule,
    ExpandedRowDetailModule,
    GridCellModule,
    GridDateRangeModule,
    GridRowsRangeModule,
    GridHeaderCellModule,
    GridHeaderMenuModule,
    IndeterminatePaginatorModule,
    LazyRenderModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PreventDefaultModule,
    StopPropagationModule
  ],
  declarations: [
    GridComponent
  ],
  exports: [
    GridComponent
  ]
})
export class GridComponentModule { }
