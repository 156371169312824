import { Component, EventEmitter, Input, Output, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CommentBoxComponent } from '@portal-core/tasks/components/comment-box/comment-box.component';
import { TaskCommentGroupComponent } from '@portal-core/tasks/components/task-comment-group/task-comment-group.component';
import { TaskCommentGroup } from '@portal-core/tasks/models/task-comment-group.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';

@Component({
  selector: 'mc-task-profile-dialog-discussion',
  templateUrl: './task-profile-dialog-discussion.component.html',
  styleUrls: ['./task-profile-dialog-discussion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskProfileDialogDiscussionComponent {
  @ViewChild(CommentBoxComponent) commentBox: CommentBoxComponent;
  @ViewChildren(TaskCommentGroupComponent) taskGroups: TaskCommentGroupComponent[];
  @Input() commentGroups: TaskCommentGroup[];
  @Input() newComment: TaskComment;
  @Input() canEditAllTasks: boolean;
  @Input() licenseId: number;
  @Input() showDelete: boolean;
  @Output() isDirtyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isDirty: boolean = false;

  isTabInitialized: boolean;

  onCommentGroupDirtyChanged() {
    this.isDirty = (this.commentBox
      ? this.commentBox.isDirty
      : false)
      || (this.taskGroups
        ? this.taskGroups.some(t => t.isDirty)
        : false);
    this.isDirtyChanged.emit(this.isDirty);
  }
}
