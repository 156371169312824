import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { ReviewPackageAvatarTooltipModule } from '@portal-core/reviews/review-packages/pipes/review-package-avatar-tooltip/review-package-avatar-tooltip.module';
import { ReviewPackageColorClassModule } from '@portal-core/reviews/review-packages/pipes/review-package-color-class/review-package-color-class.module';
import { ReviewPackageLinkModule } from '@portal-core/reviews/review-packages/pipes/review-package-link/review-package-link.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { ReviewPackageAvatarComponent } from './review-package-avatar.component';

@NgModule({
  declarations: [ReviewPackageAvatarComponent],
  imports: [
    CommonModule,
    InlineButtonModule,
    MatIconModule,
    MatTooltipModule,
    ReviewPackageAvatarTooltipModule,
    ReviewPackageColorClassModule,
    ReviewPackageLinkModule,
    RouterModule
  ],
  exports: [ReviewPackageAvatarComponent]
})
export class ReviewPackageAvatarModule { }
