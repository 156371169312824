import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { SelectionListItem } from '@portal-core/general/models/selection-list-item';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, combineLatest, first, map, tap } from 'rxjs';

export enum TeamAccessFormTab {
  Users = 0,
  Projects,
  Sites
}

interface LicenseUserItem extends SelectionListItem<string> {
  LicenseUser: LicenseUser;
}

interface ProjectItem extends SelectionListItem {
  Project: Project;
}

interface SiteItem extends SelectionListItem {
  Site: Site;
}

@Component({
  selector: 'mc-team-access-form',
  templateUrl: './team-access-form.component.html',
  styleUrls: ['./team-access-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamAccessFormComponent implements OnInit {
  @Input() selectedTabIndex?: TeamAccessFormTab;
  @Input() team: Team;
  @Input() teamProjects: Project[];
  @Input() teamLicenseUsers: LicenseUser[];
  @Input() teamSites: Site[];

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    // Using this.editing isn't entirely accurate but is close enough
    return this.editing;
  }

  ProjectStatus: typeof ProjectStatus = ProjectStatus;

  loadingState: LoadingState<string> = new LoadingState<string>();
  savingState: LoadingState<string> = new LoadingState<string>();

  canEdit$: Observable<boolean>;
  editing: boolean = false;

  allLicenseUsers: LicenseUserItem[] = [];
  allLicenseProjects: ProjectItem[] = [];
  allLicenseSites: SiteItem[] = [];

  licenseUsers: LicenseUser[];
  projects: Project[];
  sites: Site[];

  constructor(
    private errorService: ErrorService,
    private licenseUsersService: LicenseUsersService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private sitesService: SitesService,
    private teamsService: TeamsService,
    private pageFilterService: PageFilterService
  ) { }

  ngOnInit() {
    this.canEdit$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);
  }

  onSubmit() {
    this.savingState.update(true);

    const team: Team = {
      Id: this.team.Id,
      TeamUserIds: this.allLicenseUsers.filter(licenseUser => licenseUser.Selected).map(selectedUser => selectedUser.Value),
      TeamProjectIds: this.allLicenseProjects.filter(project => project.Selected).map(selectedProject => selectedProject.Value),
      TeamSiteIds: this.allLicenseSites.filter(site => site.Selected).map(selectedSite => selectedSite.Value)
    };

    this.teamsService.updateTeamUsersProjectsAndSites$(this.team.LicenseId, team).subscribe(() => {
      this.savingState.update(false);
      this.editing = false;
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'There was an error saving the team.', this.errorService.getErrorMessages(error));
    });
  }

  onEditClicked() {
    this.editing = true;
    this.loadLicenseUsersTeamsAndSites();
  }

  onCancelClicked() {
    this.editing = false;

    // Remove any save errors now that we are leaving the editing mode
    this.savingState.update(false);
  }

  protected loadLicenseUsersTeamsAndSites() {
    this.loadingState.update(true);

    // Build a filter for the users that includes users that are active or invited
    const usersFilter: PageFilter = {
      ...this.pageFilterService.create({
        Id: 'license-users',
        Type: PageFilterGroupType.Custom
      }).select('Status', [LicenseUserStatus.Active, LicenseUserStatus.Invited]).value,
      PageNumber: 0,
      PerPage: -1
    };

    // Build a filter for the projects that includes projects that are active/locked or are directly associated with this team
    const projectsFilter: PageFilter = {
      ...this.pageFilterService.create({
        Id: 'license-projects',
        Type: PageFilterGroupType.Custom
      }).custom({
        Id: 'projects',
        Type: PageFilterGroupType.Custom,
        Operator: PageFilterOperator.Or,
        Filters: [{
          FilterType: PageFilterType.Equals,
          PropertyName: 'TeamId',
          PropertyType: PageDataType.ProjectTeams,
          PropertyValue: this.team.Id
        }, {
          FilterType: PageFilterType.Equals,
          PropertyName: 'Status',
          PropertyType: PageDataType.Select,
          PropertyValue: ProjectStatus.Active
        }, {
          FilterType: PageFilterType.Equals,
          PropertyName: 'Status',
          PropertyType: PageDataType.Select,
          PropertyValue: ProjectStatus.Locked
        }]
      }).value,
      OrderBy: 'Name',
      OrderDirection: 'asc',
      PageNumber: 0,
      PerPage: -1
    };

    combineLatest([
      this.licenseUsersService.getLicenseUsersPageByLicenseId$(this.team.LicenseId, usersFilter).pipe(
        map(page => {
          if (Array.isArray(page.Items)) {
            return page.Items.sort((licenseUserA, licenseUserB) => licenseUserA.User.FullName.localeCompare(licenseUserB.User.FullName));
          } else {
            return page.Items;
          }
        }),
        tap(licenseUsers => this.licenseUsersService.addItems$(licenseUsers))
      ),
      this.projectsService.getProjectsPageByLicenseId$(this.team.LicenseId, projectsFilter, 0).pipe(
        map(page => page.Items),
        tap(projects => this.projectsService.addItems$(projects))
      ),
      this.sitesService.getSitesByLicenseId$(this.team.LicenseId, { forceApiRequest: true }).pipe(
        first(sites => !!sites)
      )
    ]).subscribe(([licenseUsers, projects, sites]) => {
      this.licenseUsers = licenseUsers;
      this.sites = sites;
      this.projects = projects;

      this.allLicenseUsers = this.licenseUsers.map(licenseUser => {
        return {
          LicenseUser: licenseUser,
          Selected: !!this.teamLicenseUsers.find(teamLicenseUser => teamLicenseUser.User.Id === licenseUser.User.Id),
          Value: licenseUser.User.Id
        };
      });

      this.allLicenseProjects = this.projects.map(project => {
        return {
          Project: project,
          Selected: !!this.teamProjects.find(teamProject => teamProject.Id === project.Id),
          Value: project.Id
        };
      });

      this.allLicenseSites = this.sites.map(site => {
        return {
          Site: site,
          Selected: !!this.teamSites.find(teamSite => teamSite.Id === site.Id),
          Value: site.Id
        };
      });

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the users, projects, and sites.', this.errorService.getErrorMessages(error));
    });
  }
}
