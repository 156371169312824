import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { TeamProfileModule } from '@portal-core/teams/components/team-profile/team-profile.module';
import { DialogAutoFocusModule } from '@portal-core/ui/dialog/directives/dialog-auto-focus/dialog-auto-focus.module';
import { TeamProfileDialogComponent } from './team-profile-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DialogAutoFocusModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TeamProfileModule
  ],
  declarations: [
    TeamProfileDialogComponent
  ],
  exports: [
    TeamProfileDialogComponent
  ]
})

// This module should only be imported into @portal-core/dialogs/dialogs.module due to circular dependencies
export class TeamProfileDialogModule { }
