import { DeletedSpan, Span } from 'prosemirror-changeset';
import { ResolvedPos } from 'prosemirror-model';
import { depthInFirstChildOfFragment, depthInLastChildOfFragment } from '@common/prosemirror/model/fragment';

// Helper methods
export function spansOverlap (spans: Span[]): boolean {
  for (let i = 0; i < spans.length; i += 1) {
    const outerSpan = spans[i];

    for (let j = i + 1; j < spans.length; j += 1) {
      const innerSpan = spans[j];

      // if (outerSpan.from > innerSpan.from || outerSpan.to > innerSpan.from) {
      if (outerSpan.to > innerSpan.from) {
        return true;
      }
    }
  }

  return false;
}

interface Getter {
  get: () => any;
}

export function cachedGetter(name: string, getter: () => any): Getter {
  name = '_' + name;

  return {
    get() {
      return typeof this[name] !== 'undefined' ? this[name] : this[name] = getter.call(this);
    }
  };
}

// Add helper properties to Span
Object.defineProperties(Span.prototype, {
  isDelete: {
    get(): boolean {
      return this instanceof DeletedSpan;
    }
  },

  isInsert: {
    get(): boolean {
      return !this.isDelete;
    }
  },

  rangeSize: cachedGetter('rangeSize', function (): number {
    return this.to - this.from;
  }),

  // From
  '$from': {
    get(): ResolvedPos {
      return typeof this._$from !== 'undefined' ? this._$from : this._$from = this.srcState.doc.resolve(this.from);
    }
  },

  'fromDepth': {
    get(): number {
      return typeof this._fromDepth !== 'undefined' ? this._fromDepth : this._fromDepth = depthInFirstChildOfFragment(this.slice.content);
    }
  },

  'fromNode': {
    get(): Node {
      return typeof this._fromNode !== 'undefined' ? this._fromNode : this._fromNode = this.slice.content.firstChild;
    }
  },

  'fromDocNode': { // What exactly is this node?
    get(): Node {
      return typeof this._fromDocNode !== 'undefined' ? this._fromDocNode : this._fromDocNode = this.$from.node(this.$from.depth - this.fromDepth);
    }
  },

  'isDeletingEntireFromNode': {
    get(): boolean {
      return typeof this._isDeletingEntireFromNode !== 'undefined' ? this._isDeletingEntireFromNode
             : this._isDeletingEntireFromNode = (this.fromDocNode && this.fromNode && this.fromDocNode.content.size === this.fromNode.content.size);
    }
  },

  // To
  '$to': {
    get(): ResolvedPos {
      return typeof this._$to !== 'undefined' ? this._$to : this._$to = this.srcState.doc.resolve(this.to);
    }
  },

  'toDepth': {
    get(): number {
      return typeof this._toDepth !== 'undefined' ? this._toDepth : this._toDepth = depthInLastChildOfFragment(this.slice.content);
    }
  },

  'toNode': {
    get(): Node {
      return typeof this._toNode !== 'undefined' ? this._toNode : this._toNode = this.slice.content.lastChild;
    }
  },

  'toDocNode': { // What exactly is this node?
    get(): Node {
      return typeof this._toDocNode !== 'undefined' ? this._toDocNode : this._toDocNode = this.$to.node(this.$to.depth - this.toDepth);
    }
  },

  'isDeletingEntireToNode': {
    get(): boolean {
      return typeof this._isDeletingEntireToNode !== 'undefined' ? this._isDeletingEntireToNode
             : this._isDeletingEntireToNode = (this.toDocNode && this.toNode && this.toDocNode.content.size === this.toNode.content.size);
    }
  },

  /*
   * isJoin
   * hitting backspace between two nodes
   */
  'isJoin': cachedGetter('isJoin', function (): boolean {
    // The slice must have the same open start and end
    if (this.slice.openStart === this.slice.openEnd) {
      // There must be exactly two children
      if (this.slice.content.childCount === 2) {
        // The two children must be of the same type, not be text nodes, and be empty
        // if (this.fromNode.type === this.toNode.type && !this.fromNode.isText && this.fromNode.childCount === 0 && this.toNode.childCount === 0) {
        // if (this.fromNode.type === this.toNode.type && !this.fromNode.isInline && this.fromNode.childCount === 0 && this.toNode.childCount === 0) {
        // 	// Its a join!
        // 	return true;
        // }

        // The two children must be block nodes and be empty
        if (this.fromNode.isBlock && this.toNode.isBlock && this.fromNode.childCount === 0 && this.toNode.childCount === 0) {
          // Its a join!
          return true;
        }
      }
    }

    return false;
  }),

  'isPlaceholder': cachedGetter('isPlaceholder', function(): boolean {
    if (this.slice.content.childCount === 1 && this.slice.content.firstChild.childCount === 0 && this.slice.content.firstChild.type.name === 'mcCentralContainer') {
      return true;
    }
    return false;
  }),

  // isDeletionOfEmptyNodeByHittingBackspaceFromStartOfNextNodeOrDeletedSelf may be a more correct name for this
  'isEmptyJoin': cachedGetter('isEmptyJoin', function (): boolean {
    // The slice must have the same open start and end
    if (this.slice.openStart === this.slice.openEnd) {
      // There must be exactly one child
      if (this.slice.content.childCount === 1) {
        // if (this.fromNode === this.toNode) {
        // The child must not be a text node
        // if (!this.fromNode.isText) {
        if (!this.fromNode.isInline) {
          // The node immediately before and after the $from position must be different than the child
          if (this.$from.beforeNode !== this.fromNode && this.$from.afterNode !== this.fromNode) {
            return true;
          }
        }
        // }
      }
    }

    return false;
  })
});

// Re-export the same values as prosemirror-changeset
export {
  DeletedSpan,
  Span
};
