import { Inject, Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { basename, dirname, extname } from '@common/util/path';
import { CodeLang } from '@portal-core/code-editor/types/code-lang.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { FileService } from '@portal-core/general/services/file.service';
import { AllFileFilter, ConditionalTagSetFileFilter, EditableFileFilter, ProjectFileFilter, SnippetFileFilter, StylesheetsFileFilter, TargetFileFilter, TocFileFilter, TopicFileFilter, VariableSetFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFileTemplate } from '@portal-core/project-files/enums/project-file-template.enum';
import { ProjectFileType } from '@portal-core/project-files/enums/project-file-type.enum';
import { ProjectFileFilterType } from '@portal-core/project-files/enums/project-files-filter-type.enum';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { OpenAICompletion } from '@portal-core/project-files/models/open-ai/open-ai-completion.model';
import { OpenAIMessage } from '@portal-core/project-files/models/open-ai/open-ai-message.model';
import { ProjectFileInfo } from '@portal-core/project-files/models/project-file-info.model';
import { ProjectFileTemplateDropdownItem } from '@portal-core/project-files/models/project-file-template-dropdown-item.model';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { ProjectFilesApiService } from '@portal-core/project-files/services/project-files-api.service';
import { ProjectFilesDataService } from '@portal-core/project-files/services/project-files-data.service';
import BrandingStylesheet from '@portal-core/project-files/templates/Branding.flcss';
import ConditionalTagSet from '@portal-core/project-files/templates/ConditionTagSet.flcts';
import NewTopic from '@portal-core/project-files/templates/NewTopic.htm';
import Snippet from '@portal-core/project-files/templates/Snippet.flsnp';
import Target from '@portal-core/project-files/templates/Target.fltar';
import TopicForEndnotes from '@portal-core/project-files/templates/TopicForEndnotes.htm';
import TopicForGlossary from '@portal-core/project-files/templates/TopicForGlossary.htm';
import TopicForIndex from '@portal-core/project-files/templates/TopicForIndex.htm';
import TopicForListOfConcepts from '@portal-core/project-files/templates/TopicForListOfConcepts.htm';
import TopicForListOfElements from '@portal-core/project-files/templates/TopicForListOfElements.htm';
import TopicForListOfImages from '@portal-core/project-files/templates/TopicForListOfImages.htm';
import TopicForListOfTables from '@portal-core/project-files/templates/TopicForListOfTables.htm';
import TopicForMiniTOC from '@portal-core/project-files/templates/TopicForMiniTOC.htm';
import TopicForTOC from '@portal-core/project-files/templates/TopicForTOC.htm';
import VariableSet from '@portal-core/project-files/templates/VariableSet.flvar';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { WINDOW } from '@portal-core/util/window.provider';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ProjectFilesService extends CollectionServiceBase<ProjectFile> {
  fileTypes: Dictionary<ProjectFileInfo> = {};

  constructor(private projectFilesDataService: ProjectFilesDataService, private projectFilesApiService: ProjectFilesApiService, private fileService: FileService, private errorService: ErrorService, private snackBar: MatSnackBar, protected dataService: DataService, @Inject(WINDOW) private window: Window) {
    super(projectFilesDataService, dataService);
    this.fileTypes[ProjectFileFilterType.All] = { type: ProjectFileFilterType.All, displayText: 'All Files', newFileDisplayText: 'Other', restrictedToFolder: ProjectFolder.Root, defaultFolder: ProjectFolder.Root, filter: AllFileFilter, extensions: [''], };
    this.fileTypes[ProjectFileFilterType.Content] = { type: ProjectFileFilterType.Content, displayText: 'Content Files', newFileDisplayText: 'Content File', restrictedToFolder: ProjectFolder.Content, defaultFolder: ProjectFolder.Content, filter: AllFileFilter, extensions: [''] };
    this.fileTypes[ProjectFileFilterType.Project] = { type: ProjectFileFilterType.Project, displayText: 'Project Files', newFileDisplayText: 'Project File', restrictedToFolder: ProjectFolder.Projects, defaultFolder: ProjectFolder.Projects, filter: ProjectFileFilter, extensions: [''] };
    this.fileTypes[ProjectFileFilterType.Topic] = { type: ProjectFileFilterType.Topic, displayText: 'Topics', newFileDisplayText: 'Topic', restrictedToFolder: ProjectFolder.Content, filter: TopicFileFilter, defaultFolder: ProjectFolder.Topics, extensions: ['.htm', '.html', '.xhtml'] };
    this.fileTypes[ProjectFileFilterType.Snippet] = { type: ProjectFileFilterType.Snippet, displayText: 'Snippets', newFileDisplayText: 'Snippet', restrictedToFolder: ProjectFolder.Content, defaultFolder: ProjectFolder.Snippets, filter: SnippetFileFilter, extensions: ['.flsnp', '.flmsp'] };
    this.fileTypes[ProjectFileFilterType.ConditionTagSet] = { type: ProjectFileFilterType.ConditionTagSet, displayText: 'Condition Tag Sets', newFileDisplayText: 'Condition Tag Set', restrictedToFolder: ProjectFolder.ConditionTagSets, defaultFolder: ProjectFolder.ConditionTagSets, filter: ConditionalTagSetFileFilter, extensions: ['.flcts'] };
    this.fileTypes[ProjectFileFilterType.VariableSet] = { type: ProjectFileFilterType.VariableSet, displayText: 'Variable Sets', newFileDisplayText: 'Variable Set', restrictedToFolder: ProjectFolder.VariableSets, defaultFolder: ProjectFolder.VariableSets, filter: VariableSetFileFilter, extensions: ['.flvar', '.mcvar'] };
    this.fileTypes[ProjectFileFilterType.Target] = { type: ProjectFileFilterType.Target, displayText: 'Targets', newFileDisplayText: 'Target', restrictedToFolder: ProjectFolder.Targets, defaultFolder: ProjectFolder.Targets, filter: TargetFileFilter, extensions: ['.fltar', '.flbat'] };
    this.fileTypes[ProjectFileFilterType.Toc] = { type: ProjectFileFilterType.Toc, displayText: 'TOCs', newFileDisplayText: 'TOC', restrictedToFolder: ProjectFolder.TOCs, defaultFolder: ProjectFolder.TOCs, filter: TocFileFilter, extensions: ['.fltoc'] };
    this.fileTypes[ProjectFileFilterType.EditableFiles] = { type: ProjectFileFilterType.EditableFiles, displayText: '-', newFileDisplayText: 'Other', restrictedToFolder: ProjectFolder.Root, defaultFolder: ProjectFolder.Root, filter: EditableFileFilter, extensions: [''] };
    this.fileTypes[ProjectFileFilterType.Stylesheet] = { type: ProjectFileFilterType.Stylesheet, displayText: 'Stylesheets', newFileDisplayText: 'Branding Stylesheet', restrictedToFolder: ProjectFolder.Content, defaultFolder: ProjectFolder.BrandingStylesheet, filter: StylesheetsFileFilter, extensions: ['.css'] };
  }

  getProjectFileTree$(projectId: number, branchName: string, treePath: string = '', includeFiles: boolean = false, fileFilter: string = '.*'): Observable<ProjectFile[]> {
    return this.projectFilesApiService.getProjectFileTree$(projectId, branchName, treePath, includeFiles, fileFilter);
  }

  getProjectFile$(projectId: number, filePath: string, branchName: string): Observable<ProjectFile> {
    return this.projectFilesApiService.getProjectFileByPath$(projectId, filePath, branchName);
  }

  getOpenAIResponse$(projectId: number, messages: OpenAIMessage[]): Observable<OpenAICompletion> {
    return this.projectFilesApiService.getOpenAIResponse$(projectId, messages);
  }

  getFirstNodeText$(projectId: number, filePath: string, branchName: string): Observable<string> {
    return this.projectFilesApiService.getFirstNodeTextByPath$(projectId, filePath, branchName);
  }

  /**
   * Get whether the project file or folder already exists in the project.
   * This function does not get the file content from git it just check whether the path exists.
   * You can check the type of existing file.
   * @param projectId The project id where to check the path.
   * @param filePath The full file path to check.
   * @param branchName The branch name where to check the path.
   * @returns The project file without its content if found, otherwise null.
  */
  isProjectFileExists$(projectId: number, filePath: string, branchName: string): Observable<ProjectFile> {
    const name = basename(filePath).toLowerCase();
    const folder = dirname(filePath);
    const filter = folder ? '/' : '' + name + '$';
    return this.getProjectFileTree$(projectId, branchName, folder, true, filter).pipe(
      // in case folder does not exist
      catchError(() => of(null)),
      map(projectFiles => {
        if (!Array.isArray(projectFiles)) return null;
        // the server doesn't filter folders, so we should check folders as well
        return projectFiles.find(file => file.Name.toLowerCase() === name);
      }));
  }

  isProjectFlareDataFileType(fileType: ProjectFileType): boolean {
    switch (fileType) {
      case ProjectFileType.ConditionTagSet:
      case ProjectFileType.VariableSet:
      case ProjectFileType.Target:
        return true;
      default:
        return false;
    }
  }

  getFileType(filePath: string): ProjectFileType {
    switch (this.fileService.getFileExtension(filePath).toLowerCase()) {
      case '.bmp':
      case '.gif':
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.tif':
      case '.tiff':
        return ProjectFileType.Image;
      case '.flmsp':
      case '.flsnp':
      case '.htm':
      case '.html':
        return ProjectFileType.Flare;
      case '.flcts':
        return ProjectFileType.ConditionTagSet;
      case '.flvar':
        return ProjectFileType.VariableSet;
      case '.fltar':
        return ProjectFileType.Target;
      case '.css':
        return ProjectFileType.Stylesheet;
      case '.pdf':
      case '.swf':
      case '.wmv':
      case '.docx':
      case '.xlsx':
      case '.ttf':
      case '.zip':
      case '.mp3':
      case '.mpg':
      case '.webm':
      case '.hdp':
      case '.eps':
      case '.wdp':
      case '.emf':
      case '.xps':
      case '.cur':
      case '.dib':
      case '.exps':
      case '.wmf':
      case '.u3d':
      case '.webp':
      case '.flv':
      case '.mov':
      case '.mp4':
      case '.avi':
      case '.wma':
      case '.qt':
      case '.oga':
      case '.ogg':
      case '.ogv':
      case '.spx':
      case '.mpe':
      case '.jpe':
      case '.doc':
      case '.xls':
      case '.idml':
      case '.ppt':
      case '.pptx':
      case '.flprjzip':
      case '.exe':
      case '.chm':
      case '.asf':
      case '.mpa':
      case '.d2h':
      case '.book':
      case '.fm':
      case '.lirev':
      case '.fltrev':
      case '.mcco':
      case '.mccot':
      case '.mcdoc':
      case '.mcdoct':
      case '.fprjzip':
      case '.asx':
      case '.asf':
      case '.au':
      case '.m4v':
      case '.mid':
      case '.midi':
      case '.mpa':
      case '.mpeg':
      case '.opus':
      case '.wav':

        return ProjectFileType.Binary;
      default:
        return ProjectFileType.Code;
    }
  }


  getAllFileTypesForCreating(): ProjectFileInfo[] {
    return [
      this.fileTypes[ProjectFileFilterType.Topic],
      this.fileTypes[ProjectFileFilterType.Snippet],
      this.fileTypes[ProjectFileFilterType.Target],
      this.fileTypes[ProjectFileFilterType.VariableSet],
      this.fileTypes[ProjectFileFilterType.ConditionTagSet],
      this.fileTypes[ProjectFileFilterType.Stylesheet],
      this.fileTypes[ProjectFileFilterType.EditableFiles]
    ];
  }

  getFileTemplateFiles(fileType: ProjectFileFilterType): ProjectFileTemplateDropdownItem[] {
    switch (fileType) {
      case ProjectFileFilterType.Topic:
        return [
          { visibleName: 'New Topic', type: ProjectFileTemplate.NewTopic },
          { visibleName: 'Topic For Endnotes', type: ProjectFileTemplate.TopicForEndnotes },
          { visibleName: 'Topic For Glossary', type: ProjectFileTemplate.TopicForGlossary },
          { visibleName: 'Topic For Index', type: ProjectFileTemplate.TopicForIndex },
          { visibleName: 'Topic For List Of Concepts', type: ProjectFileTemplate.TopicForListOfConcepts },
          { visibleName: 'Topic For List Of Elements', type: ProjectFileTemplate.TopicForListOfElements },
          { visibleName: 'Topic For List Of Images', type: ProjectFileTemplate.TopicForListOfImages },
          { visibleName: 'Topic For List Of Tables', type: ProjectFileTemplate.TopicForListOfTables },
          { visibleName: 'Topic For Mini TOC', type: ProjectFileTemplate.TopicForMiniTOC },
          { visibleName: 'Topic For TOC', type: ProjectFileTemplate.TopicForTOC }
        ];
      case ProjectFileFilterType.Snippet:
        return [{ visibleName: 'Snippet', type: ProjectFileTemplate.Snippet }];
      case ProjectFileFilterType.ConditionTagSet:
        return [{ visibleName: 'Condition Tag Set', type: ProjectFileTemplate.ConditionTagSet }];
      case ProjectFileFilterType.VariableSet:
        return [{ visibleName: 'Variable Set', type: ProjectFileTemplate.VariableSet }];
      case ProjectFileFilterType.Target:
        return [{ visibleName: 'Target', type: ProjectFileTemplate.Target }];
      case ProjectFileFilterType.Stylesheet:
        return [{ visibleName: 'Branding Stylesheet', type: ProjectFileTemplate.BrandingStylesheet }];
      default:
        return [{ visibleName: 'No Template', type: ProjectFileTemplate.Empty }];
    }
  }

  getFilterTypeForCreatingByFolder(filePath: string): ProjectFileFilterType {
    if (!filePath) return null;
    filePath = filePath.trim().toLowerCase();
    if (!filePath?.endsWith('/'))
      filePath = filePath + '/';
    const types = [
      this.fileTypes[ProjectFileFilterType.Stylesheet],
      this.fileTypes[ProjectFileFilterType.Snippet],
      this.fileTypes[ProjectFileFilterType.Topic],
      this.fileTypes[ProjectFileFilterType.ConditionTagSet],
      this.fileTypes[ProjectFileFilterType.VariableSet],
      this.fileTypes[ProjectFileFilterType.Target],
      this.fileTypes[ProjectFileFilterType.Toc],
    ]

    return types.find(filter => filePath.startsWith(filter.defaultFolder.toLowerCase() + '/'))?.type;
  }

  /**
  Get project file info by the file extension.
  */
  getProjectFileInfoByExtension(filePath: string): ProjectFileInfo {
    if (filePath) {
      const fileExt = this.fileService.getFileExtension(filePath).toLowerCase()
      for (let type in this.fileTypes) {
        const fileType = this.fileTypes[type];
        if (fileType.extensions.some(ext => ext === fileExt)) return fileType;
      }
    }
    return this.fileTypes[ProjectFileFilterType.All];
  }


  getFileCodeEditorLang(filePath: string): CodeLang {
    switch (this.fileService.getFileExtension(filePath).toLowerCase()) {
      case '.js':
        return 'javascript';
      case '.json':
        return 'json';
      case '.html':
      case '.htm':
        return 'html';
      case '.css':
        return 'css';
      case '.flaix':
      case '.flali':
      case '.flbrs':
      case '.flcts':
      case '.fldes':
      case '.flexp':
      case '.flfts':
      case '.flglo':
      case '.flimp':
      case '.flimpdita':
      case '.flimpfl':
      case '.flimpfm':
      case '.flimphtml':
      case '.flimpxls':
      case '.flixl':
      case '.flpgl':
      case '.flprj':
      case '.flrep':
      case '.flrtb':
      case '.flsfs':
      case '.flskn':
      case '.fltar':
      case '.fltbx':
      case '.fltoc':
      case '.flvar':
      case '.flmco':
      case '.flimpconf':
      case '.mcdic':
      case '.mcsyns':
      case '.mchyph':
      case '.props':
      case '.mchyph':
      case '.xml':
        return 'xml';
    }
  }

  getFileIconName(filePath: string): string {
    switch (this.fileService.getFileExtension(filePath).toLowerCase()) {
      case '.bmp':
      case '.gif':
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.tif':
      case '.tiff':
        return 'icon-picture';
      case '.pdf':
        return 'icon-pdficon';
      case '.flprj':
        return 'icon-flare';
      case '.flcts':
        return 'icon-condition-tree-view';
      case '.flvar':
        return 'icon-variable-tree-view';
      case '.fltar':
        return 'icon-reticle';
      case '.css':
        return 'icon-css';
      default:
        return 'icon-documenticon';
    }
  }

  getFileLineCount(fileContent: string): number {
    return typeof fileContent === 'string' ? fileContent.split(/\r\n|\r|\n/).length : 0;
  }

  openProjectFileRawPath(projectId: number, branchName: string, filePath: string) {
    const rawPath = this.getProjectFileRawPath(projectId, branchName, filePath);
    this.window.open(rawPath, '_blank');
  }

  getProjectFileRawPath(projectId: number, branchName: string, filePath: string): string {
    return this.projectFilesApiService.getProjectFileRawPath(projectId, branchName, filePath);
  }

  getFileTemplateHTML(newFileTemplate: ProjectFileTemplate): string {
    switch (newFileTemplate) {
      case ProjectFileTemplate.NewTopic:
        return NewTopic;
      case ProjectFileTemplate.TopicForEndnotes:
        return TopicForEndnotes;
      case ProjectFileTemplate.TopicForGlossary:
        return TopicForGlossary;
      case ProjectFileTemplate.TopicForIndex:
        return TopicForIndex;
      case ProjectFileTemplate.TopicForListOfConcepts:
        return TopicForListOfConcepts;
      case ProjectFileTemplate.TopicForListOfElements:
        return TopicForListOfElements;
      case ProjectFileTemplate.TopicForListOfImages:
        return TopicForListOfImages;
      case ProjectFileTemplate.TopicForListOfTables:
        return TopicForListOfTables;
      case ProjectFileTemplate.TopicForMiniTOC:
        return TopicForMiniTOC;
      case ProjectFileTemplate.TopicForTOC:
        return TopicForTOC;
      case ProjectFileTemplate.Snippet:
        return Snippet;
      case ProjectFileTemplate.ConditionTagSet:
        return ConditionalTagSet;
      case ProjectFileTemplate.VariableSet:
        return VariableSet;
      case ProjectFileTemplate.Target:
        return Target;
      case ProjectFileTemplate.BrandingStylesheet:
        return BrandingStylesheet;
      case ProjectFileTemplate.Empty:
      default:
        return '';
    }
  }

  /**
    Gets all file filters for the project files.
    */
  getAllFiltersForProject(): ProjectFileInfo[] {
    return [
      this.fileTypes[ProjectFileFilterType.All],
      this.fileTypes[ProjectFileFilterType.Content],
      this.fileTypes[ProjectFileFilterType.Project],
      this.fileTypes[ProjectFileFilterType.Toc],
      this.fileTypes[ProjectFileFilterType.Target],
      this.fileTypes[ProjectFileFilterType.VariableSet],
      this.fileTypes[ProjectFileFilterType.ConditionTagSet],
      this.fileTypes[ProjectFileFilterType.Stylesheet],
    ];
  }

  /**
  Makes a file path relative to project root folder of the corresponding file type and removes extension, e.g. 'Project/ConditionTagSets/Subfolder/Default.flcts' -> 'Subfolder/Default'
  */
  getProjectDataFileRelativePath(filePath: string): string {
    const restrictedToFolder: string = this.getProjectFileInfoByExtension(filePath).restrictedToFolder;
    const ext = extname(filePath);
    if (filePath.startsWith(restrictedToFolder + '/') && filePath.endsWith(ext))
      filePath = filePath.substring(restrictedToFolder.length + 1, filePath.length - ext.length);
    return filePath;
  }

  /**
  Gets whether the specified file path correspond to the given filter type.
  */
  validateFileType(filepath: string, type: ProjectFileFilterType): boolean {
    return this.getProjectFileInfoByExtension(filepath).type === type;
  }
}
