<div class="mc-task-profile-dialog-details-container">
  <form class="mc-task-profile-dialog-details-form" [formGroup]="detailsFormGroup">
    <div class="mc-task-profile-dialog-details-header-container">
      <div class="mc-task-profile-dialog-details-header-left">
        <div class="mc-task-profile-dialog-details-priority-container">
          <ng-container [ngSwitch]="task.TaskPriority">
            <mat-icon *ngSwitchCase="TaskPriority.Low" color="task-low-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'"></mat-icon>
            <mat-icon *ngSwitchCase="TaskPriority.Medium" color="task-medium-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'">
            </mat-icon>
            <mat-icon *ngSwitchCase="TaskPriority.High" color="task-high-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'"></mat-icon>
          </ng-container>
          <mat-form-field class="mc-task-profile-dialog-details-select-container" color="primary-vivid">
            <mat-select [(value)]="task.TaskPriority" [disabled]="!canEditAllTasks">
              <mat-option [value]="TaskPriority.Low">
                <mat-icon color="task-low-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'"></mat-icon>
                Low Priority
              </mat-option>
              <mat-option [value]="TaskPriority.Medium">
                <mat-icon color="task-medium-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'"></mat-icon>
                Medium Priority
              </mat-option>
              <mat-option [value]="TaskPriority.High">
                <mat-icon color="task-high-priority" [fontSet]="'project-titan'" [fontIcon]="'icon-dot'"></mat-icon>
                High Priority
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mc-task-profile-dialog-details-header-row mc-task-profile-dialog-details-status-container">
          <div class="mc-task-profile-dialog-details-title text-small">Status:</div>
          <mat-form-field class="mc-task-profile-dialog-details-select-container" color="primary-vivid">
            <mat-select [value]="task.Status === TaskStatus.Active ? task.Milestone : task.Status" [disabled]="!canEditAllTasks" (selectionChange)="onStatusChanged($event)">
              <mat-option [value]="TaskMilestone.ToDo" [ngClass]="{'mat-selected': task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.ToDo}">
                To Do
              </mat-option>
              <mat-option [value]="TaskMilestone.InProgress" [ngClass]="{'mat-selected': task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.InProgress}">
                In Progress
              </mat-option>
              <mat-option [value]="TaskMilestone.Completed" [ngClass]="{'mat-selected': task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.Completed}">
                Completed
              </mat-option>
              <mat-option [value]="TaskStatus.Backlog" [ngClass]="{'mat-selected': task.Status === TaskStatus.Backlog}">
                Backlog
              </mat-option>
              <mat-option [value]="TaskStatus.Archive" [ngClass]="{'mat-selected': task.Status === TaskStatus.Archive}">
                Archive
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mc-task-profile-dialog-details-comment-asset-container">
          <mc-task-comment-icon [count]="task.TaskCommentCount" [size]="'normal'" [displayParenthesis]="'true'"></mc-task-comment-icon>
          <mc-task-asset-icon [count]="task.TaskAssetCount" [size]="'normal'" [displayParenthesis]="'true'"></mc-task-asset-icon>
          <mat-form-field color="primary-vivid" class="mc-task-profile-dialog-details-est-pos-field">
            <input class="text-small" formControlName="estHour" matInput type="number" (change)="task.EstHour=+$event.target.value" min="0">
            <span class="text-small" matSuffix>hrs</span>
            <mat-error *ngIf="detailsFormGroup.get('estHour').errors?.undefined">Est hours is required</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('estHour').errors?.invalidNegative">Must be greater than or equal to zero</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('estHour').errors?.invalidMax">Must be less than 1000</mat-error>
          </mat-form-field>
          <mat-form-field color="primary-vivid" class="mc-task-profile-dialog-details-est-pos-field">
            <input class="text-small" formControlName="position" matInput type="number" (change)="task.Position=(+$event.target.value)-1" min="1">
            <span class="text-small" matSuffix>pos</span>
            <mat-error *ngIf="detailsFormGroup.get('position').errors?.undefined">Position is required</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('position').errors?.invalidNegative">Must be greater than zero</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('position').errors?.invalidMax">Position must be less than {{maxPosition+1}}</mat-error>
          </mat-form-field>
        </div>
        <div class="mc-task-profile-dialog-details-task-board-container">
          <div class="mc-task-profile-dialog-details-task-board-title text-small">Task Board:</div>
          <mat-form-field class="mc-task-profile-dialog-details-select-container mc-task-profile-dialog-details-task-board" color="primary-vivid">
            <mat-select [value]="task.TaskBoardId" [disabled]="!canEditAllTasks" (selectionChange)="task.TaskBoardId = $event.value">
              <mat-option *ngFor="let tb of taskBoards" [value]="tb.Id">{{tb.Title}}</mat-option>
            </mat-select>
            <div *ngIf="!task.TaskBoardId" class="mc-task-profile-dialog-details-task-board-error mc-text-warn">Task Board is required</div>
          </mat-form-field>
        </div>
        <div class="mc-task-profile-dialog-details-user-title">Owner:</div>
        <div class="mc-task-profile-dialog-details-user-body">
          <mc-license-user-avatar [licenseUser]="createdByUser$ | async" size="small" [disabledName]="true"></mc-license-user-avatar>
          <button *ngIf="canEditAllTasks && task.CreatedByUserId" type="button" class="mc-button-small mc-task-profile-dialog-details-add-button" mat-icon-button color="link"
            [matMenuTriggerFor]="ownerMenu">
            <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-expand'"></mat-icon>
          </button>
        </div>
        <div class="mc-task-profile-dialog-details-user-title">Assigned:</div>
        <div class="mc-task-profile-dialog-details-user-body">
          <mc-license-user-avatar *ngIf="task.AssignedUserId; else addUser" [licenseUser]="assignedUser$ | async" size="small" [disabledName]="true"></mc-license-user-avatar>
          <button *ngIf="canEditAllTasks && task.AssignedUserId" type="button" class="mc-button-small mc-task-profile-dialog-details-add-button" mat-icon-button color="link"
            [matMenuTriggerFor]="userMenu">
            <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-expand'"></mat-icon>
          </button>
        </div>
        <ng-template #addUser>
          <button *ngIf="canEditAllTasks" type="button" class="mc-task-profile-dialog-details-add-project-or-user-button" mat-icon-button color="link" [matMenuTriggerFor]="userMenu"
            [disabled]="!canEditAllTasks">
            <mat-icon class="mc-task-profile-dialog-details-project-add-folder-or-user-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-user'"></mat-icon>
          </button>
        </ng-template>
      </div>
      <div class="mc-task-profile-dialog-details-header-right">
        <div class="mc-task-profile-dialog-details-header-row">
          <div class="mc-task-profile-dialog-details-title text-small">Start:</div>
          <mat-form-field color="primary-vivid" class="mc-task-profile-dialog-details-date-form-field">
            <input formControlName="start" matInput [matDatepicker]="startPicker" (dateChange)="onStartDateValueChanged($event.value)">
            <mat-datepicker-toggle matSuffix [for]="startPicker">
            </mat-datepicker-toggle>
            <mat-datepicker color="primary-vivid" #startPicker></mat-datepicker>
            <mat-error *ngIf="detailsFormGroup.get('start').errors?.mismatch">Start Date is required</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('start').errors?.overlap">Cannot set after Due</mat-error>
          </mat-form-field>
          <input class="mc-task-profile-dialog-details-time text-small" #startTime type="time" formControlName="startTime" (blur)="onStartTimeValueChanged(startTime.value)">
        </div>
        <div class="mc-task-profile-dialog-details-header-row mc-task-profile-dialog-details-due-date">
          <div class="mc-task-profile-dialog-details-title text-small">Due:</div>
          <mat-form-field color="primary-vivid" class="mc-task-profile-dialog-details-date-form-field">
            <input formControlName="due" matInput [matDatepicker]="duePicker" (dateChange)="onDueDateValueChanged($event.value)">
            <mat-datepicker-toggle matSuffix [for]="duePicker">
            </mat-datepicker-toggle>
            <mat-datepicker color="primary-vivid" #duePicker></mat-datepicker>
            <mat-error *ngIf="detailsFormGroup.get('due').errors?.mismatch">Due Date is required</mat-error>
            <mat-error *ngIf="detailsFormGroup.get('due').errors?.overlap">Cannot set before Start</mat-error>
          </mat-form-field>
          <input class="mc-task-profile-dialog-details-time text-small" #dueTime type="time" formControlName="dueTime" (blur)="onDueTimeValueChanged(dueTime.value)">
        </div>
        <div class="mc-form-field">
          <mat-checkbox class="text-small text-light-weight" [checked]="task.AllDay" (change)="onAllDayChecked($event.checked)" [disabled]="!canEditAllTasks" color="primary-vivid">
            All Day Event
          </mat-checkbox>
        </div>
        <div class="mc-task-profile-dialog-details-project-title">Project:</div>
        <mc-project-avatar *ngIf="task.ProjectId; else addProject" class="mc-task-profile-dialog-details-project-avatar text-small" [disabledName]="true" [project]="project$ | async" size="small">
        </mc-project-avatar>
        <button *ngIf="canEditAllTasks && task.ProjectId" mat-icon-button type="button" color="link" [matMenuTriggerFor]="projectMenu">
          <mat-icon class="mc-task-profile-dialog-details-project-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-expand'"></mat-icon>
        </button>
        <ng-template #addProject>
          <button *ngIf="canEditAllTasks" type="button" class="mc-task-profile-dialog-details-add-project-or-user-button" mat-icon-button color="link" [matMenuTriggerFor]="projectMenu">
            <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-folder'"></mat-icon>
            <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-expand'" class="mc-task-profile-dialog-details-project-add-icon"></mat-icon>
          </button>
        </ng-template>
      </div>
    </div>
  </form>
  <div class="mc-task-profile-dialog-details-user-title">Description:</div>
  <mc-rich-text-editor *ngIf="canEditAllTasks" [content]="task.Description" (editorStateChange)="onEditorStateChange()"></mc-rich-text-editor>
  <mc-rich-text-block *ngIf="!canEditAllTasks" [content]="task.Description" [showBorder]="true"></mc-rich-text-block>
</div>

<mat-menu class="popup-card" #projectMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="onProjectClicked()">None</button>
  <button *ngFor="let project of projects" mat-menu-item (click)="onProjectClicked(project)">{{project.Name}}</button>
</mat-menu>
<mat-menu class="popup-card" #ownerMenu="matMenu" [overlapTrigger]="false">
  <button *ngFor="let licenseUser of licenseUsers" mat-menu-item (click)="onLicenseUserClicked(false, licenseUser)">{{licenseUser.User.FullName}}</button>
</mat-menu>
<mat-menu class="popup-card" #userMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="onLicenseUserClicked(true)">None</button>
  <button *ngFor="let licenseUser of licenseUsers" mat-menu-item (click)="onLicenseUserClicked(true, licenseUser)">{{licenseUser.User.FullName}}</button>
</mat-menu>
