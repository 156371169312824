
import { mergeFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { FoundNodeInfo } from '@common/prosemirror/model/node';
import { NodeType, ProseMirrorNode } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { Transform } from 'prosemirror-transform';

/**
 * Replaces the current selection in a ProseMirror transaction with a link node.
 * If `editLinkNode` is provided, it replaces the existing link node with the new one.
 * If not, it inserts a new link node at the current selection.
 * @param tr The ProseMirror transaction object.
 * @param linkType The node type for the link.
 * @param startPos The start position of the selection or edit location.
 * @param content The content of the link node.
 * @param attrs Additional attributes for the link node.
 * @param editLinkNode The existing link node to be edited (optional).
 * @returns The updated ProseMirror transaction with the edited or new link node.
 */
export function replaceSelectionWithLink(tr: Transaction, linkType: NodeType, startPos: number, content: string | ProseMirrorNode, attrs: Dictionary, editLinkNode?: ProseMirrorNode): Transaction {
  const newAttrs = mergeFlareNodeAttrs(editLinkNode?.attrs, attrs);
  const linkNode = createLinkNode(linkType, newAttrs, content);

  if (editLinkNode) {
    // edit link
    const start = startPos;
    const end = start + editLinkNode.nodeSize;
    // edit just attrs
    if ((typeof content === 'string' && content === editLinkNode.textContent) || typeof content !== 'string') {
      tr.setNodeMarkup(start, linkType, newAttrs);
    } else {
      //edit the whole thing
      tr.replaceWith(start, end, linkNode);
    }
  } else {
    //insert link
    tr.replaceSelectionWith(linkNode);
  }

  return tr;
}

/**
 * Creates a bookmark node in a ProseMirror transaction if a bookmark node is not already present at the specified position.
 * @param tr The ProseMirror transaction object.
 * @param bookmarkType The node type for the bookmark node.
 * @param foundBookmark Indicates whether a bookmark node is already present.
 * @param linkToNode Information about the node to which the bookmark is linked.
 * @param attrs Additional attributes for the bookmark node.
 * @returns The updated ProseMirror transaction with the new bookmark.
 */
export function createBookmark(tr: Transform, bookmarkType: NodeType, foundBookmark: boolean, linkToNode: FoundNodeInfo, attrs: Dictionary): Transform {
  if (!foundBookmark && linkToNode) {
    // should be in the container if its the first child.
    if (linkToNode.node.isBlock && linkToNode.node.firstChild?.type.name === 'mcCentralContainer') {
      tr.insert(linkToNode.pos + 2, bookmarkType.create({ name: attrs.href.slice(1) }));
    }
    // normal case
    else {
      tr.insert(linkToNode.pos + 1, bookmarkType.create({ name: attrs.href.slice(1) }));
    }
  }

  return tr;
}

/**
 * Creates a link node in ProseMirror with the specified attributes and content.
 * @param linkType The node type for the link node.
 * @param attrs Attributes for the link node.
 * @param content Content of the link node. Can be a string or a ProseMirrorNode.
 * @returns The new link node.
 */
export function createLinkNode(linkType: NodeType, attrs: Dictionary, content: string | ProseMirrorNode): ProseMirrorNode {
  let linkContent;

  // if its a string a text node is needed.
  if (typeof content === 'string') {
    linkContent = linkType.schema.text(content ? content : 'New Link');
  }
  // if it doesnt have content then its a special xml tag and we just need that
  else if (!content.firstChild) {
    linkContent = content;
  }
  // this gives the content from the selection, we dont need the top container because it already exists hence content.content.
  else {
    linkContent = content.content;
  }

  return linkType.create(attrs, linkContent);
}
