import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { NavigationEnd, NavigationSkipped, NavigationSkippedCode, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { isEnumValue } from '@common/util/enum';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ReviewPackageProfile } from '@portal-core/profiles/models/review-package-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import {
  ReviewPackageProfileComponent,
  ReviewPackageProfileForm,
  ReviewPackageProfileTab
} from '@portal-core/reviews/review-packages/components/review-package-profile/review-package-profile.component';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, filter, first, switchMap } from 'rxjs';

export interface ReviewPackageProfileDialogData {
  reviewPackageId: number;
  reviewPackageProfileTab?: ReviewPackageProfileTab;
}

@Component({
  selector: 'mc-review-package-profile-dialog',
  templateUrl: './review-package-profile-dialog.component.html',
  styleUrls: ['./review-package-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '90rem',
    height: '64rem'
  };

  @ViewChild(ReviewPackageProfileComponent, { static: true })
  reviewPackageProfileComponent: ReviewPackageProfileComponent;

  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  reviewPackageProfile$: Observable<ReviewPackageProfile>;
  reviewPackageProfileTab: ReviewPackageProfileTab;

  get requirePromptOnClose(): boolean {
    return this.reviewPackageProfileComponent?.dirty;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ReviewPackageProfileDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ReviewPackageProfileDialogComponent>,
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    private profilesService: ProfilesService,
    private router: Router
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);
    this.reviewPackageProfile$ = this.profilesService.getReviewPackageProfile$(this.data.reviewPackageId);

    this.reviewPackageProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getReviewPackageProfile$(this.data.reviewPackageId)),
      cache()
    );

    this.reviewPackageProfile$.pipe(
      first(),
      switchMap(reviewPackageProfile => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageReviews, reviewPackageProfile.ReviewPackage.ProjectId))
    ).subscribe(userCanManageReviews => {
      this.reviewPackageProfileTab = this.getInitialTab(this.data.reviewPackageProfileTab, userCanManageReviews);

      this.loadingState.update(false);
      this.onLoaded();
    }, error => {
      this.loadingState.update(
        false,
        'Unable to load the review package profile.',
        this.errorService.getErrorMessages(error)
      );
    });

    // Subscribes to the navigation change events for closing the dialog when there is a route change.
    // This Dialog panel contains some links which redirect to other routes so we need to close the Dialog when the route changes.
    this.router.events
      .pipe(
        filter(event => {
          const navigated = event instanceof NavigationEnd;
          const sameUrlNavigation = event instanceof NavigationSkipped && event.code === NavigationSkippedCode.IgnoredSameUrlNavigation;

          return navigated || sameUrlNavigation;
        })
      )
      .subscribe(() => {
        this.closeDialog(null, true);
      });
  }

  onCancel() {
    this.closeDialog();
  }

  onSaved(reviewPackageProfileForm: ReviewPackageProfileForm) {
    switch (reviewPackageProfileForm) {
      case ReviewPackageProfileForm.Delete:
        this.closeDialog();
        break;
      case ReviewPackageProfileForm.Files:
      case ReviewPackageProfileForm.Reviewers:
      case ReviewPackageProfileForm.Settings:
        this.reload();
        break;
    }
  }

  reload() {
    this.reload$.next();
  }

  private getInitialTab(reviewPackageProfileTab: ReviewPackageProfileTab, userCanManageReviews: boolean): ReviewPackageProfileTab {
    if (reviewPackageProfileTab === ReviewPackageProfileTab.Settings || reviewPackageProfileTab === ReviewPackageProfileTab.Delete) {
      if (userCanManageReviews) {
        return reviewPackageProfileTab;
      } else {
        return ReviewPackageProfileTab.Overview;
      }
    } else if (isEnumValue(ReviewPackageProfileTab, reviewPackageProfileTab)) {
      return reviewPackageProfileTab;
    } else {
      return ReviewPackageProfileTab.Overview;
    }
  }
}
