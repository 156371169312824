import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { SiteStyles } from '@portal-core/sites/models/site-styles.model';
import { SiteStylesApiService } from '@portal-core/sites/services/site-styles/site-styles-api.service';
import { SiteStylesDataService } from '@portal-core/sites/services/site-styles/site-styles-data.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteStylesService extends CollectionServiceBase<SiteStyles> {
  constructor(private sitesStylesApiService: SiteStylesApiService, private siteStylesDataService: SiteStylesDataService, protected dataService: DataService) {
    super(siteStylesDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<SiteStyles> {
    return this.sitesStylesApiService.getSiteStylesById$(itemId);
  }

  getSiteStylesByLicense$(licenseId: number, options?: GetDataOptions): Observable<SiteStyles[]> {
    return this.dataService.getDataListItems$<SiteStyles>('Licenses', licenseId, this.siteStylesDataService, {
      fetch: () => this.sitesStylesApiService.getSiteStylesByLicenseId$(licenseId).pipe(
        map(siteStylesList => {
          if (Array.isArray(siteStylesList)) {
            return siteStylesList.sort((siteStylesA, siteStylesB) => siteStylesA.Name.localeCompare(siteStylesB.Name));
          }
        })
      )
    }, options);
  }

  getSiteStylesPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<SiteStyles>> {
    return this.sitesStylesApiService.getSiteStylesPageByLicenseId$(licenseId, filter);
  }

  createSiteStyles$(licenseId: number, siteStylesName: string): Observable<SiteStyles> {
    return this.sitesStylesApiService.upsertSiteStyles$({
      LicenseId: licenseId,
      Name: siteStylesName
    }).pipe(
      tap(newSiteStyles => {
        this.siteStylesDataService.addItems$([newSiteStyles]);
        this.siteStylesDataService.addItemsToListById$('Licenses', licenseId, [newSiteStyles]);
      })
    );
  }

  duplicateSiteStyles$(siteStylesId: number, siteStylesName?: string): Observable<SiteStyles> {
    return this.sitesStylesApiService.duplicateSiteStyles$(siteStylesId, siteStylesName).pipe(
      tap(newSiteStyles => {
        this.siteStylesDataService.addItems$([newSiteStyles]);
        this.siteStylesDataService.addItemsToListById$('Licenses', newSiteStyles.LicenseId, [newSiteStyles]);
      })
    );
  }

  updateSiteStyles$(siteStyles?: Partial<SiteStyles>, siteLogoFile?: File): Observable<SiteStyles> {
    return this.sitesStylesApiService.upsertSiteStyles$(siteStyles, siteLogoFile).pipe(
      tap(newSiteStyles => this.siteStylesDataService.updateItems$([newSiteStyles]))
    );
  }

  deleteSiteStyles$(siteStylesId: number): Observable<any> {
    return this.sitesStylesApiService.deleteSiteStyles$(siteStylesId).pipe(
      tap(() => this.siteStylesDataService.removeItems$([siteStylesId]))
    );
  }
}
