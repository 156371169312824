import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ReviewPackageDeletionFormComponent } from './review-package-deletion-form.component';

@NgModule({
  declarations: [ReviewPackageDeletionFormComponent],
  exports: [ReviewPackageDeletionFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatInputModule,
    MatButtonModule
  ]
})
export class ReviewPackageDeletionFormModule { }
