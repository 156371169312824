import { LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@portal-core/dialogs/dialogs.module';
import { EmptyModule } from '@portal-core/general/components/empty/empty.module';
import { PATH_PRESERVE_QUERY_LOCATION_STRATEGY_OPTIONS, PathPreserveQueryLocationStrategy } from '@portal-core/general/util/path-preserve-query-location-strategy';
import { AppRoutingModule } from '@portal-login/app/app-routing.module';
import { AppComponent } from '@portal-login/app/components/app/app.component';
import { CoreModule } from '@portal-login/core/core.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    DialogsModule,
    EmptyModule,
    AppRoutingModule // Last Import (AppRoutingModule needs to be the last import in the imports array)
  ],
  providers: [
    {
      provide: PATH_PRESERVE_QUERY_LOCATION_STRATEGY_OPTIONS,
      useValue: {
        paramNames: ['reason']
      }
    },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
