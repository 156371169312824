import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { DeleteBuildType } from '@portal-core/builds/enums/delete-build-type.enum';
import { ProcessBuildType } from '@portal-core/builds/enums/process-build-type.enum';
import { BuildLanguageBranch } from '@portal-core/builds/models/build-language-branch.model';
import { BuildSchedule } from '@portal-core/builds/models/build-schedule.model';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsApiService } from '@portal-core/builds/services/builds-api.service';
import { BuildsDataService } from '@portal-core/builds/services/builds-data.service';
import { BuildsScheduleDataService } from '@portal-core/builds/services/builds-schedule-data.service';
import { CollectionServiceBase, } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { ProcessStatus } from '@portal-core/processes/enums/process-status.enum';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { get } from 'lodash';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class BuildsService extends CollectionServiceBase<Build> {
  viewableOutputExtensions = ['htm', 'html', 'pdf'];

  constructor(
    private buildApiService: BuildsApiService,
    private buildsDataService: BuildsDataService,
    private buildsScheduleDataService: BuildsScheduleDataService,
    protected dataService: DataService) {
    super(buildsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<Build> {
    return this.buildApiService.getBuild$(itemId).pipe(
      map(build => this.processBuild(build))
    );
  }

  protected fetchItemsById$(itemIds: number[]): Observable<Build[]> {
    return this.buildApiService.getBuildsByIds$(itemIds).pipe(
      map(builds => Array.isArray(builds) ? this.processBuilds(builds) : null)
    );
  }

  getBuildsPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Build>> {
    return this.buildApiService.getBuildsPageByProjectId$(projectId, filter).pipe(
      tap(page => this.processBuildsPage(page))
    );
  }

  getBuildsPage$(filter: PageFilter): Observable<Page<Build>> {
    return this.buildApiService.getBuildsPage$(filter).pipe(
      tap(page => this.processBuildsPage(page))
    );
  }

  bulkDeleteBuilds$(projectId: number, buildIds: number[]): Observable<any> {
    return this.buildApiService.bulkDeleteBuilds$(projectId, buildIds).pipe(
      tap(() => this.buildsDataService.removeItems$(buildIds))
    );
  }

  deleteBuildsInProject$(projectId: number, deleteBuildType: DeleteBuildType): Observable<MadCloudResult> {
    return this.buildApiService.deleteBuildsInProject$(projectId, deleteBuildType);
  }

  bulkDeleteBuildScheduleById$(projectId: number, buildScheduleIds: number[]): Observable<MadCloudResult> {
    return this.buildApiService.bulkDeleteBuildSchedulesById$(projectId, buildScheduleIds).pipe(
      tap(() => {
        this.buildsScheduleDataService.removeItems$(buildScheduleIds);
      })
    );
  }

  buildByTargetId$(targetId: number): Observable<any> {
    return this.buildApiService.buildByTargetId$(targetId);
  }

  buildByTargetPathAndBranchName$(projectId: number, currentTargetPath: string, currentBranchName: string, buildLanguageBranches: BuildLanguageBranch[]): Observable<any> {
    return this.buildApiService.buildByTargetPathAndBranchName$(projectId, currentTargetPath, currentBranchName, buildLanguageBranches);
  }

  processBuild$(buildId: number, processBuildType: ProcessBuildType): Observable<any> {
    return this.buildApiService.processBuild$(buildId, processBuildType);
  }

  getDownloadBuildSasToken$(buildId: number): Observable<HttpResponse<any>> {
    return this.buildApiService.getDownloadBuildSasToken$(buildId);
  }

  setBuildAsKeep$(buildId: number, keep: boolean): Observable<any> {
    return this.buildApiService.setBuildAsKeep$(buildId, keep);
  }

  setUserViewedBuild$(buildId: number): Observable<any> {
    return this.buildApiService.setUserViewedBuild$(buildId);
  }

  saveBuildSchedule$(projectId: number, buildSchedule: BuildSchedule): Observable<any> {
    // update
    if (buildSchedule.Id) {
      return this.buildApiService.saveBuildSchedule$(projectId, buildSchedule).pipe(
        tap(() => this.buildsScheduleDataService.updateItems$([buildSchedule]))
      );
    } else {
      // create
      return this.buildApiService.saveBuildSchedule$(projectId, buildSchedule);
    }
  }

  updateBuild$(build: Build): Observable<any> {
    this.buildsDataService.addItemsToListById$('Targets', build.Id, [build]);
    return this.updateItems$([this.processBuild(build)]);
  }

  processBuildsPage(page: Page<Build>): Page<Build> {
    if (Array.isArray(page?.Items)) {
      this.processBuilds(page.Items);
    }
    return page;
  }

  processBuilds(builds: Build[]): Build[] {
    builds.forEach(build => this.processBuild(build));
    return builds;
  }

  processBuild(build: Build): Build {
    if (build) {
      let state = ProcessState.Unknown;
      let status = ProcessStatus.Unknown;
      const lastNotification = build.LastNotification;
      if (lastNotification) {
        state = lastNotification.ProcessState;
        status = lastNotification.ProcessNotification.ProcessStatus;
      }

      const isSuccess = state === ProcessState.Complete;
      const isFinished = isSuccess ||
        state === ProcessState.Cancelled ||
        state === ProcessState.Failed && (status === ProcessStatus.Finished || status === ProcessStatus.Terminated);

      build.CanBeKeep = isSuccess;
      build.CanViewOutput = false;
      build.CanBeEdited = isSuccess;
      build.CanBeCancelled = !isFinished;
      build.CanBeDeleted = isFinished &&
        !build.IsKept &&
        (!build.SitesList || build.SitesList.length === 0); // cannot delete builds that are kept or assigned to site
      build.CanDownload = isSuccess;
      build.CanBeAssignedToSite = isSuccess;
      build.CanViewLog = isFinished;
      build.LinkedToLiveSite = Array.isArray(build.SitesList) && build.SitesList.some(s => s.IsLive);
      build.IsFinished = isFinished;
      build.IsStopped = build.LastNotification && (
        build.LastNotification.ProcessState === ProcessState.Complete ||
        build.LastNotification.ProcessState === ProcessState.Cancelled ||
        build.LastNotification.ProcessState === ProcessState.Failed
      );
      if (isSuccess && build.PublishStartupFile) {
        const fileExt = build.PublishStartupFile.split('.').pop();
        build.CanViewOutput = this.viewableOutputExtensions.includes(fileExt.toLowerCase());
      }

      if (isFinished) {
        build.Completed = lastNotification.ProcessNotification.StartTime;
        build.Progress = 100;
      } else {
        // Calculate the build progress
        const buildProgress = get(build, 'ProgressNotification.ProcessProgressNotification.Dx', 0);
        const buildProgressMax = get(build, 'ProgressNotification.ProcessProgressNotification.MaxValue', 100);

        build.Progress = (buildProgress / buildProgressMax) * 100;
      }
    }

    return build;
  }

  getColorForState(state: ProcessState): string {
    switch (state) {
      case ProcessState.Unknown:
        return 'build-unknown';
      case ProcessState.Failed:
        return 'build-fail';
      case ProcessState.Building:
        return 'build-success';
      case ProcessState.Publishing:
        return 'build-success';
      case ProcessState.GettingFiles:
        return 'build-success';
      case ProcessState.Complete:
        return 'build-success';
      case ProcessState.Cancelled:
        return 'build-cancel';
      case ProcessState.Queued:
        return 'info';
      default:
        return 'build-unknown';
    }
  }


  // TODO: circular dependency if adding ProjectTargetService - need to fix that and not use getZipNameFromTargetPath.
  getZipNameForBuild(build: Build): string {
    return this.getZipNameFromTargetPath(build.TargetPath) + '_' + build.Id + '.zip';
  }

  getZipNameFromTargetPath(targetPath: string): string {
    if (targetPath) {
      const splitTargetPath = targetPath.split('/');
      return splitTargetPath[splitTargetPath.length - 1]?.split('.fltar')[0];
    }
  }

  getBuildByTargetPath$(projectId: number, branchName: string, targetPath: string): Observable<Build> {
    return this.buildApiService.getBuildByTargetPath$(projectId, branchName, targetPath);
  }
  getBuildByProjectIdAndPath$(projectId: number, targetPath: string): Observable<Build> {
    return this.buildApiService.getBuildByProjectIdAndPath$(projectId, targetPath);
  }
}
