import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { TranslationPackageLanguageBranchNotificationListModule } from '@portal-core/notifications/components/translation-package-language-branch-notification-list/translation-package-language-branch-notification-list.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { ProjectLinkModule } from '@portal-core/projects/pipes/project-link/project-link.module';
import { TranslationPackageLanguageBranchAvatarModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-avatar/translation-package-language-branch-avatar.module';
import { TranslationPackageLanguageBranchDeletionFormModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-deletion-form/translation-package-language-branch-deletion-form.module';
import { TranslationPackageLanguageBranchOverviewModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-overview/translation-package-language-branch-overview.module';
import { TranslationPackageLanguageBranchProfileDialogComponent } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-profile-dialog/translation-package-language-branch-profile-dialog.component';
import { TranslationPackageLanguageBranchAsyncModule } from '@portal-core/translation-package-language-branches/pipes/translation-package-language-branch-async/translation-package-language-branch-async.module';
import { TranslationPackageStatusTextModule } from '@portal-core/translation-packages/pipes/translation-package-status-text/translation-package-status-text.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
@NgModule({
  declarations: [TranslationPackageLanguageBranchProfileDialogComponent],
  imports: [
    BuildAvatarModule,
    BranchAvatarModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    PreventDefaultModule,
    TranslationPackageLanguageBranchAsyncModule,
    TranslationPackageLanguageBranchAvatarModule,
    ProjectLinkModule,
    TranslationPackageStatusTextModule,
    TranslationPackageLanguageBranchDeletionFormModule,
    TranslationPackageLanguageBranchNotificationListModule,
    TranslationPackageLanguageBranchOverviewModule,
    SwitchBoardModule,
    MatTooltipModule,
    ProfileTabModule,
    RouterModule
  ],
  exports: [TranslationPackageLanguageBranchProfileDialogComponent]
})
export class TranslationPackageLanguageBranchesProfileDialogModule { }
