import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicenseAsyncPipe } from './license-async.pipe';

@NgModule({
  declarations: [LicenseAsyncPipe],
  imports: [CommonModule],
  exports: [LicenseAsyncPipe],
  providers: [LicenseAsyncPipe]
})
export class LicenseAsyncModule { }
