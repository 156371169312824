<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
</button>

<mc-user-profile mcDialogAutoFocus [userProfile]="userProfile$ | async" [profileTab]="userProfileTab" (cancel)="onCancel()" (saved)="onSaved($event)"></mc-user-profile>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>
