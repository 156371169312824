import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ReviewFileProfileModule } from '@portal-core/reviews/review-files/components/review-file-profile/review-file-profile.module';
import { ReviewFileProfileDialogComponent } from './review-file-profile-dialog.component';

@NgModule({
  declarations: [
    ReviewFileProfileDialogComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReviewFileProfileModule
  ],
  exports: [
    ReviewFileProfileDialogComponent
  ]
})
export class ReviewFileProfileDialogModule { }
