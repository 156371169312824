import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { join } from '@common/util/path';
import { environment } from '@env/environment';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { License } from '@portal-core/licenses/models/license.model';
import { AuditCategory } from '@portal-core/notifications/models/audit-category.model';
import { OAuthVendor } from '@portal-core/oauth/enums/oauth-vendor.enum';
import { OAuthService } from '@portal-core/oauth/services/oauth.service';
import { ProjectTarget } from '@portal-core/project-targets/models/project-target.model';
import { ProjectTargetsService } from '@portal-core/project-targets/services/project-targets.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { WebhookChannel } from '@portal-core/slack/models/webhook-channel.model';
import { WebhookSubscriber } from '@portal-core/slack/models/webhook-subscriber.model';
import { SlackWebhookChannelsService } from '@portal-core/slack/services/slack-webhooks-channels.service';
import { SlackWebhooksService } from '@portal-core/slack/services/slack-webhooks.service';
import { Task } from '@portal-core/tasks/models/task.model';
import { TasksService } from '@portal-core/tasks/services/tasks.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { filter as _filter, flatMap as _flatMap } from 'lodash';
import { Observable, distinctUntilChanged, filter, flatMap, forkJoin, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'mc-license-slack-form',
  templateUrl: './license-slack-form.component.html',
  styleUrls: ['./license-slack-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicenseSlackFormComponent implements OnInit, OnChanges {
  @Input() license: License;
  @Input() licenseUser: LicenseUser;
  @Input() subscribers: WebhookSubscriber[];
  @Input() channels: WebhookChannel[];
  @Input() auditCategoryTree: AuditCategory[];
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  editSlackNotificationForm: UntypedFormGroup;
  showEdit: boolean = false;
  projects$: Observable<Project[]>;
  users$: Observable<LicenseUser[]>;
  teams$: Observable<Team[]>;
  targets$: Observable<ProjectTarget[]>;
  tasks$: Observable<Task[]>;
  sites$: Observable<Site[]>;
  supportedActivityTypes: string[] = [
    'Builds',
    'Checklists',
    'Projects',
    'Sites',
    'Tasks',
    'Teams',
    'Users'
  ];
  // FILTER_TYPES should match enum from server
  FILTER_TYPES: string[] = ['noFilter', 'singleton', 'all', 'allWithoutProject', 'allWithProject'];
  activityTypes: AuditCategory[];
  newWebhookSubscriber: WebhookSubscriber;
  auditCategorySelection: SelectionModel<AuditCategory> = new SelectionModel<AuditCategory>(true);
  awaitingResponse: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private slackWebhooksService: SlackWebhooksService,
    private slackWebhookChannelsService: SlackWebhookChannelsService,
    private projectsService: ProjectsService,
    private licenseUsersService: LicenseUsersService,
    private teamsService: TeamsService,
    private projectTargetsService: ProjectTargetsService,
    private tasksService: TasksService,
    private sitesService: SitesService,
    private errorService: ErrorService,
    private oAuthService: OAuthService,
    private authUrlService: AuthUrlService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.editSlackNotificationForm = this.formBuilder.group(this.buildForm());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.auditCategoryTree && changes.auditCategoryTree.currentValue !== null && changes.auditCategoryTree.previousValue !== changes.auditCategoryTree.currentValue) {
      this.activityTypes = this.auditCategoryTree.filter(activity => {
        return activity.Visible && this.supportedActivityTypes.includes(activity.Title);
      });
    }
  }

  buildForm() {
    return {
      name: new UntypedFormControl('', Validators.required),
      channel: new UntypedFormControl('', Validators.required),
      activityType: new UntypedFormControl(null, Validators.required),
      task: new UntypedFormControl(''),
      project: new UntypedFormControl(''),
      target: new UntypedFormControl(''),
      team: new UntypedFormControl(''),
      site: new UntypedFormControl(''),
      user: new UntypedFormControl('')
    };
  }

  addSlackChannel() {
    // For security purposes to authenticate the redirect back from Slack
    const generatedStateValue = this.oAuthService.generateStateParam();

    const slackAuthUrl = 'https://slack.com/oauth/v2/authorize';
    // Permissions our app is requesting (currently just incoming webhook, to send messages to a Slack channel)
    const scopeParam = 'scope=incoming-webhook,channels:read,groups:read';
    // The MadCap Central app id. This is public and safe to share, unlike ClientSecret
    const clientIdParam = `client_id=${environment.slackAppClientId}`;
    // Security param to authenticate the slack request after the redirect
    const stateParam = `state=${generatedStateValue}`;
    // Users will be sent here when returning to Central
    // NOTE: the API has to submit this exact value to Slack when confirming, so if this changes the API must also be updated. See: WebhooksController.PutConfirmAuthChannel
    // TODO: Remove this line that hard codes the confirm slack path prefix to the portal base URI. This is a temporary workaround to make the slack confirmation flow work until Slack accepts our configuration changes
    // NGINX checks for the confirm slack path prefix and redirects to the correct 'confirm' subdomain without the confirm slack path prefix in the path
    const redirectUri = encodeURIComponent(join([environment.centralPortalBaseUri, 'confirm-slack', this.authUrlService.getSubdomain(), this.license.Id, 'confirm/slack']));
    // const redirectUri = encodeURIComponent(join([this.authUrlService.buildSubdomainUrl(environment.centralConfirmSubdomain), this.authUrlService.getSubdomain(), this.license.Id, 'confirm/slack']));
    const redirectUriParam = `redirect_uri=${redirectUri}`;

    // Store the state id and current route for use after Slack redirects back to Central
    this.oAuthService.setConfirm$(OAuthVendor.Slack, generatedStateValue).subscribe(() => {
      // Leave Central and visit Slack for authorization
      window.location.href = `${slackAuthUrl}?${scopeParam}&${clientIdParam}&${stateParam}&${redirectUriParam}`;
    });
  }

  deleteSlackChannel(channel: WebhookChannel) {
    this.createDeleteConfirmationDialog(`Are you sure you want to delete channel ${channel.Name}?`)
      .afterClosed().pipe(
        filter(confirmed => confirmed),
        switchMap(() => {
          this.awaitingResponse = true;
          this.cdr.markForCheck();
          return this.slackWebhookChannelsService.deleteChannel$(channel.Id);
        })
      ).subscribe(ok => {
        this.awaitingResponse = false;
        this.showMessage('Channel deleted');
        this.cdr.markForCheck();
      },
        (errorResponse: HttpErrorResponse) => {
          this.awaitingResponse = false;
          this.displayError('Error Deleting', 'There was a problem deleting the channel.', errorResponse);
          this.cdr.markForCheck();
        }
      );
  }

  editSlackChannelSubscription(subscriber?: WebhookSubscriber) {
    this.showEdit = true;
    this.newWebhookSubscriber = Object.assign({}, subscriber);

    this.populateCategories();
    if (subscriber) {
      this.slackWebhooksService.getSubscriptions$(subscriber.Id).subscribe(
        subscriptions => {
          // Store subscribed audit categories
          const selectedCategories = _flatMap(this.activityTypes, p => p.SubCategories.filter(cat => subscriptions.some(sub => sub.AuditCategoryId === cat.Id)));
          this.auditCategorySelection.select(...selectedCategories);
        }
      );
      this.editSlackNotificationForm.patchValue({
        name: subscriber.Name,
        channel: this.channels.find(channel => channel.Id === subscriber.WebhookChannelId),
        activityType: this.activityTypes.find(activity => activity.AuditTargetPageType === subscriber.AuditTargetPageType),
        task: subscriber.TaskId ? subscriber.TaskId : subscriber.TaskFilter > 1 ? this.getFilterTypeName(subscriber.TaskFilter) : '',
        project: subscriber.ProjectId ? subscriber.ProjectId : subscriber.ProjectFilter > 1 ? this.getFilterTypeName(subscriber.ProjectFilter) : '',
        target: subscriber.TargetId ? subscriber.TargetId : subscriber.TargetFilter > 1 ? this.getFilterTypeName(subscriber.TargetFilter) : '',
        team: subscriber.TeamId ? subscriber.TeamId : subscriber.TeamFilter > 1 ? this.getFilterTypeName(subscriber.TeamFilter) : '',
        site: subscriber.SiteId ? subscriber.SiteId : subscriber.SiteFilter > 1 ? this.getFilterTypeName(subscriber.SiteFilter) : '',
        user: subscriber.UserId ? subscriber.UserId : subscriber.UserFilter > 1 ? this.getFilterTypeName(subscriber.UserFilter) : '',
      });
      this.editSlackNotificationForm.updateValueAndValidity();
    }

    this.cdr.markForCheck();
  }

  private populateCategories() {
    this.projects$ = this.projectsService.getProjectsList$(this.license.Id).pipe(
      // Only get non archived projects
      map(projects => _filter(projects, project => project.Status !== 0)),
      distinctUntilChanged()
    );
    this.users$ = this.licenseUsersService.getAllLicenseUsersByLicenseId$(this.license.Id, false).pipe(
      // Only get non deactivated users
      map(licenseUsers => _filter(licenseUsers, licenseUser => licenseUser.Status !== 0)),
      distinctUntilChanged()
    );
    this.teams$ = this.teamsService.getTeamsByLicenseId$(this.license.Id).pipe(
      distinctUntilChanged()
    );
    this.tasks$ = this.tasksService.getAllTasks$(this.license.Id).pipe(
      distinctUntilChanged()
    );
    this.sites$ = this.sitesService.getSitesByLicenseId$(this.license.Id).pipe(
      distinctUntilChanged()
    );
  }

  updateTargets(projectId: number) {
    const displayTargets = this.editSlackNotificationForm.value.activityType.Title === 'Builds';
    if (!displayTargets || typeof projectId !== 'number') {
      return;
    }

    this.targets$ = this.projectTargetsService.getProjectTargets$(projectId, false, true).pipe(
      // Filter non batch targets
      map(result => {
        return _filter(result.OutputTargets, target => target.OutputTypeKeyName !== 'batch');
      })
    );
  }

  deleteSlackSubscriber(subscriber: WebhookSubscriber) {
    this.createDeleteConfirmationDialog(`Are you sure you want to delete notification ${subscriber.Name}?`)
      .afterClosed().pipe(
        filter(confirmed => confirmed),
        switchMap(() => {
          this.awaitingResponse = true;
          this.cdr.markForCheck();
          return this.slackWebhooksService.deleteSubscriber$(subscriber.Id);
        })
      ).subscribe(ok => {
        this.awaitingResponse = false;
        this.showMessage('Notification subscription deleted');
        this.cdr.markForCheck();
      },
        (errorResponse: HttpErrorResponse) => {
          this.awaitingResponse = false;
          this.displayError('Error Deleting', 'There was a problem deleting the notification subscription.', errorResponse);
          this.cdr.markForCheck();
        }
      );
  }

  saveChannelSubscription(values: any, formGroupDirective: FormGroupDirective) {
    this.newWebhookSubscriber = Object.assign(this.newWebhookSubscriber, {
      Name: values.name,
      LicenseId: this.license.Id,
      WebhookChannelId: values.channel.Id,
      WebhookChannelName: values.channel.Name,
      AuditTargetPageType: values.activityType.AuditTargetPageType,
      TaskId: Number.isInteger(values.task) ? values.task : null,
      ProjectId: Number.isInteger(values.project) ? values.project : null,
      TargetId: Number.isInteger(values.target) ? values.target : null,
      TeamId: Number.isInteger(values.team) ? values.team : null,
      SiteId: Number.isInteger(values.site) ? values.site : null,
      UserId: this.FILTER_TYPES.indexOf(values.user) >= 0 ? null : values.user !== '' ? values.user : null,

      TaskFilter: Number.isInteger(values.task) ? this.FILTER_TYPES.indexOf('singleton') : this.FILTER_TYPES.indexOf(values.task !== '' ? values.task : 'noFilter'),
      ProjectFilter: Number.isInteger(values.project) ? this.FILTER_TYPES.indexOf('singleton') : this.FILTER_TYPES.indexOf(values.project !== '' ? values.project : 'noFilter'),
      TeamFilter: Number.isInteger(values.team) ? this.FILTER_TYPES.indexOf('singleton') : this.FILTER_TYPES.indexOf(values.team !== '' ? values.team : 'noFilter'),
      TargetFilter: Number.isInteger(values.target) ? this.FILTER_TYPES.indexOf('singleton') : this.FILTER_TYPES.indexOf(values.target !== '' ? values.target : 'noFilter'),
      SiteFilter: Number.isInteger(values.site) ? this.FILTER_TYPES.indexOf('singleton') : this.FILTER_TYPES.indexOf(values.site !== '' ? values.site : 'noFilter'),
      UserFilter: this.FILTER_TYPES.indexOf(values.user) >= 0 ? this.FILTER_TYPES.indexOf(values.user) : this.FILTER_TYPES.indexOf(values.user !== '' ? 'singleton' : 'noFilter'),
    });

    const request$ = this.newWebhookSubscriber.Id ? this.slackWebhooksService.updateSubscriber$(this.newWebhookSubscriber) :
      this.slackWebhooksService.addSubscriber$(this.newWebhookSubscriber);
    this.awaitingResponse = true;
    request$.pipe(
      flatMap((dBsubscriber: WebhookSubscriber) => {
        // Use server returned subscriber with Id
        this.newWebhookSubscriber.Id = dBsubscriber.Id;
        // Clear all old subscriptions
        return this.slackWebhooksService.clearAllSubscriptions$(this.newWebhookSubscriber.Id);
      }),
      flatMap(() => {
        // Get subscriptions from the form
        const addSubscriptions$ = this.auditCategorySelection.selected
          .filter(category => category.AuditTargetPageType === values.activityType.AuditTargetPageType)
          .map(category => this.slackWebhooksService.addSubscription$(this.newWebhookSubscriber.Id, category.Id));

        if (addSubscriptions$.length === 0) {
          return of(null);
        }
        // Update subscriber with new subscriptions
        return forkJoin(addSubscriptions$);
      })).subscribe(ok => {
        this.awaitingResponse = false;
        this.resetForm(formGroupDirective);
        this.showMessage('Notification subscription saved');
        this.cdr.markForCheck();
      },
        (errorResponse: HttpErrorResponse) => {
          this.awaitingResponse = false;
          this.displayError('Error Saving', 'There was a problem saving the notification subscription.', errorResponse);
          this.cdr.markForCheck();
        }
      );
  }

  private showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 2500,
    });
  }

  private displayError(title: string, message: string, errorResponse: HttpErrorResponse) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: title,
        message: message,
        errors: this.errorService.getErrorMessages(errorResponse)
      }
    });
  }

  toggleCategory(category: AuditCategory) {
    this.auditCategorySelection.toggle(category);
    this.editSlackNotificationForm.markAsDirty();
  }

  resetForm(formGroupDirective: FormGroupDirective) {
    this.showEdit = false;
    formGroupDirective.resetForm();
    this.auditCategorySelection.clear();
    this.newWebhookSubscriber = null;
  }

  private createDeleteConfirmationDialog(prompt: string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        action: 'Delete',
        title: 'Delete Confirmation',
        prompt: prompt
      }
    });
    return dialogRef;
  }

  private getFilterTypeName(filterCode: number): string {
    if (filterCode >= 0 && filterCode < this.FILTER_TYPES.length) {
      return this.FILTER_TYPES[filterCode];
    }
    return '';
  }
}
