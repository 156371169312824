import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ReviewPackageTocResponse } from '@portal-core/reviews/models/review-package-toc.model';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';
import { CreateReviewPackage } from '@portal-core/reviews/review-packages/models/create-review-package.model';
import { ReviewPackageAnalytics } from '@portal-core/reviews/review-packages/models/review-package-analytics.model';
import { ReviewPackageFileAnalytics } from '@portal-core/reviews/review-packages/models/review-package-file-analytics.model';
import { ReviewPackageGridItem } from '@portal-core/reviews/review-packages/models/review-package-grid-item.model';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackageOperationType } from '@portal-core/reviews/review-packages/types/review-package-operation-type.type';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewPackagesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getReviewPackageById$(reviewPackagesId: number): Observable<ReviewPackage> {
    return this.http.get<ReviewPackage>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackagesId}`);
  }

  @SharePendingRequest()
  getReviewPackagesByIds$(reviewPackagesIds: number[]): Observable<ReviewPackage[]> {
    return this.http.post<ReviewPackage[]>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/getBatchReviewPackagesByIds`,
      reviewPackagesIds
    );
  }

  @SharePendingRequest()
  getReviewPackagesPage$(
    licenseId: number,
    reviewersListLimit: number,
    filter?: PageFilter
  ): Observable<Page<ReviewPackageGridItem>> {
    return this.http.post<Page<ReviewPackageGridItem>>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/license/${licenseId}/${reviewersListLimit}`,
      filter
    );
  }

  @SharePendingRequest()
  getReviewsPackageAnalytics$(reviewPackageId: number, aggregateForAllUsers: boolean): Observable<ReviewPackageAnalytics> {
    return this.http.get<ReviewPackageAnalytics>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/analytics/${reviewPackageId}?aggregateForAllUsers=${aggregateForAllUsers}`);
  }

  @SharePendingRequest()
  getReviewsPackageFileAnalytics$(reviewPackageId: number): Observable<ReviewPackageFileAnalytics> {
    return this.http.get<ReviewPackageFileAnalytics>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/FileStatistics/${reviewPackageId}`);
  }

  createReviewPackage$(licenseId: number, projectId: number, reviewPackage: CreateReviewPackage): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/${licenseId}/${projectId}`,
      reviewPackage
    );
  }

  updateReviewPackage$(reviewPackage: Partial<ReviewPackage>, resetReviewerStatus: boolean): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/Settings/${reviewPackage.Id}?resetReviewerStatus=${resetReviewerStatus}`,
      reviewPackage
    );
  }

  setReviewers$(reviewPackageId: number, reviewUserIds: string[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageId}/SetReviewers`,
      reviewUserIds
    );
  }

  bulkUpdateReviewPackageStatus$(reviewPackageIds: number[], status: ReviewPackageStatus, resetReviewerStatus: boolean): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/Status/${status}?resetReviewerStatus=${resetReviewerStatus}`, reviewPackageIds);
  }

  deleteReviewPackages$(reviewPackageIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(
      `${this.apiService.centralApiBaseUri}/api/ReviewPackage/BulkDelete`,
      reviewPackageIds
    );
  }

  @SharePendingRequest()
  getReviewsPackageToc$(reviewPackage: ReviewPackage, tocPath: string): Observable<ReviewPackageTocResponse> {
    const { ProjectId, CommitId } = reviewPackage;

    return this.http.get<ReviewPackageTocResponse>(
      `${this.apiService.centralApiBaseUri}/content/project/${ProjectId}/git/${CommitId}/${tocPath}?mimeType=application/json`
    );
  }

  @SharePendingRequest()
  getHasAccess$(reviewPackageId: number, operationType: ReviewPackageOperationType): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/Access/${reviewPackageId}?operation=${operationType}`);
  }
}
