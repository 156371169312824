import { InjectionToken } from '@angular/core';
import { ColorInputFormat } from '@portal-core/ui/color-picker/models/color-input-format';

export type McPickerColorFormats = {
  display: {
    colorInput: ColorInputFormat;
  }
}

export const NGX_MC_PICKER_COLOR_FORMATS: McPickerColorFormats = {
  display: {
    colorInput: 'hex'
  }
}

export const MC_PICKER_COLOR_FORMATS = new InjectionToken<McPickerColorFormats>('mat-color-formats');
