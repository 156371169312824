<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
</button>

<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="translationPackage$ | async as translationPackage">
      <div class="mc-spring-board-header">
        <mc-translation-package-avatar [translationPackage]="translationPackage" [disabled]="true"></mc-translation-package-avatar>
      </div>
      <div class="mc-spring-board-body">
        <ul class="mc-property-list">
          <li>
            <span class="mc-property-name">Status: </span>
            <span class="mc-property-value">
              {{ translationPackage.Status | mcTranslationPackageStatusText }}
            </span>
          </li>
          <li>
            <span class="mc-property-name">Languages: </span>
            <span class="mc-property-value"> {{translationPackage.TranslationPackageLanguageBranches.length | mcPluralize: 'language-numeric'}}</span>
          </li>
          <li>
            <span class="mc-property-name">Commit ID: </span>
            <span class="mc-property-value">
              <mc-commit-avatar [commit]="translationPackage.GitCommitId" [showAvatar]="false" [truncate]="true"></mc-commit-avatar>
            </span>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Owner: </span>
            <span class="mc-property-value mc-truncate" [matTooltip]="translationPackage.CreatedByUser.FullName">
              {{ translationPackage.CreatedByUser.FullName }}
            </span>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Main Branch: </span>
            <span class="mc-property-value mc-truncate" [matTooltip]="translationPackage.SourceBranchName">
              {{ translationPackage.SourceBranchName }}
            </span>
          </li>
        </ul>
      </div>
    </ng-container>
    <div class="mc-spring-board-body">
      <!-- Empty to fill the space between the header and footer -->
    </div>
    <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
      <a mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="translationPackageProfileTab === TranslationPackageProfileTab.Overview" (click)="translationPackageProfileTab = TranslationPackageProfileTab.Overview">
        <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-overview" color="primary"></mat-icon> Overview
      </a>
      <a mat-list-item *ngIf="userCanManageTranslationPackage$ | async" mcPreventDefault="click" [class.mc-list-item-selected]="translationPackageProfileTab === TranslationPackageProfileTab.Settings" (click)="translationPackageProfileTab = TranslationPackageProfileTab.Settings">
        <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-gear" color="primary"></mat-icon> Settings
      </a>
      <a mat-list-item mcPreventDefault="click" [class.mc-list-item-selected]="translationPackageProfileTab === TranslationPackageProfileTab.Activity" (click)="translationPackageProfileTab = TranslationPackageProfileTab.Activity">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-clock'" color="primary"></mat-icon> Activity
      </a>
      <a mat-list-item *ngIf="userCanManageTranslationPackage$ | async" mcPreventDefault="click" [class.mc-list-item-selected]="translationPackageProfileTab === TranslationPackageProfileTab.Delete" (click)="translationPackageProfileTab = TranslationPackageProfileTab.Delete">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'" color="primary"></mat-icon> Delete
      </a>
      <mat-divider class="mc-project-profile-switch-board-bar-nav-list-divider"></mat-divider>
      <ng-container *ngIf="project$ | async as project">
        <a *ngIf="project | mcProjectLink : 'builds' as buildsLink" mat-list-item [routerLink]="buildsLink">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-build" color="primary"></mat-icon> Builds
        </a>
      </ng-container>
    </mat-nav-list>
  </mc-switch-board-bar>
  <mc-switch-board-content class="mc-spring-board" [ngSwitch]="translationPackageProfileTab">

    <mat-tab-group *ngSwitchCase="TranslationPackageProfileTab.Overview" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Overview">
        <div class="mc-spring-board">
          <mc-translation-packages-summary class="mc-spring-board-body" [translationPackage]="translationPackage$ | async"></mc-translation-packages-summary>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="TranslationPackageProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-translation-package-settings-form #settingsForm [translationPackage]="translationPackage$ | async" (cancel)="closeDialog()" (saved)="onSaved()"></mc-translation-package-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="TranslationPackageProfileTab.Activity" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Activity">
        <mc-translation-package-notification-list [translationPackageId]="(translationPackage$ | async).Id"></mc-translation-package-notification-list>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="TranslationPackageProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete">
        <mc-translation-package-deletion-form (cancel)="closeDialog()" [translationPackage]="translationPackage$ | async" (deleted)="closeDialog(true)"></mc-translation-package-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ |  async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>