<input class="mc-hidden" type="file" accept="image/*" #fileInput (change)="onFileChanged($event)">

<span *ngIf="placeholder" class="mc-image-picker-placeholder-label">{{ placeholder }}</span>

<button type="button" mat-button [color]="color" class="mc-image-picker-img-button" (click)="fileInput.click()">
  <img *ngIf="value?.path; else placeholderTemplate" [src]="value?.path" class="mc-image-picker-img" alt="Image Preview">

  <ng-template #placeholderTemplate>
    <mat-icon fontSet="project-titan" fontIcon="icon-picture" class="mc-image-picker-placeholder-icon"></mat-icon>
  </ng-template>
</button>

<div class="mc-image-picker-actions">
  <button type="button" mat-button [color]="color" class="mc-button-small" (click)="fileInput.click()">Change</button>
  <mat-divider [vertical]="true"></mat-divider>
  <button type="button" mat-button [color]="color" class="mc-button-small" (click)="onDeleteImageClicked()">Delete</button>
</div>
