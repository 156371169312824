import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcReviewPackageAsync'
})
export class ReviewPackageAsyncPipe implements PipeTransform {
  constructor(private reviewPackagesService: ReviewPackagesService) { }

  transform(reviewPackageId: number | null | undefined, allowApiRequest: boolean = false): Observable<ReviewPackage | null> {
    if (!reviewPackageId) {
      return of(null);
    }

    return this.reviewPackagesService.getItemById$(reviewPackageId, { allowApiRequest });
  }
}
