<form class="mc-spring-board" [formGroup]="taskBoardFormGroup">
  <div class="mc-spring-board-body">
    <mat-form-field color="primary-vivid" class="mc-form-field">
      <input matInput type="text" formControlName="title" placeholder="Title" [readonly]="taskBoard.IsDeleted" required>
      <mat-error>
        <mc-form-control-error-message [form]="taskBoardFormGroup" controlName="title" type="mc_task_board_title"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field color="primary-vivid" class="mc-form-field">
      <mat-select placeholder="Owner" formControlName="owner" [disabled]="taskBoard.IsDeleted">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let licenseUser of licenseUsers$ | async" [value]="licenseUser.User.Id">{{licenseUser.User.FullName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field color="primary-vivid" class="mc-form-field">
      <textarea matInput formControlName="description" placeholder="Description" rows="4" [readonly]="taskBoard.IsDeleted"></textarea>
      <mat-error>
        <mc-form-control-error-message [form]="taskBoardFormGroup" controlName="description" type="mc_task_board_description"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" [showIcon]="false" [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async">
    </mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button *ngIf="!taskBoard.IsDeleted" type="submit" mat-raised-button color="primary" (click)="onSaveClicked()" [disabled]="!taskBoardFormGroup.valid">
        Save
      </button>
    </div>
  </div>

  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel [showIcon]="false" [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async">
    </mc-errors-expansion-panel>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
</form>
