import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { NoItemPlaceholderModule } from '@portal-core/general/components/no-item-placeholder/no-item-placeholder.module';
import { SelectionListHeaderModule } from '@portal-core/general/components/selection-list-header/selection-list-header.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamAsyncModule } from '@portal-core/teams/pipes/team-async/team-async.module';
import { SiteTeamsFormComponent } from './site-teams-form.component';

@NgModule({
  declarations: [SiteTeamsFormComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    TeamAvatarModule,
    LoaderModule,
    FormsModule,
    SelectionListHeaderModule,
    ErrorsExpansionPanelModule,
    NoItemPlaceholderModule,
    TeamAsyncModule
  ],
  exports: [SiteTeamsFormComponent]
})
export class SiteTeamsFormModule { }
