import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { DurationModule } from '@portal-core/general/pipes/duration/duration.module';
import { BuildProgressBarComponent } from './build-progress-bar.component';

@NgModule({
  declarations: [
    BuildProgressBarComponent
  ],
  imports: [
    CommonModule,
    DurationModule,
    MatProgressBarModule
  ],
  exports: [
    BuildProgressBarComponent
  ]
})
export class BuildProgressBarModule { }
