<mc-grid-string-filter #gridFilter *ngIf="column.type === PageDataType.String || column.type === PageDataType.FullName" [column]="column" [columnInputs]="columnInputs" [filterable]="filterable" (menuClosed)="onMenuClosed($event)" (menuOpened)="onMenuOpened($event)">
  <ng-template *ngIf="menuTemplate" mcGridHeaderMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-string-filter>

<mc-grid-date-filter #gridFilter *ngIf="column.type === PageDataType.Date" [column]="column" [columnInputs]="columnInputs" [filterable]="filterable" (menuClosed)="onMenuClosed($event)" (menuOpened)="onMenuOpened($event)">
  <ng-template *ngIf="menuTemplate" mcGridHeaderMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-date-filter>

<mc-grid-number-filter #gridFilter *ngIf="column.type === PageDataType.Double || column.type === PageDataType.Int" [column]="column" [columnInputs]="columnInputs" [filterable]="filterable" (menuClosed)="onMenuClosed($event)" (menuOpened)="onMenuOpened($event)">
  <ng-template *ngIf="menuTemplate" mcGridHeaderMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-number-filter>

<mc-grid-select-filter #gridFilter *ngIf="column.type === PageDataType.Select" [column]="column" [columnInputs]="columnInputs" [filterable]="filterable" (menuClosed)="onMenuClosed($event)" (menuOpened)="onMenuOpened($event)">
  <ng-template *ngIf="menuTemplate" mcGridHeaderMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-select-filter>

<mc-grid-boolean-filter #gridFilter *ngIf="column.type === PageDataType.Boolean" [column]="column" [columnInputs]="columnInputs" [filterable]="filterable" (menuClosed)="onMenuClosed($event)" (menuOpened)="onMenuOpened($event)">
  <ng-template *ngIf="menuTemplate" mcGridHeaderMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-boolean-filter>
