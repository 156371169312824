import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { isEnumValue } from '@common/util/enum';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ReviewFileProfile } from '@portal-core/profiles/models/review-file-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { ReviewFileProfileComponent, ReviewFileProfileForm, ReviewFileProfileTab } from '@portal-core/reviews/review-files/components/review-file-profile/review-file-profile.component';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, first, switchMap } from 'rxjs';

export interface ReviewFileProfileDialogData {
  reviewFileId: number;
  reviewFileProfileTab?: ReviewFileProfileTab;
}

@Component({
  selector: 'mc-review-file-profile-dialog',
  templateUrl: './review-file-profile-dialog.component.html',
  styleUrls: ['./review-file-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '53rem'
  };

  @ViewChild(ReviewFileProfileComponent, { static: true }) reviewProfileComponent: ReviewFileProfileComponent;

  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  reviewFileProfile$: Observable<ReviewFileProfile>;
  reviewFileProfileTab: ReviewFileProfileTab;
  routerEventSubscription: Subscription;

  get requirePromptOnClose(): boolean {
    return this.reviewProfileComponent?.dirty;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ReviewFileProfileDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ReviewFileProfileDialogComponent>,
    private currentService: CurrentService,
    private permissionsService: PermissionsService,
    private profilesService: ProfilesService,
    private errorService: ErrorService,
    private router: Router
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);

    // Create an observable of the review profile. Start with reload$ so that the data can be reloaded as needed
    this.reviewFileProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getReviewFileProfile$(this.data.reviewFileId)),
      cache()
    );

    this.reviewFileProfile$.pipe(
      first()
    ).pipe(
      switchMap(reviewFileProfile => {
        return combineLatest([
          this.currentService.getCurrentLicenseUserIsAuthor$(),
          this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageReviews, reviewFileProfile.ReviewFile.ProjectId)
        ]);
      })
    ).subscribe(([isAuthor, userCanManageReviews]) => {
      this.reviewFileProfileTab = this.getInitialTab(this.data.reviewFileProfileTab, isAuthor, userCanManageReviews);

      this.loadingState.update(false);
      this.onLoaded();
    }, error => {
      this.loadingState.update(false, 'Unable to load the review file profile.', this.errorService.getErrorMessages(error));
    });

    // Listen for successful navigations and close the dialog (for closing the dialog when the user clicks a link in the dialog)
    this.routerEventSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.dialogRef.close());
  }

  onCancel() {
    this.closeDialog();
  }

  onSaved(reviewProfileForm: ReviewFileProfileForm) {
    switch (reviewProfileForm) {
      case ReviewFileProfileForm.Settings:
        this.reload();
        break;
    }
  }

  private reload() {
    this.reload$.next();
  }

  private getInitialTab(reviewFileProfileTab: ReviewFileProfileTab, isAuthor: boolean, userCanManageReviews: boolean): ReviewFileProfileTab {
    if (reviewFileProfileTab === ReviewFileProfileTab.Settings) {
      if (isAuthor && userCanManageReviews) {
        return reviewFileProfileTab;
      } else {
        return ReviewFileProfileTab.Reviewers;
      }
    } else if (isEnumValue(ReviewFileProfileTab, reviewFileProfileTab)) {
      return reviewFileProfileTab;
    } else {
      return ReviewFileProfileTab.Reviewers;
    }
  }
}
