import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DataStreamService } from '@portal-core/data-stream/services/data-stream.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { NotificationPage } from '@portal-core/notifications/models/notification-page.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { NotificationList } from '@portal-core/notifications/util/notification-list';
import { filter } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-user-notification-list',
  templateUrl: './user-notification-list.component.html',
  styleUrls: ['./user-notification-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNotificationListComponent extends NotificationList implements OnChanges {
  @HostBinding('class.mc-notification-list') hostClass: boolean = true;
  @Input() licenseUser: LicenseUser;

  constructor(protected notificationsService: NotificationsService, protected dataStreamService: DataStreamService, cdr: ChangeDetectorRef) {
    super(notificationsService, dataStreamService, cdr);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseUser) {
      this.onConfigurationChanged();
    }
  }

  protected isConfigured(): boolean {
    return this.licenseUser && (typeof this.licenseUser.LicenseId === 'number' && !!this.licenseUser.User.Id);
  }

  protected fetchNotifications$(pageSize: number, pageIndex: number): Observable<NotificationPage> {
    return this.notificationsService.getNotificationsByUserIdAndLicenseId$(this.licenseUser.User.Id, this.licenseUser.LicenseId, pageSize, pageIndex);
  }

  protected filterNotifications(notifications: { [id: string]: Notification }): Notification[] {
    return filter(notifications, (notification: Notification) => {
      return (notification.LicenseId === this.licenseUser.LicenseId || typeof notification.LicenseId !== 'number') &&
        (notification.InvokerUserId === this.licenseUser.User.Id || notification.UserId === this.licenseUser.User.Id);
    });
  }
}
