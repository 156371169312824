import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { NotificationListComponent } from '@portal-core/notifications/components/notification-list/notification-list.component';
import { NotificationModule } from '@portal-core/notifications/components/notification/notification.module';

@NgModule({
  declarations: [
    NotificationListComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    NotificationModule
  ],
  exports: [
    NotificationListComponent
  ]
})
export class NotificationListModule { }
