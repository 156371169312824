import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageLanguageBranchesService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-translation-package-language-branch-deletion-form',
  templateUrl: './translation-package-language-branch-deletion-form.component.html',
  styleUrls: ['./translation-package-language-branch-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageLanguageBranchDeletionFormComponent {
  @Input() translationPackageLanguageBranch: TranslationPackageLanguageBranch;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private errorService: ErrorService, private snackBar: MatSnackBar, private translationPackageLanguageBranchService: TranslationPackageLanguageBranchesService) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.translationPackageLanguageBranchService.deletePackages$([this.translationPackageLanguageBranch.Id]).subscribe(() => {
      this.savingState.update(false);

      this.snackBar.open('The translation branch has been deleted.', 'OK', { duration: 2500 });
      this.deleted.emit();
    }, error => {
      this.savingState.update(false, 'Unable to delete the translation branch.', this.errorService.getErrorMessages(error));
    });
  }

}
