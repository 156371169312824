import { Directive, Input } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { FilterableListBase } from '@portal-core/ui/page-filters/util/filterable-list-base';

/**
 * ListBase
 * The base class for list components.
 */
@Directive()
export abstract class ListCoreBase<T> extends FilterableListBase<T> {
  /** Class to be added to the list element */
  @Input() listClass?: string;
  /** The background color of the loader. */
  @Input() loaderBackgroundColor: 'body' | 'component';
  /** The foreground color of the loader. */
  @Input() loaderColor?: ThemePalette;

  constructor(collectionService: CollectionServiceBase<T>) {
    super(collectionService);
  }
}
