import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Conversation } from '@portal-core/messages/models/conversation.model';
import { MessagePage } from '@portal-core/messages/models/message-page.model';
import { Message } from '@portal-core/messages/models/message.model';
import { ConversationsService } from '@portal-core/messages/services/conversations.service';
import { MessagesService } from '@portal-core/messages/services/messages.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { filter } from 'lodash';
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, distinctUntilChanged, first, map, of, startWith, switchMap, tap } from 'rxjs';

enum AutocompleteOptionType {
  Team,
  User
}

interface AutocompleteOption {
  Description: string;
  Entity: LicenseUser | Team;
  Id: number | string;
  Type: AutocompleteOptionType;
}

enum DialogMode {
  Loading,
  ErrorLoading,
  AllConversations,
  UnreadConversations
}

enum ContentMode {
  CreatingConversation,
  ViewingConversation
}

@Component({
  selector: 'mc-message-center-dialog-card',
  templateUrl: './message-center-dialog-card.component.html',
  styleUrls: ['./message-center-dialog-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe()
export class MessageCenterDialogCardComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '70rem',
    panelClass: 'mc-message-center-dialog-container' // Need to apply overflow styles to make the new conversation button fully visible
  };

  @ViewChild('participantsInput') participantsInput: ElementRef;
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('conversationMessages') conversationMessagesElement: ElementRef;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  DialogMode = DialogMode;
  ContentMode = ContentMode;

  dialogMode: DialogMode;
  contentMode: ContentMode;
  errorsLoading: string[];
  licenseId: number;
  licenseUser: LicenseUser;
  conversations$: Observable<Conversation[]>;
  unreadConversations$: Observable<Conversation[]>;
  unreadCount$: Observable<number>;
  conversation$: Observable<Conversation>;
  selectedConversationId$ = new BehaviorSubject<number>(null);
  messages$: Observable<Message[]>;
  oldestMessageId: number;
  loadingMoreMessagesError: string;
  loadingMoreMessages: boolean;
  allMessagesLoaded = false;
  loadingMessages = true;
  selectedConversationSubscription: Subscription;
  autocompleteFilteredItems$: Observable<AutocompleteOption[]>;
  allLicenseTeams: Team[];
  licenseUsersSubscription: Subscription;
  newConversationParticipants$ = new BehaviorSubject<AutocompleteOption[]>([]);
  addParticipantsToConversationCtrl = new UntypedFormControl();
  unreadCountSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<MessageCenterDialogCardComponent>,
    public dialog: MatDialog,
    private conversationsService: ConversationsService,
    private messagesService: MessagesService,
    private teamsService: TeamsService,
    private licenseUsersService: LicenseUsersService,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.licenseUser = this.data.licenseUser;
    this.licenseId = this.licenseUser.LicenseId;
    this.errorsLoading = null;
    this.dialogMode = DialogMode.Loading;

    // Create an observable for the all the license users
    const licenseUsers$ = this.licenseUsersService.getAllLicenseUsersByLicenseId$(this.licenseId, false).pipe(
      map(licenseUsers => licenseUsers.filter(licenseUser => licenseUser.SeatType === LicenseUserSeatType.Author))
    );

    // Create an observable for the new conversation participant auto complete list
    this.autocompleteFilteredItems$ = combineLatest(
      // Observe changes to the license users
      licenseUsers$,
      // Observe changes to the auto complete field
      this.addParticipantsToConversationCtrl.valueChanges.pipe(
        startWith(null)
      ),
      // Observe changes to the participants in the new conversation
      this.newConversationParticipants$,
    ).pipe(
      map(([licenseUsers, filter, participants]) => this.filterAutocompleteOptions(licenseUsers, filter, participants))
    );

    // Create observables for the conversations
    this.conversations$ = this.conversationsService.getConversationsByLicenseId$(this.licenseId, { forceApiRequest: true });

    // Sends the event to Google Analytics
    this.analyticsService.trackEvent('message_center_open', {
      'event_category': 'Message Center',
      'event_label': 'Message Center Opened',
      'license_id': this.licenseId
    });

    this.unreadConversations$ = this.conversations$.pipe(
      map(conversations => conversations.filter(conversation => conversation.CountUnread > 0))
    );
    this.unreadCount$ = this.conversations$.pipe(
      map(conversations => {
        return conversations.reduce((totalUnreadCount, conversation) => {
          if (Number.isInteger(conversation.CountUnread)) {
            return totalUnreadCount + conversation.CountUnread;
          } else {
            return totalUnreadCount;
          }
        }, 0);
      })
    );

    this.unreadCountSubscription = this.unreadCount$.subscribe(unreadCount => {
      if (unreadCount === 0) {
        this.licenseUsersService.setLicenseUserHasNewMessages(this.licenseUser.Id, false);
      }
    });

    // Create an observable for the selected conversation
    this.conversation$ = this.selectedConversationId$.pipe(
      // Only do something if the conversation id has changed
      distinctUntilChanged(),
      // Grab the conversation out of the data store
      switchMap(conversationId => {
        if (Number.isInteger(conversationId)) {
          return this.conversationsService.getItemById$(conversationId, { allowApiRequest: false });
        } else {
          return of(null);
        }
      })
    );

    // Create an observable for the messages of the selected conversation
    this.messages$ = this.selectedConversationId$.pipe(
      // Only do something if the conversation id has changed
      distinctUntilChanged(),
      // Load the most recent messages for the conversation
      switchMap(conversationId => {
        if (Number.isInteger(conversationId)) {
          // Remove existing messages for the conversation so that we start with only the recent shown
          return this.messagesService.removeConversationMessages$(conversationId).pipe(
            // The messages are now loading
            tap(() => this.loadingMessages = true),
            // Grab the most recent messages
            switchMap(() => this.loadMessages$(conversationId)),
            // The messages are done loading
            tap(() => this.loadingMessages = false),
            catchError(() => {
              this.loadingMessages = false;
              return of(null);
            }),
            // Scroll to the bottom of the message list
            tap(() => this.scrollToElementBottom(this.conversationMessagesElement))
          );
        } else {
          return of(null);
        }
      }),
      // Grab the messages out of the data store
      switchMap((messagePage: MessagePage) => {
        if (messagePage) {
          // Filter the messages to only include this conversation
          return this.messagesService.getItems$().pipe(
            map(messages => filter(messages, message => message.ConversationId === messagePage.Conversation.Id))
          );
        } else {
          return of(null);
        }
      }),
      // Sort the messages by id (aka creation order)
      map((messages: Message[]) => {
        if (Array.isArray(messages)) {
          return messages.sort((messageA, messageB) => messageA.Id - messageB.Id);
        } else {
          return null;
        }
      }),
      // Keep track of the oldest message loaded
      tap(messages => {
        if (Array.isArray(messages) && messages.length > 0) {
          this.oldestMessageId = messages[0].Id;
        } else {
          this.oldestMessageId = null;
        }
      })
    );

    // Subscribe to the selected conversation changing
    this.selectedConversationSubscription = this.selectedConversationId$.pipe(
      // Only do something if the conversation id has changed
      distinctUntilChanged(),
    ).subscribe(conversationId => {
      // If there is a newly selected conversation then clear out the message field and give it focus
      if (Number.isInteger(conversationId)) {
        this.messageInput.nativeElement.value = '';
        this.setFocusOnElement(this.messageInput);
      }

      // Clear out message loading errors
      this.loadingMoreMessagesError = null;
    });

    // Do the initial load
    combineLatest(
      licenseUsers$,
      this.teamsService.getTeamsByUserAndLicense$(this.licenseUser.User.Id, this.licenseId),
      this.conversations$
    ).pipe(
      first()
    ).subscribe(([licenseUsers, teams, conversations]) => {
      this.allLicenseTeams = teams;
      this.dialogMode = DialogMode.AllConversations;
      this.contentMode = ContentMode.ViewingConversation;

      const selectedLicenseUser = this.data.selectedLicenseUser;

      if (selectedLicenseUser) {
        const existingConversations = conversations.filter(conversation => conversation?.UserOpponents?.length === 1 && conversation.UserOpponents[0].Id === selectedLicenseUser.User.Id);

        if (existingConversations.length > 0) {
          this.contentMode = ContentMode.ViewingConversation;
          // The message input isn't immediately available when the dialog is opened to send a message to a user.
          // Use setTimeout to wait for the DOM to be ready
          setTimeout(() => {
            this.selectedConversationId$.next(existingConversations[0].Id)
          }, 0)
        } else {
          const autoCompleteOption: AutocompleteOption = {
            Description: this.data.selectedLicenseUser.User.FullName,
            Entity: selectedLicenseUser,
            Id: selectedLicenseUser.User.Id,
            Type: AutocompleteOptionType.User
          };

          this.contentMode = ContentMode.CreatingConversation;
          this.newConversationParticipants$.next([autoCompleteOption]);
        }
      }
    }, error => {
      this.errorsLoading = this.errorService.getErrorMessages(error);
      this.dialogMode = DialogMode.ErrorLoading;
    });
  }

  onAutocompleteOptionRemoved(option: AutocompleteOption) {
    const participants = this.newConversationParticipants$.value.slice();

    const index = participants.indexOf(option);
    if (index >= 0) {
      participants.splice(index, 1);
      this.newConversationParticipants$.next(participants);
    }
  }

  onAutocompleteOptionSelected(event: MatAutocompleteSelectedEvent) {
    const participants = this.newConversationParticipants$.value.slice();

    participants.push(event.option.value);
    this.newConversationParticipants$.next(participants);
    this.participantsInput.nativeElement.value = '';
    this.addParticipantsToConversationCtrl.setValue(null);
  }

  filterAutocompleteOptions(licenseUsers: LicenseUser[], filterValue: string | AutocompleteOption, participants: AutocompleteOption[]): AutocompleteOption[] {
    // If there is a team in the participants then nothing else can be added so return an empty array
    const participantsIncludeTeam = participants.some(option => {
      return option.Type === AutocompleteOptionType.Team;
    });

    if (participantsIncludeTeam) {
      return [];
    }

    // If there is a user in the participants then only users can be added so return only users
    const licenseUserParticipants = participants.filter(option => option.Type === AutocompleteOptionType.User);
    const participantsIncludeUser = licenseUserParticipants.length > 0;

    let filteredTeams: Team[] = [];
    let filteredLicenseUsers: LicenseUser[] = licenseUsers.filter(licenseUser => {
      return !licenseUserParticipants.some(option => option.Id === licenseUser.User.Id);
    });
    const filterString = typeof filterValue === 'string' ? filterValue :
      filterValue ? filterValue.Description : null;

    // If there is a value to filter by then filter all the teams and license users
    if (filterValue) {
      if (!participantsIncludeUser) {
        filteredTeams = this.allLicenseTeams.filter(team => team.Name.toLowerCase().indexOf(filterString) === 0);
      }
      filteredLicenseUsers = filteredLicenseUsers.filter(licenseUser => {
        return licenseUser.User.FirstName.toLowerCase().indexOf(filterString.toLowerCase()) === 0 ||
          licenseUser.User.LastName.toLowerCase().indexOf(filterString.toLowerCase()) === 0;
      });
      // Else use all the teams and license users BUT make sure to return a copy of the arrays
    } else {
      if (!participantsIncludeUser) {
        filteredTeams = this.allLicenseTeams.slice();
      }
    }

    return filteredTeams.map(team => {
      return {
        Description: team.Name,
        Entity: team,
        Id: team.Id,
        Type: AutocompleteOptionType.Team
      } as AutocompleteOption;
    }).concat(filteredLicenseUsers.map(licenseUser => {
      return {
        Description: licenseUser.User.FullName,
        Entity: licenseUser,
        Id: licenseUser.User.Id,
        Type: AutocompleteOptionType.User
      } as AutocompleteOption;
    }));
  }

  loadMoreMessages(conversationId: number) {
    // Get the current scroll offset from the bottom. It will be used to maintain the current position in the message list
    const scrollOffsetFromBottom = this.conversationMessagesElement.nativeElement.scrollHeight - this.conversationMessagesElement.nativeElement.scrollTop;
    this.loadingMoreMessagesError = null;
    this.loadingMoreMessages = true;

    this.loadMessages$(conversationId, this.oldestMessageId).subscribe(() => {
      this.loadingMoreMessages = false;

      // Restore the scroll position to the previous offset
      requestAnimationFrame(() => {
        this.conversationMessagesElement.nativeElement.scrollTop = this.conversationMessagesElement.nativeElement.scrollHeight - scrollOffsetFromBottom;
      });
    }, () => {
      this.loadingMoreMessages = false;
      this.loadingMoreMessagesError = 'There was a problem loading more messages.';
    });
  }

  loadMessages$(conversationId: number, messageId?: number): Observable<MessagePage> {
    return this.conversationsService.getConversationMessages$(conversationId, true, 20, messageId).pipe(
      tap(messagePage => {
        const senderIds = messagePage.Messages.map(message => message.Sender.Id);
        this.licenseUsersService.loadLicenseUsersByUserId$(messagePage.Conversation.LicenseId, senderIds).subscribe();
        this.allMessagesLoaded = messagePage.AllMessagesLoaded;
      })
    );
  }

  onStartNewConversationClicked() {
    this.contentMode = ContentMode.CreatingConversation;
    this.selectedConversationId$.next(null);
    this.newConversationParticipants$.next([]);
    this.setFocusOnElement(this.participantsInput);
  }

  onConversationClicked(conversation: Conversation) {
    // TODO: if the messages endpoint is changed to return a CountUnread of zero then this call to updateItems$ does not need to happen
    this.conversationsService.updateItems$({
      [conversation.Id]: { CountUnread: 0 }
    });

    this.contentMode = ContentMode.ViewingConversation;
    this.selectedConversationId$.next(conversation.Id);
  }

  onShowInboxClicked() {
    this.dialogMode = DialogMode.AllConversations;
  }

  onShowUnreadOnlyClicked() {
    this.dialogMode = DialogMode.UnreadConversations;
  }

  onMessageEnterKeyDown() {
    const messageBody = this.messageInput.nativeElement.value.trim();
    if (!messageBody) {
      return;
    }

    if (this.contentMode === ContentMode.CreatingConversation) {
      const participants = this.newConversationParticipants$.value;

      if (participants.length > 0) {
        const recipentIds: string[] = [];
        let teamId: number = null;

        participants.forEach(option => {
          if (option.Type === AutocompleteOptionType.Team) {
            teamId = option.Id as number;
          } else {
            recipentIds.push(option.Id as string);
          }
        });

        this.conversationsService.createConversation$(this.licenseUser.User.Id, this.licenseId, messageBody, teamId, recipentIds).subscribe(newConversation => {
          this.dialogMode = DialogMode.AllConversations;
          this.contentMode = ContentMode.ViewingConversation;
          this.selectedConversationId$.next(newConversation.Id);
        });
      }
    } else if (this.contentMode === ContentMode.ViewingConversation) {
      this.conversationsService.addMessageToConversation$(this.selectedConversationId$.value, messageBody).subscribe(() => {
        this.messageInput.nativeElement.value = '';
        // Scroll to the bottom of the message list to show the new message
        this.scrollToElementBottom(this.conversationMessagesElement);
      }, () => {
        this.snackBar.open('An error occurred while sending your message', 'OK', { duration: 2500 });
      });
    }
  }

  onRemoveConversationClicked(conversation: Conversation) {
    this.conversationsService.hideConversation$(conversation.Id).subscribe(() => {
      this.snackBar.open('Conversation removed', 'OK', { duration: 2500 });

      // If the selected conversation was removed then unselect it
      if (conversation.Id === this.selectedConversationId$.value) {
        this.selectedConversationId$.next(null);
      }
    }, () => {
      this.snackBar.open('An error occurred while attempting to remove the conversation', 'OK', { duration: 2500 });
    });
  }

  onRemoveMessageClicked(message: Message) {
    this.conversationsService.deleteMessage$(message.Id).subscribe(() => {
      this.snackBar.open('Message deleted', 'OK', { duration: 2500 });
    }, () => {
      this.snackBar.open('An error occurred while attempting to delete the message', 'OK', { duration: 2500 });
    });
  }

  setFocusOnElement(focusElement: ElementRef) {
    setTimeout(() => focusElement.nativeElement.focus(), 0);
  }

  scrollToElementBottom(elementToScroll: ElementRef) {
    requestAnimationFrame(() => elementToScroll.nativeElement.scrollTop = elementToScroll.nativeElement.scrollHeight);
  }
}
