import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { GridDateRangeComponent } from './grid-date-range.component';

@NgModule({
  declarations: [
    GridDateRangeComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [
    GridDateRangeComponent
  ]
})
export class GridDateRangeModule { }
