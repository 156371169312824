import { FocusMonitor } from '@angular/cdk/a11y';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, QueryList, Self, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { MatMenuPosition } from '@portal-core/general/models/mat-menu-position.model';
import { TreeService } from '@portal-core/general/services/tree.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { AllFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFilesTreeItemDirective } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.directive';
import { ProjectFilesTreeContextMenuOptions } from '@portal-core/project-files/enums/project-files-tree-context-menu-options.enum';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { CreateProjectFileEvent } from '@portal-core/project-files/events/create-project-file.event';
import { RenameProjectFileEvent } from '@portal-core/project-files/events/rename-project-file.event';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { ProjectFilesChecklistService } from '@portal-core/project-files/services/project-files-checklist.service';
import { ProjectFilesTreeControlService } from '@portal-core/project-files/services/project-files-tree-control.service';
import { ProjectFilesTreeDataService } from '@portal-core/project-files/services/project-files-tree-data.service';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { ProjectFilesTreeType } from '@portal-core/project-files/types/project-files-tree.type';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { ContextMenuItemsDirective } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.directive';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subject, combineLatest, forkJoin, map, mergeMap, of, takeUntil } from 'rxjs';

export interface ProjectFileContextMenuData {
  projectId: number;
  branchName: string;
  filePath: string;
};

export interface TreeData {
  projectId: number;
  branchName: string;
}

@Component({
  selector: 'mc-project-files-tree',
  templateUrl: './project-files-tree.component.html',
  styleUrls: ['./project-files-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectFilesTreeDataService, ProjectFilesTreeControlService, ProjectFilesChecklistService]
})

export class ProjectFilesTreeComponent extends CustomInputBase<string[]> implements OnInit, OnChanges, OnDestroy {
  @Input() filePath: string = null;
  /** The place in the tree to scroll to on open if a user hasn't selected a value yet. */
  @Input() defaultPath: string = null;
  @Input() includeFiles: boolean = true;
  @Input() rootFolder: string = ProjectFolder.Root;
  @Input() show404ErrorAsEmptyTree: boolean = false;
  @Input() projectFilesTreeType: ProjectFilesTreeType = 'default';
  @Input() ignoreInitialFilters: boolean = false;
  @Input() showIcons?: boolean = true;
  @Input() fileFilter: string = AllFileFilter;
  @Input() treeClass?: string;
  @Input() treeData: TreeData;
  @Input() disableSelectionTree: boolean = false;
  @Input() disableFolderCheckboxes: boolean = false;

  @Output() createFile: EventEmitter<CreateProjectFileEvent> = new EventEmitter<CreateProjectFileEvent>();
  @Output() fileDeleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() fileRenamed: EventEmitter<RenameProjectFileEvent> = new EventEmitter<RenameProjectFileEvent>();
  @Output() fileSelected: EventEmitter<ProjectFileFlatNode> = new EventEmitter<ProjectFileFlatNode>();
  @Output() pathFiltersChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  @PropertyObservable('treeData') treeData$: Observable<any>;
  @InputObservable('fileFilter') fileFilter$: Observable<string>;
  @PropertyObservable('treeClass') treeClass$: Observable<string>;
  @PropertyObservable('projectFilesTreeType') projectFilesTreeType$: Observable<ProjectFilesTreeType>;

  @ContentChild(ContextMenuItemsDirective) contextMenuItemsDirective: ContextMenuItemsDirective;
  @ContentChildren(ProjectFilesTreeItemDirective) treeItemDirectives: QueryList<ProjectFilesTreeItemDirective>;
  @ViewChild('viewport', { static: false }) viewPortRef: CdkVirtualScrollViewport;
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;
  @ViewChild('filesTreeContainer', { static: true }) filesTreeContainerRef: ElementRef<HTMLInputElement>;

  TriSelectedState: typeof TriSelectedState = TriSelectedState;

  /** Required by CustomInputBase */
  controlType: string = 'mc-project-files-tree-input';
  projectId: number;
  branchName: string;
  contextMenuItemsTemplate: TemplateRef<any>;
  contextMenuPosition: MatMenuPosition = { x: '0px', y: '0px' };
  dataSource: MatTreeFlatDataSource<ProjectFileNode, ProjectFileFlatNode>;
  emptyTree: boolean;
  selectedDirectoryPath: string;
  /** This fake tree control is only needed for mat-tree styles to be included in the template. */
  fakeTreeControl: FlatTreeControl<void> = new FlatTreeControl<void>(null, null);
  loadingState: LoadingState<string> = new LoadingState<string>();
  projectFile: ProjectFile;
  projectFilesTreeContextMenuOptions: typeof ProjectFilesTreeContextMenuOptions = ProjectFilesTreeContextMenuOptions;
  treeClasses$: Observable<string[]>;
  treeControl: FlatTreeControl<ProjectFileFlatNode>;
  treeFlattener: MatTreeFlattener<ProjectFileNode, ProjectFileFlatNode>;
  userCanRunCreateEditFile$: Observable<boolean>;
  userCanRunCreateEditFile: boolean = false;
  pathFilterChangedByUser: boolean = false;

  private unsubscribe = new Subject<void>();

  /**  exposes a view over the data provided by a data source. In this case, it shows the visible nodes in the tree, so it was easy to find the index to scroll to programmatically.
   *   @param viewChange A stream that emits whenever the CollectionViewer starts looking at a new portion of the data. The start index is inclusive, while the end is exclusive.
  . */
  private dataSourceViewer: CollectionViewer = {
    viewChange: new Observable<ListRange>()
  };
  expandedNodeList: ProjectFileFlatNode[] = [];

  get treeItemFileDirective(): ProjectFilesTreeItemDirective {
    return this.treeItemDirectives?.find(treeItemDirective => treeItemDirective.templateName === 'file');
  }

  get treeItemFolderDirective(): ProjectFilesTreeItemDirective {
    return this.treeItemDirectives?.find(treeItemDirective => treeItemDirective.templateName === 'folder');
  }

  constructor(
    private projectFilesTreeDataService: ProjectFilesTreeDataService,
    private projectFilesService: ProjectFilesService,
    private projectFilesTreeControlService: ProjectFilesTreeControlService,
    private errorService: ErrorService,
    private treeService: TreeService,
    private permissionsService: PermissionsService,
    private projectFilesChecklistService: ProjectFilesChecklistService,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    protected focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  ngOnInit() {
    this.treeClasses$ = combineLatest([
      this.treeClass$,
      this.projectFilesTreeType$
    ]).pipe(
      map(([treeClass, projectFilesTreeType]) => {
        const classes: string[] = [];

        if (treeClass) {
          classes.push(treeClass);
        }
        if (projectFilesTreeType === 'selection') {
          classes.push('mc-selection-tree');
        }

        return classes;
      })
    );

    this.userCanRunCreateEditFile$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.CreateEditFiles, this.projectId);
    this.userCanRunCreateEditFile$.pipe(takeUntil(this.unsubscribe)).subscribe(permission => this.userCanRunCreateEditFile = permission);
    this.projectFilesTreeControlService.pathFilters = !this.value?.length ? [''] : this.value;
    this.projectFilesTreeDataService.includeFiles = this.includeFiles;
    this.projectFilesTreeDataService.fileFilter = this.fileFilter;

    this.treeFlattener = new MatTreeFlattener(this.projectFilesTreeControlService.transformer.bind(this.projectFilesTreeControlService),
      this.projectFilesTreeControlService.getLevel, this.projectFilesTreeControlService.isExpandable, this.projectFilesTreeControlService.getChildren);
    this.treeControl = new FlatTreeControl<ProjectFileFlatNode>(this.projectFilesTreeControlService.getLevel, this.projectFilesTreeControlService.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.projectFilesTreeDataService.dataChange.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      // this function is called only on the first load (or reload) of this tree.
      if (!this.dataSource.data?.length && data.length) {
        this.projectFilesTreeDataService.projectId = this.projectId;
        this.scrollToPath(this.filePath ?? this.defaultPath);
      } else {
        this.loadingState.update(false);
      }
      this.dataSource.data = data;
    });
    this.projectFilesTreeDataService.toggleTreeNode.pipe(takeUntil(this.unsubscribe)).subscribe((flatNodeToToggle) => {
      this.treeControl.toggle(flatNodeToToggle);
    });
    this.treeData$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.projectId = this.treeData?.projectId;
      this.branchName = this.treeData?.branchName;
      this.loadProjectFileRootTree();
    });

    // get the visible nodes at all times so it was easier to figure out what to scroll to.
    this.dataSource.connect(this.dataSourceViewer).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.expandedNodeList = data;
    })
    this.fileFilter$.pipe(takeUntil(this.unsubscribe)).subscribe(fileFilter => this.projectFilesTreeDataService.fileFilter = fileFilter);
  }

  ngAfterContentInit() {
    this.contextMenuItemsTemplate = this.contextMenuItemsDirective && this.contextMenuItemsDirective.templateRef || null;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.value && !changes.value.firstChange && !this.pathFilterChangedByUser) {
      this.projectFilesTreeDataService.setTreeSelection(this.value);
    }
    this.pathFilterChangedByUser = false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** Returns true if the project files tree input is empty. It is considered empty if the value's first value is ''. */
  get empty(): boolean {
    return this.value?.[0] === '';
  }

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return '';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.filesTreeContainerRef;
  }

  /** Sets the value and path properties on the file path  picker and notifies Angular Forms of the changes.  */
  private setValueFromUI(value: string[]) {
    this.value = value;
    this.onChange(this.value); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
    this.projectFilesTreeControlService.pathFilters = this.value;
  }

  onContextMenu(event: MouseEvent, node: ProjectFileFlatNode) {
    if (this.projectFilesTreeType === 'link' && (node.expandable && this.userCanRunCreateEditFile || !node.expandable)) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'node': node };
      this.contextMenu.openMenu();
    }
  }

  onPathFiltersChanged(pathFilters: string[]) {
    this.ignoreInitialFilters = false;
    this.pathFiltersChanged.emit(pathFilters);
    this.setValueFromUI(pathFilters);
  }

  onFileSelected(node: ProjectFileFlatNode) {
    // Update UI to show file selected
    this.filePath = node.path;
    this.fileSelected.emit(node);
  }

  onRetryLoadNodes() {
    // If true sets the UI to have the right selected path
    this.loadProjectFileRootTree();
  }

  // if one of the root nodes is indeterminate or selected then there is at least one selected node.
  atLeastOneNodeSelected(): boolean {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      const flatFileNode = this.dataSource.data[i].flatFileNode;
      if (flatFileNode.selected === TriSelectedState.Selected || flatFileNode.selected === TriSelectedState.Indeterminate) {
        return true;
      }
    }
    return false;
  }

  checkViewportSize() {
    this.viewPortRef?.checkViewportSize();
  }

  getPathFilters(): string[] {
    return this.value;
  }

  hardReload() {
    this.loadProjectFileRootTree();
  }

  scrollToTop() {
    if (!this.emptyTree) {
      this.viewPortRef?.scrollTo({ top: 0, behavior: 'auto' });
      this.viewPortRef?.checkViewportSize();
    }

  }

  private clearData() {
    if (this.dataSource) this.dataSource.data = [];
    this.projectFilesTreeDataService.nodeMap.clear();
    this.projectFilesTreeControlService.nodeMap.clear();
  }

  private createActivePathsForAPI(filePathParts: string[]): string[] {
    const apiPaths: string[] = [];
    let filePathDirectoryStr: string = '';

    // last one is file and is not needed
    for (let i = 0; i < filePathParts.length - 1; i++) {
      filePathDirectoryStr += filePathParts[i] + '/';
      apiPaths.push(filePathDirectoryStr.substr(0, filePathDirectoryStr.length - 1));
    }

    if (this.rootFolder) {
      apiPaths.splice(0, this.rootFolder.split('/').length);
    }
    return apiPaths;
  }

  scrollToPath(filePath: string) {
    const filePathParts = filePath ? filePath.split('/') : []
    const apiPaths = this.createActivePathsForAPI(filePathParts ?? []);
    // this function is sometimes called outside of how the tree normally loads, so when that happens the loader should hide the data appearing.
    this.loadingState.update(true);

    // if path already exist just open folders and scroll to it.
    if (filePath && this.expandPath(apiPaths)) {
      this.loadingState.update(false);
      this.scrollViewportToPath(filePath);
      return;
    }

    // load missing paths.
    if (!apiPaths.length || this.ignoreInitialFilters) {
      this.loadingState.update(false);
      return;
    } else {
      const pathChildren$ = of(apiPaths).pipe(mergeMap(paths => forkJoin(paths.map(path => this.projectFilesTreeDataService.getChildren$(path, this.branchName)))));
      pathChildren$.pipe(takeUntil(this.unsubscribe)).subscribe((nodeChildrenCollection: [ProjectFileNode[]]) => {

        for (let i = 0; i < apiPaths.length; i++) {
          if (!nodeChildrenCollection[i]) {
            continue;
          }
          const parent = this.projectFilesTreeDataService.getNode(apiPaths[i]);
          if (!parent) break; // this might happen if the root folder is changed but the active path points to a different root folder
          parent.childrenChange.next(nodeChildrenCollection[i]);
          this.projectFilesTreeDataService.dataChange.next(this.projectFilesTreeDataService.dataChange.value);
          nodeChildrenCollection[i].forEach(childNode => { childNode.parentNode = parent; this.projectFilesChecklistService.setSelection(childNode, this.value); });
          parent.flatFileNode.loaded = true;
        }
        this.loadingState.update(false);

        // open folders and scroll to active file/folder
        this.expandPath(apiPaths);
        this.scrollViewportToPath(filePath);

      });

    }
  }

  private expandPath(pathParts: string[]): boolean {
    for (let i = 0; i < pathParts.length; i++) {
      const node = this.treeControl.dataNodes.find(node => node.path === pathParts[i]);
      if (!node || !node.loaded) {
        return false;
      }
      this.treeControl.expand(node);
    }
    return true;
  }

  private scrollViewportToPath(path: string) {
    // grab the first substring in the path that is found in the tree
    let currentSubStr = path;
    while (currentSubStr) {
      const indexToPath = this.expandedNodeList.findIndex(node => node.path === currentSubStr);
      if (indexToPath >= 0) {
        setTimeout(() => this.viewPortRef.scrollToIndex(indexToPath), 0);
        return;
      }
      const lastIndexOfFolder = currentSubStr.lastIndexOf('/');
      currentSubStr = currentSubStr.substring(0, lastIndexOfFolder);
    }

  }

  private loadProjectFileRootTree() {
    this.clearData();
    this.emptyTree = true;
    if (typeof this.projectId === 'number' && typeof this.branchName === 'string') {
      this.loadingState.update(true);

      this.projectFilesService.getProjectFileTree$(this.projectId, this.branchName, this.rootFolder, this.includeFiles, this.fileFilter).pipe(takeUntil(this.unsubscribe)).subscribe(projectFiles => {
        if (Array.isArray(projectFiles) && projectFiles.length > 0) {
          this.emptyTree = false;
          const nodes = this.projectFilesTreeDataService.initialize(projectFiles);
          this.projectFilesTreeDataService.setTree(nodes, this.value, this.ignoreInitialFilters);
        } else {
          this.loadingState.update(false);
        }
      }, error => {
        if (this.show404ErrorAsEmptyTree && error.status === 404) {
          this.loadingState.update(false);
        } else {
          this.emptyTree = false;
          this.loadingState.update(false, 'There was a problem loading the file tree.', this.errorService.getErrorMessages(error));
        }
      });
    }
  }

  changeRootFolder(newRootDirectory: string, newFileFilter?: string) {
    this.rootFolder = newRootDirectory ? newRootDirectory : ProjectFolder.Root;
    if (newFileFilter)
      this.fileFilter = newFileFilter ? newFileFilter : AllFileFilter;
    this.loadProjectFileRootTree();
  }

  selectedDirectoryPathChanged(newDirectoryPath: string) {
    this.selectedDirectoryPath = newDirectoryPath;
  }

  nodeToggled(node: ProjectFileFlatNode) {
    this.treeControl.toggle(node);
  }

  findNodeByPathAndUnselect(path: string, pathFilters: string[]) {
    this.setValueFromUI(pathFilters);
    const node = this.projectFilesTreeDataService.getNode(path);
    if (node) {
      node.flatFileNode.selected = TriSelectedState.NotSelected;
      this.cdr.markForCheck();
    }
  }

  setSelection() {
    this.projectFilesTreeDataService.setTreeSelection(this.value);
  }


}
