import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthVendor } from '@portal-core/oauth/enums/oauth-vendor.enum';

// OAuthState
export class OAuthStateModel {
  lastActivatedRoute: string[];
  state: string;
  vendor: OAuthVendor;
}

// Actions
export class Confirm {
  static readonly type: string = '[OAuth] Confirm';
  constructor(public payload: { lastActivatedRoute: string[], state: string, vendor: OAuthVendor }) {}
}

export class ClearConfirm {
  static readonly type: string = '[OAuth] ClearConfirm';
  constructor(public payload: { vendor: OAuthVendor }) {}
}

// OAuthStateModel
@State<OAuthStateModel>({
  name: 'oAuthState'
})
@Injectable()
export class OAuthState {
  @Selector()
  static vendor(state: OAuthStateModel): OAuthVendor { return state.vendor; }

  @Selector()
  static state(state: OAuthStateModel): string { return state.state; }

  @Selector()
  static lastActivatedRoute(state: OAuthStateModel): string[] { return state.lastActivatedRoute; }

  @Action(Confirm)
  confirm(ctx: StateContext<OAuthStateModel>, action: Confirm) {
    ctx.patchState({
      lastActivatedRoute: action.payload.lastActivatedRoute,
      state: action.payload.state,
      vendor: action.payload.vendor
    });
  }

  @Action(ClearConfirm)
  clearConfirm(ctx: StateContext<OAuthStateModel>, action: ClearConfirm) {
    ctx.patchState({
      lastActivatedRoute: null,
      state: null,
      vendor: null
    });
  }
}

// OAuthDataService Implementation
@Injectable({
  providedIn: 'root'
})
export class OAuthDataService {
  constructor(private store: Store) { }

  getVendor(): OAuthVendor {
    return this.store.selectSnapshot(OAuthState.vendor);
  }

  getVendor$(): Observable<OAuthVendor> {
    return this.store.select(OAuthState.vendor);
  }

  getState(): string {
    return this.store.selectSnapshot(OAuthState.state);
  }

  getState$(): Observable<string> {
    return this.store.select(OAuthState.state);
  }

  getLastActivatedRoute(): string[] {
    return this.store.selectSnapshot(OAuthState.lastActivatedRoute);
  }

  getLastActivatedRoute$(): Observable<string[]> {
    return this.store.select(OAuthState.lastActivatedRoute);
  }

  setConfirm$(vendor: OAuthVendor, state: string, lastActivatedRoute: string[]): Observable<any> {
    return this.store.dispatch(new Confirm({ lastActivatedRoute, state, vendor }));
  }

  clearConfirm$(vendor: OAuthVendor): Observable<any> {
    return this.store.dispatch(new ClearConfirm({ vendor }));
  }
}
