import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mcTrim'
})
export class TrimPipe implements PipeTransform {
  transform(path: string | undefined | null, search: string, side: 'start' | 'end' | 'both' = 'both'): string {
    switch (side) {
      case 'start':
        return path.replace(new RegExp(`^${search}`, 'i'), '');
      case 'end':
        return path.replace(new RegExp(`${search}$`, 'i'), '');
      case 'both':
      default:
        return path.replace(new RegExp(`^${search}`, 'i'), '').replace(new RegExp(`${search}$`, 'i'), '');
    }
  }
}
