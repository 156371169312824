import { updateFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { NodeType } from 'prosemirror-model';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export declare type InsertVariableNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, name?: string) => boolean;

export function insertVariable(variableType: NodeType): InsertVariableNodeCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, name?: string): boolean {
    const selection = editorState.selection;
    const tr = editorState.tr;
    const $from = selection.$from;
    const index = $from.index();

    if (!$from.parent.canReplaceWith(index, index, variableType)) {
      return false;
    }

    if (dispatch) {
      if (selection instanceof NodeSelection && selection.node.type === variableType) {
        updateFlareNodeAttrs(tr, selection.node, $from.pos, { name });
        tr.setSelection(NodeSelection.create(tr.doc, $from.pos));
      } else {
        tr.replaceSelectionWith(variableType.create({ name }))
      }

      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}
