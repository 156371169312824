import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';

@CollectionStateSelectors({
  stateType: LicenseUsersState
})
@State<LicenseUsersState>({
  name: LicenseUsersState.source,
})
@Injectable()
export class LicenseUsersState extends CollectionStateBase {
  static source = 'LicenseUsers';
  getSource(): string {
    return LicenseUsersState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicenseUsersDataService extends CollectionDataServiceBase<LicenseUser> {
  constructor(protected store: Store) {
    super(store, LicenseUsersState);
  }
}
