import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { AccessTreeNode } from '@portal-core/general/models/access-tree-node.model';
import { AccessTreeService } from '@portal-core/general/services/access-tree.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectTeam } from '@portal-core/projects/models/project-team.model';
import { ProjectUser } from '@portal-core/projects/models/project-user.model';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, combineLatest, first, map, tap } from 'rxjs';

enum ProjectAccessTreeNodeType {
  TeamsNode = 'TeamsNode',
  UsersNode = 'UsersNode',
  TeamNode = 'TeamNode',
  UserNode = 'UserNode'
}

@Component({
  selector: 'mc-project-access-form',
  templateUrl: './project-access-form.component.html',
  styleUrls: ['./project-access-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AccessTreeService]
})
export class ProjectAccessFormComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    // Using this.editing isn't entirely accurate but is close enough
    return this.editing;
  }

  ProjectAccessTreeNodeType: typeof ProjectAccessTreeNodeType = ProjectAccessTreeNodeType;

  licenseUsers: LicenseUser[];
  teams: Team[];
  nestedTreeControl: NestedTreeControl<AccessTreeNode>;
  dataSource: MatTreeNestedDataSource<AccessTreeNode> = new MatTreeNestedDataSource<AccessTreeNode>();
  editing: boolean;
  loadingState: LoadingState<string> = new LoadingState<string>();
  savingState: LoadingState<string> = new LoadingState<string>();
  canEdit$: Observable<boolean>;

  hasNestedChild = (index: number, node: AccessTreeNode): boolean => {
    return node.type === ProjectAccessTreeNodeType.TeamsNode || node.type === ProjectAccessTreeNodeType.UsersNode ||
      node.type === ProjectAccessTreeNodeType.TeamNode;
  };

  getChildren = (node: AccessTreeNode): AccessTreeNode[] => {
    if (node.type === ProjectAccessTreeNodeType.TeamsNode || node.type === ProjectAccessTreeNodeType.UsersNode || node.type === ProjectAccessTreeNodeType.TeamNode) {
      return node.children;
    }
  };

  constructor(
    private projectsService: ProjectsService,
    private licenseUsersService: LicenseUsersService,
    private teamsService: TeamsService,
    private permissionsService: PermissionsService,
    private errorService: ErrorService,
    private accessTreeService: AccessTreeService,
    private pageFilterService: PageFilterService
  ) {
    this.dataSource.data = [];
  }

  ngOnInit() {
    this.canEdit$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);
    this.nestedTreeControl = new NestedTreeControl<AccessTreeNode>(this.getChildren);
    this.loadCurrentTree();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && this.project && !this.editing) {
      this.loadCurrentTree();
    }
  }

  onCancelClicked() {
    this.editing = false;
    this.loadCurrentTree();

    // Remove any save errors now that we are leaving the editing mode
    this.savingState.update(false);
  }

  onEditClicked() {
    this.editing = true;
    this.loadLicenseTree();
  }

  onLeafNodeClicked(node: AccessTreeNode) {
    node.selected = !node.selected;
    this.updateCheckbox(node.parent);
  }

  onSubmit() {
    this.savingState.update(true);

    const projectTeams = this.dataSource.data[0].children.filter(node => node.selected).map(teamNode => {
      return {
        ProjectId: this.project.Id,
        TeamId: teamNode.value.teamId
      };
    });

    const projectUsers = this.dataSource.data[1].children.filter(node => node.selected).map(userNode => {
      return {
        UserId: userNode.value.userId,
        ProjectId: this.project.Id,
        LicenseUserId: userNode.value.licenseUserId,
        Status: 0
      };
    });

    const project: Project = {
      Id: this.project.Id,
      LicenseId: this.project.LicenseId,
      ProjectTeams: projectTeams,
      ProjectUsers: projectUsers
    };

    this.projectsService.updateProjectTeamsAndUsers$(project).subscribe(() => {
      this.editing = false;
      this.loadCurrentTree();
      this.savingState.update(false);
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to assign the teams and users to the project.', this.errorService.getErrorMessages(error));
    });
  }

  selectAllChanged(node: AccessTreeNode) {
    this.accessTreeService.selectAll(node);
  }

  updateCheckbox(node: AccessTreeNode) {
    this.accessTreeService.updateSelectAll(node);
  }

  private loadCurrentTree() {
    this.dataSource.data = this.getCurrentProjectAccessTreeData();
  }

  private loadLicenseTree() {
    this.loadingState.update(true);

    // Build a filter for the users that includes users that are active/invited authors
    const usersFilter: PageFilter = {
      ...this.pageFilterService.create({
        Id: 'license-users',
        Type: PageFilterGroupType.Select
      }).select('SeatType', [LicenseUserSeatType.Author]).select('Status', [LicenseUserStatus.Active, LicenseUserStatus.Invited]).value,
      PageNumber: 0,
      PerPage: -1
    };

    combineLatest([
      this.licenseUsersService.getLicenseUsersPageByLicenseId$(this.project.LicenseId, usersFilter).pipe(
        map(page => {
          if (Array.isArray(page.Items)) {
            return page.Items.sort((licenseUserA, licenseUserB) => licenseUserA.User.FullName.localeCompare(licenseUserB.User.FullName));
          } else {
            return page.Items;
          }
        }),
        tap(licenseUsers => this.licenseUsersService.addItems$(licenseUsers))
      ),
      this.teamsService.getTeamsByLicenseId$(this.project.LicenseId, { forceApiRequest: true }).pipe(
        first(teams => !!teams)
      )
    ]).subscribe(([licenseUsers, teams]) => {
      this.licenseUsers = licenseUsers;
      this.teams = teams;

      this.dataSource.data = this.getLicenseProjectAccessTreeData();
      this.accessTreeService.initializeTree(this.dataSource);
      this.accessTreeService.expandNodes(this.nestedTreeControl, this.dataSource.data);

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the teams and users.', this.errorService.getErrorMessages(error));
    });
  }

  private getLicenseProjectAccessTreeData(): AccessTreeNode[] {
    const teamsNode: AccessTreeNode = {
      name: 'Teams',
      type: ProjectAccessTreeNodeType.TeamsNode
    };

    const usersNode: AccessTreeNode = {
      name: 'Users',
      type: ProjectAccessTreeNodeType.UsersNode
    };

    teamsNode.children = this.teams.map(team => this.buildTeamNode(teamsNode, team.Id, this.project.ProjectTeams));
    usersNode.children = this.licenseUsers.map(licenseUser => this.buildUserNode(usersNode, licenseUser.Id, licenseUser.User.Id, this.project.ProjectUsers));

    return [teamsNode, usersNode];
  }

  private getCurrentProjectAccessTreeData(): AccessTreeNode[] {
    const teamsNode: AccessTreeNode = {
      name: 'Teams',
      type: ProjectAccessTreeNodeType.TeamsNode
    };

    const usersNode: AccessTreeNode = {
      name: 'Users',
      type: ProjectAccessTreeNodeType.UsersNode
    };

    teamsNode.children = this.project.ProjectTeams.map(projectTeam => this.buildTeamNode(teamsNode, projectTeam.TeamId));
    usersNode.children = this.project.ProjectUsers.map(projectUser => this.buildUserNode(usersNode, projectUser.LicenseUserId, projectUser.UserId));

    return [teamsNode, usersNode];
  }

  private buildUserNode(parent: AccessTreeNode, licenseUserId: number, userId: string, projectUsers?: ProjectUser[]): AccessTreeNode {
    return {
      parent,
      selected: projectUsers?.some(projectUser => projectUser.LicenseUserId === licenseUserId && projectUser.Status !== LicenseUserStatus.Deactivated) ?? true,
      type: ProjectAccessTreeNodeType.UserNode,
      value: { licenseUserId, userId }
    };
  }

  private buildTeamNode(parent: AccessTreeNode, teamId: number, projectTeams?: ProjectTeam[]): AccessTreeNode {
    return {
      parent,
      selected: projectTeams?.some(projectTeam => projectTeam.TeamId === teamId) ?? true,
      type: ProjectAccessTreeNodeType.TeamNode,
      value: { teamId }
    };
  }
}
