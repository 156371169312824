import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { MathService } from '@portal-core/general/services/math.service';
import { ThemeService } from '@portal-core/general/services/theme.service';
import { ReviewFileUserStatusColorPipe } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-color/review-file-user-status-color.pipe';
import { ReviewPackageAnalytics } from '@portal-core/reviews/review-packages/models/review-package-analytics.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { BarController, BarElement, CategoryScale, Chart, ChartData, ChartOptions, Colors, LinearScale } from 'chart.js';
import { Observable, map } from 'rxjs';

Chart.register(BarController, BarElement, CategoryScale, Colors, LinearScale);

interface ChartColsData {
  Percents: number[];
  Labels: string[];
  TooltipLabels: string[];
  Count: number[];
}

interface ChartCountsData {
  ToDo: number;
  InProgress: number;
  Submitted: number;
}

@Component({
  selector: 'mc-review-file-reviewer-status-bar-chart',
  templateUrl: './review-file-reviewer-status-bar-chart.component.html',
  styleUrls: ['./review-file-reviewer-status-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReviewFileReviewerStatusBarChartComponent implements OnInit {
  @Input() analytics: ReviewPackageAnalytics;
  @Input() keyJustification?: 'around' | 'between' = 'between';

  @InputObservable('analytics') analytics$: Observable<ReviewPackageAnalytics>;

  ReviewFileUserStatus: typeof ReviewFileUserStatus = ReviewFileUserStatus;

  colAmounts: number = 0;
  chartColsData: ChartColsData;
  chartColumnPaletteNames: string[] = [
    this.reviewFileUserStatusColorPipe.transform(ReviewFileUserStatus.Todo),
    this.reviewFileUserStatusColorPipe.transform(ReviewFileUserStatus.InProgress),
    this.reviewFileUserStatusColorPipe.transform(ReviewFileUserStatus.Submitted)
  ];
  chartCounts: ChartCountsData;

  chartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  };

  chartData$: Observable<ChartData<'bar'>>;

  private barThickness: number = 68;

  constructor(private themeService: ThemeService, private mathService: MathService, private reviewFileUserStatusColorPipe: ReviewFileUserStatusColorPipe) { }

  ngOnInit() {
    this.chartData$ = this.analytics$.pipe(
      map((analytics) => {
        if (analytics) {
          this.chartColsData = this.parseChartData(analytics);
          this.colAmounts = this.chartColsData.Labels.length; // used to calculate how thick the chart's bars are.
          this.chartCounts = this.getChartCounts(this.chartColsData.Count);

          const datasets = this.chartColsData.Labels.map((label, i) => {
            return {
              backgroundColor: this.themeService.getColor(this.chartColumnPaletteNames[i]),
              barThickness: this.barThickness,
              data: [this.chartColsData.Percents[i]],
              label: label,
            };
          });

          return {
            datasets,
            labels: new Array(datasets.length).fill('')
          };
        } else {
          this.chartColsData = null;
          this.colAmounts = null;
          this.chartCounts = null;
          return {
            datasets: [],
            labels: []
          };
        }
      })
    );
  }

  parseChartData(analytics: ReviewPackageAnalytics): ChartColsData {
    const chartData = analytics.TotalStatusFileCounts;
    const legendOrder = ['Todo', 'InProgress', 'Submitted'];
    const chartColsData = { Percents: [], Labels: [], TooltipLabels: [], Count: [] };
    chartData.sort((a, b) => legendOrder.indexOf(a.Legend) - legendOrder.indexOf(b.Legend));
    chartData.forEach(({ Legend, Count }) => {
      chartColsData.TooltipLabels.push(Legend);
      chartColsData.Labels.push(Legend.length > 14 ? `${Legend.substr(0, 11)}...` : Legend);
      chartColsData.Percents = this.mathService.roundPercentagesToOneHundred(this.analytics.TotalStatusFileCounts.map(fileCount => fileCount.Percent * 100));
      chartColsData.Count.push(Count);
    });
    return chartColsData;
  }

  private getChartCounts(counts: number[]): ChartCountsData {
    return {
      ToDo: counts[0],
      InProgress: counts[1],
      Submitted: counts[2]
    };
  }
}
