import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW } from '@portal-core/util/window.provider';
import { Subscription } from 'rxjs';

// declare var ga: any;
declare global {
  interface Window {
    gtag: Function;
    dataLayer: [];
  }
}

interface EventData {
  [key: string]: string | number | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private routerEventsSubscription: Subscription;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router) { }

  init(trackingId: string) {
    // This is an adaptation of the code provided by the Google Analytics team to be pasted in a script tag on the root html page.
    this.window.gtag = this.window.gtag || function () { (this.window.dataLayer = window.dataLayer || []).push(arguments); };
    this.window.gtag('js', new Date());
    this.window.gtag('config', trackingId);

    // In a single-page application we will use changes in routing to trigger analytics data collection.
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        const host = window.location.host;
        this.window.gtag('event', 'page_view', { 'full_url': `${host}${url}` });
      }
    });
  }

  destroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
      this.routerEventsSubscription = null;
    }
  }

  trackEvent(name: string, data: EventData) {
    this.window.gtag('event', name, data);
  }
}
