import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { SiteStyles } from '@portal-core/sites/models/site-styles.model';

@CollectionStateSelectors({
  stateType: SiteStylesState
})
@State<SiteStylesState>({
  name: SiteStylesState.source,
})
@Injectable()
export class SiteStylesState extends CollectionStateBase {
  static source: string = 'SiteStyles';
  getSource(): string {
    return SiteStylesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SiteStylesDataService extends CollectionDataServiceBase<SiteStyles> {
  constructor(protected store: Store) {
    super(store, SiteStylesState);
   }
}
