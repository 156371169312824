import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileAsyncPipe } from './review-file-async.pipe';

@NgModule({
  declarations: [ReviewFileAsyncPipe],
  imports: [CommonModule],
  exports: [ReviewFileAsyncPipe],
  providers: [ReviewFileAsyncPipe]
})
export class ReviewFileAsyncModule { }
