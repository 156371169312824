<div class="mc-vertical-split-dialog">
  <div matDialogTitle>
    <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-alert'" class="mc-image-icon mc-image-icon-normal" color="primary"></mat-icon>
    <button mat-icon-button color="primary" class="mc-vertical-split-dialog-close-button" tabindex="-1" [matDialogClose]="false">
      <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <h2 class="mc-vertical-split-dialog-title" *ngIf="title">{{title}}</h2>
    <p class="mc-vertical-split-dialog-body">{{message}}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-center">
    <button mat-raised-button color="primary" matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</div>
