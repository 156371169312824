import { Pipe, PipeTransform } from '@angular/core';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcTranslationPackageAsync'
})
export class TranslationPackageAsyncPipe implements PipeTransform {
  constructor(private translationPackagesService: TranslationPackagesService) { }

  transform(translationPackageId: number | null | undefined, allowApiRequest: boolean = false): Observable<TranslationPackage | null> {
    if (!translationPackageId) {
      return of(null);
    }

    return this.translationPackagesService.getItemById$(translationPackageId, {
      allowApiRequest
    });
  }
}
