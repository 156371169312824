import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from '@common/auth/models/auth.model';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { validate } from '@common/util/validate.operator';
import { AuthRootTokenCookieName } from '@portal-core/auth/constants/auth-cookies.constant';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { LogoutReason } from '@portal-core/auth/enums/logout-reason.enum';
import { OnLoginComplete } from '@portal-core/auth/interfaces/login-complete.interface';
import { AddPasswordRequest } from '@portal-core/auth/models/add-password-request.model';
import { AuthModuleConfig } from '@portal-core/auth/models/auth-module-config.model';
import { AuthUserData } from '@portal-core/auth/models/auth-user-data.model';
import { CentralInstance } from '@portal-core/auth/models/central-instance.model';
import { CompletedInvite } from '@portal-core/auth/models/completed-invite.model';
import { SubdomainLicense } from '@portal-core/auth/models/subdomain-license.model';
import { VerifiedUser } from '@portal-core/auth/models/verified-user.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthApiService } from '@portal-core/auth/services/auth-api.service';
import { AuthDataService } from '@portal-core/auth/services/auth-data.service';
import { AuthLocalStorageCleanupService } from '@portal-core/auth/services/auth-local-storage-cleanup.service';
import { AuthModuleConfigService } from '@portal-core/auth/services/auth-module-config.service';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { RouterService } from '@portal-core/general/services/router.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public supportsMultipleCentralInstances: boolean = true;

  constructor(
    @Inject(AuthModuleConfigService) private config: AuthModuleConfig,
    private authApiService: AuthApiService,
    private authDataService: AuthDataService,
    private authRouteService: AuthRouteService,
    private authUrlService: AuthUrlService,
    private dataService: DataService,
    private injector: Injector,
    private cookieService: CookieService,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private authLocalStorageCleanupService: AuthLocalStorageCleanupService // Required to cleanup old auth data from local storage which isn't used anymore
  ) {
    // Create any effects that have been configured to run with the auth module. The effects need to be instantiated somewhere and this is a good place.
    if (Array.isArray(this.config.effects)) {
      this.config.effects.forEach(effect => this.injector.get<any>(effect));
    }
  }

  /** Returns the logged in user's access token. */
  getAccessToken(): string {
    return this.authDataService.getAccessToken();
  }

  /** Returns an observable of the logged in user's access token. */
  getAccessToken$(): Observable<string> {
    return this.authDataService.getAccessToken$();
  }

  /** Returns a map of access tokens by instance code that the user has logged into. */
  getAccessTokensByInstanceCode(): Dictionary<string> {
    return this.authDataService.getAccessTokensByInstanceCode();
  }

  /** Returns an observable of a map of access tokens by instance code that the user has logged into. */
  getAccessTokensByInstanceCode$(): Observable<Dictionary<string>> {
    return this.authDataService.getAccessTokensByInstanceCode$();
  }

  /** Adds an access token to the map of access tokens by instance code that the user has logged into. */
  addAccessTokenByInstanceCode$(instanceCode: string, token: string): Observable<any> {
    return this.authDataService.addAccessTokenByInstanceCode$(instanceCode, token);
  }

  /** Removes an access token from the map of access tokens by instance code that the user has logged into. */
  removeAccessTokenByInstanceCode$(instanceCode: string): Observable<any> {
    return this.authDataService.removeAccessTokenByInstanceCode$(instanceCode);
  }

  /** Returns the auth data. */
  getAuth(): Auth {
    return this.authDataService.getAuth();
  }

  /** Returns an observable of the auth data. */
  getAuth$(): Observable<Auth> {
    return this.authDataService.getAuth$();
  }

  /** Returns the user's auth data. */
  getAuthUserData(): AuthUserData {
    return this.authDataService.getAuthUserData();
  }

  /** Returns an observable of the user's auth data. */
  getAuthUserData$(options?: GetDataOptions): Observable<AuthUserData> {
    return this.dataService.getData({
      get: () => this.authDataService.getAuthUserData$(),
      fetch: () => this.authApiService.getUserData$(true),
      set: authUserData => this.authDataService.setAuthUserData$(authUserData)
    }, options);
  }

  /** Returns whether or not an auto-login has been attempted. */
  getAutoLoginAttempted(): boolean {
    return this.authDataService.getAutoLoginAttempted();
  }

  /** Returns the number of days until the user's password should expire after being set. */
  getDaysUntilPasswordExpires(): number {
    return this.authDataService.getDaysUntilPasswordExpires();
  }

  /** Returns the last activated route the user was on before they logged out. */
  getLastActivatedRoute(): string[] {
    return this.authDataService.getLastActivatedRoute();
  }

  /** Sets the last activated route the user was on before they logged out. */
  setLastActivatedRoute$(route: string[]): Observable<any> {
    return this.authDataService.setLastActivatedRoute$(route);
  }

  /** Returns the max idle time in minutes before the user should be logged out. */
  getMaxIdleTimeMinutes(): number {
    return this.authDataService.getMaxIdleTimeMinutes();
  }

  /** Returns an observable of the list of available Central instances. */
  getCentralInstances$(): Observable<CentralInstance[]> {
    return this.authApiService.getCentralInstances$();
  }

  /** Returns an observable of the Central instance for a license vanity. */
  getLicenseCentralInstance$(licenseVanityUrl: string): Observable<CentralInstance> {
    return this.authApiService.getLicenseCentralInstance$(licenseVanityUrl);
  }

  /** Returns an observable of the Central instance for an instance code. */
  getCentralInstanceByInstanceCode$(instanceCode: string): Observable<CentralInstance> {
    return this.authApiService.getCentralInstanceByInstanceCode$(instanceCode);
  }

  /** Returns an observable of whether the vanity exists on a license in any Central instance if supported, otherwise just the default instance. */
  getIsVanityUrlPresent$(vanity: string): Observable<MadCloudResult> {
    return this.authApiService.getIsVanityUrlPresent$(vanity, this.supportsMultipleCentralInstances);
  }

  /** Returns the instance code for the central instance that the user is logged into. */
  getInstanceCode(): string {
    return this.authDataService.getInstanceCode();
  }

  /** Returns an observable of the instance code for the central instance that the user is logged into. */
  getInstanceCode$(): Observable<string> {
    return this.authDataService.getInstanceCode$();
  }

  /** Returns the subdomain license. */
  getSubdomainLicense(): SubdomainLicense {
    return this.authDataService.getSubdomainLicense();
  }

  /** Returns an observable of the subdomain license. */
  getSubdomainLicense$(options?: GetDataOptions): Observable<SubdomainLicense> {
    return this.dataService.getData<SubdomainLicense>({
      get: () => this.authDataService.getSubdomainLicense$(),
      fetch: () => this.authApiService.getLicenseByDomain$().pipe(
        map(license => {
          return license ? {
            ...license,
            Subdomain: this.authUrlService.getSubdomain()
          } : null;
        }),
        catchError(error => {
          // A 404 means the subdomain doesn't match a license so set the license to null
          if (error.status === 404) {
            return of(null);
          } else {
            return throwError(error);
          }
        })
      ),
      set: subdomainLicense => this.authDataService.setSubdomainLicense$(subdomainLicense)
    }, options);
  }

  /** Returns the logged in user's id. */
  getUserId(): string {
    return this.authDataService.getUserId();
  }

  /** Returns an observable of the logged in user's id. */
  getUserId$(): Observable<string> {
    return this.authDataService.getUserId$();
  }

  /** Returns the logged in user's name. */
  getUserName(): string {
    return this.authDataService.getUserName();
  }

  /** Returns an observable of the logged in user's name. */
  getUserName$(): Observable<string> {
    return this.authDataService.getUserName$();
  }

  /** Returns whether or not the subdomain has been validated. */
  hasValidatedSubdomain(): boolean {
    return typeof this.getSubdomainLicense() !== 'undefined';
  }

  /** Returns whether or not the subdomain is valid. */
  isValidSubdomain(): boolean {
    return !!this.getSubdomainLicense();
  }

  /** Returns whether or not the logged in user has been authenticated on the backend. */
  isAuthenticated(): boolean {
    return !!this.authDataService.getAccessToken();
  }

  /** Returns an observable of whether or not the logged in user has been authenticated on the backend. */
  isAuthenticated$(): Observable<boolean> {
    return this.authDataService.getAccessToken$().pipe(
      map(token => !!token)
    );
  }

  /** Logs a user in with the user's email and password credentials. */
  login$(email: string, password: string): Observable<Auth> {
    const instanceCode = this.apiService.centralInstanceInstanceCode;

    return this.authApiService.login$(email, password).pipe(
      switchMap(auth => this.setLogin$(auth, instanceCode))
    );
  }

  /**
   * Verifies that the user is logged in and returns the Auth data if the user is logged in.
   * If the user is logged into the root domain and the user is logging into a subdomain this will attempt to auto log in the user. If successful the Auth data will be returned.
   * If the user is not logged in then nothing is returned.
   */
  loginToLicense$(useCredentials: boolean = false): Observable<Auth> {
    const instanceCode = this.apiService.centralInstanceInstanceCode;

    return this.authApiService.loginToLicense$(this.cookieService.get(AuthRootTokenCookieName), useCredentials).pipe(
      switchMap(auth => {
        return this.authDataService.setAutoLoginAttempted$(true).pipe(
          map(() => auth)
        );
      }),
      switchMap(auth => {
        if (auth) {
          return this.setLogin$(auth, instanceCode);
        } else {
          return this.authDataService.logout$(LogoutReason.LoginFailure, instanceCode).pipe(
            map(() => auth)
          );
        }
      })
    );
  }

  /** Begins the logout process for the user by navigating them to the logout route. */
  logout(reason: LogoutReason = LogoutReason.Manual) {
    this.authDataService.setLastActivatedRoute$(this.routerService.getRoutePath(this.route.snapshot)).pipe(
      switchMap(() => {
        return this.authRouteService.navigateToAuthRoute$(AuthRoute.Logout, {
          queryParams: {
            reason
          }
        });
      })
    ).subscribe();
  }

  /** Logs out a user with the API. Optionally redirects the user if the API returns a url to redirect to. */
  logout$(reason: LogoutReason = LogoutReason.Manual): Observable<any> {
    const instanceCode = this.apiService.centralInstanceInstanceCode;

    const apiLogout$ = this.authApiService.logout$().pipe(
      // If the API has an error while logging the user out just ignore it and log the user out of the client
      catchError(() => of(null)),
      switchMap(redirect => {
        return this.authDataService.logout$(reason, instanceCode).pipe(
          map(() => redirect)
        );
      })
    );

    apiLogout$.subscribe(redirect => {
      if (redirect) {
        window.location.href = redirect.replace(/\"/g, '');
      }
    });

    return apiLogout$;
  }

  /** Switches the auth state to a new Central instance. Basically clears out the auth data and token for the logged in user. */
  switchCentralInstance$(instanceCode: string): Observable<any> {
    return this.authDataService.switchCentralInstance$(instanceCode);
  }

  /** Calls OnLoginComplete handlers. */
  emitLoginComplete() {
    if (this.config.onLoginComplete) {
      const handler: OnLoginComplete = this.injector.get<OnLoginComplete>(this.config.onLoginComplete);
      if (handler.onLoginComplete) {
        return handler.onLoginComplete();
      }
    }
  }

  /** Fetches the SSO login url for the current license.  */
  getSsoLoginUrl$(): Observable<string> {
    // This code assumes the user is on the login page and the login page is at the route '/login'.
    // It also assumes that everything before '/login' should be kept.
    // Currently this is true for the portals that use this method: portal-client and portal-output
    const loginPageIndex = window.location.href.lastIndexOf('/login');
    const clientDomain = loginPageIndex > 0 ? window.location.href.substring(0, loginPageIndex) : window.location.href;
    var returnUrl = this.routerService.getCurrentQueryParams()['returnUrl'];
    const fragment = this.routerService.getCurrentFragment();
    if (fragment)
      returnUrl += `#${fragment}`;
    return this.authApiService.samlSsoLogin$(returnUrl, clientDomain);
  }

  addPassword$(addPasswordRequest: AddPasswordRequest): Observable<MadCloudResult> {
    return this.authApiService.addPassword$(addPasswordRequest);
  }

  changePassword$(userId: string, currentPassword: string, newPassword: string): Observable<MadCloudResult> {
    return this.authApiService.updatePassword$(userId, currentPassword, newPassword);
  }

  changeExpiredPassword$(userName: string, currentPassword: string, newPassword: string): Observable<any> {
    return this.authApiService.changeExpiredPassword$(userName, currentPassword, newPassword);
  }

  completeInvite$(userId: string): Observable<CompletedInvite> {
    return this.authApiService.completeInvite$(userId);
  }

  requestPasswordReset$(email: string, client?: string): Observable<MadCloudResult> {
    return this.authApiService.requestPasswordReset$(email, client);
  }

  requestSiteAccess$(): Observable<any> {
    return this.authApiService.requestSiteAccess$();
  }

  requestLicenseAccess$(): Observable<any> {
    return this.authApiService.requestLicenseAccess$();
  }

  resetPassword$(userId: string, token: string, password: string): Observable<MadCloudResult> {
    return this.authApiService.resetPassword$(userId, token, password);
  }

  verifyUserToken$(userId: string, token: string, confirmEmail: boolean): Observable<VerifiedUser> {
    return this.authApiService.verifyUserToken$(userId, token, confirmEmail);
  }

  verifySsoUserToken$(userId: string, token: string, teamName?: string): Observable<MadCloudResult> {
    return this.authApiService.verifySsoUserToken$(userId, token, teamName);
  }

  getMinPasswordLength$(userName: string): Observable<number> {
    return this.authApiService.getMinPasswordLength$(userName);
  }

  private setLogin$(auth: Auth, instanceCode: string): Observable<Auth> {
    return this.authDataService.login$(auth, instanceCode).pipe(
      map(() => auth),
      validate(() => Array.isArray(this.config.loginGuards) ? this.config.loginGuards.map(guard => this.injector.get<any>(guard)) : null),
      // If an error happens then logout the user and rethrow the error
      catchError(error => this.logout$(LogoutReason.LoginFailure).pipe(
        switchMap(() => throwError(error))
      ))
    );
  }
}
