import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageServicesDescriptions } from '@portal-core/translation-packages/enums/translation-package-services.enum';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';

@Component({
  selector: 'mc-translation-package-language-branch-overview',
  templateUrl: './translation-package-language-branch-overview.component.html',
  styleUrls: ['./translation-package-language-branch-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageLanguageBranchOverviewComponent {
  @HostBinding('class.mc-spring-board') springBoardClass: boolean = true;
  @Input() translationPackageLanguageBranch: TranslationPackageLanguageBranch;
  @Input() translationPackage: TranslationPackage;

  TranslationPackageStatus: typeof TranslationPackageStatus = TranslationPackageStatus;
  TranslationPackageServicesDescriptions: typeof TranslationPackageServicesDescriptions = TranslationPackageServicesDescriptions;

}
