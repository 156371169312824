import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { ProjectReportScanStatus } from '@portal-core/projects/enums/project-report-scan-status.enum';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { ProjectDependencies } from '@portal-core/projects/models/project-dependencies.model';
import { ProjectReportScanStatusWithLatestNotificationId } from '@portal-core/projects/models/project-report-scan-status-with-latest-notification-id.model';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsApiService } from '@portal-core/projects/services/projects-api.service';
import { ProjectsDataService } from '@portal-core/projects/services/projects-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, first, map, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ProjectsService extends CollectionServiceBase<Project> {
  constructor(
    private projectsDataService: ProjectsDataService,
    private projectsApiService: ProjectsApiService,
    private licenseStorageService: LicenseStorageService,
    protected dataService: DataService
  ) {
    super(projectsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<Project> {
    return this.projectsApiService.getProjectById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<Project[]> {
    return this.projectsApiService.getProjectsByIds$(itemIds);
  }

  getProjectsByLicenseId$(licenseId: number): Observable<Project[]> {
    return this.dataService.getDataListItems$<Project>('Licenses', licenseId, this.projectsDataService, {
      fetch: () => this.projectsApiService.getProjectListItemsByLicenseId$(licenseId).pipe(
        map(projects => {
          if (Array.isArray(projects)) {
            return projects.sort((projectA, projectB) => projectA.Name.localeCompare(projectB.Name));
          }
        })
      )
    });
  }

  getProjectsPageByLicenseId$(licenseId: number, filter: PageFilter, maxIncludes?: number): Observable<Page<Project>> {
    return this.projectsApiService.getProjectsPageByLicenseId$(licenseId, filter, maxIncludes);
  }

  getReviewProjectsPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<Project>> {
    return this.projectsApiService.getReviewProjectsPageByLicenseId$(licenseId, filter);
  }

  checkProjectById$(projectId: number): Observable<Project> {
    return this.projectsApiService.checkProjectById$(projectId).pipe(
      tap(project => this.addItems$({ [project.Id]: project }))
    );
  }

  getProjectListItemsByLicenseId$(licenseId: number): Observable<Project[]> {
    return this.projectsApiService.getProjectListItemsByLicenseId$(licenseId).pipe(
      tap(projects => this.addItems$(projects)),
      map(projects => {
        if (Array.isArray(projects)) {
          return projects.sort((projectA, projectB) => projectA.Name.localeCompare(projectB.Name));
        }
      })
    );
  }

  updateProject$(project: Project): Observable<Project> {
    return this.projectsApiService.updateProject$(project).pipe(
      tap(() => this.projectsDataService.updateItems$({ [project.Id]: project }))
    );
  }

  updateProjectTeamsAndUsers$(project: Project): Observable<Project> {
    return this.projectsApiService.updateProjectTeamsAndUsers$(project).pipe(
      tap(() => this.projectsDataService.updateItems$({ [project.Id]: project }))
    );
  }

  getProjectsList$(licenseId: number): Observable<Project[]> {
    return this.projectsApiService.getProjectsList$(licenseId).pipe(
      map(projects => {
        if (Array.isArray(projects)) {
          return projects.sort((projectA, projectB) => {
            return projectA.Name.localeCompare(projectB.Name);
          });
        }
      })
    );
  }

  getProjectsByLicenseIdAndUserId$(licenseId: number, userId: string): Observable<Project[]> {
    return this.projectsApiService.getProjectsByLicenseIdAndUserId$(licenseId, userId).pipe(
      tap(projects => this.projectsDataService.addItems$(projects))
    );
  }

  getProjectReportScanStatus$(projectId: number, options: GetDataOptions = null): Observable<ProjectReportScanStatusWithLatestNotificationId> {
    return this.projectsApiService.getProjectReportScanStatus$(projectId).pipe(
      switchMap(currentStatus => {
        return this.projectsDataService.updateItems$({
          [projectId]: { ReportScanStatus: currentStatus.Status }
        }).pipe(
          map(() => currentStatus)
        );
      })
    );
  }
  analyzeProject$(projectId: number): Observable<any> {
    return this.projectsApiService.analyzeProject$(projectId).pipe(
      tap(() => {
        this.projectsDataService.updateItems$({
          [projectId]: {
            Id: projectId,
            ReportScanStatus: ProjectReportScanStatus.Queued
          }
        });
      })
    );
  }

  updateProjectStatus$(projectId: number, status: ProjectStatus): Observable<any> {
    return this.projectsApiService.putProjectStatus$(projectId, status).pipe(
      tap(() => this.projectsDataService.updateItems$({ [projectId]: { Status: status } }))
    );
  }

  bulkDeleteProjects$(projectIds: number[], licenseId?: number): Observable<any> {
    return this.projectsApiService.bulkDeleteProjects$(projectIds).pipe(
      tap(() => {
        this.projectsDataService.deleteItems$(projectIds);
        if (licenseId) {
          this.licenseStorageService.getItemById$(licenseId, { forceApiRequest: true }).pipe(
            first(licenseStorage => !!licenseStorage)
          ).subscribe();
        }
      })
    );
  }

  bulkUpdateProjectStatus$(status: ProjectStatus, projectIds: number[]): Observable<any> {
    return this.projectsApiService.bulkUpdateProjectStatus$(status, projectIds).pipe(
      tap(() => projectIds.forEach(projectId => this.projectsDataService.updateItems$({ [projectId]: { Status: status } })))
    );
  }

  getProjectDependencies$(projectId: number): Observable<ProjectDependencies> {
    return this.projectsApiService.getProjectDependencies$(projectId);
  }

  getProjectLanguage$(projectId: number, commitId: string): Observable<string> {
    return this.projectsApiService.getProjectLanguage$(projectId, commitId);
  }

  getTargetLanguage$(projectId: number, commitId: string, targetPath: string): Observable<string> {
    return this.projectsApiService.getTargetLanguage$(projectId, commitId, targetPath);
  }

  getProjectArchivePath(projectId: number, commitId: string): string {
    return this.projectsApiService.getProjectArchivePath(projectId, commitId);
  }
}
