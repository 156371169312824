import { Pipe, PipeTransform } from '@angular/core';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcTeamAsync'
})
export class TeamAsyncPipe implements PipeTransform {
  constructor(private teamsService: TeamsService) { }

  transform(teamId: number | null | undefined, allowApiRequest: boolean = false): Observable<Team | null> {
    if (!teamId) {
      return of(null);
    }

    return this.teamsService.getItemById$(teamId, {
      allowApiRequest
    });
  }
}
