import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { LicenseHostMap } from '@portal-core/license-host-maps/models/license-host-map.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseHostMapsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getLicenseHostMap$(licenseHostMapId: number): Observable<LicenseHostMap> {
    return this.http.get<LicenseHostMap>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseHostMapId}`);
  }

  @SharePendingRequest()
  getCNameValidation$(licenseId: number, domain: string, licenseHostMapId?: number): Observable<MadCloudResult> {
    const licenseHostMapParam = licenseHostMapId !== undefined ? `&licenseHostMapId=${licenseHostMapId}` : '';
    return this.http.get<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/validateCName?domain=${domain}${licenseHostMapParam}`);
  }

  addLicenseHostMap$(licenseId: number, licenseHostMap: LicenseHostMap, email: string, password: string): Observable<LicenseHostMap> {
    if (password) {
      return this.http.post<LicenseHostMap>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/addLicenseHostMap?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`, licenseHostMap);
    } else {
      return this.http.post<LicenseHostMap>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/addLicenseHostMap?email=${encodeURIComponent(email)}`, licenseHostMap);
    }
  }

  updateLicenseHostMap$(licenseId: number, licenseHostMap: LicenseHostMap, email: string, password: string): Observable<LicenseHostMap> {
    let put$: Observable<MadCloudResult<LicenseHostMap>>;
    if (password) {
      put$ = this.http.put<MadCloudResult<LicenseHostMap>>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/licenseHostMapUpdate?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`, licenseHostMap);
    } else {
      put$ = this.http.put<MadCloudResult<LicenseHostMap>>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/licenseHostMapUpdate?email=${encodeURIComponent(email)}`, licenseHostMap);
    }

    return put$.pipe(
      map(result => result.Result)
    );
  }

  deleteLicenseHostMap$(licenseId: number, hostMapId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/deleteLicenseHostMap?id=${hostMapId}`);
  }

  setLicenseHostMapDefaultSite$(licenseId: number, licenseHostMapId: number, defaultSiteId: number): Observable<MadCloudResult> {
    if (typeof defaultSiteId === 'number') {
      return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/setDefaultSite?licenseHostMapId=${licenseHostMapId}&defaultSiteId=${defaultSiteId}`, null);
    } else {
      return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/LicenseHostMap/${licenseId}/setDefaultSite?licenseHostMapId=${licenseHostMapId}`, null);
    }
  }
}
