import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';

@Component({
  selector: 'mc-grid-date-filter',
  templateUrl: './grid-date-filter.component.html',
  styleUrls: ['./grid-date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridDateFilterComponent extends GridFilterBase {
  @ViewChild(MatSelect) filterTypeSelect: MatSelect;

  get selectedFilterType(): PageFilterType {
    if (this.filterTypeSelect) {
      if (!Array.isArray(this.filterTypeSelect.selected) && this.filterTypeSelect.selected) {
        return this.filterTypeSelect.selected.value;
      }
    }
  }

  constructor(protected formBuilder: UntypedFormBuilder, private formsService: FormsService, pageFilterService: PageFilterService) {
    super(pageFilterService);

    // Do this in the constructor so that the form is ready for ngOnChanges
    this.buildForm();
  }

  onMenuOpened() {
    super.onMenuOpened();

    // Give focus to the filter type field if there is no custom menu
    if (!this.menuTemplate) {
      this.filterTypeSelect.focus();
    }
  }

  onSubmit() {
    if (this.filterForm.invalid) {
      return;
    }

    this.submitFilter(this.buildFilter(this.filterForm.value['filterType'], this.filterForm.value['from'], this.filterForm.value['to']));
  }

  buildFilter(filterType: PageFilterType, fromValue: Date, toValue: Date): PageFilterGroup {
    return this.pageFilterService.create(this.filterable.getFilter(GridFilterName.Date) ?? {
      Id: GridFilterName.Date,
      Type: PageFilterGroupType.Date
    }).date(this.column.name, fromValue, toValue, {
      FilterType: filterType,
      PropertyName: this.column.filterName
    }).value;
  }

  protected buildForm() {
    this.filterForm = this.formBuilder.group({
      filterType: new UntypedFormControl(PageFilterType.Equals),
      from: new UntypedFormControl(null, [this.formsService.createRequiredIfNotValueValidator('filterType', PageFilterType.Today)]),
      to: new UntypedFormControl(null, [this.formsService.createRequiredIfValueValidator('filterType', PageFilterType.Between)])
    });
  }

  protected buildFormValue(pageFilter: PageFilterGroup): Dictionary {
    let filterType: PageFilterType;
    let from: Date;
    let to: Date;

    const dateFilterGroup = this.pageFilterService.getFilterGroup(pageFilter, this.column.name);

    if (dateFilterGroup?.Filters?.length > 1) {
      filterType = PageFilterType.Between;
      from = new Date(dateFilterGroup.Filters[0].PropertyValue);
      to = new Date(dateFilterGroup.Filters[1].PropertyValue);
    } else if (dateFilterGroup?.Filters?.length === 1) {
      filterType = dateFilterGroup.Filters[0].FilterType;
      from = new Date(dateFilterGroup.Filters[0].PropertyValue);
    }

    return { filterType, from, to };
  }

  protected getGridFilterName(): GridFilterName {
    return GridFilterName.Date;
  }
}
