import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ReviewFileGridItem } from '@portal-core/reviews/review-files/models/review-file-grid-item.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewFilesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getReviewFileById$(reviewFileId: number): Observable<ReviewFile> {
    return this.http.get<ReviewFile>(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileId}`);
  }

  @SharePendingRequest()
  getReviewFilesByIds$(reviewFileIds: number[]): Observable<ReviewFile[]> {
    return this.http.post<ReviewFile[]>(`${this.apiService.centralApiBaseUri}/api/Reviews/getBatchReviewFilesByIds`, reviewFileIds);
  }

  @SharePendingRequest()
  getReviewFilesPage$(licenseId: number, reviewersListLimit: number, filter?: PageFilter): Observable<Page<ReviewFileGridItem>> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/Reviews/license/${licenseId}/${reviewersListLimit}`, filter);
  }

  @SharePendingRequest()
  getReviewFilesPageByPackageId$(reviewPackageId: number, filter: PageFilter): Observable<Page<ReviewFileGridItem>> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/gridItems/${reviewPackageId}`, filter);
  }

  updateReviewFileStatus$(reviewFileId: number, status: ReviewFileStatus, resetReviewerStatus: boolean): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileId}/Status/${status}?resetReviewerStatus=${resetReviewerStatus}`, null);
  }

  bulkUpdateReviewFileStatus$(reviewIds: number[], status: ReviewFileStatus, resetReviewerStatus: boolean): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/Reviews/ReviewStatus/${status}?resetReviewerStatus=${resetReviewerStatus}`, reviewIds);
  }

  addReviewFiles$(reviewPackageId: number, paths: string[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/Reviews/Add/${reviewPackageId}`, paths);
  }

  deleteReviewFile$(reviewPackageId: number, reviewFileId: number): Observable<any> {
    return this.http.delete(`${this.apiService.centralApiBaseUri}/api/Reviews/Remove/${reviewPackageId}/${reviewFileId}`);
  }
}
