import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ImagePickerModule } from '@portal-core/forms/components/image-picker/image-picker.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamsSelectListModule } from '@portal-core/teams/components/teams-select-list/teams-select-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { LicenseSettingsFormComponent } from './license-settings-form.component';

@NgModule({
  declarations: [
    LicenseSettingsFormComponent
  ],
  imports: [
    AutocompleteInputModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    FileSizeModule,
    FormsModule,
    FormControlErrorMessageModule,
    ImagePickerModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    TeamAvatarModule,
    TeamsSelectListModule
  ],
  exports: [
    LicenseSettingsFormComponent
  ]
})
export class LicenseSettingsFormModule { }
