import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Logout, SwitchCentralInstance } from '@portal-core/auth/services/auth-data.service';
import { ResettableService } from '@portal-core/general/services/resettable.service';

/**
 * Resets all resetabbles when the user logs out.
 * Listens to the Logout action from the auth data store to reset the resettables.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthResettablesEffect {
  constructor(private actions$: Actions, private resettableService: ResettableService) {
    this.actions$.pipe(
      ofActionSuccessful(Logout)
    ).subscribe(() => {
      this.resettableService.getResettables().forEach(resettable => resettable.reset$());
    });

    this.actions$.pipe(
      ofActionSuccessful(SwitchCentralInstance)
    ).subscribe(() => {
      this.resettableService.getResettables().forEach(resettable => resettable.reset$());
    });
  }
}
