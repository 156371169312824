import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MultipleChoiceItemNodeViewComponent } from './multiple-choice-item-node-view.component';

@NgModule({
  declarations: [
    MultipleChoiceItemNodeViewComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    MultipleChoiceItemNodeViewComponent
  ]
})
export class MultipleChoiceItemNodeViewModule { }
