import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectAndTargets } from '@portal-core/project-targets/models/project-and-targets.model';
import { ProjectTarget } from '@portal-core/project-targets/models/project-target.model';
import { ProjectTargetFolder } from '@portal-core/project-targets/models/project-target-folder.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectTargetsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getProjectTargetById$(targetId: number): Observable<ProjectTarget> {
    return this.http.get<ProjectTarget>(`${this.apiService.centralApiBaseUri}/api/projects/${targetId}/outputTarget`);
  }

  @SharePendingRequest()
  getProjectTargetsByIds$(targetIds: number[]): Observable<ProjectTarget[]> {
    return this.http.post<ProjectTarget[]>(`${this.apiService.centralApiBaseUri}/api/projects/getBatchOutputTargetsByIds`, targetIds);
  }

  @SharePendingRequest()
  getProjectTargets$(projectId: number, getDeleted: boolean, flatten: boolean): Observable<ProjectTargetFolder> {
    return this.http.get<ProjectTargetFolder>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/outputTargets/${getDeleted}/${flatten}`);
  }

  @SharePendingRequest()
  getMyProjectsAndOutputsByLicenseLite$(licenseId: number): Observable<ProjectAndTargets[]> {
    return this.http.get<ProjectAndTargets[]>(`${this.apiService.centralApiBaseUri}/api/projects/license/${licenseId}/getMyProjectsAndOutputsByLicenseLite`);
  }
}
