import { Mark, MarkType, Node } from 'prosemirror-model';
import { Transform } from 'prosemirror-transform';

// Sets the attributes of a mark
export function setMarkAttrs(tr: Transform, node: Node, mark: Mark, pos: number, attrs: object): Transform {
  // In prosemirror a mark's attributes cannot simply be changed. The mark must be removed and a new mark added
  tr.removeMark(pos, pos + node.nodeSize, mark).addMark(pos, pos + node.nodeSize, mark.type.create(attrs));
  return tr;
}

// Sets the attributes of a mark by adding/setting the given attrs to the mark's existing attributes
export function updateMarkAttrs(tr: Transform, node: Node, mark: Mark, pos: number, attrs: object): Transform {
  return setMarkAttrs(tr, node, mark, pos, Object.assign({}, mark.attrs, attrs));
}

export function removeMarksOfType(tr: Transform, node: Node, markType: MarkType, pos: number): Transform {
  if (Array.isArray(node.marks)) {
    node.marks.forEach(mark => {
      if (mark.type === markType) {
        tr.removeMark(pos, pos + node.nodeSize, mark);
      }
    });
  }

  return tr;
}
