import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ListModule } from '@portal-core/ui/list/list.module';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BranchesSelectListComponent } from '@portal-core/branches/components/branches-select-list/branches-select-list.component';

@NgModule({
  declarations: [
    BranchesSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    BranchAvatarModule
  ],
  exports: [
    BranchesSelectListComponent
  ]
})
export class BranchesSelectListModule { }
