import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

/**
 * Display a horizontal divider with centered text.
 */
@Component({
  selector: 'mc-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DividerComponent {
  /** Gives the host element an aria role value of button. */
  @HostBinding('attr.role') _ariaRole: string = 'separator';

  /** Gives the host element the mc-divider CSS class. */
  @HostBinding('class.mc-divider') _dividerClass: boolean = true;
}
