import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { cache } from '@common/util/cache.operator';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'mc-user-usage-bar',
  templateUrl: './user-usage-bar.component.html',
  styleUrls: ['./user-usage-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserUsageBarComponent implements OnInit {
  @Input() licenseStorage: LicenseStorage;
  @Input() seat: LicenseUserSeatType;
  @Input() trialLicense: boolean;

  @PropertyObservable('licenseStorage') licenseStorage$: Observable<LicenseStorage>;
  @PropertyObservable('seat') seat$: Observable<LicenseUserSeatType>;
  @PropertyObservable('trialLicense') trialLicense$: Observable<boolean>;

  availableSeats$: Observable<number>;
  color$: Observable<string>;
  isUnlimited$: Observable<boolean>;
  title$: Observable<string>;
  totalSeats$: Observable<number>;
  usedSeats$: Observable<number>;
  usedSeatsPercentage$: Observable<number>;

  ngOnInit() {
    // Create an observable for the color of the progress bar
    this.color$ = this.seat$.pipe(
      map(seat => {
        switch (seat) {
          case LicenseUserSeatType.Author:
            return 'usage-authors';
          case LicenseUserSeatType.SME:
            return 'usage-smes';
          case LicenseUserSeatType.Viewer:
            return 'usage-viewers';
        }
      })
    );

    // Create an observable for the title of the usage bar
    this.title$ = this.seat$.pipe(
      map(seat => {
        switch (seat) {
          case LicenseUserSeatType.Author:
            return 'Authors';
          case LicenseUserSeatType.SME:
            return 'Subject Matter Experts';
          case LicenseUserSeatType.Viewer:
            return 'Viewers';
        }
      })
    );

    // Create an observable for whether the number of seats is unlimited
    this.isUnlimited$ = combineLatest(
      this.seat$,
      this.trialLicense$
    ).pipe(
      map(([seat, trialLicense]) => {
        return trialLicense || seat === LicenseUserSeatType.Viewer;
      }),
      cache()
    );

    // Create an observable for the number of available seats
    this.availableSeats$ = combineLatest(
      this.licenseStorage$,
      this.seat$,
      this.trialLicense$
    ).pipe(
      map(([licenseStorage, seat, trialLicense]) => {
        if (!licenseStorage) {
          return null;
        }

        if (trialLicense) {
          return Infinity;
        }

        switch (seat) {
          case LicenseUserSeatType.Author:
            return licenseStorage.AuthorSeats - licenseStorage.UsedAuthorSeats;
          case LicenseUserSeatType.SME:
            return licenseStorage.SmeSeats - licenseStorage.UsedSmeSeats;
          case LicenseUserSeatType.Viewer:
            return Infinity;
        }
      })
    );

    // Create an observable for the total number of seats
    this.totalSeats$ = combineLatest(
      this.licenseStorage$,
      this.seat$,
      this.trialLicense$
    ).pipe(
      map(([licenseStorage, seat, trialLicense]) => {
        if (!licenseStorage) {
          return null;
        }

        if (trialLicense) {
          return Infinity;
        }

        switch (seat) {
          case LicenseUserSeatType.Author:
            return licenseStorage.AuthorSeats;
          case LicenseUserSeatType.SME:
            return licenseStorage.SmeSeats;
          case LicenseUserSeatType.Viewer:
            return Infinity;
        }
      }),
      cache()
    );

    // Create an observable for the number of used seats
    this.usedSeats$ = combineLatest(
      this.licenseStorage$,
      this.seat$
    ).pipe(
      map(([licenseStorage, seat]) => {
        if (!licenseStorage) {
          return null;
        }

        switch (seat) {
          case LicenseUserSeatType.Author:
            return licenseStorage.UsedAuthorSeats;
          case LicenseUserSeatType.SME:
            return licenseStorage.UsedSmeSeats;
          case LicenseUserSeatType.Viewer:
            return licenseStorage.Viewers;
        }
      })
    );

    // Create an observable for the percentage of used seats
    this.usedSeatsPercentage$ = combineLatest(
      this.licenseStorage$,
      this.seat$,
      this.trialLicense$
    ).pipe(
      map(([licenseStorage, seat, trialLicense]) => {
        if (!licenseStorage) {
          return null;
        }

        if (trialLicense) {
          return 0;
        }

        switch (seat) {
          case LicenseUserSeatType.Author:
            return (licenseStorage.UsedAuthorSeats / licenseStorage.AuthorSeats) * 100;
          case LicenseUserSeatType.SME:
            return (licenseStorage.UsedSmeSeats / licenseStorage.SmeSeats) * 100;
          case LicenseUserSeatType.Viewer:
            return 0;
        }
      })
    );
  }
}
