<p class="mc-form-heading">Settings</p>
<ul class="mc-property-list">
  <li>
    <span class="mc-property-name">Name: </span>
    <span class="mc-property-value mc-break-text">{{translationPackage?.Name}}</span>
  </li>
  <li>
    <span class="mc-property-name">Translation Method: </span>
    <span class="mc-property-value">
      {{translationPackage?.UseMadTranslations ? 'MadTranslations - ' + translationPackageServicesDescriptions[translationPackage.MadTranslationsService] : 'Manual'}}
    </span>
  </li>
  <li>
    <span class="mc-property-name">Description: </span>
    <span class="mc-property-value mc-break-text" *ngIf="translationPackage?.ProjectDescription;else noDescription">
      {{ translationPackage?.ProjectDescription }}
    </span>
    <ng-template #noDescription>
      <i class="mc-property-value">No Description</i>
    </ng-template>
  </li>
  <li>
    <span class="mc-property-name">Due Date: </span>
    <span class="mc-property-value" *ngIf="translationPackage?.DueDate;else noDate">
      {{translationPackage?.DueDate | date : 'fullDate'}}
    </span>
    <ng-template #noDate>
      <i class="mc-property-value">No Date</i>
    </ng-template>
  </li>
  <li>
    <span class="mc-property-name">Languages: </span>
    <span class="mc-property-value mc-break-text">
      <ng-container *ngFor="let branch of translationPackage?.TranslationPackageLanguageBranches; let isLast = last">
        <mc-translation-package-language-branch-avatar [translationPackageLanguageBranch]="branch" [showAvatar]="false" [disabled]="disabledAvatars"></mc-translation-package-language-branch-avatar> {{isLast ? '' : ', '}}
      </ng-container>
    </span>
  </li>
  <li>
    <span class="mc-property-name">Targets: </span>
    <span class="mc-property-value mc-break-text" *ngIf="translationPackage?.TargetPaths?.length;else noTargets">
      <ng-container *ngFor="let targetPath of translationPackage.TargetPaths.split('|'); let isLast = last">
        <mc-target-avatar [showAvatar]="false" [showPath]="true" size="small" [targetPath]="targetPath" [truncate]="true" [emphasizeTargetName]="false"></mc-target-avatar>{{isLast ? '' : ', '}}
      </ng-container>
    </span>
    <ng-template #noTargets>
      <i class="mc-property-value">Whole Project</i>
    </ng-template>
  </li>
  <li *ngIf="translationPackage?.GitCommitId">
    <span class="mc-property-name">Commit ID: </span>
    <span class="mc-property-value" [matTooltip]="translationPackage.GitCommitId">
      {{ translationPackage.GitCommitId }}
    </span>
  </li>
  <li *ngIf="translationPackage?.CreatedByUser">
    <span class="mc-property-name">Owner: </span>
    <span class="mc-property-value">
      {{ translationPackage.CreatedByUser.FullName }}
    </span>
  </li>
  <li>
    <span class="mc-property-name">Main Branch: </span>
    <span class="mc-property-value mc-break-text">
      {{ translationPackage?.SourceBranchName }}
    </span>
  </li>
  <li>
    <span class="mc-property-name">Create a Language Skin: </span>
    <span class="mc-property-value">{{translationPackage?.CreateLanguageSkin ? 'Yes' : 'No'}}</span>
  </li>

  <div class="mc-form-spacer"></div>

  <p class="mc-form-heading">Variables</p>
  <ng-container *ngIf="variableSets?.length; else noVariables">
    <ng-container *ngFor="let variableSet of variableSets">
      <li class="mc-property-list-item-truncate">
        <span class="mc-property-name">{{variableSet.name}}: </span>
        <span class="mc-property-value mc-truncate" [matTooltip]="variableSet.variableNamesStr" matTooltipClass="mc-tooltip-full-width">
          {{variableSet.variableNamesStr}}
        </span>
      </li>
    </ng-container>
  </ng-container>
  <ng-template #noVariables>
    <i class="mc-property-value">No Variables</i>
  </ng-template>
</ul>