import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { TaskBoardAvatarComponent } from './task-board-avatar.component';

@NgModule({
  declarations: [
    TaskBoardAvatarComponent
  ],
  imports: [
    CommonModule,
    InlineButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule
  ],
  exports: [
    TaskBoardAvatarComponent
  ]
})
export class TaskBoardAvatarModule { }
