import { Injectable } from '@angular/core';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { Observable } from 'rxjs';

@Injectable()
/**
 *  Only meant to be used by ProjectFilesTreeComponent as a provider.
 *  This service creates a flatNode for each normal node for the tree in ProjectFilesTreeComponent.
**/
export class ProjectFilesTreeControlService {

  nodeMap: Map<string, ProjectFileFlatNode> = new Map<string, ProjectFileFlatNode>();
  pathFilters: string[] = [];

  constructor() { }

  getChildren(node: ProjectFileNode): Observable<ProjectFileNode[]> {
    return node.childrenChange;
  }

  transformer(node: ProjectFileNode, level: number): ProjectFileFlatNode {
    const existingNode = this.nodeMap.get(node.path);
    if (existingNode) {
      return existingNode;
    }
    const newNode = new ProjectFileFlatNode(node.name, node.id, node.path, level, node.hasChildren);
    node.flatFileNode = newNode;
    this.nodeMap.set(node.path, newNode);
    return newNode;
  }

  getLevel(node: ProjectFileFlatNode): number {
    return node.level;
  }

  isExpandable(node: ProjectFileFlatNode): boolean {
    return node.expandable;
  }

  hasChild(nodeNumber: number, node: ProjectFileFlatNode): boolean {
    return  node.expandable;
  }

}
