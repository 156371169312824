import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Observable, map } from 'rxjs';

/**
 *  Provides helper methods for working with images.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  /**
   * Creates an `HTMLImageElement` and loads the given image path into it.
   * Emits the `HTMLImageElement` after it has loaded and completes the `Observable`.
   * */
  loadImage$(path: string): Observable<HTMLImageElement> {
    return new Observable(observer => {
      try {
        const img = new Image();

        img.onload = function () {
          observer.next(img);
          observer.complete();
        };

        img.onerror = function (error: Event | string) {
          observer.error(error);
          observer.complete();
        };

        img.src = path;
      } catch (ex) {
        observer.error(ex);
        observer.complete();
      }
    });
  }

  /** Returns the url for a `MadCapImage`. The url is an asset hosted by the Central API server. */
  getMadCapImageUrl(storageId: string, extension: string): string {
    return `${this.apiService.centralAssetsBaseUri}/${storageId}${extension}`;
  }

  /**
   * Loads an image and reads in its dimensions.
   * Emits the dimensions and completes the `Observable`.
   * */
  getImageDimensions$(path: string): Observable<{ height: number, width: number }> {
    return this.loadImage$(path).pipe(
      map(img => {
        return {
          height: img.height,
          width: img.width
        };
      })
    );
  }

  fetchSvg$(path: string): Observable<SVGSVGElement> {
    return this.fetchXml$(path, 'image/svg+xml').pipe(
      map(xmlDoc => xmlDoc?.firstElementChild as SVGSVGElement)
    );
  }

  fetchXml$(path: string, headerType: 'application/xml' | 'image/svg+xml' = 'application/xml'): Observable<XMLDocument> {
    const headers = new HttpHeaders();
    headers.set('Accept', headerType);
    return this.http.get(path, { headers, responseType: 'text' }).pipe(
      map(response => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, headerType);
        if (xmlDoc.getElementsByTagName('parsererror').length !== 0) {
          // file is not a valid xml doc
          return null;
        }
        return xmlDoc as XMLDocument;
      })
    );
  }
}
