import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RichTextLinkPropertiesDialogModule } from '@portal-core/text-editor/components/rich-text-link-properties-dialog/rich-text-link-properties-dialog.module';
import { EditorToolbarModule } from '@portal-core/ui/editor/components/editor-toolbar/editor-toolbar.module';
import { RichTextEditorToolbarComponent } from './rich-text-editor-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    EditorToolbarModule,
    RichTextLinkPropertiesDialogModule
  ],
  declarations: [
    RichTextEditorToolbarComponent
  ],
  exports: [
    RichTextEditorToolbarComponent
  ]
})
export class RichTextEditorToolbarModule { }
