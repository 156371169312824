import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewPackageLinkPipe } from './review-package-link.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ReviewPackageLinkPipe
  ],
  exports: [
    ReviewPackageLinkPipe
  ]
})
export class ReviewPackageLinkModule { }
