<mc-infinite-list
  [allItemsLoaded]="allPagesLoaded$ | async"
  [itemHeight]="itemHeight"
  [items]="items$ | async"
  [listClass]="listClass"
  [listType]="ListType.Select"
  [loadingDetailedErrors]="loadingState.detailedErrors$ | async"
  [loadingGeneralError]="loadingState.generalError$ | async"
  [loading]="loadingState.loading$ | async"
  [loadingMore]="loadingMoreState.loading$ | async"
  [loadingMoreDetailedErrors]="loadingMoreState.detailedErrors$ | async"
  [loadingMoreGeneralError]="loadingMoreState.generalError$ | async"
  [selectionListControl]="listControl"
  (retryLoad)="onRetryLoad()"
  (selectionChange)="onSelectionChanged($event)"
>
  <ng-template mcListItem let-item>
    <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  </ng-template>

  <ng-template *ngIf="allItemsLoadedListDirective" mcAllItemsLoadedList>
    <ng-container [ngTemplateOutlet]="allItemsLoadedListDirective?.templateRef"></ng-container>
  </ng-template>

  <ng-template *ngIf="emptyListDirective" mcEmptyList>
    <ng-container [ngTemplateOutlet]="emptyListDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: emptyListData$ | async }"></ng-container>
  </ng-template>
</mc-infinite-list>
