import { getNodeType } from '@common/prosemirror/model/node';
import { selectionIsInNode } from '@common/prosemirror/state/selection';
import { NodeType } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

const vscodePasterKey = new PluginKey('vscodePaster');

/**
 * The options for the VSCode paster plugin.
 */
export interface VscodePasterKeyPluginOptions {
  /**
   * The types or names of the nodes to wrap the pasted code into. If more than one node is provided then each subsequent node is wrapped by the former node.
   * e.g. ['pre', 'code'] becomes <pre><code>code</code></pre>
   * */
  codeNodeTypesOrNames: (NodeType | string)[];
}

/**
 * A ProseMirror plugin for pasting code into a code block from the clipboard when coming from VSCode.
 */
export function vscodePasterPlugin(options: VscodePasterKeyPluginOptions): Plugin {
  return new Plugin({
    key: vscodePasterKey,

    props: {
      handlePaste(view: EditorView, event: ClipboardEvent): boolean {
        const vscodeJsonData = event.clipboardData?.getData('vscode-editor-data')
        if (!vscodeJsonData) {
          return false;
        }

        const text = event.clipboardData.getData('text/plain');
        if (!text) {
          return false;
        }

        // If the selection is in a code block then don't create a new code block
        if (selectionIsInNode(view.state.selection, getNodeType(options.codeNodeTypesOrNames.at(-1), view.state.schema), true)) {
          return false;
        }

        // The language is not currently used
        // const vscodeData = JSON.parse(vscodeJsonData);
        // const language = vscodeData.mode;

        // Create the code nodes with the pasted text as content
        const codeNode = options.codeNodeTypesOrNames.reduceRight((content, nodeTypeOrName) => {
          return getNodeType(nodeTypeOrName, view.state.schema).create(null, content);
        }, view.state.schema.text(text));

        // Replace the selection with the code nodes
        const tr = view.state.tr;
        tr.replaceSelectionWith(codeNode);
        view.dispatch(tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste'));

        return true;
      }
    }
  });
}
