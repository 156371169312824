
import { Component, ViewEncapsulation, ChangeDetectionStrategy, DoCheck, HostBinding, Input, Output, KeyValueDiffer, KeyValueDiffers, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { SelectionListItem } from '@portal-core/general/models/selection-list-item';

@Component({
  selector: 'mc-selection-list-header',
  templateUrl: './selection-list-header.component.html',
  styleUrls: ['./selection-list-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'selectionList'
})
export class SelectionListHeaderComponent implements DoCheck {
  /** Adds extra spacing between the checkbox and its label. Use this when the list displays avatars with a white border over a white background. This aligns the label with the avatars. */
  @HostBinding('class.mc-selection-list-header-avatar-spacing')
  @Input() avatarSpacing?: boolean = false;

  /** The items bound to the MatSelectionList that this SelectionListHeaderComponent belongs to. */
  @Input()
  set selectionListItems(newSelectionListItems: SelectionListItem[]) {
    this._selectionListItems = newSelectionListItems;

    // Whenever the selectionListItems array is set to a new reference the differs all need to be recreated
    this.itemDiffers = [];

    if (Array.isArray(newSelectionListItems)) {
      this.selectionListItems.forEach(selectionListItem => {
        this.itemDiffers.push(this.differs.find(selectionListItem).create());
      });
    }

    // Update the checkbox since the selectionListItems changed
    this.updateCheckbox();
  }
  get selectionListItems(): SelectionListItem[] {
    return this._selectionListItems;
  }
  private _selectionListItems: SelectionListItem[];

  @Output() selectAllChange: EventEmitter<boolean> = new EventEmitter();

  selectAllChecked: boolean;
  selectAllDisabled: boolean;
  selectAllIndeterminate: boolean;
  private itemDiffers: KeyValueDiffer<any, any>[];

  constructor(private differs: KeyValueDiffers, private cdr: ChangeDetectorRef) { }

  ngDoCheck() {
    // If there are items then check them for changes to their Selected property
    if (Array.isArray(this.selectionListItems)) {
      let anyItemHasChanged = false;

      // Loop through every item to run the diff on each. Every item must be diffed even after finding the first item changed so that the differs are up to date
      for (let i = 0; i < this.selectionListItems.length; i += 1) {
        const changes = this.itemDiffers[i].diff(this.selectionListItems[i]);

        // If there was a change and we haven't already detected a change
        if (changes && !anyItemHasChanged) {
          // Check to see if the change is to the Selected property
          changes.forEachChangedItem((changedItem: { key: string; }) => {
            if (changedItem.key === 'Selected') {
              anyItemHasChanged = true;
            }
          });
        }
      }

      // If an item's Selected property changed then update the checkbox
      if (anyItemHasChanged) {
        this.updateCheckbox();
      }
    }
  }

  onSelectAllClicked() {
    const selectAll = !this.selectAllChecked;

    this.selectionListItems
      .filter(selectionListItem => !selectionListItem.Disabled)
      .forEach(selectionListItem => {
        selectionListItem.Selected = selectAll;
      });

    // Update the checkbox based on the new selections
    this.updateCheckbox();

    this.selectAllChange.emit(this.selectAllChecked);
  }

  /** Updates the values bound to the mat-checkbox and marks this component for change detection. */
  private updateCheckbox() {
    this.selectAllChecked = Array.isArray(this.selectionListItems) && this.selectionListItems.every(t => t.Selected);
    this.selectAllDisabled = !Array.isArray(this.selectionListItems) || this.selectionListItems.every(t => t.Disabled);
    this.selectAllIndeterminate = !this.selectAllChecked && Array.isArray(this.selectionListItems) && this.selectionListItems.some(t => t.Selected);
    this.cdr.markForCheck();
  }
}
