import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-review-package-deletion-form',
  templateUrl: './review-package-deletion-form.component.html',
  styleUrls: ['./review-package-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageDeletionFormComponent {
  @Input() reviewPackage: ReviewPackage;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private reviewPackagesService: ReviewPackagesService
  ) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.reviewPackagesService.deleteReviewPackages$([this.reviewPackage.Id]).subscribe(
      () => {
        this.savingState.update(false);
        this.snackBar.open('The review package has been deleted.', 'OK', { duration: 2500 });
        this.deleted.emit();
      },
      error => {
        this.savingState.update(
          false,
          'Unable to delete the review package.',
          this.errorService.getErrorMessages(error)
        );
      }
    );
  }
}
