import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { RoutingService } from '@portal-core/routing/services/routing.service';

/*
 * Returns the routerLink commands for a review package.
 * Usage:
 *   reviewPackage | mcReviewPackageLink
*/
@Pipe({
  name: 'mcReviewPackageLink'
})
export class ReviewPackageLinkPipe implements PipeTransform {
  constructor(private routingService: RoutingService) { }

  transform(reviewPackage: ReviewPackage): any[] {
    let commands: any[] = null;

    if (reviewPackage) {
      const path = this.routingService.getReviewPackagePath(reviewPackage.LicenseId, reviewPackage.Id);

      // If the path is undefined then use the default path
      if (typeof path === 'undefined') {
        commands = ['/', reviewPackage.LicenseId, 'reviews', 'packages', reviewPackage.Id];
      } else {
        commands = path;
      }
    }

    return commands;
  }
}
