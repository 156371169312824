import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PathService {
  isWithinPath(child: string, parent: string): boolean {
    if (child === parent) {
      return true;
    }

    const parentTokens = parent.split('/').filter(i => i.length);
    return parentTokens.every((t, i) => child.split('/')[i] === t);
  }
}
