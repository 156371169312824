import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { SortDirection } from '@common/paged-data/types/sort-direction.type';
import { DataList } from '@portal-core/data/collection/models/data-list.model';
import { GetPageDataMethods } from '@portal-core/data/collection/models/get-page-data-methods.model';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { McModel } from '@portal-core/data/common/models/mc-model.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ModelId, ModelIds } from '@portal-core/data/common/types/mc-model.type';
import { IResettable } from '@portal-core/util/resettable.decorator';
import { keyBy } from 'lodash';
import { first, map, Observable, of, switchMap } from 'rxjs';

/**
 * Provides common functionality for a service that interacts with a collection-data service (CollectionDataServiceBase).
 * Extend this service in collection service classes.
 */
export abstract class CollectionServiceBase<T extends McModel> implements IResettable {
  protected collectionDataService: CollectionDataServiceBase<T>;
  protected dataService: DataService;

  constructor(collectionDataService: CollectionDataServiceBase<T>, dataService: DataService) {
    this.collectionDataService = collectionDataService;
    this.dataService = dataService;
    this.collectionDataService.addDefaultItems$();
  }

  protected fetchItemById$(itemId: ModelId): Observable<T> { throw new Error('fetchItemById$ is not implemented'); }
  protected fetchItemsById$(itemIds: ModelIds): Observable<T[]> { throw new Error('fetchItemsById$ is not implemented'); }

  reset$(): Observable<any> {
    return this.collectionDataService.reset$();
  }

  // Items
  getItems<S extends T = T>(): Dictionary<S> {
    return this.collectionDataService.getItems<S>();
  }

  getItems$<S extends T = T>(): Observable<Dictionary<S>> {
    return this.collectionDataService.getItems$<S>();
  }

  getItemsArray<S extends T = T>(): S[] {
    const items = this.getItems<S>();
    return items ? Object.values(items) : [];
  }

  getItemsArray$<S extends T = T>(): Observable<S[]> {
    return this.getItems$<S>().pipe(
      map(item => item ? Object.values(item) : [])
    );
  }

  addItems$<S extends T = T>(items: Dictionary<Partial<S>> | Partial<S>[]): Observable<any> {
    return this.collectionDataService.addItems$<S>(items);
  }

  updateItems$<S extends T = T>(items: Dictionary<Partial<S>> | Partial<S>[]): Observable<any> {
    return this.collectionDataService.updateItems$<S>(items);
  }

  removeItems$(items: Dictionary<T> | T[] | ModelId[]): Observable<any> {
    // const itemIds = items.map(item => typeof item === 'string' || typeof item === 'number' ? item : item.Id);
    // return this.collectionDataService.removeItems$(itemIds);
    return this.collectionDataService.removeItems$(items);
  }

  deleteItems$(items: Dictionary<T> | T[] | ModelId[]): Observable<any> {
    return this.collectionDataService.deleteItems$(items);
  }

  // Item by id
  getItemById<S extends T = T>(itemId: ModelId): S {
    return this.collectionDataService.getItemById<S>(itemId);
  }

  getItemById$<S extends T = T>(itemId: ModelId, options: GetDataOptions = null): Observable<S> {
    return this.dataService.getData<T>({
      get: () => this.collectionDataService.getItemById$<T>(itemId),
      fetch: () => this.fetchItemById$(itemId),
      set: newItem => this.collectionDataService.addItems$({ [newItem.Id]: newItem })
    }, options) as Observable<S>;
  }

  // Items by properties
  getItemByProperties(...args): T {
    return this.collectionDataService.getItemByProperties(...args);
  }

  getItemByProperties$(...args): Observable<T> {
    return this.collectionDataService.getItemByProperties$(...args);
  }

  getItemsByProperties(...args): T[] {
    return this.collectionDataService.getItemsByProperties(...args);
  }

  getItemsByProperties$(...args): Observable<T[]> {
    return this.collectionDataService.getItemsByProperties$(...args);
  }

  // Lists
  getListById(listName: string, listId: ModelId): DataList {
    return this.collectionDataService.getListById(listName, listId);
  }

  getListById$(listName: string, listId: ModelId): Observable<DataList> {
    return this.collectionDataService.getListById$(listName, listId);
  }

  clearLists$(): Observable<any> {
    return this.collectionDataService.clearLists$();
  }

  setListById$(listName: string, listId: ModelId, items: T[]): Observable<any> {
    return this.collectionDataService.setListById$(listName, listId, items);
  }

  /*
   * Paged Data Lists
   */
  // Pages
  getPagedDataListItems$(pagedDataListId: string): Observable<T[]> {
    return this.collectionDataService.getPagedDataListItems$(pagedDataListId);
  }

  loadMorePagedDataListItems$(dataListId: string, pageSize: number, cursor: number, methods?: GetPageDataMethods<T>, options?: GetDataOptions): Observable<any> {
    let pageIndex = this.collectionDataService.getPagedDataListPageIndex(dataListId);
    if (typeof pageIndex !== 'number') {
      pageIndex = -1; // The page index will be incremented to load the next page so default to -1 so that it is incremented to 0
    }

    const total = this.collectionDataService.getPagedDataListTotal(dataListId);
    const length = this.collectionDataService.getPagedDataListLength(dataListId);
    const allPagesLoaded = this.collectionDataService.getPagedDataListAllPagesLoaded(dataListId);

    if (typeof length !== 'number' || typeof total !== 'number' || length < total || (total === -1 && !allPagesLoaded)) {
      return this.loadPagedDataListItems$(dataListId, pageIndex + 1, pageSize, cursor, methods, options);
    } else {
      return of(null);
    }
  }

  loadPagedDataListItems$(dataListId: string, pageIndex: number, pageSize: number, cursor: number, methods?: GetPageDataMethods<T>, options?: GetDataOptions): Observable<any> {
    const filterOrder = this.collectionDataService.getPagedDataListOrder(dataListId);

    const filter = this.buildPageFilter(
      pageIndex,
      pageSize,
      cursor,
      filterOrder ? filterOrder.by : null,
      filterOrder ? filterOrder.dir : null,
      this.collectionDataService.getPagedDataListFilters(dataListId)
    );

    return methods.fetch(filter, options).pipe(
      switchMap(page => {
        return page ? this.collectionDataService.addDataListPage$(dataListId, page) : of(null);
      })
    );
  }

  resetPagedDataList$(dataListId: string): Observable<any> {
    return this.collectionDataService.resetPagedDataList$(dataListId);
  }

  clearPagedDataList$(dataListId: string): Observable<any> {
    return this.collectionDataService.clearPagedDataList$(dataListId);
  }

  getPagedDataListAllPagesLoaded(dataListId: string): boolean {
    return this.collectionDataService.getPagedDataListAllPagesLoaded(dataListId);
  }

  getPagedDataListAllPagesLoaded$(dataListId: string): Observable<boolean> {
    return this.collectionDataService.getPagedDataListAllPagesLoaded$(dataListId);
  }

  // Filters
  getPagedDataListFilters$(pagedDataListId: string): Observable<Dictionary<PageFilterGroup>> {
    return this.collectionDataService.getPagedDataListFilters$(pagedDataListId);
  }

  // Filter by name
  getPagedDataListFilterByName(pagedDataListId: string, name: string): PageFilterGroup {
    return this.collectionDataService.getPagedDataListFilterByName(pagedDataListId, name);
  }

  getPagedDataListFilterByName$(pagedDataListId: string, name: string): Observable<PageFilterGroup> {
    return this.collectionDataService.getPagedDataListFilterByName$(pagedDataListId, name);
  }

  setPagedDataListFilterByName$(pagedDataListId: string, name: string, value: PageFilterGroup): Observable<any> {
    return this.collectionDataService.setPagedDataListFilterByName$(pagedDataListId, name, value);
  }

  // Order
  getPagedDataListOrder(pagedDataListId: string): PageSort {
    return this.collectionDataService.getPagedDataListOrder(pagedDataListId);
  }

  getPagedDataListOrder$(pagedDataListId: string): Observable<PageSort> {
    return this.collectionDataService.getPagedDataListOrder$(pagedDataListId);
  }

  setPagedDataListOrder$(dataListId: string, orderBy: string, orderDirection: SortDirection): Observable<any> {
    return this.collectionDataService.setPagedDataListOrder$(dataListId, orderBy, orderDirection);
  }

  /*
   * Grids
   */

  /**
   * Returns an observable for a page's items in a grid.
   * @param gridId The id of the grid to get the items for.
   * @param pageIndex The page index to get the items for.
   */
  getGridItems$(gridId: string, pageIndex: number): Observable<T[]> {
    return this.collectionDataService.getGridItems$(gridId, pageIndex);
  }

  /**
   * Returns an observable that emits once and completes after the data is fetched and loaded into the data store.
   * If the page already exists then the fetch is not made and the observable immediately completes.
   * @param gridId The id of the grid to the load the items for.
   * @param pageIndex  The page index to load the items for.
   * @param pageSize The number of items to load.
   * @param methods The fetch method configuration which defines how the grid page's data is loaded.
   * @param options The data fetching options passed to the fetch method.
   */
  loadGridItems$(gridId: string, pageIndex: number, pageSize: number, cursor: number, methods?: GetPageDataMethods<T>, options?: GetDataOptions): Observable<any> {
    const filterOrder = this.collectionDataService.getGridOrder(gridId);

    const filter = this.buildPageFilter(
      pageIndex,
      pageSize,
      cursor,
      filterOrder ? filterOrder.by : null,
      filterOrder ? filterOrder.dir : null,
      this.collectionDataService.getGridFilters(gridId)
    );

    const gridPage = this.collectionDataService.getGridPage(gridId, pageIndex);

    if (!gridPage || options.forceApiRequest) {
      return methods.fetch(filter, options).pipe(
        switchMap(page => {
          return page ? this.collectionDataService.addGridPage$(gridId, page, pageSize) : of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Removes all the data and config associated with the grid.
   * @param gridId The id of the grid.
   */
  resetGrid$(gridId: string): Observable<any> {
    return this.collectionDataService.resetGrid$(gridId);
  }

  /**
   * Removes the pages from the grid.
   * @param gridId The id of the grid.
   */
  clearGrid$(gridId: string): Observable<any> {
    return this.collectionDataService.clearGrid$(gridId);
  }

  /**
   * Returns the cursor for page in a grid.
   * @param gridId The id of the grid to get the cursor for.
   * @param pageIndex The page index to get the cursor for.
   */
  getGridCursor(gridId: string, pageIndex: number): number {
    return this.collectionDataService.getGridCursor(gridId, pageIndex);
  }

  /**
   * Returns the total number of items across every page of the grid.
   * @param gridId The id of the grid.
   */
  getGridTotal(gridId: string): number {
    return this.collectionDataService.getGridTotal(gridId);
  }

  /**
   * Returns an observable of the total number of items across every page of the grid.
   * @param gridId The id of the grid.
   */
  getGridTotal$(gridId: string): Observable<number> {
    return this.collectionDataService.getGridTotal$(gridId);
  }

  /**
   * Returns the limited total number of items across every page of the grid.
   * The server will sometimes return a limited total number of items for performance reasons.
   * @param gridId The id of the grid.
   */
  getGridLimitedTotal(gridId: string): number {
    return this.collectionDataService.getGridLimitedTotal(gridId);
  }

  /**
   * Returns an observable of the limited total number of items across every page of the grid.
   * The server will sometimes return a limited total number of items for performance reasons.
   * @param gridId The id of the grid.
   */
  getGridLimitedTotal$(gridId: string): Observable<number> {
    return this.collectionDataService.getGridLimitedTotal$(gridId);
  }

  /**
   * Returns the filters on the grid.
   * @param gridId The id of the grid.
   */
  getGridFilters(gridId: string): Dictionary<PageFilterGroup> {
    return this.collectionDataService.getGridFilters(gridId);
  }

  /**
   * Returns an observable of the filters on the grid.
   * @param gridId The id of the grid.
   */
  getGridFilters$(gridId: string): Observable<Dictionary<PageFilterGroup>> {
    return this.collectionDataService.getGridFilters$(gridId);
  }

  /**
   * Returns the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   */
  getGridFilterByName(gridId: string, name: string): PageFilterGroup {
    return this.collectionDataService.getGridFilterByName(gridId, name);
  }

  /**
   * Returns an observable of the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   */
  getGridFilterByName$(gridId: string, name: string): Observable<PageFilterGroup> {
    return this.collectionDataService.getGridFilterByName$(gridId, name);
  }

  /**
   * Sets the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   * @param value: The value of the filter.
   */
  setGridFilterByName$(gridId: string, name: string, value: PageFilterGroup): Observable<any> {
    return this.collectionDataService.setGridFilterByName$(gridId, name, value);
  }

  /**
   * Clear all the filters on the grid.
   * @param gridId The id of the grid.
   */
  clearGridFilters$(gridId: string): Observable<any> {
    return this.collectionDataService.clearGridFilters$(gridId);
  }

  /**
   * Returns the page size of the grid.
   * @param gridId The id of the grid.
   */
  getGridPageSize(gridId: string): number {
    return this.collectionDataService.getGridPageSize(gridId);
  }

  /**
   * Returns an observable of the page size of the grid.
   * @param gridId The id of the grid.
   */
  getGridPageSize$(gridId: string): Observable<number> {
    return this.collectionDataService.getGridPageSize$(gridId);
  }

  /**
   * Sets the page size of the grid.
   * @param gridId The id of the grid.
   * @param pageSize The new page size of the grid.
   */
  setGridPageSize$(gridId: string, pageSize: number): Observable<any> {
    return this.collectionDataService.setGridPageSize$(gridId, pageSize);
  }

  /**
   * Returns the sort order on the grid.
   * @param gridId The id of the grid.
   */
  getGridOrder(gridId: string): PageSort {
    return this.collectionDataService.getGridOrder(gridId);
  }

  /**
   * Returns an observable of the sort order on the grid.
   * @param gridId The id of the grid.
   */
  getGridOrder$(gridId: string): Observable<PageSort> {
    return this.collectionDataService.getGridOrder$(gridId);
  }

  /**
   * Sets the sort order of the grid.
   * @param gridId The id of the grid.
   * @param orderBy The name of the column to order the grid by.
   * @param orderDirection The direction to sort the column by.
   */
  setGridOrder$(gridId: string, orderBy: string, orderDirection: SortDirection): Observable<any> {
    return this.collectionDataService.setGridOrder$(gridId, orderBy, orderDirection);
  }

  /**
   * Returns the visible columns for the grid.
   * @param gridId The id of the grid.
   */
  getGridVisibleColumns(gridId: string): string[] {
    return this.collectionDataService.getGridVisibleColumns(gridId);
  }

  /**
   * Returns an observable of the visible columns for the grid.
   * @param gridId The id of the grid.
   */
  getGridVisibleColumns$(gridId: string): Observable<string[]> {
    return this.collectionDataService.getGridVisibleColumns$(gridId);
  }

  /**
   * Sets the visible columns for the grid.
   * @param gridId The id of the grid.
   * @param visibleColumns An array of column names to be displayed in the grid. The order of the names is used as the order of the columns in the grid.
   */
  setGridVisibleColumns$(gridId: string, visibleColumns: string[]): Observable<any> {
    return this.collectionDataService.setGridVisibleColumns$(gridId, visibleColumns);
  }

  /**
   * Makes a request to the API server for the latest data for the item and updates the data store.
   * @param itemId: The id of the item.
   */
  refreshItemById(itemId: ModelId) {
    // Get the item but force an api request. And stop listening after the item is fetched
    this.getItemById$(itemId, { forceApiRequest: true }).pipe(
      first()
    ).subscribe();
  }

  /*
   * Bulk item loading
   */
  loadItems$(itemIds: ModelIds): Observable<any> {
    return this.bulkLoadItems$(
      () => {
        const itemIdsToFetch = (itemIds ?? []).filter(itemId => {
          if (itemId === 0) {
            return false;
          }

          const item = this.getItemById(itemId);

          // If the item has expired OR there is no item then fetch this item
          return item ? this.dataService.cacheHasExpired(item, null, { maxAgeMS: DataService.DefaultDataMaxAgeMS }) : true;
        });

        if (itemIdsToFetch.length > 0) {
          return this.fetchItemsById$(itemIdsToFetch);
        }
      }
    );
  }

  protected bulkLoadItems$(fetch: () => Observable<T[]>): Observable<any> {
    // Fetch is allowed to return undefined if it determined nothing needs to be loaded. In that case use of(null) to have an observable to work with
    return (fetch() || of(null)).pipe(
      switchMap(newItems => {
        if (newItems && newItems.length > 0) {
          return this.collectionDataService.addItems$(keyBy(newItems, 'Id'));
        } else {
          return of(null);
        }
      })
    );
  }

  protected buildPageFilter(pageIndex: number, pageSize: number, cursor: number, orderBy: string, orderDirection: SortDirection, filters: Dictionary<PageFilterGroup>): PageFilter {
    return {
      Id: 'collection-page',
      Type: PageFilterGroupType.Custom,
      Cursor: cursor,
      PageNumber: pageIndex,
      PerPage: pageSize,
      OrderBy: orderBy,
      OrderDirection: orderDirection,
      Operator: PageFilterOperator.And,
      FilterGroups: filters ? Object.values(filters).filter(filterGroup => {
        // The old filter format without an Id should have been migrated out of the persisted data. Just in case filter out any old filters
        if (!filterGroup?.Id) {
          return false;
        }

        // Filter out groups that do not contain at least one filter or one filter group
        return filterGroup && ((filterGroup.Filters && filterGroup.Filters.length > 0) || (filterGroup.FilterGroups && filterGroup.FilterGroups.length > 0));
      }).reduce((allFilters, filterOptions) => {
        return allFilters.concat(filterOptions);
      }, []) : []
    };
  }
}
