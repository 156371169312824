import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewPackageNotificationListComponent } from './review-package-notification-list.component';
import { NotificationListModule } from '@portal-core/notifications/components/notification-list/notification-list.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@NgModule({
  declarations: [ReviewPackageNotificationListComponent],
  exports: [ReviewPackageNotificationListComponent],
  imports: [CommonModule, NotificationListModule, MatButtonModule, MatProgressBarModule]
})
export class ReviewPackageNotificationListModule {}
