import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommitAvatarComponent } from './commit-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [
    CommitAvatarComponent
  ],
  exports: [
    CommitAvatarComponent
  ]
})
export class CommitAvatarModule { }
