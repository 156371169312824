import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';

@Pipe({
  name: 'mcReviewFileUserStatusLicenseUserTooltipClass'
})
export class ReviewFileUserStatusLicenseUserTooltipClassPipe implements PipeTransform {
  transform(status: ReviewFileUserStatus | null | undefined, luminosity: '' | 'muted' | 'vivid' = ''): string {
    let statusClass: string = '';

    switch (status) {
      case ReviewFileUserStatus.Todo:
        statusClass = `mc-border-caution${luminosity ? '-' + luminosity : ''}`;
        break;
      case ReviewFileUserStatus.Submitted:
        statusClass = `mc-border-info${luminosity ? '-' + luminosity : ''}`;
        break;
      case ReviewFileUserStatus.InProgress:
        statusClass = `mc-border-info-2${luminosity ? '-' + luminosity : ''}`;
        break;
    }

    return `mc-tooltip-multiline mc-tooltip-centered mc-tooltip-border ${statusClass}`;
  }
}
