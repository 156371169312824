import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreventDefaultDirective } from '@portal-core/general/directives/prevent-default/prevent-default.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PreventDefaultDirective
  ],
  exports: [
    PreventDefaultDirective
  ]
})
export class PreventDefaultModule { }
