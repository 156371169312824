import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-review-file-settings-form',
  templateUrl: './review-file-settings-form.component.html',
  styleUrls: ['./review-file-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileSettingsFormComponent implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewFile: ReviewFile;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  ReviewFileStatus: typeof ReviewFileStatus = ReviewFileStatus;
  ReviewFileUserStatus: typeof ReviewFileUserStatus = ReviewFileUserStatus;

  savingState: LoadingState<string> = new LoadingState<string>();
  reviewSettingsForm: FormGroup;

  get dirty(): boolean {
    return this.reviewSettingsForm.dirty;
  }

  get statusChangedToOpen(): boolean {
    if (this.reviewFile) {
      return this.reviewFile.Status !== ReviewFileStatus.Open && this.reviewSettingsForm.value.status === ReviewFileStatus.Open;
    } else {
      return false;
    }
  }

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private reviewFilesService: ReviewFilesService
  ) {
    // Do this in the constructor so that it is ready for ngOnChanges
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reviewFile) {
      this.resetForm(this.reviewFile || {} as ReviewFile);
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    if (!this.reviewSettingsForm.valid) {
      return;
    }

    this.savingState.update(true);

    this.reviewFilesService.updateReviewFileStatus$(this.reviewFile.Id, this.reviewSettingsForm.get('status').value, this.statusChangedToOpen && this.reviewSettingsForm.value.resetReviewerStatus).subscribe(() => {
      this.savingState.update(false);
      this.reviewSettingsForm.markAsPristine();
      this.snackBar.open('Your settings have been saved.', 'OK', { duration: 2500 });
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to update your review file settings.', this.errorService.getErrorMessages(error));
    });
  }

  protected buildForm() {
    this.reviewSettingsForm = this.formBuilder.group({
      resetReviewerStatus: new FormControl<boolean>(false, { nonNullable: true }),
      status: new FormControl(null, [Validators.required])
    });
  }

  protected resetForm(reviewFile: ReviewFile) {
    this.reviewSettingsForm.reset({
      status: reviewFile.Status
    });
  }
}
