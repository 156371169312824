import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-task-board-delete-form',
  templateUrl: './task-board-delete-form.component.html',
  styleUrls: ['./task-board-delete-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoardDeleteFormComponent {
  @Input() taskBoard: TaskBoard;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private taskBoardsService: TaskBoardsService, private errorService: ErrorService, private snackBar: MatSnackBar) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.taskBoardsService.deleteTaskBoards$([this.taskBoard.Id]).subscribe(() => {
      this.savingState.update(false);

      this.snackBar.open('The task board has been deleted.', 'OK', { duration: 2500 });
      this.delete.emit();
    }, error => {
      this.savingState.update(false, 'Unnable to delete the task board.', this.errorService.getErrorMessages(error));
    });
  }
}
