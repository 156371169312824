import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, AfterContentInit, ViewChild, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MaterialService } from '@portal-core/general/services/material.service';
import { GridHeaderFilterMenuDirective } from '@portal-core/ui/grid/directives/grid-header-filter-menu/grid-header-filter-menu.directive';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridMenuEvent } from '@portal-core/ui/grid/models/grid-menu-event.model';
import { GridHeaderFilterFormDirective } from '@portal-core/ui/grid/directives/grid-header-filter-form/grid-header-filter-form.directive';

@Component({
  selector: 'mc-grid-header-filter',
  templateUrl: './grid-header-filter.component.html',
  styleUrls: ['./grid-header-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridHeaderFilterComponent implements OnInit, AfterContentInit {
  @Input() column: GridColumn;
  @Input() hasFilter: boolean;

  @Output() menuClosing: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() menuClosed: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() menuOpened: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ContentChild(GridHeaderFilterFormDirective) formDirective: GridHeaderFilterFormDirective;
  @ContentChild(GridHeaderFilterMenuDirective) menuDirective: GridHeaderFilterMenuDirective;

  formTemplate: TemplateRef<any>;
  menuTemplate: TemplateRef<any>;
  showFormPanel: boolean = false;

  get menuButtonIcon(): string {
    return this.menuTemplate ? 'icon-meatballs-solid' : 'icon-filter';
  }

  get menuEnabled(): boolean {
    return (this.column && this.column.filterEnabled) || !!this.menuTemplate;
  }

  get menuOpen(): boolean {
    return this.menuTrigger.menuOpen;
  }

  constructor(private materialService: MaterialService) { }

  ngOnInit() {
    this.showFormPanel = this.hasFilter;
  }

  ngAfterContentInit() {
    this.formTemplate = (this.formDirective && this.formDirective.templateRef) || null;
    this.menuTemplate = (this.menuDirective && this.menuDirective.templateRef) || null;
  }

  onMenuOpened() {
    this.menuOpened.emit({
      column: this.column
    });
  }

  onMenuClosed() {
    this.menuClosing.emit({
      column: this.column
    });

    // Once the menu is done closing
    setTimeout(() => {
      this.menuClosed.emit({
        column: this.column
      });
    }, this.materialService.MenuCloseAnimationDuration);
  }

  closeMenu() {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }
}
