import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { DataStreamService } from '@portal-core/data-stream/services/data-stream.service';
import { NotificationPageBase } from '@portal-core/notifications/models/notification-page-base.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subscription, map } from 'rxjs';

@Directive()
@AutoUnsubscribe()
export abstract class NotificationList implements OnInit {
  @Input() pageSize = 10;

  pageIndex: number = 0;
  notifications$: Observable<Notification[]>;
  loadingNotifications: boolean = false;
  allNotificationsLoaded: boolean = false;
  loadingError: string;
  hubReconnected: Subscription;

  constructor(protected notificationsService: NotificationsService, protected dataStreamService: DataStreamService, protected cdr: ChangeDetectorRef) { }

  protected abstract isConfigured(): boolean;
  protected abstract fetchNotifications$(pageSize: number, pageIndex: number): Observable<NotificationPageBase>;
  protected abstract filterNotifications(notifications: { [id: string]: Notification }): Notification[];

  onConfigurationChanged() {
    // Reset the state of the list
    this.loadingError = null;
    this.pageIndex = 0;
    this.allNotificationsLoaded = false;

    if (this.isConfigured()) {
      // Load the first page of notifications
      this.loadNotifications();
    } else {
      this.notifications$ = null;
    }
  }

  loadMoreNotifications() {
    if (this.isConfigured() && !this.allNotificationsLoaded) {
      this.pageIndex += 1;
      this.loadNotifications();
    }
  }

  loadNotifications() {
    if (this.isConfigured()) {
      this.loadingError = null;
      this.loadingNotifications = true;
      this.fetchNotifications$(this.pageSize, this.pageIndex).subscribe(page => {
        this.loadingNotifications = false;
        this.allNotificationsLoaded = page.AllNotificationsLoaded;
        // Wait until now when the new data is available to create the notifications observable so that the list isn't rendered with stale existing data while the new data is loading
        this.maybeCreateNotificationsObservable();
        this.cdr.markForCheck();
      }, () => {
        this.loadingNotifications = false;
        this.loadingError = 'There was a problem loading more notifications.';
        this.cdr.markForCheck();
      });
    }
  }

  ngOnInit() {
    this.hubReconnected = this.dataStreamService.hubReconnected$.subscribe(() => {
      this.onConfigurationChanged();
    });
  }

  private maybeCreateNotificationsObservable() {
    if (!this.notifications$) {
      // Create an observable for the notifications
      this.notifications$ = this.notificationsService.getItems$().pipe(
        map(notificationsMap => {
          const notifications: Notification[] = this.filterNotifications(notificationsMap);

          // Sort the notifications by the date they were created with the newest first
          notifications.sort((notificationA, notificationB) => {
            return new Date(notificationB.CreatedOn).getTime() - new Date(notificationA.CreatedOn).getTime();
          });

          notifications.splice((this.pageIndex + 1) * this.pageSize);

          return notifications;
        })
      );
    }
  }
}
