import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';

@Component({
  selector: 'mc-storage-usage-bar',
  templateUrl: './storage-usage-bar.component.html',
  styleUrls: ['./storage-usage-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageUsageBarComponent {
  @Input() licenseStorage: LicenseStorage;
  @Input() trialLicense: boolean;
}
