import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-site-deletion-form',
  templateUrl: './site-deletion-form.component.html',
  styleUrls: ['./site-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteDeletionFormComponent {
  @Input() site: Site;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private snackBar: MatSnackBar, private errorService: ErrorService, private sitesService: SitesService) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.sitesService.deleteSites$([this.site.Id]).subscribe(() => {
      this.savingState.update(false);
      this.snackBar.open('The site has been deleted.', 'OK', { duration: 2500 });
      this.deleted.emit();
    }, error => {
      this.savingState.update(false, 'Unable to delete the site.', this.errorService.getErrorMessages(error));
    });
  }
}
