import { containsInlineNode, nodeEq } from '@common/prosemirror/model/node';
import { getInlineNodeRange, getInlineRange, getNearestAncestorRange, getTextRanges, getWordRange } from '@common/prosemirror/model/range';
import { getParentFromNodeSelection } from '@common/prosemirror/state/selection';
import { liftInlineNode, liftTargetFromNodeType, setBlockType as setBlockTypeTransformation } from '@common/prosemirror/transform/node';
import { Fragment, Node, NodeRange, NodeType } from 'prosemirror-model';
import { Command, EditorState, NodeSelection, Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { canSplit, findWrapping } from 'prosemirror-transform';
import { EditorView } from 'prosemirror-view';

export interface WrapInlineNodeOptions {
  canWrapEmptySelection?: boolean;
  limitRangeToOneNode?: boolean;
  meta?: string | Plugin | PluginKey;
  metaValue?: any;
  wrapParentForEmptySelection?: boolean;
  wrapWordForEmptySelection?: boolean;
}

export type WrapInlineNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, attrs?: Dictionary) => boolean;

export function wrapInInlineNode(nodeType: NodeType, options: WrapInlineNodeOptions = {}): WrapInlineNodeCommand {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, attrs?: Dictionary): boolean {
    if (state.selection.empty && !options.wrapParentForEmptySelection && !options.wrapWordForEmptySelection && !options.canWrapEmptySelection) {
      return false;
    }

    // Calculate the ranges to wrap
    let ranges: NodeRange[];
    let range: NodeRange;

    // If the selection is empty
    if (state.selection.empty) {
      // If the parent should be wrapped
      if (options.wrapParentForEmptySelection) {
        // Grab the range for the inline node that the cursor is in
        range = getInlineNodeRange(state.selection.$from);
        if (range) {
          ranges = [range];
        }
      } else if (options.wrapWordForEmptySelection) { // Else if the word should be wrapped
        // Grab the range for the word in the text node that the cursor is in
        range = getWordRange(state.selection.$from);
        if (range) {
          ranges = [range];
        }
      }
      // Else there is a selection so use it for the range
    } else {
      if (options.limitRangeToOneNode) {
        range = getInlineRange(state.selection.$from, state.selection.$to);
        if (range) {
          ranges = [range];
        }
      } else {
        ranges = getTextRanges(state.selection.from, state.selection.to, state.doc);
      }
    }

    // If there are no valid ranges
    if (!Array.isArray(ranges) || ranges.length === 0) {
      return false;
    }

    const wrappings = [];
    for (let i = 0; i < ranges.length; i += 1) {
      // findWrapping requires the range's parent to have children
      if (ranges[i].parent.childCount > 0) {
        wrappings.push(findWrapping(ranges[i], nodeType, attrs));
      } else {
        wrappings.push(null);
      }
    }

    // If there are no valid wrappings
    if (!wrappings.some(wrap => !!wrap)) {
      return false;
    }

    // If the transaction should be dispatched
    if (dispatch) {
      // Create the transaction
      const tr = state.tr;

      // Apply the wraps in reverse document order. Why? I'm not sure but it fails otherwise. Maybe something to do with position changes?
      for (let i = ranges.length - 1; i >= 0; i -= 1) {
        // If there is a valid wrapping for this range
        if (wrappings[i]) {
          tr.wrap(ranges[i], wrappings[i]);
        }
      }

      // If meta data was specified then set it on the transaction
      if (options.meta) {
        tr.setMeta(options.meta, options.metaValue);
      }

      // Dispatch the transaction
      dispatch(tr.scrollIntoView());
    }


    // Return true indicating the command can/did execute
    return true;
  };
}

export interface LiftFromInlineNodeOptions {
  liftAncestorForEmptySelection?: boolean;
  meta?: string | Plugin | PluginKey;
  metaValue?: any;
}

export function getNodeRangesAndTargetsForLiftFromInlineNode(state: EditorState, nodeType: NodeType, options: LiftFromInlineNodeOptions = {}): { range: NodeRange, target: number }[] {
  // Calculate the ranges to lift
  let ranges: NodeRange[] = [];

  if (state.selection.empty || options.liftAncestorForEmptySelection) {
    const textSelection = state.selection as TextSelection;
    const range = getNearestAncestorRange(textSelection.$cursor || textSelection.$from, nodeType);
    if (range) {
      ranges.push(range);
    }
  } else {
    ranges = getTextRanges(state.selection.from, state.selection.to, state.doc, nodeType) ?? [];
  }

  return ranges.map(range => {
    const target = liftTargetFromNodeType(range, nodeType);

    if (typeof target === 'number') {
      return { range, target };
    }
  }).filter(item => !!item);
}

export function liftFromInlineNode(nodeType: NodeType, options: LiftFromInlineNodeOptions = {}): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    // console.log(nodeType.name + '.liftFromInlineNode');

    if (state.selection.empty && !options.liftAncestorForEmptySelection) {
      return false;
    }

    // Get the ranges to lift
    const rangesAndTargets = getNodeRangesAndTargetsForLiftFromInlineNode(state, nodeType, options);

    // If there are no ranges then return false indicating the command cannot be run
    if (rangesAndTargets.length === 0) {
      return false;
    }

    if (dispatch) {
      // Create the transaction
      const tr = state.tr;

      // Apply the lifts in reverse document order. Why? I'm not sure but it fails otherwise. Maybe something to do with position changes?
      for (let i = rangesAndTargets.length - 1; i >= 0; i -= 1) {
        liftInlineNode(tr, rangesAndTargets[i].range, rangesAndTargets[i].target);
      }

      // If meta data was specified then set it on the transaction
      if (options.meta) {
        tr.setMeta(options.meta, options.metaValue);
      }

      // Dispatch the transaction
      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

export interface ToggleInlineNodeOptions extends WrapInlineNodeOptions, LiftFromInlineNodeOptions { }

export function toggleInlineNode(nodeType: NodeType, options?: ToggleInlineNodeOptions): Command {
  const wrap = wrapInInlineNode(nodeType, options);
  const lift = liftFromInlineNode(nodeType, options);

  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    // If the selection is directly inside the inline node
    if (containsInlineNode(state.selection.from, state.selection.to, nodeType, state.doc)) {
      // Then lift the selection out of the inline node
      return lift(state, dispatch);
      // Else wrap the selection in the inline node
    } else {
      return wrap(state, dispatch);
    }
  };
}

/** Direct Prosemirror splitBlock with custom change */
// :: (EditorState, ?(tr: Transaction)) → bool
// Split the parent block of the selection. If the selection is a text
// selection, also delete its content.
export function splitBlock(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  const ref = state.selection;
  const $from = ref.$from;
  const $to = ref.$to;
  if (state.selection instanceof NodeSelection && state.selection.node.isBlock) {
    if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) {
      return false;
    }
    if (dispatch) {
      dispatch(state.tr.split($from.pos).scrollIntoView());
    }
    return true;
  }

  if (!$from.parent.isBlock) {
    return false;
  }

  if (dispatch) {
    const atEnd = $to.parentOffset == $to.parent.content.size;
    const tr = state.tr;
    if (state.selection instanceof TextSelection) {
      tr.deleteSelection();
    }
    const deflt = $from.depth == 0 ? null : $from.node(-1).contentMatchAt($from.indexAfter(-1)).defaultType;
    let types = atEnd && deflt ? [{ type: deflt }] : null;
    let can = canSplit(tr.doc, $from.pos, 1, types);
    if (!types && !can && canSplit(tr.doc, tr.mapping.map($from.pos), 1, deflt && [{ type: deflt }])) {
      types = [{ type: deflt }];
      can = true;
    }
    if (can) {
      // Don't split if the node is an empty placeholder. In the next step we will convert it to the default type
      if (!($from.parent.type.name === 'mcCentralContainer' && $from.parent.childCount === 0)) {
        tr.split(tr.mapping.map($from.pos), 1, types);
      }
      if ((!atEnd && !$from.parentOffset && $from.parent.type != deflt && $from.node(-1).canReplace($from.index(-1), $from.indexAfter(-1), Fragment.from([deflt.create(), $from.parent])))) {
        tr.setNodeMarkup(tr.mapping.map($from.before()), deflt);
      }
      /* Custom check to disallow mixed content.
       * Change to the default block type if split would create mcCentralContainers
       * mcCentralContainers are block representation of text nodes
       */
      const $newPos = tr.doc.resolve(tr.doc.resolve($from.pos).after());
      // Check if the split nodes are text representations
      if ($newPos.nodeBefore?.type.name === 'mcCentralContainer') {
        tr.setNodeMarkup($newPos.pos - $newPos.nodeBefore.nodeSize, deflt);
      }
      if ($newPos.nodeAfter?.type.name === 'mcCentralContainer') {
        tr.setNodeMarkup($newPos.pos, deflt);
      }
    }
    dispatch(tr.scrollIntoView());
  }
  return true;
}

export function splitInlinesNearestBlock(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  const { $from, $to } = state.selection;

  // if (state.selection instanceof NodeSelection && state.selection.node.isInline) {
  //     if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) {
  //         return false;
  //     }
  //     if (dispatch) {
  //         dispatch(state.tr.split($from.pos).scrollIntoView());
  //     }

  //     return true;
  // }

  // NodeSelection is not handled at this time
  if (state.selection instanceof NodeSelection) {
    return false;
  }

  if (!$from.parent.isInline) {
    return false;
  }

  if (dispatch) {
    const atStart = $from.parentOffset === 0;
    const atEnd = $to.parentOffset === $to.parent.content.size;
    const tr = state.tr;

    if (state.selection instanceof TextSelection) {
      tr.deleteSelection();
    }

    // Find the nearest ancestor block to split
    let depth = $from.depth - 1;
    let blockDepth = -1;

    let firstChildCheckNode = $from.node($from.depth);
    let firstChildCheck = true;
    while (depth >= 0 && blockDepth === -1) {
      const node = $from.node(depth);
      if (!nodeEq(node.firstChild, firstChildCheckNode) && firstChildCheck) {
        firstChildCheck = false;
      }
      if (node.isBlock) {
        blockDepth = depth;
      }
      firstChildCheckNode = node;
      depth -= 1;
    }

    if (blockDepth < 0) {
      return false;
    }

    // Determine the node types for the content after the split
    const splitDepth = $from.depth - blockDepth + 1;
    const deflt = $from.depth === 0 ? null : $from.node(blockDepth - 1).contentMatchAt($from.indexAfter(blockDepth - 1)).defaultType;
    let types = atEnd && deflt ? [{ type: deflt }] : null;
    let can = canSplit(tr.doc, $from.pos, splitDepth, types);

    if (!types && !can && canSplit(tr.doc, tr.mapping.map($from.pos), splitDepth, deflt && [{ type: deflt }])) {
      types = [{ type: deflt }];
      can = true;
    }

    // Split the content
    tr.split(tr.mapping.map($from.pos), splitDepth, types);

    // If the split happened at the beginning of the node then replace the original line with an empty default block node
    if (atStart && deflt) {
      const newPos = $from.pos
      const newDepth = $from.depth - blockDepth;
      const from = newPos - newDepth;

      if (firstChildCheck) {
        // default block node wrapping doesnt happen when you hit enter from the start, so need to replace instead of delete with a default block node
        tr.replaceRangeWith(from, $from.pos, deflt.create());
      } else {
        tr.deleteRange(from, $from.pos);
      }

    }
    // If the split happened at the end of the node THEN clear the content out of the new node so it is only the block node
    else if (atEnd && deflt) {
      // Calculate the range of the new block node
      const newPos = tr.mapping.map($from.pos);
      const newDepth = $from.depth - blockDepth;
      const from = newPos - newDepth;
      const to = newPos + newDepth;

      // Delete the empty copied node and only leave the default block node
      tr.deleteRange(from, to);
    }

    dispatch(tr.scrollIntoView());
  }

  return true;
}

// A command to replace the selection with a node
export function replaceSelectionWith(node: Node): Command {
  return (state: EditorState, dispatch?: ProsemirrorDispatcher) => {
    if (dispatch) {
      dispatch(state.tr.replaceSelectionWith(node));
    }
    return true;
  };
}

/**
 * This is a command to join two non text blocks together after backspacing requires a join of two nodes.
 * Example ( where | is your current selection) ---- <h1>some text</h1>|<a>other text </a> -> <h1>some text|<a>other text</a></h1>
 * @param state The current state of the editor.
 * @param dispatch To be able to send the commands out to the editor.
 * @returns Return true if successfully sent out commands.
 */
export function joinInlineNodeToTextBlock(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  const selection = state.selection;
  const $cut = selection.$head;
  const tr = state.tr;

  if (selection.empty && !$cut.parentOffset && !$cut.parent.isTextblock) {
    if (dispatch) {
      tr.deleteRange($cut.pos - 2, $cut.pos + $cut.parent.nodeSize);
      tr.insert($cut.pos - 3, $cut.parent);
      tr.setSelection(TextSelection.near(tr.doc.resolve($cut.pos - 3)));
      dispatch(tr.scrollIntoView());
    }
    return true;
  }
  return false;
}

// A command to set the block type of text block nodes in the current selection
// When changing the block type of each node the node's attrs are copied over to the new node.
// Optionally include a list of attrs to not copy over.
// This code is a slight modification of prosemirror-commands' setBlockType https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L414
export function setBlockType(nodeType: NodeType, excludeAttrs?: string[]): Command {
  return function (state: EditorState, dispatch: ProsemirrorDispatcher) {
    const { from, to } = state.selection;
    let applicable = false;

    state.doc.nodesBetween(from, to, (node, pos) => {
      if (applicable) {
        return false;
      }

      if (!node.isTextblock || node.hasMarkup(nodeType)) {
        return;
      }

      if (node.type === nodeType) {
        applicable = true;
      } else {
        const $pos = state.doc.resolve(pos), index = $pos.index();
        applicable = $pos.parent.canReplaceWith(index, index + 1, nodeType);
      }
    });

    if (!applicable) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      setBlockTypeTransformation(tr, from, to, nodeType, excludeAttrs)
      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

/**
 * A command to select the node immediately after the given position.
 *
 * For example, `<p>foo</p>|<p>bar</p>` would select the second paragraph.
 * @param state The current state of the editor.
 * @param dispatch If provided the the command will be dispatched.
 * @param view The editor view.
 * @param pos The position to select the node after.
 * @param to The position to place the head table cell when the selection is a cell selection.
 * @returns Return true if the command can run.
 */
export type SelectNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, pos?: number, to?: number) => boolean;

/**
 * Return a command to select the node immediately after the given position.
 *
 * For example, `<p>foo</p>|<p>bar</p>` would select the second paragraph.
 * @returns A command to select the node after the given position.
 */
export function selectNode(): SelectNodeCommand {
  return function (state: EditorState, dispatch: ProsemirrorDispatcher, view: EditorView, pos: number, to?: number): boolean {
    const $pos = state.doc.resolve(pos);
    const node = $pos.nodeAfter;

    if (!node || !NodeSelection.isSelectable(node)) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      if (node.type.name === 'table_cell' || node.type.name === 'table_header') {
        tr.setSelection(new CellSelection(state.doc.resolve(pos), state.doc.resolve(to || pos)));
      } else {
        tr.setSelection(NodeSelection.create(state.doc, pos));
      }
      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

/**
 * A command that replaces the deleted selection with the parent node's default content node type.
 * The command only runs if the parent defines fillIfContentDeleted to be true and the deleted selection is a node selection of the only child in the parent.
 * @param state The current state of the editor.
 * @param dispatch If provided the the command will be dispatched.
 * @param view The editor view.
 * @returns Return true if the command can run.
 */
export function deleteSelectionAndFill(state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView): boolean {
  if (state.selection.empty) {
    return false;
  }

  if (!(state.selection instanceof NodeSelection)) {
    return false;
  }

  const { parent, pos } = getParentFromNodeSelection(state.selection);
  if (!parent.type.spec.fillIfContentDeleted || parent.childCount !== 1) {
    return false;
  }

  if (dispatch) {
    const fillNodeType = typeof parent.type.spec.fillIfContentDeleted === 'string' ? state.schema.nodes[parent.type.spec.fillIfContentDeleted] : parent.type.contentMatch.defaultType;

    const tr = state.tr;
    tr.replaceSelectionWith(fillNodeType.createAndFill());

    if (fillNodeType.isAtom) {
      // Select the new node
      tr.setSelection(NodeSelection.create(tr.doc, pos + 1));
    } else {
      // Place the cursor inside the new node
      tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
    }

    dispatch(tr.scrollIntoView());
  }

  return true;
}
