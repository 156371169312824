import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { SelectionListHeaderModule } from '@portal-core/general/components/selection-list-header/selection-list-header.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { HasPermissionModule } from '@portal-core/permissions/pipes/has-permission/has-permission.module';
import { ReviewPackageUserListModule } from '@portal-core/reviews/review-package-users/components/review-package-user-list/review-package-user-list.module';
import { ReviewPackageReviewersFormComponent } from './review-package-reviewers-form.component';

@NgModule({
  declarations: [ReviewPackageReviewersFormComponent],
  exports: [ReviewPackageReviewersFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ErrorsExpansionPanelModule,
    HasPermissionModule,
    LicenseUserForLicenseAsyncModule,
    LicenseUserAvatarModule,
    LoaderModule,
    MatButtonModule,
    MatListModule,
    ReviewPackageUserListModule,
    SelectionListHeaderModule
  ]
})
export class ReviewPackageReviewersFormModule { }
