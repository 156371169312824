<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="reviewFile$ | async as reviewFile">
      <div class="mc-spring-board-header">
        <mc-review-file-avatar [disabled]="true" [reviewFile]="reviewFile" [showName]="false"></mc-review-file-avatar>
      </div>

      <div class="mc-spring-board-body">
        <p class="text-light-weight mc-break-text mc-profile-switch-board-bar-section">{{reviewFile.Name}}</p>

        <ul class="mc-property-list mc-profile-switch-board-bar-section">
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Status: </span>
            <span class="mc-property-value">{{ reviewFile.Status | mcReviewFileStatusText }}</span>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Package: </span>
            <mc-review-package-avatar class="mc-property-value" [reviewPackage]="reviewPackage$ | async" [showAvatar]="false" [truncate]="true"></mc-review-package-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Project: </span>
            <mc-project-avatar class="mc-property-value" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-project-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Branch: </span>
            <mc-branch-avatar class="mc-property-value" matDialogClose [branchName]="reviewFile.BranchName" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-branch-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Commit ID: </span>
            <mc-commit-avatar class="mc-property-value" [commit]="reviewFile.CommitId" [showAvatar]="false" [truncate]="true"></mc-commit-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Owner: </span>
            <mc-license-user-avatar class="mc-property-value" [licenseUser]="ownerLicenseUser$ | async" [showAvatar]="false" [truncate]="true"></mc-license-user-avatar>
          </li>
          <li class="mc-property-list-item-truncated">
            <span class="mc-property-name">Path: </span>
            <mc-project-file-avatar class="mc-property-value" [branchName]="reviewFile.BranchName" [filePath]="reviewFile.Path" [inline]="true" [project]="project$ | async" [showAvatar]="false"></mc-project-file-avatar>
          </li>
        </ul>
      </div>

      <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
        <a *ngIf="showSettingsTab$ | async" href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': profileTab === ReviewFileProfileTab.Settings }" (click)="profileTab = ReviewFileProfileTab.Settings">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-pen" color="primary"></mat-icon> Settings
        </a>
        <a href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': profileTab === ReviewFileProfileTab.Reviewers }" (click)="profileTab = ReviewFileProfileTab.Reviewers">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-team" color="primary"></mat-icon> Reviewers
        </a>
        <!-- <a href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': profileTab === ReviewFileProfileTab.Activity }" (click)="profileTab = ReviewFileProfileTab.Activity">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-clock" color="primary"></mat-icon> Activity
        </a> -->
        <ng-container *ngIf="reviewFile | mcReviewFileLink as reviewFileLink">
          <mat-divider></mat-divider>
          <a mat-list-item matDialogClose [routerLink]="reviewFileLink">
            <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-review" color="primary"></mat-icon> Open Review File
          </a>
        </ng-container>
      </mat-nav-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="profileTab" class="mc-spring-board">
    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewFileProfileTab.Settings">
      <mat-tab label="Settings">
        <mc-review-file-settings-form [licenseId]="(reviewFile$ | async)?.LicenseId" [reviewFile]="reviewFile$ | async" (cancel)="onCancel()" (saved)="onSettingsSaved()"></mc-review-file-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewFileProfileTab.Reviewers">
      <mat-tab label="Reviewers">
        <mc-review-file-reviewers-form [reviewFile]="reviewFile$ | async"></mc-review-file-reviewers-form>
      </mat-tab>
    </mat-tab-group>

    <!-- <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewFileProfileTab.Activity">
      <mat-tab label="Activity">
        <mc-review-file-notification-list [reviewFileId]="(reviewFile$ | async)?.Id"></mc-review-file-notification-list>
      </mat-tab>
    </mat-tab-group> -->
  </mc-switch-board-content>
</mc-switch-board>