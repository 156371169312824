import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Build } from '@portal-core/builds/models/build.model';
import { LicenseHostMapProfile } from '@portal-core/profiles/models/license-host-map-profile.model';
import { LicenseProfile } from '@portal-core/profiles/models/license-profile.model';
import { ProjectProfile } from '@portal-core/profiles/models/project-profile.model';
import { ReviewFileProfile } from '@portal-core/profiles/models/review-file-profile.model';
import { ReviewPackageProfile } from '@portal-core/profiles/models/review-package-profile.model';
import { TeamProfile } from '@portal-core/profiles/models/team-profile.model';
import { UserProfile } from '@portal-core/profiles/models/user-profile.model';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { Site } from '@portal-core/sites/models/site.model';
import { TranslationPackageLanguageBranchProfile } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch-profile.model';
import { TranslationPackageProfile } from '@portal-core/translation-packages/models/translation-package-profile.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { omit } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfilesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getProjectProfile$(projectId: number): Observable<ProjectProfile> {
    return this.http.get<ProjectProfile>(`${this.apiService.centralApiBaseUri}/api/projects/projectProfile/${projectId}`);
  }

  @SharePendingRequest()
  getReviewFileProfile$(reviewId: number): Observable<ReviewFileProfile> {
    return this.http.get<ReviewFileProfile>(`${this.apiService.centralApiBaseUri}/api/Reviews/ReviewProfile/${reviewId}`);
  }

  @SharePendingRequest()
  getReviewPackageProfile$(reviewPackageId: number): Observable<ReviewPackageProfile> {
    return this.http.get<ReviewPackageProfile>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/Profile/${reviewPackageId}`);
  }

  @SharePendingRequest()
  getTeamProfile$(teamId: number): Observable<TeamProfile> {
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/teams/teamProfile/${teamId}`).pipe(
      map(profile => {
        profile.Sites = profile.Sites.map(site => omit(site, ['PrivateOutputUrl', 'PrivateVanityOutputUrl', 'LiveOutputUrl', 'OutputUrl', 'Build']) as Site);
        return profile;
      })
    );
  }

  @SharePendingRequest()
  getUserProfile$(userId: string, licenseId: number): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${this.apiService.centralApiBaseUri}/api/users/userProfile/${userId}/${licenseId}`);
  }

  @SharePendingRequest()
  getLicenseHostMapProfile$(licenseHostMapId: number): Observable<LicenseHostMapProfile> {
    return this.http.get<LicenseHostMapProfile>(`${this.apiService.centralApiBaseUri}/api/Licensehostmap/licenseHostMapProfile/${licenseHostMapId}`);
  }

  @SharePendingRequest()
  getBuildProfile$(buildId: number): Observable<Build> {
    return this.http.get<Build>(`${this.apiService.centralApiBaseUri}/api/builds/buildProfile/${buildId}`);
  }

  @SharePendingRequest()
  getTranslationPackageProfile$(translationPackageId: number): Observable<TranslationPackageProfile> {
    return this.http.get<TranslationPackageProfile>(`${this.apiService.centralApiBaseUri}/api/translationPackage/${translationPackageId}/getPackageProfile`);
  }

  @SharePendingRequest()
  getTranslationPackageLanguageBranchProfile$(translationPackageLanguageBranchId: number): Observable<TranslationPackageLanguageBranchProfile> {
    return this.http.get<TranslationPackageLanguageBranchProfile>(`${this.apiService.centralApiBaseUri}/api/translationPackageLanguageBranch/${translationPackageLanguageBranchId}/getLanguageBranchProfile`);
  }

  @SharePendingRequest()
  getSiteProfile$(siteId: number): Observable<SiteGridItem> {
    return this.http.get<SiteGridItem>(`${this.apiService.centralApiBaseUri}/api/sites/GetSiteGridItem/${siteId}`);
  }

  @SharePendingRequest()
  getLicenseProfile$(licenseId: number): Observable<LicenseProfile> {
    return this.http.get<LicenseProfile>(`${this.apiService.centralApiBaseUri}/api/licenses/licenseProfile/${licenseId}`);
  }
}
