
import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, Input, OnDestroy, OnInit, Optional, QueryList, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { addLeadingSlash, removeLeadingSlash } from '@common/util/path';
import { ProjectFilesTreeComponent } from '@portal-core/project-files/components/project-files-tree/project-files-tree.component';
import { AllFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFilePickerInputItemDirective } from '@portal-core/project-files/directives/project-file-picker-input-item/project-file-picker-input-item.directive';
import { ProjectFilesTreeItemDirective } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.directive';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mc-project-file-picker-input',
  templateUrl: './project-file-picker-input.component.html',
  styleUrls: ['./project-file-picker-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-project-file-picker-input-disabled]': 'disabled',
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ProjectFilePickerInputComponent }
  ]
})

export class ProjectFilePickerInputComponent extends CustomInputBase<string[]> implements OnInit, OnDestroy {
  /** The branch name to pick the file from. */
  @Input() branchName: string;
  /** The place in the tree to scroll to on open if a user hasn't selected a value yet. */
  @Input() defaultPath: string;
  @Input() fileFilter: string = AllFileFilter;

  /** The height of the popup in pixels. Defaults to 200 pixels. */
  @Input() popupHeight?: number | 'width' = 200;

  /** The project id to pick the file from. */
  @Input() projectId: number;

  @Input() rootFolder: ProjectFolder = ProjectFolder.Root;

  @Input() treeClass?: string;

  /**
   * It indicates if a path is supposed to start with a slash, which is a common thing in Flare projects, for example: /Project/TOCs/Flare.fltoc
   * so in this case we show a value in the picker without leading slash but preserve it in the value property
   * By default it is false so not to change the behavior in the existing components
  */
  @Input() isPathWithLeadingSlash?: boolean = false;

  // observe project tree filter properties
  @InputObservable(['rootFolder', 'fileFilter']) treeFilter$: Observable<[ProjectFolder, string]>;

  @Input() multiple?: boolean = false;
  @ContentChildren(ProjectFilesTreeItemDirective) treeItemDirectives: QueryList<ProjectFilesTreeItemDirective>;
  @ContentChild(ProjectFilePickerInputItemDirective) itemDirective: ProjectFilePickerInputItemDirective;
  /** A reference to the popup component. */
  @ViewChild('popup', { static: true }) popup: PopupComponent;
  @ViewChild(ProjectFilesTreeComponent, { static: true }) projectFilesTree: ProjectFilesTreeComponent;
  @ViewChild('trigger', { static: true }) triggerElementRef: ElementRef<HTMLElement>;

  private unsubscribe = new Subject<void>();

  /** Whether the file picker has no value. Required by MatFormFieldControl. */
  get empty(): boolean {
    return !this.value?.length;
  }

  /** Required by CustomInputBase */
  readonly controlType: string = 'mc-project-file-picker-input';

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || this.popup?.opened;
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  ngOnInit(): void {
    // update the project tree on filter changes
    this.treeFilter$.pipe(takeUntil(this.unsubscribe))
      .subscribe(treeFilter => this.projectFilesTree.changeRootFolder(treeFilter[0], treeFilter[1]));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // release all subscriptions
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onPopupOpened() {
    this.projectFilesTree.checkViewportSize();

    this.value = this.value === null || this.value === undefined ? this.value = [] : this.value;
    if (this.value.length || this.defaultPath) {
      // Scroll the project files tree to what user selected last when the popup is opened or the default folder
      const lastSelectedPath = this.value.length ? this.value[this.value.length - 1] : null;
      this.projectFilesTree.scrollToPath(this.valueWithoutSlash(lastSelectedPath ?? this.defaultPath));
      this.projectFilesTree.value = this.value;
      this.projectFilesTree.setSelection();
    } else {
      // Scroll the project files tree back to the top when the popup is opened and no value selected
      this.projectFilesTree.scrollToTop();
    }
  }

  onFileChipRemoved(path: string) {
    if (!this.disabled) {
      this.value.splice(this.value.indexOf(path), 1);
      this.value = this.value.slice();
      this.projectFilesTree.findNodeByPathAndUnselect(path, this.value);
      this.onChange(this.value); // So Angular Forms know this control's value has changed
      this.onTouched(); // So Angular Forms know this control has been touched
      this.focus();
    }
  }

  onPopupClosed() {
    this.projectFilesTree.setSelection();
  }

  /** Handles the file selected event to update the file picker value. */
  onFileSelected(fileNode: ProjectFileFlatNode) {
    if (fileNode.path !== this.value?.[0]) {
      this.value = [this.valueWithSlash(fileNode.path)];
      this.onChange(this.value);
      this.onTouched();
      // the label for this input gets checked before this popup is closed and after its closed.
      // This timeout is to not have "Expression has changed after it was checked." error
      setTimeout(() => this.popup.close(), 0);
    }
  }

  onPathFiltersChanged(pathFilters: string[]) {
    this.value = pathFilters;
    this.onChange(this.value);
    this.onTouched();
  }

  focus() {
    this.triggerElementRef.nativeElement.focus();
  }

  open() {
    if (!this.disabled) {
      this.popup.open();
    }
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return 'File';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.triggerElementRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
    this.open();
  }

  /** Touch the form field on focus out */
  onFocusOut(): void {
    this.onTouched();
  }

  private valueWithoutSlash(value: string): string {
    return this.isPathWithLeadingSlash ? removeLeadingSlash(value) : value;
  }

  private valueWithSlash(value: string): string {
    return this.isPathWithLeadingSlash ? addLeadingSlash(value) : value;
  }
}
