import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ReviewPackageTocModel } from '@portal-core/reviews/models/review-package-toc.model';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';
import { CreateReviewPackage } from '@portal-core/reviews/review-packages/models/create-review-package.model';
import { ReviewPackageAnalytics } from '@portal-core/reviews/review-packages/models/review-package-analytics.model';
import { ReviewPackageFileAnalytics } from '@portal-core/reviews/review-packages/models/review-package-file-analytics.model';
import { ReviewPackageGridItem } from '@portal-core/reviews/review-packages/models/review-package-grid-item.model';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesApiService } from '@portal-core/reviews/review-packages/services/review-packages-api.service';
import { ReviewPackagesDataService } from '@portal-core/reviews/review-packages/services/review-packages-data.service';
import { ReviewPackageOperationType } from '@portal-core/reviews/review-packages/types/review-package-operation-type.type';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { map, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ReviewPackagesService extends CollectionServiceBase<ReviewPackage> {
  constructor(
    private reviewPackagesApiService: ReviewPackagesApiService,
    private reviewPackagesDataService: ReviewPackagesDataService,
    protected dataService: DataService
  ) {
    super(reviewPackagesDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<ReviewPackage> {
    return this.reviewPackagesApiService.getReviewPackageById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<ReviewPackage[]> {
    return this.reviewPackagesApiService.getReviewPackagesByIds$(itemIds);
  }

  updateReviewPackage$(reviewPackage: Partial<ReviewPackage>, resetReviewerStatus: boolean): Observable<MadCloudResult> {
    return this.reviewPackagesApiService.updateReviewPackage$(reviewPackage, resetReviewerStatus).pipe(
      switchMap(response => {
        return this.updateItems$({ [reviewPackage.Id]: reviewPackage }).pipe(map(() => response));
      })
    );
  }

  setReviewers$(reviewPackageId: number, reviewUserIds: string[]): Observable<MadCloudResult> {
    return this.reviewPackagesApiService.setReviewers$(reviewPackageId, reviewUserIds).pipe(
      switchMap(() => this.fetchItemById$(reviewPackageId)),
      // Insert the new review data into the collection store
      switchMap(reviewPackage => this.updateItems$([reviewPackage]))
    );
  }

  bulkUpdateReviewPackageStatus$(reviewPackageIds: number[], status: ReviewPackageStatus, resetReviewerStatus: boolean): Observable<any> {
    return this.reviewPackagesApiService.bulkUpdateReviewPackageStatus$(reviewPackageIds, status, resetReviewerStatus).pipe(
      // Insert the new review data into the collection store
      switchMap(() =>
        this.updateItems$(
          reviewPackageIds.map(reviewPackageId => {
            return {
              Id: reviewPackageId,
              Status: status
            };
          })
        )
      )
    );
  }

  createReviewPackage$(licenseId: number, projectId: number, reviewPackage: CreateReviewPackage): Observable<MadCloudResult> {
    return this.reviewPackagesApiService.createReviewPackage$(licenseId, projectId, reviewPackage);
  }

  deleteReviewPackages$(reviewPackageIds: number[]): Observable<MadCloudResult> {
    return this.reviewPackagesApiService.deleteReviewPackages$(reviewPackageIds).pipe(
      tap(() => {
        this.deleteItems$(reviewPackageIds);
      })
    );
  }

  getReviewPackagesPage$(
    licenseId: number,
    reviewersListLimit: number,
    filter?: PageFilter
  ): Observable<Page<ReviewPackageGridItem>> {
    return this.reviewPackagesApiService.getReviewPackagesPage$(licenseId, reviewersListLimit, filter);
  }

  getReviewsPackageAnalytics$(reviewPackageId: number, aggregateForAllUsers: boolean): Observable<ReviewPackageAnalytics> {
    return this.reviewPackagesApiService.getReviewsPackageAnalytics$(reviewPackageId, aggregateForAllUsers);
  }

  getReviewsPackageFileAnalytics$(reviewPackageId: number): Observable<ReviewPackageFileAnalytics> {
    return this.reviewPackagesApiService.getReviewsPackageFileAnalytics$(reviewPackageId);
  }

  getReviewsPackageToc$(reviewPackage: ReviewPackage, tocPath?: string): Observable<ReviewPackageTocModel> {
    const tocPathFinal = tocPath || reviewPackage.TocPath;

    if (!tocPathFinal) {
      throw new Error('TOC path is empty');
    }

    return this.reviewPackagesApiService
      .getReviewsPackageToc$(reviewPackage, tocPathFinal)
      .pipe(map(response => response.CatapultToc));
  }

  getHasAccess$(reviewPackageId: number, operationType: ReviewPackageOperationType): Observable<boolean> {
    return this.reviewPackagesApiService.getHasAccess$(reviewPackageId, operationType);
  }
}
