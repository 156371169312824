import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Notification } from '@portal-core/notifications/models/notification.model';

@Component({
  selector: 'mc-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent {
  @Input() notifications: Notification[];

  /**
   * Prevents re-rendering of the notification if the notification hasn't actually changed.
   * The notification's id is used to determine if a notification has changed.
   */
  trackById(index: number, notification: Notification): number {
    return notification.Id;
  }
}
