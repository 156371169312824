import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { of } from 'rxjs';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { Target } from '@portal-core/targets/models/target.model';
import { TargetsService } from '@portal-core/targets/services/targets.service';
import { TargetPageFilterConfig } from '@portal-core/targets/util/target-page-filter-config';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';

@Component({
  selector: 'mc-targets-select-list',
  templateUrl: './targets-select-list.component.html',
  styleUrls: ['./targets-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as TargetsSelectListComponent so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => TargetsSelectListComponent) }]
})
export class TargetsSelectListComponent extends SelectListBase<Target> implements OnChanges {
  @Input() processStates?: ProcessState[];
  @Input() projectId: number;

  filterConfig: TargetPageFilterConfig = new TargetPageFilterConfig(['Path', 'TargetPath']);

  listControl: SelectionListControl<Target> = new SelectionListControl<Target>(this.targetsService, filter => {
    if (typeof this.projectId === 'number') {
      return this.targetsService.getBuildTargetsPageByProjectId$(this.projectId, filter);
    } else {
      return of(null);
    }
  }, target => {
    return target.Id;
  });

  constructor(private targetsService: TargetsService, private pageFilterService: PageFilterService) {
    super(targetsService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.processStates) {
      this.applyFilter$('targets-select-list-process-states', this.pageFilterService.create({
        Id: 'targets-select-list-process-states',
        Type: PageFilterGroupType.Select
      }).select('LastNotification.ProcessState', this.processStates).value);
    }

    if (changes.projectId) {
      this.hardReload();
    }
  }
}
