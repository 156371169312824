import { MediaQueryString } from '@portal-core/util/types/media-query-string.type';

export const Breakpoints: Dictionary<MediaQueryString> = {
  pageMultiCardBreakpoint: '(min-width: 0px)',
  // pageSingleCardBreakpoint: '(max-width: 1439px)',
  userNavbarBreakpoint: '(min-width: 1024px)',
  userDrawerBreakpoint: '(max-width: 1023px)',
  pageNavbarTabLinksBreakpoint: '(min-width: 0px)',
  pageNavbarToolbarBreakpoint: '(min-width: 0px)',
  pageNavbarDropdownLinksBreakpoint: '(max-width: 1023px)',
  appNavbarLaunchPadBreakpoint: '(min-width: 0px)',
  appDrawerLaunchPadBreakpoint: '(max-width: 1023px)',
  appNavBreakpoint: '(min-width: 1490px)',
  pageGutterBreakpoint: '(min-width: 1490px)',
  appDrawerBreakpoint: '(max-width: 1498px)',
  appDrawerNavBreakpoint: '(max-width: 1498px)',
  appDrawerGutterBreakpoint: '(max-width: 1498px)',
  /** @deprecated */
  pageGridCardBreakpoint: '(max-width: 767px)',
  pageNoPaddingBreakpoint: '(max-width: 499px)',
  gridXSmallPaginator: '(max-width: 399px)',
  gridSmallPaginator: '(min-width: 400px) and (max-width: 459px)'
};
