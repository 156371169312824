import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RichTextBlockComponent } from './rich-text-block.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  declarations: [
    RichTextBlockComponent
  ],
  exports: [
    RichTextBlockComponent
  ]
})
export class RichTextBlockModule { }
