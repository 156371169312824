<form novalidate class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <ng-container *ngIf="!editing">
      <mc-review-package-user-list [licenseId]="reviewPackage?.LicenseId" listClass="mc-list-no-outer-spacing" [reviewPackageId]="reviewPackage?.Id"></mc-review-package-user-list>
    </ng-container>

    <ng-container *ngIf="editing">
      <mc-selection-list-header [avatarSpacing]="true" [selectionListItems]="allLicenseUsers" (selectAllChange)="onUserListSelectAllChanged()"></mc-selection-list-header>
      <mat-selection-list class="mat-primary-vivid" #usersList>
        <ng-container *ngFor="let licenseUser of allLicenseUsers">
          <mat-list-option checkboxPosition="before" [value]="licenseUser?.Value" [selected]="licenseUser?.Selected" (click)="onUserListOptionClicked(licenseUser)">
            <mc-license-user-avatar [disabled]="true" [licenseUser]="licenseUser?.LicenseUser" [showSeatType]="true" size="small" [truncate]="true"></mc-license-user-avatar>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </ng-container>
  </div>

  <div class="mc-spring-board-form-footer">
    <button *ngIf="!editing && (CentralPermissions.ManageReviews | mcHasPermission : reviewPackage?.ProjectId | async)" type="button" mat-raised-button color="primary" (click)="onEditClicked()">Edit</button>

    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div *ngIf="editing" class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>

  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
</form>