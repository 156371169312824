import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '@common/paged-data/types/page.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { SiteUrl } from '@portal-core/sites/models/site-url.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SiteUrlsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getSiteUrlById$(siteUrlId: number): Observable<SiteUrl> {
    return this.http.get<SiteUrl>(`${this.apiService.centralApiBaseUri}/api/sites/${siteUrlId}`);
  }

  @SharePendingRequest()
  getSiteUrlsPageByLicenseId$(licenseId: number, getDeleted: boolean, filter: PageFilter): Observable<Page<SiteUrl>> {
    return this.http.post<Page<SiteUrl>>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/GetSiteUrlsByLicenseList?getDeleted=${getDeleted}`, filter);
  }

  @SharePendingRequest()
  getSiteUrlsByLicenseId$(licenseId: number, getDeleted: boolean, filter: PageFilter): Observable<Page> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/GetSiteUrlsByLicenseList?getDeleted=${getDeleted}`, filter);
  }
}
