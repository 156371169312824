<div class="mc-dialog-card-content-container">
  <ng-container *ngIf="task">
    <div class="mc-dialog-tab-header mc-task-profile-dialog-container">
      <div class="mc-dialog-card-main">
        <div class="mc-task-profile-dialog-header">
          <mat-form-field color="primary-vivid" class="mc-task-profile-dialog-title-form-field">
            <input #title type="text" class="mc-task-profile-dialog-title-input" [(ngModel)]="task.Title" [maxlength]="256" [disabled]="!(canEditAllTasks$ | async)" required matInput>
            <mat-error>Title is required</mat-error>
            <span *ngIf="!title.value" class="mc-task-profile-dialog-title-placeholder mc-text-muted">Title</span>
          </mat-form-field>
          <div class="mc-task-profile-dialog-move-delete-container">
            <ng-container *ngIf="canEditAllTasks$ | async">
              <button mat-button type="button" color="link" class="mc-button-small" [matMenuTriggerFor]="milestoneMenu">Move</button>
              <span *ngIf="task.Id !== 0">|</span>
              <button *ngIf="task.Id !== 0" mat-button type="button" color="link" class="mc-button-small" (click)="onTaskDelete()">Delete</button>
            </ng-container>
          </div>
        </div>
        <mat-tab-group class="mc-arrow-tabs" color="secondary">
          <mat-tab label="Details">
            <div class="mc-dialog-card-tab-content">
              <mc-task-profile-dialog-details [task]="task" [projects]="projects$ | async" [licenseUsers]="licenseUsers$ | async" [canEditAllTasks]="canEditAllTasks$ | async"
                [taskBoard]="taskBoard$ | async" [taskBoards]="taskBoards$ | async" (delete)="onTaskDelete()" (descriptionChanged)="onDescriptionChanged($event)">
              </mc-task-profile-dialog-details>
            </div>
          </mat-tab>
          <mat-tab label="Discussion">
            <ng-template matTabContent>
              <div class="mc-dialog-card-tab-content">
                <mc-task-profile-dialog-discussion #discussion [commentGroups]="commentGroups" [newComment]="newComment" [licenseId]="license.Id" [showDelete]="task.IsDeleted"
                  [canEditAllTasks]="canEditAllTasks$ | async" (isDirtyChanged)="onTaskDiscussionDirtyChanged($event)">
                </mc-task-profile-dialog-discussion>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Attachments">
            <ng-template matTabContent>
              <div class="mc-dialog-card-tab-content">
                <mc-task-profile-dialog-attachments [newComment]="newComment" [license]="license" [canEditAllTasks]="canEditAllTasks$ | async" (isDirtyChanged)="onTaskAttachmentsDirtyChanged()">
                </mc-task-profile-dialog-attachments>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <button class="mc-dialog-tab-header-close-button" type="button" color="primary" mat-icon-button tabindex="-1" (click)="onClose()">
        <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </button>
    </div>
    <div class="mc-dialog-card-actions">
      <button mat-stroked-button class="mc-dialog-card-action-button" [mat-dialog-close]="true">Cancel</button>
      <button *ngIf="canEditAllTasks$ | async" mat-raised-button color="primary" class="mc-dialog-card-action-button" (click)="onSaveClicked()"
        [disabled]="!isDialogDirty || !task.Title || !task.TaskBoardId || task.EstHour < 0 || task.EstHour > 999 || (!!task.StartDate && !task.DueDate) || (!task.StartDate && !!task.DueDate) || areDatesNotSetOrOverlapping() || task.Position >= detailsComponent.maxPosition || !(canEditAllTasks$ | async)">
        Save
      </button>
    </div>
  </ng-container>

  <mc-loader class="mc-dialog-card-loader" *ngIf="loadingData"></mc-loader>
  <div *ngIf="loadingData && isUploadingAttachments" class="mc-task-profile-dialog-upload-attachments"><span>Attachments are being uploaded</span></div>
</div>

<mat-menu class="popup-card" #milestoneMenu="matMenu" [overlapTrigger]="false">
  <button *ngIf="task && (task.Milestone !== TaskMilestone.ToDo || task.Status !== TaskStatus.Active)" mat-menu-item (click)="onStatusClicked(TaskStatus.Active, TaskMilestone.ToDo)">
    To Do
  </button>
  <button *ngIf="task && (task.Milestone !== TaskMilestone.InProgress || task.Status !== TaskStatus.Active)" mat-menu-item (click)="onStatusClicked(TaskStatus.Active, TaskMilestone.InProgress)">
    In Progress
  </button>
  <button *ngIf="task && (task.Milestone !== TaskMilestone.Completed || task.Status !== TaskStatus.Active)" mat-menu-item (click)="onStatusClicked(TaskStatus.Active, TaskMilestone.Completed)">
    Completed
  </button>
  <button *ngIf="task && (task.Status !== TaskStatus.Backlog)" mat-menu-item (click)="onStatusClicked(TaskStatus.Backlog)">
    Backlog
  </button>
  <button *ngIf="task && (task.Status !== TaskStatus.Archive)" mat-menu-item (click)="onStatusClicked(TaskStatus.Archive)">
    Archive
  </button>
</mat-menu>
