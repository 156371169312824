import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusColorClassModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-color-class/review-file-user-status-color-class.module';
import { ReviewFileUserStatusTextModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-text/review-file-user-status-text.module';
import { ChartModule } from '@portal-core/ui/charts/components/chart/chart.module';
import { ReviewFileReviewerStatusBarChartComponent } from './review-file-reviewer-status-bar-chart.component';

@NgModule({
  declarations: [
    ReviewFileReviewerStatusBarChartComponent
  ],
  imports: [
    ChartModule,
    CommonModule,
    ReviewFileUserStatusColorClassModule,
    ReviewFileUserStatusTextModule
  ],
  exports: [
    ReviewFileReviewerStatusBarChartComponent
  ]
})
export class ReviewFileReviewerStatusBarChartModule { }
