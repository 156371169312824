import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { Project } from '@portal-core/projects/models/project.model';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsApiService } from '@portal-core/teams/services/teams-api.service';
import { TeamsDataService } from '@portal-core/teams/services/teams-data.service';
import { User } from '@portal-core/users/models/user.model';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TeamsService extends CollectionServiceBase<Team> {
  constructor(private teamsDataService: TeamsDataService, private teamsApiService: TeamsApiService, protected dataService: DataService) {
    super(teamsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<Team> {
    return this.teamsApiService.getTeamById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<Team[]> {
    return this.teamsApiService.getTeamsByIds$(itemIds);
  }

  getTeamsByLicenseId$(licenseId: number, options?: GetDataOptions): Observable<Team[]> {
    return this.dataService.getDataListItems$<Team>('Licenses', licenseId, this.teamsDataService, {
      fetch: () => this.teamsApiService.getTeamsByLicenseId$(licenseId).pipe(
        map(teams => {
          if (Array.isArray(teams)) {
            return teams.sort((teamA, teamB) => teamA.Name.localeCompare(teamB.Name));
          }
        })
      )
    }, options);
  }

  getTeamsWithSitesByLicenseId$(licenseId: number, options?: GetDataOptions): Observable<Team[]> {
    return this.dataService.getDataListItems$<Team>('LicensesWithSites', licenseId, this.teamsDataService, {
      fetch: () => this.teamsApiService.getTeamsWithSitesByLicenseId$(licenseId).pipe(
        map(teams => {
          if (Array.isArray(teams)) {
            return teams.sort((teamA, teamB) => teamA.Name.localeCompare(teamB.Name));
          }
        })
      )
    }, options);
  }

  getTeamsPageByLicenseId$(licenseId: number, teamListLimit: number, filter: PageFilter): Observable<Page<Team>> {
    return this.teamsApiService.getTeamsPageByLicenseId$(licenseId, teamListLimit, filter);
  }

  getTeamProjects$(teamId: number): Observable<Project[]> {
    return this.teamsApiService.getTeamProjects$(teamId);
  }

  getTeamUsers$(teamId: number): Observable<User[]> {
    return this.teamsApiService.getTeamUsers$(teamId);
  }

  createTeam$(licenseId: number, team: FormData): Observable<Team> {
    return this.teamsApiService.createTeam$(licenseId, team).pipe(
      tap(newTeam => {
        this.teamsDataService.addItems$([newTeam]);
        this.teamsDataService.addItemsToListById$('Licenses', licenseId, [newTeam]);
        this.teamsDataService.addItemsToListById$('LicensesWithSites', licenseId, [newTeam]);
      })
    );
  }

  updateTeamSettings$(team: Team, avatarFile?: File): Observable<Team> {
    return this.teamsApiService.updateTeamSettings$(team, avatarFile)
      .pipe(tap(() => {
        this.teamsApiService.getTeamById$(team.Id).subscribe(newTeam => {
          this.teamsDataService.updateItems$({ [newTeam.Id]: newTeam });
        });
      })
      );
  }

  updateTeamUsersProjectsAndSites$(licenseId: number, team: Team): Observable<any> {
    return this.teamsApiService.updateTeamUsersProjectsAndSites$(licenseId, team).pipe(
      tap(() => {
        this.teamsApiService.getTeamById$(team.Id).subscribe(newTeam => {
          this.teamsDataService.updateItems$({ [newTeam.Id]: newTeam });
        });
      })
    );
  }

  deleteTeams$(licenseId: number, teamIds: number[]): Observable<any> {
    return this.teamsApiService.deleteTeams$(licenseId, teamIds).pipe(
      tap(() => this.teamsDataService.removeItems$(teamIds))
    );
  }

  getTeamsByUserAndLicense$(userId: string, licenseId: number): Observable<Team[]> {
    return this.teamsApiService.getTeamsByUserAndLicense$(userId, licenseId).pipe(
      tap(teams => this.teamsDataService.addItems$(teams))
    );
  }

  addUserToTeams$(userId: string, addToTeamIds: number[]): Observable<MadCloudResult> {
    return this.teamsApiService.addUserToTeams$(userId, addToTeamIds);
  }

  removeUserFromTeams$(userId: string, removeFromTeamIds: number[]): Observable<MadCloudResult> {
    return this.teamsApiService.removeUserFromTeams$(userId, removeFromTeamIds);
  }

  addUsersToTeam$(teamId: number, userIds: string[]): Observable<MadCloudResult> {
    return this.teamsApiService.addUsersToTeam$(teamId, userIds);
  }

  verifyTeamName$(licenseId: number, teamName: string): Observable<any> {
    return this.teamsApiService.verifyTeamName$(licenseId, teamName);
  }
}
