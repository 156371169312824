import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { basename } from '@common/util/path';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TargetFileExtension } from '@portal-core/project-files/constants/file-extensions.constant';
import { TargetFileInfo } from '@portal-core/targets/models/target-file-info.model';
import { Target } from '@portal-core/targets/models/target.model';
import { TargetsApiService } from '@portal-core/targets/services/targets-api.service';
import { TargetsDataService } from '@portal-core/targets/services/targets-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TargetsService extends CollectionServiceBase<Target> {
  constructor(
    private targetsDataService: TargetsDataService,
    private targetsApiService: TargetsApiService,
    protected dataService: DataService
  ) {
    super(targetsDataService, dataService);
  }

  protected fetchItemById$(itemId: string): Observable<Target> {
    return of(this.createTargetFromId(itemId));
  }

  protected fetchItemsById$(itemIds: string[]): Observable<Target[]> {
    return of(itemIds.map(itemId => this.createTargetFromId(itemId)));
  }

  getBuildTargetsPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Target>> {
    return this.targetsApiService.getBuildTargetsPageByProjectId$(projectId, filter).pipe(
      tap(page => {
        if (Array.isArray(page.Items) && page.Items.length > 0) {
          page.Items.forEach(target => {
            target.Id = this.buildTargetId(projectId, target.Path);
          });
        }
      })
    );
  }

  getProjectTargetFileInfo$(projectId: number, filePath: string, branchName: string): Observable<TargetFileInfo> {
    return this.targetsApiService.getProjectTargetFileInfo$(projectId, filePath, branchName);
  }

  buildTargetId(projectId: number, targetPath: string): string {
    if (typeof projectId === 'number' && typeof targetPath === 'string') {
      return `${projectId}-${targetPath}`;
    }
  }

  createTargetFromId(targetId: string): Target {
    if (typeof targetId === 'string') {
      const index = targetId.indexOf('-');
      if (index >= 0) {
        return {
          Id: targetId,
          ProjectId: parseInt(targetId.substring(0, index), 0),
          Path: targetId.substring(index + 1)
        };
      }
    }
  }

  getTargetFileNameFromPath(targetPath: string): string {
    return basename(targetPath, `.${TargetFileExtension}`);
  }

  getTargetIconName(outputType: number): string {
    switch (outputType) {
      case 0: { // dita
        return 'icon-ditaicon';
      }
      case 1: { // dotnet help
        return 'icon-dotnethelpicon';
      }
      case 2: { // framemaker
        return 'icon-icon-frame-maker';
      }
      case 3: { // html help
        return 'icon-xhtmlicon';
      }
      case 4: { // pdf
        return 'icon-pdficon';
      }
      case 5: { // webhelp
        return 'icon-webhelpicon';
      }
      case 6: { // html5
        return 'icon-html5icon';
      }
      case 7: { // adobe air
        return 'icon-icon-adobe-air';
      }
      case 8: { // webhelp mobile
        return 'icon-mobileicon';
      }
      case 9: { // webhelp plus
        return 'icon-webhelpplusicon';
      }
      case 10: { // word
        return 'icon-wordicon';
      }
      case 11: { // xhtml book
        return 'icon-xhtmlicon';
      }
      case 12: { // xps
        return 'icon-xpsicon';
      }
      case 13: { // epub
        return 'icon-epubicon';
      }
      case 14: { // eclipse
        return 'icon-eclipseicon';
      }
      case 15: { // batch
        return 'icon-icon-batch-target';
      }
      case 16: { // clean xhtml
        return 'icon-cleanxhtmltarget';
      }
      default: {
        return null;
      }
    }
  }

  getTargetIconNameByType(outputType: string): string {
    switch (outputType) {
      case 'DITA': { // dita
        return 'icon-ditaicon';
      }
      case 'DotNet Help': { // dotnet help
        return 'icon-dotnethelpicon';
      }
      case 'FrameMaker': { // framemaker
        return 'icon-icon-frame-maker';
      }
      case 'HTML Help': { // html help
        return 'icon-xhtmlicon';
      }
      case 'PDF': { // pdf
        return 'icon-pdficon';
      }
      case 'WebHelp': { // webhelp
        return 'icon-webhelpicon';
      }
      case 'HTML5': { // html5
        return 'icon-html5icon';
      }
      case 'WebHelp AIR': { // adobe air
        return 'icon-icon-adobe-air';
      }
      case 'WebHelp Mobile': { // webhelp mobile
        return 'icon-mobileicon';
      }
      case 'WebHelp Plus': { // webhelp plus
        return 'icon-webhelpplusicon';
      }
      case 'Word': { // word
        return 'icon-wordicon';
      }
      case 'XHTML Book': { // xhtml book
        return 'icon-xhtmlicon';
      }
      case 'XPS': { // xps
        return 'icon-xpsicon';
      }
      case 'EPUB': { // epub
        return 'icon-epubicon';
      }
      case 'Eclipse Help': { // eclipse
        return 'icon-eclipseicon';
      }
      case 'Batch': { // batch
        return 'icon-icon-batch-target';
      }
      case 'Clean XHTML': { // clean xhtml
        return 'icon-cleanxhtmltarget';
      }
      default: {
        return null;
      }
    }
  }
}
