import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { MathService } from '@portal-core/general/services/math.service';
import { ThemeService } from '@portal-core/general/services/theme.service';
import { ReviewFileStatusColorPipe } from '@portal-core/reviews/review-files/pipes/review-file-status-color/review-file-status-color.pipe';
import { ReviewFileStatusTextPipe } from '@portal-core/reviews/review-files/pipes/review-file-status-text/review-file-status-text.pipe';
import { ReviewPackageFileAnalytics } from '@portal-core/reviews/review-packages/models/review-package-file-analytics.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { BarController, BarElement, CategoryScale, Chart, ChartData, ChartDataset, ChartOptions, Colors, LinearScale } from 'chart.js';
import { Observable, map } from 'rxjs';

Chart.register(BarController, BarElement, CategoryScale, Colors, LinearScale);

interface ChartCountsData {
  Open: number;
  Closed: number;
  Accepted: number;
}

@Component({
  selector: 'mc-review-file-status-bar-chart',
  templateUrl: './review-file-status-bar-chart.component.html',
  styleUrls: ['./review-file-status-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReviewFileStatusBarChartComponent implements OnInit {
  @Input() analytics: ReviewPackageFileAnalytics;
  @Input() keyJustification?: 'around' | 'between' = 'between';

  @InputObservable('analytics') analytics$: Observable<ReviewPackageFileAnalytics>;

  ReviewFileStatus: typeof ReviewFileStatus = ReviewFileStatus;

  chartCounts: ChartCountsData;
  chartData$: Observable<ChartData<'bar'>>;
  chartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  };

  private barThickness: number = 68;

  constructor(
    private themeService: ThemeService,
    private mathService: MathService,
    private reviewFileStatusColorPipe: ReviewFileStatusColorPipe,
    private reviewFileStatusTextPipe: ReviewFileStatusTextPipe
  ) { }

  ngOnInit() {
    this.chartData$ = this.analytics$.pipe(
      map((analytics) => {
        if (analytics) {
          this.chartCounts = {
            Open: analytics.StatusFileCounts.find(statusCount => statusCount.Status === ReviewFileStatus.Open)?.Count ?? 0,
            Closed: analytics.StatusFileCounts.find(statusCount => statusCount.Status === ReviewFileStatus.Closed)?.Count ?? 0,
            Accepted: analytics.StatusFileCounts.find(statusCount => statusCount.Status === ReviewFileStatus.Accepted)?.Count ?? 0
          };
          return this.getChartData(analytics);
        } else {
          this.chartCounts = null;
          return { datasets: [], labels: [] };
        }
      })
    );
  }

  private getChartData(analytics: ReviewPackageFileAnalytics): ChartData<'bar'> {
    const percentMap: Dictionary<number> = {
      [ReviewFileStatus.Open]: 0,
      [ReviewFileStatus.Closed]: 0,
      [ReviewFileStatus.Accepted]: 0
    };

    analytics.StatusFileCounts?.forEach(statusCount => {
      percentMap[statusCount.Status] = (statusCount.Count / analytics.TotalFileCount) * 100;
    });

    const percents = this.mathService.roundPercentagesToOneHundred(Object.values(percentMap));

    const datasets: ChartDataset<'bar'>[] = [
      {
        backgroundColor: this.themeService.getColor(this.reviewFileStatusColorPipe.transform(ReviewFileStatus.Open)),
        barThickness: this.barThickness,
        data: [percents[ReviewFileStatus.Open]],
        label: this.reviewFileStatusTextPipe.transform(ReviewFileStatus.Open)
      },
      {
        backgroundColor: this.themeService.getColor('basic'),
        barThickness: this.barThickness,
        data: [percents[ReviewFileStatus.Closed]],
        label: this.reviewFileStatusTextPipe.transform(ReviewFileStatus.Closed)
      },
      {
        backgroundColor: this.themeService.getColor(this.reviewFileStatusColorPipe.transform(ReviewFileStatus.Accepted)),
        barThickness: this.barThickness,
        data: [percents[ReviewFileStatus.Accepted]],
        label: this.reviewFileStatusTextPipe.transform(ReviewFileStatus.Accepted)
      }
    ];

    return {
      datasets,
      labels: ['', '', '']
    };
  }
}
