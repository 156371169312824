import { Component, ViewEncapsulation, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { of } from 'rxjs';
import { isEnumValue } from '@common/util/enum';
import { BuildLogsService } from '@portal-core/build-logs/services/build-logs.service';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ClipboardService } from '@portal-core/general/services/clipboard.service';
import { LogDialogComponent } from '@portal-core/logs/components/log-dialog/log-dialog.component';
import { ProcessLogMessageType } from '@portal-core/processes/enums/process-log-message-type.enum';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';
import { ProcessNotificationService } from '@portal-core/processes/services/process-notification.service';
import { DataGridBase } from '@portal-core/ui/grid/util/data-grid-base';
import { DataGridControl } from '@portal-core/ui/grid/util/data-grid-control';
import { BuildLogPageFilterConfig } from '@portal-core/build-logs/util/build-log-page-filter-config';

@Component({
  selector: 'mc-build-logs-grid',
  templateUrl: './build-logs-grid.component.html',
  styleUrls: ['./build-logs-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildLogsGridComponent extends DataGridBase<ProcessNotification> implements OnChanges {
  @Input() buildId: number;
  @Input() logMessageType: ProcessLogMessageType;

  filterConfig: BuildLogPageFilterConfig = new BuildLogPageFilterConfig();
  gridControl: DataGridControl<ProcessNotification> = new DataGridControl(this.processNotificationService, filter => {
    if (typeof this.buildId === 'number' && isEnumValue(ProcessLogMessageType, this.logMessageType)) {
      return this.buildLogsService.getBuildLogsPageByMessageType$(this.buildId, this.logMessageType, filter);
    } else {
      return of(null);
    }
  }, this.filterConfig);

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private buildLogsService: BuildLogsService, private processNotificationService: ProcessNotificationService, private clipboardService: ClipboardService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.buildId || changes.logMessageType) {
      this.hardReload();
    }
  }

  openLogDialog(message: string) {
    this.dialog.open(LogDialogComponent, {
      ...LogDialogComponent.DialogConfig,
      data: { message }
    });
  }

  copyText(text: string) {
    const isCopied = this.clipboardService.copy(text);
    let message: string;

    if (isCopied) {
      message = 'Copied to clipboard';
    } else {
      message = 'Copy failed';
    }

    this.snackBar.open(message, null, {
      duration: 2500,
    });
  }
}
