import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { TranslationPackageLanguageBranchAvatarModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-avatar/translation-package-language-branch-avatar.module';
import { TranslationPackageAvatarModule } from '@portal-core/translation-packages/components/translation-package-avatar/translation-package-avatar.module';
import { TranslationPackageLanguageBranchOverviewComponent } from './translation-package-language-branch-overview.component';

@NgModule({
  declarations: [
    TranslationPackageLanguageBranchOverviewComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    TranslationPackageAvatarModule,
    TargetAvatarModule,
    TranslationPackageLanguageBranchAvatarModule
  ],
  exports: [
    TranslationPackageLanguageBranchOverviewComponent
  ]
})
export class TranslationPackageLanguageBranchOverviewModule { }
