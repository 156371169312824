import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '@portal-core/messages/models/message.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'

})
export class MessagesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  @SharePendingRequest()
  getMessagesByConversationId$(markRead: boolean, conversationId: number): Observable<Message> {
    return this.http.get<Message>(`${this.apiService.centralApiBaseUri}/api/conversations/markRead/${markRead}/${conversationId}`);
  }

  @SharePendingRequest()
  getMessageById$(conversationId: number): Observable<Message> {
    return this.http.get<Message>(`${this.apiService.centralApiBaseUri}/api/conversations/markRead/${conversationId}`);
  }
}
