import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileStatusColorClassModule } from '@portal-core/reviews/review-files/pipes/review-file-status-color-class/review-file-status-color-class.module';
import { ReviewFileStatusColorModule } from '@portal-core/reviews/review-files/pipes/review-file-status-color/review-file-status-color.module';
import { ReviewFileStatusTextModule } from '@portal-core/reviews/review-files/pipes/review-file-status-text/review-file-status-text.module';
import { ChartModule } from '@portal-core/ui/charts/components/chart/chart.module';
import { ReviewFileStatusBarChartComponent } from './review-file-status-bar-chart.component';

@NgModule({
  declarations: [
    ReviewFileStatusBarChartComponent
  ],
  imports: [
    ChartModule,
    CommonModule,
    ReviewFileStatusColorClassModule,
    ReviewFileStatusColorModule,
    ReviewFileStatusTextModule
  ],
  exports: [
    ReviewFileStatusBarChartComponent
  ]
})
export class ReviewFileStatusBarChartModule { }
