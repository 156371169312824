import { Directive } from '@angular/core';

/**
 * Bar of a switch board, needed as it's used as a selector in mc-switch-board.
 */
@Directive({
  selector: 'mc-switch-board-bar, [mcSwitchBoardBar]',
  // tslint:disable-next-line: use-host-property-decorator
  host: { class: 'mc-switch-board-bar mc-spring-board-header' }
})
export class SwitchBoardBarDirective { }
