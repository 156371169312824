import { cloneSlice } from '@common/prosemirror/model/node';
import { Slice } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { v4 as uuidv4 } from 'uuid';

export function cloneSliceWithGuids(slice: Slice): Slice {
  return cloneSlice(slice, (node, content) => {
    return node?.type?.spec?.attrs?.['MadCap:guid'] ? node.type.create({ ...node.attrs, 'MadCap:guid': uuidv4() }, content, node.marks) : node.copy(content);
  });
}

const GuidAttrPastePluginKey = new PluginKey('GuidAttrPastePlugin');

export function guidAttrPastePlugin(): Plugin {
  return new Plugin({
    key: GuidAttrPastePluginKey,

    props: {
      transformPasted: function (slice: Slice, editorView: EditorView) {
        return cloneSliceWithGuids(slice);
      }
    }
  });
}
