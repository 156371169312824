import { Directive, Input, TemplateRef } from '@angular/core';
import { EmptyGridDisplay } from '@portal-core/ui/grid/types/empty-grid-display.type';

@Directive({
  selector: '[mcEmptyGrid]'
})
export class EmptyGridDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('mcEmptyGrid') templateName: EmptyGridDisplay;

  constructor(public templateRef: TemplateRef<any>) { }
}
