<div class="mc-spring-board-body">
  <ul class="mc-property-list">
    <li>
      <span class="mc-property-name">Parent Package: </span>
      <span class="mc-property-value">
        <mc-translation-package-avatar [translationPackage]="translationPackage" [showAvatar]="false" [truncate]="true"></mc-translation-package-avatar>
      </span>
    </li>
    <li>
      <span class="mc-property-name">Translation Method: </span>
      <span class="mc-property-value">
        MadTranslations - {{TranslationPackageServicesDescriptions[(translationPackage)?.MadTranslationsService] ?? 'Manual'}}
      </span>
    </li>
    <li>
      <span class="mc-property-name">Description: </span>
      <span class="mc-property-value">
        <ng-container *ngIf="(translationPackage)?.ProjectDescription as projectDescription; else noDescription">
          {{projectDescription}}
        </ng-container>
        <ng-template #noDescription>
          <i class="mc-property-value">No Description</i>
        </ng-template>
      </span>

    </li>
    <li>
      <span class="mc-property-name">Due Date: </span>
      <span class="mc-property-value" *ngIf="(translationPackage )?.DueDate;else noDate">
        {{ (translationPackage)?.DueDate | date: 'MMM dd, yyyy' }}
      </span>
      <ng-template #noDate>
        <i class="mc-property-value">No Date</i>
      </ng-template>
    </li>
    <li>
      <span class="mc-property-name">Language: </span>
      <span class="mc-property-value">
        <mc-translation-package-language-branch-avatar [translationPackageLanguageBranch]="translationPackageLanguageBranch" [showAvatar]="false" [disabled]="true"></mc-translation-package-language-branch-avatar>
      </span>
    </li>
    <li>
      <span class="mc-property-name">Targets: </span>
      <span class="mc-property-value mc-break-text" *ngIf="(translationPackage)?.TargetPaths as targetPaths; else noTargets">
        <ng-container *ngIf="targetPaths.length; else noTargets">
          <ng-container *ngFor="let targetPath of targetPaths.split('|'); let isLast = last">
            <mc-target-avatar [showAvatar]="false" [showPath]="true" size="small" [targetPath]="targetPath" [truncate]="true" [emphasizeTargetName]="false"></mc-target-avatar>{{isLast ? '' : ', '}}
          </ng-container>
        </ng-container>

      </span>
      <ng-template #noTargets>
        <i class="mc-property-value">Whole Project</i>
      </ng-template>
    </li>
    <li class="mc-property-list-item-truncate">
      <span class="mc-property-name">Commit ID: </span>
      <span class="mc-property-value" [matTooltip]="(translationPackage)?.GitCommitId">
        {{ (translationPackage)?.GitCommitId }}
      </span>
    </li>
    <li class="mc-property-list-item-truncate">
      <span class="mc-property-name">Owner: </span>
      <span class="mc-property-value" [matTooltip]="(translationPackage)?.CreatedByUser.FullName">
        {{ (translationPackage)?.CreatedByUser.FullName }}
      </span>
    </li>
    <li class="mc-property-list-item-truncate">
      <span class="mc-property-name">Main Branch: </span>
      <span class="mc-property-value">
        {{ (translationPackage)?.SourceBranchName }}
      </span>
    </li>
    <li class="mc-property-list-item-truncate">
      <span class="mc-property-name">Last Downloaded: </span>
      <span class="mc-property-value">
        <ng-container *ngIf="translationPackageLanguageBranch.LastDownloaded; else noLastDownloaded">
          {{ translationPackageLanguageBranch.LastDownloaded }}
        </ng-container>
        <ng-template #noLastDownloaded>
          <i class="mc-property-value">No Last Downloaded</i>
        </ng-template>
      </span>
    </li>
    <li class="mc-property-list-item-truncate">
      <span class="mc-property-name">Last Uploaded: </span>
      <span class="mc-property-value">
        <ng-container *ngIf="translationPackageLanguageBranch.LastUploaded; else noLastUploaded">
          {{ translationPackageLanguageBranch.LastUploaded }}
        </ng-container>
        <ng-template #noLastUploaded>
          <i class="mc-property-value">No Last Uploaded</i>
        </ng-template>

      </span>
    </li>
  </ul>
</div>