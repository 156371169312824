import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { AuditCategory } from '@portal-core/notifications/models/audit-category.model';
import { NotificationDeliveryMethod } from '@portal-core/notifications/models/notification-delivery-method.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { UserNotification } from '@portal-core/users/models/user-notification.model';
import { UsersService } from '@portal-core/users/services/users.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription, combineLatest, map } from 'rxjs';

interface AuditCategoryColumn {
  auditCategories: AuditCategory[];
  treeControl: NestedTreeControl<AuditCategory>;
}

@Component({
  selector: 'mc-user-notifications-editor',
  templateUrl: './user-notifications-editor.component.html',
  styleUrls: ['./user-notifications-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class UserNotificationsEditorComponent implements OnInit {
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    return this.deliveryMethodForm?.dirty || !this.notificationsSelectionsUntouched;
  }

  auditCategoryColumns$: Observable<AuditCategoryColumn[]>;
  auditCategorySelection: SelectionModel<AuditCategory> = new SelectionModel<AuditCategory>(true);
  deliveryMethodForm: UntypedFormGroup;
  loadSubscription: Subscription;
  loadingState: LoadingState<string> = new LoadingState<string>();
  notificationDeliveryMethod: NotificationDeliveryMethod;
  savingState: LoadingState<string> = new LoadingState<string>();
  notificationsSelectionsUntouched: boolean = true;

  constructor(
    private notificationsService: NotificationsService,
    private errorService: ErrorService,
    private usersService: UsersService,
    private snackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.auditCategoryColumns$ = combineLatest([
      this.notificationsService.getAuditCategoryTree$(),
      this.usersService.getSubscriptions$()
    ]).pipe(
      map(([auditCategoryTree, subscribedCategories]) => {
        const auditCategoryColumns: AuditCategoryColumn[] = [];

        if (Array.isArray(auditCategoryTree) && Array.isArray(subscribedCategories)) {
          // Set all preSelected subcategories.
          auditCategoryTree.forEach(parentCategory => this.preSelectCategories(parentCategory, subscribedCategories));

          // Separate into 2 columns for the UI
          auditCategoryColumns.push({
            auditCategories: auditCategoryTree.slice(0, 6),
            treeControl: new NestedTreeControl(this.getChildren)
          });

          auditCategoryColumns.push({
            auditCategories: auditCategoryTree.slice(6, auditCategoryTree.length),
            treeControl: new NestedTreeControl(this.getChildren)
          });

          auditCategoryColumns.forEach(auditCategoryColumn => {
            // The dataNodes property only needs to be set if attempting to use expand all function. See https://github.com/angular/material2/issues/12469
            auditCategoryColumn.treeControl.dataNodes = auditCategoryColumn.auditCategories;
            auditCategoryColumn.treeControl.expandAll();
          });
        }

        return auditCategoryColumns;
      })
    );

    this.loadingState.update(true);

    // The component is done loading after the delivery method has been fetched and the columns created
    this.loadSubscription = combineLatest([
      this.notificationsService.getNotificationDeliveryMethod$(),
      this.auditCategoryColumns$
    ]).subscribe(([notificationDeliveryMethod]) => {
      this.notificationDeliveryMethod = notificationDeliveryMethod;
      this.deliveryMethodForm.reset({
        'notificationCenterDelivery': notificationDeliveryMethod.EnablePortalNotification,
        'emailDelivery': notificationDeliveryMethod.EnableEmailNotification
      });
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the notifications.', this.errorService.getErrorMessages(error));
    });
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit(formGroup: UntypedFormGroup) {
    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      const requests$ = [];
      const auditCategorySelections = this.auditCategorySelection.selected.map(t => t.Id);

      requests$.push(this.notificationsService.saveUserNotifications$(auditCategorySelections));

      // If enabling email notifications then delivery method cannot be null or the server will error. 'Instantly' is the value which v1 set on this occasion.
      // If EmailActivity ever becomes a value which the user can select it will be appropriate to create an enum which mirrors the one on the server
      const emailActivity = () => {
        if (formGroup.value['emailDelivery']) {
          return this.notificationDeliveryMethod.EmailActivity ? this.notificationDeliveryMethod.EmailActivity : 'Instantly';
        }
        return this.notificationDeliveryMethod.EmailActivity;
      };

      requests$.push(
        this.notificationsService.saveNotificationDeliveryMethod$({
          EnablePortalNotification: formGroup.value['notificationCenterDelivery'],
          EnableEmailNotification: formGroup.value['emailDelivery'],
          EnableSMSNotification: this.notificationDeliveryMethod.EnableEmailNotification,
          PhoneNumberNotification: this.notificationDeliveryMethod.PhoneNumberNotification,
          UrlInSMS: this.notificationDeliveryMethod.UrlInSMS,
          EmailActivity: emailActivity(),
        })
      );

      combineLatest(requests$).subscribe(() => {
        this.savingState.update(false);
        this.notificationsSelectionsUntouched = true;
        this.deliveryMethodForm.markAsPristine();
        this.snackBar.open('Notifications Saved', 'OK', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to save the notification settings.', this.errorService.getErrorMessages(error));
      });
    }
  }

  toggleNodeSelection(node: AuditCategory) {
    this.notificationsSelectionsUntouched = false;

    // It's a subcategory, simply toggle it
    if (node.ParentCategoryId) {
      this.auditCategorySelection.toggle(node);
      return;
    }

    // if not descendantsAllSelected then the parent is ether indeterminate or not selected so select all else deselect all
    this.descendantsAllSelected(node)
      ? this.auditCategorySelection.deselect(...node.SubCategories)
      : this.auditCategorySelection.select(...node.SubCategories);
  }

  hasNestedChildren = (index: number, node: AuditCategory) => Array.isArray(node.SubCategories) && node.SubCategories.length > 0;

  getChildren = (node: AuditCategory) => node.SubCategories;

  descendantsAllSelected(node: AuditCategory) {
    const selectedLength = node.SubCategories.filter(ac => this.auditCategorySelection.isSelected(ac)).length;
    const allLength = node.SubCategories.length;

    return selectedLength === allLength;
  }

  descendantsPartiallySelected(node: AuditCategory) {
    const selectedLength = node.SubCategories.filter(ac => this.auditCategorySelection.isSelected(ac)).length;
    const allLength = node.SubCategories.length;

    return selectedLength !== allLength && selectedLength !== 0;
  }

  protected preSelectCategories(parentCategory: AuditCategory, subscribedCategories: UserNotification[]) {
    this.auditCategorySelection.select(
      ...parentCategory.SubCategories.filter(
        category => subscribedCategories.some(subscribed => subscribed.AuditCategoryId === category.Id)
      )
    );
  }

  protected buildForm() {
    this.deliveryMethodForm = new UntypedFormGroup({
      'notificationCenterDelivery': new UntypedFormControl(),
      'emailDelivery': new UntypedFormControl()
    });
  }
}
