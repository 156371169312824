import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { AuthStatusMessage } from '@portal-core/auth/enums/auth-status-message.enum';
import { OnAuthError } from '@portal-core/auth/interfaces/auth-error.interface';
import { AuthModuleConfig } from '@portal-core/auth/models/auth-module-config.model';
import { AuthModuleConfigService } from '@portal-core/auth/services/auth-module-config.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { AuthInterceptorHttpParams } from '@portal-core/auth/util/auth-interceptor-http-params';
import { Observable, noop, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(AuthModuleConfigService) private config: AuthModuleConfig,
    private authService: AuthService,
    private injector: Injector
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.authService.getAccessToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(noop, err => {
        if (!this.ignoreError(request) && err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.logout$();
          } else if (err.status === 403) {
            const authStatus: AuthStatusMessage = AuthStatusMessage[err.headers.get('X-AuthStatus')] as AuthStatusMessage;

            // If there is an auth status message AND it is not the NeedsPermission message then this is a drastic auth error
            if (authStatus) {
              if (authStatus !== AuthStatusMessage.NeedsPermission) {
                this.onAuthError(authStatus, err, request);
              }
            } else {
              this.onAuthError(AuthStatusMessage.UserAccessDenied, err, request);
            }
          }
        }
      })
    );
  }

  private onAuthError(authStatus: AuthStatusMessage, error: any, request: HttpRequest<any>) {
    // If any onAuthError event handlers have been registered
    if (Array.isArray(this.config.onAuthError)) {
      // Then loop through each handler and call its method
      this.config.onAuthError.forEach(handlerClass => {
        const handler: OnAuthError = this.injector.get<OnAuthError>(handlerClass);
        if (handler.onAuthError) {
          handler.onAuthError(authStatus, error, request);
        }
      });
    }
  }

  private ignoreError(request: HttpRequest<any>): boolean {
    if (request.params instanceof AuthInterceptorHttpParams) {
      return request.params.interceptorConfig.ignoreAuthErrors;
    } else {
      return false;
    }
  }
}
