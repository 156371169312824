import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';

@CollectionStateSelectors({
  stateType: ProjectFilesState
})
@State<ProjectFilesState>({
  name: ProjectFilesState.source
})
@Injectable()
export class ProjectFilesState extends CollectionStateBase {
  static source = 'ProjectFiles';
  getSource(): string {
    return ProjectFilesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectFilesDataService extends CollectionDataServiceBase<ProjectFile> {
  constructor(protected store: Store) {
    super(store, ProjectFilesState);
  }
}
