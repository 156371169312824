<div class="mc-spring-board">
  <div class="mc-spring-board-body">
    <ng-container *ngIf="projectInfo">
      <div class="mc-project-overview-charts">
        <mc-doughnut-chart [options]="chartOptions" [data]="sourceBuildsChartData" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
          <ng-template mcDoughnutChartHole let-hole>
            <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize }}</span>
            <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
          </ng-template>
        </mc-doughnut-chart>
        <mc-doughnut-chart [options]="chartOptions" [data]="sourceFilesChartData" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
          <ng-template mcDoughnutChartHole let-hole>
            <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize }}</span>
            <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
          </ng-template>
        </mc-doughnut-chart>
      </div>

      <div class="mc-project-overview-properties">
        <ul class="mc-property-list">
          <li>
            <span class="mc-property-name">Created: </span>
            <span class="mc-property-value">{{ projectInfo?.CreatedOn | date: 'MM/dd/yy h:mm a' }}</span>
          </li>
          <li>
            <span class="mc-property-name">Modified: </span>
            <span class="mc-property-value">{{ projectInfo?.UpdatedOn | date: 'MM/dd/yy h:mm a' }}</span>
          </li>
          <li>
            <span class="mc-property-name">Storage Usage: </span>
            <span class="mc-property-value">{{ projectInfo?.TotalProjectStorage | mcFileSize }}</span>
          </li>
          <li class="mc-sub-property">
            <span class="mc-property-name">
              <mat-icon fontSet="project-titan" fontIcon="icon-dot" color="usage-builds" class="mc-centered-text-icon"></mat-icon>
              Builds:
            </span>
            <span class="mc-property-value">{{ projectInfo?.TotalBuildsSize | mcFileSize }}</span>
          </li>
          <li class="mc-sub-property">
            <span class="mc-property-name">
              <mat-icon fontSet="project-titan" fontIcon="icon-dot" color="usage-source-files" class="mc-centered-text-icon"></mat-icon>
              Project Files:
            </span>
            <span class="mc-property-value">{{ projectInfo?.TotalSourceFileSize | mcFileSize }}</span>
          </li>
        </ul>

        <ul class="mc-property-list">
          <li>
            <span class="mc-property-name">Live Sites: </span>
            <span class="mc-property-value">{{ projectInfo?.TotalLiveSites | number:'1.0-0' }}</span>
          </li>
          <li>
            <span class="mc-property-name">Total Builds: </span>
            <span class="mc-property-value">{{ projectInfo?.TotalStoredBuilds | number:'1.0-0' }}</span>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
