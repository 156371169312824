import { NodeType } from 'prosemirror-model';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export declare type CreateSnippetNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, src?: string, isInlineContent?: boolean) => boolean;

export function insertSnippet(textType: NodeType, blockType: NodeType): CreateSnippetNodeCommand {
    return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, src?: string, isInlineContent?: boolean): boolean {
        const selection = editorState.selection;
        const $from = selection.$from;
        const index = $from.index();

        if (!$from.parent.canReplaceWith(index, index, textType) && !$from.parent.canReplaceWith(index, index, blockType)) {
            return false;
        }

        if (dispatch && src) {
            // Check to see if it should be a blockNode
            let snippetType = textType;
            if (!isInlineContent && // Content must be multi line or be blocks
                (selection.empty && $from.parent.childCount == 0) || // Selection is in an empty block and
                (selection instanceof NodeSelection && selection.node.isBlock) || // Selection is a block
                (selection.empty && $from.nodeBefore?.isBlock && $from.nodeAfter?.isBlock)) // Selection is between blocks
            {
                snippetType = blockType;
            }

            dispatch(editorState.tr.replaceSelectionWith(snippetType.create({src: src})));
        }

        return true;
    }
}
