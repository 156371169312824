import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';

/**
 * This component should be used when an element must behave like a button but render like an inline element.
 * The primary use case for this is when a button's text may wrap. The <button> element does not wrap well as an inline element so use <mc-inline-button> instead.
 */
@Component({
  selector: 'mc-inline-button',
  templateUrl: './inline-button.component.html',
  styleUrls: ['./inline-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineButtonComponent{
  /** The theme color of the button. */
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    if (this._color) {
      this.renderer.removeClass(this.elementRef.nativeElement, `mat-${this._color}`);
    }

    this._color = color;

    if (this._color) {
      this.renderer.addClass(this.elementRef.nativeElement, `mat-${this._color}`);
    }
  }
  private _color: string;

  /** Whether or not the button is disabled. Gives the host element the mc-inline-button-disabled CSS class if true. */
  @HostBinding('class.mc-inline-button-disabled')
  @HostBinding('attr.aria-disabled')
  @Input() disabled?: boolean;

  /** Tabindex for the button. Needs to be a number so that the element can gain focus in the browser. */
  @Input() tabindex?: number = 0;

  /**
   * Emits when the user clicks on the button and the button is not disabled.
   * Normal DOM click events are still emitted when the button is disabled because only some HTML element support the `disabled` attribute.
   * Therefore use the `clicked` event instead of the `click` event to respect the `disabled` attribute.
   */
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  /** Gives the host element a tabindex value when the button is not disabled. */
  @HostBinding('attr.tabindex')
  get _tabindex (): number {
    // Do not give the element a tabindex when the button is disabled
    // This keeps the button from being able to gain focus while disabled
    return this.disabled ? undefined : this.tabindex;
  }

  /** Gives the host element an aria role value of button. */
  @HostBinding('attr.role') _ariaRole: string = 'button';

  /** Gives the host element the mc-inline-button CSS class. */
  @HostBinding('class.mc-inline-button') _inlineButtonClass: boolean = true;

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

  /** Handles keyboard events to make enter/space click the button. */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this.elementRef.nativeElement.click();
    }
  }

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.color = 'link'; // Default to 'link'. Set it here so that the color setter runs.
  }
}
