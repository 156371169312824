import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cache } from '@common/util/cache.operator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseSamlAuthenticationSettings } from '@portal-core/licenses/models/license-saml-authentication-settings.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, Subscription, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-license-sso-form',
  templateUrl: './license-sso-form.component.html',
  styleUrls: ['./license-sso-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicenseSsoFormComponent implements OnInit {
  @Input() license: License;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @InputObservable('license') license$: Observable<License>;

  createViewerUserOnDemandValueChangesSubscription: Subscription;
  enabledValueChangesSubscription: Subscription;
  isEditing: boolean;
  isSamlAuthenticationConfigured: boolean;
  ssoSettings: LicenseSamlAuthenticationSettings;
  ssoSettings$: Observable<LicenseSamlAuthenticationSettings>;
  ssoSettingsForm: UntypedFormGroup;
  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  samlAuthenticationSettings$: Observable<LicenseSamlAuthenticationSettings>;
  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(
    private licensesService: LicensesService,
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorService,
    private teamsService: TeamsService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.ssoSettings$ = this.reload$.pipe(
      tap(() => this.loadingState.update(true)),
      switchMap(() => this.license$),
      switchMap<License, Observable<LicenseSamlAuthenticationSettings>>(license => {
        if (license) {
          return this.licensesService.getSamlAuthenticationSettingsAndDefaultTeams$(license.Id);
        } else {
          return of(null);
        }
      }),
      // Load the team data for the default teams avatar list
      switchMap(ssoSettings => {
        return this.teamsService.loadItems$(ssoSettings?.SSODefaultTeamIds).pipe(
          map(() => ssoSettings)
        );
      }),
      cache()
    );

    this.ssoSettings$.subscribe(ssoSettings => {
      this.ssoSettings = ssoSettings;
      this.isSamlAuthenticationConfigured = !!ssoSettings;
      this.resetForm(ssoSettings ?? {} as LicenseSamlAuthenticationSettings);
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to get the single sign-on settings.', this.errorService.getErrorMessages(error));
    });
  }

  onConfigureSamlAuthentication() {
    // Reset the form when switching to edit mode. This makes sure the form shows the current settings even if the user changed the form and canceled out later
    this.resetForm(this.ssoSettings ?? {} as LicenseSamlAuthenticationSettings);
    this.isEditing = true;
  }

  onSubmit() {
    if (!this.ssoSettingsForm.valid) {
      return;
    }

    this.savingState.update(true);

    const ssoSettings: LicenseSamlAuthenticationSettings = {
      Enabled: this.ssoSettingsForm.get('saml.enabled').value ?? false,
      IdentityProviderIssuer: this.ssoSettingsForm.get('saml.identityProviderIssuer').value,
      IdpLogoutEndpoint: this.ssoSettingsForm.get('saml.idpLogoutEndpoint').value,
      PublicCertificate: this.ssoSettingsForm.get('saml.publicCertificate').value,
      SamlEndpoint: this.ssoSettingsForm.get('saml.samlEndpoint').value,
      LicenseId: this.license.Id,
      SignInButtonLabel: this.ssoSettingsForm.get('customization.signInButtonLabel').value,
      CreateViewerUserOnDemand: this.ssoSettingsForm.get('settings.createViewerUserOnDemand').value ?? false,
      SSODefaultTeamIds: this.ssoSettingsForm.get('settings.teams').value
    };

    this.licensesService.setSamlAuthenticationSettings$(this.license.Id, ssoSettings).subscribe(() => {
      this.reload$.next();
      this.savingState.update(false);
      this.isEditing = false;
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to update the license SAML authentication settings.', this.errorService.getErrorMessages(error));
    });
  }

  getTeamById$(teamId: number): Observable<Team> {
    return this.teamsService.getItemById$(teamId, { allowApiRequest: false });
  }

  private buildForm() {
    this.ssoSettingsForm = this.formBuilder.group({
      saml: new UntypedFormGroup({
        enabled: new UntypedFormControl(false),
        samlEndpoint: new UntypedFormControl(null),
        idpLogoutEndpoint: new UntypedFormControl(null),
        identityProviderIssuer: new UntypedFormControl(null),
        publicCertificate: new UntypedFormControl(null)
      }),
      settings: new UntypedFormGroup({
        createViewerUserOnDemand: new UntypedFormControl(false),
        teams: new UntypedFormControl(null, Validators.required)
      }),
      customization: new UntypedFormGroup({
        signInButtonLabel: new UntypedFormControl(null)
      })
    });

    // Observe the createViewerUserOnDemand value changing to update the state of the teams control
    this.createViewerUserOnDemandValueChangesSubscription = this.ssoSettingsForm.get('settings.createViewerUserOnDemand').valueChanges.subscribe(createViewerUserOnDemand => {
      const teamsControl = this.ssoSettingsForm.get('settings.teams');

      if (createViewerUserOnDemand) {
        teamsControl.enable();
      } else {
        teamsControl.setValue(null);
        teamsControl.disable();
      }
    });

    // Observe the enabled value changing to update the state of the saml controls
    this.enabledValueChangesSubscription = this.ssoSettingsForm.get('saml.enabled').valueChanges.subscribe(enabled => {
      if (enabled) {
        this.ssoSettingsForm.get('saml.samlEndpoint').setValidators([Validators.required]);
        this.ssoSettingsForm.get('saml.identityProviderIssuer').setValidators([Validators.required]);
        this.ssoSettingsForm.get('saml.publicCertificate').setValidators([Validators.required]);
      } else {
        this.ssoSettingsForm.get('saml.samlEndpoint').setValidators(null);
        this.ssoSettingsForm.get('saml.identityProviderIssuer').setValidators(null);
        this.ssoSettingsForm.get('saml.publicCertificate').setValidators(null);
      }

      this.ssoSettingsForm.get('saml').updateValueAndValidity();
    });
  }

  private resetForm(ssoSettings: LicenseSamlAuthenticationSettings) {
    this.ssoSettingsForm.reset({
      saml: {
        enabled: ssoSettings.Enabled ?? false,
        identityProviderIssuer: ssoSettings.IdentityProviderIssuer ?? '',
        idpLogoutEndpoint: ssoSettings.IdpLogoutEndpoint ?? '',
        publicCertificate: ssoSettings.PublicCertificate ?? '',
        samlEndpoint: ssoSettings.SamlEndpoint ?? ''
      },
      settings: {
        createViewerUserOnDemand: ssoSettings.CreateViewerUserOnDemand ?? false,
        teams: ssoSettings.SSODefaultTeamIds ?? null
      },
      customization: {
        signInButtonLabel: ssoSettings.SignInButtonLabel ?? ''
      }
    });

    // Update the teams control
    if (ssoSettings.CreateViewerUserOnDemand) {
      this.ssoSettingsForm.get('settings.teams').enable();
    } else {
      this.ssoSettingsForm.get('settings.teams').disable();
    }

    // Update the saml controls
    if (ssoSettings.Enabled) {
      this.ssoSettingsForm.get('saml.samlEndpoint').setValidators([Validators.required]);
      this.ssoSettingsForm.get('saml.identityProviderIssuer').setValidators([Validators.required]);
      this.ssoSettingsForm.get('saml.publicCertificate').setValidators([Validators.required]);
    } else {
      this.ssoSettingsForm.get('saml.samlEndpoint').setValidators(null);
      this.ssoSettingsForm.get('saml.identityProviderIssuer').setValidators(null);
      this.ssoSettingsForm.get('saml.publicCertificate').setValidators(null);
    }

    this.ssoSettingsForm.get('saml').updateValueAndValidity();
  }
}
