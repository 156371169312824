<mc-list [dataListId]="dataListId" [itemHeight]="48" [listClass]="listClass" [listControl]="listControl" [loaderBackgroundColor]="loaderBackgroundColor" [loaderColor]="loaderColor">
  <ng-template mcListItem let-reviewFileUser>
    <mat-list-item class="mc-list-item">
      <mc-license-user-avatar *ngIf="reviewFileUser.UserId | mcLicenseUserForLicenseAsync : licenseId | async as licenseUser" compact [licenseUser]="licenseUser" [showSeatType]="true" size="small" [truncate]="true" [tooltip]="reviewFileUser.Status | mcReviewFileUserStatusLicenseUserTooltip : licenseUser.Status : licenseUser.IsDeleted : licenseUser.SeatType : licenseUser.User.FullName" [tooltipClass]="reviewFileUser.Status | mcReviewFileUserStatusLicenseUserTooltipClass">
        <ng-template mcAvatarIndicator>
          <mat-icon class="mc-avatar-indicator-bottom-right mc-avatar-indicator-icon" [color]="reviewFileUser.Status | mcReviewFileUserStatusColor" fontSet="project-titan" [fontIcon]="reviewFileUser.Status | mcReviewFileUserStatusIcon"></mat-icon>
        </ng-template>>
      </mc-license-user-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>There are no reviewers assigned to this file.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All reviewers loaded.</p>
  </ng-template>
</mc-list>