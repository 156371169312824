import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { DeleteBuildType } from '@portal-core/builds/enums/delete-build-type.enum';
import { ProcessBuildType } from '@portal-core/builds/enums/process-build-type.enum';
import { BuildLanguageBranch } from '@portal-core/builds/models/build-language-branch.model';
import { BuildSchedule } from '@portal-core/builds/models/build-schedule.model';
import { Build } from '@portal-core/builds/models/build.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getBuild$(buildId: number): Observable<Build> {
    return this.http.get<Build>(`${this.apiService.centralApiBaseUri}/api/Build/${buildId}`);
  }

  @SharePendingRequest()
  getBuildsByIds$(buildIds: number[]): Observable<Build[]> {
    return this.http.post<Build[]>(`${this.apiService.centralApiBaseUri}/api/Builds/getBatchBuildsByIds`, buildIds);
  }

  @SharePendingRequest()
  getBuildByTargetPath$(projectId: number, branchName: string, targetPath: string): Observable<Build> {
    return this.http.get<Build>(`${this.apiService.centralApiBaseUri}/api/Builds/getBuildByTargetPath/${projectId}?targetPath=${encodeURIComponent(targetPath)}&branchName=${encodeURIComponent(branchName)}`);
  }

  @SharePendingRequest()
  getBuildByProjectIdAndPath$(projectId: number, targetPath: string): Observable<Build> {
    return this.http.get<Build>(`${this.apiService.centralApiBaseUri}/api/Builds/getBuildByProjectIdAndPath/${projectId}?targetPath=${encodeURIComponent(targetPath)}`);
  }

  @SharePendingRequest()
  getScheduledBuildsGridByProjectId$(projectId: number, filterModel: PageFilter): Observable<Page<BuildSchedule>> {
    return this.http.post<Page<BuildSchedule>>(`${this.apiService.centralApiBaseUri}/api/Builds/getScheduleBuildsGrid/${projectId}`, filterModel);
  }

  @SharePendingRequest()
  getBuildsPage$(filterModel: PageFilter): Observable<Page<Build>> {
    return this.http.post<Page<Build>>(`${this.apiService.centralApiBaseUri}/api/BuildNotification/builds/query`, filterModel);
  }

  @SharePendingRequest()
  getBuildsPageByProjectId$(projectId: number, filterModel: PageFilter): Observable<Page<Build>> {
    return this.http.post<Page<Build>>(`${this.apiService.centralApiBaseUri}/api/builds/BuildsGrid/${projectId}`, filterModel);
  }

  bulkDeleteBuilds$(projectId: number, buildIds: number[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/bulkDeleteBuilds/${projectId}`, buildIds);
  }

  deleteBuildsInProject$(projectId: number, deleteBuildType: DeleteBuildType): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/builds/${projectId}/deleteBuildsInProject/${deleteBuildType}`);
  }

  buildByTargetId$(targetId: number): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/target/${targetId}`, null);
  }

  @SharePendingRequest()
  buildByTargetPathAndBranchName$(projectId: number, currentTargetPath: string, currentBranchName: string, buildLanguageBranches: BuildLanguageBranch[]) {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/target/${projectId}?targetPath=${encodeURIComponent(currentTargetPath)}&branchName=${encodeURIComponent(currentBranchName)}`, buildLanguageBranches);
  }

  processBuild$(buildId: number, processBuildType: ProcessBuildType): Observable<any> {
    const params = new HttpParams().set('processBuildType', processBuildType.toString());
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/processBuild/${buildId}`, null, { params });
  }

  @SharePendingRequest()
  getDownloadBuildSasToken$(buildId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/builds/downloadToken/${buildId}`);
  }

  getBuildScheduleById$(buildScheduleId: number): Observable<BuildSchedule> {
    return this.http.get<BuildSchedule>(`${this.apiService.centralApiBaseUri}/api/builds/schedule/${buildScheduleId}`);
  }

  setBuildAsKeep$(buildId: number, keep: boolean): Observable<any> {
    if (keep) {
      return this.http.put(`${this.apiService.centralApiBaseUri}/api/builds/SetKeepBuild/${buildId}`, null);
    } else {
      return this.http.put(`${this.apiService.centralApiBaseUri}/api/builds/CancelKeepBuild/${buildId}`, null);
    }
  }

  setUserViewedBuild$(buildId: number): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/userViewed/${buildId}`, null);
  }

  saveBuildSchedule$(projectId: number, buildSchedule: BuildSchedule): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/${projectId}/buildSchedule`, buildSchedule);
  }

  bulkDeleteBuildSchedulesById$(projectId: number, buildScheduleIds: number[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/builds/${projectId}/bulkDeleteBuildSchedule`, buildScheduleIds);
  }
}
