import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[mcLazyRender]'
})
export class LazyRenderDirective {
  @Input() set mcLazyRender(condition: boolean) {
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private hasView: boolean = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }
}
