import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MadCapImageUrlPipe } from './madcap-image-url.pipe';

@NgModule({
  declarations: [MadCapImageUrlPipe],
  imports: [CommonModule],
  exports: [MadCapImageUrlPipe]
})
export class MadCapImageUrlModule { }
