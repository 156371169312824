/**
Specifies the folders that different file types belong to.
*/
export enum ProjectFolder {
  Root = '',
  Content = 'Content',
  Topics = 'Content',
  Projects = 'Project',
  ConditionTagSets = 'Project/ConditionTagSets',
  VariableSets = 'Project/VariableSets',
  Targets = 'Project/Targets',
  TOCs = 'Project/TOCs',
  Snippets = 'Content/Resources/Snippets',
  Images = 'Content/Resources/Images',
  Multimedia = 'Content/Resources/Multimedia',
  BrandingStylesheet = 'Content/Resources/Branding',
}
