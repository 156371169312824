import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';

@CollectionStateSelectors({
  stateType: LicenseStorageState
})
@State<LicenseStorageState>({
  name: LicenseStorageState.source
})
@Injectable()
export class LicenseStorageState extends CollectionStateBase {
  static source = 'LicenseStorage';
  getSource(): string {
    return LicenseStorageState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicenseStorageDataService extends CollectionDataServiceBase<LicenseStorage> {
  constructor(protected store: Store) {
    super(store, LicenseStorageState);
  }
}
