import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ListModule } from '@portal-core/ui/list/list.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectsSelectListComponent } from './projects-select-list.component';

@NgModule({
  declarations: [
    ProjectsSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    ProjectAvatarModule
  ],
  exports: [
    ProjectsSelectListComponent
  ]
})
export class ProjectsSelectListModule { }
