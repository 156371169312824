import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { BuildMessagesListComponent } from './build-messages-list.component';

@NgModule({
  declarations: [
    BuildMessagesListComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatListModule
  ],
  exports: [
    BuildMessagesListComponent
  ]
})
export class BuildMessagesListModule { }
