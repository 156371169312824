import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

/**
 * Wrapper for Material Expansion panel. Displays general error in title,
 * expands with list of detailed errors.
 */
@Component({
  selector: 'mc-errors-expansion-panel',
  templateUrl: './errors-expansion-panel.component.html',
  styleUrls: ['./errors-expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorsExpansionPanelComponent implements OnInit {
  @Input() generalError: string;
  @Input() detailedErrors: string[];
  @Input() showIcon: boolean = true;
  @Output() retry: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('detailedErrorsExpansionPanel') detailedErrorsExpansionPanel: MatExpansionPanel;

  showRetry: boolean = false;

  ngOnInit() {
    this.showRetry = this.retry.observers.length > 0;
  }

  toggleDetailErrorsExpansionPanel() {
    this.detailedErrorsExpansionPanel.toggle();
  }
}
