import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectorRef, Directive, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NgControl, NgForm, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { LegacyCanDisable as CanDisable, LegacyCanUpdateErrorState as CanUpdateErrorState, LegacyErrorStateMatcher as ErrorStateMatcher, LegacyHasTabIndex as HasTabIndex, LegacyThemePalette as ThemePalette, legacyMixinDisabled as mixinDisabled, legacyMixinErrorState as mixinErrorState, legacyMixinTabIndex as mixinTabIndex } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { noop } from 'lodash';
import { Subject } from 'rxjs';

/** Mixin Material's helpers */
const CustomInputMixinBase: Constructor<HasTabIndex> & Constructor<CanDisable> & Constructor<CanUpdateErrorState> = mixinTabIndex(mixinDisabled(mixinErrorState(
  class {
    readonly stateChanges = new Subject<void>();

    constructor(
      public _defaultErrorStateMatcher: ErrorStateMatcher,
      public _parentForm: NgForm,
      public _parentFormGroup: FormGroupDirective,
      public ngControl: NgControl
    ) { }
  }
)));

// Used to generate unique ids which are required by MatFormFieldControl.
let customInputControlId: number = 0;

/**
 * CustomInputBase provides common functionality for implementing MatFormFieldControl.
 */
@Directive()
export abstract class CustomInputBase<V> extends CustomInputMixinBase implements AfterViewInit, OnChanges, OnDestroy, DoCheck, ControlValueAccessor, Validator, CanDisable, HasTabIndex, CanUpdateErrorState, MatFormFieldControl<V> {
  /** The theme color of the input. */
  @Input() color: ThemePalette;

  /**
   * Whether the input is disabled. This fully overrides the implementation provided by mixinDisabled, but the mixin is still required because mixinTabIndex requires it.
   */
  @Input()
  override get disabled(): boolean {
    return this._disabled;
  }
  override set disabled(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this.cdr.markForCheck();
      this.stateChanges.next();
    }
  }
  private _disabled: boolean = false;

  /** The label displayed on the input. */
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    if (this._placeholder !== placeholder) {
      this._placeholder = placeholder;
      this.stateChanges.next();
    }
  }
  private _placeholder: string = this.getDefaultPlaceholder();

  /** Whether the input is required in a form. Required by MatFormFieldControl. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    if (this._required !== required) {
      this._required = coerceBooleanProperty(required);
      this.stateChanges.next();
    }
  }
  private _required: boolean = false;

  /** The current value of the input. Emits a valueChange event if the value being set is different than the current value. */
  @Input()
  get value(): V {
    return this._value;
  }
  set value(value: V) {
    if (!this.valuesAreEqual(this._value, value)) {
      this._value = value;
      this.cdr.markForCheck();
      this.valueChange.emit(value);
      this.stateChanges.next();
    }
  }
  private _value: V;

  /** Emits when the value changes (either due to user input or programmatic change). */
  @Output() valueChange: EventEmitter<V> = new EventEmitter<V>();

  /** Sets the aria-describedby attribute on the build picker. Required by MatFormFieldControl */
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  /** Adds an id to the element. Required by MatFormFieldControl. */
  @HostBinding('attr.id')
  get id(): string {
    return `${this.controlType}-${this._id}`;
  }
  private _id: number = customInputControlId++;

  /** Required by MatFormFieldControl */
  focused: boolean;

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  /** The value change callback for Angular Forms */
  protected onChange: Function = noop;
  /** The control touched callback for Angular Forms */
  protected onTouched: Function = noop;
  /** The validator change callback for Angular Forms */
  protected onValidatorChange: Function = noop;
  /** The stateChanges Observable emits when the state of the build picker changes (value, disabled, required, etc). Required by MatFormFieldControl. */
  readonly stateChanges: Subject<void> = new Subject<void>();
  /** The combined form control validator for this input. */
  protected validator: ValidatorFn = Validators.nullValidator;

  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    protected focusMonitor: FocusMonitor,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

    // Replace the provider normally specified in a component's config. This is a workaround for a cyclic dependency error as explained here:
    // https://material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  /** Sets up the focus monitor on the focusable element. */
  ngAfterViewInit() {
    if (this.getFocusableElementRef()) {
      this.focusMonitor.monitor(this.getFocusableElementRef().nativeElement, true).subscribe(origin => {
        if (!this.disabled) {
          this.focused = !!origin;
          this.stateChanges.next();
        }
      });
    }
  }

  /** Updates the MatFormField when the disabled property changes. */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.stateChanges.next();
    }
  }

  /** Necessary to update the error state for MatFormFieldControl. */
  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  /** Stops listening to focus events and completes the state changes observable for MatFormFieldControl. */
  ngOnDestroy() {
    if (this.getFocusableElementRef()) {
      this.focusMonitor.stopMonitoring(this.getFocusableElementRef().nativeElement);
    }
    this.stateChanges.complete();
  }

  /** Returns whether two values are equal. Override to change how values are compared. Equality comparison (===) is used by default. */
  valuesAreEqual(valueA: V, valueB: V): boolean {
    return valueA === valueB;
  }

  /** Required by ControlValueAccessor */
  writeValue(value: V): void {
    this.value = value;
  }

  /** Required by ControlValueAccessor */
  registerOnChange(fn: Function): void {
    this.onChange = fn ?? noop;
  }

  /** Required by ControlValueAccessor */
  registerOnTouched(fn: Function): void {
    this.onTouched = fn ?? noop;
  }

  /** Required by ControlValueAccessor */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Required by Validator */
  validate(control: AbstractControl): ValidationErrors {
    return this.validator(control);
  }

  /** Required by Validator */
  registerOnValidatorChange(fn: Function) {
    this.onValidatorChange = fn ?? noop;
  }

  /** Required by MatFormFieldControl */
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void { }

  /** Returns the focusable element. */
  getFocusableElement<T = HTMLElement>(): T {
    return this.getFocusableElementRef().nativeElement as T;
  }

  /** Whether the build picker has no value. Required by MatFormFieldControl. */
  abstract get empty(): boolean;

  /** Required by MatFormFieldControl */
  abstract readonly controlType: string;

  /** Returns the default value for the placeholder. */
  abstract getDefaultPlaceholder(): string;

  /** Returns an ElementRef for the focusable element. This is used for managing the focus state and updating MatFormField with the focus state. */
  abstract getFocusableElementRef(): ElementRef<HTMLElement>;
}
