import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Notification } from '@portal-core/notifications/models/notification.model';

@CollectionStateSelectors({
  stateType: NotificationsState
})
@State<NotificationsState>({
  name: NotificationsState.source
})
@Injectable()
export class NotificationsState extends CollectionStateBase {
  static source = 'Notifications';
  getSource(): string {
    return NotificationsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsDataService extends CollectionDataServiceBase<Notification> {
  constructor(protected store: Store) {
    super(store, NotificationsState);
  }
}
