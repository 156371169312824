import { Pipe, PipeTransform } from '@angular/core';
import { Project } from '@portal-core/projects/models/project.model';
import { RoutingService } from '@portal-core/routing/services/routing.service';

/*
 * Returns the routerLink commands for a project.
 * Usage:
 *   project | mcProjectLink : 'dashboard'
*/
@Pipe({
  name: 'mcProjectLink'
})
export class ProjectLinkPipe implements PipeTransform {
  constructor(private routingService: RoutingService) { }

  transform(project: Project, subPage: 'dashboard' | 'builds' | 'checklists' | 'files' | 'commits' | 'reports' | 'translations', pathTail?: any[]): any[] {
    let commands: any[] = null;

    if (project) {
      const path = this.routingService.getProjectPath(project.LicenseId, project.Id, subPage);

      // If the path is undefined then use the default path
      if (typeof path === 'undefined') {
        commands = ['/', project.LicenseId, 'projects', project.Id];
        if (subPage) {
          commands.push(subPage);
        }
      } else {
        commands = path;
      }
    }

    if (pathTail) {
      commands = commands.concat(pathTail);
    }

    return commands;
  }
}
