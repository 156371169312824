import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-translation-package-deletion-form',
  templateUrl: './translation-package-deletion-form.component.html',
  styleUrls: ['./translation-package-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageDeletionFormComponent {
  @Input() translationPackage: TranslationPackage;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private errorService: ErrorService, private snackBar: MatSnackBar, private translationPackageService: TranslationPackagesService) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.translationPackageService.deletePackage$(this.translationPackage.Id).subscribe(() => {
      this.savingState.update(false);

      this.snackBar.open('The translation package has been deleted.', 'OK', { duration: 2500 });
      this.deleted.emit();
    }, error => {
      this.savingState.update(false, 'Unable to delete the translation package.', this.errorService.getErrorMessages(error));
    });
  }

}
