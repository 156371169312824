<div class="mc-avatar-tooltip-container" [matTooltip]="branchName" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !branchName">
  <a *ngIf="showAvatar && isLink() && !isImageDisabled()" class="mc-avatar-container" [routerLink]="routeCommands">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-branching"></mat-icon>
  </a>
  <div *ngIf="showAvatar && (!isLink() || isImageDisabled())" class="mc-avatar-container">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-branching"></mat-icon>
  </div>

  <a *ngIf="showName && isLink() && !isNameDisabled()" href="#" class="mc-avatar-name" [routerLink]="routeCommands">{{ branchName }}</a>

  <span *ngIf="showName && (!isLink() || isNameDisabled())" class="mc-avatar-name">{{ branchName }}</span>
</div>
