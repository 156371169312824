import { Pipe, PipeTransform } from '@angular/core';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcTaskBoardAsync'
})
export class TaskBoardAsyncPipe implements PipeTransform {
  constructor(private taskBoardsService: TaskBoardsService) { }

  transform(taskBoardId: number | null | undefined, allowApiRequest: boolean = false): Observable<TaskBoard | null> {
    if (!taskBoardId) {
      return of(null);
    }

    return this.taskBoardsService.getItemById$(taskBoardId, {
      allowApiRequest
    });
  }
}
