import { MarkSpec, NodeSpec, Schema } from 'prosemirror-model';

export class SchemaPlugin {
  marks: Dictionary<MarkSpec>;
  nodes: Dictionary<NodeSpec>;
  props: Dictionary;

  addMarks(marks: Dictionary<MarkSpec>) {
    Object.assign(marks, this.marks);
  }

  addNodes(nodes: Dictionary<NodeSpec>) {
    Object.assign(nodes, this.nodes);
  }

  addProps(schema: Schema) {
    Object.assign(schema, this.props);
  }

  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) { }
}
