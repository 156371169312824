import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { PhoneNumber } from '@portal-core/users/models/phone-number.model';
import { UserEdit } from '@portal-core/users/models/user-edit.model';
import { UserNotification } from '@portal-core/users/models/user-notification.model';
import { User } from '@portal-core/users/models/user.model';
import { UsersApiService } from '@portal-core/users/services/users-api.service';
import { UsersDataService } from '@portal-core/users/services/users-data.service';
import { UserAppRole } from '@portal-core/users/types/user-app-role.type';
import { find, forEach, isEmpty } from 'lodash';
import { Observable, forkJoin, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CollectionServiceBase<User> {
  constructor(
    private usersDataService: UsersDataService,
    protected dataService: DataService,
    private usersApiService: UsersApiService,
    private licenseUsersService: LicenseUsersService
  ) {
    super(usersDataService, dataService);
  }

  getUserByName$(userName: string): Observable<User> {
    return this.usersApiService.getUserByName$(userName);
  }

  updateUserSettings$(licenseUserId: number, user?: Partial<UserEdit>, avatarFile?: File): Observable<User> {
    return this.usersApiService.updateUserSettings$(user, avatarFile).pipe(
      tap(() => {
        this.usersApiService.getUserById$(user.Id).subscribe(newUser => this.licenseUsersService.updateItems$({ [licenseUserId]: { User: newUser } }));
      })
    );
  }

  disableGettingStartedSetting$(userId: string): Observable<any> {
    return this.usersApiService.disableGettingStartedSetting$(userId);
  }

  savePhoneNumbers$(userId: string, oldPhoneNumbers: PhoneNumber[], newPhoneNumbers: PhoneNumber[]): Observable<any> {
    const requests$ = [];
    forEach(newPhoneNumbers, phoneNumber => {
      const oldPhoneNumber: PhoneNumber = find(oldPhoneNumbers, { Label: phoneNumber.Label });
      if (!isEmpty(phoneNumber.Value) && oldPhoneNumber === undefined) {
        requests$.push(this.saveNewPhoneNumber$(userId, phoneNumber.Value, phoneNumber.Label));
      } else if (oldPhoneNumber && phoneNumber.Value !== oldPhoneNumber.Value) {
        requests$.push(this.updatePhoneNumber$(userId, oldPhoneNumber.Id, phoneNumber.Value, phoneNumber.Label));
      }
    });
    return requests$.length === 0 ? of(null) : forkJoin(requests$);
  }

  private saveNewPhoneNumber$(userId: string, phoneNumber: string, label: string): Observable<User> {
    return this.usersApiService.saveNewPhoneNumber$(userId, phoneNumber, label);
  }

  private updatePhoneNumber$(userId: string, phoneNumberId: number, phoneNumber: string, label: string): Observable<PhoneNumber> {
    return this.usersApiService.updatePhoneNumber$(userId, phoneNumberId, phoneNumber, label);
  }

  updateEmail$(licenseUser: LicenseUser, newEmail: string): Observable<any> {
    return this.usersApiService.updateEmail$(licenseUser.User.Email, newEmail).pipe(
      tap(() => {
        this.usersApiService.getUserById$(licenseUser.User.Id).subscribe(newUser => this.licenseUsersService.updateItems$({ [licenseUser.Id]: { User: newUser } }));
      })
    );
  }

  updateUserAvatar$(userId: string, licenseUserId: number, avatarImage: FormData): Observable<any> {
    return this.usersApiService.updateUserAvatar$(userId, avatarImage).pipe(
      tap(() => {
        this.usersApiService.getUserById$(userId).subscribe(newUser => this.licenseUsersService.updateItems$({ [licenseUserId]: { User: newUser } }));
      })
    );
  }

  deleteUserAvatar$(userId: string, licenseUserId: number): Observable<any> {
    return this.usersApiService.deleteUserAvatar$(userId).pipe(
      tap(() => {
        this.usersApiService.getUserById$(userId).subscribe(newUser => this.licenseUsersService.updateItems$({ [licenseUserId]: { User: newUser } }));
      })
    );
  }

  updateUserProjectsAndTeams$(userId: string, licenseId: number, projectIds: number[], teamIds: number[]): Observable<any> {
    return this.usersApiService.updateUserProjectsAndTeams$(userId, licenseId, projectIds, teamIds);
  }

  getSubscriptions$(): Observable<UserNotification[]> {
    return this.usersApiService.getSubscriptions$();
  }

  getWhatsNew$(): Observable<MadCloudResult<string>> {
    return this.usersApiService.getWhatsNew$();
  }

  updateLatestVersionViewed$(): Observable<any> {
    return this.usersApiService.updateLatestVersionViewed$();
  }

  getPrimaryPhoneNumber(user: User): string {
    if (Array.isArray(user.PhoneNumbers)) {
      const primaryPhone = user.PhoneNumbers.find(phone => phone.Label === 'primary');
      return primaryPhone ? primaryPhone.Value : undefined;
    }
  }

  getCellPhoneNumber(user: User): string {
    if (Array.isArray(user.PhoneNumbers)) {
      const cellPhone = user.PhoneNumbers.find(phone => phone.Label === 'cell');
      return cellPhone ? cellPhone.Value : undefined;
    }
  }

  getUsersPage$(filter: PageFilter): Observable<Page<User>> {
    return this.usersApiService.getUsersPage$(filter);
  }

  getUserRolesDisplayText(roles: UserAppRole[]): string {
    return roles?.map(role => this.getUserRoleTypeDisplayText(role)).join(', ');
  }

  getUserRoleTypeDisplayText(role: UserAppRole): string {
    switch (role) {
      case 'SystemAdmin':
        return 'System Admin';
      case 'SupportAdmin':
        return 'Support Admin';
      case 'QAAdmin':
        return 'QA Admin';
      case 'ServiceUser':
        return 'Service User';
      default:
        return 'Not set';
    }
  }

  updateUserRoles$(userIds: string[], newRoles: UserAppRole[]): Observable<MadCloudResult> {
    return this.usersApiService.updateUserRoles$(userIds, newRoles).pipe(
      tap(() => {
        this.updateItems$(userIds.map(id => ({
          Id: id,
          RoleNames: newRoles
        })));
      })
    );
  }

  hasSomeRoles(user: User, roles: UserAppRole[]): boolean {
    return user?.RoleNames?.some(role => roles.includes(role));
  }

  hasRole(user: User, role: UserAppRole): boolean {
    return user?.RoleNames?.includes(role);
  }

  resetAccessFailed$(userId: string): Observable<MadCloudResult> {
    return this.usersApiService.resetAccessFailed$(userId);
  }
}
