import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { Branch } from '@portal-core/branches/models/branch.model';
import { BranchesApiService } from '@portal-core/branches/services/branches-api.service';
import { BranchesDataService } from '@portal-core/branches/services/branches-data.service';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { map, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class BranchesService extends CollectionServiceBase<Branch> {
  constructor(
    private branchesDataService: BranchesDataService,
    private branchesApiService: BranchesApiService,
    protected dataService: DataService
  ) {
    super(branchesDataService, dataService);
  }

  protected fetchItemById$(branchId: string): Observable<Branch> {
    return this.branchesApiService.getBranchFromProjectId$(this.getProjectIdFromId(branchId), this.getBranchNameFromId(branchId)).pipe(
      map(branch => this.processBranch(branch))
    );
  }

  protected fetchItemsById$(itemIds: string[]): Observable<Branch[]> {
    // TODO: use a new bulk load endpoint and call processBranches
    // THEN: test
    // create-build-dialog
    // build-picker in site-settings-form
    return of(itemIds.map(itemId => this.createBranchFromId(itemId)));
  }

  getProjectBranchesPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Branch>> {
    return this.branchesApiService.getProjectBranchesPageByProjectId$(projectId, filter).pipe(
      tap(page => this.processBranches(page.Items))
    );
  }

  getBuildBranchesPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Branch>> {
    return this.branchesApiService.getBuildBranchesPageByProjectId$(projectId, filter).pipe(
      tap(page => this.processBranches(page.Items))
    );
  }

  getBranchFromProjectId$(projectId: number, branchName: string): Observable<Branch> {
    return this.getItemById$(this.buildBranchId(projectId, branchName));
  }

  getProjectBranches$(projectId: number): Observable<string[]> {
    return this.branchesApiService.getProjectBranches$(projectId);
  }

  getDefaultBranch$(projectId: number): Observable<string> {
    return this.branchesApiService.getDefaultBranch$(projectId);
  }

  buildBranchId(projectId: number, branchName: string): string {
    if (typeof projectId === 'number' && typeof branchName === 'string') {
      return `${projectId}-${branchName}`;
    }
  }

  getProjectIdFromId(branchId: string): number {
    if (typeof branchId === 'string') {
      const index = branchId.indexOf('-');
      if (index >= 0) {
        return parseInt(branchId.substring(0, index), 10);
      }
    }
  }

  getBranchNameFromId(branchId: string): string {
    if (typeof branchId === 'string') {
      const index = branchId.indexOf('-');
      if (index >= 0) {
        return branchId.substring(index + 1);
      }
    }
  }

  createBranchFromId(branchId: string): Branch {
    if (typeof branchId === 'string') {
      const index = branchId.indexOf('-');
      if (index >= 0) {
        return {
          Id: branchId,
          ProjectId: parseInt(branchId.substring(0, index), 0),
          Name: branchId.substring(index + 1)
        };
      }
    }
  }

  private processBranches(branches: Branch[]) {
    if (branches?.length > 0) {
      branches.forEach(branch => this.processBranch(branch));
    }
    return branches;
  }

  private processBranch(branch: Branch): Branch {
    if (!branch.Id) {
      branch.Id = this.buildBranchId(branch.ProjectId, branch.Name);
    }
    return branch;
  }
}
