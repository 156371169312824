/**
 * The Flare document white list for element attributes on paste.
 * Attribute names should be in lowercase.
 */
export const FlareElementAttributeWhitelist: Dictionary<string[] | '*'> = {
  // Allow these attributes on all tags
  '.*': [
    'alt',
    'controls',
    'colspan',
    'download',
    'href',
    'madcap:conditions',
    'name',
    'rowspan',
    'span',
    'src',
    'style',
    'target',
    'type'
  ],
  // Allow all attributes on MadCap tags
  'madcap:.+': '*',
  // Object tags are basically the MadCap multimedia tag so let them keep all their attributes just like MadCap tags
  'object': '*',
  // Allow all attributes on math tags. This should be improved when we more directly support math and equations in the LWE.
  'annotation|annotation-xml|maction|math|merror|mfrac|mi|mmultiscripts|mn|mo|mover|mpadded|mphantom|mprescripts|mroot|mrow|ms|mspace|msqrt|mstyle|msub|msubsup|msup|mtable|mtd|mtext|mtr|munder|munderover|semantics': '*'
};
