import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Project} from '@portal-core/projects/models/project.model';
import {ProjectsService} from '@portal-core/projects/services/projects.service';

@Pipe({
  name: 'mcProjectAsync'
})
export class ProjectAsyncPipe implements PipeTransform {
  constructor(private projectsService: ProjectsService) {}

  transform(projectId: number | null | undefined, allowApiRequest: boolean = false): Observable<Project | null> {
    if (!projectId) {
      return of(null);
    }

    return this.projectsService.getItemById$(projectId, {
      allowApiRequest
    });
  }
}
