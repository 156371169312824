import { Directive, ElementRef, Output, EventEmitter, HostListener, Input, HostBinding } from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';

/**
 * Adds click behavior to an element. Clicking, pressing enter, and pressing space all emit the mcClick event.
 * The element becomes focusable using tabindex and by default is given the aria role value 'button'.
 */
@Directive({
  selector: '[mcClick]'
})
export class ClickDirective {
  /** Whether or not the element is disabled. */
  @HostBinding('attr.aria-disabled')
  @Input() mcClickDisabled?: boolean;
  /** Tabindex for the elements. Needs to be a number so that the element can gain focus and be clicked. */
  @Input() tabindex?: number = 0;

  /** Emits when the element is clicked or enter/space is pressed while the element has focus. */
  @Output() mcClick: EventEmitter<void> = new EventEmitter<void>();

  /** Gives the host element a tabindex value when the button is not disabled. */
  @HostBinding('attr.tabindex')
  get _tabindex (): number {
    // Do not give the element a tabindex when the button is disabled
    // This keeps the button from being able to gain focus while disabled
    return this.mcClickDisabled ? undefined : this.tabindex;
  }

  /** Gives the host element an aria role value. Defaults to button. */
  @HostBinding('attr.role')
  @Input() role?: string = 'button';

  constructor(private elementRef: ElementRef) { }

  /** Handles the click event on the element. */
  @HostListener('click')
  onClick() {
    if (!this.mcClickDisabled) {
        this.mcClick.emit();
    }
  }

  /** Handles keyboard events to make enter/space click the element. */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this.elementRef.nativeElement.click();
    }
  }
}
