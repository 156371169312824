import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamsSelectListModule } from '@portal-core/teams/components/teams-select-list/teams-select-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { LicenseSsoFormComponent } from './license-sso-form.component';

@NgModule({
  declarations: [
    LicenseSsoFormComponent
  ],
  imports: [
    AutocompleteInputModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    FormControlErrorMessageModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    TeamsSelectListModule,
    TeamAvatarModule
  ],
  exports: [
    LicenseSsoFormComponent
  ]
})
export class LicenseSsoFormModule { }
