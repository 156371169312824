import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ReviewFileProfileDialogComponent, ReviewFileProfileDialogData } from '@portal-core/reviews/review-files/components/review-file-profile-dialog/review-file-profile-dialog.component';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, Subscription, combineLatest, map } from 'rxjs';

@Component({
  selector: 'mc-review-file-avatar',
  templateUrl: './review-file-avatar.component.html',
  styleUrls: ['./review-file-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileAvatarComponent extends AvatarBase implements OnInit {
  @Input() color?: 'invert' | 'default' | 'inherit' = 'inherit';
  @Input() nameLinksToPage?: boolean = false;
  @Input() reviewFile: ReviewFile;
  @Input() showId?: boolean = false;
  @Input() showPath?: boolean = false;
  @Input() showStatus?: boolean = true;

  @InputObservable('color') color$: Observable<'invert' | 'default'>;
  @InputObservable('nameLinksToPage') nameLinksToPage$: Observable<boolean>;

  ReviewFileStatus: typeof ReviewFileStatus = ReviewFileStatus;

  colorClass$: Observable<string>;

  private currentUserHasPermission: boolean = false;
  private isAuthorSubscription: Subscription;

  constructor(private dialog: MatDialog, private currentService: CurrentService) {
    super();
  }
  ngOnInit() {
    this.isAuthorSubscription = combineLatest([this.currentService.getCurrentLicenseUserIsAuthor$(), this.nameLinksToPage$]).pipe(
      map(([isAuthor, nameLinksToPage]) => isAuthor || (!isAuthor && nameLinksToPage))
    ).subscribe(hasPermission => {
      this.currentUserHasPermission = hasPermission || this.currentService.isSysAdmin();
    });

    this.colorClass$ = this.color$.pipe(
      map(color => {
        if (color === 'default') {
          return 'mc-text-avatar';
        } else if (color === 'invert') {
          return 'mc-text-avatar-invert';
        } else {
          return 'mc-text-avatar-inherit';
        }
      })
    );
  }

  onAvatarClicked() {
    this.openReviewFileProfileDialog();
  }

  hasAvatarIcon(): boolean {
    return true;
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return this.currentUserHasPermission;
  }

  isDeleted(): boolean {
    return this.reviewFile?.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.reviewFile;
  }

  private openReviewFileProfileDialog() {
    this.dialog.open<ReviewFileProfileDialogComponent, ReviewFileProfileDialogData>(ReviewFileProfileDialogComponent, {
      ...ReviewFileProfileDialogComponent.DialogConfig,
      data: {
        reviewFileId: this.reviewFile.Id
      }
    });
  }
}
