import { resolvedPosFind, resolvedPosFindNode } from '@common/prosemirror/model/resolved-pos';
import { getWordAt } from '@common/util/string';
import { Node, NodeRange, NodeType, ResolvedPos } from 'prosemirror-model';

export function getNearestAncestorRange($pos: ResolvedPos, nodeType: NodeType): NodeRange {
  const depthInfo = resolvedPosFind($pos, node => node.type === nodeType);

  if (depthInfo) {
    return new NodeRange($pos.doc.resolve($pos.start(depthInfo.depth)), $pos.doc.resolve($pos.end(depthInfo.depth)), depthInfo.depth);
  }
}

// Gets the range for the word at a given position in a text node
export function getWordRange($pos: ResolvedPos): NodeRange {
  const parent = $pos.parent;

  if (parent.inlineContent) {
    const childNode = parent.nodeAt($pos.parentOffset - 1 >= 0 ? $pos.parentOffset - 1 : 0);
    if (childNode && childNode.isText) {
      // prosemirror for whatever reason sets textOffset to 0 if you are at the end of a node, since we know we are at the 
      // end the size of the node is now the correct position. 
      const textOffset = $pos.textOffset === 0 && $pos.parentOffset !== 0 ? childNode.text.length : $pos.textOffset;
      const wordInfo = getWordAt(childNode.text, textOffset);

      if (wordInfo) {
        return new NodeRange($pos.doc.resolve($pos.pos - wordInfo.startDistance), $pos.doc.resolve($pos.pos + wordInfo.endDistance), $pos.depth);
      }
    }
  }
}

// Gets the text range between $pos1 and $pos2
// export function getTextRange($pos1: ResolvedPos, $pos2: ResolvedPos, predicate?: (node: Node) => boolean): NodeRange {
//   if ($pos2.pos < $pos1.pos) {
//     return getTextRange($pos2, $pos1, predicate);
//   }

//   if ($pos1.parent.inlineContent) {
//     for (let depth = $pos1.depth; depth >= 0; depth--) {
//       if ($pos2.pos <= $pos1.end(depth) && (!predicate || predicate($pos1.node(depth)))) {
//         return new NodeRange($pos1, $pos2, depth);
//       }
//     }
//   }
// }

// Returns the range from $pos1 to $pos2 if the positions share the same parent and the parent allows inline content
export function getInlineRange($pos1: ResolvedPos, $pos2: ResolvedPos): NodeRange {
  if ($pos2.pos < $pos1.pos) {
    return getInlineRange($pos2, $pos1);
  }

  if ($pos1.parent.inlineContent && $pos1.parent === $pos2.parent) {
    return new NodeRange($pos1, $pos2, $pos1.depth);
  }
}

// Gets the text ranges between $pos1 and $pos2. If the content between the two positions contains multiple block nodes then a text range is returned for each block node.
export function getTextRanges(from: number, to: number, doc: Node, nodeType?: NodeType): NodeRange[] {
  const ranges = [];

  doc.nodesBetween(from, to, (node: Node, pos: number, parent: Node) => {
    if (node.isText) {
      const $start = doc.resolve(Math.max(pos, from));
      const $end = doc.resolve(Math.min(pos + node.nodeSize, to));
      const depth = $start.depth;

      // If the range should be limited to a specific node type and the that node type is an ancestor of this node
      if (!nodeType || resolvedPosFindNode($start, node => node.type === nodeType)) {
        // START - The below is just for logging
        // let slice = doc.slice($start.pos, $end.pos);
        // if (slice.content.childCount === 1) {
        //     slice = slice.content.firstChild;

        //     if (slice.type.name === 'text') {
        //         slice = '"' + slice.text + '"';
        //     }
        // }

        // console.log('getTextRanges.isText', '<' + node.type.name + '>', '[' + $start.pos + ', ' + $end.pos + ', ' + depth + ']' + ' in <' + parent.type.name + '>', 'SLICE', slice);
        // END - just for logging


        ranges.push(new NodeRange($start, $end, depth));
      }
    }
  });

  // console.log('getTextRanges.ranges', ranges);

  return ranges;
}

export function getInlineNodeRange($pos: ResolvedPos): NodeRange {
  if ($pos.parent.inlineContent) {
    return new NodeRange($pos.doc.resolve($pos.start()), $pos.doc.resolve($pos.end()), $pos.depth);
  }
}

// function getInlineNodeRanges (from, to, nodeType, doc) {
//     var ranges = [];

//     // This returns a range for any text that is a direct child of a node that matches the inline node type
//     doc.nodesBetween(from, to, function (node, pos, parent) {
//         if (node.isText && parent.type === nodeType) {
//         // if (node.isInline && parent.type === nodeType) {
//             var $start = doc.resolve(Math.max(pos, from)),
//                 $end = doc.resolve(Math.min(pos + node.nodeSize, to)),
//                 depth =  $start.depth;

//             var slice = doc.slice($start.pos, $end.pos);
//             if (slice.content.childCount === 1) {
//                 slice = slice.content.firstChild;

//                 if (slice.type.name === 'text') {
//                     slice = '"' + slice.text + '"';
//                 }
//             }

//             console.log('getInlineNodeRanges.isInline', '<' + node.type.name + '>', '[' + $start.pos + ', ' + $end.pos + ', ' + depth + ']' + ' in <' + parent.type.name + '>', 'SLICE', slice);


//             ranges.push(new pm.model.NodeRange($start, $end, depth));
//         }
//     });




//     // if (nodeType.name === 'b') {
//     //     console.log('getInlineNodeRanges.b', 'from', from, 'to', to);
//     // }

//     // var $from = doc.resolve(from);
//     // var $to = doc.resolve(to);

//     // // This returns a range directly inside of an inline node that matches the type
//     // doc.nodesBetween(from, to, function (node, pos, parent) {
//     //     // If this is a node we want the range of
//     //     if (node.isInline && node.type === nodeType) {
//     //         // var $from = doc.resolve(from);
//     //         var $pos = doc.resolve(pos);

//     //         // console.log(pos, from, $from.pos, $from.sharedDepth(pos));
//     //         // If the node is the direct child of a block
//     //         // if (parent.isBlock) {
//     //         // If replacing all of the content of the inline node
//     //         if (Math.abs($from.pos - pos) === Math.abs($from.depth - $from.sharedDepth(pos)) &&
//     //             Math.abs($to.pos - pos + node.nodeSize) === Math.abs($to.depth - $to.sharedDepth(pos + node.nodeSize))) { // this might be working now
//     //             // 20 41 - 22 39 :: the difference is 2 (22 - 20) because the text starts 2 more positions down, 1 from the <b> and another from the <u>
//     //             // console.log('parent is block');
//     //             var $start = doc.resolve(pos + 1),
//     //                 $end = doc.resolve(pos + node.nodeSize - 1);
//     //         }
//     //         else {
//     //             // 20 41 - 29 34
//     //             // console.log('parent is not block');
//     //             var $start = doc.resolve(Math.max(pos + 1, from)),
//     //                 $end = doc.resolve(Math.min(pos + node.nodeSize - 1, to));

//     //             // var $start = doc.resolve(Math.max(pos, from)),
//     //             //     $end = doc.resolve(Math.min(pos + node.nodeSize, to));
//     //         }

//     //         var start;
//     //         if (from > pos) {
//     //             if ($from.textOffset === 0) {
//     //                 for (var depth = $from.depth; depth >= 0; depth--) {
//     //                     if (pos === $from.start(depth)) {
//     //                         start = $from.start(depth);
//     //                         break;
//     //                     }
//     //                 }
//     //             }
//     //             else {
//     //                 start = from;
//     //             }
//     //         }
//     //         else {
//     //             start = pos;
//     //         }

//     //         var end;
//     //         var endPos = pos + node.nodeSize;
//     //         if (to < endPos) {
//     //             for (var depth = $to.depth; depth >= 0; depth--) {
//     //                 if (endPos === $to.end(depth)) {
//     //                     end = $to.end(depth);
//     //                     break;
//     //                 }
//     //             }
//     //         }
//     //         else {
//     //             end = endPos;
//     //         }

//     //         console.log('getInlineNodeRanges', 'start', start, 'end', end, 'pos', pos, 'endPos', endPos);


//     //         var depth =  $start.depth;

//     //         // var $start = doc.resolve(Math.max(pos + 1, from)),
//     //         //     $end = doc.resolve(Math.min(pos + node.nodeSize - 1, to)),
//     //         //     depth =  $start.depth;

//     //         var slice = doc.slice($start.pos, $end.pos);
//     //         if (slice.content.childCount === 1) {
//     //             slice = slice.content.firstChild;

//     //             if (slice.type.name === 'text') {
//     //                 slice = '"' + slice.text + '"';
//     //             }
//     //         }

//     //         console.log('getInlineNodeRanges.isInline', '<' + node.type.name + '>', '[' + $start.pos + ', ' + $end.pos + ', ' + depth + ']' + ' in <' + parent.type.name + '>', 'SLICE', slice);


//     //         ranges.push(new pm.model.NodeRange($start, $end, depth));
//     //     }
//     // });



//     // This returns a range for text that has any ancestor that matches the inline node type
//     // nodesBetweenWithData(doc, from, to, function (node, isWithinInlineNode, pos) {
//     //     if (node.isText && isWithinInlineNode) {
//     //         var $start = doc.resolve(Math.max(pos, from)),
//     //             $end = doc.resolve(Math.min(pos + node.nodeSize, to)),
//     //             depth =  $start.depth;

//     //         ranges.push(new pm.model.NodeRange($start, $end, depth));
//     //     }

//     //     if (node.isInline && node.type === nodeType) {
//     //         isWithinInlineNode = true;
//     //     }

//     //     return isWithinInlineNode;
//     // });

//     console.log('getInlineNodeRanges.ranges', ranges);

//     return ranges;
// }
