import { DOMSerializer } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';

const clipboardSerializerKey = new PluginKey('clipboardSerializer');

export interface ClipboardSerializerPluginOptions {
  clipboardSerializer?: DOMSerializer;
}

/**
 * A ProseMirror plugin for specifying a clipboard serializer for the editor to use.
 */
export function clipboardSerializerPlugin(options: ClipboardSerializerPluginOptions): Plugin {
  return new Plugin({
    key: clipboardSerializerKey,

    props: {
      clipboardSerializer: options.clipboardSerializer
    }
  });
}
