import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectProfile } from '@portal-core/profiles/models/project-profile.model';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { ProjectInfo } from '@portal-core/project-info/models/project-info.model';
import { ProjectAccessFormComponent } from '@portal-core/projects/components/project-access-form/project-access-form.component';
import { ProjectSettingsFormComponent } from '@portal-core/projects/components/project-settings-form/project-settings-form.component';
import { ProjectProfileTab } from '@portal-core/projects/enums/project-profile-tab.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, map, Observable } from 'rxjs';

export enum ProjectProfileForm {
  Settings,
  Access,
  Activity,
  Delete
}

@Component({
  selector: 'mc-project-profile',
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MC_FOCUSABLE, useExisting: ProjectProfileComponent }
  ]
})
export class ProjectProfileComponent extends ProfileBase<ProjectProfileTab> implements OnInit {
  @Input() projectProfile: ProjectProfile;
  @Input() showAccess: boolean = true;
  @Input() showActivity: boolean = true;
  @Input() showDelete: boolean = true;
  @Input() showOverview: boolean = true;
  @Input() showSettings: boolean = true;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<ProjectProfileForm> = new EventEmitter<ProjectProfileForm>();

  @InputObservable('projectProfile') projectProfile$: Observable<ProjectProfile>;
  @InputObservable('showAccess') showAccess$: Observable<boolean>;
  @InputObservable('showActivity') showActivity$: Observable<boolean>;
  @InputObservable('showDelete') showDelete$: Observable<boolean>;
  @InputObservable('showOverview') showOverview$: Observable<boolean>;
  @InputObservable('showSettings') showSettings$: Observable<boolean>;

  @ViewChild(ProjectSettingsFormComponent) projectSettingsForm: ProjectSettingsFormComponent;
  @ViewChild(ProjectAccessFormComponent) projectAccessForm: ProjectAccessFormComponent;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case ProjectProfileTab.Settings:
        return this.projectSettingsForm?.dirty;
      case ProjectProfileTab.Access:
        return this.projectAccessForm?.dirty;
      default:
        return false;
    }
  }

  ProjectProfileForm: typeof ProjectProfileForm = ProjectProfileForm;
  ProjectProfileTab: typeof ProjectProfileTab = ProjectProfileTab;

  project$: Observable<Project>;
  projectInfo$: Observable<ProjectInfo>;

  showAccessTab$: Observable<boolean>;
  showActivityTab$: Observable<boolean>;
  showDeleteTab$: Observable<boolean>;
  showOverviewTab$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;

  // Permissions
  userCanManageProjects$: Observable<boolean>;
  userCanDeleteProjects$: Observable<boolean>;

  constructor(
    dialog: MatDialog,
    cdr: ChangeDetectorRef,
    private currentService: CurrentService,
    private permissionsService: PermissionsService
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    // Create an observable for whether the current user is an author
    const currentUserIsAuthor$ = this.currentService.getCurrentLicenseUserIsAuthor$();

    // Create permission observables
    this.userCanManageProjects$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);
    this.userCanDeleteProjects$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.DeleteProjects);

    this.project$ = this.projectProfile$.pipe(
      map(projectProfile => projectProfile ? projectProfile.Project : null)
    );

    this.projectInfo$ = this.projectProfile$.pipe(
      map(projectProfile => projectProfile ? projectProfile.ProjectInfo : null)
    );

    this.showAccessTab$ = this.showAccess$;
    this.showActivityTab$ = combineLatest([this.showActivity$, currentUserIsAuthor$]).pipe(
      map(([showActivity, currentUserIsAuthor]) => showActivity && currentUserIsAuthor)
    );
    this.showDeleteTab$ = combineLatest([this.showDelete$, this.userCanDeleteProjects$]).pipe(
      map(([showDelete, userCanDeleteProjects]) => showDelete && userCanDeleteProjects)
    );
    this.showOverviewTab$ = this.showOverview$;
    this.showSettingsTab$ = combineLatest([this.showSettings$, this.userCanManageProjects$]).pipe(
      map(([showSettings, userCanManageProjects]) => showSettings && userCanManageProjects)
    );
  }

  onSettingsSaved() {
    this.saved.emit(ProjectProfileForm.Settings);
  }

  onProjectAccessSaved() {
    this.saved.emit(ProjectProfileForm.Access);
  }

  onProjectDeleted() {
    this.saved.emit(ProjectProfileForm.Delete);
  }

  onCancel() {
    this.cancel.emit();
  }
}
