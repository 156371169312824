import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mcProjectFilesTreeItem]'
})
export class ProjectFilesTreeItemDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('mcProjectFilesTreeItem') templateName: 'file' | 'folder';

  constructor(public templateRef: TemplateRef<any>) { }
}
