import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseBulkAddUserResponse } from '@portal-core/license-users/models/license-bulk-add-user-response.model';
import { LicenseUserClientSideInfo } from '@portal-core/license-users/models/license-user-client-side-info.model';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersApiService } from '@portal-core/license-users/services/license-users-api.service';
import { LicenseUsersDataService } from '@portal-core/license-users/services/license-users-data.service';
import { ProcessReport } from '@portal-core/processes/models/process-report.model';
import { BulkUploadUserRequest } from '@portal-core/users/models/bulk-upload-user-request.model';
import { UserOnlineStatus } from '@portal-core/users/models/user-online-status.model';
import { User } from '@portal-core/users/models/user.model';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { first, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class LicenseUsersService extends CollectionServiceBase<LicenseUser> {
  constructor(
    private licenseUsersDataService: LicenseUsersDataService,
    private licenseUsersApiService: LicenseUsersApiService,
    private licenseStorageService: LicenseStorageService,
    protected dataService: DataService) {
    super(licenseUsersDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<LicenseUser> {
    return this.licenseUsersApiService.getLicenseUserById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<LicenseUser[]> {
    return this.licenseUsersApiService.getLicenseUsersById$(itemIds);
  }

  getLicenseUsersPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<LicenseUser>> {
    return this.licenseUsersApiService.getLicenseUsersPageByLicenseId$(licenseId, filter);
  }

  getAllLicenseUsersByLicenseId$(licenseId: number, getViewers: boolean, options?: GetDataOptions): Observable<LicenseUser[]> {
    return this.dataService.getDataListItems$<LicenseUser>('Licenses', licenseId, this.licenseUsersDataService, {
      fetch: () => this.licenseUsersApiService.getAllLicenseUsersByLicenseId$(licenseId, getViewers).pipe(
        map(licenseUsers => {
          if (Array.isArray(licenseUsers)) {
            return licenseUsers.sort((licenseUserA, licenseUserB) => licenseUserA.User.FullName.localeCompare(licenseUserB.User.FullName));
          }
        })
      )
    }, options);
  }

  getLicenseUsersAdministrations$(licenseId: number, options: GetDataOptions = null): Observable<LicenseUser[]> {
    return this.dataService.getDataListItems$<LicenseUser>('Admins', licenseId, this.licenseUsersDataService, {
      fetch: () => this.licenseUsersApiService.getLicenseUsersAdministrations$(licenseId).pipe(
        // After getting the administrations getting the related license users
        switchMap(users => this.licenseUsersApiService.getLicenseUsersByUserIds$(licenseId, users.map(user => user.Id)))
      )
    }, options);
  }

  getListOfLicenseUsersAdministrations$(licenseId: number): Observable<User[]> {
    return this.licenseUsersApiService.getLicenseUsersAdministrations$(licenseId);
  }

  getLicenseUserByUserId(userId: string, licenseId: number): LicenseUser {
    return this.licenseUsersDataService.getItemByProperties('User.Id', userId, 'LicenseId', licenseId);
  }

  getLicenseUserByUserId$(userId: string, licenseId: number, options: GetDataOptions = null): Observable<LicenseUser> {
    return this.dataService.getData<LicenseUser>({
      get: () => this.licenseUsersDataService.getItemByProperties$('User.Id', userId, 'LicenseId', licenseId),
      fetch: () => this.licenseUsersApiService.getLicenseUserByUserId$(userId, licenseId),
      set: newLicenseUser => {
        return this.licenseUsersDataService.addItems$({ [newLicenseUser.Id]: newLicenseUser });
      }
    }, options);
  }

  getLicenseUsersByUserIds$(licenseId: number, userIds: string[], options: GetDataOptions = null): Observable<LicenseUser[]> {
    return this.licenseUsersApiService.getLicenseUsersByUserIds$(licenseId, userIds);
  }

  getBulkImportProcessReport$(processId: number): Observable<ProcessReport> {
    return this.licenseUsersApiService.getBulkImportProcessReport$(processId);
  }

  removeUserFromLicense$(licenseId: number, userId: string): Observable<any> {
    return this.licenseUsersApiService.removeUserFromLicense$(licenseId, userId).pipe(
      tap(() => this.updateLicenseStorageData(licenseId))
    );
  }

  loadLicenseUsersByUserId$(licenseId: number, userIds: string[]): Observable<any> {
    return this.bulkLoadItems$(
      () => {
        const userIdsToFetch = userIds.filter(userId => {
          const licenseUser = this.getItemByProperties('LicenseId', licenseId, 'User.Id', userId);

          // If the licenseUser has expired OR there is no licenseUser then fetch this license user
          return licenseUser ? this.dataService.cacheHasExpired(licenseUser, null, { maxAgeMS: DataService.DefaultDataMaxAgeMS }) : true;
        });

        if (userIdsToFetch.length > 0) {
          return this.licenseUsersApiService.getLicenseUsersByUserIds$(licenseId, userIdsToFetch);
        }
      }
    );
  }

  updateLicenseUser$(licenseId: number, userId: string, isActive: boolean, updateLicenseModel = null): Observable<any> {
    return this.licenseUsersApiService.updateLicenseUser$(licenseId, userId, isActive, updateLicenseModel).pipe(
      tap(() => this.updateLicenseStorageData(licenseId))
    );
  }

  reinviteUser$(licenseId: number, userId: string): Observable<any> {
    return this.licenseUsersApiService.reinviteUser$(licenseId, userId);
  }

  addUsersToLicense$(licenseId: number, seatType: LicenseUserSeatType, bulkUploadUserRequest: BulkUploadUserRequest, siteId?: number): Observable<LicenseBulkAddUserResponse> {
    return this.licenseUsersApiService.addUsersToLicense$(licenseId, seatType, bulkUploadUserRequest, siteId);
  }

  bulkDeleteLicenseUsers$(licenseId: number, licenseUserIds: number[]): Observable<any> {
    return this.licenseUsersApiService.bulkDeleteLicenseUsers$(licenseId, licenseUserIds).pipe(
      tap(() => {
        this.licenseUsersDataService.removeItems$(licenseUserIds);
        this.updateLicenseStorageData(licenseId);
      }),
    );
  }

  bulkSetLicenseUsersSeatType$(licenseId: number, seatType: LicenseUserSeatType, licenseUserIds: number[]): Observable<any> {
    return this.licenseUsersApiService.bulkSetLicenseUsersSeatType$(licenseId, seatType, licenseUserIds).pipe(
      tap(() => {
        this.licenseUsersDataService.updateItems$(licenseUserIds.map(id => ({
          Id: id,
          SeatType: seatType
        })));
        this.updateLicenseStorageData(licenseId);
      })
    );
  }

  bulkSetLicenseUsersStatus$(licenseId: number, status: LicenseUserStatus, licenseUserIds: number[]): Observable<any> {
    return this.licenseUsersApiService.bulkSetLicenseUsersStatus$(licenseId, status, licenseUserIds).pipe(
      tap(() => {
        this.licenseUsersDataService.updateItems$(licenseUserIds.map(id => ({
          Id: id,
          Status: status
        })));
        this.updateLicenseStorageData(licenseId);
      })
    );
  }

  setUserOnlineStatus(users: UserOnlineStatus[] = null) {
    if (Array.isArray(users)) {
      const licenseUsers: LicenseUser[] = [];

      users.forEach(user => {
        this.getItemsByProperties('User.Id', user.UserId).forEach(licenseUser => {
          licenseUsers.push({
            Id: licenseUser.Id,
            User: Object.assign({}, licenseUser.User, {
              OnlineStatus: user.OnlineStatus,
              OnlineStatusDisplay: user.OnlineStatusDisplay
            })
          });
        });
      });

      if (licenseUsers.length > 0) {
        this.updateItems$(licenseUsers).subscribe();
      }
    }
  }

  setLicenseUserHasNewNotifications(licenseUserIds: number[], hasNewNotifications: boolean) {
    this.updateItems$(licenseUserIds.map(licenseUserId => {
      return {
        Id: licenseUserId,
        HasNewNotifications: hasNewNotifications
      };
    }));
  }

  setLicenseUserHasNewNotificationsByUserIdAndLicenseId(userId: string, licenseId: number, hasNewNotifications: boolean) {
    let licenseUsers: LicenseUser[] = [];
    if (licenseId) {
      licenseUsers = this.getItemsByProperties('User.Id', userId, 'LicenseId', licenseId);
    } else {
      licenseUsers = this.getItemsByProperties('User.Id', userId);
    }
    this.setLicenseUserHasNewNotifications(licenseUsers.map(licenseUser => licenseUser.Id), hasNewNotifications);
  }

  setLicenseUserHasNewMessages(licenseUserId: number, hasNewMessages: boolean) {
    this.updateItems$({ [licenseUserId]: { HasNewMessages: hasNewMessages } });
  }

  getClientSideSettings$(licenseId: number, key: LicenseUserClientSideInfoKey): Observable<MadCloudResult<LicenseUserClientSideInfo>> {
    return this.licenseUsersApiService.getClientSideSettings$(licenseId, key);
  }

  saveClientSideSettings$(licenseId: number, clientSideInfoKey: LicenseUserClientSideInfoKey, settings: any): Observable<MadCloudResult> {
    return this.licenseUsersApiService.saveClientSideSettings$(licenseId, clientSideInfoKey, settings);
  }

  getLicenseUserSeatTypeDisplayText(licenseUserSeatType: LicenseUserSeatType): string {
    switch (licenseUserSeatType) {
      case LicenseUserSeatType.Author:
        return 'Author';
      case LicenseUserSeatType.SME:
        return 'Subject Matter Expert';
      case LicenseUserSeatType.Viewer:
        return 'Viewer';
    }
  }

  getLicenseUserSeatTypeShortDisplayText(licenseUserSeatType: LicenseUserSeatType): string {
    switch (licenseUserSeatType) {
      case LicenseUserSeatType.Author:
        return 'Author';
      case LicenseUserSeatType.SME:
        return 'SME';
      case LicenseUserSeatType.Viewer:
        return 'Viewer';
    }
  }

  getLicenseUserSeatTypeArticleDisplayText(licenseUserSeatType: LicenseUserSeatType): string {
    switch (licenseUserSeatType) {
      case LicenseUserSeatType.Author:
        return 'an';
      case LicenseUserSeatType.SME:
      case LicenseUserSeatType.Viewer:
        return 'a';
    }
  }

  private updateLicenseStorageData(licenseId: number) {
    this.licenseStorageService.getItemById$(licenseId, { forceApiRequest: true }).pipe(
      first(licenseStorage => !!licenseStorage)
    ).subscribe();
  }

  buildSysAdminLicenseUser$(sysAdminUser: User, licenseId: number): Observable<LicenseUser> {
    // Creating a faux license user for the sysAdmin
    return of({
      Id: licenseId,
      LicenseId: licenseId,
      SeatType: LicenseUserSeatType.Author,
      User: sysAdminUser
    });
  }
}
