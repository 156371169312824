import { Observable, MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';

/**
 * Emits a value only if the value is equivalently different than the previous value.
 * The comparison is made using Lodash's isEqual function.
 * @return {Observable<T>} An observable that emits values from the source Observable with equivalently distinct values.
 */
export function distinctEquivalenceUntilChanged<T>(): MonoTypeOperatorFunction<T> {
  return (source$: Observable<any>) => source$.pipe(
    distinctUntilChanged((x, y) => isEqual(x, y))
  );
}
