import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { DividerComponent } from './divider.component';

@NgModule({
  declarations: [
    DividerComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule
  ],
  exports: [
    DividerComponent
  ]
})
export class DividerModule { }
