import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProcessBuildType } from '@portal-core/builds/enums/process-build-type.enum';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { FileService } from '@portal-core/general/services/file.service';
import { Subject, filter, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildsUIService {
  private refreshBuildsDataSource = new Subject<void>();
  private refreshBuildsScheduleDataSource = new Subject<void>();

  refreshBuildsData$ = this.refreshBuildsDataSource.asObservable();
  refreshBuildsScheduleData$ = this.refreshBuildsScheduleDataSource.asObservable();

  constructor(
    private buildsService: BuildsService,
    private errorService: ErrorService,
    private fileService: FileService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { }

  refreshBuilds() {
    this.refreshBuildsDataSource.next();
  }
  refreshBuildsSchedules() {
    this.refreshBuildsScheduleDataSource.next();
  }

  viewOutput(build: Build) {
    if (build.CanViewOutput && build.PrivateOutputUrl) {
      window.open(build.PrivateOutputUrl, '_blank');
      this.buildsService.setUserViewedBuild$(build.Id).subscribe({
        error: error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
          duration: 2500
        })
      });
    }
  }

  downloadBuild(build: Build) {
    if (build.CanDownload) {
      this.buildsService.getDownloadBuildSasToken$(build.Id).subscribe(
        blob => {
          this.fileService.downloadZip(blob, this.buildsService.getZipNameForBuild(build));
        },
        error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
          duration: 2500
        })
      );
    }
  }

  setBuildKeep(build: Build) {
    const prompt = build.IsKept ?
      'Are you sure you want to remove \"Keep\" setting on build?' :
      'Are you sure you want to set build to \"Keep\"?';
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        prompt,
        action: 'OK'
      }
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      switchMap(() => this.buildsService.setBuildAsKeep$(build.Id, !build.IsKept))
    ).subscribe(
      ok => this.refreshBuilds(),
      error => {
        this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
          duration: 2500
        });
      }
    );
  }

  cancelBuild(build: Build) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        prompt: 'Are you sure you want to cancel?',
        action: 'OK'
      }
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      switchMap(() => this.buildsService.processBuild$(build.Id, ProcessBuildType.Cancel))
    ).subscribe(
      ok => this.refreshBuilds(),
      error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
        duration: 2500
      })
    );
  }

  rebuildTarget(build: Build) {
    this.buildsService.buildByTargetPathAndBranchName$(build.ProjectId, build.TargetPath, build.BranchName, build.BuildLanguageBranches).subscribe(
      ok => {
        if (ok && ok.ExistsOtherBuildsInProgress) {
          this.snackBar.open('The selected build is already in progress', 'OK', {
            duration: 2500
          });
        }
        this.refreshBuilds();
      },
      error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
        duration: 2500
      })
    );
  }
}
