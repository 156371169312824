import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { isRtl } from '@common/html/util/dom';
import { getWordRange } from '@common/prosemirror/model/range';
import { resolvedPosForEachNode } from '@common/prosemirror/model/resolved-pos';
import { PmEditorComponent } from '@portal-core/text-editor/components/pm-editor/pm-editor.component';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { ProseMirrorNode, ResolvedPos, Schema } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, Selection, TextSelection } from 'prosemirror-state';
import { DirectEditorProps, EditorView, NodeViewConstructor } from 'prosemirror-view';
import { Observable, map } from 'rxjs';

export class EditorContextMenuEvent {
  constructor(public event: MouseEvent) { }

  get defaultPrevented(): boolean {
    return this.event.defaultPrevented;
  }

  preventDefault() {
    this.event.preventDefault();
  }
}

@Component({
  selector: 'mc-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorComponent implements OnInit {
  @ViewChild(PmEditorComponent, { static: true }) pmEditor: PmEditorComponent;
  @Input() nodeViews: Dictionary<NodeViewConstructor>;
  @ViewChild('overlayContainer', { static: true }) overlayContainerElementRef: ElementRef<HTMLElement>;
  @Input() editorProps?: Partial<DirectEditorProps>;
  @Input() language?: string = 'en-us';
  @Input() plugins: Plugin[];
  @Input() readonly: boolean;
  @Input() schema: Schema;

  @InputObservable('language') language$: Observable<string>;

  @Output() change: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() editorContextMenu: EventEmitter<EditorContextMenuEvent> = new EventEmitter<EditorContextMenuEvent>();
  @Output() editorStateChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() ready: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() selectionChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();

  dir$: Observable<string>;

  get editorState(): EditorState {
    return this.pmEditor ? this.pmEditor.editorState : null;
  }

  get editorView(): EditorView {
    return this.pmEditor ? this.pmEditor.editorView : null;
  }

  get viewPluginOverlay(): HTMLElement {
    return this.overlayContainerElementRef?.nativeElement;
  }

  ngOnInit() {
    this.dir$ = this.language$.pipe(
      map(language => isRtl(language) ? 'rtl' : null)
    );
  }

  markAsPristine() {
    this.pmEditor.markAsPristine();
  }

  resetState() {
    this.pmEditor?.resetState();
  }

  createSelection(node: ProseMirrorNode, depth: number, $pos: ResolvedPos): Selection {
    // If this node has inline content then create a text selection
    if (node.inlineContent) {
      const range = getWordRange($pos);

      // If a word was found then select it
      if (range) {
        return TextSelection.between(range.$from, range.$to);
      } else { // Else select all the text in the node
        const nodePos = $pos.before(depth);
        return TextSelection.create(this.editorState.doc, nodePos + 1, nodePos + 1 + node.content.size);
      }
    }

    // If the node does not have inline content but is selectable then select the node
    if (NodeSelection.isSelectable(node)) {
      return NodeSelection.create(this.editorState.doc, $pos.before(depth));
    }
  }

  onContextMenu(event: MouseEvent) {
    // Do not show the editor menu if the alt key is being pressed
    if (!event.altKey) {
      const clickPos = this.editorView.posAtCoords({ left: event.clientX, top: event.clientY });

      if (clickPos && clickPos.inside !== -1) {
        let currentSelection = this.editorState.selection;

        // If the click was outside the current selection then create a new selection where the click was
        if (clickPos.pos < currentSelection.from || clickPos.pos > currentSelection.to) {
          const clickedNode = this.editorState.doc.nodeAt(clickPos.inside);
          const $pos = this.editorState.doc.resolve(clickPos.pos);
          let newSelection: Selection;

          // Try to select the nodeAfter the click position first
          if ($pos.nodeAfter) {
            newSelection = this.createSelection($pos.nodeAfter, $pos.depth + 1, $pos);
          } else if (clickedNode) { // If the nodeAfter does not exist then try the clicked node
            newSelection = NodeSelection.create(this.editorState.doc, clickPos.inside);
          }

          // If no selection has been made yet then try to make a selection using the nodes in the resolved position
          if (!newSelection) {
            resolvedPosForEachNode($pos, (node, depth) => {
              newSelection = this.createSelection(node, depth, $pos);
              if (newSelection) {
                return false;
              }
            });
          }

          // If there is still no selection then just make a simple empty cursor selection at the position
          if (!newSelection) {
            newSelection = TextSelection.create(this.editorState.doc, clickPos.pos);
          }

          // Set the new selection in the doc
          const tr = this.editorState.tr;
          tr.setSelection(newSelection);
          tr.setMeta('pointer', true);
          this.editorView.dispatch(tr);

          currentSelection = this.editorState.selection;
        }

        this.editorContextMenu.emit(new EditorContextMenuEvent(event));
      }
    }
  }
}
