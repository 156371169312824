import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class BuildLogPageFilterConfig extends PageFilterConfigBase {
  static readonly Columns: GridColumn[] = [
    {
      name: 'ErrorId',
      title: 'ID',
      type: PageDataType.Int,
      filterEnabled: true,
      sortEnabled: true
    },
    {
      name: 'Message',
      title: 'Message',
      type: PageDataType.String
    },
    {
      name: 'Subsystem',
      title: 'Subsystem',
      type: PageDataType.String,
      filterEnabled: true,
      sortEnabled: true
    },
    {
      name: 'File',
      title: 'File',
      type: PageDataType.String,
      filterEnabled: true,
      sortEnabled: true
    }
  ];

  constructor(enabledColumnNames?: string[]) {
    super(enabledColumnNames, BuildLogPageFilterConfig.Columns);
  }
}
