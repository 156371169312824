import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { DoughnutChartModule } from '@portal-core/ui/charts/components/doughnut-chart/doughnut-chart.module';
import { DoughnutChartHoleModule } from '@portal-core/ui/charts/directives/doughnut-chart-hole/doughnut-chart-hole.module';
import { StorageUsageBarModule } from '@portal-core/license-storage/components/storage-usage-bar/storage-usage-bar.module';
import { UserUsageBarModule } from '@portal-core/license-storage/components/user-usage-bar/user-usage-bar.module';
import { LicenseOverviewComponent } from './license-overview.component';

@NgModule({
  declarations: [
    LicenseOverviewComponent
  ],
  imports: [
    CommonModule,
    DoughnutChartModule,
    DoughnutChartHoleModule,
    FileSizeModule,
    StorageUsageBarModule,
    UserUsageBarModule
  ],
  exports: [
    LicenseOverviewComponent
  ]
})
export class LicenseOverviewModule { }
