import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Color } from '@portal-core/ui/color-picker/models/color.model';

@Component({
  selector: 'ngx-mat-color-palette',
  templateUrl: 'color-palette.component.html',
  styleUrls: ['color-palette.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'ngx-mat-color-palette'
  }
})
export class NgxMatColorPaletteComponent {
  @Input() color: Color;
  @Input() showRGBA: boolean = false;

  @Output() colorChanged: EventEmitter<Color> = new EventEmitter<Color>();

  public handleColorChanged(color: Color) {
    this.colorChanged.emit(color);
  }
}
