import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class TeamPageFilterConfig extends PageFilterConfigBase {
  static readonly Columns: GridColumn[] = [
    {
      name: 'Name',
      title: 'Name',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'CreatedBy.FullName',
      title: 'Created By',
      type: PageDataType.String
    },
    {
      name: 'TeamProjects',
      title: 'Projects',
      type: PageDataType.String
    },
    {
      name: 'TeamUsers',
      title: 'Users',
      type: PageDataType.String
    },
    {
      name: 'TeamSites',
      title: 'Sites',
      type: PageDataType.String
    },
    {
      name: 'Description',
      title: 'Description',
      type: PageDataType.String
    }
  ];

  constructor(enabledColumnNames?: string[]) {
    super(enabledColumnNames, TeamPageFilterConfig.Columns);
  }
}
