import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';

@Component({
  selector: 'mc-review-file-reviewers-form',
  templateUrl: './review-file-reviewers-form.component.html',
  styleUrls: ['./review-file-reviewers-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileReviewersFormComponent {
  @Input() reviewFile: ReviewFile;
}
