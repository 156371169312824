import { Injectable } from '@angular/core';
import { array } from '@common/util/array';
import { isAbsoluteUrl, resolvePath } from '@common/util/path';
import { ProjectStylesheetType } from '@portal-core/project-files/enums/project-stylesheet-type.enum';
import { CssSyntaxService } from '@portal-core/project-files/services/css-syntax.service';
import { AnyNode, ChildNode, Declaration, Root, Rule } from 'postcss';

/** Regular expression to match Flare css meta comment in a stylesheet content */
const cssMetaRegExp = /\/\*\s*<meta(\s*|(\s+([^/*][^*]*)*))\/>\s*\*+\//m;
/** Regular expression to match Flare branding comment in a stylesheet content */
const brandingRegExp = /\/\*\s*MadCap-Branding[^*]*\*+([^/*][^*]*\*+)*\//m;
/** Regular expression to match Flare table comment in a stylesheet content */
const tableRegExp = /\/\*\s*MadCap\s+Table\s+Style[^*]*\*+([^/*][^*]*\*+)*\//m;

/** Provides methods to work with stylesheets. */
@Injectable({
  providedIn: 'root'
})
export class ProjectStylesheetService {

  constructor(private cssSyntaxService: CssSyntaxService) { }

  /** Validate the given stylesheet content.
   * @param content: The stylesheet content to validate.
   * @returns An Error if the validation fail, otherwise null.
  */
  validate(content: string): Error {
    let error = null;
    try {
      content = this.cssSyntaxService.updateInlineComments(content);
      error = this.cssSyntaxService.validateCssSyntax(content);
    }
    catch (err) {
      error = err;
    }
    if (error) {
      // handle PostCss error
      if (error.name === 'CssSyntaxError') {
        return this.createSyntaxError(error['reason'], error['line'], error['column']);
      }
      // handle CssTree error
      if (error.name === 'SyntaxError') {
        return this.createSyntaxError(error.message, error['line'], error['column']);
      }
    }
    return error;
  }

  /** Gets the type of the stylesheet.
   * @param content: The stylesheet content.
   * @returns The stylesheet type.
   */
  stylesheetType(content: string): ProjectStylesheetType {
    const brandingDeclaration = this.brandingDeclaration(content);
    if (brandingDeclaration) {
      return ProjectStylesheetType.Branding;
    }
    const tableDeclaration = this.tableDeclaration(content);
    if (tableDeclaration) {
      return ProjectStylesheetType.Table;
    }
    return ProjectStylesheetType.General;
  }

  /** Gets the Flare css meta comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare css meta comment if found, otherwise null.
  */
  cssMetaDeclaration(content: string): string | null {
    return cssMetaRegExp.exec(content)?.[0] ?? null;
  }

  /** Gets the Flare branding comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare branding comment if found, otherwise null.
  */
  brandingDeclaration(content: string): string | null {
    return brandingRegExp.exec(content)?.[0] ?? null;
  }

  /** Gets the Flare table comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare table comment if found, otherwise null.
  */
  tableDeclaration(content: string): string | null {
    return tableRegExp.exec(content)?.[0] ?? null;
  }

  /** Returns a root node that represents the parsed stylesheet content. */
  loadCss(content: string): Root {
    return this.cssSyntaxService.parse(content);
  }

  /** Generates stylesheet text for the specified root node. */
  cssText(root: Root): string {
    return this.cssSyntaxService.generate(root, 'full', true);
  }

  /** Generates inner text for the specified css node.
   * @param cssNode The css node.
   * @returns Inner text generated for the specified css node.
   */
  cssInnerText(cssNode: AnyNode): string {
    return this.cssSyntaxService.generate(cssNode, 'inner');
  }

  /** Gets a value of the specified declaration.
   * @param rule The rule containing the specified declaration.
   * @param name The declaration name.
   * @returns A value of the specified declaration if found, otherwise undefined.
   */
  getDeclarationValue(rule: Rule, name: string): string | null {
    const declaration = this.getFirstDeclaration(rule, name);
    return declaration?.value;
  }

  /** Sets a value of the specified declaration.
   * @param rule: The rule containing the specified declaration.
   * @param name The declaration name.
   * @param value The declaration value.
   */
  setDeclarationValue(rule: Rule, name: string, value: string) {
    let declaration = this.getFirstDeclaration(rule, name);
    if (!declaration) {
      const lastNode = rule.last;
      declaration = new Declaration({ prop: name, value: value });
      rule.append(declaration);
      // if previous node is inline comment
      if (this.cssSyntaxService.isInlineComment(lastNode))
        // insert a new line char before added declaration
        rule.last.raws.before = '\n';
    }
    declaration.value = value;
  }

  /** Removes the specified declaration and its comments from the css rule.
   * @param rule The rule containing the specified declaration.
   * @param name The removing declaration name.
   */
  removeDeclaration(rule: Rule, name: string) {
    const declaration = this.getFirstDeclaration(rule, name);
    if (declaration) {
      const nodesToRemove: ChildNode[] = [declaration];
      // find comments before node and add them to list to remove
      const commentsBefore = this.getNodeCommentsPlacedBefore(declaration);
      if (commentsBefore)
        nodesToRemove.push(...commentsBefore);
      // find comments after node and add them to list to remove
      const commentAfter = this.getInlineCommentAfterNode(declaration);
      if (commentAfter)
        nodesToRemove.push(commentAfter);
      // remove declaration and its comments
      nodesToRemove.forEach(node => node.remove());
    }
  }

  /** Gets an array of css rules that contain the given selector.
   * @param root The root node to search css rules.
   * @param selector The css selector.
   * @returns The array of css rules.
   */
  findRulesBySelector(root: Root, selector: string): Rule[] {
    const rules: Rule[] = [];
    root.each(node => {
      if (node.type === 'rule' && (node.selector === selector || node.selectors.includes(selector))) {
        rules.push(node);
      }
    });
    return rules;
  }

  /** Creates a new rule with specified selector and append it to the root node.
   * @param root The root node.
   * @param selector The new rule selector.
   * @returns The created rule.
   */
  addRule(root: Root, selector: string): Rule {
    const rule = new Rule({ selector });
    root.append(rule);
    return rule;
  }

  /** Gets a css property key-link map from the specified css text.
   * Links are absolute inside the project, like 'Content/Resources/images/Logo.png'
   * @param rule The css rule from which to get links.
   * @param base The base absolute path relative to resolve links.
   * @returns The css property key-link map found in the css text.
   */
  getLinks(rule: Rule, base: string): Dictionary<string> {
    const links: Dictionary<string> = {};
    if (rule) {
      rule.each(node => {
        if (node.type === 'decl') {
          // try to get link for the css property
          const link = this.getLink(node, base);
          if (link) {
            // put link into the map
            links[node.prop] = link;
          }
        }
      });
    }
    return links;
  }

  /** Updates declarations of the specified css rule.
   * @param rule The css rule to update properties values.
   * @param map The name-value map that defines which declarations to update.
   */
  updateDeclarations(rule: Rule, map: Dictionary<string>) {
    if (!rule || !map) return rule;
    // iterate all declarations
    rule.each(node => {
      if (node.type === 'decl') {
        const value = map[node.prop];
        if (value === undefined) return;
        // update the declaration
        node.value = value;
      }
    });
    return rule;
  }

  /** Aggregates declarations from all rules with the specified selector in a single rule and returns it.
   * @param root The root node that contains observable rules.
   * @param selector The selector to find and aggregate rules.
   * @returns A rule with aggregated declarations if any matched rule is found, otherwise returns null.
   */
  aggregateRules(root: Root, selector: string): Rule | null {
    let rules = this.findRulesBySelector(root, selector);
    if (!rules.length) {
      return null;
    }
    let rule: Rule;
    // init major rule
    {
      // find the first rule with the specified selector (exact matching)
      rule = rules.find(node => node.selector === selector);
      if (!rule) {
        // create a new rule if not found
        rule = this.addRule(root, selector);
      } else {
        // remove duplicate declarations in the major rule
        this.removeDuplicateDeclarations(rule);
        // exclude the found rule from rules array
        rules = rules.filter(item => item !== rule);
      }
    }
    // merge the remaining rules into the main rule
    if (rules.length) {
      this.mergeRules(rules, rule, true);
    }
    return rule;
  }

  /** Gets the first declaration with specified name from the given css rule.
   * @param Rule The css rule.
   * @param name The declaration name.
   * @returns The declaration if found, otherwise undefined.
   */
  private getFirstDeclaration(rule: Rule, name: string): Declaration {
    const nodes = rule.nodes;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.type === 'decl' && node.prop === name) {
        return node;
      }
    }
  }

  /** Removes duplicate declarations in the specified rule. */
  private removeDuplicateDeclarations(rule: Rule) {
    const declarations: Dictionary<Declaration> = {};
    const nodesToRemove: ChildNode[] = [];
    rule.each(node => {
      if (node.type !== 'decl') return;
      const prev = declarations[node.prop];
      // if previous declaration exists it means the current declaration is duplicate
      if (prev) {
        // copy value from current declaration into previous one
        prev.value = node.value;
        // add current declaration to list to remove
        nodesToRemove.push(node);
        // copy comments
        const copiedComments = this.copyNodeComments(node, prev);
        // add copied comments to list to remove
        if (copiedComments)
          nodesToRemove.push(...copiedComments);
      } else {
        declarations[node.prop] = node;
      }
    });
    nodesToRemove.forEach(node => node.remove());
  }

  /** Copy source node comments to target node.
   * @param source - The source node whose comments should be copied.
   * @param target - The target node to copy comments to.
   * @param type - The type of comments to copy: placed before/after the source node or all comments.
   * @return Returns an array of copied source comment nodes or null when no comments to copy.
   */
  private copyNodeComments(source: ChildNode, target: ChildNode, type: 'all' | 'before' | 'after' = 'all'): ChildNode[] {
    const copiedNodes: ChildNode[] = [];
    if (type === 'all' || type === 'before') {
      // copy comments placed before current node
      const commentsBefore = this.getNodeCommentsPlacedBefore(source);
      if (commentsBefore) {
        commentsBefore[0].raws.before = target.raws.before;
        target.raws.before = this.getLastLine(target.raws.before);
        this.insertClonedNodes(commentsBefore, target, true);
        // add comments to list to remove
        copiedNodes.push(...commentsBefore);
      }
    }
    if (type === 'all' || type === 'after') {
      // copy comments placed after current node
      const commentAfter = this.getInlineCommentAfterNode(source);
      if (commentAfter) {
        this.insertClonedNodes([commentAfter], target, false);
        // add comments to list to remove
        copiedNodes.push(commentAfter);
      }
    }
    return array(copiedNodes);
  }

  /** Gets an array of node's comments placed before it. Returns null when no node's comments. */
  private getNodeCommentsPlacedBefore(node: ChildNode): ChildNode[] {
    const comments: ChildNode[] = [];
    for (; ;) {
      if (node.raws.before && (node.raws.before.match(/\n/g) || []).length > 1)
        /* break if node has at least one empty row before,
          e.g. \*general comment*\ is not a node comment
          =============================
          \* general comment *\

          \* node comment *\
          <node>
          ==============================
        */
        break;
      const prevNode = node.prev();
      if (!prevNode || prevNode.type !== 'comment')
        /* break if node is not a comment,
          e.g. <previous node> is not a node comment
          =============================
          <previous node>
          <node>
          ==============================
        */
        break;
      if (this.cssSyntaxService.isInlineComment(prevNode) && !prevNode.raws.before?.includes('\n'))
        /* break if the comment is a inline comment and it has a node in the same line before itself,
          e.g. '// previous node comment' is not a node comment
          =============================
          <previous node> // previous node comment
          <node>
          ==============================
        */
        break;
      node = prevNode;
      comments.push(prevNode);
    }
    // reverse the array since items are added in reverse order
    return array(comments.reverse());
  }

  /** Gets inline comment node placed after the specified node. If comment does not exist, then return null. */
  private getInlineCommentAfterNode(node: ChildNode): ChildNode {
    const nextNode = node.next();
    return nextNode && nextNode.type === 'comment' && nextNode.raws.inline && !nextNode.raws.before?.includes('\n')
      ? nextNode : null;
  }

  /** Clones the specified nodes and inserts them before/after the target node.
 * @param nodes The array of nodes to clone and insert.
 * @param target The node to insert nodes before.
 * @param before True - inserts nodes before target, False - inserts nodes after target.
 */
  private insertClonedNodes(nodes: ChildNode[], target: ChildNode, before: boolean) {
    if (!before)
      nodes = [...nodes].reverse();
    nodes.forEach(comment => {
      const clone = comment.clone();
      if (before)
        target.parent.insertBefore(target, clone);
      else
        target.parent.insertAfter(target, clone);
    });
  }

  /** Merges an array of css rules into the specified target rule. */
  private mergeRules(sourceRules: Rule[], targetRule: Rule, move: boolean) {
    if (!sourceRules.length) return;
    // init a declaration dictionary for the target rule
    const targetDeclarations: Dictionary<Declaration> = {};
    targetRule.walk((node) => {
      if (node.type === 'decl')
        targetDeclarations[node.prop] = node;
    });
    // merge declarations
    sourceRules.forEach(rule => {
      const selectorMatched = move && rule.selector === targetRule.selector;
      const removeFromSource = move && selectorMatched;
      this.mergeDeclarations(rule, targetRule, targetDeclarations, removeFromSource);
      if (move) {
        if (selectorMatched) {
          // remove the source rule if it has no child nodes
          let hasChildren = false;
          rule.each(node => {
            if (node.type !== 'comment') {
              hasChildren = true;
              return false;
            }
          });
          if (!hasChildren) {
            // copy rule's comments
            const comments = this.copyNodeComments(rule, targetRule, 'before');
            // remove source rule
            rule.remove();
            // remove comments of source rule
            if (comments)
              comments.forEach(comment => comment.remove());
          }
        } else {
          // remove selector from the source rule
          const selectors = rule.selectors.filter(s => s !== targetRule.selector);
          rule.selector = selectors.join('\n ');
        }
      }
    });
  }

  /** Merges declarations with comments around it from the source css rule into the target css rule.
   * @source The source css rule.
   * @target The target css rule.
   * @targetDeclarations The declaration dictionary for the target rule to speed up declaration search.
   * @removeFromSource Indicates whether moved declarations and its comments should be removed from source rule.
   */
  private mergeDeclarations(source: Rule, target: Rule, targetDeclarations: Dictionary<Declaration>, removeFromSource: boolean) {
    source.each((node) => {
      if (node.type !== 'decl') return;
      let decl = targetDeclarations[node.prop];
      // move declaration
      {
        if (decl) {
          // update value of the exiting declaration
          decl.value = node.value;
        } else {
          // or copy the declaration from the source rule to the target rule
          decl = targetDeclarations[node.prop] = node.clone();
          target.append(decl);
        }
      }
      // copy comments to target declaration
      const copiedComments = this.copyNodeComments(node, decl);
      // remove source nodes and its comments
      if (removeFromSource) {
        const movedNodes: ChildNode[] = [node];
        if (copiedComments)
          movedNodes.push(...copiedComments);
        movedNodes.forEach(node => node.remove());
      }
    });
  }

  /** Creates a syntax error. */
  private createSyntaxError(message: string, line: number, column: number): Error {
    const error = Error(`(${line}:${column}) ${message}`);
    error.name = 'SyntaxError';
    return error;
  }

  /** Gets project absolute link path from the css property value.
   * @param styles Style declaration which contains property.
   * @param base Base absolute path relative to resolve the link path.
   * @returns The property project absolute link path.
   * @example 'url(../Images/Logo.png)' => 'Content/Resources/Images/Logo.png'
   */
  private getLink(property: Declaration, base: string): string {
    const value = property.value.trim();
    if (!value) return null;
    // we suppose the only link in the value
    const link = this.cssSyntaxService.parseValueAsLink(value);
    // do not resolve absolute links
    if (!link || isAbsoluteUrl(link)) return null;
    return resolvePath(base, link);
  }

  /** Returns substring starting from last \n char if exists, otherwise return the whole string. */
  private getLastLine(text: string): string {
    if (!text)
      return text;
    const idx = text.lastIndexOf('\n');
    if (idx <= 0)
      return text;
    return text.substring(idx);
  }

}
