import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { PermissionsFormComponent } from '@portal-core/permissions/components/permissions-form/permissions-form.component';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { PermissionsFormControl } from '@portal-core/permissions/util/permissions-form-control';
import { TeamProfile } from '@portal-core/profiles/models/team-profile.model';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { Site } from '@portal-core/sites/models/site.model';
import { TeamAccessFormComponent, TeamAccessFormTab } from '@portal-core/teams/components/team-access-form/team-access-form.component';
import { TeamSettingsFormComponent } from '@portal-core/teams/components/team-settings-form/team-settings-form.component';
import { TeamProfileTab } from '@portal-core/teams/enums/team-profile-tab.enum';
import { Team } from '@portal-core/teams/models/team.model';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, map, Observable } from 'rxjs';

export enum TeamProfileForm {
  Settings,
  Access,
  Activity,
  Delete
}

@Component({
  selector: 'mc-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MC_FOCUSABLE, useExisting: TeamProfileComponent }
  ]
})
export class TeamProfileComponent extends ProfileBase<TeamProfileTab> implements OnInit {
  @Input() teamProfile: TeamProfile;
  @Input() showAccess?: boolean = true;
  @Input() showActivity?: boolean = true;
  @Input() showDelete?: boolean = true;
  @Input() showPermissions?: boolean = true;
  @Input() showSettings?: boolean = true;
  @Input() teamAccessFormTab?: TeamAccessFormTab;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<TeamProfileForm> = new EventEmitter<TeamProfileForm>();

  @InputObservable('teamProfile') teamProfile$: Observable<TeamProfile>;
  @InputObservable('showAccess') showAccess$: Observable<boolean>;
  @InputObservable('showActivity') showActivity$: Observable<boolean>;
  @InputObservable('showDelete') showDelete$: Observable<boolean>;
  @InputObservable('showPermissions') showPermissions$: Observable<boolean>;
  @InputObservable('showSettings') showSettings$: Observable<boolean>;

  @ViewChild(PermissionsFormComponent) permissionsFormComponent: PermissionsFormComponent;
  @ViewChild(TeamSettingsFormComponent) teamSettingsForm: TeamSettingsFormComponent;
  @ViewChild(TeamAccessFormComponent) teamAccessForm: TeamAccessFormComponent;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case TeamProfileTab.Settings:
        return this.teamSettingsForm?.dirty;
      case TeamProfileTab.Access:
        return this.teamAccessForm?.dirty;
      case TeamProfileTab.Permissions:
        return this.permissionsFormComponent?.dirty;
      default:
        return false;
    }
  }

  TeamProfileTab: typeof TeamProfileTab = TeamProfileTab;

  showAccessTab$: Observable<boolean>;
  showActivityTab$: Observable<boolean>;
  showDeleteTab$: Observable<boolean>;
  showPermissionsTab$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;
  team$: Observable<Team>;
  teamProjects$: Observable<Project[]>;
  teamProjectIdsNotArchived$: Observable<number[]>;
  teamLicenseUsers$: Observable<LicenseUser[]>;
  teamSites$: Observable<Site[]>;
  userCanDeleteTeams$: Observable<boolean>;
  userCanManageTeams$: Observable<boolean>;

  permissionsFormControl: PermissionsFormControl = new PermissionsFormControl(
    (teamId: number, licenseId: number) => this.permissionsService.getTeamPermissionsOnLicenseLevel$(teamId),
    (teamId: number, projectId: number) => this.permissionsService.getTeamPermissionsOnProjectLevel$(teamId, projectId),
    (teamId: number, licenseId: number, permissions: Permissions[]) => this.permissionsService.saveTeamPermissionsOnLicenseLevel$(teamId, licenseId, permissions),
    (teamId: number, projectId: number, permissions: Permissions[]) => this.permissionsService.saveTeamPermissionsOnProjectLevel$(teamId, projectId, permissions)
  );

  constructor(
    dialog: MatDialog,
    cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private currentService: CurrentService
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    this.userCanDeleteTeams$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.DeleteTeams);
    this.userCanManageTeams$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);

    // Create an observable for whether the current user is an author
    const currentUserIsAuthor$ = this.currentService.getCurrentLicenseUserIsAuthor$();

    this.team$ = this.teamProfile$.pipe(
      map(teamProfile => teamProfile?.Team)
    );
    this.teamProjects$ = this.teamProfile$.pipe(
      map(teamProfile => teamProfile?.Projects)
    );
    this.teamProjectIdsNotArchived$ = this.teamProfile$.pipe(
      map(teamProfile => teamProfile ? teamProfile.Projects.filter(project => project.Status !== ProjectStatus.Archived) : null),
      map(projects => projects?.map(project => project.Id))
    );
    this.teamLicenseUsers$ = this.teamProfile$.pipe(
      map(teamProfile => teamProfile?.LicenseUsers.filter(user => user.Status !== LicenseUserStatus.Deactivated))
    );
    this.teamSites$ = this.teamProfile$.pipe(
      map(teamProfile => teamProfile?.Sites)
    );

    this.showAccessTab$ = this.showAccess$;
    this.showActivityTab$ = this.showActivity$;
    this.showDeleteTab$ = combineLatest([this.showDelete$, this.userCanDeleteTeams$]).pipe(
      map(([showDelete, userCanDeleteTeams]) => showDelete && userCanDeleteTeams)
    );
    this.showPermissionsTab$ = combineLatest([this.showPermissions$, currentUserIsAuthor$]).pipe(
      map(([showPermissions, currentUserIsAuthor]) => showPermissions && currentUserIsAuthor)
    );
    this.showSettingsTab$ = combineLatest([this.showDelete$, this.userCanManageTeams$]).pipe(
      map(([showDelete, userCanManageTeams]) => showDelete && userCanManageTeams)
    );
  }

  onSettingsSaved() {
    this.saved.emit(TeamProfileForm.Settings);
  }

  onTeamAccessSaved() {
    this.saved.emit(TeamProfileForm.Access);
  }

  onTeamDeleted() {
    this.saved.emit(TeamProfileForm.Delete);
  }

  onCancel() {
    this.cancel.emit();
  }
}
