import { Injectable, Injector } from '@angular/core';
import { Resettable, IResettable } from '@portal-core/util/resettable.decorator';

@Injectable({
  providedIn: 'root'
})
export class ResettableService {
  constructor(private injector: Injector) { }

  // Returns all the injectables from providers that use the @Resettable decorator
  getResettables(): IResettable[] {
    return Resettable.resettables.map(resettable => this.injector.get<any>(resettable as any));
  }
}
