import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseStorageDataService } from '@portal-core/license-storage/services/license-storage-data.service';
import { LicensesApiService } from '@portal-core/licenses/services/licenses-api.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class LicenseStorageService extends CollectionServiceBase<LicenseStorage> {
  constructor(private licenseStorageDataService: LicenseStorageDataService, private licensesApiService: LicensesApiService, protected dataService: DataService) {
    super(licenseStorageDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<LicenseStorage> {
    return this.licensesApiService.getStorageInfo$(itemId).pipe(
      // Set the Id since it isn't set on the model from the server
      tap(licenseStorage => licenseStorage.Id = itemId)
    );
  }
}
