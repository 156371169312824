import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { BuildPickerComponent } from '@portal-core/builds/components/build-picker/build-picker.component';
import { BuildPickerValue } from '@portal-core/builds/types/build-picker-value.type';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { isEqual } from 'lodash';

@Component({
  selector: 'mc-build-picker-input',
  templateUrl: './build-picker-input.component.html',
  styleUrls: ['./build-picker-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-build-picker-input-disabled]': 'disabled',
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: BuildPickerInputComponent }
  ]
})
export class BuildPickerInputComponent extends CustomInputBase<BuildPickerValue> {
  /** The license to pick the build from. */
  @Input() licenseId: number;

  /** A reference to the build picker component. */
  @ViewChild(BuildPickerComponent, { static: true }) buildPicker: BuildPickerComponent;
  /** A reference to the popup component. */
  @ViewChild('popup', { static: true }) popup: PopupComponent;
  /** A reference to the popup trigger. */
  @ViewChild('trigger', { static: true }) triggerElementRef: ElementRef<HTMLElement>;

  /** Whether the build picker has no value. Required by MatFormFieldControl. */
  get empty(): boolean {
    return !this.valueIsComplete(this.value);
  }

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return !this.empty;
  }

  /** Required by MatFormFieldControl */
  readonly controlType: string = 'mc-build-picker-input';

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  onPopupClosed() {
    // Ensure the popup has the current value after it closes.
    // The user may have entered an incomplete value in the popup.
    // Patching the value here makes sure the incomplete value is replaced by the actual value for the next time the popup is opened.
    this.buildPicker.setValue(this.value);
  }

  onPopupOpened() {
    // Wait for the popup to be fully opened and then give the build-picker focus
    setTimeout(() => {
      this.buildPicker.focus();
    }, 0);
  }

  onBuildPickerCleared() {
    this.close();
  }

  onBuildPickerDone() {
    this.close();
  }

  onBuildPickerValueChanged(value: BuildPickerValue) {
    // Only update the value if the build picker popup has values for every property or every value has been nulled out
    if (this.valueIsComplete(value) || this.valueIsNull(value)) {
      const valueChanged = !this.valuesAreEqual(this.value, value);

      if (valueChanged) {
        this.value = value;
        this.onChange(this.value); // So Angular Forms know this control's value has changed
        this.onTouched(); // So Angular Forms know this control has been touched
      }

      // Close the popup if the user has selected a build
      if (this.valueIsComplete(value)) {
        this.close();
      }
    }
  }

  close() {
    this.buildPicker?.closePopups();
    this.popup.close();
  }

  focus() {
    this.triggerElementRef.nativeElement.focus();
  }

  open() {
    if (!this.disabled) {
      this.popup.open();
    }
  }

  private valueIsComplete(value: BuildPickerValue): boolean {
    return !!(value && typeof value.projectId === 'number' && value.targetPath && typeof value.buildId === 'number');
  }

  private valueIsNull(value: BuildPickerValue): boolean {
    return !!(value && value.projectId === null && value.targetPath === null && value.branchName === null && value.buildId === null);
  }

  /** Returns whether two values are equal. */
  valuesAreEqual(valueA: BuildPickerValue, valueB: BuildPickerValue): boolean {
    return isEqual(valueA, valueB);
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return 'Build';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.triggerElementRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
    this.open();
  }
}
