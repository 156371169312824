<div class="mc-avatar-tooltip-container" [matTooltip]="reviewFile?.Status | mcReviewFileAvatarTooltip : reviewFile?.IsDeleted : reviewFile?.Path" [matTooltipClass]="reviewFile?.Status | mcReviewFileAvatarTooltipClass : reviewFile?.IsDeleted" [matTooltipDisabled]="disabledTooltip || !reviewFile">
  <button *ngIf="showAvatar" class="mc-avatar-container" [disabled]="isImageDisabled()" [ngClass]="colorClass$ | async" type="button" (click)="onAvatarClicked()">
    <mat-icon class="mc-avatar-icon" fontSet="project-titan" fontIcon="icon-documenticon"></mat-icon>
    <mat-icon *ngIf="showStatus && (reviewFile?.Status === ReviewFileStatus.Closed || reviewFile?.Status === ReviewFileStatus.Accepted)" class="mc-avatar-indicator-bottom-right mc-avatar-indicator-icon" [color]="reviewFile?.Status | mcReviewFileStatusColor" fontSet="project-titan" fontIcon="icon-lock-solid"></mat-icon>
  </button>

  <ng-container *ngIf="showName">
    <ng-container *ngIf="nameLinksToPage && !isNameDisabled() && reviewFile | mcReviewFileLink as reviewFileLink; else nameAsButton">
      <a href="#" class="mc-avatar-name" [routerLink]="reviewFileLink">
        <mc-self-truncating-path *ngIf="showPath" [filePath]="reviewFile?.Path | mcTrim : 'Content/' : 'start'" [showTooltip]="false"></mc-self-truncating-path>
        <ng-container *ngIf="!showPath">{{ reviewFile?.Name }}</ng-container>
        <span *ngIf="showId" class="mc-text-muted"> ({{ reviewFile?.Id }})</span>
      </a>
    </ng-container>
    <ng-template #nameAsButton>
      <mc-inline-button class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">
        <mc-self-truncating-path *ngIf="showPath" [filePath]="reviewFile?.Path | mcTrim : 'Content/' : 'start'" [showTooltip]="false"></mc-self-truncating-path>
        <ng-container *ngIf="!showPath">{{ reviewFile?.Name }}</ng-container>
        <span *ngIf="showId" class="mc-text-muted"> ({{ reviewFile?.Id }})</span>
      </mc-inline-button>
    </ng-template>
  </ng-container>
</div>