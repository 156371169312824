import { EventEmitter, Output, Input, ViewChild, HostBinding, Directive } from '@angular/core';
import { MediaQueryString } from '@portal-core/util/types/media-query-string.type';
import { DataGridComponent } from '@portal-core/ui/grid/components/data-grid/data-grid.component';
import { VisibleColumns } from '@portal-core/ui/grid/types/visible-columns.type';
import { Observable } from 'rxjs';

/**
 * DataGridBase
 * A base class that should be extended by grid components.
 * DataGridBase provides common functionality when working with grids including configuration Inputs, Outputs, and selection methods.
 * Replaces DataGridBase which is now deprecated.
 */
@Directive()
export abstract class DataGridBase<T extends { Id: number | string }> {
  /**
   * The media query at which to switch the grid into a single column card based view.
   * @deprecated
   */
  @Input() cardBreakpoint?: MediaQueryString;
  /** An array of columns names that are displayed by default. */
  @Input() defaultVisibleColumns?: VisibleColumns = 'all';
  /** Whether the grid has expandable rows. */
  @Input() expandableRows?: boolean = false;
  /** The unique id for the grid. Used for differentiating grids that belong to the same collection and persisting grid configuration across page loads. */
  @Input() gridId: string;
  /** Whether there is a checkbox column in the grid. */
  @Input() selectable?: boolean = false;
  /** Selects the item with the given id. */
  @Input() selectedItemId?: number | string;
  /** Whether the border is shown between rows. */
  @Input() showRowBorder?: boolean = true;

  /** Defined on DataGridBase but must be emitted by the component that extends DataGridBase. */
  @Output() selectedRowsChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  /** Defined on DataGridBase but must be emitted by the component that extends DataGridBase. */
  @Output() dataLoaded: EventEmitter<void> = new EventEmitter<void>();

  /** Adds the mc-data-grid-base class to the host element. This styles the component so that the mc-data-grid fills the component. */
  @HostBinding('class.mc-data-grid-base') dataGridBaseHostClass: boolean = true;

  /** A reference to the data grid. **/
  @ViewChild(DataGridComponent) dataGrid: DataGridComponent<T>;

  /** Whether the grid's context menu is open. */
  get contextMenuOpen(): boolean {
    return this.dataGrid && this.dataGrid.contextMenuOpen;
  }

  /** The first item that is currently selected in the grid. */
  get firstSelectedItem(): T {
    if (this.dataGrid) {
      const items = this.dataGrid.selectedItems;

      if (Array.isArray(items)) {
        return items[0];
      }
    }
  }

  /** The items on the current page of the grid.  */
  get items$(): Observable<T[]> {
    if (this.dataGrid) {
      return this.dataGrid.items$;
    }
  }

  /** The items on the current page of the grid.  */
  get items(): T[] {
    if (this.dataGrid) {
      return this.dataGrid.items;
    }
  }

  /** The total number items in the grid across all pages.  */
  get itemTotal(): number {
    if (this.dataGrid) {
      return this.dataGrid.itemTotal;
    } else {
      return 0;
    }
  }

  /** The currently selected items in the grid. */
  get selectedItems(): T[] {
    if (this.dataGrid) {
      return this.dataGrid.selectedItems;
    } else {
      return [];
    }
  }

  /** The number of items currently selected in the grid. */
  get selectedItemCount(): number {
    if (this.dataGrid) {
      return this.dataGrid.selectedItemCount;
    } else {
      return 0;
    }
  }

  /** Clears the current selection of items. */
  clearSelection() {
    if (this.dataGrid) {
      this.dataGrid.clearSelection();
    }
  }

  /**
   * Closes the header menu on the grid.
   * @param columnName Optionally provide the column name to only close the menu for that column.
   */
  closeHeaderMenu(columnName?: string) {
    if (this.dataGrid) {
      this.dataGrid.closeHeaderMenu(columnName);
    }
  }

  /**
   * Deselects the given items from the grid.
   * @param items An array of items to deselect.
   */
  deselectItems(...items: T[]) {
    if (this.dataGrid) {
      this.dataGrid.deselectItems(...items);
    }
  }

  /** Reloads the grid by fetching new data from the server. */
  hardReload() {
    if (this.dataGrid) {
      this.dataGrid.hardReload();
    }
  }

  /** Opens the grid's columns dialog for configuring the grid's columns. */
  openColumnsDialog() {
    if (this.dataGrid) {
      this.dataGrid.openColumnsDialog();
    }
  }

  /**
   * Clears out the current selection and selects the given items by id.
   * @param itemIds The item ids to select.
   */
  replaceSelectionById(...itemIds: (number | string)[]) {
    if (this.dataGrid) {
      this.dataGrid.replaceSelectionById(...itemIds);
    }
  }
}
