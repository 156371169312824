import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Branch } from '@portal-core/branches/models/branch.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getBuildBranchesPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Branch>> {
    return this.http.post<Page<Branch>>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/branchnames`, filter);
  }

  @SharePendingRequest()
  getProjectBranches$(projectId: number): Observable<string[]> {
    return this.http.get<Branch[]>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/Branches`).pipe(
      map(branches => branches.map(branch => branch.Name))
    );
  }

  @SharePendingRequest()
  getProjectBranchesPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Branch>> {
    return this.http.post<Page<Branch>>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/BranchesGrid`, filter);
  }

  @SharePendingRequest()
  getDefaultBranch$(projectId: number): Observable<string> {
    return this.http.get<string>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/DefaultBranch`);
  }

  @SharePendingRequest()
  getBranchFromProjectId$(projectId: number, branchName: string): Observable<Branch> {
    return this.http.get<Branch>(`${this.apiService.centralApiBaseUri}/api/git/projects/${projectId}/branches/${encodeURIComponent(branchName)}`);
  }
}

