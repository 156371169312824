import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { ReviewFileUser } from '@common/reviews/models/review-file-user.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ReviewFileUsersApiService } from '@portal-core/reviews/review-file-users/services/review-file-users-api.service';
import { ReviewFileUsersDataService } from '@portal-core/reviews/review-file-users/services/review-file-users-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ReviewFileUsersService extends CollectionServiceBase<ReviewFileUser> {
  constructor(
    private reviewFileUsersApiService: ReviewFileUsersApiService,
    private reviewFileUsersDataService: ReviewFileUsersDataService,
    protected dataService: DataService
  ) {
    super(reviewFileUsersDataService, dataService);
  }

  protected fetchItemById$(reviewFileUserId: number): Observable<ReviewFileUser> {
    return this.reviewFileUsersApiService.getReviewFileUserById$(reviewFileUserId);
  }

  getMyReviewFileUserByUserId$(userId: string, reviewFileId: number, options: GetDataOptions = null): Observable<ReviewFileUser> {
    return this.dataService.getData<ReviewFileUser>({
      get: () => this.reviewFileUsersDataService.getItemByProperties$('UserId', userId, 'ReviewFileId', reviewFileId),
      fetch: () => this.reviewFileUsersApiService.getMyReviewFileUser$(reviewFileId),
      set: newReviewFileUser => this.reviewFileUsersDataService.addItems$([newReviewFileUser])
    }, options);
  }

  getReviewFileUsersPage$(reviewPackageId: number, filter?: PageFilter): Observable<Page<ReviewFileUser>> {
    return this.reviewFileUsersApiService.getReviewFileUsersPage$(reviewPackageId, filter);
  }

  updateMyReviewFileUserStatus$(reviewFileId: number, reviewFileUserId: number, status: ReviewFileUserStatus): Observable<any> {
    return this.reviewFileUsersApiService.updateMyReviewFileUserStatus$(reviewFileId, status).pipe(
      tap(() => {
        this.updateItems$([{
          Id: reviewFileUserId,
          Status: status
        }]);
      })
    );
  }
}
