import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@common/auth/models/auth.model';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { AddPasswordRequest } from '@portal-core/auth/models/add-password-request.model';
import { AuthUserData } from '@portal-core/auth/models/auth-user-data.model';
import { CentralInstance } from '@portal-core/auth/models/central-instance.model';
import { CompletedInvite } from '@portal-core/auth/models/completed-invite.model';
import { SubdomainLicense } from '@portal-core/auth/models/subdomain-license.model';
import { VerifiedUser } from '@portal-core/auth/models/verified-user.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthInterceptorHttpParams } from '@portal-core/auth/util/auth-interceptor-http-params';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

// TODO: move this to the environment files
const clientId = 'a87e66a0-4c59-46bd-a89c-a49eab02ba93';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(protected http: HttpClient, protected errorService: ErrorService, protected apiService: ApiService) { }

  @SharePendingRequest()
  getUserData$(ignoreAuthErrors: boolean = false): Observable<AuthUserData> {
    return this.http.get<AuthUserData>(`${this.apiService.centralApiBaseUri}/api/Users/MyLicenseData`, {
      params: new AuthInterceptorHttpParams({
        ignoreAuthErrors
      })
    });
  }

  @SharePendingRequest()
  login$(email: string, password: string): Observable<Auth> {
    const body = new URLSearchParams();
    body.set('client_id', clientId);
    body.set('grant_type', 'password');
    body.set('username', email);
    body.set('password', password);

    return this.http.post<Auth>(`${this.apiService.centralApiBaseUri}/token`, body.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });
  }

  logout$(): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/users/logout`, {}, {
      responseType: 'text',
      withCredentials: true
    });
  }

  loginToLicense$(rootToken: string, useCredentials: boolean): Observable<Auth> {
    if (rootToken && !useCredentials) {
      return this.http.post<Auth>(`${this.apiService.centralApiBaseUri}/api/licenses/login`, {
        ClientId: clientId,
        RootBearerToken: rootToken
      });
    } else {
      return this.http.post<Auth>(`${this.apiService.centralApiBaseUri}/api/licenses/login`, {
        ClientId: clientId
      }, { withCredentials: true });
    }
  }

  addPassword$(addPasswordRequest: AddPasswordRequest): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/AddPassword`, addPasswordRequest);
  }

  changeExpiredPassword$(userName: string, currentPassword: string, newPassword: string): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/users/ChangeExpiredPassword`, {
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
      UserName: userName
    });
  }

  @SharePendingRequest()
  completeInvite$(userId: string): Observable<CompletedInvite> {
    return this.http.get<CompletedInvite>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/UserToCompleteRegistration`);
  }

  requestPasswordReset$(email: string, client?: string): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/ForgotPassword`, null, { params: { email, client } });
  }

  requestSiteAccess$(): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/RequestSiteAccess`, null);
  }

  requestLicenseAccess$(): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/RequestLicenseAccess`, null);
  }

  samlSsoLogin$(returnUrl: string, clientDomain: string): Observable<any> {
    const body = new URLSearchParams();
    body.set('client_id', clientId);
    body.set('return_url', returnUrl ? returnUrl : '');
    body.set('client_domain', clientDomain);

    return this.http.post(`${this.apiService.centralApiBaseUri}/api/users/SamlLoginRequest`, body.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      responseType: 'text'
    });
  }

  resetPassword$(userId: string, token: string, password: string): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/ResetPassword`, {
      NewPassword: password,
      Token: token,
      UserId: userId
    });
  }

  updatePassword$(userId: string, currentPassword: string, newPassword: string): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/ChangePassword`, {
      CurrentPassword: currentPassword,
      NewPassword: newPassword
    });
  }

  verifyUserToken$(userId: string, token: string, confirmEmail: boolean): Observable<VerifiedUser> {
    return this.http.post<VerifiedUser>(`${this.apiService.centralApiBaseUri}/api/users/VerifyUserToken`, {
      ConfirmEmail: confirmEmail,
      Token: token,
      UserId: userId
    });
  }

  verifySsoUserToken$(userId: string, token: string, teamName?: string): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/ConfirmUserTokenAndCreate${teamName ? '?teamName=' + encodeURIComponent(teamName) : ''}`, JSON.stringify(token),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
  }

  @SharePendingRequest()
  getMinPasswordLength$(userName: string): Observable<number> {
    return this.http.get<number>(`${this.apiService.centralApiBaseUri}/api/users/${userName}/GetMinPasswordLength`);
  }

  @SharePendingRequest()
  getCentralInstances$(): Observable<CentralInstance[]> {
    return this.http.get<CentralInstance[]>(`${this.apiService.centralGlobalApiBaseUri}/api/common/instances`);
  }

  @SharePendingRequest()
  getLicenseCentralInstance$(licenseVanityUrl: string): Observable<CentralInstance> {
    return this.http.get<CentralInstance>(`${this.apiService.centralGlobalApiBaseUri}/api/licenses/${licenseVanityUrl}/instance`);
  }

  @SharePendingRequest()
  getCentralInstanceByInstanceCode$(instanceCode: string): Observable<CentralInstance> {
    return this.http.get<CentralInstance>(`${this.apiService.centralGlobalApiBaseUri}/api/common/instance/${instanceCode}`);
  }

  @SharePendingRequest()
  getIsVanityUrlPresent$(vanity: string, checkAllInstances: boolean): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralGlobalApiBaseUri}/api/licenses/IsVanityUrlPresent?checkAllInstances=${checkAllInstances}`, { VanityBaseUrl: vanity });
  }

  @SharePendingRequest()
  getLicenseByDomain$(): Observable<SubdomainLicense> {
    return this.http.get<SubdomainLicense>(`${this.apiService.centralApiBaseUri}/api/licenses/getDomainLicense`);
  }
}
