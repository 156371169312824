import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { SelectionListItem } from '@portal-core/general/models/selection-list-item';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { map } from 'rxjs';

interface TeamItem extends SelectionListItem {
  Team: Team;
  TeamId: number;
}

@Component({
  selector: 'mc-site-teams-form',
  templateUrl: './site-teams-form.component.html',
  styleUrls: ['./site-teams-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteTeamsFormComponent {
  @Input() site: SiteGridItem;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  teamPristine: boolean = true;
  readOnly: boolean = true;
  licenseTeams: TeamItem[];
  savingSitesState: LoadingState<string> = new LoadingState<string>();
  loadingState: LoadingState<string> = new LoadingState<string>();

  constructor(private teamsService: TeamsService, private sitesService: SitesService, private errorService: ErrorService) { }

  teamSelectionUpdated() {
    this.teamPristine = false;
  }

  toggleTeamSelection(teamItem: TeamItem) {
    teamItem.Selected = !teamItem.Selected;
    this.teamSelectionUpdated();
  }

  onCancelClicked() {
    this.readOnly = true;
    this.cancel.emit();
  }

  onSubmit() {
    const siteTeams = this.licenseTeams
      .filter(teamItem => teamItem.Selected)
      .map(teamItem => {
        return {
          SiteId: this.site.Id,
          TeamId: teamItem.Team.Id,
        };
      });

    this.savingSitesState.update(true);

    this.sitesService.updateSiteTeams$(this.site.Id, siteTeams).subscribe(ok => {
      this.savingSitesState.update(false);
      this.teamPristine = true;
      this.readOnly = true;
      this.saved.emit();
    }, error => {
      this.savingSitesState.update(false, 'Unable to save the site teams.', this.errorService.getErrorMessages(error));
    });
  }

  onEditTeamsClicked() {
    this.readOnly = false;
    this.loadTeams();
  }

  private loadTeams() {
    this.loadingState.update(true);

    this.teamsService.getTeamsByLicenseId$(this.site.LicenseId, { forceApiRequest: true }).pipe(
      map((teams) => {
        if (Array.isArray(teams)) {
          let selectedTeamIds: number[];

          // Build the team items
          if (this.site) {
            selectedTeamIds = this.site.SiteTeams.map(siteTeam => siteTeam.TeamId);
          }

          return teams.map<TeamItem>(team => {
            // A team is selected if it is a part of the project
            return {
              Team: team,
              TeamId: team.Id,
              Selected: Array.isArray(selectedTeamIds) && selectedTeamIds.some(teamId => team.Id === teamId),
              Value: team.Id
            };
          });
        } else {
          return null;
        }
      })
    ).subscribe(licenseTeams => {
      this.licenseTeams = licenseTeams;
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the teams.', this.errorService.getErrorMessages(error));
    });
  }
}
