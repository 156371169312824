import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { ReviewPackageAnalytics } from '@portal-core/reviews/review-packages/models/review-package-analytics.model';
import { ReviewPackageFileAnalytics } from '@portal-core/reviews/review-packages/models/review-package-file-analytics.model';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { ReviewPackageAnalyticsView } from '@portal-core/reviews/review-packages/types/review-package-analytics-view.type';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'mc-review-package-overview',
  templateUrl: './review-package-overview.component.html',
  styleUrls: ['./review-package-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageOverviewComponent {
  @Input() reviewPackage: ReviewPackage;
  @Input() reviewPackageUser: ReviewPackageUser;

  @InputObservable('reviewPackage') reviewPackage$: Observable<ReviewPackage>;
  @PropertyObservable('analyticsView') analyticsView$: Observable<ReviewPackageAnalyticsView>;

  analytics$: Observable<ReviewPackageAnalytics>;
  analyticsView: ReviewPackageAnalyticsView = 'files';
  fileAnalytics$: Observable<ReviewPackageFileAnalytics>;

  constructor(private reviewPackagesService: ReviewPackagesService) { }

  ngOnInit() {
    this.analytics$ = combineLatest([
      this.reviewPackage$,
      this.analyticsView$
    ]).pipe(
      switchMap(([reviewPackage, analyticsView]) => this.reviewPackagesService.getReviewsPackageAnalytics$(reviewPackage.Id, analyticsView === 'all'))
    );

    this.fileAnalytics$ = this.reviewPackage$.pipe(
      switchMap(reviewPackage => this.reviewPackagesService.getReviewsPackageFileAnalytics$(reviewPackage.Id))
    );
  }

  onAnalyticsViewChanged(event: MatSelectChange) {
  }
}
