<div class="mc-indeterminate-paginator-page-size">
  <span class="mc-indeterminate-paginator-page-size-label">Items per page:</span>
  <mat-form-field [color]="color" class="mc-indeterminate-paginator-page-size-select-form-field">
    <mat-select [value]="pageSize" (selectionChange)="onPageSizeSelected($event)">
      <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">
        {{ pageSizeOption }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="mc-indeterminate-paginator-range-actions">
  <div class="mc-indeterminate-paginator-range-label">
    {{ getRangeLabel() }}
  </div>
  <button type="button" mat-icon-button aria-label="Previous page" [disabled]="pageIndex === 0" (click)="onPrevPageClicked()">
    <mat-icon fontSet="project-titan" fontIcon="icon-left-arrow"></mat-icon>
  </button>
  <button type="button" mat-icon-button aria-label="Next page" [disabled]="nextButtonIsDisabled()" (click)="onNextPageClicked()">
    <mat-icon fontSet="project-titan" fontIcon="icon-right-arrow"></mat-icon>
  </button>
</div>