<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Resetting <b>{{ licenseUser?.User?.FullName}}'s</b> password will resend an email invitation to the user, forcing a Password Reset.
    </p>
    <p class="mc-form-text">
      The user must click the link in the latest email in order to reset the password.
    </p>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button *ngIf="showCancelButton" type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Reset Password</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>