<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Deleting a site will permanently remove it.
    </p>
    <p class="mc-form-text">
      You cannot delete sites that are live.
    </p>
    <p *ngIf="site.IsLive" class="mc-text-warn">
      This site is live and cannot be deleted.
      To delete this site, remove the live setting.
    </p>
  </div>
  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="warn" [disabled]="site.IsLive">Delete</button>
    </div>
  </div>
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>