import { Pipe, PipeTransform } from '@angular/core';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';

@Pipe({
  name: 'mcLicenseUserAvatarTooltip'
})
export class LicenseUserAvatarTooltipPipe implements PipeTransform {
  constructor(private licenseUsersService: LicenseUsersService) { }

  transform(status: LicenseUserStatus | null | undefined, isDeleted: boolean, seatType: LicenseUserSeatType, fullName: string): string {
    let statusText: string = '';

    if (isDeleted) {
      statusText = 'This user has been deleted.';
    } else {
      switch (status) {
        case LicenseUserStatus.Deactivated:
          statusText = 'This user has been deactivated.';
          break;
        case LicenseUserStatus.Invited:
          statusText = 'This user has a pending invite.';
          break;
        case LicenseUserStatus.Paused:
          statusText = 'This user has been paused.';
          break;
      }
    }

    return `${fullName}\n${this.licenseUsersService.getLicenseUserSeatTypeDisplayText(seatType)}${statusText ? '\n\n' + statusText : ''}`;
  }
}
