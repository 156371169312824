import { getNodeType, removeNodeTypeFromSlice } from '@common/prosemirror/model/node';
import { NodeType, Slice } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

const pasteCleanerKey = new PluginKey('pasteCleaner');

export interface PasteCleanerPluginOptions {
  ignoredNodeTypesOrNames?: (NodeType | string)[];
}

/**
 * A ProseMirror plugin for removing nodes before they are pasted into the editor.
 */
export function pasteCleanerPlugin(options: PasteCleanerPluginOptions): Plugin {
  let ignoredNodeTypes: NodeType[];

  return new Plugin({
    key: pasteCleanerKey,

    props: {
      transformPasted: function (slice: Slice, editorView: EditorView) {
        ignoredNodeTypes = ignoredNodeTypes ?? options.ignoredNodeTypesOrNames?.map(nodeName => getNodeType(nodeName, editorView.state.schema));

        if (Array.isArray(ignoredNodeTypes)) {
          // Remove all nodes from the slice that are not allowed
          return removeNodeTypeFromSlice(slice, ignoredNodeTypes);
        }
      }
    }
  });
}
