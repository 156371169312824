import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewPackageAsyncPipe } from './review-package-async.pipe';

@NgModule({
  declarations: [ReviewPackageAsyncPipe],
  imports: [CommonModule],
  exports: [ReviewPackageAsyncPipe],
  providers: [ReviewPackageAsyncPipe]
})
export class ReviewPackageAsyncModule {}
