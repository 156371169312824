<div class="mc-avatar-tooltip-container" [matTooltip]="team?.Name" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !team">
  <button *ngIf="showAvatar && !isImageDisabled()" type="button" class="mc-avatar-container" (click)="onAvatarClicked()">
    <img *ngIf="team?.Avatar?.StorageId" class="mc-avatar-image" alt="Team Avatar" [src]="team?.Avatar?.StorageId | mcMadCapImageUrl : team?.Avatar?.Extension">
    <img *ngIf="team?.Avatar?.Url" class="mc-avatar-image" alt="Team Avatar" [src]="team?.Avatar?.Url">
    <span *ngIf="!team?.Avatar" class="mc-avatar-initials">{{ team?.Initials | uppercase }}</span>
  </button>
  <div *ngIf="showAvatar && isImageDisabled()" class="mc-avatar-container">
    <img *ngIf="team?.Avatar?.StorageId" class="mc-avatar-image" alt="Team Avatar" [src]="team?.Avatar?.StorageId | mcMadCapImageUrl : team?.Avatar?.Extension">
    <img *ngIf="team?.Avatar?.Url" class="mc-avatar-image" alt="Team Avatar" [src]="team?.Avatar?.Url">
    <span *ngIf="!team?.Avatar" class="mc-avatar-initials">{{ team?.Initials | uppercase }}</span>
  </div>

  <mc-inline-button *ngIf="showName" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">
    {{ team?.Name }}
  </mc-inline-button>
</div>