<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="reviewPackage$ | async as reviewPackage">
      <div class="mc-spring-board-header">
        <mc-review-package-avatar color="invert" [disabled]="true" [reviewPackage]="reviewPackage" [showName]="false"></mc-review-package-avatar>
      </div>

      <div class="mc-spring-board-body">
        <h3 class="text-x-large mc-profile-switch-board-bar-section">{{ reviewPackage.Name }}</h3>

        <ul class="mc-property-list mc-profile-switch-board-bar-section">
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Status: </span>
            <span class="mc-property-value">{{ reviewPackage.Status | mcReviewPackageStatusText }}</span>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Project: </span>
            <mc-project-avatar class="mc-property-value" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-project-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Branch: </span>
            <mc-branch-avatar class="mc-property-value" matDialogClose [branchName]="reviewPackage.BranchName" [project]="project$ | async" [showAvatar]="false" [truncate]="true"></mc-branch-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Commit ID: </span>
            <mc-commit-avatar class="mc-property-value" [commit]="reviewPackage.CommitId" [showAvatar]="false" [truncate]="true"></mc-commit-avatar>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Source: </span>
            <span class="mc-property-value">{{ reviewPackage.Source | mcReviewPackageSourceText }}</span>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">TOC: </span>
            <mc-toc-avatar *ngIf="reviewPackage.TocPath" class="mc-property-value" [branchName]="reviewPackage.BranchName" [project]="project$ | async" [showAvatar]="false" size="small" [tocPath]="reviewPackage.TocPath" [truncate]="true"></mc-toc-avatar>
            <i *ngIf="!reviewPackage.TocPath" class="mc-property-value">None</i>
          </li>
          <li class="mc-property-list-item-truncate">
            <span class="mc-property-name">Owner: </span>
            <mc-license-user-avatar class="mc-property-value" [licenseUser]="ownerLicenseUser$ | async" [showAvatar]="false" [truncate]="true"></mc-license-user-avatar>
          </li>
        </ul>
      </div>

      <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
        <a href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Overview" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-overview" color="primary"></mat-icon> Overview
        </a>
        <a *ngIf="showSettingsTab$ | async" href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Settings" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-pen" color="primary"></mat-icon> Settings
        </a>
        <a href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Files" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-files-o" color="primary"></mat-icon> Files
        </a>
        <a href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Reviewers" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-team" color="primary"></mat-icon> Reviewers
        </a>
        <a *ngIf="showActivityTab" href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Activity" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-clock" color="primary"></mat-icon> Activity
        </a>
        <a *ngIf="showDeleteTab$ | async" href="#" mat-list-item [mcProfileTab]="ReviewPackageProfileTab.Delete" [(mcProfileTabSelected)]="profileTab">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-trash" color="primary"></mat-icon> Delete
        </a>

        <ng-container *ngIf="reviewPackage | mcReviewPackageLink as reviewPackageLink">
          <mat-divider></mat-divider>
          <a mat-list-item matDialogClose [routerLink]="reviewPackageLink">
            <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-review" color="primary"></mat-icon> Open Review Package
          </a>
        </ng-container>
      </mat-nav-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="profileTab" class="mc-spring-board">
    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Overview">
      <mat-tab label="Overview">
        <mc-review-package-overview [reviewPackage]="reviewPackage$ | async" [reviewPackageUser]="reviewPackageUser$ | async"></mc-review-package-overview>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Settings">
      <mat-tab label="Settings">
        <mc-review-package-settings-form [licenseId]="(reviewPackage$ | async)?.LicenseId" [reviewPackage]="reviewPackage$ | async" (cancel)="onCancel()" (saved)="onSettingsSaved()"></mc-review-package-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Files">
      <mat-tab label="Files">
        <mc-review-package-files-form [reviewPackage]="reviewPackage$ | async" (saved)="onFilesSaved()"></mc-review-package-files-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Reviewers">
      <mat-tab label="Reviewers">
        <mc-review-package-reviewers-form [reviewPackage]="reviewPackage$ | async" (saved)="onReviewersSaved()"></mc-review-package-reviewers-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Activity">
      <mat-tab label="Activity">
        <mc-review-package-notification-list [reviewPackageId]="(reviewPackage$ | async)?.Id"></mc-review-package-notification-list>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="ReviewPackageProfileTab.Delete">
      <mat-tab label="Delete">
        <mc-review-package-deletion-form [reviewPackage]="reviewPackage$ | async" (cancel)="onCancel()" (deleted)="onReviewDeleted()"></mc-review-package-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>