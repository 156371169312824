import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CommentBoxComponent } from '@portal-core/tasks/components/comment-box/comment-box.component';
import { TaskAssetModule } from '@portal-core/tasks/components/task-asset/task-asset.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TaskAssetModule
  ],
  declarations: [
    CommentBoxComponent
  ],
  exports: [
    CommentBoxComponent
  ]
})
export class CommentBoxModule { }
