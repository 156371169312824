import { Injectable } from '@angular/core';
import * as cssTree from 'css-tree';
import { AnyNode, Comment, Root, stringify } from 'postcss';
import scss from 'postcss-scss';

/** This service uses the 'postcss' and 'postcss-scss' plugins to parse/generate css content,
 * and the 'css-tree' plugin to validate css syntax and to parse css values. */
@Injectable({
  providedIn: 'root'
})
export class CssSyntaxService {

  /** Parses css content to node's tree.
   * Uses the 'postcss-scss' plugin that supports inline comments to parse css content. 
   */
  public parse(content: string): Root {
    if (content === null || typeof content === 'undefined')
      return null;
    return scss.parse(content, { map: true });
  }

  /** Generates css text for the specified css node.
   * @param css The css node to generate stylesheet text.
   * @param innerContent The flag specifying whether to get inner or full css text of the given node.
   * @param restoreInlineComments Pass 'true' to save inline comment formatting, otherwise inline comments would be formatted as block comments.
   */
  public generate(css: AnyNode, type: 'full' | 'inner' = 'full', restoreInlineComments: boolean = false): string {
    const innerContent = type === 'inner';
    let content = '';
    if (css) {
      stringify(css, (part: string, node?: AnyNode, partType?: 'start' | 'end') => {
        if (innerContent && partType)
          return;
        if (restoreInlineComments && this.isInlineComment(node)) {
          content += this.stringifyInlineComment(node as Comment);
          return;
        }
        content += part;
      });
    }
    return content;
  }

  /** Checks whether node is an inline comment. */
  public isInlineComment(node: AnyNode): boolean {
    return node ? node.type === 'comment' && node.raws.inline === true : false;
  }

  /** Transforms inline comments in css content into block comments. */
  public updateInlineComments(cssText: string): string {
    if (cssText?.includes('//')) {
      // parse content, scss plugin inside parse() function will updates inline comments automatically
      const root = this.parse(cssText);
      // stringify parsed css
      return this.generate(root);
    }
    return cssText;
  }

  /** Updates links in css text using provided 'updateLink' function. */
  public updateLinksInCss(cssText: string, updateLink: (link: string) => string): string {
    const root = this.parse(cssText);
    root.walkDecls((decl) => {
      decl.value = this.updateLinksInValue(decl.value, updateLink);
    });
    const text = this.generate(root, 'full', true);
    return text;
  }

  /** Validates syntax of css text. */
  public validateCssSyntax(cssText: string): Error {
    let error = null;
    try {
      cssTree.parse(cssText, {
        onParseError(err) {
          error = error || err;
        }
      });
    }
    catch (err) {
      error = error || err;
    }
    return error;
  }

  /** Encodes the specified link and wraps it into css 'url()' function. */
  public toCssUrl(link: string, encode: boolean = true): string {
    if (encode)
      link = this.encodeUrl(link);
    return `url('${link}')`;
  }

  /** Parses passed css value as link. Returns the parsed link if success, otherwise returns null. */
  public parseValueAsLink(value: string): string {
    const data = this.parseValue(value);
    const node = data?.children.size === 1 ? data.children.first : null;
    return this.parseNodeAsLink(node);
  }

  /** Updates links in the css property value using specified `updateLink()` function.
   * @returns The updated css property value.
   */
  public updateLinksInValue(value: string, updateLink: (link: string) => string): string {
    if (!value?.includes('url('))
      return value;
    const data = this.parseValue(value);
    if (data) {
      let result = '';
      data.children.forEach((node) => {
        if (node.type === 'Url') {
          if (node.value)
            node.value = updateLink(node.value);
          result += this.toCssUrl(node.value);
        } else {
          result += cssTree.generate(node);
        }
      });
      return result;
    }
    return value;
  }

  /** Encodes the specified link. */
  private encodeUrl(link: string): string {
    return link?.replaceAll('\'', '%27');
  }

  /** Decodes the specified link. */
  private decodeUrl(link: string): string {
    return link?.replaceAll('%27', '\'');
  }

  /** Returns a decoded node's value if the specified node has 'Url' type. Otherwise, returns null. */
  private parseNodeAsLink(node: cssTree.CssNode): string | null {
    return (node?.type === 'Url') ? this.decodeUrl(node.value) : null;
  }

  private parseValue(value: string): cssTree.Value {
    const data = cssTree.parse(value, { context: 'value' });
    return data.type === 'Value' ? data : null;
  }

  /** Returns text representation of the inline comment.  */
  private stringifyInlineComment(node: Comment): string {
    return '//' + node.raws.left + node.text + node.raws.right;
  }

}
