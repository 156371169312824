import { Fragment, Node } from 'prosemirror-model';

// Returns the depth of a fragment by descending down the first child of each fragment/node until the bottom is reached
export function depthAtStartOfFragment(fragment: Fragment): number {
  let depth = 0;
  let node = fragment.firstChild;

  while (node) {
    depth += 1;
    node = node.firstChild;
  }

  return depth;
}

// Returns the depth of the deepest non-text node from the first child in a fragment
export function depthInFirstChildOfFragment(fragment: Fragment): number {
  let depth = 0;
  let node = fragment.firstChild;

  while (node) {
    node = node.firstChild;

    if (node && !node.isLeaf) {
      depth += 1;
    }
  }

  return depth;
}

// Returns the depth of the deepest non-text node from the last child in a fragment
export function depthInLastChildOfFragment(fragment: Fragment): number {
  let depth = 0;
  let node = fragment.lastChild;

  while (node) {
    node = node.lastChild;

    if (node && !node.isLeaf) {
      depth += 1;
    }
  }

  return depth;
}

export interface FragmentNodeInfo {
  from: number;
  node: Node;
  to: number;
}

// Returns the node and its range for a position in a fragment
export function getNodeInfoFromFragmentAtPos(fragment: Fragment, pos: number, doc: Node): FragmentNodeInfo {
  const depth = depthInFirstChildOfFragment(fragment);
  const $pos = doc.resolve(pos);
  const relativeDepth = $pos.depth - depth;

  return {
    node: $pos.node(relativeDepth),
    from: $pos.start(relativeDepth) - 1, // -1 to include the position that is the start of the node itself (the node's start token)
    to: $pos.end(relativeDepth) + 1 // +1 to include the position that is the end of the node itself (the node's end token)
  };
}

/**
 * Returns the first leaf node in a fragment
 * @param fragment The fragment to search
 * @returns The first leaf node in the fragment
 */
export function getFirstLeafNodeInFragment(fragment: Fragment): Node {
  let node = fragment.firstChild;

  while (node?.firstChild) {
    node = node.firstChild;
  }

  return node;
}
