import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { DynamicViewModule } from '@portal-core/text-editor/components/dynamic-view/dynamic-view.module';
import { RichTextEditorToolbarModule } from '@portal-core/text-editor/components/rich-text-editor-toolbar/rich-text-editor-toolbar.module';
import { SoloFileTextEditorModule } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.module';
import { RichTextEditorComponent } from './rich-text-editor.component';

@NgModule({
  imports: [
    CommonModule,
    DynamicViewModule,
    MatSnackBarModule,
    RichTextEditorToolbarModule,
    ScrollingModule,
    SoloFileTextEditorModule
  ],
  declarations: [
    RichTextEditorComponent
  ],
  exports: [
    RichTextEditorComponent
  ]
})
export class RichTextEditorModule { }
