import { ChangeDetectionStrategy, Component, ContentChild, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { SortDirection } from '@common/paged-data/types/sort-direction.type';
import { cache } from '@common/util/cache.operator';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { GridColumnsDialogComponent, GridColumnsDialogData, GridColumnsDialogResult } from '@portal-core/ui/grid/components/grid-columns-dialog/grid-columns-dialog.component';
import { GridComponent } from '@portal-core/ui/grid/components/grid/grid.component';
import { ContextMenuItemsDirective } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.directive';
import { EmptyGridDirective } from '@portal-core/ui/grid/directives/empty-grid/empty-grid.directive';
import { ExpandedRowDetailDirective } from '@portal-core/ui/grid/directives/expanded-row-detail/expanded-row-detail.directive';
import { GridCellDirective } from '@portal-core/ui/grid/directives/grid-cell/grid-cell.directive';
import { GridHeaderMenuDirective } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.directive';
import { VisibleColumns } from '@portal-core/ui/grid/types/visible-columns.type';
import { DataGridControl } from '@portal-core/ui/grid/util/data-grid-control';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { MediaQueryString } from '@portal-core/util/types/media-query-string.type';
import { Observable, Subscription, combineLatest, filter, map, of, switchMap } from 'rxjs';

/**
 * DataGridComponent
 * Connects a collection data store to an mc-grid component.
 */
@Component({
  selector: 'mc-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class DataGridComponent<T extends { Id: number | string }> implements OnInit, OnDestroy, Filterable {
  /**
   * The media query at which to switch the grid into a single column card based view.
   * @deprecated
   * */
  @Input() cardBreakpoint?: MediaQueryString;
  /** A dictionary of column names with Dictionary values. Where those dictionary values map to input names used by components in the grid headers.  */
  @Input() columnInputs?: Dictionary<Dictionary<any>>;
  /** An array of columns names that are displayed by default. */
  @Input() defaultVisibleColumns?: VisibleColumns = 'all';
  /** Whether the grid has expandable rows. */
  @Input() expandableRows?: boolean = false;
  /** The DataGridControl for the grid which defines the columns, collection data store, and method to fetch items. */
  @Input() gridControl: DataGridControl;
  /** The unique id for the grid. Used for differentiating grids that belong to the same collection and persisting grid configuration across page loads. */
  @Input() gridId: string;
  /** Whether the grid has indeterminate pages. Changes the controls in the grid's paginators. */
  @Input() indeterminatePages?: boolean = false;
  /** The most recent number of days to limit the grid data to. */
  @Input() lastDays?: number;
  /** The most recent number of top rows to limit the grid data to. */
  @Input() topRows?: number;
  /** Whether or not expandable rows are rendered only at the point of being expanded. */
  @Input() lazyLoadExpandableRows?: boolean = false;
  /** The mode of paging to use for the grid. Only use `cursor` if the backend for this grid requires a cursor to get the items. */
  @Input() pagingMode?: 'cursor' | 'index' = 'index';
  /** Provides a method that is called for each row that adds the 'mc-grid-row-disabled' CSS class to the row when the method returns true. */
  @Input() rowDisabledPredicate?: (item: T) => boolean;
  /** Whether the grid scrolls horizontally when its content is wider than the grid. */
  @Input() scrollHorizontally?: boolean = true;
  /** Whether there is a checkbox column in the grid. */
  @Input() selectable?: boolean = false;
  /**
   * Selects the item with the given id.
   * @deprecated Use replaceSelectionById instead.
   * */
  @Input() selectedItemId?: number | string;
  /** Whether or not to show the last days dropdown. */
  @Input() showLastDays?: boolean = false;
  /** Whether or not to show the top rows dropdown. */
  @Input() showTopRows?: boolean = false;
  /** Whether the border is shown between rows. */
  @Input() showRowBorder?: boolean = true;

  /** Emits an array of the selected items whenever the selected items changes. */
  @Output() selectedRowsChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  /** Emits after the grid's data is loaded. */
  @Output() dataLoaded: EventEmitter<void> = new EventEmitter<void>();

  /** Emits the selected last days whenever it changes. */
  @Output() lastDaysChange: EventEmitter<number> = new EventEmitter<number>();

  /** Emits the selected last top rows whenever it changes. */
  @Output() topRowsChange: EventEmitter<number> = new EventEmitter<number>();

  /** The underlying mc-grid component. */
  @ViewChild(GridComponent, { static: true }) grid: GridComponent<T>;

  /** The grid's header menu directives which are given to mc-grid. */
  @ContentChildren(GridHeaderMenuDirective) gridHeaderMenuDirectives: QueryList<GridHeaderMenuDirective>;
  /** The grid's cell directives which are given to mc-grid. */
  @ContentChildren(GridCellDirective) gridCellDirectives: QueryList<GridCellDirective>;
  /** The grid's context menu directive which is given to mc-grid. */
  @ContentChild(ContextMenuItemsDirective) contextMenuItemsDirective: ContextMenuItemsDirective;
  /** The grid's empty panel directives which is given to mc-grid. */
  @ContentChildren(EmptyGridDirective) emptyGridDirectives: QueryList<EmptyGridDirective>;
  /** The grid's expanded row directive which is given to mc-grid. */
  @ContentChild(ExpandedRowDetailDirective) expandedRowDetailDirective: ExpandedRowDetailDirective;

  /** An observable of the defaultVisibleColumns used for composing streams. */
  @PropertyObservable('defaultVisibleColumns') defaultVisibleColumns$: Observable<VisibleColumns>;
  /** An observable of the DataGridControl used for composing streams. */
  @PropertyObservable('gridControl') gridControl$: Observable<DataGridControl>;
  /** An observable of the gridId used for composing streams. */
  @PropertyObservable('gridId') gridId$: Observable<string>;
  /** An observable of the pageIndex used for composing streams. */
  @PropertyObservable('pageIndex') pageIndex$: Observable<number>;

  // SubscriptionProperty is used to ensure that any pending loads are cancelled when a new load is initiated
  @SubscriptionProperty() loadSubscription: Subscription;

  /** The config for the grid's filters. */
  filterConfig: PageFilterConfigBase;
  /** A subscription to the grid control observable used to unsubscribe when the component is destroyed. */
  filterConfigSubscription: Subscription;
  /** The grid's filters that are streamed from the grid's collection data store to mc-grid. */
  filters$: Observable<Dictionary<PageFilterGroup>>;
  /** A subscription to the hard reload observable used to unsubscribe when the component is destroyed. */
  hardReloadSubscription: Subscription;
  /** The grid's items that are streamed from the grid's collection data store to mc-grid. */
  items$: Observable<T[]>;
  /** The grid's total number of items across pages that is streamed from the grid's collection data store to mc-grid. */
  itemTotal$: Observable<number>;
  /** If the count of the total number of items was limited then this is the count it was limited to. The count can be limited on the server for performance reasons.  */
  itemLimitedTotal$: Observable<number>;
  /** Keeps track of the loading and error state of the grid. */
  loadingState: LoadingState<string> = new LoadingState<string>();
  /** The grid's sort order that is streamed from the grid's collection data store to mc-grid. */
  order$: Observable<PageSort>;
  /** The current page index of the grid. */
  pageIndex: number = 0;
  /** A subscription to the page index reset observable used to unsubscribe when the component is destroyed. */
  pageIndexResetSubscription: Subscription;
  /** The grid's page size that is streamed from the grid's collection data store to mc-grid. */
  pageSize$: Observable<number>;
  /** A subscription to the reload observable used to unsubscribe when the component is destroyed. */
  reloadSubscription: Subscription;
  /** The grid's visible columns that is streamed from the grid's collection data store and default visible columns to mc-grid. */
  visibleColumns$: Observable<VisibleColumns>;

  /** Whether the grid's context menu is open. */
  get contextMenuOpen(): boolean {
    return this.grid && this.grid.contextMenu && this.grid.contextMenu.menuOpen;
  }

  /** The items on the current page of the grid.  */
  get items(): T[] {
    if (this.grid) {
      return this.grid.items;
    }
  }

  /** The total number items in the grid across all pages.  */
  get itemTotal(): number {
    if (this.grid) {
      return this.grid.itemCount;
    } else {
      return 0;
    }
  }

  /** The currently selected items in the grid. */
  get selectedItems(): T[] {
    if (this.grid) {
      return this.grid.selectedItems;
    } else {
      return [];
    }
  }

  /** The number of items currently selected in the grid. */
  get selectedItemCount(): number {
    return this.grid && this.grid.selection && this.grid.selection.selected ? this.grid.selection.selected.length : 0;
  }

  /**
   * constructor
   * @param errorService Used to get error messages when data fails to load.
   */
  constructor(private dialog: MatDialog, private errorService: ErrorService) { }

  /**
   * Sets up the observables used to stream data to the underlying mc-grid component.
   * Also sets up subscriptions for (re)loading the data.
   */
  ngOnInit() {
    // Make an observable of the items to render
    this.items$ = combineLatest([
      this.gridId$,
      this.gridControl$,
      this.pageIndex$
    ]).pipe(
      switchMap(([gridId, gridControl, pageIndex]: [string, DataGridControl, number]) => {
        if (gridId && gridControl && typeof pageIndex === 'number') {
          return gridControl.collectionService.getGridItems$(gridId, pageIndex);
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Make an observable of the total number of items across all pages in the grid
    this.itemTotal$ = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      switchMap(([gridId, gridControl]) => gridControl ? gridControl.collectionService.getGridTotal$(gridId) : of(null)),
      cache()
    );

    // Make an observable of the limited total number of items across all pages in the grid
    this.itemLimitedTotal$ = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      switchMap(([gridId, gridControl]) => gridControl ? gridControl.collectionService.getGridLimitedTotal$(gridId) : of(null)),
      cache()
    );

    // Make an observable of the grid's filters
    this.filters$ = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      switchMap(([gridId, gridControl]) => gridControl ? gridControl.collectionService.getGridFilters$(gridId) : of(null)),
      cache()
    );

    // Make an observable of the grid's sort order
    this.order$ = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      switchMap(([gridId, gridControl]) => gridControl ? gridControl.collectionService.getGridOrder$(gridId) : of(null)),
      cache()
    );

    // Make an observable of the grid's page size
    this.pageSize$ = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      switchMap(([gridId, gridControl]) => gridControl ? gridControl.collectionService.getGridPageSize$(gridId) : of(null)),
      cache()
    );

    // Make an observable of the grid's visible columns
    this.visibleColumns$ = combineLatest([
      this.gridId$,
      this.gridControl$,
      this.defaultVisibleColumns$
    ]).pipe(
      switchMap(([gridId, gridControl, defaultVisibleColumns]) => {
        if (gridControl) {
          return gridControl.collectionService.getGridVisibleColumns$(gridId).pipe(
            map(visibleColumns => {
              let columnsToShow = Array.isArray(visibleColumns) ? visibleColumns : defaultVisibleColumns;

              // Remove any visible column names that do not exist in the grid's columns.
              // This might happen if the columns in a grid change in a future update and the user has a removed column saved in their visible column config
              if (Array.isArray(columnsToShow)) {
                columnsToShow = columnsToShow.filter(visibleColumnName => !!gridControl.columns.find(column => column.name === visibleColumnName));
              }

              return columnsToShow;
            })
          );
        } else {
          return of(null);
        }
      }),
      cache()
    );

    this.filterConfigSubscription = this.gridControl$.subscribe(gridControl => {
      this.filterConfig = gridControl?.filterConfig;
    });

    // Listen to grid id/control and filter changes to reset the page index to zero
    this.pageIndexResetSubscription = combineLatest([
      this.gridId$,
      this.gridControl$,
      this.filters$
    ]).subscribe(() => {
      this.pageIndex = 0;
    });

    // Listen to the grid id/control and its filters/sort changes to hard reload the data
    this.hardReloadSubscription = combineLatest([
      this.gridId$,
      this.gridControl$
    ]).pipe(
      filter(([gridId, gridControl]) => !!gridId && !!gridControl),
      switchMap(([gridId, gridControl]) => {
        return combineLatest([
          gridControl.collectionService.getGridFilters$(gridId),
          gridControl.collectionService.getGridOrder$(gridId),
          gridControl.collectionService.getGridPageSize$(gridId)
        ]);
      })
    ).subscribe(() => {
      this.loadItems(true);
    });

    // Listen to page index changes to reload the data
    this.reloadSubscription = this.pageIndex$.subscribe(() => {
      this.loadItems();
    });
  }

  /**
   * Removes the grid's data.
   * */
  ngOnDestroy() {
    // Clean up the data. It is no longer needed.
    if (this.gridId && this.gridControl) {
      this.gridControl.collectionService.clearGrid$(this.gridId);
    }
  }

  /** The pageChange event handler. Updates the grid's collection data store with the new page size and page index. */
  onPageChanged(event: PageEvent) {
    // If the page size changed then change to the first page
    this.pageIndex = event.pageSize !== this.gridControl.collectionService.getGridPageSize(this.gridId) ? 0 : event.pageIndex;

    this.gridControl.collectionService.setGridPageSize$(this.gridId, event.pageSize);
  }

  /** The sortChange event handler. Updates the grid's collection data store with the new sort order. */
  onSortChanged(event: Sort) {
    this.gridControl.collectionService.setGridOrder$(this.gridId, event.active, event.direction);
  }

  /** The filtersCleared event handler. Clears out the filters in the grid's collection data store. */
  onFiltersCleared() {
    this.gridControl.collectionService.clearGridFilters$(this.gridId);
    this.gridControl.collectionService.setGridOrder$(this.gridId, undefined, undefined);

    if (typeof this.gridControl.clearGridFilters === 'function') {
      this.gridControl.clearGridFilters();
    }
  }

  /** The lastDaysChange event handler. Re-emits the event. */
  onLastDaysChanged(event: number) {
    this.lastDaysChange.emit(event);
  }

  /** The topRowsChange event handler. Re-emits the event. */
  onTopRowsChanged(event: number) {
    this.topRowsChange.emit(event);
  }

  /** The selectedRowsChange event handler. Re-emits the event. */
  onSelectedRowsChange(event: T[]) {
    this.selectedRowsChange.emit(event);
  }

  /** The retry event handler. Reloads the grid. */
  onRetryClicked() {
    this.loadItems();
  }

  /** Clears the current selection of items. */
  clearSelection() {
    if (this.selectable && this.grid) {
      this.grid.clearSelection();
    }
  }

  /**
   * Closes the header menu on the grid.
   * @param columnName Optionally provide the column name to only close the menu for that column.
   */
  closeHeaderMenu(columnName?: string) {
    if (this.grid) {
      this.grid.closeHeaderMenu(columnName);
    }
  }

  /**
   * Deselects the given items from the grid.
   * @param items The items to deselect.
   */
  deselectItems(...items: T[]) {
    if (this.selectable && this.grid) {
      this.grid.deselectItems(...items);
    }
  }

  /** Reloads the grid by fetching new data from the server. */
  hardReload() {
    this.loadItems(true);
  }

  /** Opens the grid's columns dialog for configuring the grid's columns. */
  openColumnsDialog() {
    if (this.gridId && this.gridControl) {
      this.dialog.open<GridColumnsDialogComponent, GridColumnsDialogData, GridColumnsDialogResult>(GridColumnsDialogComponent, {
        ...GridColumnsDialogComponent.DialogConfig,
        data: {
          columns: this.gridControl.columns,
          visibleColumns: this.gridControl.collectionService.getGridVisibleColumns(this.gridId) || this.defaultVisibleColumns
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.gridControl.collectionService.setGridVisibleColumns$(this.gridId, result.visibleColumns);
        }
      });
    }
  }

  /**
   * Clears out the current selection and selects the given items by id.
   * @param itemIds The item ids to select.
   */
  replaceSelectionById(...itemIds: (number | string)[]) {
    if (this.selectable && this.grid) {
      this.grid.replaceSelectionById(...itemIds);
    }
  }

  /**
   * Clears the items in the grid and then loads the first page of items.
   * @param hardReload Whether or not to force a request to get the items from the API server. Defaults to false.
   */
  protected loadItems(hardReload: boolean = false) {
    if (this.gridId) {
      this.loadingState.update(true);

      // Store the left scroll position to restore after the data has loaded. This is for cases like filtering the grid and maintaining the scroll position after the data loads
      const leftScrollPosition = this.grid.leftScrollPosition;

      // Get the cursor to load this page of items. The cursor for this page of items is stored in the preceding page. This is because a page stores the cursor for the next page.
      const cursor = this.pagingMode === 'cursor' ? this.gridControl.collectionService.getGridCursor(this.gridId, this.pageIndex - 1) : undefined;

      this.loadSubscription = (hardReload ? this.gridControl.collectionService.clearGrid$(this.gridId) : of(null)).pipe(
        switchMap(() => this.gridControl.collectionService.loadGridItems$(this.gridId, this.pageIndex, this.gridControl.collectionService.getGridPageSize(this.gridId), cursor, {
          fetch: pageFilter => this.gridControl.fetchGridPage$(pageFilter)
        }, {
          forceApiRequest: hardReload
        }))
      ).subscribe(() => {
        // Wait for the dom to 'settle'
        setTimeout(() => {
          // Restore the scroll position now that the data has rendered
          this.grid.leftScrollPosition = leftScrollPosition;
          this.loadingState.update(false);
          this.dataLoaded.emit();
        }, 0);
      }, error => {
        this.loadingState.update(false, 'Unable to load the grid.', this.errorService.getErrorMessages(error));
      });
    }
  }

  /** Sets a page filter in the grid's collection data store. */
  applyFilter$(name: string, filterGroup: PageFilterGroup): Observable<any> {
    return this.gridControl?.collectionService?.setGridFilterByName$(this.gridId, name, filterGroup);
  }

  /** Gets a page filter from the grid's collection data store. */
  getFilter(name: string): PageFilterGroup {
    return this.gridControl?.collectionService?.getGridFilterByName(this.gridId, name);
  }

  /** Gets an observable of the page filter from the grid's collection data store. */
  getFilter$(name: string): Observable<PageFilterGroup> {
    return this.gridControl?.collectionService?.getGridFilterByName$(this.gridId, name);
  }

  /** Gets an observable of the page filters from the grid's collection data store. */
  getFilters$(): Observable<Dictionary<PageFilterGroup>> {
    return this.gridControl?.collectionService?.getGridFilters$(this.gridId);
  }

  /** Gets the sort order from the grid's collection data store. */
  getOrder(): PageSort {
    return this.gridControl?.collectionService?.getGridOrder(this.gridId);
  }

  /** Gets an observable of the sort order from the grid's collection data store. */
  getOrder$(): Observable<PageSort> {
    return this.gridControl?.collectionService?.getGridOrder$(this.gridId);
  }

  /** Sets the sort order in the grid's collection data store. */
  setOrder$(orderBy: string, orderDir: SortDirection): Observable<any> {
    return this.gridControl?.collectionService?.setGridOrder$(this.gridId, orderBy, orderDir);
  }
}
