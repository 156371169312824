import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mc-notification-message-wrapper',
  templateUrl: './notification-message-wrapper.component.html',
  styleUrls: ['./notification-message-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageWrapperComponent {
  @Input() wrapper: string;
  @Input() text: string;
}
