import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Project } from '@portal-core/projects/models/project.model';

@CollectionStateSelectors({
  stateType: ProjectsState
})
@State<ProjectsState>({
  name: ProjectsState.source
})
@Injectable()
export class ProjectsState extends CollectionStateBase {
  static source = 'Projects';
  getSource(): string {
    return ProjectsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsDataService extends CollectionDataServiceBase<Project> {
  constructor(protected store: Store) {
    super(store, ProjectsState);
  }
}
