import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamDeletionFormComponent } from './team-deletion-form.component';
import { FormsModule } from '@angular/forms';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
  declarations: [TeamDeletionFormComponent],
  exports:[
    TeamDeletionFormComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule
  ]
})
export class TeamDeletionFormModule { }
