<ng-container *ngIf="filterList$ | async as filterList">
  <div *ngIf="filterList.length > 0" class="mc-filter-bar-wrapper mc-border-divider">
    <div matSubheader class="mc-text-list-subheader">Active Filters</div>
    <mat-chip-list aria-label="Active Filters" class="mc-filter-bar-chip-list">
      <mat-chip *ngFor="let filterListItem of filterList" #chip class="mc-filter-bar-chip" [removable]="true" [selectable]="false" (mcClick)="onFilterChipRemoved(filterListItem)" (removed)="onFilterChipRemoved(filterListItem)">
        <div>
          <span>{{ filterListItem.title }}<ng-container *ngIf="filterListItem.value">:&nbsp;</ng-container></span>
          <span class="mc-filter-bar-item-value" [ngSwitch]="filterListItem.type">
            <ng-container *ngSwitchCase="PageFilterGroupType.Date">
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.LessThan">Before</ng-container>
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.GreaterThan">After</ng-container>
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.Between">Between</ng-container>
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.Today">Today</ng-container>

              <ng-container *ngIf="filterListItem.filterType !== PageFilterType.Today">
                {{ filterListItem.value | date: 'MMM dd, yyyy' }}
              </ng-container>

              <ng-container *ngIf="filterListItem.filterType === PageFilterType.Between">
                and {{ filterListItem.value2 | date: 'MMM dd, yyyy' }}
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="PageFilterGroupType.Number">
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.LessThan">Less than</ng-container>
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.GreaterThan">Greater than</ng-container>
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.Between">Between</ng-container>
              {{ filterListItem.value }}
              <ng-container *ngIf="filterListItem.filterType === PageFilterType.Between">and {{ filterListItem.value2
                }}</ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="PageFilterGroupType.Search">
              {{ filterListItem.value }}
            </ng-container>

            <ng-container *ngSwitchCase="PageFilterGroupType.Select">
              <ng-container *ngFor="let selectItem of filterListItem.value; first as isFirst; last as isLast">
                <ng-container *ngIf="!isFirst">,&nbsp;</ng-container>
                <mc-project-avatar *ngIf="filterListItem.autocomplete === 'projects' || filterListItem.autocomplete === 'review-projects'" [disabled]="true" [project]="selectItem | mcProjectAsync: true | async" [showAvatar]="false" size="small"></mc-project-avatar>
                <span *ngIf="filterListItem.selectOptions">{{ getSelectValueDisplayText(selectItem,
                  filterListItem.selectOptions) }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="PageFilterGroupType.String">
              {{ filterListItem.value }}
            </ng-container>

            <ng-container *ngSwitchCase="PageFilterGroupType.Custom">
              {{ filterListItem.value }}
            </ng-container>
          </span>
        </div>

        <mat-icon matChipRemove fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-container>