import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { NotificationListModule } from '@portal-core/notifications/components/notification-list/notification-list.module';
import { ProjectNotificationListComponent } from './project-notification-list.component';

@NgModule({
  declarations: [
    ProjectNotificationListComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    NotificationListModule
  ],
  exports: [
    ProjectNotificationListComponent
  ]
})
export class ProjectNotificationListModule { }
