import { BACKSPACE, ENTER } from '@angular/cdk/keycodes';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { toggleMultipleChoiceItem } from '@common/prosemirror/transform/multiple-choice';
import { MultipleChoiceItemNodeViewComponent } from '@portal-core/text-editor/components/multiple-choice-item-node-view/multiple-choice-item-node-view.component';
import { ComponentNodeView } from '@portal-core/text-editor/node-views/component.node-view';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { Decoration, EditorView } from 'prosemirror-view';

export class MultipleChoiceItemNodeView extends ComponentNodeView {
  get componentInstance(): MultipleChoiceItemNodeViewComponent {
    return this.component?.componentRef?.instance as MultipleChoiceItemNodeViewComponent;
  }

  constructor(
    node: ProseMirrorNode,
    editorView: EditorView,
    getPos: GetPosForNodeView,
    decorations: Decoration[],
    create: DynamicViewComponentInjector
  ) {
    super(MultipleChoiceItemNodeViewComponent, node, editorView, getPos, decorations, create);
    this.contentDOM = this.componentInstance.contentElementRef.nativeElement;
  }

  onComponentCreated() {
    this.outputs.checkFocus = this.onCheckFocus.bind(this);
    this.outputs.toggleChecked = this.toggleChecked.bind(this);
  }

  updateComponent() {
    this.inputs.checked = !!this.node.attrs['MadCap:correctAnswer'];
    this.inputs.checkDisabled = !this.editorView.props?.editable(this.editorView.state) ?? false;
  }

  stopEvent(event: Event): boolean {
    // If the DOM event is from the checkboxes or radio buttons then we might want to stop them
    if (this.componentInstance.customElementRef.nativeElement.contains(event.target)) {
      // Allow most key events so that ProseMirror can still do things like undo/redo.
      // Do not let ProseMirror handle enter/backspace as it will run those commands on the doc which is weird when the checkbox/radio button has focus.
      if (event.type === 'keydown' && (event as KeyboardEvent).keyCode !== ENTER && (event as KeyboardEvent).keyCode !== BACKSPACE) {
        return false;
      }

      // Allow events that will let selection/focus be handled by ProseMirror so that ProseMirror knows it no longer has focus outside of the node view
      if (event.type.includes('mouse') || event.type.includes('focus')) {
        return false;
      }

      // Stop all other DOM events that occur
      return true;
    } else {
      return false;
    }
  }

  ignoreMutation(mutation: MutationRecord): boolean {
    // Ignore DOM mutations that occur within the custom elements of the node view (checkbox and radio button)
    return this.componentInstance.customElementRef.nativeElement.contains(mutation.target);
  }

  private onCheckFocus() {
    // This is primarily done to put the selection in the correct place when undo/redo is done in the editor. Otherwise the editor will scroll to wherever the selection was outside the nodeview
    this.editorView.dispatch(this.editorView.state.tr.setSelection(TextSelection.near(this.editorView.state.doc.resolve(this.getPos() + 1))));
  }

  private toggleChecked() {
    this.editorView.state.doc.resolve(this.getPos());

    const resolvePosInfo = resolvedPosFind(this.editorView.state.doc.resolve(this.getPos()), node => node.type.name === 'madcapmultiplechoice');

    if (resolvePosInfo.node) {
      const tr = this.editorView.state.tr;
      toggleMultipleChoiceItem(tr, this.node, this.getPos(), resolvePosInfo.node, resolvePosInfo.$nodePos.pos);

      if (tr.docChanged) {
        this.editorView.dispatch(tr.scrollIntoView());
      }
    }
  }
}
