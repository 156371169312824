<img *ngIf="imgSrc" [src]="imgSrc" [alt]="alt" [title]="title" class="mc-image-node-view-img" [ngClass]="hasChanges ? 'mc-node-view-pane' : ''" [style.height]="imageHeight" [style.width]="imageWidth">

<span *ngIf="!src" class="mc-image-node-view-no-src mc-node-view-pane">
  Empty image
</span>
<span *ngIf="loadingState.generalError$ | async as generalError" class="mc-image-node-view-error mc-node-view-pane">
  {{ generalError }}
</span>
<span *ngIf="loadingState.loading$ | async" class="mc-image-node-view-loading mc-node-view-pane">
  {{ alt || title || 'Loading...' }}
</span>
