import { Injectable } from '@angular/core';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { Store, State } from '@ngxs/store';

@CollectionStateSelectors({
  stateType: TaskBoardsState
})
@State<TaskBoardsState>({
  name: TaskBoardsState.source
})
@Injectable()
export class TaskBoardsState extends CollectionStateBase {
  static source: string = 'TaskBoards';
  getSource(): string {
    return TaskBoardsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskBoardsDataService extends CollectionDataServiceBase<TaskBoard> {

  constructor(protected store: Store) {
    super(store, TaskBoardsState);
   }
}
