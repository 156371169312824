import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { StringService } from '@portal-core/general/services/string.service';

@Component({
  selector: 'mc-rich-text-block',
  templateUrl: './rich-text-block.component.html',
  styleUrls: ['./rich-text-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextBlockComponent {
  @Input() content: string;
  @Input() mode?: 'rich' | 'plain' | 'inline' | 'truncate' = 'rich';

  @HostBinding('class.mc-rich-text-block-border')
  @Input() showBorder?: boolean = false;

  @HostBinding('class.mc-border-toolbar') borderToolbarClass: boolean = true;

  get tooltip(): string {
    return typeof this.content === 'string' ? this.stringService.stripHTMLTags(this.content) : '';
  }

  constructor(private stringService: StringService) { }
}
