import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ContentSecurityPolicy } from '@portal-core/sites/models/content-security-policy.model';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SiteTeam } from '@portal-core/sites/models/site-team.model';
import { SiteUpdate } from '@portal-core/sites/models/site-update.model';
import { SiteUrl } from '@portal-core/sites/models/site-url.model';
import { Site } from '@portal-core/sites/models/site.model';
import { UserSite } from '@portal-core/sites/models/user-site.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SitesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getLiveSiteUrlTemplate$(): Observable<string> {
    return this.http.get<string>(`${this.apiService.centralApiBaseUri}/api/sites/GetLiveSiteUrlTemplate`);
  }

  @SharePendingRequest()
  getSiteById$(siteId: number): Observable<Site> {
    return this.http.get<Site>(`${this.apiService.centralApiBaseUri}/api/sites/${siteId}`);
  }

  @SharePendingRequest()
  getContentSecurityPolicyById$(contentSecurityPolicyId: number): Observable<ContentSecurityPolicy> {
    return this.http.get<ContentSecurityPolicy>(`${this.apiService.centralApiBaseUri}/api/sites/GetContentSecurityPolicy/${contentSecurityPolicyId}`);
  }

  @SharePendingRequest()
  getSiteGridItem$(siteId: number): Observable<SiteGridItem> {
    return this.http.get<SiteGridItem>(`${this.apiService.centralApiBaseUri}/api/sites/GetSiteGridItem/${siteId}`);
  }

  @SharePendingRequest()
  getSitesByIds$(sitesIds: number[]): Observable<Site[]> {
    return this.http.post<Site[]>(`${this.apiService.centralApiBaseUri}/api/sites/getBatchSitesByIds`, sitesIds);
  }

  @SharePendingRequest()
  getSitesByTeamIds$(teamIds: number[]): Observable<Site[]> {
    return this.http.post<Site[]>(`${this.apiService.centralApiBaseUri}/api/sites/TeamSites`, teamIds);
  }


  @SharePendingRequest()
  getSitesPageByLicenseList$(licenseId: number, filter: PageFilter): Observable<Page<SiteGridItem>> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/sites/License/${licenseId}/GetSitesByLicenseList`, filter);
  }

  @SharePendingRequest()
  getSitesByLicenseId$(licenseId: number): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.apiService.centralApiBaseUri}/api/sites/License/${licenseId}`);
  }

  @SharePendingRequest()
  getSiteListItemsByLicenseId$(licenseId: number): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/SitesList`);
  }

  @SharePendingRequest()
  getSitesListByLicenseHostMapId$(licenseHostMapId: number): Observable<Site[]> {
    throw new Error('SitesService.getSitesListByLicenseHostMapId$ fetch not implemented.');
  }

  updateSiteTeams$(siteId: number, siteTeams: SiteTeam[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/UpdateSiteTeams/${siteId}`, siteTeams);
  }

  @SharePendingRequest()
  getSiteIndexUrl$(siteKey: string): Observable<string> {
    return this.http.get<MadCloudResult<string>>(`${this.apiService.centralApiBaseUri}/api/Sites/${siteKey}/IndexUrl`).pipe(
      map(result => result.Result)
    );
  }

  @SharePendingRequest()
  getSitesListByBuildId$(buildId: number): Observable<Site[]> {
    throw new Error('SitesService.getSitesListByBuildId$ fetch not implemented.');
  }

  @SharePendingRequest()
  getCurrentUserSites$(licenseId: number, liveSitesOnly: boolean): Observable<UserSite[]> {
    return this.http.get<MadCloudResult<UserSite[]>>(`${this.apiService.centralApiBaseUri}/api/Sites/GetSitesForLicenseUser/${licenseId}/${!liveSitesOnly}`).pipe(
      map(result => result.Result)
    );
  }

  @SharePendingRequest()
  getSiteUrlsPageByLicenseId$(licenseId: number, getDeleted: boolean, filter: PageFilter): Observable<Page<SiteUrl>> {
    return this.http.post<Page<SiteUrl>>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/GetSiteUrlsByLicenseList?getDeleted=${getDeleted}`, filter);
  }

  @SharePendingRequest()
  getContentSecurityPoliciesByLicenseId$(licenseId: number): Observable<ContentSecurityPolicy[]> {
    return this.http.get<ContentSecurityPolicy[]>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/ContentSecurityPolicies`);
  }


  @SharePendingRequest()
  getContentSecurityPoliciesPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<ContentSecurityPolicy>> {
    return this.http.post<Page<ContentSecurityPolicy>>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/GetContentSecurityPolicyGrid`, filter);
  }


  @SharePendingRequest()
  getLicenseId$(siteKey: string): Observable<number> {
    return this.http.get<number>(`${this.apiService.centralApiBaseUri}/api/Sites/${siteKey}/LicenseId`);
  }

  createSite$(licenseId: number, siteSettings: Partial<SiteUpdate>): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/License/${licenseId}/Create`, siteSettings);
  }

  updateSiteSettings$(siteSettings: Partial<SiteUpdate>): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/Update`, siteSettings);
  }

  setSitesAsLive$(siteIds: number[], live: boolean): Observable<MadCloudResult> {
    if (live) {
      return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/SetLiveSite`, siteIds);
    } else {
      return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/CancelLiveSite`, siteIds);
    }
  }

  deleteSites$(siteIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/DeleteSites`, siteIds);
  }

  @SharePendingRequest()
  getVanityValidation$(licenseId: number, vanity: string, hostMapId?: number): Observable<MadCloudResult> {
    const hostMapParam = typeof hostMapId === 'number' ? hostMapId : '';
    return this.http.get<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/${licenseId}/ValidateVanity/${vanity}/${hostMapParam}`);
  }

  upsertContentSecurityPolicy$(licenseId: number, contentSecurityPolicyName: string): Observable<ContentSecurityPolicy> {
    return this.http.post<ContentSecurityPolicy>(`${this.apiService.centralApiBaseUri}/api/Sites/ContentSecurityPolicy/${licenseId}?contentSecurityPolicyName=${encodeURIComponent(contentSecurityPolicyName)}`, null);
  }

  upsertContentSecurityPolicyDirectives$(licenseId: number, updatedContentSecurityPolicy: ContentSecurityPolicy): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/UpdateContentSecurityPolicy/${licenseId}`, updatedContentSecurityPolicy);
  }

  removeContentSecurityPolicy$(contentSecurityPolicyId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/Sites/DeleteContentSecurityPolicy/${contentSecurityPolicyId}`);
  }

  @SharePendingRequest()
  getLiveSitesPage$(filter: PageFilter): Observable<Page<SiteGridItem>> {
    return this.http.post<Page<SiteGridItem>>(`${this.apiService.centralApiBaseUri}/api/Sites/liveSites/query`, filter);
  }
}
