import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TreeService } from '@portal-core/general/services/tree.service';
import { PermissionsCategory } from '@portal-core/permissions/models/permissions-category.model';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { PermissionsTreeNode } from '@portal-core/permissions/types/permissions-tree-node.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { combineLatest, map, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mc-permissions-tree',
  templateUrl: './permissions-tree.component.html',
  styleUrls: ['./permissions-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class PermissionsTreeComponent implements OnInit {
  @Input() disabledPermissions?: Permissions[];
  @Input() readonly?: boolean = false;
  @Input() selectedPermissions?: Permissions[];
  @Input() useDefaultPermissions?: boolean = false;

  @InputObservable('disabledPermissions') disabledPermissions$: Observable<Permissions[]>;
  @InputObservable('readonly') readonly$: Observable<boolean>;
  @InputObservable('selectedPermissions') selectedPermissions$: Observable<Permissions[]>;
  @InputObservable('useDefaultPermissions') useDefaultPermissions$: Observable<boolean>;

  adminPermissionsDataSource: PermissionsTreeNode[];
  adminPermissionsTreeControl: NestedTreeControl<PermissionsTreeNode>;
  dirty: boolean = false;
  loadingState: LoadingState<string> = new LoadingState<string>();
  touched: boolean = false;
  projectPermissionsDataSource: PermissionsTreeNode[];
  projectPermissionsTreeControl: NestedTreeControl<PermissionsTreeNode>;

  private disabledPermissionsSubscription: Subscription;
  private permissions: PermissionsCategory[];
  private permissionsCategorySelection: SelectionModel<number> = new SelectionModel<number>(true);
  private permissionsTreeSubscription: Subscription;
  private selectedPermissionsSubscription: Subscription;

  get allSelected(): boolean {
    if (this.permissionsCategorySelection && Array.isArray(this.permissions)) {
      return this.permissionsCategorySelection.selected.length === this.permissions.length;
    } else {
      return false;
    }
  }

  get noneSelected(): boolean {
    return this.permissionsCategorySelection?.isEmpty() ?? false;
  }

  constructor(private permissionsService: PermissionsService, private errorService: ErrorService, private treeService: TreeService, private cdr: ChangeDetectorRef) { }

  getSelectedPermissions(): PermissionsCategory[] {
    return this.permissionsCategorySelection.selected.map(permissionId => {
      return this.permissions.find(permission => permission.Id === permissionId);
    });
  }

  ngOnInit() {
    const permissionsTree$ = this.permissionsService.getPermissionCategoriesTree$();

    const permissions$ = permissionsTree$.pipe(
      // Flatten the tree to make searching the permissions easy
      map(permissionsTree => this.treeService.flattenChildren(permissionsTree, 'children').map(node => node.category))
    );

    // Listen for the permissions and selected permissions to change in order to update the tree's selection
    this.selectedPermissionsSubscription = combineLatest([
      permissions$,
      this.selectedPermissions$,
      this.useDefaultPermissions$
    ]).subscribe(([permissions, selectedPermissions, useDefaultPermissions]) => {
      this.permissions = permissions;
      this.permissionsCategorySelection.clear();

      if (permissions && selectedPermissions) {
        this.permissionsCategorySelection.select(
          ...permissions.filter(permission => selectedPermissions.some(selectedPermission => selectedPermission.PermissionCategoryId === permission.Id)).map(permission => permission.Id)
        );
      } else if (permissions && useDefaultPermissions) {
        this.permissionsCategorySelection.select(
          ...permissions.filter(permission => permission.ShowAsDefault).map(permission => permission.Id)
        );
      }

      this.updateTreeSelectionState();
      this.cdr.markForCheck();
    });

    this.disabledPermissionsSubscription = combineLatest([
      this.disabledPermissions$,
      this.readonly$
    ]).subscribe(() => {
      this.updateTreeDisabledState();
      this.updateTreeSelectionState();
      this.cdr.markForCheck();
    });

    // Create the tree controls
    this.adminPermissionsTreeControl = new NestedTreeControl<PermissionsTreeNode>(this.getChildren);
    this.projectPermissionsTreeControl = new NestedTreeControl<PermissionsTreeNode>(this.getChildren);

    // Start loading the permissions tree data to populate the trees
    this.loadingState.update(true);
    this.permissionsTreeSubscription = permissionsTree$.subscribe(permissionsTree => {
      this.adminPermissionsDataSource = [this.treeService.findChild(permissionsTree, 'children', node => node.category.Name === 'Administrative')];
      this.projectPermissionsDataSource = [this.treeService.findChild(permissionsTree, 'children', node => node.category.Name === 'Projects')];

      // Expand the tree nodes
      // dataNodes property only needs to be set if attempting to use expand all function. See https://github.com/angular/material2/issues/12469
      this.adminPermissionsTreeControl.dataNodes = this.adminPermissionsDataSource;
      this.projectPermissionsTreeControl.dataNodes = this.projectPermissionsDataSource;

      this.adminPermissionsTreeControl.expandAll();
      this.projectPermissionsTreeControl.expandAll();

      this.updateTreeSelectionState();
      this.updateTreeDisabledState();
      this.cdr.markForCheck();

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'There was a problem loading the permissions.', this.errorService.getErrorMessages(error));
    });
  }

  markAsPristine() {
    this.dirty = false;
    this.touched = false;
  }

  hasNestedChildren = (index: number, node: PermissionsTreeNode) => Array.isArray(node.children) && node.children.length > 0;
  getChildren = (node: PermissionsTreeNode) => node.children;

  onNodeCheckboxChanged(node: PermissionsTreeNode) {
    this.dirty = true;
    this.touched = true;
    this.toggleNodeSelection(node);
  }

  private toggleNodeSelection(node: PermissionsTreeNode) {
    if (node.children?.length > 0) {
      const descendants = this.treeService.flatten(node, 'children').filter(child => !child.disabled).map(child => child.category.Id);

      if (this.childrenAllSelected(node)) {
        this.permissionsCategorySelection.deselect(node.category.Id, ...descendants);
      } else {
        this.permissionsCategorySelection.select(node.category.Id, ...descendants);
      }
    } else {
      this.permissionsCategorySelection.toggle(node.category.Id);

      // Update the parent nodes
      let parentNode = node.parent;
      while (parentNode?.category) {
        if (this.childrenAllSelected(parentNode)) {
          this.permissionsCategorySelection.select(parentNode.category.Id);
        } else {
          this.permissionsCategorySelection.deselect(parentNode.category.Id);
        }

        parentNode = parentNode.parent;
      }
    }

    this.updateNodeSelectionStateBottomUp(node);
    this.updateNodeSelectionStateTopDown(node);
  }

  /** Returns whether a node is selected. The disabled state of the node is not taken into account. */
  nodeIsSelected(node: PermissionsTreeNode): boolean {
    return this.permissionsCategorySelection.isSelected(node.category.Id);
  }

  /** Returns whether all descendants of a node are selected. */
  private childrenAllSelected(node: PermissionsTreeNode): boolean {
    // If at least one child is enabled
    if (this.treeService.someChild(node, 'children', child => !child.disabled)) {
      // Then all children are considered selected if all the enabled children are selected
      return this.treeService.everyChild(node, 'children', child => child.disabled || this.nodeIsSelected(child));
    } else {
      // Else all children are considered selected if all children are disabled and selected
      return this.treeService.everyChild(node, 'children', child => child.disabled && this.nodeIsSelected(child));
    }
  }

  /** Returns whether some but not all descendants of a node are selected. */
  private childrenPartiallySelected(node: PermissionsTreeNode): boolean {
    // If at least one child is enabled
    if (this.treeService.someChild(node, 'children', child => !child.disabled)) {
      // Then the children are partially selected if at least one enabled child is selected and one enabled child is not selected
      return this.treeService.someChild(node, 'children', child => !child.disabled && this.nodeIsSelected(child)) && this.treeService.someChild(node, 'children', child => !child.disabled && !this.nodeIsSelected(child));
    } else {
      // Else the children are partially selected if there is at least one selected child and one unselected child
      return this.treeService.someChild(node, 'children', child => this.nodeIsSelected(child)) && this.treeService.someChild(node, 'children', child => !this.nodeIsSelected(child));
    }
  }

  private updateNodeSelectionStateBottomUp(node: PermissionsTreeNode) {
    node.allSelected = this.childrenAllSelected(node);
    node.partiallySelected = this.childrenPartiallySelected(node);

    if (node.parent) {
      this.updateNodeSelectionStateBottomUp(node.parent);
    }
  }

  private updateNodeSelectionStateTopDown(node: PermissionsTreeNode) {
    node.allSelected = this.childrenAllSelected(node);
    node.partiallySelected = this.childrenPartiallySelected(node);

    if (node.children?.length > 0) {
      node.children.forEach(child => this.updateNodeSelectionStateTopDown(child));
    }
  }

  private updateTreeSelectionState() {
    this.adminPermissionsTreeControl?.dataNodes?.forEach(node => this.updateNodeSelectionStateTopDown(node));
    this.projectPermissionsTreeControl?.dataNodes?.forEach(node => this.updateNodeSelectionStateTopDown(node));
  }

  private updateNodeDisabledState(node: PermissionsTreeNode) {
    let disabled: boolean = false;

    if (this.readonly) {
      disabled = true;
    } else if (this.disabledPermissions?.length > 0) {
      if (node.children?.length > 0) {
        // disabled = this.treeService.some(node, 'children', child => this.disabledPermissions.some(permission => permission.PermissionCategoryId === child.category.Id));
        disabled = this.treeService.every(node, 'children', child => {
          return child.children?.length > 0 || this.disabledPermissions.some(permission => permission.PermissionCategoryId === child.category.Id);
        });
      } else {
        disabled = this.disabledPermissions.some(permission => permission.PermissionCategoryId === node.category.Id);
      }
    }

    node.disabled = disabled;
  }

  private updateTreeDisabledState() {
    this.adminPermissionsTreeControl?.dataNodes?.forEach(node => this.treeService.forEach(node, 'children', child => this.updateNodeDisabledState(child)));
    this.projectPermissionsTreeControl?.dataNodes?.forEach(node => this.treeService.forEach(node, 'children', child => this.updateNodeDisabledState(child)));
  }
}
