import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { SiteStyles } from '@portal-core/sites/models/site-styles.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SiteStylesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getSiteStylesById$(siteStylesId: number): Observable<SiteStyles> {
    return this.http.get<SiteStyles>(`${this.apiService.centralApiBaseUri}/api/SiteStyles/${siteStylesId}`);
  }

  @SharePendingRequest()
  getSiteStylesByLicenseId$(licenseId: number): Observable<SiteStyles[]> {
    return this.http.get<SiteStyles[]>(`${this.apiService.centralApiBaseUri}/api/SiteStyles/AllLicenseSiteStyles/${licenseId}`);
  }

  /**
   * Updates the `SiteStyles` with the given data. If the `SiteStyles` does not exist then a new `SiteStyles` is created.
   * To create a `SiteStyles` then do not define `siteStyles.Id`
   * @param siteStyles The new properties of the `SiteStyles`.
   * @param siteLogoFile Using different values for `siteLogoFile` can delete, update, or leave the logo untouched.
   * undefined - do not change the logo
   * null - remove the logo
   * File - change the logo to the given file
   */
  upsertSiteStyles$(siteStyles?: Partial<SiteStyles>, siteLogoFile?: File): Observable<SiteStyles> {
    const formData = new FormData();
    formData.append('SiteStyle', siteStyles ? JSON.stringify(siteStyles) : null);

    if (typeof siteLogoFile !== 'undefined') {
      formData.append('logoFile', siteLogoFile);
    }

    return this.http.put<SiteStyles>(`${this.apiService.centralApiBaseUri}/api/SiteStyles/setSiteStyles`, formData);
  }

  duplicateSiteStyles$(siteStylesId: number, siteStylesName?: string): Observable<SiteStyles> {
    return this.http.post<SiteStyles>(`${this.apiService.centralApiBaseUri}/api/SiteStyles/duplicateSiteStyles/${siteStylesId}`, {
      Name: siteStylesName
    });
  }

  deleteSiteStyles$(siteStylesId: number): Observable<any> {
    return this.http.delete(`${this.apiService.centralApiBaseUri}/api/SiteStyles/deleteSiteStyles/${siteStylesId}`);
  }

  getSiteStylesPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<SiteStyles>> {
    return this.http.post<Page<SiteStyles>>(`${this.apiService.centralApiBaseUri}/api/SiteStyles/PageLicenseSitesStyles/${licenseId}`, filter);
  }
}
