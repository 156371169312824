import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CurrentConfigurator } from '@portal-core/current/services/current-module-config.interface';
import { License } from '@portal-core/licenses/models/license.model';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
// import { CoreService } from '@portal-flare/core/core.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentConfiguratorService implements CurrentConfigurator {
  constructor() { }

  getLicenseUser(): LicenseUser {
    return null;
  }

  getLicenseUser$(): Observable<LicenseUser> {
    return of(null);
  }

  getLicense(): License {
    return null;
  }

  getLicense$(): Observable<License> {
    return of(null);
  }

  getActiveLicense(): License {
    return null;
  }

  getActiveLicense$(): Observable<License> {
    return of(null);
  }

  isSysAdmin(): boolean {
    return false;
  }
}
