import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectLinkPipe } from './project-link.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ProjectLinkPipe
  ],
  exports: [
    ProjectLinkPipe
  ]
})
export class ProjectLinkModule { }
