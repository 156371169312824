import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { WebhookChannel } from '@portal-core/slack/models/webhook-channel.model';

// Webhook Channel
@CollectionStateSelectors({
  stateType: WebhookChannelsState
})
@State<WebhookChannelsState>({
  name: WebhookChannelsState.source
})
@Injectable()
export class WebhookChannelsState extends CollectionStateBase {
  static source = 'WebhookChannels';
  getSource(): string {
    return WebhookChannelsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlackWebhookChannelsDataService extends CollectionDataServiceBase<WebhookChannel> {
  constructor(protected store: Store) {
    super(store, WebhookChannelsState);
  }
}
