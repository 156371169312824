<form novalidate [formGroup]="siteSettingsForm" class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" placeholder="Name">
      <mat-error>
        <mc-form-control-error-message [form]="siteSettingsForm" controlName="name" type="mc_site_name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Domain</mat-label>
        <mat-select formControlName="domain" placeholder="Domain">
          <mat-option *ngFor="let siteUrl of siteUrls$ | async" [value]="siteUrl.Id || -1">
            <ng-container *ngIf="siteUrl.VanityBaseUrl">
              {{ getDomain(liveSiteUrl) }}
            </ng-container>
            <ng-container *ngIf="!siteUrl.VanityBaseUrl">
              {{ siteUrl.LicenseHostMap?.MappedDomain }}
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Vanity</mat-label>
        <input matInput formControlName="vanityUrl" placeholder="Vanity">
        <mat-error>
          <mc-form-control-error-message [form]="siteSettingsForm" controlName="vanityUrl" type="mc_site_vanity"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Site Theme</mat-label>
        <mat-select formControlName="siteStylesId">
          <mat-option [value]="null"><i>None</i></mat-option>
          <mat-option *ngFor="let siteStyles of siteStyles$ | async" [value]="siteStyles.Id">
            {{ siteStyles.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Site Content Security Policy</mat-label>
        <mat-select formControlName="contentSecurityPolicyId">
          <mat-option [value]="null"><i>None</i></mat-option>
          <mat-option *ngFor="let contentSecurityPolicy of contentSecurityPolicies$ | async" [value]="contentSecurityPolicy.Id">
            {{ contentSecurityPolicy.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Build</mat-label>
      <mc-build-picker-input color="primary-vivid" formControlName="build" [licenseId]="licenseId"></mc-build-picker-input>
      <mat-error>
        <mc-form-control-error-message [form]="siteSettingsForm" controlName="build" type="mc_build"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div class="mc-form-spacer"></div>

    <div class="mc-form-field">
      <mat-checkbox type="checkbox" color="primary-vivid" formControlName="isLive">Set live</mat-checkbox>
    </div>
    <div class="mc-form-field">
      <mat-checkbox type="checkbox" color="primary-vivid" formControlName="isCrawlable">Include in search engines</mat-checkbox>
    </div>
    <div class="mc-form-field">
      <mat-checkbox type="checkbox" color="primary-vivid" formControlName="isPrivate">Set private</mat-checkbox>
      <button mat-icon-button type="button" color="link" [matMenuTriggerFor]="privateHelp">
        <mat-icon fontSet="project-titan" fontIcon="icon-circle-help"></mat-icon>
      </button>
    </div>

    <div class="mc-form-spacer"></div>

    <p class="mc-form-label">URL Preview</p>
    <p *ngIf="previewUrl$ | async as previewUrl; else selectBuild" class="mc-form-value mc-break-text">{{ previewUrl }}</p>
    <ng-template #selectBuild>
      <p class="mc-form-note">Please select a build.</p>
    </ng-template>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button mat-stroked-button type="button" (click)="onCancelClicked()">Cancel</button>
      <button mat-raised-button type="submit" color="primary" [disabled]="siteSettingsForm.invalid || (siteSettingsForm.pristine)">Save</button>
    </div>
  </div>

  <div *ngIf="loadingState.generalError$ |  async as generalError" class="error-pane">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
  <mc-loader *ngIf="loadingState.loading$ | async"> </mc-loader>
</form>

<mat-menu #privateHelp="matMenu" [overlapTrigger]="false">
  <p class="mc-site-settings-form-private-help-menu">If you select “Set private,” the site should also be associated with at least one team. Only members of the selected team(s) can view the private output.</p>
  <p class="mc-site-settings-form-private-help-more">For more information, <a target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Set_Private">click here.</a></p>
</mat-menu>