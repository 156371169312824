import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentModuleConfig } from '@portal-core/current/models/current-module-config.model';
import { CurrentModuleConfigService } from '@portal-core/current/services/current-module-config.service';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class CurrentServicesModule {
  static forRoot(config: CurrentModuleConfig): ModuleWithProviders<CurrentServicesModule> {
    return {
      ngModule: CurrentServicesModule,
      providers: [
        {
          provide: CurrentModuleConfigService,
          useValue: config
        }
      ]
    };
  }
}
