import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAutoFocusDirective } from './dialog-auto-focus.directive';

@NgModule({
  declarations: [
    DialogAutoFocusDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DialogAutoFocusDirective
  ]
})
export class DialogAutoFocusModule { }
