import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectChecklistLinkPipe } from './project-checklist-link.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ProjectChecklistLinkPipe
  ],
  exports: [
    ProjectChecklistLinkPipe
  ]
})
export class ProjectChecklistLinkModule { }
