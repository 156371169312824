import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { Page } from '@common/paged-data/types/page.type';
import { ModelId, ModelIds } from '@portal-core/data/common/types/mc-model.type';

// The collection state's model class
export class CollectionStateModel {
  items: Dictionary;
}
// Define the interface for the model's defaults. Every property is optional.
export interface CollectionStateModelDefaults {
  items?: Dictionary;
}

export class AddItems {
  static readonly type: string = '[CollectionState] AddItems';
  oldItems?: Dictionary;
  constructor(public source: string, public payload: { items: Dictionary }) { }
}

export class RemoveItems {
  static readonly type: string = '[CollectionState] RemoveItems';
  constructor(public source: string, public payload: { itemIds: ModelIds }) { }
}

export class DeleteItems {
  static readonly type: string = '[CollectionState] DeleteItems';
  constructor(public source: string, public payload: { itemIds: ModelIds }) { }
}

export class SetListById {
  static readonly type: string = `[CollectionState] SetListById`;
  constructor(public source: string, public payload: { listName: string, listId: ModelId, items: any[] }) { }
}

export class AddItemsToListById {
  static readonly type: string = `[CollectionState] AddItemsToListById`;
  constructor(public source: string, public payload: { listName: string, listId: ModelId, items: any[] }) { }
}

export class ClearLists {
  static readonly type: string = '[CollectionState] ClearLists';
  constructor(public source: string) { }
}

export class Reset {
  static readonly type: string = '[CollectionState] Reset';
  constructor(public source: string) { }
}

/*
 * Paged Data Lists
 */
export class AddDataListPage {
  static readonly type: string = '[CollectionState] AddDataListPage';
  oldItems?: Dictionary;
  constructor(public source: string, public payload: { dataListId: string, page: Page }) { }
}

export class ResetPagedDataList {
  static readonly type: string = '[CollectionState] ResetPagedDataList';
  constructor(public source: string, public payload: { dataListId: string }) { }
}

export class ClearPagedDataList {
  static readonly type: string = '[CollectionState] ClearPagedDataList';
  constructor(public source: string, public payload: { dataListId: string }) { }
}

export class SetPagedDataListFilterByName {
  static readonly type: string = '[CollectionState] SetPagedDataListFilterByName';
  constructor(public source: string, public payload: { dataListId: string, name: string, value: PageFilterGroup }) { }
}

export class SetPagedDataListOrder {
  static readonly type: string = '[CollectionState] SetPagedDataListOrder';
  constructor(public source: string, public payload: { dataListId: string, order: PageSort }) { }
}

/*
 * Grids
 */
export class AddGridPage {
  static readonly type: string = '[CollectionState] AddGridPage';
  oldItems?: Dictionary;
  constructor(public source: string, public payload: { gridId: string, page: Page, pageSize: number }) { }
}

export class ResetGrid {
  static readonly type: string = '[CollectionState] ResetGrid';
  constructor(public source: string, public payload: { gridId: string }) { }
}

export class ClearGrid {
  static readonly type: string = '[CollectionState] ClearGrid';
  constructor(public source: string, public payload: { gridId: string }) { }
}

export class SetGridFilterByName {
  static readonly type: string = '[CollectionState] SetGridFilterByName';
  constructor(public source: string, public payload: { gridId: string, name: string, value: PageFilterGroup }) { }
}

export class ClearGridFilters {
  static readonly type: string = '[CollectionState] ClearGridFilters';
  constructor(public source: string, public payload: { gridId: string }) { }
}

export class SetGridPageSize {
  static readonly type: string = '[CollectionState] SetGridPageSize';
  constructor(public source: string, public payload: { gridId: string, pageSize: number }) { }
}

export class SetGridOrder {
  static readonly type: string = '[CollectionState] SetGridOrder';
  constructor(public source: string, public payload: { gridId: string, order: PageSort }) { }
}

export class SetGridVisibleColumns {
  static readonly type: string = '[CollectionState] SetGridVisibleColumns';
  constructor(public source: string, public payload: { gridId: string, visibleColumns: string[] }) { }
}
