import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { LicenseUsersSelectListComponent } from './license-users-select-list.component';

@NgModule({
  declarations: [
    LicenseUsersSelectListComponent
  ],
  imports: [
    CommonModule,
    LicenseUserAvatarModule,
    ListModule,
    MatListModule
  ],
  exports: [
    LicenseUsersSelectListComponent
  ]
})
export class LicenseUsersSelectListModule { }
