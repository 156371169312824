import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription, catchError, map, of } from 'rxjs';

interface LicenseOpenAIForm {
  key: FormControl<string>,
  version: FormControl<string>
}

interface ModelOption {
  text: string,
  value: string
}

@Component({
  selector: 'mc-license-open-ai-form',
  templateUrl: './license-open-ai-form.component.html',
  styleUrls: ['./license-open-ai-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicenseOpenAIFormComponent implements OnInit {

  @Input() license: License;
  @Output() cancel = new EventEmitter<boolean>();

  hide: boolean = true;
  savingState: LoadingState<string> = new LoadingState<string>();

  AIForm: FormGroup<LicenseOpenAIForm>;

  models: ModelOption[] = [
    { text: 'GPT-3.5', value: 'gpt-3.5-turbo' },
    { text: 'GPT-4', value: 'gpt-4' },
    { text: 'GPT-4o', value: 'gpt-4o' }
  ];

  versionSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private licensesService: LicensesService,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.versionSubscription = this.AIForm.controls.version.valueChanges.subscribe(() => this.AIForm.controls.key.updateValueAndValidity());
  }

  onSubmit() {
    this.savingState.update(true);
    this.licensesService.changeOpenAISettings$(this.license.Id, this.AIForm.value.key, this.AIForm.value.version).subscribe(() => {
      this.snackBar.open('OpenAI settings has been saved', 'OK', { duration: 2500 });
      this.savingState.update(false);
    }, error => {
      this.savingState.update(false, 'Unable to update the OpenAI settings.', this.errorService.getErrorMessages(error));
    });
  }

  private buildForm() {
    this.AIForm = this.formBuilder.group<LicenseOpenAIForm>({
      key: new FormControl(this.license.OpenAIKey, { asyncValidators: [this.createValidateOpenAI()] }),
      version: new FormControl(this.license.OpenAIModel ? this.license.OpenAIModel : 'gpt-3.5-turbo')
    });
  }

  private createValidateOpenAI(): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors> => {
      const [apiKey, apiModel] = [
        control?.value,
        this.AIForm?.value?.version
      ];
      if (apiKey && apiModel) {
        return this.licensesService.verifyOpenAIKey$(apiKey, apiModel).pipe(
          map(() => null),
          catchError(() => {
            return of({ apiKey: true });
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
