import { EditorState, Plugin, PluginKey, PluginView, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';

export const ImageUploadMetaKey: string = 'image-upload';
export const ImageUploadPluginKey = new PluginKey('image-upload');

export interface ImageUploadPluginMetaData {
  add?: ImagePlaceholderAction[];
  error?: ImagePlaceholderAction[];
  remove?: ImagePlaceholderAction[];
}

export enum ImageActionType {
  Add = 'add',
  Error = 'error',
  Remove = 'remove'
}

export interface ImagePlaceholderAction {
  id: string | number;
  pos?: number;
  errors?: string[];
}

export function findImageUploadPos(editorState: EditorState, id: string | number): number {
  const decos: DecorationSet = ImageUploadPluginKey.getState(editorState);
  const found = decos?.find(null, null, spec => spec.id === id);
  return found?.length > 0 ? found[0].from : null;
}

export function imageUploadPlugin(): Plugin {
  return new Plugin({
    key: ImageUploadPluginKey,

    view(view: EditorView): PluginView {
      return new ImageUploadErrorTooltipView(view);
    },

    state: {
      init(): DecorationSet {
        return DecorationSet.empty;
      },

      apply(tr: Transaction, set: DecorationSet): DecorationSet {
        // Adjust decoration positions to changes made by the transaction
        set = set.map(tr.mapping, tr.doc);

        // See if the transaction adds or removes any placeholders
        const action: ImageUploadPluginMetaData = tr.getMeta(ImageUploadMetaKey);

        if (action) {
          let removeIds: string[] = [];
          let widgetParams: {id: string, pos: number, widget: HTMLElement}[] = [];

          if (action.add) {
            action.add.forEach(action => {
              // Check if the document exists so that this plugin can work in the server environment.
              // There is no need to render a decoration on the server. It just needs to exist in the decoration set.
              let widget;
              if (typeof document !== 'undefined') {
                widget = createElement('div', 'mc-editor-image-upload-placeholder', 'Uploading image...');
              }
              widgetParams.push({id: action.id as string, pos: action.pos, widget: widget});
            });
          }

          if (action.error) {
            action.error.forEach(action => {
              // Check if the document exists so that this plugin can work in the server environment.
              // There is no need to render a decoration on the server. It just needs to exist in the decoration set.
              let widget;
              if (typeof document !== 'undefined') {
                widget = createElement('div', 'mc-editor-image-upload-error-placeholder', 'Unable to upload image', {name: 'upload-error-message', value: 'Error Uploading Image:\n' + action.errors.join('\n')});
                const icon = createElement('span', 'mc-editor-image-upload-error-icon-close icon-close', null, {name: 'guid', value: action.id as string});
                widget.append(icon);
              }
              widgetParams.push({id: action.id as string, pos: action.pos, widget: widget});
            });

            removeIds.push(...action.error.map(action => action.id as string));
          }

          if (action.remove) {
            removeIds.push(...action.remove.map(action => action.id as string));
          }

          // Clear the previous placeholder
          if (removeIds.length) {
            set = set.remove(set.find(null, null, spec => removeIds.includes(spec.id)));
          }

          // Add the new widget where the old placeholder was
          if (widgetParams) {
            const decos = widgetParams.map(param => {
               return Decoration.widget(param.pos, param.widget, {
                id: param.id
              } as any); // Casted as any because the type definition file is incorrect for this function signature
            });

            set = set.add(tr.doc, decos);
          }
        }

        return set;
      }
    },

    props: {
      decorations(state: EditorState): DecorationSet {
        return this.getState(state);
      },
      handleClick(view, _, event) {
        const target: any = event.target;
        // Error placholder close button clicked
        if (target.dataset.guid) {
          // Remove the error placeholder
          const tr = view.state.tr.setMeta(ImageUploadMetaKey, {
            remove: [{
              id: target.dataset.guid
            }]
          } as ImageUploadPluginMetaData);

          view.dispatch(tr);

          return true;
        }
      },
      handleDOMEvents: {
        mouseover: (view, event) => {
          let tooltip: HTMLElement;
          // Find the tooltip element
          for (let i = 0; i < view.dom.parentNode.childElementCount; i++) {
            const child = view.dom.parentNode.children.item(i) as HTMLElement;
            if (child.classList.contains('mc-editor-image-upload-error-tooltip')) {
              tooltip = child;
              break;
            }
          }

          if (!tooltip) {
            return;
          }

          const target = event.target as HTMLElement;
          const errorMessage: string = target.dataset['uploadErrorMessage'];

          if (errorMessage) {
            /* Based on https://prosemirror.net/examples/tooltip/ */
            // Reposition the tooltip and update its content
            tooltip.textContent = errorMessage;
            tooltip.style.display = '';
            tooltip.style.removeProperty('top');
            tooltip.style.removeProperty('bottom');

            // The box in which the tooltip is positioned, to use as base
            const box = tooltip.offsetParent.getBoundingClientRect();

            // Find a center x position of the placeholder. Adjust 14px to account for the mat-tooltip side margins
            const left = target.offsetLeft + (target.offsetWidth / 2) - 14;

            tooltip.style.left = left + 'px';
            tooltip.style.bottom = (box.height - target.offsetTop) + 'px';
            // If the tooltip is outside the bounds of the top editor, then move the tooltip to below
            if (tooltip.offsetTop <= 0) {
              tooltip.style.removeProperty('bottom');
              tooltip.style.top = (target.offsetTop + target.offsetHeight) + 'px';
            }
          } else {
            tooltip.style.display = 'none';
          }
        }
      }
    }
  });
}

function createElement(tagName: string, className: string = '', text: string = '', attribute?: {name: string, value: string}): HTMLElement {
  const element = document.createElement(tagName);
  element.className = className;
  element.innerText = text;
  if (attribute) {
    element.setAttribute(`data-${attribute.name}`, attribute.value);
  }

  return element;
}

class ImageUploadErrorTooltipView implements PluginView {
  tooltip: HTMLDivElement;

  constructor(view: EditorView) {
    this.tooltip = document.createElement('div');
    // Use the styles from mat-tooltip
    this.tooltip.className = 'mc-editor-image-upload-error-tooltip mat-tooltip';
    // Hide it until the error placeholder is hovered
    this.tooltip.style.display = 'none';
    view.dom.parentNode.appendChild(this.tooltip);
  }

  destroy() {
    this.tooltip.remove();
  }
}
