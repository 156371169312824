import { Observable } from 'rxjs';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridControl } from '@portal-core/ui/grid/util/grid-control';
import { EmptyGridDisplay } from '@portal-core/ui/grid/types/empty-grid-display.type';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export interface DataGridControlOptions {
  clearGridFilters?: () => void;
  getEmptyGridDisplay?: (filters: Dictionary<PageFilterGroup>) => EmptyGridDisplay;
}

/**
 * DataGridControl
 * Defines the columns, collection data store, and fetch method used in a Grid.
 * T is the type of items used in the grid.
 * S is the base type of the items used in the grid.
 * If S and T are the same type then only T needs to be specified.
 */
export class DataGridControl<T extends S = any, S = T> extends GridControl {
  public clearGridFilters: () => void;

  get columns(): GridColumn[] {
    return this.filterConfig.enabledColumns;
  }

  /**
   * constructor
   * @param collectionService: The collection service that manages the items in the grid.
   * @param fetchGridPage$ A function that can fetch a Page of items and returns it in an Observable.
   * @param columns: An array that defines the grid's columns.
   * @param options: Optional configuration for the grid.
   * clearGridFilters: A function that is called when the grid's filters are cleared from within the grid.
   * getEmptyGridDisplay: A function that returns the type of empty grid UI to show.
   */
  constructor(
    public collectionService: CollectionServiceBase<S>,
    public fetchGridPage$: (filter: PageFilter) => Observable<Page<T>>,
    public filterConfig: PageFilterConfigBase,
    public options?: DataGridControlOptions
  ) {
    super(options?.getEmptyGridDisplay);

    this.clearGridFilters = options?.clearGridFilters;
  }
}
