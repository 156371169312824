import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TaskCommentIconComponent } from '@portal-core/tasks/components/task-comment-icon/task-comment-icon.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
   ],
  declarations: [
    TaskCommentIconComponent
  ],
  exports: [
    TaskCommentIconComponent
  ]
})
export class TaskCommentIconModule { }
