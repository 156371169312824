import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationStringComponent } from './translation-string.component';

@NgModule({
  declarations: [
    TranslationStringComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslationStringComponent
  ]
})
export class TranslationStringModule { }
