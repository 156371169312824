import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { Project } from '@portal-core/projects/models/project.model';
import { TranslationPackageSettingsFormComponent } from '@portal-core/translation-packages/components/translation-package-settings-form/translation-package-settings-form.component';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { TranslationPackageProfile } from '@portal-core/translation-packages/models/translation-package-profile.model';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, combineLatest, filter, first, map, switchMap } from 'rxjs';

export interface TranslationPackageProfileDialogData {
  translationPackageId: number;
  translationPackageProfileTab?: TranslationPackageProfileTab;
}

export enum TranslationPackageProfileTab {
  Overview,
  Settings,
  Activity,
  Delete
}

@Component({
  selector: 'mc-translation-package-profile-dialog',
  templateUrl: './translation-package-profile-dialog.component.html',
  styleUrls: ['./translation-package-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '90rem',
    height: '63rem',
    autoFocus: false
  };

  @ViewChild('settingsForm', { static: false }) settingsFormComponent: TranslationPackageSettingsFormComponent;

  userCanManageTranslationPackage$: Observable<boolean>;
  TranslationPackageProfileTab: typeof TranslationPackageProfileTab = TranslationPackageProfileTab;
  TranslationPackageStatus: typeof TranslationPackageStatus = TranslationPackageStatus;
  loadingState: LoadingState<string> = new LoadingState<string>();
  translationPackageProfileTab: TranslationPackageProfileTab;
  translationPackageProfile$: Observable<TranslationPackageProfile>;
  project$: Observable<Project>;
  translationPackage$: Observable<TranslationPackage>;
  private reload$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<TranslationPackageProfileDialogComponent>,
    private profilesService: ProfilesService,
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: TranslationPackageProfileDialogData
  ) {
    super(dialog, dialogRef);
  }

  get requirePromptOnClose(): boolean {
    return this.settingsFormComponent?.translationPackageSettingsForm?.dirty;
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);
    this.translationPackageProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getTranslationPackageProfile$(this.data.translationPackageId)),
      cache()
    );
    this.project$ = this.translationPackageProfile$.pipe(
      map(packageProfile => packageProfile ? packageProfile.Project : null)
    );

    this.translationPackage$ = this.translationPackageProfile$.pipe(
      map(packageProfile => packageProfile ? packageProfile.TranslationPackage : null)
    );

    this.userCanManageTranslationPackage$ = this.project$.pipe(
      switchMap(project => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTranslationPackage, project.Id))
    );

    combineLatest([
      this.project$,
      this.translationPackage$
    ]).pipe(
      first()
    ).subscribe(() => {
      this.translationPackageProfileTab = this.data.translationPackageProfileTab != null ? this.data.translationPackageProfileTab : TranslationPackageProfileTab.Overview;
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Failed to load the Translation Package.', this.errorService.getErrorMessages(error));
    });

    // Subscribes to the navigation change events for closing the dialog when there is a route change.
    // This Dialog panel contains some links which redirect to other routes so we need to close the Dialog when the route changes.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.closeDialog(null, true);
    });
  }

  onSaved() {
    this.reload$.next();
  }
}
