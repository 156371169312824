import { SimpleChanges } from '@angular/core';
import { some } from 'lodash';
import { ReplaySubject, distinctUntilChanged } from 'rxjs';

function observe(target: Object, propertyKey: string, useDistinctUntilChanged: boolean, getValue: (instance: any) => any, onChanges: (changes: SimpleChanges) => boolean) {
  const originalOnChanges: (changes: SimpleChanges) => void = target['ngOnChanges'];

  // The property keys for the new "private" properties on the object instance
  const subjectKey = `mc${propertyKey}Subject`;
  const observableKey = `mc${propertyKey}Observable`;

  // Makes sure the properties for the subject and observable exist on the object instance
  function ensureSubjectExists() {
    if (!this[subjectKey]) {
      this[subjectKey] = new ReplaySubject(1);
      this[subjectKey].next(getValue(this));
      this[observableKey] = this[subjectKey].asObservable();

      if (useDistinctUntilChanged) {
        this[observableKey] = this[observableKey].pipe(
          distinctUntilChanged()
        );
      }
    }
  }

  // Change the @InputObservable property into a getter property
  delete target[propertyKey];
  Object.defineProperty(target, propertyKey, {
    get() {
      ensureSubjectExists.call(this);
      return this[observableKey];
    }
  });

  target['ngOnChanges'] = function (changes: SimpleChanges) {
    if (onChanges(changes)) {
      ensureSubjectExists.call(this);
      this[subjectKey].next(getValue(this));
    }

    return originalOnChanges?.apply(this, arguments);
  };
}

/*
 * @InputObservable()
 * Creates an observable property that emits a new value whenever its @Input property or properties changes values.
 * First declare an @Input property on the class, then declare an @InputObservable property passing in the name of the @Input property.
 * This binds the @InputObservable property to the @Input property causing the @InputObservable property to emit a value whenever the @Input value changes.
 * To emit whenever any number of properties are changed then declare an @InputObservable property passing in the names of each property as an array.
 *
 * Example:
 * @Input() reviewFile: ReviewFile;
 * @InputObservable('reviewFile') reviewFile$: Observable<ReviewFile>;
 *
 * @Input() projectId: number;
 * @Input() branchName: string;
 * @InputObservable(['projectId', 'branchName']) treeParameters$: Observable<[number, string]>;
 */
export function InputObservable(inputPropertyName: string | string[], useDistinctUntilChanged: boolean = true): PropertyDecorator {
  return (target: Object, propertyKey: string) => {
    // If an array of properties are being observed
    if (Array.isArray(inputPropertyName)) {
      // Then emit a value whenever any of the properties changes values but only once per ngOnChanges
      observe(target, propertyKey, useDistinctUntilChanged, instance => {
        return inputPropertyName.map(name => instance[name]);
      }, (changes: SimpleChanges) => {
        return some(changes, (change, name) => inputPropertyName.includes(name));
      });
    } else { // Else a single property is being observed
      // Emit a value whenever that property changes
      observe(target, propertyKey, useDistinctUntilChanged, instance => {
        return instance[inputPropertyName];
      }, (changes: SimpleChanges) => {
        return !!changes[inputPropertyName];
      });
    }
  };
}
