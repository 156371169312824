import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
  selector: 'mc-grid-rows-range',
  templateUrl: './grid-rows-range.component.html',
  styleUrls: ['./grid-rows-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridRowsRangeComponent {
  /** Theme color to be used for the underlying form controls. */
  @Input() color: ThemePalette;
  @Input() rows?: number = 100;

  @Output() rowsChange: EventEmitter<number> = new EventEmitter<number>();

  onRowsChanged(event: MatSelectChange) {
    this.rowsChange.emit(event.value);
  }
}
