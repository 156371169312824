import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationPackageAsyncPipe } from '@portal-core/translation-packages/pipes/translation-package-async/translation-package-async.pipe';

@NgModule({
  declarations: [TranslationPackageAsyncPipe],
  imports: [CommonModule],
  exports: [TranslationPackageAsyncPipe],
  providers: [TranslationPackageAsyncPipe]
})
export class TranslationPackageAsyncModule { }
