import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mc-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '36rem',
    autoFocus: false
  };

  title = '';
  message = 'Sorry, an unexpected error occurred. Please try again later. If the problem persists, please contact Technical Support.';
  errors: string[] = null;
  showErrors = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ErrorDialogComponent>) { }

  ngOnInit() {
    this.title = this.data.title || 'Unexpected Error';
    this.errors = [];
    if (this.data.message) {
      this.errors.push(this.data.message);
    }
    if (Array.isArray(this.data.errors)) {
      this.errors = this.errors.concat(this.data.errors);
    }

    this.dialogRef.afterOpened().subscribe(() => this.showErrors = true);
  }
}
