import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';

@CollectionStateSelectors({
  stateType: ReviewPackagesState
})
@State<ReviewPackagesState>({
  name: ReviewPackagesState.source
})
@Injectable()
export class ReviewPackagesState extends CollectionStateBase {
  static source: string = 'ReviewPackages';

  getSource(): string {
    return ReviewPackagesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewPackagesDataService extends CollectionDataServiceBase<ReviewPackage> {
  constructor(protected store: Store) {
    super(store, ReviewPackagesState);
  }
}
