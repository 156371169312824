<mat-list>
  <mat-list-item *ngFor="let processNotification of messages$ | async">
    {{ processNotification.Message }}
  </mat-list-item>
</mat-list>

<div class="error-pane" *ngIf="loadingState.generalError$ | async as generalError">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>