import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ListItemModule } from '@portal-core/ui/list/directives/list-item/list-item.module';
import { AvatarsListComponent } from './avatars-list.component';

@NgModule({
  declarations: [
    AvatarsListComponent
  ],
  imports: [
    CommonModule,
    ListItemModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    AvatarsListComponent,
    ListItemModule
  ]
})
export class AvatarsListModule { }
