import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChange, TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { getMark } from '@common/prosemirror/model/mark';
import { removeMarksOfType, setMarkAttrs } from '@common/prosemirror/transform/mark';
import { clone, isEqual } from 'lodash';
import { Fragment, Mark, ProseMirrorNode, Slice } from 'prosemirror-model';
import { Transform } from 'prosemirror-transform';

export function getChangeIdsFromString(changeIds: string): string[] {
  return changeIds ? changeIds.split(',') : [];
}

export function makeChangeIdsString(changeIds: string[]): string {
  return changeIds.join(',');
}

export function makeChangeIdsFromChangeList(changeList: TrackedChangeList): string {
  return makeChangeIdsString(changeList.map(change => change.id));
}

export function makeChangeAttrsFromChangeList(changeList: TrackedChangeList): ChangeNodeAttrs {
  return {
    changeIds: makeChangeIdsFromChangeList(changeList),
    changeList: changeList
  };
}

export function removeChangesByType(attrs: ChangeNodeAttrs, changeTypes: string | string[]): ChangeNodeAttrs {
  if (!Array.isArray(changeTypes)) {
    changeTypes = [changeTypes];
  }

  return makeChangeAttrsFromChangeList(attrs.changeList.filter(change => !changeTypes.includes(change.changeType)));
}

export function setNodeChangesFromAttrs(tr: Transform, schema: CollabSchema, node: ProseMirrorNode, pos: number, attrs: ChangeNodeAttrs) {
  const changeIds = makeChangeIdsFromChangeList(attrs.changeList);

  if (node.isText) {
    const changeMark = schema.marks[schema.madcapChangeMarkName];
    // Remove any existing change marks
    removeMarksOfType(tr, node, changeMark, pos);
    // Add a mark with the new change attrs
    tr.addMark(pos, pos + node.nodeSize, changeMark.create({ ...attrs, 'MadCap:changes': changeIds }));
  } else {
    // Copy the node's attrs but override the change attrs with new values
    tr.setNodeMarkup(tr.mapping.map(pos), null, Object.assign({}, node.attrs, attrs, { 'MadCap:changes': changeIds }));
  }
}

export function setNodeChangesFromChangeList(tr: Transform, schema: CollabSchema, node: ProseMirrorNode, pos: number, changeList: TrackedChangeList | TrackedChange) {
  if (!Array.isArray(changeList)) {
    changeList = [changeList];
  }

  setNodeChangesFromAttrs(tr, schema, node, pos, makeChangeAttrsFromChangeList(changeList));
}

// Returns a new attrs object with the changes merged together
export function mergeChanges(attrs: ChangeNodeAttrs, changes: TrackedChange | TrackedChangeList): ChangeNodeAttrs {
  if (!Array.isArray(changes)) {
    changes = [changes];
  }

  const changeIds = getChangeIdsFromString(attrs.changeIds || '');
  const changeList: TrackedChangeList = clone(attrs.changeList || []);

  changes.forEach(change => {
    changeIds.push(change.id);
    changeList.push(change);
  });

  return {
    changeIds: makeChangeIdsString(changeIds),
    changeList: changeList
  };
}

// Returns a new attrs object with the changes removed
export function removeChanges(attrs: ChangeNodeAttrs, changeTypesToRemove: ChangeType | ChangeType[]): ChangeNodeAttrs {
  if (!Array.isArray(changeTypesToRemove)) {
    changeTypesToRemove = [changeTypesToRemove];
  }

  let changeIds = getChangeIdsFromString(attrs.changeIds || '');
  let changeList: TrackedChangeList = clone(attrs.changeList || []);

  changeList = changeList.filter(change => {
    if ((changeTypesToRemove as ChangeType[]).includes(change.changeType)) {
      changeIds = changeIds.filter(changeId => changeId !== change.id);
      return false;
    } else {
      return true;
    }
  });

  return {
    changeIds: makeChangeIdsString(changeIds),
    changeList: changeList
  };
}

export function mergeNodeChanges(tr: Transform, schema: CollabSchema, node: ProseMirrorNode, pos: number, changes: TrackedChange | TrackedChangeList) {
  if (node.isText) {
    // Set a mark on the node with the merged change attrs
    const mark = getMark(node, schema.marks[schema.madcapChangeMarkName]) as Mark;
    setMarkAttrs(tr, node, mark, pos, mergeChanges(mark.attrs as ChangeNodeAttrs, changes));
  } else {
    // Copy the node's attrs but override the change attrs with new merged changes
    tr.setNodeMarkup(tr.mapping.map(pos), null, Object.assign({}, node.attrs, mergeChanges(node.attrs as ChangeNodeAttrs, changes)));
  }
}

/**
 * Returns true if any of the change attrs pass a test by the given iteratee.
 * @param attrs The change node attrs to check.
 * @param iteratee A callback function that takes a TrackedChange as an argument and returns true or false.
 * @returns True if the callback function returns true for any of the changes in the attrs.
 */
export function attrsHaveChangeBy(attrs: ChangeNodeAttrs, iteratee: (change: TrackedChange) => boolean): boolean {
  if (attrs && Array.isArray(attrs.changeList)) {
    return attrs.changeList.some(iteratee);
  }

  return false;
}

/**
 * attrsHaveChange
 * Returns true if the attrs include the given change type.
 * @param attrs The attributes to check for the change.
 * @param changeType The change type to check for.
 */
export function attrsHaveChange(attrs: ChangeNodeAttrs, changeType: ChangeType): boolean {
  return attrsHaveChangeBy(attrs, change => changeType === change.changeType);
}

/**
 * Replaces tracked changes within ChangeNodeAttrs.
 * @param attrs The attributes to replace the changes within.
 * @param iteratee A callback function that is called for each change and returns a new change to replace it or nothing to keep the original change.
 * @returns The new ChangeNodeAttrs with the replaced changes.
 */
export function replaceChangesBy(attrs: ChangeNodeAttrs, iteratee: (change: TrackedChange) => TrackedChange): ChangeNodeAttrs {
  const changeList: TrackedChangeList = attrs?.changeList ?? [];

  return makeChangeAttrsFromChangeList(changeList.map(change => {
    return iteratee(change) ?? change;
  }));
}

/**
 * Returns true if the changes are the same for two change node attrs.
 * @param attrsA The attributes to compare.
 * @param attrsB The other attributes to compare.
 * @return Returns true if the changes are the same for two change node attrs.
 */
export function changeNodeAttrsAreEqual(attrsA: ChangeNodeAttrs, attrsB: ChangeNodeAttrs): boolean {
  // Empty/falsy attributes and change lists are considered equal so reduce them down to be arrays before comparing them
  const changeListA = attrsA && Array.isArray(attrsA.changeList) ? attrsA.changeList : [];
  const changeListB = attrsB && Array.isArray(attrsB.changeList) ? attrsB.changeList : [];

  return isEqual(changeListA, changeListB);
}

/*
 * marksHaveChange
 * Returns true if the mark set contains a change mark. Optionally pass a changeType to check if the mark's change is of the same type
 */
export function marksHaveChange(schema: CollabSchema, marks: readonly Mark[], changeType?: ChangeType): boolean {
  return Array.isArray(marks) && marks.some(mark => {
    if (mark.type === schema.changeMark) {
      if (changeType) {
        return attrsHaveChange(mark.attrs as ChangeNodeAttrs, changeType);
      }

      return true;
    }

    return false;
  });
}

/*
 * getMarkChange
 * Returns the first change found in an array of marks
 */
export function getMarkChange(schema: CollabSchema, marks: readonly Mark[], changeType: ChangeType): TrackedChange {
  if (Array.isArray(marks)) {
    for (let i = 0; i < marks.length; i += 1) {
      const mark = marks[i];

      if (mark.type === schema.changeMark && Array.isArray(mark.attrs.changeList)) {
        const foundChange = mark.attrs.changeList.find(change => changeType === change.changeType);
        if (foundChange) {
          return foundChange;
        }
      }
    }
  }
}

/*
 * nodeHasChange
 * Returns true if a node has a change. Optionally pass a changeType to check if the node's change is of the same type
 */
export function nodeHasChange(schema: CollabSchema, node: ProseMirrorNode, changeType?: ChangeType): boolean {
  if (node.isText || node.type.spec.unchangeable) {
    return marksHaveChange(schema, node.marks, changeType);
  } else {
    const attrs: ChangeNodeAttrs = node.attrs;

    // If this node does not have a change id then it does not have a change
    if (!attrs.changeIds) {
      return false;
    }

    if (changeType) {
      return attrsHaveChange(attrs, changeType);
    }

    return true;
  }
}

/*
 * getNodeChange
 * Returns a change on a node if it exists
 */
export function getNodeChange(schema: CollabSchema, node: ProseMirrorNode, changeType: ChangeType): TrackedChange {
  if (node.isText || node.type.spec.unchangeable) {
    return getMarkChange(schema, node.marks, changeType);
  } else {
    const changeNodeAttrs = node.attrs as ChangeNodeAttrs;
    // If this node has a change id and a change list then search for the change
    if (changeNodeAttrs.changeIds && Array.isArray(changeNodeAttrs.changeList)) {
      return changeNodeAttrs.changeList.find(change => changeType === change.changeType);
    }
  }
}

/**
 * Returns the change attrs for the node whether it be changes on a non-text node or mark changes on a text node.
 * @param schema The schema that the node belongs to.
 * @param node The node to get the change attrs from.
 * @returns The node's change attrs.
 */
export function getNodeChangeAttrs(schema: CollabSchema, node: ProseMirrorNode): ChangeNodeAttrs {
  if (node.isText || node.type.spec.unchangeable) {
    return node.marks?.find(mark => mark.type === schema.changeMark)?.attrs;
  } else {
    // If this node has a change id and a change list then it has change attrs
    if (node.attrs.changeIds && Array.isArray(node.attrs.changeList)) {
      return node.attrs;
    }
  }
}

/*
 * copyNodeWithoutChanges
 * Copies a node without content that is a tracked change. Optionally pass a changeType to exclude a certain type
 */
export function copyNodeWithoutChanges(schema: CollabSchema, node: ProseMirrorNode, changeType?: ChangeType): ProseMirrorNode {
  // If this a text node and it does not have a change
  if (node.isText || node.type.spec.unchangeable) {
    if (!marksHaveChange(schema, node.marks, changeType)) {
      return node;
    }
    // Else if this node does not have a change
  } else if (!nodeHasChange(schema, node, changeType)) {
    return node.copy(copyFragmentWithoutChanges(schema, node.content, changeType));
  }

  return null;
}

/*
 * copyFragmentWithoutChanges
 * Copies a fragment without content that is a tracked change. Optionally pass a changeType to exclude a certain type
 */
export function copyFragmentWithoutChanges(schema: CollabSchema, fragment: Fragment, changeType?: ChangeType): Fragment {
  const newNodes = [];

  // Loop through the fragment's children
  fragment.forEach(node => {
    const newNode = copyNodeWithoutChanges(schema, node, changeType);
    if (newNode) {
      newNodes.push(newNode);
    }
  });

  return Fragment.fromArray(newNodes);
}

/*
 * copySliceWithoutChanges
 * Copies a slice without change content. Optionally pass a changeType to exclude a certain type
 */
export function copySliceWithoutChanges(schema: CollabSchema, slice: Slice, changeType?: ChangeType): Slice {
  // Create a new slice by making a copy of the slice's fragment with changes removed
  const fragment = copyFragmentWithoutChanges(schema, slice.content, changeType);

  let openStart = slice.openStart;
  let openEnd = slice.openEnd;

  // If the fragment is empty then the open start/end should be zero since there are no child nodes to even be open.
  // This can happen when a node is excluded because it was a tracked remove
  if (fragment.size === 0) {
    openStart = 0;
    openEnd = 0;
  }

  return new Slice(fragment, openStart, openEnd);
}

/*
 * copyNodeBySettingChanges
 * Copies a node overriding any changes with the changes from `options`
 */
export function copyNodeBySettingChanges(schema: CollabSchema, node: ProseMirrorNode, attrs: ChangeNodeAttrs): ProseMirrorNode {
  const changeIds = makeChangeIdsFromChangeList(attrs.changeList);
  if (node.isText || node.type.spec.unchangeable) {
    return node.mark(schema.changeMark.create({ ...attrs, 'MadCap:changes': changeIds }).addToSet(node.marks));
  } else {
    return node.type.create(Object.assign({}, node.attrs, attrs, { 'MadCap:changes': changeIds }), node.content, node.marks); // TODO: should node.content be cloned?
  }
}

/*
 * copyNodeByMergingChanges
 * Copies a node merging the change attrs with the changes already on the node
 */
export function copyNodeByMergingChanges(schema: CollabSchema, node: ProseMirrorNode, attrs: ChangeNodeAttrs): ProseMirrorNode {
  let newAttrs;

  if (node.isText || node.type.spec.unchangeable) {
    const mark = getMark(node, schema.marks[schema.madcapChangeMarkName]) as Mark;
    newAttrs = mergeChanges((mark ? mark.attrs : {}) as ChangeNodeAttrs, attrs.changeList);
  } else {
    newAttrs = mergeChanges(node.attrs as ChangeNodeAttrs, attrs.changeList);
  }

  return copyNodeBySettingChanges(schema, node, newAttrs);
}

/**
 * Applies a tracked change to an existing set of change node attributes.
 * @param existingAttrs The existing attributes to apply the new change to
 * @param trackedChange The change to apply to the existing attributes
 * @return The new attributes with the change applied to them
 */
export function applyChange(existingAttrs: ChangeNodeAttrs, trackedChange: TrackedChange): ChangeNodeAttrs {
  const changeType = trackedChange.changeType;

  // Mixing adds and removes just leads to no changes
  if ((changeType === ChangeType.Remove && attrsHaveChange(existingAttrs, ChangeType.Add)) ||
    (changeType === ChangeType.Add && attrsHaveChange(existingAttrs, ChangeType.Remove))) {
    // Delete the attrs
    return null;
    // Mixing removes just keeps the original remove
  } else if (changeType === ChangeType.Remove && attrsHaveChange(existingAttrs, ChangeType.Remove)) {
    // Keep the attrs as is
    return existingAttrs;
    // Mixing adds just gives the node the new add
  } else if (changeType === ChangeType.Add && attrsHaveChange(existingAttrs, ChangeType.Add)) {
    // Use the new attrs
    return makeChangeAttrsFromChangeList([trackedChange]);
    // Mixing replaces substitutes the old replace with the new replace
  } else if (changeType === ChangeType.Replace && attrsHaveChange(existingAttrs, ChangeType.Replace)) {
    // Remove the old replace and merge in the new replace
    return mergeChanges(removeChanges(existingAttrs, ChangeType.Replace), trackedChange);
    // Mixing attribute changes (of the same name) keeps the old change but updates the user and timestamp info
  } else if (changeType === ChangeType.Attributes && attrsHaveChangeBy(existingAttrs, change => change.changeType === ChangeType.Attributes && change.attribute === trackedChange.attribute)) {
    // Replace the existing attribute change with the new change but keep the original value
    return replaceChangesBy(existingAttrs, change => {
      if (change.changeType === ChangeType.Attributes && change.attribute === trackedChange.attribute) {
        return {
          ...trackedChange,
          value: change.value // Keep the original value
        };
      }
    });
    // Mixing anything but an add or remove with an existing add just keeps the original add
  } else if (changeType !== ChangeType.Add && changeType !== ChangeType.Remove && attrsHaveChange(existingAttrs, ChangeType.Add)) {
    // Keep the node as is
    return existingAttrs;
    // Mixing any changes that do not include an add just merges the changes
  } else if (changeType !== ChangeType.Add && existingAttrs && !attrsHaveChange(existingAttrs, ChangeType.Add)) {
    // Merge the changes
    return mergeChanges(existingAttrs, trackedChange);
    // If the node does not have a change then just give the node the new change
  } else {
    // Use the new attrs
    return makeChangeAttrsFromChangeList([trackedChange]);
  }
}

/**
 * Tracks the state of copying a slice, fragment, or nodes with changes.
 */
interface CopyState {
  /**
   * Whether or not the copying action is currently in the first branch of the node tree.
   */
  inFirstBranch: boolean;
  /**
   * Whether or not the copying action is currently in the last branch of the node tree.
   */
  inLastBranch: boolean;
}

/**
 * Creates a new ProseMirror node with the given change applied to it.
 * Uses the applyChange function to apply the changes.
 * If the new node is the same as the original node then the original node is returned.
 * @param schema The schema that the node belongs to.
 * @param node The node to copy and apply the changes to.
 * @param changeType The type of change being applied to the node.
 * @param attrs The change being applied to the node.
 * @return The new node with the change applied to it. If the new node is the same as the original node then the original node is returned.
 */
export function copyNodeByApplyingChanges(schema: CollabSchema, node: ProseMirrorNode, attrs: ChangeNodeAttrs): ProseMirrorNode {
  const newChangeNodeAttrs = applyChange(getNodeChangeAttrs(schema, node), attrs.changeList[0]);

  if (newChangeNodeAttrs) {
    if (changeNodeAttrsAreEqual(node.attrs, newChangeNodeAttrs)) {
      return node;
    } else {
      return copyNodeBySettingChanges(schema, node, newChangeNodeAttrs);
    }
  } else {
    return null;
  }
}

/*
 * copyNodeWithChanges
 * Copies a node with tracked changes applied to it
 */
export function copyNodeWithChanges(schema: CollabSchema, node: ProseMirrorNode, attrs: ChangeNodeAttrs, openStart: number = 0, openEnd: number = 0, copyState: CopyState = { inFirstBranch: true, inLastBranch: true }): ProseMirrorNode {
  // If the node is text THEN apply the change to the node
  if (node.isLeaf || node.type.spec.unchangeable) {
    return copyNodeByApplyingChanges(schema, node, attrs);
    // Else if this node has content and is in an open edge branch
  } else if (node.content.size !== 0 && ((copyState.inFirstBranch && openStart > 0) || (copyState.inLastBranch && openEnd > 0))) {
    // Apply the change to the node's content
    const fragment = copyFragmentWithChanges(schema, node.content, attrs, openStart - 1, openEnd - 1, copyState);

    // If the resulting fragment is empty THEN put the change attrs on the node instead
    if (fragment.size === 0) {
      return copyNodeByApplyingChanges(schema, node, attrs);
    } else {
      return node.copy(fragment);
    }
    // Else the entire node has been changed so put the change attrs on the node and remove any changes from within it
  } else {
    return copyNodeByApplyingChanges(schema, node, attrs);
  }
}

/*
 * copyFragmentWithChanges
 * Copies a fragment with tracked changes applied to it
 */
export function copyFragmentWithChanges(schema: CollabSchema, fragment: Fragment, attrs: ChangeNodeAttrs, openStart: number = 0, openEnd: number = 0, copyState: CopyState = { inFirstBranch: true, inLastBranch: true }): Fragment {
  const newNodes: ProseMirrorNode[] = [];

  fragment.forEach((node, offset, index) => {
    let newNode;

    // If this is the only child
    if (index === 0 && index === fragment.childCount - 1) {
      newNode = copyNodeWithChanges(schema, node, attrs, openStart, openEnd, copyState);
      // Else if this is the first child
    } else if (index === 0) {
      newNode = copyNodeWithChanges(schema, node, attrs, openStart, openEnd, {
        inFirstBranch: copyState.inFirstBranch,
        inLastBranch: false
      });
      // Else if this is the last child
    } else if (index === fragment.childCount - 1) {
      newNode = copyNodeWithChanges(schema, node, attrs, openStart, openEnd, {
        inFirstBranch: false,
        inLastBranch: copyState.inLastBranch
      });
      // Else this is a middle child
    } else {
      newNode = copyNodeWithChanges(schema, node, attrs, openStart, openEnd, {
        inFirstBranch: false,
        inLastBranch: false
      });
    }

    if (newNode) {
      newNodes.push(newNode);
    }
  });

  return Fragment.fromArray(newNodes);
}

/*
 * copySliceWithChanges
 * Copies a slice with tracked changes applied to it
 */
export function copySliceWithChanges(schema: CollabSchema, slice: Slice, attrs: ChangeNodeAttrs): Slice {
  // Create a new slice by making a copy of the slice's fragment with changes added
  const fragment = copyFragmentWithChanges(schema, slice.content, attrs, slice.openStart, slice.openEnd);

  let openStart = slice.openStart;
  let openEnd = slice.openEnd;

  // If the fragment is empty then the open start/end should be zero since there are no child nodes to even be open.
  // This can happen when a change entirely removes a node (eg a tracked added node is removed)
  if (fragment.size === 0) {
    openStart = 0;
    openEnd = 0;
    // Else if this is a remove or unbind change
  } else if (attrsHaveChange(attrs, ChangeType.Remove) || attrsHaveChange(attrs, ChangeType.Unbind)) {
    // If the first child is not a text node and it has a change attr
    if (!fragment.firstChild.isText && nodeHasChange(schema, fragment.firstChild)) {
      // Then set the openStart to 0 to insure this slice does not get joined with another slice.
      // This fixes the problem where multiples nodes being deleted would not have a tracked change on the first node.
      openStart = 0;
    }
  }

  return new Slice(fragment, openStart, openEnd);
}
