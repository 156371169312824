<mc-infinite-list [allItemsLoaded]="allPagesLoaded$ | async" [itemHeight]="itemHeight" [items]="items$ | async" [listClass]="listClass" [listType]="ListType.Standard" [loaderBackgroundColor]="loaderBackgroundColor" [loaderColor]="loaderColor" [loadingDetailedErrors]="loadingState.detailedErrors$ | async" [loadingGeneralError]="loadingState.generalError$ | async" [loading]="loadingState.loading$ | async" [loadingMore]="loadingMoreState.loading$ | async" [loadingMoreDetailedErrors]="loadingMoreState.detailedErrors$ | async" [loadingMoreGeneralError]="loadingMoreState.generalError$ | async" (retryLoad)="onRetryLoad()">
  <ng-template mcListItem let-item>
    <ng-container [ngTemplateOutlet]="listItemDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  </ng-template>

  <ng-template *ngIf="allItemsLoadedListDirective" mcAllItemsLoadedList>
    <ng-container [ngTemplateOutlet]="allItemsLoadedListDirective?.templateRef"></ng-container>
  </ng-template>

  <ng-template *ngIf="emptyListDirective" mcEmptyList>
    <ng-container [ngTemplateOutlet]="emptyListDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: emptyListData$ | async }"></ng-container>
  </ng-template>
</mc-infinite-list>