import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { DynamicViewBase } from '@portal-core/text-editor/util/dynamic-view.base';

@Component({
  selector: 'mc-dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicViewComponent {
  @Input() componentType: any;
  @Input() inputs: Dictionary;
  @Input() outputs: Dictionary;

  componentRef: ComponentRef<DynamicViewBase>;

  private classNameSuffix: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private elementRef: ElementRef) { }

  onComponentCreated(componentRef: ComponentRef<DynamicViewBase>) {
    this.componentRef = componentRef;

    this.updateClassName();
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();

    if (this.componentRef) {
      this.componentRef.instance.detectChanges();
    }

    this.updateClassName();
  }

  private updateClassName() {
    const classNameSuffix = this.componentRef?.instance?.parentClassNameSuffix;

    if (this.classNameSuffix !== classNameSuffix) {
      this.elementRef.nativeElement.classList.remove(`mc-dynamic-view-type-${this.classNameSuffix}`);
    }

    if (classNameSuffix) {
      this.elementRef.nativeElement.classList.add(`mc-dynamic-view-type-${classNameSuffix}`)
    }

    this.classNameSuffix = classNameSuffix;
  }
}
