import { Pipe, PipeTransform } from '@angular/core';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcLicenseUserForLicenseAsync'
})
export class LicenseUserForLicenseAsyncPipe implements PipeTransform {
  constructor(private licenseUsersService: LicenseUsersService) { }

  transform(
    userId: string | null | undefined,
    licenseId: number | null | undefined,
    allowApiRequest: boolean = false
  ): Observable<LicenseUser | null> {
    if (!userId || !licenseId) {
      return of(null);
    }

    return this.licenseUsersService.getLicenseUserByUserId$(userId, licenseId, {
      allowApiRequest
    });
  }
}
