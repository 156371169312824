import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationPackageLanguageBranchesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  uploadPackage$(file: File, translationPackageId: number): Observable<MadCloudResult> {
    const formData = new FormData();
    formData.append('File', file);
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackageLanguageBranch/${translationPackageId}/uploadPackage`, formData);
  }

  @SharePendingRequest()
  getProjectTranslationPackageLanguageBranches$(projectId: number, filter: PageFilter): Observable<Page<TranslationPackageLanguageBranch>> {
    return this.http.post<Page<TranslationPackageLanguageBranch>>(`${this.apiService.centralApiBaseUri}/api/TranslationPackageLanguageBranch/${projectId}/getProjectLanguageBranches`, filter);
  }

  deletePackage$(translationPackageId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackageLanguageBranch/${translationPackageId}`);
  }

  deletePackages$(translationPackageLanguageBranchIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackageLanguageBranch/bulkDelete`, translationPackageLanguageBranchIds);
  }

  updatePackageLanguageStatuses$(translationPackageLanguageBranchIds: number[], status: TranslationPackageStatus): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackageLanguageBranch/status/${status}`, translationPackageLanguageBranchIds);
  }
}
