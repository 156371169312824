import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-project-deletion-form',
  templateUrl: './project-deletion-form.component.html',
  styleUrls: ['./project-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDeletionFormComponent {
  @Input() project: Project;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private errorService: ErrorService, private snackBar: MatSnackBar, private projectsService: ProjectsService) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onDeleteClicked() {
    this.savingState.update(true);

    this.projectsService.bulkDeleteProjects$([this.project.Id], this.project.LicenseId).subscribe(() => {
      this.savingState.update(false);

      this.snackBar.open('The project has been deleted.', 'OK', { duration: 2500 });
      this.deleted.emit();
    }, error => {
      this.savingState.update(false, 'Unable to delete the project.', this.errorService.getErrorMessages(error));
    });
  }

  onSubmit() {
    this.savingState.update(true);

    this.projectsService.bulkUpdateProjectStatus$(ProjectStatus.Archived, [this.project.Id]).subscribe(() => {
      this.savingState.update(false);

      this.snackBar.open('The project has been archived.', 'OK', {
        duration: 2500
      });
    }, error => {
      this.savingState.update(false, 'Unable to archive the project.', this.errorService.getErrorMessages(error));
    });
  }
}
