import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { Message } from '@portal-core/messages/models/message.model';
import { MessagesApiService } from '@portal-core/messages/services/messages-api.service';
import { MessagesDataService } from '@portal-core/messages/services/messages-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class MessagesService extends CollectionServiceBase<Message> {
  constructor(private messagesApiService: MessagesApiService,
    private messagesDataService: MessagesDataService,
    protected dataService: DataService) {
    super(messagesDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<Message> {
    return this.messagesApiService.getMessageById$(itemId);
  }

  removeConversationMessages$(conversationId: number): Observable<any> {
    return this.removeItems$(this.getItemsByProperties('ConversationId', conversationId));
  }
}
