import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { TeamPageFilterConfig } from '@portal-core/teams/util/team-page-filter-config';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { of } from 'rxjs';

@Component({
  selector: 'mc-teams-select-list',
  templateUrl: './teams-select-list.component.html',
  styleUrls: ['./teams-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as TeamsSelectListComponent so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => TeamsSelectListComponent) }]
})
export class TeamsSelectListComponent extends SelectListBase<Team> implements OnChanges {
  /** The license id that the teams belong to. */
  @Input() licenseId: number;

  filterConfig: TeamPageFilterConfig = new TeamPageFilterConfig(['Name']);

  listControl: SelectionListControl<Team> = new SelectionListControl<Team>(this.teamsService, filter => {
    if (typeof this.licenseId === 'number') {
      return this.teamsService.getTeamsPageByLicenseId$(this.licenseId, 0, filter);
    } else {
      return of(null);
    }
  }, team => {
    return team.Id;
  });

  constructor(private teamsService: TeamsService) {
    super(teamsService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId) {
      this.hardReload();
    }
  }
}
