import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';

@Component({
  selector: 'mc-grid-string-filter',
  templateUrl: './grid-string-filter.component.html',
  styleUrls: ['./grid-string-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridStringFilterComponent extends GridFilterBase {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  constructor(protected formBuilder: UntypedFormBuilder, pageFilterService: PageFilterService) {
    super(pageFilterService);

    // Do this in the constructor so that the form is ready for ngOnChanges
    this.buildForm();
  }

  onMenuOpened() {
    super.onMenuOpened();

    // Give focus to the search field if there is no custom menu
    if (!this.menuTemplate) {
      this.searchInput.nativeElement.focus();
    }
  }

  onSubmit() {
    this.submitFilter(this.buildFilter(this.filterForm.value['search']));
  }

  buildFilter(value: string): PageFilterGroup {
    return this.pageFilterService.create(this.filterable.getFilter(GridFilterName.String) ?? {
      Id: GridFilterName.String,
      Type: PageFilterGroupType.String
    }).string(this.column.name, value, {
      PropertyType: this.column.filterDataType ?? this.column.type,
      PropertyName: this.column.filterName
    }).value;
  }

  protected buildForm() {
    this.filterForm = this.formBuilder.group({
      search: new UntypedFormControl(null)
    });
  }

  protected buildFormValue(pageFilter: PageFilterGroup): Dictionary {
    let search: string;
    if (this.column && this.filterable) {
      search = this.pageFilterService.getValueFromStrings(pageFilter, this.column.name);
    }

    return { search };
  }

  protected getGridFilterName(): GridFilterName {
    return GridFilterName.String;
  }
}
