import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { Commit } from '@portal-core/commits/models/commit.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { Observable, map } from 'rxjs';

/**
 * mc-commit-avatar
 * A component for displaying a commit icon with its id.
 */
@Component({
  selector: 'mc-commit-avatar',
  templateUrl: './commit-avatar.component.html',
  styleUrls: ['./commit-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommitAvatarComponent extends AvatarBase implements OnInit {
  @Input() commit: Commit | string;

  @PropertyObservable('commit') commit$: Observable<Commit | string>;

  commitId$: Observable<string>;

  ngOnInit() {
    this.commitId$ = this.commit$.pipe(
      map(commit => {
        let commitId: string;

        if (typeof commit === 'string') {
          commitId = commit;
        } else if (commit) {
          commitId = commit.Id;
        }

        return commitId ? commitId.slice(0, 8) : '';
      })
    );
  }

  hasAvatarImage(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.commit;
  }

  isLink(): boolean {
    return false;
  }
}

