<mc-list [dataListId]="dataListId" [itemHeight]="48" [listClass]="'mc-list-hoverable' + (listClass ? ' ' + listClass : '')" [listControl]="listControl">
  <ng-template mcListItem let-reviewFile>
    <mat-list-item class="mc-list-item">
      <mc-review-file-avatar compact [reviewFile]="reviewFile" [showPath]="true" size="small" [truncate]="true"></mc-review-file-avatar>

      <ng-container *ngIf="showDeleteButton">
        <span class="mc-fill-remaining-flex-space"></span>

        <mat-icon *ngIf="getDeletingState(reviewFile)?.generalError$ | async as generalError" class="mc-image-icon mc-image-icon-xxxx-small" color="warn" fontSet="project-titan" fontIcon="icon-alert" [matTooltip]="generalError"></mat-icon>

        <button mat-icon-button class="mc-review-file-list-delete-button" color="warn" [disabled]="getDeletingState(reviewFile)?.loading$ | async" [matTooltip]="(getDeletingState(reviewFile)?.loading$ | async) ? 'Removing review file' : 'Remove review file'" (click)="onDeleteFileClicked(reviewFile)">
          <mat-progress-spinner *ngIf="getDeletingState(reviewFile)?.loading$ | async; else trashIcon" color="primary-vivid" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
          <ng-template #trashIcon>
            <mat-icon fontSet="project-titan" fontIcon="icon-trash"></mat-icon>
          </ng-template>
        </button>
      </ng-container>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>No review files to display.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All review files loaded.</p>
  </ng-template>
</mc-list>