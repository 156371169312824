<mc-switch-board [color]="color">
  <mc-switch-board-bar class="mc-spring-board">
    <div class="mc-spring-board-header">
      <mc-license-user-avatar [licenseUser]="licenseUser$ | async" [showName]="false" [disabled]="true" [showAdmin]="true" [showOnlineStatus]="true" size="large"></mc-license-user-avatar>
    </div>

    <div class="mc-spring-board-body">
      <ng-container *ngIf="licenseUser$ | async as licenseUser">
        <h3 class="text-x-large">{{ licenseUser.User.FullName}}</h3>
        <p class="text-light-weight">{{ licenseUser.User.Email }}</p>
        <p class="text-light-weight">
          {{ licenseUser.User.Title }}
          <span *ngIf="licenseUser.User.Title && licenseUser.User.Department">|</span>
          {{ licenseUser.User.Department }}
        </p>
        <p class="text-light-weight">{{ licenseUser.User.Location }}</p>
        <p class="text-light-weight">{{ phoneNumber$ | async }}</p>
        <p class="text-light-weight">{{ cellPhoneNumber$ | async }}</p>
      </ng-container>
    </div>

    <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
      <a href="#" *ngIf="showSettingsTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Settings" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-gear'" color="primary"></mat-icon> Settings
      </a>
      <a href="#" *ngIf="showSecurityTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Security" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-privacy'" color="primary"></mat-icon> Password
      </a>
      <a href="#" *ngIf="showAccessTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Access" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-assign-task'" color="primary"></mat-icon> Access
      </a>
      <a href="#" *ngIf="showMessageTab$ | async" mat-list-item mcPreventDefault="click" (click)="onMessageClicked()">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-mail'" color="primary"></mat-icon> Message
      </a>
      <a href="#" *ngIf="showAssignNewTaskTab$ | async" mat-list-item mcPreventDefault="click" (click)="onAssignTaskClicked()">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-pin'" color="primary"></mat-icon> Assign New Task
      </a>
      <a href="#" *ngIf="showActivityTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Activity" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-clock'" color="primary"></mat-icon> Activity
      </a>
      <a href="#" *ngIf="showPermissionsTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Permissions" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-list'" color="primary"></mat-icon> Permissions
      </a>
      <a href="#" *ngIf="showNotificationsTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Notifications" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-bell'" color="primary"></mat-icon> Notifications
      </a>
      <a href="#" *ngIf="showDeactivateTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Deactivate" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-close'" color="primary"></mat-icon> Deactivate
      </a>
      <a href="#" *ngIf="showDeleteTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.Delete" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'" color="primary"></mat-icon> Delete
      </a>
      <a href="#" *ngIf="showResetPasswordTab$ | async" mat-list-item [mcProfileTab]="UserProfileTab.ResetPassword" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-pw'" color="primary"></mat-icon> Reset Password
      </a>
    </mat-nav-list>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="profileTab" class="mc-spring-board">
    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Settings">
      <mat-tab label="Profile">
        <mc-user-settings-form [licenseUser]="licenseUser$ | async" [showIdpLogout]="licenseSSOEnabled$ | async" (cancel)="onCancel()" (save)="onSave($event, UserProfileForm.Settings)" (saved)="onSettingsSaved()"></mc-user-settings-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Security">
      <mat-tab label="Password">
        <mc-user-password-form [licenseUser]="licenseUser$ | async" (cancel)="onCancel()" (save)="onSave($event, UserProfileForm.Password)" (saved)="onPasswordSaved()"></mc-user-password-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="UserProfileTab.Access" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Access">
        <mc-user-access-form class="mc-switch-board-body-slim" [licenseUser]="licenseUser$ | async" [userTeams]="userTeams$ | async" [userProjects]="userProjects$ | async" (save)="onSave($event, UserProfileForm.Access)" (saved)="onTeamsAndProjectsSaved()"></mc-user-access-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Activity">
      <mat-tab label="Activity">
        <mc-user-notification-list [licenseUser]="licenseUser$ | async"></mc-user-notification-list>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Permissions">
      <mat-tab label="Permissions">
        <mc-permissions-form [licenseId]="userProfile?.LicenseUser?.LicenseId" [entityId]="userProfile?.LicenseUser?.User?.Id" [permissionsFormControl]="permissionsFormControl" [projectIds]="userProjectIdsNotArchived$ | async" [readonly]="!(userCanManageUsers$ | async)" (cancel)="onCancel()" (save)="onSave($event, UserProfileForm.Permissions)" (saved)="onPermissionsSaved()"></mc-permissions-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Notifications">
      <mat-tab label="Notifications">
        <mc-user-notifications-editor (cancel)="onCancel()" (save)="onSave($event, UserProfileForm.Notifications)" (saved)="onNotificationsSaved()"></mc-user-notifications-editor>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Deactivate">
      <mat-tab label="Deactivate">
        <mc-user-deactivation-form [licenseUser]="licenseUser$ | async" (cancel)="onCancel()" (deactivate)="onSave($event, UserProfileForm.Deactivate)" (deactivated)="onUserDeactivated()"></mc-user-deactivation-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.Delete">
      <mat-tab label="Delete">
        <mc-user-deletion-form [licenseUser]="licenseUser$ | async" (cancel)="onCancel()" (deactivate)="onSave($event, UserProfileForm.Delete)" (deactivated)="onUserDeactivated()" (delete)="onSave($event, UserProfileForm.Delete)" (deleted)="onUserDeleted()"></mc-user-deletion-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border" *ngSwitchCase="UserProfileTab.ResetPassword">
      <mat-tab label="Reset Password">
        <mc-user-password-reset-form [licenseUser]="licenseUser$ | async" (cancel)="onCancel()" (resetting)="onSave($event, UserProfileForm.ResetPassword)" (reset)="onPasswordReset()"></mc-user-password-reset-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>