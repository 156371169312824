import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Build } from '@portal-core/builds/models/build.model';

@CollectionStateSelectors({
  stateType: BuildsState
})
@State<BuildsState>({
  name: BuildsState.source,
})
@Injectable()
export class BuildsState extends CollectionStateBase {
  static source = 'Builds';
  getSource(): string {
    return BuildsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BuildsDataService extends CollectionDataServiceBase<Build> {
  constructor(protected store: Store) {
    super(store, BuildsState);
  }
}
