import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserListModule } from '@portal-core/reviews/review-file-users/components/review-file-user-list/review-file-user-list.module';
import { ReviewFileReviewersFormComponent } from './review-file-reviewers-form.component';

@NgModule({
  declarations: [ReviewFileReviewersFormComponent],
  imports: [
    CommonModule,
    ReviewFileUserListModule
  ],
  exports: [ReviewFileReviewersFormComponent]
})
export class ReviewFileReviewersFormModule { }
