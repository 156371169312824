import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { A11yModule } from '@angular/cdk/a11y';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { BuildPickerModule } from '@portal-core/builds/components/build-picker/build-picker.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { BuildPickerInputComponent } from './build-picker-input.component';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { BuildAsyncModule } from '@portal-core/builds/pipes/build-async/build-async.module';

@NgModule({
  declarations: [BuildPickerInputComponent],
  imports: [
    A11yModule,
    BranchAvatarModule,
    BuildAvatarModule,
    BuildPickerModule,
    CommonModule,
    DropdownArrowModule,
    MatFormFieldModule,
    MatInputModule,
    PopupModule,
    ProjectAvatarModule,
    TargetAvatarModule,
    ProjectAsyncModule,
    BuildAsyncModule
  ],
  exports: [BuildPickerInputComponent]
})
export class BuildPickerInputModule {}
