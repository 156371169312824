import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MadCapImageUrlModule } from '@portal-core/general/pipes/madcap-image-url/madcap-image-url.module';
import { LicenseUserAvatarTooltipClassModule } from '@portal-core/license-users/pipes/license-user-avatar-tooltip-class/license-user-avatar-tooltip-class.module';
import { LicenseUserAvatarTooltipModule } from '@portal-core/license-users/pipes/license-user-avatar-tooltip/license-user-avatar-tooltip.module';
import { AvatarIndicatorModule } from '@portal-core/ui/avatar/directives/avatar-indicator/avatar-indicator.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { LicenseUserAvatarComponent } from './license-user-avatar.component';

@NgModule({
  imports: [
    AvatarIndicatorModule,
    CommonModule,
    InlineButtonModule,
    LicenseUserAvatarTooltipClassModule,
    LicenseUserAvatarTooltipModule,
    MadCapImageUrlModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule
  ],
  declarations: [
    LicenseUserAvatarComponent
  ],
  exports: [
    AvatarIndicatorModule,
    LicenseUserAvatarComponent
  ]
})
export class LicenseUserAvatarModule { }
