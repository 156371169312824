import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ErrorsExpansionPanelComponent } from './errors-expansion-panel.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule
  ],
  declarations: [
    ErrorsExpansionPanelComponent
  ],
  exports: [
    ErrorsExpansionPanelComponent
  ]
})
export class ErrorsExpansionPanelModule { }
