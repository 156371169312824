import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcReviewFileAsync'
})
export class ReviewFileAsyncPipe implements PipeTransform {
  constructor(private reviewFilesService: ReviewFilesService) { }

  transform(reviewFileId: number | null | undefined, allowApiRequest: boolean = false): Observable<ReviewFile | null> {
    if (!reviewFileId) {
      return of(null);
    }

    return this.reviewFilesService.getItemById$(reviewFileId, { allowApiRequest });
  }
}
