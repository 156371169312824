import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { SelfTruncatingPathModule } from '@portal-core/general/components/self-truncating-path/self-truncating-path.module';
import { TrimModule } from '@portal-core/general/pipes/trim/trim.module';
import { ReviewFileAvatarTooltipClassModule } from '@portal-core/reviews/review-files/pipes/review-file-avatar-tooltip-class/review-file-avatar-tooltip-class.module';
import { ReviewFileAvatarTooltipModule } from '@portal-core/reviews/review-files/pipes/review-file-avatar-tooltip/review-file-avatar-tooltip.module';
import { ReviewFileLinkModule } from '@portal-core/reviews/review-files/pipes/review-file-link/review-file-link.module';
import { ReviewFileStatusColorModule } from '@portal-core/reviews/review-files/pipes/review-file-status-color/review-file-status-color.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { ReviewFileAvatarComponent } from './review-file-avatar.component';

@NgModule({
  declarations: [
    ReviewFileAvatarComponent
  ],
  imports: [
    CommonModule,
    InlineButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    ReviewFileAvatarTooltipClassModule,
    ReviewFileAvatarTooltipModule,
    ReviewFileLinkModule,
    ReviewFileStatusColorModule,
    RouterModule,
    SelfTruncatingPathModule,
    TrimModule
  ],
  exports: [
    ReviewFileAvatarComponent
  ]
})
export class ReviewFileAvatarModule { }
