import { updateFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { NodeType } from 'prosemirror-model';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

/**
 * Command that either inserts a multimedia tag or 3d model.
 * @param editorState The current state of the editor.
 * @param editorView The current view of the editor.
 * @param dispatch Runs the command on the editor.
 * @param attrs A multimedia object has width and height currently, its placed in the style attribute of the tag
 * @param isHTML5Video Html5 videos require a bunch more attributes to be set to true which is then used by the editor to show that its an HTML5 video.
 */
export declare type MultimediaNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary, isHTML5Video?: boolean) => boolean;

/**
 * Makes the editor insert or edit a multimedia tag or 3d-model tag inside of itself.
 * @param multimediaType The current type to insert or edit, can be multimedia or a 3d-model.
 * @return returns a function that handles the insert or edit.
 */
export function insertOrEditMultimedia(multimediaType: NodeType): MultimediaNodeCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary, isHTML5Video?: boolean): boolean {
    const selection = editorState.selection;
    const $from = selection.$from;
    const index = $from.index();

    if (!$from.parent.canReplaceWith(index, index, multimediaType)) {
      return false;
    }

    if (dispatch) {
      const node = selection instanceof NodeSelection && selection.node.type === multimediaType ? selection.node : undefined;

      if (isHTML5Video) {
        attrs = {
          ...attrs,
          'MadCap:HTML5Video': true,
          'MadCap:Param_controls': 'true', // This attribute is not simply a boolean value, it can be other string values as well so use a string value.
          'MadCap:Param_muted': true,
          'MadCap:Param_loop': true,
          'MadCap:Param_autoplay': true
        };
      }

      const tr = editorState.tr;
      if (node) {
        updateFlareNodeAttrs(tr, node, $from.pos, attrs);
        tr.setSelection(NodeSelection.create(tr.doc, $from.pos));
      } else {
        tr.replaceSelectionWith(multimediaType.create(attrs));
      }

      dispatch(tr.scrollIntoView());
    }

    return true;
  }
}
