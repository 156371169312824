import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';

@Pipe({
  name: 'mcReviewFileStatusColor'
})
export class ReviewFileStatusColorPipe implements PipeTransform {
  transform(status: ReviewFileStatus | null | undefined, luminosity: '' | 'muted' | 'vivid' = ''): string {
    switch (status) {
      case ReviewFileStatus.Open:
        return `caution${luminosity ? '-' + luminosity : ''}`;
      case ReviewFileStatus.Closed:
      case ReviewFileStatus.Accepted:
        return `info${luminosity ? '-' + luminosity : ''}`;
    }
  }
}
