import { Directive, ViewChild } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { ListComponent } from '@portal-core/ui/list/components/list/list.component';
import { ListCoreBase } from '@portal-core/ui/list/util/list-core.base';

/**
 * ListBase
 * The base class for list components.
 */
@Directive()
export abstract class ListBase<T> extends ListCoreBase<T> {
  /** A reference to the mc-select-list component. */
  @ViewChild(ListComponent, { static: true }) list: ListComponent;

  constructor(collectionService: CollectionServiceBase<T>) {
    super(collectionService);
  }

  hardReload() {
    this.list?.hardReload();
  }

  checkViewportSize() {
    this.list?.checkViewportSize();
  }
}
