import { Pipe, PipeTransform } from '@angular/core';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';

@Pipe({
  name: 'mcTranslationPackageStatusText'
})
export class TranslationPackageStatusTextPipe implements PipeTransform {
  transform(status: TranslationPackageStatus | null | undefined): string {
    switch (status) {
      case TranslationPackageStatus.New:
        return 'New';
      case TranslationPackageStatus.Sent:
        return 'Sent';
      case TranslationPackageStatus.InProgress:
        return 'In Progress';
      case TranslationPackageStatus.Uploading:
        return 'Uploading';
      case TranslationPackageStatus.Committing:
        return 'Committing';
      case TranslationPackageStatus.Received:
        return 'Received';
      case TranslationPackageStatus.Approved:
        return 'Approved';
      case TranslationPackageStatus.Canceled:
        return 'Canceled';
    }
  }
}
