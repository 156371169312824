import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { EmptyGridDisplay } from '@portal-core/ui/grid/types/empty-grid-display.type';

/**
 * GridControl
 * Defines behaviors for a GridComponent.
 */
export class GridControl {
  /**
   * constructor
   * @param getEmptyGridDisplay$ A function that returns the type of empty grid UI to show.
   */
  constructor(public getEmptyGridDisplay?: (filters: Dictionary<PageFilterGroup>) => EmptyGridDisplay) { }
}
