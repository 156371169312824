import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteAsyncPipe } from './site-async.pipe';

@NgModule({
  declarations: [SiteAsyncPipe],
  imports: [CommonModule],
  exports: [SiteAsyncPipe],
  providers: [SiteAsyncPipe]
})
export class SiteAsyncModule {}
