import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from '@portal-core/ui/charts/components/chart/chart.module';
import { DoughnutChartHoleModule } from '@portal-core/ui/charts/directives/doughnut-chart-hole/doughnut-chart-hole.module';
import { DoughnutChartComponent } from './doughnut-chart.component';

@NgModule({
  declarations: [
    DoughnutChartComponent
  ],
  imports: [
    ChartModule,
    CommonModule,
    DoughnutChartHoleModule
  ],
  exports: [
    DoughnutChartComponent
  ]
})
export class DoughnutChartModule { }
