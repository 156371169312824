
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, InjectionToken, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { propertyValue } from '@common/util/properties';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ReviewPackageProfile } from '@portal-core/profiles/models/review-package-profile.model';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { ReviewPackageUsersService } from '@portal-core/reviews/review-package-users/services/review-package-users.service';
import { ReviewPackageReviewersFormComponent } from '@portal-core/reviews/review-packages/components/review-package-reviewers-form/review-package-reviewers-form.component';
import { ReviewPackageSettingsFormComponent } from '@portal-core/reviews/review-packages/components/review-package-settings-form/review-package-settings-form.component';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

export interface ReviewPackageProfileOptions {
  showActivityTab?: boolean | (() => boolean);
}
export const MC_REVIEW_PACKAGE_PROFILE_OPTIONS = new InjectionToken<ReviewPackageProfileOptions>('ReviewPackageProfileOptions');

export enum ReviewPackageProfileTab {
  Overview,
  Settings,
  Files,
  Reviewers,
  Activity,
  Delete
}

export enum ReviewPackageProfileForm {
  Delete,
  Files,
  Reviewers,
  Settings
}

@Component({
  selector: 'mc-review-package-profile',
  templateUrl: './review-package-profile.component.html',
  styleUrls: ['./review-package-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageProfileComponent extends ProfileBase<ReviewPackageProfileTab> implements OnInit {
  @Input() reviewPackageProfile: ReviewPackageProfile;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<ReviewPackageProfileForm> = new EventEmitter<ReviewPackageProfileForm>();

  @InputObservable('reviewPackageProfile') reviewPackageProfile$: Observable<ReviewPackageProfile>;

  @ViewChild(ReviewPackageReviewersFormComponent, { static: false }) reviewPackageReviewersFormComponent: ReviewPackageReviewersFormComponent;
  @ViewChild(ReviewPackageSettingsFormComponent, { static: false }) reviewPackageSettingsFormComponent: ReviewPackageSettingsFormComponent;

  ReviewPackageProfileTab: typeof ReviewPackageProfileTab = ReviewPackageProfileTab;

  project$: Observable<Project>;
  ownerLicenseUser$: Observable<LicenseUser>;
  reviewPackage$: Observable<ReviewPackage>;
  reviewPackageUser$: Observable<ReviewPackageUser>;
  showDeleteTab$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;
  userCanManageReviews$: Observable<boolean>;

  showActivityTab: boolean = true;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case ReviewPackageProfileTab.Reviewers:
        return this.reviewPackageReviewersFormComponent?.dirty;
      case ReviewPackageProfileTab.Settings:
        return this.reviewPackageSettingsFormComponent?.dirty;
      default:
        return false;
    }
  }

  constructor(
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private currentService: CurrentService,
    private licenseUsersService: LicenseUsersService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private reviewPackagesService: ReviewPackagesService,
    private reviewPackageUsersService: ReviewPackageUsersService,
    @Optional() @Inject(MC_REVIEW_PACKAGE_PROFILE_OPTIONS) private reviewPackageProfileOptions: ReviewPackageProfileOptions,
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    this.showActivityTab = propertyValue(this.reviewPackageProfileOptions, 'showActivityTab', this.showActivityTab);

    // Create an observable for the user's edit permission
    this.userCanManageReviews$ = this.reviewPackageProfile$.pipe(
      switchMap(reviewPackageProfile => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageReviews, reviewPackageProfile?.ReviewPackage?.ProjectId))
    );

    // Create an observable for the review package
    this.reviewPackage$ = this.reviewPackageProfile$.pipe(
      switchMap(reviewPackageProfile => {
        if (typeof reviewPackageProfile?.ReviewPackage?.Id === 'number') {
          // Do not allow an api request because the review package profile data already contains the review package data
          return this.reviewPackagesService.getItemById$(reviewPackageProfile.ReviewPackage.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable for the review package user
    this.reviewPackageUser$ = this.reviewPackageProfile$.pipe(
      switchMap(reviewPackageProfile => {
        if (typeof reviewPackageProfile?.ReviewPackageUser?.Id === 'number') {
          return this.reviewPackageUsersService.getItemById$(reviewPackageProfile.ReviewPackageUser.Id);
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable of the project the review package belongs to
    this.project$ = this.reviewPackageProfile$.pipe(
      switchMap(reviewPackageProfile => {
        if (typeof reviewPackageProfile?.Project?.Id === 'number') {
          // Do not allow an api request because the review package profile data already contains the project data
          return this.projectsService.getItemById$(reviewPackageProfile.Project.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable of the review package's owner
    this.ownerLicenseUser$ = this.reviewPackageProfile$.pipe(
      switchMap(reviewPackageProfile => {
        if (typeof reviewPackageProfile?.Owner?.Id === 'number') {
          // Do not allow an api request because the review package profile data already contains the license user data
          return this.licenseUsersService.getItemById$(reviewPackageProfile.Owner.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create observables for which tabs to show in the sidebar
    this.showDeleteTab$ = combineLatest([this.currentService.getCurrentLicenseUserIsAuthor$(), this.userCanManageReviews$]).pipe(
      map(([isAuthor, userCanManageReviews]) => isAuthor && userCanManageReviews)
    );
    this.showSettingsTab$ = combineLatest([this.currentService.getCurrentLicenseUserIsAuthor$(), this.userCanManageReviews$]).pipe(
      map(([isAuthor, userCanManageReviews]) => isAuthor && userCanManageReviews)
    );
  }

  onCancel() {
    this.cancel.emit();
  }

  onFilesSaved() {
    this.saved.emit(ReviewPackageProfileForm.Files);
  }

  onReviewersSaved() {
    this.saved.emit(ReviewPackageProfileForm.Reviewers);
  }

  onReviewDeleted() {
    this.saved.emit(ReviewPackageProfileForm.Delete);
  }

  onSettingsSaved() {
    this.saved.emit(ReviewPackageProfileForm.Settings);
  }
}
