import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { UsersService } from '@portal-core/users/services/users.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-user-email-form',
  templateUrl: './user-email-form.component.html',
  styleUrls: ['./user-email-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEmailFormComponent implements OnInit {
  @Input() licenseUser: LicenseUser;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('formDirective', { static: true }) formDirective: FormGroupDirective;

  get dirty(): boolean {
    return this.emailForm?.dirty;
  }

  savingState: LoadingState<string> = new LoadingState<string>();
  showCancelButton: boolean;
  emailForm: UntypedFormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private usersService: UsersService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.showCancelButton = this.cancel.observers.length > 0;
    this.buildForm();
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }
    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      this.usersService.updateEmail$(this.licenseUser, formGroup.value['newEmail']).subscribe(() => {
        this.savingState.update(false);
        this.formDirective.resetForm();
        this.snackBar.open('Your email address has been updated.', 'OK', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to update your email address.', this.errorService.getErrorMessages(error));
      });
    }
  }

  protected buildForm() {
    this.emailForm = this.formBuilder.group({
      newEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
      confirmEmail: new UntypedFormControl('', [Validators.required, this.formsService.createMatchValueValidator('newEmail')])
    });
  }
}
