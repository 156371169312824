import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LinkService } from '@portal-core/general/services/link.service';
import { EmptySiteKey } from '@portal-core/sites/constants/empty-site-key.constant';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SiteTeam } from '@portal-core/sites/models/site-team.model';
import { SiteUpdate } from '@portal-core/sites/models/site-update.model';
import { Site } from '@portal-core/sites/models/site.model';
import { UserSite } from '@portal-core/sites/models/user-site.model';
import { SitesApiService } from '@portal-core/sites/services/sites-api.service';
import { SitesDataService } from '@portal-core/sites/services/sites-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, map, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class SitesService extends CollectionServiceBase<Site> {
  constructor(
    private sitesApiService: SitesApiService,
    private sitesDataService: SitesDataService,
    private linkService: LinkService,
    protected dataService: DataService,
    private apiService: ApiService
  ) {
    super(sitesDataService, dataService);
  }

  private getLiveSiteUrlTemplate$(): Observable<string> {
    return this.sitesApiService.getLiveSiteUrlTemplate$();
  }

  private getUrlFromTemplate(urlTemplate: string, vanityUrl: string): string {
    return urlTemplate
      .replaceAll('{license}', vanityUrl)
      .replaceAll('{license-subdomain}', vanityUrl + '.');
  }

  getDomainFromUrl(url: string) {
    return url && url.charAt(0) !== '.' && url.charAt(0) !== '/' ?
      new URL(url).hostname : url;
  }

  getLiveSiteUrl$(vanityUrl: string): Observable<string> {
    return this.getLiveSiteUrlTemplate$().pipe(
      map(urlTemplate => this.getUrlFromTemplate(urlTemplate, vanityUrl))
    );
  }

  getLiveSiteDomain$(vanityUrl: string): Observable<string> {
    return this.getLiveSiteUrl$(vanityUrl).pipe(
      map(this.getDomainFromUrl)
    );
  }

  isValidSiteKey(siteKey: string): boolean {
    return siteKey && siteKey !== EmptySiteKey;
  }

  injectSiteStyles$(siteKey: string): Observable<any> {
    return this.linkService.inject$('stylesheet', `${this.apiService.centralApiBaseUri}/api/SiteStyles/${siteKey}/css`);
  }

  setSitesAsLive$(siteIds: number[], live: boolean): Observable<MadCloudResult> {
    return this.sitesApiService.setSitesAsLive$(siteIds, live).pipe(
      switchMap((result) => {
        return this.sitesDataService.updateItems$(siteIds.map(siteId => {
          return {
            Id: siteId,
            IsLive: live
          };
        })).pipe(map(() => result));
      })
    );
  }

  createSite$(licenseId: number, siteSettings: Partial<SiteUpdate>): Observable<MadCloudResult> {
    return this.sitesApiService.createSite$(licenseId, siteSettings);
  }

  updateSiteSettings$(siteSettings: Partial<SiteUpdate>): Observable<MadCloudResult> {
    return this.sitesApiService.updateSiteSettings$(siteSettings).pipe(
      tap(() => {
        this.sitesApiService.getSiteGridItem$(siteSettings.Id).subscribe(newSite => {
          this.sitesDataService.updateItems$({ [newSite.Id]: newSite });
        });
      })
    );
  }

  updateSiteTeams$(siteId: number, siteTeams: SiteTeam[]): Observable<MadCloudResult> {
    return this.sitesApiService.updateSiteTeams$(siteId, siteTeams).pipe(
      tap(() => {
        this.updateItems$<SiteGridItem>({ [siteId]: { SiteTeams: siteTeams } });
      })
    );
  }

  deleteSites$(siteIds: number[]): Observable<MadCloudResult> {
    return this.sitesApiService.deleteSites$(siteIds).pipe(
      tap(() => {
        this.sitesDataService.removeItems$(siteIds);
      })
    );
  }

  protected fetchItemById$(itemId: number): Observable<Site> {
    return this.sitesApiService.getSiteById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<Site[]> {
    return this.sitesApiService.getSitesByIds$(itemIds);
  }

  getSiteGridItem$(siteId: number, options?: GetDataOptions): Observable<SiteGridItem> {
    return this.dataService.getData<SiteGridItem>({
      get: () => this.collectionDataService.getItemById$<SiteGridItem>(siteId),
      fetch: () => this.sitesApiService.getSiteGridItem$(siteId),
      set: newItem => this.collectionDataService.addItems$({ [newItem.Id]: newItem })
    }, options);
  }

  getSitesByLicenseId$(licenseId: number, options: GetDataOptions = null): Observable<Site[]> {
    return this.dataService.getDataListItems$<Site>('Licenses', licenseId, this.sitesDataService, {
      fetch: () => this.sitesApiService.getSitesByLicenseId$(licenseId).pipe(
        map(sites => {
          if (Array.isArray(sites)) {
            return sites.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name));
          }
        })
      )
    }, options);
  }

  getSiteListItemsByLicenseId$(licenseId: number, options: GetDataOptions = null): Observable<Site[]> {
    return this.dataService.getDataListItems$<Site>('ListItemsByLicense', licenseId, this.sitesDataService, {
      fetch: () => this.sitesApiService.getSiteListItemsByLicenseId$(licenseId).pipe(
        map(sites => {
          if (Array.isArray(sites)) {
            return sites.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name));
          }
        })
      )
    }, options);
  }

  getSitesListByLicenseHostMapId$(licenseHostMapId: number, options: GetDataOptions = null): Observable<Site[]> {
    return this.dataService.getDataListItems$<Site>('LicenseHostMaps', licenseHostMapId, this.sitesDataService, {
      fetch: () => this.sitesApiService.getSitesListByLicenseHostMapId$(licenseHostMapId).pipe(
        map(sites => {
          if (Array.isArray(sites)) {
            return sites.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name));
          }
        })
      )
    }, options).pipe(
      // Filter out any sites that are no longer associated with the license host map.
      // This allows the data list to reflect any changes made since last fetching the data list from the server.
      map(sites => {
        return Array.isArray(sites) ? sites.filter(site => site.LicenseHostMapId === licenseHostMapId) : null;
      })
    );
  }

  getSitesPageByLicenseList$(licenseId: number, filter: PageFilter): Observable<Page<SiteGridItem>> {
    return this.sitesApiService.getSitesPageByLicenseList$(licenseId, filter);
  }

  getSitesListByBuildId$(buildId: number, options: GetDataOptions = null): Observable<Site[]> {
    return this.dataService.getDataListItems$<Site>('Builds', buildId, this.sitesDataService, {
      fetch: () => this.sitesApiService.getSitesListByBuildId$(buildId).pipe(
        map(sites => {
          if (Array.isArray(sites)) {
            return sites.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name));
          }
        })
      )
    }, options).pipe(
      // Filter out any sites that are no longer associated with the build.
      // This allows the data list to reflect any changes made since last fetching the data list from the server.
      map(sites => {
        if (Array.isArray(sites)) {
          return sites.filter(site => {
            if (typeof site.BuildId === 'number') {
              return site.BuildId === buildId;
            } else if (typeof site.LastCompletedBuildId === 'number') {
              return site.LastCompletedBuildId === buildId;
            } else {
              return false;
            }
          });
        }
      })
    );
  }

  getSitesByTeamIds$(teamIds: number[]): Observable<Site[]> {
    return this.sitesApiService.getSitesByTeamIds$(teamIds);
  }

  getSiteIndexUrl$(siteKey: string): Observable<string> {
    return this.sitesApiService.getSiteIndexUrl$(siteKey);
  }

  getCurrentUserSites$(licenseId: number, liveSitesOnly: boolean = false): Observable<UserSite[]> {
    return this.sitesApiService.getCurrentUserSites$(licenseId, liveSitesOnly);
  }

  getLicenseId$(siteKey: string): Observable<number> {
    return this.sitesApiService.getLicenseId$(siteKey);
  }

  getVanityValidation$(licenseId: number, vanity: string, hostMapId?: number): Observable<MadCloudResult> {
    return this.sitesApiService.getVanityValidation$(licenseId, vanity, hostMapId);
  }

  getLiveSitesPage$(filter: PageFilter): Observable<Page<SiteGridItem>> {
    return this.sitesApiService.getLiveSitesPage$(filter);
  }

  isLocalOrigin(origin: string): boolean {
    return origin.includes('localhost') || origin.includes('localtest.me');
  }
}
