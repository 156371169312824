import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthStatusMessage } from '@portal-core/auth/enums/auth-status-message.enum';
import { OnAuthError } from '@portal-core/auth/interfaces/auth-error.interface';
import { OnAuthRoute } from '@portal-core/auth/interfaces/auth-route.interface';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { AuthRedirect } from '@portal-core/auth/types/auth-redirect.type';
import { AuthRouteData } from '@portal-core/auth/types/auth-route-data.type';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthEventHandlerService implements OnAuthError, OnAuthRoute {
  constructor(private router: Router, private authService: AuthService, private apiService: ApiService) { }

  onAuthError(type: AuthStatusMessage) {
    this.router.navigate(['/alert'], {
      queryParams: {
        type
      }
    });
  }

  onAuthRoute$(authRoute: AuthRoute, extras?: NavigationExtras, data?: AuthRouteData): Observable<AuthRedirect> {
    // If there is an instance code specified in the route data then the caller wants it to be used instead of the default instance code
    const instanceCode = data?.instanceCode;

    switch (authRoute) {
      case AuthRoute.ExpiredPassword:
        return of({
          commands: (instanceCode || this.authService.getInstanceCode())
            ? ['/instance', instanceCode ?? this.authService.getInstanceCode(), 'expired-password']
            : ['/expired-password'],
          extras
        });
      case AuthRoute.ForgotPassword:
        return of({
          commands: ['/forgot-password'],
          extras: this.addQueryParamsToExtras(extras, { instance: instanceCode ?? this.apiService.centralInstance?.InstanceCode })
        });
      case AuthRoute.Home:
      case AuthRoute.LicenseList:
        return of({
          commands: (instanceCode || this.authService.getInstanceCode())
            ? ['/instance', instanceCode ?? this.authService.getInstanceCode(), 'licenses']
            : ['/licenses'],
          extras
        });
      case AuthRoute.LicenseUrl:
        return of({
          commands: ['/login/license'],
          extras
        });
      case AuthRoute.Login:
        return of({
          commands: ['/'],
          extras: this.addQueryParamsToExtras(extras, { instance: instanceCode ?? this.apiService.centralInstance?.InstanceCode })
        });
      case AuthRoute.Logout:
        return of({
          commands: ['/logout'],
          extras: this.addQueryParamsToExtras(extras, { instance: instanceCode ?? this.authService.getInstanceCode() })
        });
      case AuthRoute.AuthLoginGuardError:
        return of({
          commands: ['/alert'],
          extras: this.addQueryParamsToExtras(extras, { instance: instanceCode ?? this.authService.getInstanceCode(), type: AlertType.AuthLoginError })
        });
      case AuthRoute.InstanceCodeGuardError:
        return of({
          commands: ['/instanceNotFound'],
          extras
        });
    }
  }

  private addQueryParamsToExtras(extras: NavigationExtras, queryParams: Params): NavigationExtras {
    return {
      ...extras,
      queryParams: {
        ...extras?.queryParams,
        ...queryParams
      }
    };
  }
}