import { FlareSchema } from '@common/flare/flare-schema';
import { nodeConditionsValue } from '@common/prosemirror/commands/conditions.command';
import { FragmentNodeInfo } from '@common/prosemirror/model/fragment';
import { Node } from 'prosemirror-model';
import { AllSelection, EditorState, Plugin, PluginKey, PluginView, Transaction } from 'prosemirror-state';
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { Observable, Subscription } from 'rxjs';

export const AppliedConditionsPluginKey = new PluginKey('appliedConditions');
const AppliedConditionsShowMetaKey: string = 'appliedConditions.show';

export interface AppliedConditionsPluginOptions {
  showConditions$: Observable<boolean>;
  schema: FlareSchema;
  processAppliedConditions: (conditions: string[]) => void;
}

export interface AppliedConditionsPluginState {
  enabled: boolean;
  decSet: DecorationSet;
}

/** A help class to describe a node, its position and applied condition. */
class AppliedConditionsItem {
  constructor(public node: Node, public from: number, public condition: string) { }

  public get to() {
    return this.from + this.node.nodeSize;
  }

  public static create(node: Node, pos: number, condition: string): AppliedConditionsItem {
    return new AppliedConditionsItem(node, pos, condition);
  }
}

/** A help class to build decorations for nodes with conditions. */
class AppliedConditionsDecorationBuilder {

  private blockDecorationClass = 'mc-madcap-condition-bg-decoration-block';
  private inlineDecorationClass = 'mc-madcap-condition-bg-decoration-inline';
  private markerBeforeDecorationClass = 'mc-madcap-condition-bg-decoration-marker';
  private markerAfterDecorationClass = 'mc-madcap-condition-bg-decoration-marker-a';

  constructor(private schema: FlareSchema, private showConditions: boolean) {
  }

  private createNodeDecoration(item: AppliedConditionsItem, decorationClasses: string[]): Decoration {
    let tagName = this.schema.getTagDisplayName(item.node);
    let title: string = `<${tagName}>\rconditions: ${item.condition}`;
    let attrs = this.showConditions
      ? { class: decorationClasses.join(' '), title: title }
      : { title: title };
    let context = { pos: item.from, tag: tagName, condition: item.condition };
    return Decoration.node(item.from, item.to, attrs, { conditionContext: context });
  }

  public buildItemDecoration(item: AppliedConditionsItem): Decoration {
    if (!item.node.isInline)
      return this.createNodeDecoration(item, [this.blockDecorationClass]);

    const typeName = item.node.type.name;

    let noMarker = typeName === 'iframe' || typeName === 'madcapsnippettext';
    if (noMarker)
      return this.createNodeDecoration(item, [this.inlineDecorationClass]);

    let hasBefore = typeName === 'unknowninline' || typeName === 'madcapconcept' || typeName === 'madcapkeyword'
      || typeName === 'madcapbookmark' || typeName === 'madcapnameddestination';
    return hasBefore
      ? this.createNodeDecoration(item, [this.inlineDecorationClass, this.markerAfterDecorationClass])
      : this.createNodeDecoration(item, [this.inlineDecorationClass, this.markerBeforeDecorationClass]);
  }
}

class AppliedConditionsPluginView implements PluginView {
  private showConditionsSubscription: Subscription = null;

  constructor(editorView: EditorView, private showConditions$?: Observable<boolean>) {
    // In some cases an error raised in collab mode if the 'init' function is called without setTimeout().
    // Need to study the issue more deeply.
    setTimeout(() => this.init(editorView));
  }

  destroy() {
    this.showConditionsSubscription?.unsubscribe();
  }

  private init(editorView: EditorView) {
    this.showConditionsSubscription = this.showConditions$
      .subscribe(showConditions => {
        // Its possible that the text editor was destroyed since this request was made so check that it still exists
        if (!editorView.isDestroyed) {
          // Update the meta data with the provided data
          const tr = editorView.state.tr;
          tr.setMeta(AppliedConditionsShowMetaKey, showConditions);
          editorView.dispatch(tr);
        }
      });
  }
}

/**
 * A prosemirror plugin that allows to track nodes with applied conditions and to build decorations for them.
 * Decoration allows to specify classes and attributes for the node with applied conditions to change its view in the browser.
 * For example, the plugin will assign different classes to block and inline elements to give them different background styles,
 * but their background color will only depend on the applied conditions.
 * Also the plugin uses 'title' attribute to add a tooltip with information about conditions applied to the node.
 *
 * The performance of this plugin is very important, especially while the user is typing, since the plugin requires keeping decorators up to date every time the document changes.
 * At the moment, the logic of the plugin is as follows:
 * 1. The plugin goes through all document nodes only when loading a file, or when switching the ShowConditions setting
 * 2. When a document is being changed, the plugin inspects only the change itself, and not the entire document, for example:
 *    - When deleting a fragment of a document, only decorators related to this fragment are removed.
 *    - Similarly, when inserting a fragment, only the fragment itself should be analyzed.
 *    - If a change occurs in a text element, then this change does not need to be analyzed; it allows for minimal impact on the user’s editing.
 */
export function appliedConditionsPlugin(options: AppliedConditionsPluginOptions): Plugin {

  let showConditions: boolean = false;

  function createNodeInfo(node: Node, offset: number): FragmentNodeInfo {
    return {
      node: node,
      from: offset,
      to: offset + node.nodeSize
    };
  }

  function getNodesWithCondition(root: Node, offset: number): AppliedConditionsItem[] {
    const nodesWithCondition: AppliedConditionsItem[] = [];
    let appliedConditions: string[] = [];

    function inspectNode(node: Node, pos: number) {
      const condition = nodeConditionsValue(node);
      if (!condition)
        return;
      if (!appliedConditions.includes(condition))
        appliedConditions.push(condition);
      nodesWithCondition.push(AppliedConditionsItem.create(node, pos, condition));
    }

    const isDoc = offset === 0;

    // inspect current node
    if (!isDoc)
      inspectNode(root, offset);

    // if current node is not a doc then correct offset for nested nodes
    if (!isDoc)
      offset++;

    // inspect nested nodes
    root.descendants((node, pos) => {
      inspectNode(node, offset + pos);
    });

    if (appliedConditions.length)
      options.processAppliedConditions(appliedConditions);

    return nodesWithCondition;
  }

  function getConditionsDecs(root: Node, offset: number): Decoration[] {
    let decs: Decoration[] = [];
    let builder = new AppliedConditionsDecorationBuilder(options.schema, showConditions);
    getNodesWithCondition(root, offset).forEach(item => {
      decs.push(builder.buildItemDecoration(item));
    });
    return decs;
  }

  function getConditionsDecSet(doc: Node): DecorationSet {
    let decs: Decoration[] = getConditionsDecs(doc, 0);
    return DecorationSet.create(doc, decs);
  }

  function initState(doc: Node): AppliedConditionsPluginState {
    const state: AppliedConditionsPluginState = { enabled: showConditions, decSet: getConditionsDecSet(doc) };
    return state;
  }

  function isAllDocumentUpdated(tr: Transaction, oldState: EditorState) {
    if (tr.steps.length !== 1)
      return false;
    const step = tr.steps[0];
    return (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) && step.from === 0 && step.to === oldState.doc.content.size;
  }

  function getNodeInfoAt(pos: number, doc: Node) {
    const resolvedPos = doc.resolve(pos);
    const from = pos - resolvedPos.parentOffset - 1; // -1 to include the position that is the start of the node itself (the node's start token)
    return createNodeInfo(resolvedPos.parent, from);
  }

  function updateDecorations(tr: Transaction, oldState: EditorState, newState: EditorState, decSet: DecorationSet): DecorationSet {
    // if the entire document was selected
    if (oldState.selection instanceof AllSelection || isAllDocumentUpdated(tr, oldState)) {
      // generate a new decoration set
      return getConditionsDecSet(tr.doc);
    }

    function getMappedPosition(pos: number, fromStep: number = 0): number | null {
      tr.mapping.from = fromStep;
      const result = tr.mapping.mapResult(pos);
      return result.deleted ? null : result.pos;
    }

    const doc = newState.doc;
    const nodesToUpdate = [];

    // apply transaction mapping to current decoration set
    {
      // map transaction steps to decoration set and collect removed decorations
      const removedDecorations = [];
      decSet = decSet.map(tr.mapping, tr.doc, {
        onRemove(spec) {
          if (spec.conditionContext)
            removedDecorations.push(spec.conditionContext);
        }
      });

      // check whether nodes which decorations were removed should be updated again
      // e.g. if node was removed it is not required to be updated
      // but if node was splitted then it should be updated to restore removed decoration
      removedDecorations.forEach(conditionContext => {
        const mappedPosition = getMappedPosition(conditionContext.pos);
        if (typeof mappedPosition === 'number') {
          const nodeInfo = getNodeInfoAt(mappedPosition + 1, doc);
          nodesToUpdate.push(nodeInfo);
        }
      });

      // update decoration specs after mapping
      const decorations = decSet.find(0, doc.nodeSize);
      decorations.forEach(dec => {
        if (dec.spec.conditionContext)
          dec.spec.conditionContext.pos = dec.from;
      });
    }

    // look for new nodes inserted in transaction steps
    {
      function addNodeToUpdateIfPossible(pos: number, nextStep: number, node: Node = null) {
        // get final node position
        const mappedPosition = getMappedPosition(pos, nextStep);
        // add node to update list if node is not removed at the end of transaction
        if (typeof mappedPosition === 'number') {
          node = node || doc.nodeAt(mappedPosition);
          const nodeInfo = createNodeInfo(node, mappedPosition);
          nodesToUpdate.push(nodeInfo);
        }
      }

      tr.steps.forEach((step, i) => {
        const nextStep = i + 1;
        let offset = step.from;

        if (step instanceof ReplaceStep) {
          let firstIdx = 0;
          // if we have only part of the first inserted node
          if (step.slice.openStart > 0) {
            const child = step.slice.content.child(0);
            offset = offset + child.nodeSize - step.slice.openStart;
            firstIdx++;
          }

          let lastIdx = step.slice.content.childCount;
          // if we have only part of the last inserted node
          if (step.slice.openEnd > 0)
            lastIdx--;

          // add rest inserted nodes
          for (let i = firstIdx; i < lastIdx; i++) {
            const child = step.slice.content.child(i);
            if (!child.type.isText) {
              addNodeToUpdateIfPossible(offset, nextStep, child);
            }
            offset = offset + child.nodeSize;
          }

          // if we have only part of the last inserted node
          if (step.slice.openEnd > 0)
            addNodeToUpdateIfPossible(offset, nextStep);
        } else if (step instanceof ReplaceAroundStep) {
          addNodeToUpdateIfPossible(offset, nextStep);
        }
      });
    }

    let newDecs: Decoration[] = [];
    // inspect inserted nodes
    nodesToUpdate.forEach(info => {
      newDecs = newDecs.concat(getConditionsDecs(info.node, info.from));
    });
    // add new decorations to current decoration set
    newDecs.forEach(dec => {
      const res = decSet.find(dec.from, dec.to);
      let exists = res.some(el => el.from === dec.from && el.to === dec.to);
      if (!exists)
        decSet = decSet.add(tr.doc, [dec]);
    });

    return decSet;
  }

  const plugin = new Plugin({
    key: AppliedConditionsPluginKey,

    state: {
      init(_, { doc }) {
        return initState(doc);
      },
      apply(tr, value, oldState, newState) {
        // Check for an appliedConditions.show message
        const enabled: boolean = tr.getMeta(AppliedConditionsShowMetaKey);
        if (enabled !== undefined) {
          showConditions = enabled;
          return initState(tr.doc);
        }
        if (!tr.docChanged) {
          return value; // Return the current plugin state if the document was not changed.
        }
        try {
          // Analyze transaction changes to update condition decorations to improve performance.
          let decSet = updateDecorations(tr, oldState, newState, value.decSet);
          return { ...value, decSet: decSet };
        }
        catch (e) {
          // Reinit the plugin state to keep condition decorations consistent with the editing document if an error is occurred.
          // This should not happen and is only used to avoid aborting the transaction.
          console.error(e);
          return initState(tr.doc);
        }
      },
    },

    view(view: EditorView): PluginView {
      return new AppliedConditionsPluginView(view, options.showConditions$);
    },

    props: {
      decorations(state) {
        return (this.getState(state) as AppliedConditionsPluginState)?.decSet;
      },
    },
  });

  return plugin;
}
