import { Observable, share, timeout } from 'rxjs';

/**
 * Decorates a method that returns an observable. The returned observable is shared by subsequent calls to the method until that observable has completed or erred.
 * If the observable does not complete in 60 seconds then it automatically fails and the observable errs.
 */
export function SharePendingRequest(): MethodDecorator {
  return function (target: Object, name: string, descriptor: PropertyDescriptor) {
    // Create a cache of Observables for the function
    const cache = new Map<string, Observable<any>>();

    // Overwrite the original function with a function that caches an Observable until it completes
    const originalFunc = descriptor.value;
    descriptor.value = function (this: any, ...args: any[]): any {
      const cacheKey = JSON.stringify(args);

      // Grab the Observable from the cache
      let cachedObservable = cache.get(cacheKey);

      // If the Observable was not in the cache
      if (!cachedObservable) {
        // Call the original function and grab the Observable it returns
        cachedObservable = originalFunc.apply(this, args).pipe(
          timeout(60000), // Default to a one minute timeout
          share()
        );

        // Remove the Observable from the cache after it has completed or erred
        cachedObservable.subscribe({
          complete: () => cache.delete(cacheKey),
          error: () => cache.delete(cacheKey)
        });

        // Cache the Observable
        cache.set(cacheKey, cachedObservable);
      }

      // Return the cached Observable
      return cachedObservable;
    };

    return descriptor;
  };
}
