import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { CurrentService } from '@portal-core/current/services/current.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { MessageCenterDialogCardComponent } from '@portal-core/messages/components/message-center/message-center-dialog-card.component';
import { PermissionsFormComponent } from '@portal-core/permissions/components/permissions-form/permissions-form.component';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { PermissionsFormControl } from '@portal-core/permissions/util/permissions-form-control';
import { UserProfile } from '@portal-core/profiles/models/user-profile.model';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { TasksFilterService } from '@portal-core/tasks/services/tasks-filter.service';
import { Team } from '@portal-core/teams/models/team.model';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';
import { McThemePalette } from '@portal-core/ui/themes/types/mc-theme-palette.type';
import { UserAccessFormComponent } from '@portal-core/users/components/user-access-form/user-access-form.component';
import { UserEmailFormComponent } from '@portal-core/users/components/user-email-form/user-email-form.component';
import { UserNotificationsEditorComponent } from '@portal-core/users/components/user-notifications-editor/user-notifications-editor.component';
import { UserPasswordFormComponent } from '@portal-core/users/components/user-password-form/user-password-form.component';
import { UserSettingsFormComponent } from '@portal-core/users/components/user-settings-form/user-settings-form.component';
import { UserProfileTab } from '@portal-core/users/enums/user-profile-tab.enum';
import { UsersService } from '@portal-core/users/services/users.service';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

export enum UserProfileForm {
  Settings,
  Email,
  Password,
  Access,
  Permissions,
  Notifications,
  Deactivate,
  Delete,
  ResetPassword
}

export class UserProfileSaveEvent extends CancelableEvent {
  constructor(public readonly userProfileForm: UserProfileForm) {
    super();
  }
}

@Component({
  selector: 'mc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MC_FOCUSABLE, useExisting: UserProfileComponent }
  ]
})
export class UserProfileComponent extends ProfileBase<UserProfileTab> implements OnInit {
  /** The theme color of the user-profile. */
  @Input() color: McThemePalette = 'secondary-vivid';
  @Input() showSettings: boolean = true;
  @Input() showSecurity: boolean = true;
  @Input() showAccess: boolean = true;
  @Input() showMessage: boolean = true;
  @Input() showAssignNewTask: boolean = true;
  @Input() showActivity: boolean = true;
  @Input() showPermissions: boolean = true;
  @Input() showNotifications: boolean = true;
  @Input() showDeactivate: boolean = true;
  @Input() showDelete: boolean = true;
  @Input() showResetPassword: boolean = true;
  @Input() userProfile: UserProfile;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<UserProfileSaveEvent> = new EventEmitter<UserProfileSaveEvent>();
  @Output() saved: EventEmitter<UserProfileForm> = new EventEmitter<UserProfileForm>();

  @InputObservable('userProfile') userProfile$: Observable<UserProfile>;
  @InputObservable('showSettings') showSettings$: Observable<boolean>;
  @InputObservable('showSecurity') showSecurity$: Observable<boolean>;
  @InputObservable('showAccess') showAccess$: Observable<boolean>;
  @InputObservable('showMessage') showMessage$: Observable<boolean>;
  @InputObservable('showAssignNewTask') showAssignNewTask$: Observable<boolean>;
  @InputObservable('showActivity') showActivity$: Observable<boolean>;
  @InputObservable('showPermissions') showPermissions$: Observable<boolean>;
  @InputObservable('showNotifications') showNotifications$: Observable<boolean>;
  @InputObservable('showDeactivate') showDeactivate$: Observable<boolean>;
  @InputObservable('showDelete') showDelete$: Observable<boolean>;
  @InputObservable('showResetPassword') showResetPassword$: Observable<boolean>;

  @ViewChild(UserSettingsFormComponent) userSettingsFormComponent: UserSettingsFormComponent;
  @ViewChild(UserEmailFormComponent) userEmailFormComponent: UserEmailFormComponent;
  @ViewChild(UserPasswordFormComponent) userPasswordFormComponent: UserPasswordFormComponent;
  @ViewChild(UserAccessFormComponent) userAccessFormComponent: UserAccessFormComponent;
  @ViewChild(PermissionsFormComponent) permissionsFormComponent: PermissionsFormComponent;
  @ViewChild(UserNotificationsEditorComponent) userNotificationsEditorComponent: UserNotificationsEditorComponent;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case UserProfileTab.Settings:
        return this.userSettingsFormComponent?.dirty;
      case UserProfileTab.Security:
        return this.userEmailFormComponent?.dirty || this.userPasswordFormComponent?.dirty;
      case UserProfileTab.Access:
        return this.userAccessFormComponent?.dirty;
      case UserProfileTab.Permissions:
        return this.permissionsFormComponent?.dirty;
      case UserProfileTab.Notifications:
        return this.userNotificationsEditorComponent?.dirty;
      default:
        return false;
    }
  }

  UserProfileForm: typeof UserProfileForm = UserProfileForm;
  UserProfileTab: typeof UserProfileTab = UserProfileTab;

  cellPhoneNumber$: Observable<string>;
  currentUserIsAuthor$: Observable<boolean>;
  currentUserIsSystemAdmin$: Observable<boolean>;
  currentUserIsSupportAdmin$: Observable<boolean>;
  currentUserIsQAAdmin$: Observable<boolean>;
  isCurrentLicenseUser$: Observable<boolean>;
  licenseSSOEnabled$: Observable<boolean>;
  licenseUser$: Observable<LicenseUser>;
  phoneNumber$: Observable<string>;
  showSettingsTab$: Observable<boolean>;
  showSecurityTab$: Observable<boolean>;
  showAccessTab$: Observable<boolean>;
  showMessageTab$: Observable<boolean>;
  showAssignNewTaskTab$: Observable<boolean>;
  showActivityTab$: Observable<boolean>;
  showPermissionsTab$: Observable<boolean>;
  showNotificationsTab$: Observable<boolean>;
  showDeactivateTab$: Observable<boolean>;
  showDeleteTab$: Observable<boolean>;
  showResetPasswordTab$: Observable<boolean>;
  userCanDeleteUsers$: Observable<boolean>;
  userCanManageTasks$: Observable<boolean>;
  userCanManageUsers$: Observable<boolean>;
  userIsAuthor$: Observable<boolean>;
  userProjects$: Observable<Project[]>;
  userProjectIdsNotArchived$: Observable<number[]>;
  userTeams$: Observable<Team[]>;

  permissionsFormControl: PermissionsFormControl = new PermissionsFormControl(
    (userId: string, licenseId: number) => this.permissionsService.getUserPermissionsOnLicenseLevel$(userId, licenseId),
    (userId: string, projectId: number) => this.permissionsService.getUserPermissionsOnProjectLevel$(userId, projectId),
    (userId: string, licenseId: number, permissions: Permissions[]) => this.permissionsService.saveUserPermissionsOnLicenseLevel$(userId, licenseId, permissions),
    (userId: string, projectId: number, permissions: Permissions[]) => this.permissionsService.saveUserPermissionsOnProjectLevel$(userId, projectId, permissions)
  );

  constructor(
    dialog: MatDialog,
    cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
    private tasksFilterService: TasksFilterService,
    private currentService: CurrentService
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    // Create permission observables
    this.userCanDeleteUsers$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.DeleteUser);
    this.userCanManageTasks$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.EditAllTasks);
    this.userCanManageUsers$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.UserAdministration);

    // Create an observable for whether the current user is a system admin
    this.currentUserIsSystemAdmin$ = this.currentService.getCurrentLicenseUserIsSystemAdmin$();

    // Create an observable for whether the current user is a support admin
    this.currentUserIsSupportAdmin$ = this.currentService.getCurrentLicenseUserIsSupportAdmin$();

    // Create an observable for whether the current user is a qa admin
    this.currentUserIsQAAdmin$ = this.currentService.getCurrentLicenseUserIsQaAdmin$();

    // Create an observable for the license user's seat type
    this.userIsAuthor$ = this.userProfile$.pipe(
      map(userProfile => userProfile && userProfile.LicenseUser && userProfile.LicenseUser.SeatType === LicenseUserSeatType.Author)
    );

    // Create an observable for whether the current user is viewing their own profile
    this.isCurrentLicenseUser$ = this.userProfile$.pipe(
      map(userProfile => userProfile ? this.currentService.isCurrentLicenseUser(userProfile.LicenseUser.Id) : null)
    );

    // Create an observable for whether the current user is an author
    this.currentUserIsAuthor$ = this.currentService.getLicenseUser$().pipe(
      map(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.Author)
    );

    // Create an observable for the license user
    this.licenseUser$ = this.userProfile$.pipe(
      map(userProfile => {
        return userProfile ? userProfile.LicenseUser : null;
      })
    );

    // Create an observable for the whether the license user has the server management permission
    const licenseUserCanManageServer$ = this.userProfile$.pipe(
      map(userProfile => userProfile?.LicensePermissions),
      switchMap(permissions => this.permissionsService.hasPermission$(permissions, CentralPermissions.ServerManagement)),
      cache()
    );

    // Create an observable for whether the license has SSO enabled
    this.licenseSSOEnabled$ = this.userProfile$.pipe(
      map(userProfile => userProfile?.LicenseSamlSettings?.Enabled)
    );

    // Create an observable for the projects the user is assigned to
    this.userProjects$ = this.userProfile$.pipe(
      map(userProfile => userProfile ? userProfile.Projects : null)
    );

    // Create an observable for the projects the user is assigned to that are not archived
    this.userProjectIdsNotArchived$ = this.userProfile$.pipe(
      map(userProfile => userProfile ? userProfile.Projects.filter(project => project.Status !== ProjectStatus.Archived) : null),
      map(projects => projects?.map(project => project.Id))
    );

    // Create an observable for the teams the user is assigned to
    this.userTeams$ = this.userProfile$.pipe(
      map(userProfile => userProfile ? userProfile.Teams : null)
    );

    // Create an observable for the user's phone number
    this.phoneNumber$ = this.userProfile$.pipe(
      map(userProfile => userProfile && userProfile.LicenseUser && userProfile.LicenseUser.User ? this.usersService.getPrimaryPhoneNumber(userProfile.LicenseUser.User) : null)
    );

    // Create an observable for the user's cell phone number
    this.cellPhoneNumber$ = this.userProfile$.pipe(
      map(userProfile => userProfile && userProfile.LicenseUser && userProfile.LicenseUser.User ? this.usersService.getCellPhoneNumber(userProfile.LicenseUser.User) : null)
    );

    // Create an observable for whether the user's status is invited
    const licenseUserStatusIsInvited$ = this.userProfile$.pipe(
      map(userProfile => userProfile?.LicenseUser?.Status === LicenseUserStatus.Invited)
    );

    // Create observables for which tabs to show in the sidebar
    this.showSettingsTab$ = combineLatest(this.showSettings$, this.isCurrentLicenseUser$).pipe(
      map(([showSettings, isCurrentLicenseUser]) => showSettings && isCurrentLicenseUser)
    );
    this.showSecurityTab$ = combineLatest([this.showSecurity$, this.isCurrentLicenseUser$, licenseUserCanManageServer$, this.licenseSSOEnabled$]).pipe(
      map(([showSecurity, isCurrentLicenseUser, licenseUserCanManageServer, licenseSSOEnabled]) => showSecurity && isCurrentLicenseUser && (licenseUserCanManageServer || !licenseSSOEnabled))
    );
    this.showAccessTab$ = combineLatest(this.showAccess$, this.currentUserIsAuthor$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$).pipe(
      map(([showAccess, currentUserIsAuthor, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin]) => showAccess && (currentUserIsAuthor || currentUserIsSystemAdmin || currentUserIsSupportAdmin || currentUserIsQAAdmin))
    );
    this.showMessageTab$ = combineLatest(this.showMessage$, this.currentUserIsAuthor$, this.isCurrentLicenseUser$, this.userIsAuthor$).pipe(
      map(([showMessage, currentUserIsAuthor, isCurrentLicenseUser, userIsAuthor]) => showMessage && currentUserIsAuthor && !isCurrentLicenseUser && userIsAuthor)
    );
    this.showAssignNewTaskTab$ = combineLatest(this.showAssignNewTask$, this.currentUserIsAuthor$, this.userCanManageTasks$, this.userIsAuthor$).pipe(
      map(([showAssignNewTask, currentUserIsAuthor, userCanManageTasks, userIsAuthor]) => showAssignNewTask && currentUserIsAuthor && userCanManageTasks && userIsAuthor)
    );
    this.showActivityTab$ = combineLatest(this.showActivity$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$).pipe(
      map(([showActivity, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin]) => showActivity && !currentUserIsSystemAdmin && !currentUserIsSupportAdmin && !currentUserIsQAAdmin)
    );
    this.showPermissionsTab$ = combineLatest(this.showPermissions$, this.currentUserIsAuthor$, this.isCurrentLicenseUser$, this.userCanManageUsers$, this.userIsAuthor$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$).pipe(
      map(([showPermissions, currentUserIsAuthor, isCurrentLicenseUser, userCanManageUsers, userIsAuthor, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin]) => showPermissions && userIsAuthor && ((currentUserIsAuthor && (isCurrentLicenseUser || userCanManageUsers)) || currentUserIsSystemAdmin || currentUserIsSupportAdmin || currentUserIsQAAdmin))
    );
    this.showNotificationsTab$ = combineLatest(this.showNotifications$, this.currentUserIsAuthor$, this.isCurrentLicenseUser$, this.userIsAuthor$).pipe(
      map(([showNotifications, currentUserIsAuthor, isCurrentLicenseUser, userIsAuthor]) => showNotifications && currentUserIsAuthor && isCurrentLicenseUser && userIsAuthor)
    );
    this.showDeactivateTab$ = combineLatest(this.showDeactivate$, this.currentUserIsAuthor$, this.userCanManageUsers$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$).pipe(
      map(([showDeactivate, currentUserIsAuthor, userCanManageUsers, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin]) => showDeactivate && ((currentUserIsAuthor && userCanManageUsers) || currentUserIsSystemAdmin || currentUserIsSupportAdmin || currentUserIsQAAdmin))
    );
    this.showDeleteTab$ = combineLatest(this.showDelete$, this.currentUserIsAuthor$, this.userCanDeleteUsers$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$).pipe(
      map(([showDelete, currentUserIsAuthor, userCanDeleteUsers, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin]) => showDelete && ((currentUserIsAuthor && userCanDeleteUsers) || currentUserIsSystemAdmin || currentUserIsSupportAdmin || currentUserIsQAAdmin))
    );
    this.showResetPasswordTab$ = combineLatest([this.showResetPassword$, this.currentUserIsAuthor$, this.isCurrentLicenseUser$, this.userCanManageUsers$, licenseUserStatusIsInvited$, this.currentUserIsSystemAdmin$, this.currentUserIsSupportAdmin$, this.currentUserIsQAAdmin$, licenseUserCanManageServer$, this.licenseSSOEnabled$]).pipe(
      map(([showResetPassword, currentUserIsAuthor, isCurrentLicenseUser, userCanManageUsers, licenseUserStatusIsInvited, currentUserIsSystemAdmin, currentUserIsSupportAdmin, currentUserIsQAAdmin, licenseUserCanManageServer, licenseSSOEnabled]) => showResetPassword && ((currentUserIsAuthor && !isCurrentLicenseUser && userCanManageUsers && !licenseUserStatusIsInvited) || currentUserIsSystemAdmin || currentUserIsSupportAdmin || currentUserIsQAAdmin) && (licenseUserCanManageServer || !licenseSSOEnabled))
    );
  }


  onMessageClicked() {
    this.dialog.open(MessageCenterDialogCardComponent, {
      ...MessageCenterDialogCardComponent.DialogConfig,
      data: {
        licenseUser: this.currentService.getLicenseUser(),
        selectedLicenseUser: this.userProfile.LicenseUser
      }
    });
  }

  onAssignTaskClicked() {
    this.tasksFilterService.openCreateTaskDialog(this.userProfile.LicenseUser.User.Id);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave(event: CancelableEvent, userProfileForm: UserProfileForm) {
    const saveEvent = new UserProfileSaveEvent(userProfileForm);
    this.save.emit(saveEvent);

    if (saveEvent.defaultPrevented) {
      event.preventDefault();
    }
  }

  onSettingsSaved() {
    this.saved.emit(UserProfileForm.Settings);
  }

  onEmailSaved() {
    this.saved.emit(UserProfileForm.Email);
  }

  onPasswordSaved() {
    this.saved.emit(UserProfileForm.Password);
  }

  onTeamsAndProjectsSaved() {
    this.saved.emit(UserProfileForm.Access);
  }

  onPermissionsSaved() {
    this.saved.emit(UserProfileForm.Permissions);
  }

  onNotificationsSaved() {
    this.saved.emit(UserProfileForm.Notifications);
  }

  onUserDeactivated() {
    this.saved.emit(UserProfileForm.Deactivate);
  }

  onUserDeleted() {
    this.saved.emit(UserProfileForm.Delete);
  }

  onPasswordReset() {
    this.saved.emit(UserProfileForm.ResetPassword);
  }
}
