import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';

@Pipe({
  name: 'mcReviewPackageStatusText'
})
export class ReviewPackageStatusTextPipe implements PipeTransform {
  transform(status: ReviewPackageStatus | null | undefined): string {
    switch (status) {
      case ReviewPackageStatus.Closed:
        return 'Closed';
      case ReviewPackageStatus.Open:
        return 'Open';
    }
  }
}
