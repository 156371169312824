import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { ReviewFileUserStatusColorModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-color/review-file-user-status-color.module';
import { ReviewFileUserStatusIconModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-icon/review-file-user-status-icon.module';
import { ReviewFileUserStatusLicenseUserTooltipClassModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-license-user-tooltip-class/review-file-user-status-license-user-tooltip-class.module';
import { ReviewFileUserStatusLicenseUserTooltipModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-license-user-tooltip/review-file-user-status-license-user-tooltip.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { ReviewFileUserListComponent } from './review-file-user-list.component';

@NgModule({
  declarations: [ReviewFileUserListComponent],
  exports: [ReviewFileUserListComponent],
  imports: [
    CommonModule,
    LicenseUserAvatarModule,
    LicenseUserForLicenseAsyncModule,
    ListModule,
    MatIconModule,
    MatListModule,
    ReviewFileUserStatusColorModule,
    ReviewFileUserStatusIconModule,
    ReviewFileUserStatusLicenseUserTooltipClassModule,
    ReviewFileUserStatusLicenseUserTooltipModule
  ]
})
export class ReviewFileUserListModule { }
