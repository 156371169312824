import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class ReviewFilePageFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'AuthorUser.FullName',
        title: 'Review Owner',
        type: PageDataType.String,
        filterEnabled: true
      },
      {
        name: 'BranchName',
        title: 'Branch',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'CommitId',
        title: 'Commit ID',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'CreatedOn',
        title: 'Created On',
        type: PageDataType.Date,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'IsDeleted',
        title: 'Deleted',
        type: PageDataType.Boolean,
        filterEnabled: true
      },
      {
        name: 'IsDirty',
        title: 'Dirty',
        type: PageDataType.Boolean,
        filterEnabled: true
      },
      {
        name: 'Name',
        title: 'File Name',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'Path',
        title: 'Path',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'ProjectId',
        title: 'Project',
        type: PageDataType.Select,
        filterEnabled: true,
        autocomplete: 'review-projects'
      },
      {
        name: 'Project.Name',
        title: 'Project',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'Reviewers',
        title: 'Reviewers',
        type: PageDataType.String
      },
      {
        name: 'ReviewersSubmittedOn',
        title: 'All Users Submitted Date',
        headerTitleClass: 'mc-header-title-fill-remaining-space',
        titleHtml: '<div class="mc-text-center">- All Users -<br/>Submitted Date</div>',
        type: PageDataType.Date
      },
      {
        name: 'ReviewersReviewerStatus',
        title: 'All Users Status',
        headerTitleClass: 'mc-header-title-fill-remaining-space',
        titleHtml: '<div class="mc-text-center">- All Users -<br/>Status</div>',
        type: PageDataType.Select
      },
      {
        name: 'ReviewPackage.Name',
        title: 'Review Package',
        type: PageDataType.String,
        filterEnabled: true,
        sortEnabled: true
      },
      {
        name: 'Status',
        title: 'Status',
        type: PageDataType.Select,
        filterEnabled: true,
        sortEnabled: true,
        selectOptions: [
          { id: ReviewFileStatus.Open.toString(), text: 'Open' },
          { id: ReviewFileStatus.Closed.toString(), text: 'Closed' },
          { id: ReviewFileStatus.Accepted.toString(), text: 'Accepted' }
        ]
      },
      {
        name: 'UserSubmittedOn',
        title: 'My Submitted Date',
        headerTitleClass: 'mc-header-title-fill-remaining-space',
        titleHtml: '<div class="mc-text-center">- My -<br/>Submitted Date</div>',
        type: PageDataType.Date
      },
      {
        name: 'UserReviewerStatus',
        title: 'My Status',
        headerTitleClass: 'mc-header-title-fill-remaining-space',
        titleHtml: '<div class="mc-text-center">- My -<br/>Status</div>',
        type: PageDataType.Select
      }
    ]);
  }
}
