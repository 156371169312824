import { Inject, Injectable, Injector } from '@angular/core';
import { CurrentModuleConfig } from '@portal-core/current/models/current-module-config.model';
import { CurrentConfigurator } from '@portal-core/current/services/current-module-config.interface';
import { CurrentModuleConfigService } from '@portal-core/current/services/current-module-config.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { License } from '@portal-core/licenses/models/license.model';
import { User } from '@portal-core/users/models/user.model';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentService {
  constructor(@Inject(CurrentModuleConfigService) private config: CurrentModuleConfig, private injector: Injector) { }

  getUserId(): string {
    if (this.config.getLicenseUser) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getLicenseUser);
      if (handler.getLicenseUser) {
        return handler.getLicenseUser()?.User?.Id;
      }
    }
  }

  getLicenseUser(): LicenseUser {
    if (this.config.getLicenseUser) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getLicenseUser);
      if (handler.getLicenseUser) {
        return handler.getLicenseUser();
      }
    }
  }

  getLicenseUser$(): Observable<LicenseUser> {
    if (this.config.getLicenseUser$) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getLicenseUser$);
      if (handler.getLicenseUser$) {
        return handler.getLicenseUser$();
      }
    }

    return of(null);
  }

  getLicenseUserId(): number {
    const licenseUser = this.getLicenseUser();
    return licenseUser ? licenseUser.Id : undefined;
  }

  getLicense(): License {
    if (this.config.getLicense) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getLicense);
      if (handler.getLicense) {
        return handler.getLicense();
      }
    }
  }

  getLicense$(): Observable<License> {
    if (this.config.getLicense$) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getLicense$);
      if (handler.getLicense$) {
        return handler.getLicense$();
      }
    }

    return of(null);
  }

  getActiveLicense(): License {
    if (this.config.getActiveLicense) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getActiveLicense);
      if (handler.getActiveLicense) {
        return handler.getActiveLicense();
      }
    }
  }

  getActiveLicense$(): Observable<License> {
    if (this.config.getActiveLicense$) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.getActiveLicense$);
      if (handler.getActiveLicense$) {
        return handler.getActiveLicense$();
      }
    }

    return of(null);
  }

  getLicenseId(): number {
    const license = this.getLicense();
    return license ? license.Id : undefined;
  }

  getActiveLicenseId(): number {
    const license = this.getActiveLicense();
    return license ? license.Id : undefined;
  }

  isCurrentLicenseUser(licenseUserId: number): boolean {
    return licenseUserId === this.getLicenseUserId();
  }

  getCurrentLicenseUserIsSystemAdmin$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser?.User?.RoleNames?.includes('SystemAdmin'))
    );
  }

  getCurrentLicenseUserIsSupportAdmin$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser?.User?.RoleNames?.includes('SupportAdmin'))
    );
  }

  getCurrentLicenseUserIsQaAdmin$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser?.User?.RoleNames?.includes('QAAdmin'))
    );
  }

  getCurrentLicenseUserIsAuthor(): boolean {
    return this.getLicenseUser()?.SeatType === LicenseUserSeatType.Author;
  }

  getCurrentLicenseUserIsAuthor$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.Author)
    );
  }

  getCurrentLicenseUserIsSme$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.SME)
    );
  }

  getCurrentLicenseUserIsViewer$(): Observable<boolean> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.Viewer)
    );
  }

  getUser(): User {
    return this.getLicenseUser()?.User;
  }

  getUser$(): Observable<User> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser?.User)
    );
  }

  isSysAdmin(): boolean {
    if (this.config.isSysAdmin) {
      const handler: CurrentConfigurator = this.injector.get<CurrentConfigurator>(this.config.isSysAdmin);
      if (handler.isSysAdmin) {
        return handler.isSysAdmin();
      }
    }
  }
}
