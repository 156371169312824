import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslationPackageProfileDialogComponent } from '@portal-core/translation-packages/components/translation-package-profile-dialog/translation-package-profile-dialog.component';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-translation-package-avatar',
  templateUrl: './translation-package-avatar.component.html',
  styleUrls: ['./translation-package-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageAvatar extends AvatarBase {
  @Input() translationPackage: TranslationPackage;

  get name(): string {
    if (this.translationPackage) {
      return this.translationPackage.Name;
    }
  }

  get tooltip(): string {
    if (this.translationPackage) {
      return this.translationPackage.Name;
    }
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  onAvatarClicked() {
    this.openTranslationPackageProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.translationPackage;
  }

  private openTranslationPackageProfileDialog() {
    this.dialog.open(TranslationPackageProfileDialogComponent, {
      ...TranslationPackageProfileDialogComponent.DialogConfig,
      data: {
        translationPackageId: this.translationPackage.Id
      }
    });
  }
}
