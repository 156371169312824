import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { Observable, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableQueueHandlerService {

  private observables: Observable<any>[] = [];
  private isExecuting = false;

  constructor(
    private errorService: ErrorService,
    private dialog: MatDialog) { }

  enqueue(observable: Observable<any>) {
    this.observables.push(observable);
    if (this.isExecuting) {
      return;
    }
    this.isExecuting = true;
    this.executeApi();
  }

  private executeApi() {
    const observable = this.observables.shift();
    if (!observable) {
      this.isExecuting = false;
      return;
    }
    try {
      observable.pipe(first()).subscribe(() => {
        try {
          this.executeApi();
        } catch (error) {
          throw (error);
        }
      },
        error => {
          this.dialog.open(ErrorDialogComponent, {
            ...ErrorDialogComponent.DialogConfig,
            data: {
              title: 'Unexpected Error',
              message: 'An unexpected error occurred. Please try again.',
              errors: this.errorService.getErrorMessages(error)
            }
          });
        });
    } catch (error) {
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Unexpected Error',
          message: 'An unexpected error occurred. Please try again.',
          errors: this.errorService.getErrorMessages(error)
        }
      });
    }
  }
}
