import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectFilesTreeItemDirective } from './project-files-tree-item.directive';

@NgModule({
  declarations: [
    ProjectFilesTreeItemDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProjectFilesTreeItemDirective
  ]
})
export class ProjectFilesTreeItemModule { }
