import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationStringModule } from '@portal-core/i18n/components/translation-string/translation-string.module';
import { CommonStringsComponent } from './common-strings.component';

@NgModule({
  declarations: [
    CommonStringsComponent
  ],
  imports: [
    CommonModule,
    TranslationStringModule
  ],
  exports: [
    CommonStringsComponent
  ]
})
export class CommonStringsModule { }
