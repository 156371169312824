import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@common/paged-data/types/page.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Target } from '@portal-core/targets/models/target.model';
import { TargetFileInfo } from '@portal-core/targets/models/target-file-info.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TargetsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getBuildTargetsPageByProjectId$(projectId: number, filter: PageFilter): Observable<Page<Target>> {
    return this.http.post<Page<Target>>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/targetpaths`, filter);
  }

  @SharePendingRequest()
  getProjectTargetFileInfo$(projectId: number, filePath: string, branchName: string): Observable<TargetFileInfo> {
    return  this.http.get<TargetFileInfo>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/targetFileInfo?filePath=${encodeURIComponent(filePath)}&branchName=${encodeURIComponent(branchName)}`);
  }
}
