import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { SelectionListItem } from '@portal-core/general/models/selection-list-item';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { ReviewPackageUsersService } from '@portal-core/reviews/review-package-users/services/review-package-users.service';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, combineLatest, first, map } from 'rxjs';

interface LicenseUserItem extends SelectionListItem<string> {
  LicenseUser: LicenseUser;
}

@Component({
  selector: 'mc-review-package-reviewers-form',
  templateUrl: './review-package-reviewers-form.component.html',
  styleUrls: ['./review-package-reviewers-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageReviewersFormComponent {
  @Input() reviewPackage: ReviewPackage;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  CentralPermissions: typeof CentralPermissions = CentralPermissions;

  allLicenseUsers: LicenseUserItem[] = [];
  dirty: boolean = false;
  editing: boolean = false;
  loadingState: LoadingState<string> = new LoadingState<string>();
  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(
    private errorService: ErrorService,
    private licenseUsersService: LicenseUsersService,
    private reviewPackagesService: ReviewPackagesService,
    private reviewPackageUsersService: ReviewPackageUsersService
  ) { }

  onCancelClicked() {
    this.editing = false;
    this.dirty = false;
    this.savingState.update(false);
  }

  onEditClicked() {
    this.editing = true;
    this.dirty = false;
    this.loadLicenseUsers();
  }

  onSubmit() {
    const userIds = this.allLicenseUsers.filter(lu => lu.Selected).map(lu => lu.Value)

    this.savingState.update(true);

    this.reviewPackagesService.setReviewers$(this.reviewPackage.Id, userIds).subscribe(() => {
      this.savingState.update(false);
      this.editing = false;
      this.dirty = false;
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to update the reviewers.', this.errorService.getErrorMessages(error));
    });
  }

  onUserListOptionClicked(licenseUserItem: LicenseUserItem) {
    licenseUserItem.Selected = !licenseUserItem.Selected;
    this.dirty = true;
  }

  onUserListSelectAllChanged() {
    this.dirty = true;
  }

  private loadLicenseUsers() {
    this.loadingState.update(true);

    // Load all the license users and all the review package users so that the selection list can be populated
    combineLatest([
      this.licenseUsersService.getAllLicenseUsersByLicenseId$(this.reviewPackage.LicenseId, false).pipe(
        first(licenseUsers => !!licenseUsers),
        map(licenseUsers => licenseUsers.filter(licenseUser => licenseUser.IsActive))
      ),
      this.getAllReviewPackageUsers$()
    ]).subscribe(([licenseUsers, reviewPackageUsers]) => {
      this.loadingState.update(false);

      const selectedUserIds = reviewPackageUsers.map(reviewPackageUser => reviewPackageUser.UserId);

      this.allLicenseUsers = licenseUsers.sort(licenseUser => licenseUser.SeatType).map(licenseUser => {
        return {
          LicenseUser: licenseUser,
          Value: licenseUser.User.Id,
          Selected: !!selectedUserIds.find(id => id === licenseUser.User.Id)
        };
      });
    }, error => {
      this.loadingState.update(false, 'Unable to load the users.', this.errorService.getErrorMessages(error));
      this.editing = true;
    });
  }

  private getAllReviewPackageUsers$(): Observable<ReviewPackageUser[]> {
    this.loadingState.update(true);

    // Build a filter for the review package users that fetches every user
    const filter: PageFilter = {
      Id: 'reviewers',
      Type: PageFilterGroupType.Custom,
      PageNumber: 0,
      PerPage: -1
    };

    return this.reviewPackageUsersService.getReviewPackageUsersPage$(this.reviewPackage.Id, filter).pipe(
      map(page => page.Items)
    );
  }
}
