import { AfterContentInit, ContentChild, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { GridHeaderFilterComponent } from '@portal-core/ui/grid/components/grid-header-filter/grid-header-filter.component';
import { GridHeaderMenuDirective } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.directive';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridMenuEvent } from '@portal-core/ui/grid/models/grid-menu-event.model';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, Observable, of, Subscription, switchMap } from 'rxjs';

@Directive()
@AutoUnsubscribe()
export abstract class GridFilterBase implements OnInit, OnDestroy, OnChanges, AfterContentInit {
  @Input() column: GridColumn;
  @Input() columnInputs?: Dictionary<any>;
  @Input() filterable: Filterable;

  @Output() menuClosed: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() menuClosing: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() menuOpened: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();

  @ViewChild('filterFormDirective') filterFormDirective: NgForm;
  @ViewChild(GridHeaderFilterComponent) gridHeaderFilter: GridHeaderFilterComponent;
  @ContentChild(GridHeaderMenuDirective) menuDirective: GridHeaderMenuDirective;

  @InputObservable('column') column$: Observable<Filterable>;
  @InputObservable('filterable') filterable$: Observable<Filterable>;

  get menuOpen(): boolean {
    return this.gridHeaderFilter?.menuOpen;
  }

  PageFilterType: typeof PageFilterType = PageFilterType;

  filterForm: UntypedFormGroup;
  hasFilter: boolean;
  menuTemplate: TemplateRef<any>;

  private filterSubscription: Subscription;

  constructor(protected pageFilterService: PageFilterService) { }

  ngOnInit() {
    // Listen for changes to the filter
    this.filterSubscription = combineLatest([
      this.column$,
      this.filterable$.pipe(
        switchMap(filterable => {
          if (filterable) {
            return filterable.getFilter$(this.getGridFilterName());
          } else {
            return of(null);
          }
        })
      )
    ]).subscribe(([column, pageFilter]) => {
      if (column) {
        this.resetForm(pageFilter);
        this.hasFilter = this.getFilterCount(pageFilter) > 0;
      }
    });
  }

  /** Required by InputObservable. */
  ngOnChanges() { }

  /** Required by AutoUnsubscribe. */
  ngOnDestroy() { }

  ngAfterContentInit() {
    this.menuTemplate = (this.menuDirective && this.menuDirective.templateRef) || null;
  }

  onMenuOpened() {
    this.menuOpened.emit({
      column: this.column
    });
  }

  onMenuClosed() {
    this.menuClosed.emit({
      column: this.column
    });

    // Reset the form after the menu closes so that the next time it is opened it displays the correct values
    // Like when the user changes a form field but closes the menu without submitting the form
    if (this.filterable) {
      this.resetForm(this.filterable.getFilter(this.getGridFilterName()));
    }
  }

  onMenuClosing() {
    this.menuClosing.emit({
      column: this.column
    });
  }

  onClearFilterClicked() {
    const filterGroup = this.filterable.getFilter(this.getGridFilterName());
    if (filterGroup) {
      const fb = this.pageFilterService.create(filterGroup);
      fb.remove(this.column.name);
      this.filterable.applyFilter$(this.getGridFilterName(), fb.value);
    }

    this.closeMenu();
  }

  closeMenu() {
    if (this.gridHeaderFilter) {
      this.gridHeaderFilter.closeMenu();
    }
  }

  getFilterCount(pageFilter: PageFilterGroup): number {
    if (this.column && pageFilter) {
      return this.pageFilterService.getFilterCount(pageFilter, this.column.name);
    } else {
      return 0;
    }
  }

  submitFilter(pageFilter: PageFilterGroup) {
    this.filterable.applyFilter$(this.getGridFilterName(), pageFilter);
    this.closeMenu();
  }

  protected resetForm(pageFilter: PageFilterGroup) {
    if (this.filterFormDirective) {
      this.filterFormDirective.resetForm(this.buildFormValue(pageFilter));
    } else {
      this.filterForm.reset(this.buildFormValue(pageFilter));
    }
  }

  protected abstract buildFormValue(pageFilter: PageFilterGroup): Dictionary;
  protected abstract getGridFilterName(): GridFilterName;
}
