import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BranchAvatarComponent } from './branch-avatar.component';

@NgModule({
  declarations: [
    BranchAvatarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule
  ],
  exports: [
    BranchAvatarComponent
  ]
})
export class BranchAvatarModule { }
