import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProjectInfo } from '@portal-core/project-info/models/project-info.model';
import { ProjectInfoDataService } from '@portal-core/project-info/services/project-info-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ProjectInfoService extends CollectionServiceBase<ProjectInfo> {
  constructor(private projectFilesDataService: ProjectInfoDataService, protected dataService: DataService) {
    super(projectFilesDataService, dataService);
  }
}
