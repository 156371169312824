<mat-tree #adminTree class="mc-tree mc-tree-compact" [dataSource]="adminPermissionsDataSource" [treeControl]="adminPermissionsTreeControl">
  <mat-tree-node *matTreeNodeDef="let node">
    <div class="mc-tree-node-item">
      <mat-checkbox color="primary-vivid" [checked]="nodeIsSelected(node)" [disabled]="node.disabled" [matTooltip]="node.category.Description" (change)="onNodeCheckboxChanged(node)">{{ node.category.Title }}</mat-checkbox>
    </div>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChildren">
    <div class="mat-tree-node">
      <div matTreeNodeToggle matRipple class="mc-tree-node-item">
        <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="adminPermissionsTreeControl.isExpanded(node) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
        <mat-checkbox color="primary-vivid" class="mc-tree-text-icon text-heavy-weight" mcStopPropagation="click" [checked]="node.allSelected" [disabled]="node.disabled" [indeterminate]="node.partiallySelected" (change)="onNodeCheckboxChanged(node)">{{ node.category.Title }}</mat-checkbox>
      </div>
    </div>

    <div class="mc-nested-tree-node-items" [class.mc-hidden]="!adminPermissionsTreeControl.isExpanded(node)">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

<mat-tree #projectsTree class="mc-tree mc-tree-compact" [dataSource]="projectPermissionsDataSource" [treeControl]="projectPermissionsTreeControl">
  <mat-tree-node *matTreeNodeDef="let node">
    <div class="mc-tree-node-item">
      <mat-checkbox color="primary-vivid" [checked]="nodeIsSelected(node)" [disabled]="node.disabled" [matTooltip]="node.category.Description" (change)="onNodeCheckboxChanged(node)">{{ node.category.Title }}</mat-checkbox>
    </div>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChildren">
    <div class="mat-tree-node">
      <div matTreeNodeToggle matRipple class="mc-tree-node-item">
        <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="projectPermissionsTreeControl.isExpanded(node) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
        <mat-checkbox color="primary-vivid" class="mc-tree-text-icon text-heavy-weight" mcStopPropagation="click" [checked]="node.allSelected" [disabled]="node.disabled" [indeterminate]="node.partiallySelected" (change)="onNodeCheckboxChanged(node)">{{ node.category.Title }}</mat-checkbox>
      </div>
    </div>

    <div class="mc-nested-tree-node-items" [class.mc-hidden]="!projectPermissionsTreeControl.isExpanded(node)">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>
