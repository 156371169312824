import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { BuildLog } from '@portal-core/build-logs/models/build-log.model';

@CollectionStateSelectors({
  stateType: BuildLogsState
})
@State<BuildLogsState>({
  name: BuildLogsState.source,
})
@Injectable()
export class BuildLogsState extends CollectionStateBase {
  static source: string = 'BuildLogs';
  getSource(): string {
    return BuildLogsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BuildLogsDataService extends CollectionDataServiceBase<BuildLog> {
  constructor(protected store: Store) {
    super(store, BuildLogsState);
   }
}

