<div class="mc-vertical-split-dialog">
  <div matDialogTitle>
    <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-alert'" class="mc-image-icon mc-image-icon-normal" color="primary"></mat-icon>
    <button *ngIf="showCloseButton" mat-icon-button color="primary" class="mc-vertical-split-dialog-close-button" tabindex="-1" [matDialogClose]="false">
      <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <h3 class="mc-vertical-split-dialog-title" *ngIf="title">{{ title }}</h3>
    <div class="mc-vertical-split-dialog-body">
      <p>{{ prompt }}</p>
      <mat-list class="mc-confirm-dialog-additional-description-list mc-list-compact" *ngIf="additionalDescription.length" role="list">
        <mat-list-item *ngFor="let text of additionalDescription" role="listitem">
          <span class="mc-truncate" [matTooltip]="text">
            {{ text }}
          </span>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-end">
    <button *ngIf="cancelActionColor" mat-raised-button [color]="cancelActionColor" [matDialogClose]="false">{{ cancelAction }}</button>
    <button *ngIf="!cancelActionColor" mat-stroked-button [color]="cancelActionColor" [matDialogClose]="false">{{ cancelAction }}</button>
    <button mat-raised-button [color]="actionColor" [matDialogClose]="true">{{ action }}</button>
  </mat-dialog-actions>
</div>
