import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProjectAndTargets } from '@portal-core/project-targets/models/project-and-targets.model';
import { ProjectTargetFolder } from '@portal-core/project-targets/models/project-target-folder.model';
import { ProjectTarget } from '@portal-core/project-targets/models/project-target.model';
import { ProjectTargetsApiService } from '@portal-core/project-targets/services/project-targets-api.service';
import { ProjectTargetsDataService } from '@portal-core/project-targets/services/project-targets-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ProjectTargetsService extends CollectionServiceBase<ProjectTarget> {
  constructor(
    private projectTargetsDataService: ProjectTargetsDataService,
    private projectTargetsApiService: ProjectTargetsApiService,
    protected dataService: DataService
  ) {
    super(projectTargetsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<ProjectTarget> {
    return this.projectTargetsApiService.getProjectTargetById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<ProjectTarget[]> {
    return this.projectTargetsApiService.getProjectTargetsByIds$(itemIds);
  }

  // The results of this were fed to an AJS component so the normal data storage pattern was not used. Should this be changed now that AJS is not used?
  getProjectTargets$(projectId: number, getDeleted: boolean, flatten: boolean): Observable<ProjectTargetFolder> {
    return this.projectTargetsApiService.getProjectTargets$(projectId, getDeleted, flatten).pipe(
      tap(targetFolder => {
        this.addItems$(targetFolder.OutputTargets);
      })
    );
  }

  getMyProjectsAndOutputsByLicenseLite$(licenseId: number): Observable<ProjectAndTargets[]> {
    return this.projectTargetsApiService.getMyProjectsAndOutputsByLicenseLite$(licenseId);
  }
}
