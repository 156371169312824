<mat-form-field appearance="standard" [color]="color" class="mc-form-field">
  <mat-label>Project</mat-label>
  <mc-autocomplete-input [multiple]="false" [triggerPopupOnFocus]="false" [value]="projectValue" (valueChange)="onProjectValueChanged($event)">
    <mc-projects-select-list mcAutocompleteList [licenseId]="licenseId"></mc-projects-select-list>
    <ng-template mcAutocompleteItem let-projectId="item">
      <mc-project-avatar compact [disabled]="true" [project]="projectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
    </ng-template>
  </mc-autocomplete-input>
</mat-form-field>

<mat-form-field appearance="standard" [color]="color" class="mc-form-field">
  <mat-label>{{ targetLabel$ | async }}</mat-label>
  <mc-autocomplete-input [disabled]="targetDisabled$ | async" [multiple]="false" [value]="targetValue" (valueChange)="onTargetValueChanged($event)" [triggerPopupOnFocus]="false">
    <mc-targets-select-list mcAutocompleteList [processStates]="[ProcessState.Complete]" [projectId]="value?.projectId"></mc-targets-select-list>
    <ng-template mcAutocompleteItem let-targetId="item">
      <mc-target-avatar compact [disabled]="true" [showPath]="true" [targetPath]="(targetId | mcTargetAsync | async)?.Path" size="small" [truncate]="true"></mc-target-avatar>
    </ng-template>
  </mc-autocomplete-input>
</mat-form-field>

<mat-form-field appearance="standard" [color]="color" class="mc-form-field">
  <mat-label>{{ branchLabel$ | async }}</mat-label>
  <mc-autocomplete-input [disabled]="branchDisabled$ | async" [multiple]="false" [value]="branchValue" (valueChange)="onBranchValueChanged($event)" [triggerPopupOnFocus]="false">
    <mc-branches-select-list mcAutocompleteList [processStates]="[ProcessState.Complete]" [projectId]="value?.projectId" [showAnyBranchOption]="true" [targetPath]="value?.targetPath" type="builds"></mc-branches-select-list>
    <ng-template mcAutocompleteItem let-branchId="item">
      <mc-branch-avatar compact [disabled]="true" [branchName]="(branchId | mcBranchAsync | async)?.Name" [disabled]="true" size="small" [truncate]="true"></mc-branch-avatar>
    </ng-template>
  </mc-autocomplete-input>
</mat-form-field>

<mat-form-field appearance="standard" [color]="color" class="mc-form-field">
  <mat-label>{{ buildLabel$ | async }}</mat-label>
  <mc-autocomplete-input [disabled]="buildDisabled$ | async" [multiple]="false" [value]="buildValue" (valueChange)="onBuildValueChanged($event)" [triggerPopupOnFocus]="false">
    <mc-builds-select-list mcAutocompleteList [branchName]="value?.branchName" [processStates]="[ProcessState.Complete]" [projectId]="value?.projectId" [showLatestBuildOption]="true" [targetPath]="value?.targetPath"></mc-builds-select-list>
    <ng-template mcAutocompleteItem let-buildId="item">
      <mc-build-avatar *ngIf="buildId !== 0" compact [build]="buildId | mcBuildAsync | async" [disabled]="true" [showCompletedDate]="true" size="small" [truncate]="true"></mc-build-avatar>
      <mc-build-avatar *ngIf="buildId === 0" compact [disabled]="true" [latest]="true" size="small" [truncate]="true"></mc-build-avatar>
    </ng-template>
  </mc-autocomplete-input>
</mat-form-field>

<div class="mc-form-buttons">
  <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
  <button type="button" mat-raised-button color="primary" (click)="onDoneClicked()">Done</button>
</div>