import { resolvedPosFindNode } from '@common/prosemirror/model/resolved-pos';
import { NodeType, ProseMirrorNode } from 'prosemirror-model';
import { NodeSelection, Selection } from 'prosemirror-state';

/**
 * Returns the node if it contains the current selection and matches the given node type.
 * Only checks the selection's parent node unless checkAllAncestors is true.
 * A selection is only considered to be within a node if the entire selection is within the node.
 * @param selection The selection to check.
 * @param nodeType The node or function to check the selection against.
 * @param checkAllAncestors Whether or not to search all the ancestors for the node. Otherwise only the parent node is checked.
 * @returns The node in the selection that matches the node type or undefined if no node matches.
 */
export function getNodeContainingSelection(selection: Selection, nodeType: NodeType | ((node: ProseMirrorNode) => boolean), checkAllAncestors: boolean = false): ProseMirrorNode {
  const $from = selection.$from;
  const to = selection.to;

  // If this is a node selection
  if (selection instanceof NodeSelection) {
    // If the selected node is the right type
    if (typeof nodeType === 'function') {
      if (nodeType(selection.node)) {
        return selection.node;
      }
    }
    else if (selection.node.type === nodeType) {
      return selection.node;
    }

    // If the ancestors should be checked
    if (checkAllAncestors) {
      return resolvedPosFindNode($from, node => {
        if (typeof nodeType === 'function') {
          return nodeType(node);
        } else {
          return node.type === nodeType;
        }
      });
    }
  } else { // Else this is a text selection
    if (checkAllAncestors) {
      // If any ancestor node is the right type AND the selection is only within that node
      return resolvedPosFindNode($from, (node, depth, $pos) => {
        if (to > $pos.end(depth)) {
          return false;
        }

        if (typeof nodeType === 'function') {
          return nodeType(node);
        } else {
          return node.type === nodeType;
        }
      });
    } else {
      // If the parent node is the right type AND the selection is only within the parent
      if (to > $from.end()) {
        return undefined;
      }

      if (typeof nodeType === 'function') {
        return nodeType($from.parent) ? $from.parent : undefined;
      } else {
        return $from.parent.type === nodeType ? $from.parent : undefined;
      }
    }
  }
}

/**
 * Returns true if the current selection is within a node type.
 * Only checks the selection's parent node unless checkAllAncestors is true.
 * A selection is only considered to be within a node if the entire selection is within the node.
 * @param selection The selection to check.
 * @param nodeType The node or function to check the selection against.
 * @param checkAllAncestors Whether or not to search all the ancestors for the node. Otherwise only the parent node is checked.
 * @returns True if the selection is within the node type.
 */
export function selectionIsInNode(selection: Selection, nodeType: NodeType | ((node: ProseMirrorNode) => boolean), checkAllAncestors: boolean = false): boolean {
  return !!getNodeContainingSelection(selection, nodeType, checkAllAncestors);
}

/**
 * Returns the parent node of the selected node along with the parent's absolute position in the doc.
 * @param selection The node selection to get the parent node from.
 * @returns The parent node and the parent's absolute position in the doc.
 */
export function getParentFromNodeSelection(selection: NodeSelection): { parent: ProseMirrorNode, pos: number } {
  return {
    parent: selection.$from.parent,
    pos: selection.$from.before()
  };
}
