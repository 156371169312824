import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ListModule } from '@portal-core/ui/list/list.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { TargetsSelectListComponent } from './targets-select-list.component';

@NgModule({
  declarations: [
    TargetsSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    TargetAvatarModule
  ],
  exports: [
    TargetsSelectListComponent
  ]
})
export class TargetsSelectListModule { }
