import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { BuildLogsService } from '@portal-core/build-logs/services/build-logs.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';
import { LoadingState } from '@portal-core/util/loading-state';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-build-messages-list',
  templateUrl: './build-messages-list.component.html',
  styleUrls: ['./build-messages-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildMessagesListComponent implements OnInit {
  @Input() buildId: number;

  @PropertyObservable('buildId') buildId$: Observable<number>;

  loadingState: LoadingState<string> = new LoadingState<string>();
  messages$: Observable<ProcessNotification[]>;

  constructor(private buildLogsService: BuildLogsService, private errorService: ErrorService) { }

  ngOnInit() {
    this.messages$ = this.buildId$.pipe(
      tap(() => this.loadingState.update(true)),
      switchMap(buildId => typeof buildId === 'number' ? this.buildLogsService.getBuildLog$(buildId) : of(null)),
      map(buildLog => buildLog ? buildLog.Messages : null),
      tap(() => this.loadingState.update(false)),
      catchError(error => {
        this.loadingState.update(false, 'There was a problem loading the build messages.', this.errorService.getErrorMessages(error));
        return of(null);
      })
    );
  }
}
