import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchBoardComponent } from './components/switch-board/switch-board.component';
import { SwitchBoardBarDirective } from './directives/switch-board-bar/switch-board-bar.directive';
import { SwitchBoardContentDirective } from './directives/switch-board-content/switch-board-content.directive';

@NgModule({
  declarations: [
    SwitchBoardComponent,
    SwitchBoardBarDirective,
    SwitchBoardContentDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SwitchBoardComponent,
    SwitchBoardBarDirective,
    SwitchBoardContentDirective
  ]
})
export class SwitchBoardModule { }
