import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { LogoutReason } from '@portal-core/auth/enums/logout-reason.enum';
import { Logout } from '@portal-core/auth/services/auth-data.service';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';

/**
 * Navigates to the login route after the user logs out.
 * Listens to the Logout action from the auth data store to navigate to a new route.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthLogoutRedirectEffect {
  constructor(private actions$: Actions, private router: Router, private authRouteService: AuthRouteService) {
    this.actions$.pipe(
      ofActionSuccessful(Logout)
    ).subscribe(action => {
      if (action.payload.reason !== LogoutReason.LoginFailure) {
        this.authRouteService.navigateToAuthRoute$(AuthRoute.Login).subscribe();
      }
    });
  }
}
