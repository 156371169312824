import { Injectable } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AccessTreeNode } from '@portal-core/general/models/access-tree-node.model';

@Injectable()
export class AccessTreeService {

  selectAll(node: AccessTreeNode) {
    node.children.forEach(child => {
      if (!child.disabled) {
        child.selected = node.selected;
      }
    });

    this.updateSelectAll(node);
  }

  updateSelectAll(node: AccessTreeNode) {
    node.selected = node.children.every(child => child.selected);
    node.indeterminate = !node.selected && node.children.some(child => child.selected);
  }

  initializeTree(dataSource: MatTreeNestedDataSource<AccessTreeNode>) {
    for (const node of dataSource.data) {
      this.updateSelectAll(node);
    }
  }

  expandNodes(nestedTreeControl: NestedTreeControl<AccessTreeNode>, nodes: AccessTreeNode[]) {
    nodes.forEach(node => {
      nestedTreeControl.expand(node);
    });
  }
}
