import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { NotificationMessageInstruction } from '@portal-core/notifications/types/notification-message-instruction.type';

@Component({
  selector: 'mc-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent implements OnChanges {
  @Input() notification: Notification;

  messageInstructions: NotificationMessageInstruction[];

  constructor(private notificationsService: NotificationsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notification) {
      this.messageInstructions = this.notificationsService.parseNotificationMessage(this.notification);
    }
  }
}
