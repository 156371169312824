import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { Project } from '@portal-core/projects/models/project.model';
import { TranslationPackageLanguageBranchProfile } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch-profile.model';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, combineLatest, filter, first, map, switchMap } from 'rxjs';

export interface TranslationPackageLanguageBranchProfileDialogData {
  translationPackageLanguageBranchId: number;
  translationPackageLanguageBranchProfileTab?: TranslationPackageLanguageBranchProfileTab;
}

export enum TranslationPackageLanguageBranchProfileTab {
  Activity,
  Overview,
  Delete
}

@Component({
  selector: 'mc-translation-package-language-branch-profile-dialog',
  templateUrl: './translation-package-language-branch-profile-dialog.component.html',
  styleUrls: ['./translation-package-language-branch-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageLanguageBranchProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '90rem',
    height: '63rem',
    autoFocus: false
  };

  userCanManageTranslationPackage$: Observable<boolean>;
  TranslationPackageLanguageBranchProfileTab: typeof TranslationPackageLanguageBranchProfileTab = TranslationPackageLanguageBranchProfileTab;
  TranslationPackageStatus: typeof TranslationPackageStatus = TranslationPackageStatus;
  loadingState: LoadingState<string> = new LoadingState<string>();
  translationPackageLanguageBranchProfileTab: TranslationPackageLanguageBranchProfileTab;
  translationPackageLanguageBranchProfile$: Observable<TranslationPackageLanguageBranchProfile>;
  project$: Observable<Project>;
  translationPackageLanguageBranch$: Observable<TranslationPackageLanguageBranch>;
  translationPackage$: Observable<TranslationPackage>;
  private reload$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<TranslationPackageLanguageBranchProfileDialogComponent>,
    private profilesService: ProfilesService,
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    private translationPackagesService: TranslationPackagesService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: TranslationPackageLanguageBranchProfileDialogData
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);
    this.translationPackageLanguageBranchProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getTranslationPackageLanguageBranchProfile$(this.data.translationPackageLanguageBranchId)),
      cache()
    );
    this.project$ = this.translationPackageLanguageBranchProfile$.pipe(
      map(packageProfile => packageProfile ? packageProfile.Project : null)
    );

    this.translationPackageLanguageBranch$ = this.translationPackageLanguageBranchProfile$.pipe(
      map(packageProfile => packageProfile ? packageProfile.TranslationPackageLanguageBranch : null)
    );

    this.translationPackage$ = this.translationPackageLanguageBranch$.pipe(
      switchMap((languageBranch) => {
        return this.translationPackagesService.getItemById$(languageBranch.TranslationPackageId)
      })
    )

    this.userCanManageTranslationPackage$ = this.project$.pipe(
      switchMap(project => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTranslationPackage, project.Id))
    );

    combineLatest([
      this.project$,
      this.translationPackageLanguageBranch$
    ]).pipe(
      first()
    ).subscribe(() => {
      this.translationPackageLanguageBranchProfileTab = this.data.translationPackageLanguageBranchProfileTab != null ? this.data.translationPackageLanguageBranchProfileTab : TranslationPackageLanguageBranchProfileTab.Overview;
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Failed to load the Language Branch.', this.errorService.getErrorMessages(error));
    });

    // Subscribes to the navigation change events for closing the dialog when there is a route change.
    // This Dialog panel contains some links which redirect to other routes so we need to close the Dialog when the route changes.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.closeDialog(null, true);
    });
  }

  onSaved() {
    this.reload$.next();
  }
}
