import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ReviewPackageUserStatus } from '@portal-core/reviews/review-package-users/enums/review-package-user-status.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class ReviewPackageUsersPageFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'UserId',
        title: 'Reviewer',
        type: PageDataType.String
      },
      {
        name: 'ReviewPackageId',
        title: 'Review Package',
        type: PageDataType.Int
      },
      {
        name: 'Status',
        title: 'Status',
        type: PageDataType.Select,
        filterEnabled: true,
        sortEnabled: true,
        selectOptions: [
          { id: ReviewPackageUserStatus.Pending.toString(), text: 'Pending' },
          { id: ReviewPackageUserStatus.Submitted.toString(), text: 'Submitted' }
        ]
      }
    ]);
  }
}
