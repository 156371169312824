import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { LicenseHostMap } from '@portal-core/license-host-maps/models/license-host-map.model';

@CollectionStateSelectors({
  stateType: LicenseHostMapsState
})
@State<LicenseHostMapsState>({
  name: LicenseHostMapsState.source
})
@Injectable()
export class LicenseHostMapsState extends CollectionStateBase {
  static source: string = 'LicenseHostMaps';
  getSource(): string {
    return LicenseHostMapsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicenseHostMapsDataService extends CollectionDataServiceBase<LicenseHostMap> {
  constructor(protected store: Store) {
    super(store, LicenseHostMapsState);
  }
}
