import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { isEnumValue } from '@common/util/enum';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { UserProfile } from '@portal-core/profiles/models/user-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { UserProfileComponent, UserProfileForm } from '@portal-core/users/components/user-profile/user-profile.component';
import { UserProfileTab } from '@portal-core/users/enums/user-profile-tab.enum';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, first, switchMap } from 'rxjs';

export interface UserProfileDialogData {
  licenseId: number;
  licenseUserId: number;
  userId: string;
  userProfileTab?: UserProfileTab;
}

@Component({
  selector: 'mc-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileDialogComponent extends DialogBase implements OnInit {
  static AuthorDialogConfig: MatDialogConfig = {
    width: '88rem',
    height: '87rem',
    autoFocus: false
  };

  static AuthorViewedBySmeDialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '35rem',
    autoFocus: false
  };

  static SmeDialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '50rem',
    autoFocus: false
  };

  static SmeDialogViewedBySmeConfig: MatDialogConfig = {
    width: '80rem',
    height: '30rem',
    autoFocus: false
  };

  static SmeDialogViewedBySelfConfig: MatDialogConfig = {
    width: '80rem',
    height: '50rem',
    autoFocus: false
  };

  static DialogConfigForLicenseUser(licenseUser: LicenseUser, currentLicenseUser: LicenseUser): MatDialogConfig {
    if (currentLicenseUser && currentLicenseUser.SeatType === LicenseUserSeatType.SME) {
      if (licenseUser.SeatType === LicenseUserSeatType.Author) {
        return UserProfileDialogComponent.AuthorViewedBySmeDialogConfig;
      } else {
        return licenseUser.Id === currentLicenseUser.Id ? UserProfileDialogComponent.SmeDialogViewedBySelfConfig : UserProfileDialogComponent.SmeDialogViewedBySmeConfig;
      }
    } else {
      return licenseUser.SeatType === LicenseUserSeatType.Author ? UserProfileDialogComponent.AuthorDialogConfig : UserProfileDialogComponent.SmeDialogConfig;
    }
  }

  @ViewChild(UserProfileComponent, { static: true }) userProfileComponent: UserProfileComponent;

  public get requirePromptOnClose(): boolean {
    return this.userProfileComponent?.requirePromptOnClose;
  }

  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  userProfile$: Observable<UserProfile>;
  userProfileTab: UserProfileTab;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: UserProfileDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<UserProfileDialogComponent>,
    private profilesService: ProfilesService,
    private errorService: ErrorService,
    private currentService: CurrentService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);

    // Create an observable of the user profile. Start with reload$ so that the data can be reloaded as needed
    this.userProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getUserProfile$(this.data.userId, this.data.licenseId)),
      cache()
    );

    this.userProfile$.pipe(
      first()
    ).subscribe(userProfile => {
      // Set the initial tab
      if (isEnumValue(UserProfileTab, this.data.userProfileTab)) {
        this.userProfileTab = this.data.userProfileTab;
      } else {
        this.userProfileTab = this.profilesService.defaultUserProfileTabForLicenseUser(userProfile.LicenseUser, this.currentService.getLicenseUser());
      }

      this.loadingState.update(false);
      this.onLoaded();
    }, error => {
      this.loadingState.update(false, 'Unable to load the user\'s profile.', this.errorService.getErrorMessages(error));
    });
  }

  onCancel() {
    this.closeDialog();
  }

  onSaved(userProfileForm: UserProfileForm) {
    switch (userProfileForm) {
      case UserProfileForm.Deactivate:
      case UserProfileForm.Delete:
        this.closeDialog();
        break;
      // If the user data was updated then refresh the profile data to get the latest data
      case UserProfileForm.Settings:
      case UserProfileForm.Email:
      case UserProfileForm.Password:
      case UserProfileForm.Access:
      case UserProfileForm.Notifications:
      case UserProfileForm.Permissions:
        this.reload();
        break;
    }
  }

  reload() {
    this.reload$.next();
  }
}
