import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { isInTable } from 'prosemirror-tables';

const tableDropRemovalKey = new PluginKey('tableDropRemoval');

/*
 * A prosemirror plugin for disabling table cell drops.
 */
export function tableDropRemovalPlugin(): Plugin {
  return new Plugin({
    key: tableDropRemovalKey,

    props: {
      handleDrop:  function (view: EditorView, event: Event) {
        // Check if the drop content comes from a table
        if (isInTable(view.state)) {
          return true;
        }
        return false;
      }
    }
  });
}
