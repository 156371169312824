import { ViewportRuler } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { StringService } from '@portal-core/general/services/string.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'mc-self-truncating-path',
  templateUrl: './self-truncating-path.component.html',
  styleUrls: ['./self-truncating-path.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SelfTruncatingPathComponent implements OnInit, OnChanges {
  @Input() filePath: string;
  @Input() showTooltip?: boolean = true;

  @ViewChild('filePathRef', { read: ElementRef, static: false }) filePathRef: ElementRef<HTMLElement>;

  // truncated path
  currentFilePath: string;
  pathItems: string[];
  isVisiblePathStyle: string = 'hidden';
  viewPortRulerSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef, private viewportRuler: ViewportRuler, private stringService: StringService) { }

  ngOnInit(): void {
    this.viewPortRulerSubscription = this.viewportRuler.change(100).subscribe(() => {
      this.currentFilePath = this.filePath;
      this.pathItems = [this.currentFilePath, null];
      this.cdr.markForCheck();
    });
  }

  onDetectorAfterViewInit() {
    // Run this code in the next cycle to ensure the dom changes are ready
    setTimeout(() => {
      this.runTruncatePath();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filePath) {
      this.currentFilePath = this.filePath;
      this.pathItems = [this.currentFilePath, null];
      this.cdr.markForCheck();
    }
  }

  runTruncatePath() {
    if (this.filePathRef.nativeElement.scrollWidth > this.filePathRef.nativeElement.offsetWidth && this.currentFilePath !== '...') {
      this.isVisiblePathStyle = 'hidden';
      this.currentFilePath = this.stringService.truncatePath(this.currentFilePath);
      this.pathItems = [this.currentFilePath, null];
      this.cdr.markForCheck();
    } else if (this.isVisiblePathStyle === 'hidden') {
      this.isVisiblePathStyle = 'visible';
      this.cdr.markForCheck();
    }
  }

  trackByFn(index: number, item: string | number) {
    return item ? item : uuidv4();
  }

}
