import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';

@Component({
  selector: 'mc-grid-number-filter',
  templateUrl: './grid-number-filter.component.html',
  styleUrls: ['./grid-number-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridNumberFilterComponent extends GridFilterBase {
  @ViewChild('fromInput') fromInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatSelect) filterTypeSelect: MatSelect;

  get selectedFilterType(): PageFilterType {
    if (this.filterTypeSelect) {
      if (!Array.isArray(this.filterTypeSelect.selected) && this.filterTypeSelect.selected) {
        return this.filterTypeSelect.selected.value;
      }
    }
  }

  constructor(protected formBuilder: UntypedFormBuilder, private formsService: FormsService, pageFilterService: PageFilterService) {
    super(pageFilterService);

    // Do this in the constructor so that the form is ready for ngOnChanges
    this.buildForm();
  }

  onMenuOpened() {
    super.onMenuOpened();

    // Give focus to the 'from' field if there is no custom menu
    if (!this.menuTemplate) {
      this.fromInput.nativeElement.focus();
    }
  }

  onSubmit() {
    if (this.filterForm.invalid) {
      return;
    }

    this.submitFilter(this.buildFilter(this.filterForm.value['filterType'], this.filterForm.value['from'], this.filterForm.value['to']));
  }

  buildFilter(filterType: PageFilterType, fromValue: number, toValue: number): PageFilterGroup {
    return this.pageFilterService.create(this.filterable.getFilter(GridFilterName.Number) ?? {
      Id: GridFilterName.Number,
      Type: PageFilterGroupType.Number
    }).number(this.column.name, this.column.type === PageDataType.Double ? 'double' : 'int', fromValue, toValue, {
      FilterType: filterType,
      PropertyName: this.column.filterName
    }).value;
  }

  protected buildForm() {
    this.filterForm = this.formBuilder.group({
      filterType: new UntypedFormControl(PageFilterType.Equals),
      from: new UntypedFormControl(null, {
        updateOn: 'submit',
        validators: [Validators.required]
      }),
      to: new UntypedFormControl(null, {
        updateOn: 'submit',
        validators: [this.formsService.createRequiredIfValueValidator('filterType', PageFilterType.Between)]
      })
    });
  }

  protected buildFormValue(pageFilter: PageFilterGroup): Dictionary {
    let filterType: PageFilterType;
    let from: number;
    let to: number;

    const dateFilterGroup = this.pageFilterService.getFilterGroup(pageFilter, this.column.filterName ?? this.column.name);

    if (dateFilterGroup?.Filters?.length > 1) {
      filterType = PageFilterType.Between;
      from = dateFilterGroup.Filters[0].PropertyValue as number;
      to = dateFilterGroup.Filters[1].PropertyValue as number;
    } else if (dateFilterGroup?.Filters?.length === 1) {
      filterType = dateFilterGroup.Filters[0].FilterType;
      from = dateFilterGroup.Filters[0].PropertyValue as number;
    }

    return { filterType, from, to };
  }

  protected getGridFilterName(): GridFilterName {
    return GridFilterName.Number;
  }
}
