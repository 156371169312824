import { HttpErrorResponse } from '@angular/common/http';
import { Component, DoCheck, Inject, KeyValueDiffer, KeyValueDiffers, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { License } from '@portal-core/licenses/models/license.model';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsFilterService } from '@portal-core/task-boards/services/task-boards-filter.service';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { TaskProfileDialogDetailsComponent } from '@portal-core/tasks/components/task-profile-dialog/task-profile-dialog-details/task-profile-dialog-details.component';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { TaskPriority } from '@portal-core/tasks/enums/tasks-priority.enum';
import { TaskCommentAsset } from '@portal-core/tasks/models/task-comment-asset.model';
import { TaskCommentGroup } from '@portal-core/tasks/models/task-comment-group.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { ObservableQueueHandlerService } from '@portal-core/tasks/services/observable-queue-handler.service';
import { TaskCommentsDialogService } from '@portal-core/tasks/services/task-comments-dialog.service';
import { TasksService } from '@portal-core/tasks/services/tasks.service';
import { TaskUtils } from '@portal-core/tasks/utils/tasks.util';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { filter as _filter, map as _map, cloneDeep, groupBy, toPairs } from 'lodash';
import { Observable, Subscription, combineLatest, filter, first, map, of, switchMap, tap } from 'rxjs';

export interface TaskProfileDialogData {
  taskId: number;
  assignedUserId?: string
}

@Component({
  selector: 'mc-task-profile-dialog',
  templateUrl: './task-profile-dialog.component.html',
  styleUrls: ['./task-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe()
export class TaskProfileDialogComponent implements OnInit, DoCheck {
  static DialogConfig: MatDialogConfig = {
    width: '59rem',
    height: '85rem',
    panelClass: 'mc-dialog-container',
    autoFocus: false
  };

  public TaskMilestone: typeof TaskMilestone = TaskMilestone;
  public TaskStatus: typeof TaskStatus = TaskStatus;

  @ViewChildren(TaskProfileDialogDetailsComponent) detailsComponent: TaskProfileDialogDetailsComponent;

  loadingData: boolean;
  task: Task;
  task$: Observable<Task>;
  newComment: TaskComment;
  canEditAllTasks$: Observable<boolean>;
  projects$: Observable<Project[]>;
  licenseUsers$: Observable<LicenseUser[]>;
  commentGroups: TaskCommentGroup[];
  taskBoards$: Observable<TaskBoard[]>;
  license: License;
  isUploadingAttachments: boolean = false;
  isDialogDirty: boolean = false;
  taskBoard$: Observable<TaskBoard>;

  private isDiscussionDirty: boolean = false;
  private saveSub: Subscription;
  private deleteSub: Subscription;
  private taskDiffer: KeyValueDiffer<string, any>;
  private taskCopy: Task;
  private commentAssetsCount: number;
  private newTaskDescription: string;

  constructor(
    private tasksService: TasksService,
    private taskBoardsService: TaskBoardsService,
    private taskBoardsFilterService: TaskBoardsFilterService,
    private errorService: ErrorService,
    private currentService: CurrentService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TaskProfileDialogComponent>,
    private taskCommentsDialogService: TaskCommentsDialogService,
    private permissionsService: PermissionsService,
    private snackBar: MatSnackBar,
    private projectsService: ProjectsService,
    private licenseUsersService: LicenseUsersService,
    private differs: KeyValueDiffers,
    private observableQueueHandlerService: ObservableQueueHandlerService,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: TaskProfileDialogData) { }

  ngOnInit() {
    this.loadingData = true;
    this.license = this.currentService.getActiveLicense();

    this.taskBoardsFilterService.currentTaskBoard$;
    this.taskBoard$ = this.taskBoardsFilterService.currentTaskBoard$;
    this.taskBoards$ = this.taskBoardsService.getTaskBoards$(this.license.Id);

    // populate project selection list
    this.projects$ = this.projectsService.getProjectsByLicenseId$(this.license.Id)
      .pipe(
        map(projects => projects.filter(p => p.Status !== ProjectStatus.Archived)),
      );

    // populate license users selection list
    this.licenseUsers$ = this.licenseUsersService.getAllLicenseUsersByLicenseId$(this.license.Id, false)
      .pipe(
        map(licenseUsers => licenseUsers.filter(licenseUser => licenseUser.SeatType !== LicenseUserSeatType.SME && licenseUser.IsActive)
          .sort((lu1, lu2) => lu1.User.FullName.toLowerCase() > lu2.User.FullName.toLowerCase() ? 1 : -1)),
      );

    // TODO: only load task data when it doesn't exist, currently this ensures task data always up to date when card opens
    const forceApiRequest = true;
    const getTask$ = this.data.taskId === 0
      ? this.taskBoard$.pipe(map(taskBoard => this.createTask(taskBoard, this.data.assignedUserId)))
      : this.tasksService.getItemById$(this.data.taskId, { forceApiRequest });
    this.task$ = getTask$;
    this.observableQueueHandlerService.enqueue(getTask$.pipe(first(), tap(task => {
      this.initDialog(task);
      this.loadingData = false;
    })));

    this.canEditAllTasks$ = combineLatest([
      this.task$,
      this.permissionsService.currentUserHasPermission$(CentralPermissions.EditAllTasks)])
      .pipe(map(([task, canEditAllTasks]) => canEditAllTasks && task && !task.IsDeleted));

    this.dialogRef.backdropClick().subscribe(() => {
      if (this.loadingData) {
        this.dialog.open(ConfirmDialogComponent, {
          width: '36rem',
          data: {
            prompt: 'Are you sure you want to close? Changes will not be saved.',
            action: 'OK'
          }
        }).afterClosed().pipe(
          filter(confirmed => confirmed)
        ).subscribe(
          ok => this.dialogRef.close(),
          error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
            duration: 2500
          })
        );
      }
    });
  }

  ngDoCheck() {
    if (this.taskDiffer && this.task) {
      const changes = this.taskDiffer.diff(this.task);
      if (changes) {
        this.checkIsDialogDirty();
      }
    }
  }

  onDescriptionChanged(description: string) {
    this.newTaskDescription = description;
    this.checkIsDialogDirty();
  }

  private createTask(taskBoard: TaskBoard, assignedUserId: string) {
    const userId = this.currentService.getLicenseUser().User.Id;
    const task: Task = {
      Id: 0,
      CreatedByUserId: userId,
      AssignedUserId: assignedUserId || userId,
      LicenseId: this.license.Id,
      Title: '',
      Description: '',
      EstHour: 0,
      IsDeleted: false,
      CreatedOn: new Date().toISOString(),
      Milestone: TaskMilestone.ToDo,
      TaskPriority: TaskPriority.Low,
      Status: TaskStatus.Active,
      TaskCommentCount: 0,
      TaskAssetCount: 0,
      Position: -1,
      AllDay: false,
      TaskBoardId: taskBoard?.Id
    };

    return task;
  }

  private checkIsDialogDirty() {
    // avoiding a ExpressionChangedAfterItHasBeenCheckedError when attempting to reassign boolean
    setTimeout(() => {
      // comparing each property to ignore properties we do not need to check and collection modifications
      const taskDetailsChanged = this.task.Title !== this.taskCopy.Title
        || this.task.TaskPriority !== this.taskCopy.TaskPriority
        || this.task.Status !== this.taskCopy.Status
        || this.task.Milestone !== this.taskCopy.Milestone
        || this.task.EstHour !== this.taskCopy.EstHour
        || this.task.Position !== this.taskCopy.Position
        || this.task.StartDate !== this.taskCopy.StartDate
        || this.task.DueDate !== this.taskCopy.DueDate
        || this.task.AllDay !== this.taskCopy.AllDay
        || this.task.CreatedByUserId !== this.taskCopy.CreatedByUserId
        || this.task.AssignedUserId !== this.taskCopy.AssignedUserId
        || this.task.ProjectId !== this.taskCopy.ProjectId
        || this.newTaskDescription !== this.taskCopy.Description
        || this.task.TaskBoardId !== this.taskCopy.TaskBoardId;

      const assets = this.taskCommentsDialogService.commentAssets$.getValue();
      const assetsChanged = assets.some(a => (a.Asset.Id === 0 && !a.Asset.IsDeleted) || (a.Asset.Id !== 0 && a.Asset.IsDeleted));

      this.isDialogDirty = taskDetailsChanged || this.isDiscussionDirty || assetsChanged || assets.length !== this.commentAssetsCount;
    });
  }

  initDialog(task: Task) {
    this.task = cloneDeep(task);
    this.taskCopy = cloneDeep(task);
    this.newComment = this.taskCommentsDialogService.createComment(task.Id);
    this.taskCommentsDialogService.reset();
    this.isDialogDirty = false;
    this.taskDiffer = this.differs.find(this.task).create();

    // create set of comment groups, grouped by date
    const comments = _filter(this.task.TaskComments, c => task.IsDeleted || !c.IsDeleted);
    const groups = groupBy(comments, c => new Date(c.CreatedOn).setHours(0, 0, 0, 0));
    this.commentGroups = _map(toPairs(groups), pair => {
      const commentGroup: TaskCommentGroup = {
        Date: new Date(parseInt(pair[0])),
        Comments: pair[1].sort((comment1, comment2) => comment1.CreatedOn > comment2.CreatedOn ? -1 : 1),
      };
      return commentGroup;
    }).sort((group1, group2) => group1.Date > group2.Date ? -1 : 1);

    const userId = this.currentService.getLicenseUser().User.Id;
    this.taskCommentsDialogService.initialize(userId, this.task.TaskComments);
    this.commentAssetsCount = this.taskCommentsDialogService.commentAssets$.getValue().length;
    this.newTaskDescription = this.task.Description;
  }

  onCloseDialogClicked() {
    this.dialogRef.close();
  }

  onTaskDiscussionDirtyChanged(event: boolean) {
    this.isDiscussionDirty = event;
    this.checkIsDialogDirty();
  }

  onTaskAttachmentsDirtyChanged() {
    this.checkIsDialogDirty();
  }

  onTaskDelete() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        action: 'Delete',
        title: 'Delete Task?',
        prompt: `Deleting "${this.task.Title}" will permanently delete the task, including comments, replies, and attached files.`,
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (!confirmed) {
          return;
        }
        let getTasks$: Observable<Task[]>;
        if (this.task.Status !== TaskStatus.Active) {
          getTasks$ = of(undefined);
        } else {
          getTasks$ = this.tasksService.getTasksByMilestone$(this.license.Id, this.task.Milestone, this.task.TaskBoardId);
        }
        this.task.IsDeleted = true;
        this.loadingData = true;

        if (this.deleteSub) {
          this.deleteSub.unsubscribe();
        }

        this.deleteSub = this.tasksService.deleteTasks$([this.task.Id])
          .pipe(
            switchMap(() => getTasks$),
            first(),
            switchMap(tasks => TaskUtils.repositionTasks$(this.tasksService, [this.task], tasks))
          ).subscribe(
            () => {
              this.dialogRef.close();
              this.snackBar.open('Task deleted', 'OK', {
                duration: 2500
              });
            },
            (errorResponse: HttpErrorResponse) => {
              this.dialog.open(ErrorDialogComponent, {
                ...ErrorDialogComponent.DialogConfig,
                data: {
                  title: 'Error Delete Task',
                  message: 'An error occurred while deleting a task',
                  errors: this.errorService.getErrorMessages(errorResponse)
                }
              });
              this.loadingData = false;
            }
          );
      });
  }

  areDatesNotSetOrOverlapping(): boolean {
    return !!this.task.StartDate
      && !!this.task.DueDate
      && new Date(this.task.StartDate) > new Date(this.task.DueDate);
  }

  onSaveClicked() {
    this.loadingData = true;
    this.dialogRef.disableClose = true;
    this.task.Description = this.newTaskDescription;

    const formData = this.getTaskFormData();
    const statusCopy = this.taskCopy.Status;
    const milestoneCopy = this.taskCopy.Milestone;

    let saveTask$: Observable<any>;
    let saveAction: string;
    let reorderCurrentTasks$: Observable<Task[]>;
    let reorderPreviousTasks$: Observable<any>;

    if (this.task.Id === 0) {
      saveTask$ = this.tasksService.createTask$(formData).pipe(tap(() => {
        // Sends the event to Google Analytics
        this.analyticsService.trackEvent('task_create', {
          'event_category': 'Task',
          'event_label': 'Task Created',
          'license_id': this.license.Id
        });

      }));
      saveAction = 'created';
    } else {
      saveTask$ = this.tasksService.updateTask$(this.task.Id, formData);
      saveAction = 'saved';
    }

    if (this.task.Milestone !== this.taskCopy.Milestone || (this.task.Status !== this.taskCopy.Status && this.taskCopy.Status === TaskStatus.Active)) {
      reorderPreviousTasks$ = this.tasksService.getTasksByMilestone$(this.license.Id, this.taskCopy.Milestone, this.task.TaskBoardId);
    } else if (this.task.Status !== this.taskCopy.Status) {
      reorderPreviousTasks$ = this.tasksService.getTasksByStatus$(this.license.Id, this.taskCopy.Status, this.task.TaskBoardId);
    }
    if (reorderPreviousTasks$) {
      reorderPreviousTasks$ = reorderPreviousTasks$.pipe(
        first(),
        switchMap(tasks => TaskUtils.repositionTasks$(this.tasksService, [], tasks))
      );
    } else {
      reorderPreviousTasks$ = of({});
    }

    if (this.task.Status === TaskStatus.Active) {
      reorderCurrentTasks$ = this.tasksService.getTasksByMilestone$(this.license.Id, this.task.Milestone, this.task.TaskBoardId);
    } else {
      reorderCurrentTasks$ = this.tasksService.getTasksByStatus$(this.license.Id, this.task.Status, this.task.TaskBoardId);
    }
    reorderCurrentTasks$ = reorderCurrentTasks$.pipe(
      first(),
      switchMap(tasks => TaskUtils.repositionTasks$(this.tasksService, [this.task], tasks))
    );

    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }

    this.saveSub = saveTask$.pipe(
      tap(newTask => this.initDialog(newTask)),
      switchMap(() => reorderPreviousTasks$),
      switchMap(() => reorderCurrentTasks$),
      first())
      .subscribe(() => {
        this.isUploadingAttachments = false;
        this.isDiscussionDirty = false;
        this.loadingData = false;
        this.dialogRef.disableClose = false;
        this.snackBar.open(`Task ${saveAction}`, 'OK', {
          duration: 2500
        });
      }, (errorResponse: HttpErrorResponse) => {
        this.loadingData = false;
        this.dialogRef.disableClose = false;
        this.dialog.open(ErrorDialogComponent, {
          ...ErrorDialogComponent.DialogConfig,
          data: {
            title: 'Error Saving Task',
            message: 'An error occurred while saving a task',
            errors: this.errorService.getErrorMessages(errorResponse)
          }
        });
      });
  }

  onClose() {
    if (this.loadingData) {
      this.dialog.open(ConfirmDialogComponent, {
        width: '36rem',
        data: {
          prompt: 'Are you sure you want to close? Changes will not be saved.',
          action: 'OK'
        }
      }).afterClosed().pipe(filter(confirmed => confirmed))
        .subscribe(
          ok => this.dialogRef.close(),
          error => this.snackBar.open(this.errorService.getErrorMessages(error)[0], 'OK', {
            duration: 2500
          })
        );
    } else {
      this.dialogRef.close();
    }
  }

  getTaskFormData(): FormData {
    if (this.newComment && (this.newComment.Comment || this.newComment.TaskAssets)) {
      if (!this.task.TaskComments) {
        this.task.TaskComments = [];
      }
      this.task.TaskComments = this.task.TaskComments.concat(this.newComment);
    }

    const commentAssets: TaskCommentAsset[] = this.taskCommentsDialogService.commentAssets$.getValue();
    const formData: FormData = new FormData();

    let uploadId = 0;
    commentAssets.forEach(commentAsset => {
      if (commentAsset.Asset.FormData) {
        commentAsset.Asset.UploadId = `File_${uploadId}`;
        let data = null;
        commentAsset.Asset.FormData.forEach(element => {
          data = element;
        });
        formData.append(commentAsset.Asset.UploadId, data);
        uploadId++;
      }
    });
    formData.append('Task', JSON.stringify(this.task));

    this.isUploadingAttachments = uploadId > 0;

    return formData;
  }

  onStatusClicked(status: TaskStatus, milestone?: TaskMilestone) {
    this.task.Status = status;
    if (milestone === 0 || milestone) {
      this.task.Milestone = milestone;
    }
  }
}
