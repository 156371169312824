import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ImageService } from '@portal-core/general/services/image.service';
import { NodeViewBase } from '@portal-core/text-editor/util/node-view.base';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-image-node-view',
  templateUrl: './image-node-view.component.html',
  styleUrls: ['./image-node-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageNodeViewComponent extends NodeViewBase implements OnChanges {
  @Input() alt: string;
  @Input() src: string;
  @Input() title: string;
  @Input() width: string;
  @Input() height: string;
  @Output() load: EventEmitter<any> = new EventEmitter<Event>();

  imgSrc: string;
  imageHeight: string;
  imageWidth: string;
  loadingState: LoadingState<string> = new LoadingState<string>();

  public get parentClassNameSuffix(): string {
    return 'image-node-view';
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected elementRef: ElementRef, private imageService: ImageService) {
    super(changeDetectorRef, elementRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.width || changes.height) {
      if (changes.width) {
        this.imageWidth = changes.width?.currentValue;
      }
      if (changes.height) {
        this.imageHeight = changes.height?.currentValue;
      }
      this.detectChanges();
    }

    if (changes.src) {
      this.loadImage();
    }
  }

  private loadImage() {
    if (this.src) {
      this.loadingState.update(true);
      this.imageService.loadImage$(this.src).subscribe(img => {
        this.imgSrc = img.src;

        // Verify svg has number or pixel based height and width attributes
        if (this.src.endsWith('.svg')) {
          this.imageService.fetchSvg$(this.imgSrc).subscribe(svg => {
            if (typeof svg === 'undefined') {
              this.loadingState.update(false, 'Unable to load image. Invalid svg');
              this.load.emit();
              this.detectChanges();
              return;
            }
            // Make sure at least one dimension exists. If svg has width but no height, the browser can display fine.
            // If a natural dimension isn't available, use w3's intrinsic size https://www.w3.org/TR/css-sizing-3/#intrinsic-sizes
            // no width and no height, set width
            const number = SVGLength.SVG_LENGTHTYPE_NUMBER;
            const pixel = SVGLength.SVG_LENGTHTYPE_PX;
            if (!(svg.width.baseVal.unitType === number || svg.width.baseVal.unitType === pixel) &&
              !(svg.height.baseVal.unitType === number || svg.height.baseVal.unitType === pixel)) {
              this.imageWidth = `${img.naturalWidth || '300'}px`;
            }
            // no width but height, set height
            else if (!(svg.width.baseVal.unitType === number || svg.width.baseVal.unitType === pixel) &&
              (svg.height.baseVal.unitType === number || svg.height.baseVal.unitType === pixel)) {
              this.imageHeight = `${img.naturalHeight || '150'}px`;
            }
            this.detectChanges();
          });
        }

        this.loadingState.update(false);
        this.load.emit();
        this.detectChanges();
      }, () => {
        this.imgSrc = null;
        this.loadingState.update(false, 'Unable to load image');
        this.load.emit();
        this.detectChanges();
      });
    } else {
      this.imgSrc = null;
      this.loadingState.update(false);
      this.load.emit();
      this.detectChanges();
    }
  }
}
