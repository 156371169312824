import { Directive, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { SortDirection } from '@common/paged-data/types/sort-direction.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

/**
 * FilterableListBase
 * A base class for list components that provides common functionality for setting and getting page filters on the list.
 */
@Directive()
export abstract class FilterableListBase<T> implements Filterable {
  /** The data list id for this list in its collection data store. */
  @Input() dataListId: string = uuidv4();

  /** The config for the list's filters. */
  filterConfig: PageFilterConfigBase;

  constructor(public collectionService: CollectionServiceBase<T>) { }

  /** Sets a page filter in the list's collection data store. */
  applyFilter$(name: string, filter: PageFilterGroup): Observable<any> {
    return this.collectionService.setPagedDataListFilterByName$(this.dataListId, name, filter);
  }

  /** Gets a page filter from the list's collection data store. */
  getFilter(name: string): PageFilterGroup {
    return this.collectionService.getPagedDataListFilterByName(this.dataListId, name);
  }

  /** Gets an observable of the page filter from the list's collection data store. */
  getFilter$(name: string): Observable<PageFilterGroup> {
    return this.collectionService.getPagedDataListFilterByName$(this.dataListId, name);
  }

  /** Gets an observable of the page filters from the list's collection data store. */
  getFilters$(): Observable<Dictionary<PageFilterGroup>> {
    return this.collectionService.getPagedDataListFilters$(this.dataListId);
  }

  /** Gets the sort order from the list's collection data store. */
  getOrder(): PageSort {
    return this.collectionService.getPagedDataListOrder(this.dataListId);
  }

  /** Gets an observable of the sort order from the list's collection data store. */
  getOrder$(): Observable<PageSort> {
    return this.collectionService.getPagedDataListOrder$(this.dataListId);
  }

  /** Sets the sort order in the list's collection data store. */
  setOrder$(orderBy: string, orderDir: SortDirection): Observable<any> {
    return this.collectionService.setPagedDataListOrder$(this.dataListId, orderBy, orderDir);
  }
}
