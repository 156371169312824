import { ChangeDetectionStrategy, Component, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { AutocompleteInputComponent } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.component';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { keyBy } from 'lodash';
import { first } from 'rxjs';

@Component({
  selector: 'mc-grid-select-filter',
  templateUrl: './grid-select-filter.component.html',
  styleUrls: ['./grid-select-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridSelectFilterComponent extends GridFilterBase {
  @ViewChild(MatSelectionList) selectionList: MatSelectionList;
  @ViewChild(AutocompleteInputComponent, { static: false }) autocompleteInput: AutocompleteInputComponent;

  constructor(protected formBuilder: UntypedFormBuilder, private ngZone: NgZone, pageFilterService: PageFilterService) {
    super(pageFilterService);

    // Do this in the constructor so that the form is ready for ngOnChanges
    this.buildForm();
  }

  onMenuOpened() {
    super.onMenuOpened();

    // Give focus to the selection list if there is no custom menu
    if (!this.menuTemplate) {
      if (this.selectionList?.options.length > 0) {
        this.selectionList.options.first.focus();
      }

      // Wait until things are stable to set the selected items and focus the autocomplete component
      this.ngZone.onStable.asObservable().pipe(
        first()
      ).subscribe(() => {
        this.autocompleteInput?.focus();
      });
    }
  }

  onSubmit() {
    this.submitFilter(this.buildFilter(this.filterForm.value['options']));
  }

  buildFilter(values: (number | string)[]): PageFilterGroup {
    const optionsById = keyBy(this.column.selectOptions, option => {
      return option.id;
    });

    return this.pageFilterService.create(this.filterable.getFilter(GridFilterName.Select) ?? {
      Id: GridFilterName.Select,
      Type: PageFilterGroupType.Select
    }).select(this.column.name, values, values?.map(id => {
      const option = optionsById[id];
      return {
        FilterType: option?.filterType,
        PropertyName: option?.propertyName ?? this.column.filterName,
        PropertyType: option?.propertyType ?? this.column.filterDataType ?? this.column.type
      };
    })).value;
  }

  protected buildForm() {
    this.filterForm = this.formBuilder.group({
      options: new UntypedFormControl(null)
    });
  }

  protected buildFormValue(pageFilter: PageFilterGroup): Dictionary {
    let options: (string | number)[];
    if (this.column && this.filterable) {
      options = this.pageFilterService.getValueFromSelects(pageFilter, this.column.name);
    }

    return { options };
  }

  protected getGridFilterName(): GridFilterName {
    return GridFilterName.Select;
  }
}
