import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { BuildSchedule } from '@portal-core/builds/models/build-schedule.model';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';

@CollectionStateSelectors({
  stateType: BuildsScheduleState
})
@State<BuildsScheduleState>({
  name: BuildsScheduleState.source,
})
@Injectable()
export class BuildsScheduleState extends CollectionStateBase {
  static source = 'BuildsSchedule';
  getSource(): string {
    return BuildsScheduleState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BuildsScheduleDataService extends CollectionDataServiceBase<BuildSchedule> {
  constructor(protected store: Store) {
    super(store, BuildsScheduleState);
  }
}
