import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';

@Pipe({
  name: 'mcReviewFileUserStatusColorClass'
})
export class ReviewFileUserStatusColorClassPipe implements PipeTransform {
  transform(status: ReviewFileUserStatus | null | undefined, style: 'bg' | 'border' | 'fill', luminosity: '' | 'muted' | 'vivid' = ''): string {
    switch (status) {
      case ReviewFileUserStatus.Todo:
        return `mc-${style}-caution${luminosity ? '-' + luminosity : ''}`;
      case ReviewFileUserStatus.Submitted:
        return `mc-${style}-info${luminosity ? '-' + luminosity : ''}`;
      case ReviewFileUserStatus.InProgress:
        return `mc-${style}-info-2${luminosity ? '-' + luminosity : ''}`;
    }
  }
}
