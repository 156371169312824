<div class="mc-d-flex mc-flex-row mc-justify-content-center mc-align-items-center mc-review-file-status-bar-chart-wrapper">
  <mc-chart type="bar" [options]="chartOptions" [data]="chartData$ | async"></mc-chart>

  <div class="mc-review-file-status-bar-chart-aside-title mc-d-flex mc-align-items-center">
    <ng-container *ngIf="analytics?.TotalFileCount && chartCounts">
      {{chartCounts.Accepted}}/{{analytics.TotalFileCount}} Files {{ ReviewFileStatus.Accepted | mcReviewFileStatusText }}
    </ng-container>
  </div>
</div>

<div class="mc-d-flex mc-flex-row mc-align-items-center mc-review-file-status-bar-chart-count-wrapper" [ngClass]="'mc-justify-content-' + keyJustification">
  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.Open">
    <span class="mc-dot mc-dot-medium" [ngClass]="[ReviewFileStatus.Open | mcReviewFileStatusColorClass : 'bg']"></span>
    <span class="mc-review-file-status-bar-chart-dot-label"><b>{{ chartCounts.Open }}</b> {{ ReviewFileStatus.Open | mcReviewFileStatusText }} </span>
  </div>

  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.Closed">
    <span class="mc-dot mc-dot-medium mc-basic"></span>
    <span class="mc-review-file-status-bar-chart-dot-label"><b> {{ chartCounts.Closed }}</b> {{ ReviewFileStatus.Closed | mcReviewFileStatusText }} </span>
  </div>

  <div class="mc-d-flex mc-align-items-center" *ngIf="chartCounts?.Accepted">
    <span class="mc-dot mc-dot-medium" [ngClass]="[ReviewFileStatus.Accepted | mcReviewFileStatusColorClass : 'bg']"></span>
    <span class="mc-review-file-status-bar-chart-dot-label"><b>{{ chartCounts.Accepted }}</b> {{ ReviewFileStatus.Accepted | mcReviewFileStatusText }} </span>
  </div>
</div>