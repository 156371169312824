import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';
import { ProcessNotificationDataService } from './process-notification-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessNotificationService extends CollectionServiceBase<ProcessNotification> {
  constructor(
    private processNotificationDataService: ProcessNotificationDataService,
    protected dataService: DataService) {
    super(processNotificationDataService, dataService);
  }
}
