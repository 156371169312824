<mc-grid-header-filter [column]="column" [hasFilter]="hasFilter" (menuClosed)="onMenuClosed()" (menuClosing)="onMenuClosing()" (menuOpened)="onMenuOpened()">
  <ng-template mcGridHeaderFilterForm>
    <form #filterFormDirective="ngForm" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <mat-form-field color="primary-vivid">
        <input #searchInput matInput placeholder="Search" type="text" formControlName="search">
      </mat-form-field>

      <div class="mc-menu-button-group">
        <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
        <button type="submit" mat-flat-button color="primary">Filter</button>
      </div>
    </form>
  </ng-template>

  <ng-template *ngIf="menuTemplate" mcGridHeaderFilterMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-header-filter>
