import { updateFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { forEachChild } from '@common/prosemirror/model/node';
import { ProseMirrorNode } from 'prosemirror-model';
import { Transform } from 'prosemirror-transform';

export function toggleMultipleChoiceItem(tr: Transform, multipleChoiceItemNode: ProseMirrorNode, itemPos: number, multipleChoiceNode: ProseMirrorNode, multipleChoicePos: number): Transform {
  // If this is a multi-answer question
  if (multipleChoiceNode.attrs['MadCap:isMultiResponse']) {
    // Then toggle the node's checked value (the correct answer)
    updateFlareNodeAttrs(tr, multipleChoiceItemNode, itemPos, { 'MadCap:correctAnswer': !multipleChoiceItemNode.attrs['MadCap:correctAnswer'] });
  } else {
    // Only update the correct answers if this node is currently not checked (aka going to change from being clicked)
    if (!multipleChoiceItemNode.attrs['MadCap:correctAnswer']) {
      // Loop through all the multiple choice items and change any that are checked to unchecked (because thats how radio buttons work)
      forEachChild(multipleChoiceNode, multipleChoicePos, (child, parentOffset, docPos) => {
        if (child.type.name === 'madcapmultiplechoiceitem' && child.attrs['MadCap:correctAnswer']) {
          updateFlareNodeAttrs(tr, child, docPos, { 'MadCap:correctAnswer': false });
        }
      });

      // Change this node to be checked (the correct answer)
      updateFlareNodeAttrs(tr, multipleChoiceItemNode, itemPos, { 'MadCap:correctAnswer': true });
    }
  }

  return tr;
}
