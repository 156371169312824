<div class="mc-avatar-tooltip-container" [matTooltip]="taskBoard?.Title" [matTooltipDisabled]="disabledTooltip || !taskBoard">
  <button *ngIf="showAvatar" type="button" class="mc-avatar-container" [ngClass]="color === 'invert' ? 'mc-text-avatar-invert' : 'mc-text-avatar'" [disabled]="isImageDisabled()" (click)="onAvatarClicked()">
    <svg viewBox="0 0 1024 1024" class="mc-avatar-svg">
      <rect class="mc-task-board-avatar-svg-bg" [ngClass]="color === 'invert' ? 'mc-fill-avatar-invert' : 'mc-fill-avatar'" x="100" y="180" width="830" height="580" />
      <path class="mc-avatar-svg-active-fill" [ngClass]="color === 'invert' ? 'mc-fill-avatar' : 'mc-fill-avatar-invert'" d="M410.368 810.666667l-73.536 147.072a42.666667 42.666667 0 0 1-76.330667-38.144L314.965333 810.666667H85.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h384V85.333333a42.666667 42.666667 0 1 1 85.333334 0v42.666667h384a42.666667 42.666667 0 0 1 42.666666 42.666667v597.333333a42.666667 42.666667 0 0 1-42.666666 42.666667H709.034667l54.464 108.928a42.666667 42.666667 0 0 1-76.330667 38.144L613.632 810.666667h-203.264z m-27.221333-85.333334H896V213.333333H128v512h255.146667z" />
    </svg>

    <span class="mc-avatar-initials">
      <ng-container *ngIf="taskBoard?.Title">
        {{ taskBoard.Title | slice : 0 : 3 | uppercase }}
      </ng-container>
    </span>
  </button>

  <a *ngIf="showName && nameLinksToPage" href="#" class="mc-avatar-name" [routerLink]="['/', taskBoard?.LicenseId, 'tasks', 'boards', taskBoard?.Id]">{{ taskBoard?.Title }}</a>

  <mc-inline-button *ngIf="showName && !nameLinksToPage" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">{{ taskBoard?.Title }}</mc-inline-button>
</div>
