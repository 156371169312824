import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { StringService } from '@portal-core/general/services/string.service';
import { EditorContextMenuEvent, TextEditorComponent } from '@portal-core/text-editor/components/text-editor/text-editor.component';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { EditorState, Plugin, Selection } from 'prosemirror-state';
import { DirectEditorProps, EditorView, NodeViewConstructor } from 'prosemirror-view';

@Component({
  selector: 'mc-solo-file-text-editor',
  templateUrl: './solo-file-text-editor.component.html',
  styleUrls: ['./solo-file-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoloFileTextEditorComponent implements AfterViewInit, OnChanges {
  @ViewChild(TextEditorComponent, { static: true }) textEditor: TextEditorComponent;
  @Input() content: string;
  @Input() editorProps?: Partial<DirectEditorProps>;
  @Input() language?: string;
  @Input() nodeViews: Dictionary<NodeViewConstructor>;
  @Input() plugins: Plugin[];
  @Input() readonly: boolean;
  @Input() schema: CollabSchema; // TODO: this should be Schema but CollabSchema has conversion method that this needs. So move some of those around in the common project.

  @Output() change: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() editorContextMenu: EventEmitter<EditorContextMenuEvent> = new EventEmitter<EditorContextMenuEvent>();
  @Output() editorStateChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() load: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() ready: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() selectionChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();

  get editorState(): EditorState {
    return this.textEditor ? this.textEditor.editorState : null;
  }

  get editorView(): EditorView {
    return this.textEditor ? this.textEditor.editorView : null;
  }

  get viewPluginOverlay(): HTMLElement {
    return this.textEditor?.viewPluginOverlay;
  }

  hasBOM: boolean;
  doc: ProseMirrorNode;

  constructor(private stringService: StringService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      if (this.content) {
        this.doc = this.schema.codeToDoc(this.content);
        this.maybeUpdateTextEditorContent();
      } else {
        this.doc = null;
      }
    }
  }

  ngAfterViewInit() {
    this.maybeUpdateTextEditorContent();
  }

  onEditorContextMenu(editorEvent: EditorContextMenuEvent) {
    this.editorContextMenu.emit(editorEvent);
  }

  onEditorReady(event: EditorChangeEvent) {
    this.maybeUpdateTextEditorContent();
    this.ready.emit(event);
  }

  getContent(): string {
    return this.schema.nodeToCode(this.editorState.doc);
  }

  markAsPristine() {
    this.textEditor.markAsPristine();
  }

  updateTextEditorContent() {
    // Reset the state primarily to reset the prosemirror-history plugin
    this.textEditor.resetState();
    // Replace the content of the editor with the new content
    const tr = this.editorView.state.tr.replaceWith(0, this.editorView.state.doc.content.size, this.doc);
    // Move the selection at the beginning of the doc
    tr.setSelection(Selection.atStart(tr.doc));
    // In case the prosemirror-history plugin is being used set 'addToHistory' to false so that setting the content does not add to the undo history
    tr.setMeta('addToHistory', false);
    tr.setMeta('trackChange', false);

    this.editorView.dispatch(tr);

    this.load.emit({
      editorView: this.editorView,
      oldEditorState: null,
      newEditorState: this.editorView.state
    });
  }

  maybeUpdateTextEditorContent() {
    if (this.textEditor && this.textEditor.editorView && this.doc) {
      this.updateTextEditorContent();
    }
  }
}
