<div class="mc-storage-usage-bar-header">
  <b>Storage</b>

  <span class="mc-fill-remaining-flex-space"></span>

  <span class="mc-text-muted">
    {{ licenseStorage?.UsedStorageSpace | mcFileSize }} of
    <ng-container *ngIf="trialLicense else notTrialStorageSize">Unlimited</ng-container>
    <ng-template #notTrialStorageSize>{{ licenseStorage.MaxStorageSpace | mcFileSize }} used ({{ (licenseStorage?.MaxStorageSpace - licenseStorage?.UsedStorageSpace) | mcFileSize }} available)</ng-template>
  </span>
</div>

<mat-progress-bar mode="determinate" color="usage-storage" [value]="(licenseStorage?.UsedStorageSpace / licenseStorage?.MaxStorageSpace) * 100"></mat-progress-bar>
