/**
 * Converts a string, number, or boolean into a boolean.
 * String values of true and 1 return true. The check is case-insensitive.
 * Any other string value returns false.
 * A number value of zero returns false.
 * Any other number value returns true.
 * A boolean value is simply returned.
 * A nullish value returns false.
 * @param value The string/number/boolean value to convert.
 * @returns Returns true or false depending on the input.
 */
export function toBoolean(value: string | number | boolean, defaultValue: boolean | null = false): boolean {
  if (typeof value === 'boolean') {
    return value;
  } else if (typeof value === 'number') {
    return !!value;
  } else if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
      case '1':
        return true;
      case 'false':
      case '0':
        return false;
    }
  }

  return defaultValue;
}

/**
 * Converts a string, number, or boolean into a boolean string.
 * By default the string returned is either 'true' or 'false'.
 * Starting case can be used to instead return 'True' or 'False'.
 * @param value The string/number/boolean value to convert.
 * @param startCase Whether the boolean string should use starting case or not (True/False). Defaults to false.
 * @returns A string version of boolean value.
 */
export function toBooleanString(value: string | number | boolean, startCase: boolean = false): string {
  if (toBoolean(value)) {
    return startCase ? 'True' : 'true';
  } else {
    return startCase ? 'False' : 'false';
  }
}
