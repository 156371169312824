import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation, forwardRef } from '@angular/core';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { BuildPageFilterConfig } from '@portal-core/builds/util/build-page-filter-config';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { of, tap } from 'rxjs';

@Component({
  selector: 'mc-builds-select-list',
  templateUrl: './builds-select-list.component.html',
  styleUrls: ['./builds-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as BuildsSelectListComponent so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => BuildsSelectListComponent) }]
})
export class BuildsSelectListComponent extends SelectListBase<Build> implements OnChanges {
  @Input() branchName?: string;
  @Input() processStates?: ProcessState[];
  @Input() projectId: number;
  @Input() showLatestBuildOption?: boolean = false;
  @Input() targetPath?: string;

  filterConfig: BuildPageFilterConfig = new BuildPageFilterConfig(['Id']);

  listControl: SelectionListControl<Build> = new SelectionListControl<Build>(this.buildsService, filter => {
    if (typeof this.projectId === 'number') {
      return this.buildsService.getBuildsPageByProjectId$(this.projectId, filter).pipe(
        tap(page => {
          if (this.showLatestBuildOption) {
            if (page.Page === 0) {
              page.Items.splice(0, 0, { Id: 0 } as Build);
            }
          }
        })
      );
    } else {
      return of(null);
    }
  }, build => {
    return build.Id;
  });

  constructor(private buildsService: BuildsService, private pageFilterService: PageFilterService) {
    super(buildsService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.branchName) {
      this.applyFilter$('builds-select-list-branch-name', this.pageFilterService.create({
        Id: 'builds-select-list-branch-name',
        Type: PageFilterGroupType.String
      }).string('BranchName', this.branchName, { FilterType: PageFilterType.Equals }).value);
    }

    if (changes.processStates) {
      this.applyFilter$('builds-select-list-process-states', this.pageFilterService.create({
        Id: 'builds-select-list-process-states',
        Type: PageFilterGroupType.Select
      }).select('LastNotification.ProcessState', this.processStates).value);
    }

    if (changes.targetPath) {
      this.applyFilter$('builds-select-list-target-path', this.pageFilterService.create({
        Id: 'builds-select-list-target-path',
        Type: PageFilterGroupType.String
      }).string('TargetPath', this.targetPath, { FilterType: PageFilterType.Equals }).value);
    }

    if (changes.projectId || changes.showLatestBuildOption) {
      this.hardReload();
    }
  }
}
