import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { DataListBase } from '@portal-core/ui/list/util/data-list-base';

/**
 * ListComponent
 *
 * An infinite list component that connects to a collection data store and renders inside of a mat-list element.
 */
@Component({
  selector: 'mc-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends DataListBase {
  constructor(protected errorService: ErrorService, protected viewportRuler: ViewportRuler) {
    super(errorService, viewportRuler);
  }
}
