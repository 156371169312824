import { Component, Input, Output, EventEmitter, ViewEncapsulation, TemplateRef, ContentChild, AfterContentInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { GridHeaderMenuDirective } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.directive';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridMenuEvent } from '@portal-core/ui/grid/models/grid-menu-event.model';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';

@Component({
  selector: 'mc-grid-header-cell',
  templateUrl: './grid-header-cell.component.html',
  styleUrls: ['./grid-header-cell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridHeaderCellComponent implements AfterContentInit {
  @Input() column: GridColumn;
  @Input() columnInputs?: Dictionary<any>;
  @Input() filterable: Filterable;

  @Output() menuClosed: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() menuOpened: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();

  @ContentChild(GridHeaderMenuDirective) menuDirective: GridHeaderMenuDirective;
  @ViewChild('gridFilter') gridFilter: GridFilterBase;

  PageDataType: typeof PageDataType = PageDataType;

  menuTemplate: TemplateRef<any>;

  get menuOpen(): boolean {
    return this.gridFilter.menuOpen;
  }

  ngAfterContentInit() {
    this.menuTemplate = (this.menuDirective && this.menuDirective.templateRef) || null;
  }

  onMenuOpened(event: GridMenuEvent) {
    this.menuOpened.emit(event);
  }

  onMenuClosed(event: GridMenuEvent) {
    this.menuClosed.emit(event);
  }

  closeMenu() {
    this.gridFilter.closeMenu();
  }
}
