<span class="mc-grid-rows-range-label">Select top</span>
<mat-form-field
  class="mc-grid-rows-range-select-form-field"
  [color]="color"
>
  <mat-select [(value)]="rows" (selectionChange)="onRowsChanged($event)">
    <mat-option [value]="100">100</mat-option>
    <mat-option [value]="300">300</mat-option>
    <mat-option [value]="500">500</mat-option>
    <mat-option [value]="700">700</mat-option>
    <mat-option [value]="1000">1000</mat-option>
  </mat-select>
</mat-form-field>
<span class="mc-grid-rows-range-label">rows</span>
