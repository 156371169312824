import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { RoutingService } from '@portal-core/routing/services/routing.service';

/*
 * Returns the routerLink commands for a review file.
 * Usage:
 *   reviewFile | mcReviewFileLink
*/
@Pipe({
  name: 'mcReviewFileLink'
})
export class ReviewFileLinkPipe implements PipeTransform {
  constructor(private routingService: RoutingService) { }

  transform(reviewFile: ReviewFile): any[] {
    let commands: any[] = null;

    if (reviewFile) {
      const path = this.routingService.getReviewFilePath(reviewFile.LicenseId, reviewFile.ReviewPackageId, reviewFile.Id);

      // If the path is undefined then use the default path
      if (typeof path === 'undefined') {
        commands = ['/', reviewFile.LicenseId, 'reviews', 'packages', reviewFile.ReviewPackageId, 'files', reviewFile.Id];
      } else {
        commands = path;
      }
    }

    return commands;
  }
}
