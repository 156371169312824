import { SimpleDomNode } from '@common/html/simple-dom/node';
import { parseFromString } from '@common/html/simple-dom/parse';
import { RichTextSchema } from '@common/rich-text/rich-text-schema';
import { DOMSerializer, ProseMirrorNode, DOMParser as pmDOMParser } from 'prosemirror-model';

export class RichTextSerializer {
  codeToDoc(code: string, schema: RichTextSchema): ProseMirrorNode {
    // Convert the html string into an xml dom
    const doc = parseFromString(code, 'text/xml');

    // Convert the xml dom into a prosemirror doc
    return pmDOMParser.fromSchema(schema).parse(doc as unknown as Document);
  }

  nodeToCode(node: ProseMirrorNode, schema: RichTextSchema): string {
    if (node === undefined) {
      return null;
    }

    // Create a SimpleDom document for prosemirror to use
    const doc = parseFromString('', 'text/xml');

    // Convert the prosemirror doc into a document fragment
    const fragment = DOMSerializer.fromSchema(schema).serializeFragment(node.content, { document: doc as unknown as Document }) as unknown as SimpleDomNode;

    // SimpleDom defines innerHTML for a fragment so we can easily get the html string instead of having to add the fragment to a div and then grab its innerHTML.
    return fragment.innerHTML;
  }
}
