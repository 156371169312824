import { Injectable } from '@angular/core';
import { Selector, State, StateContext, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { AddItems, RemoveItems, Reset } from '@portal-core/data/collection/types/collection-state.type';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { Observable, map } from 'rxjs';

@CollectionStateSelectors({
  stateType: TaskCommentsState
})
@State<TaskCommentsState>({
  name: TaskCommentsState.source
})
@Injectable()
export class TaskCommentsState extends CollectionStateBase {
  static source = 'TaskComments';
  ByTaskId: { [taskId: number]: number[] } = {};

  getSource(): string {
    return TaskCommentsState.source;
  }

  getAddItemsState(ctx: StateContext<CollectionStateBase>, action: AddItems): any {
    let taskId;
    const comments = (<TaskComment[]>action.payload.items);
    for (const prop in comments) {
      if (comments[prop]
        && typeof comments[prop] !== 'function') {
        const comment = (<TaskComment>comments[prop]);
        if (!taskId) {
          taskId = comment.TaskId;
        }
        if (!(taskId in this.ByTaskId)) {
          this.ByTaskId[taskId] = [];
        }
        if (this.ByTaskId[taskId].indexOf(comment.Id) === -1) {
          this.ByTaskId[taskId].push(comment.Id);
        }
      }
    }
    return this;
  }

  getRemoveItemsState(ctx: StateContext<CollectionStateBase>, action: RemoveItems): any {
    const comments = action.payload.itemIds;
    for (const prop in comments) {
      if (comments[prop]
        && typeof comments[prop] !== 'function') {
        const assetId = (<number>comments[prop]);

        Object.entries(this.ByTaskId).forEach(([key, value]) => {
          value.some(id => {
            if (id === assetId) {
              value.splice(value.indexOf(assetId), 1);
            }
            return id === assetId;
          });
        });
      }
    }
    return this;
  }

  getResetState(ctx: StateContext<CollectionStateBase>, action: Reset): any {
    this.ByTaskId = {};
    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskCommentsDataService extends CollectionDataServiceBase<TaskComment> {
  constructor(protected store: Store) {
    super(store, TaskCommentsState);
  }

  @Selector([TaskCommentsState])
  static getByTaskIdFn$(state: TaskCommentsState) {
    return (taskId: number) => {
      if (state.ByTaskId === undefined
        || !(taskId in state.ByTaskId)) {
        return undefined;
      }
      const comments = [];
      for (const prop in state.ByTaskId[taskId]) {
        if (state.ByTaskId[taskId][prop]
          && typeof (state.ByTaskId[taskId][prop]) !== 'function') {
          const comment = state.items[(state.ByTaskId[taskId][prop])];
          if (comment !== undefined) {
            comments.push(comment);
          }
        }
      }
      return comments;
    };
  }

  getItemsByTaskId$(taskId: number): Observable<TaskComment[]> {
    return this.store.select(TaskCommentsDataService.getByTaskIdFn$).pipe(map(getByTaskIdFn => getByTaskIdFn(taskId)));
  }
}
