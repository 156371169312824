import { ChangeDetector } from '@common/prosemirror/changeset/change-detector';
import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { DeletedSpan, Span } from '@common/prosemirror/changeset/changeset';
import { TrackedChange } from '@common/prosemirror/changeset/tracked-change.type';
import { copySliceWithChanges, copySliceWithoutChanges, getNodeChange, nodeHasChange } from '@common/prosemirror/changeset/tracked-changes';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { depthAtStartOfFragment, depthInLastChildOfFragment, getFirstLeafNodeInFragment, getNodeInfoFromFragmentAtPos } from '@common/prosemirror/model/fragment';
import { copyAndFillNode } from '@common/prosemirror/model/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { predicate } from '@common/util/predicate';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { Fragment, ResolvedPos, Slice } from 'prosemirror-model';
import { AllSelection, EditorState, NodeSelection, Selection, TextSelection, Transaction } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform';
import { v4 as uuidv4 } from 'uuid';

// Dumps a change to the console
function dumpChange(tr: Transaction, changeDetector: ChangeDetector, oldState: EditorState, newState: EditorState) {
  const changeDetectorPropertyNames = [
    'isMultiDepthReplaceJoin',
    'isReplaceJoin',
    'isReplace',
    'isNodeReplace',
    'isBlockTypeChange',
    'isUnwrap',
    'isLift',
    'isInlineLift',
    'isBackwardsLift',
    'isMiddleListItemLift',
    'isMiddleSubListItemLift',
    'isLastListItemLift',
    'isLastSubListItemLift',
    'isAllListItemsLift',
    'isListItemSink',
    'isListItemSinkJoin',
    'isDefinitionListExit',
    'isSplitText',
    'isSplit',
    'isWrap',
    'isWrapAndReplace'
  ];

  const dump = {
    detected: JSON.stringify(changeDetectorPropertyNames.map(propertyName => {
      return {
        name: propertyName,
        detected: changeDetector[propertyName]
      };
    }).filter(detection => detection.detected).map(detection => detection.name)),
    steps: Array.isArray(tr.steps) ? tr.steps.reduce((out, step) => {
      return out + ' -> ' + (step as any).jsonID + '[' + step.from + ',' + step.to + ']';
    }, '') : null,
    change: '[-' + changeDetector.deleted.length + '+' + changeDetector.inserted.length + ']',
    deleted: changeDetector.deleted.map(span => {
      return {
        from: span.from,
        to: span.to,
        pos: span.pos,
        isJoin: span.isJoin,
        isEmptyJoin: span.isEmptyJoin,
        isDeletingEntireFromNode: span.isDeletingEntireFromNode,
        isDeletingEntireToNode: span.isDeletingEntireToNode,
        isPlaceholder: span.isPlaceholder,
        sliceJSON: span.slice.toJSON(),
        sliceString: span.slice.toString()
      };
    }),
    inserted: changeDetector.inserted.map(span => {
      const slice = newState.doc.slice(span.from, span.to);
      return {
        from: span.from,
        to: span.to,
        isJoin: span.isJoin,
        isEmptyJoin: span.isEmptyJoin,
        isDeletingEntireFromNode: span.isDeletingEntireFromNode,
        isDeletingEntireToNode: span.isDeletingEntireToNode,
        isPlaceholder: span.isPlaceholder,
        sliceJSON: slice.toJSON(),
        sliceString: slice.toString()
      };
    })
  };

  console.log('DUMP [-' + changeDetector.deleted.length + '+' + changeDetector.inserted.length + ']', changeDetector, dump);
}

interface ChangeMetaData {
  content?: string;
  id?: string;
  lastChange?: dayjs.Dayjs;
  type?: ChangeType;
  timestamp?: ISO8601DateString;
}

/*
 * ChangeTrackerMetaData
 * Keeps track of the meta data for tracked changes
 */
export class ChangeTrackerMetaData {
  metaData: ChangeMetaData = {};

  constructor(public msBetweenChangesBeingDifferent: number = 3000) { }

  next(type: ChangeType, changeContent?: string): ChangeMetaData {
    // If this is a different type of change OR an attribute change OR enough time has passed since the last change
    if (type !== this.metaData.type || type === ChangeType.Attributes || changeContent !== this.metaData.content || dayjs().diff(this.metaData.lastChange) > this.msBetweenChangesBeingDifferent) {
      // Create new meta data generating a new change id and timestamp
      this.metaData = {
        content: changeContent,
        id: uuidv4(),
        timestamp: dayjs().toISOString(),
        type
      };
    }

    // Update the last time the doc was changed to now
    this.metaData.lastChange = dayjs();

    return this.metaData;
  }

  current(): ChangeMetaData {
    return this.metaData;
  }

  restore(metaData: ChangeMetaData) {
    this.metaData = metaData;
  }
}

interface ApplyTrackedChangeOptions {
  $oldFrom?: ResolvedPos;
  $oldTo?: ResolvedPos;
  attrs?: object;
  changeAttribute?: string;
  changeContent?: string;
  changeType?: ChangeType;
  changeValue?: string;
  newRange?: {
    from: number;
    to: number;
  };
  slice?: Slice;
}

interface AppliedTrackedChange {
  type: ChangeType;
  change: any;
  slice: Slice;
  from: number;
  to: number;
}

export interface ChangeTrackerUser {
  Id: string;
  Initials: string;
  UserName: string;
}

export type GetChangeTrackerUser = () => ChangeTrackerUser;

/*
 * ChangeTracker
 */
export class ChangeTracker {
  static metaData: ChangeTrackerMetaData = new ChangeTrackerMetaData();

  transaction: Transaction;
  oldState: EditorState;
  newState: EditorState;
  schema: CollabSchema;
  user: ChangeTrackerUser | GetChangeTrackerUser;
  changeDetector: ChangeDetector;

  constructor(transaction: Transaction, oldState: EditorState, newState: EditorState, user: ChangeTrackerUser | GetChangeTrackerUser) {
    this.transaction = transaction;
    this.oldState = oldState;
    this.newState = newState;
    this.schema = this.oldState.schema as CollabSchema;
    this.user = user;

    // Create a change detector
    this.changeDetector = new ChangeDetector(this.oldState, this.newState, this.transaction);

    // if (typeof window !== 'undefined') {
    //   dumpChange(transaction, this.changeDetector, oldState, newState);
    // }
  }

  getUser(): ChangeTrackerUser {
    if (typeof this.user === 'function') {
      return this.user();
    } else {
      return this.user;
    }
  }

  /*
   * applyTrackedChange
   *
   * options: {
   *   newRange: where the tracked change is being inserted into the new document
   *   slice: the content that the tracked change is being applied to
   * }
   */
  applyTrackedChange(tr: Transaction, options: ApplyTrackedChangeOptions): AppliedTrackedChange {
    // Keep a copy of the current change meta data. It will be restored if no change actually occurs
    const currentChangeMetaData = ChangeTracker.metaData.current();
    const user = this.getUser();

    // Build the attrs for the change
    const metaData = ChangeTracker.metaData.next(options.changeType, options.changeContent);
    const change: TrackedChange = {
      id: metaData.id,
      userName: user ? user.UserName : '',
      initials: user ? user.Initials : '',
      creatorCentralUserId: user ? user.Id : '',
      timestamp: metaData.timestamp,
      changeType: options.changeType,
      changeContent: options.changeContent,
      attribute: options.changeAttribute,
      value: options.changeValue
    };
    const attrs: ChangeNodeAttrs = {
      changeIds: change.id,
      changeList: [change]
    };

    // Create a new slice with the tracked change on it
    const newSlice = copySliceWithChanges(this.schema, options.slice, attrs);

    // Map the slice's position into the new state of the transaction
    const from = tr.mapping.map(options.newRange.from);
    const to = tr.mapping.map(options.newRange.to);

    // If the slice is a node
    if (newSlice.openStart === 0 && newSlice.openEnd === 0 && newSlice.content.childCount === 1) {
      tr.replaceRangeWith(from, to, newSlice.content.firstChild);
    } else {
      tr.replaceRange(from, to, newSlice);
    }

    // If the transaction did not actually make a change to the document
    if (!tr.docChanged) {
      // Then restore the previous change meta data since we aren't in a new set of changes
      ChangeTracker.metaData.restore(currentChangeMetaData);
    }

    return {
      type: options.changeType,
      change: change,
      slice: newSlice,
      from: from,
      to: to
    };
  }

  /*
   * Block Type Change
   * Handles cases like a paragraph being changed into a header
   */
  applyChangeToBlockTypeChange(tr: Transaction) {
    let selectionAppliedTrackedChange: AppliedTrackedChange;
    let newSliceAppliedTrackedChange: AppliedTrackedChange;

    // Go through each step and create a remove/add tracked change for each ReplaceAroundStep
    if (Array.isArray(this.transaction.steps)) {
      this.transaction.steps.forEach(step => {
        if (step instanceof ReplaceAroundStep) {
          const oldSlice = this.oldState.doc.slice(step.from, step.to);
          const isPlaceholder = this.changeDetector.deleted.some(span => span.from === step.from && span.to === step.to && span.isPlaceholder);

          // Create a tracked removal for the content being replaced
          const appliedTrackedChange = this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: { from: step.from, to: step.from },
            slice: isPlaceholder ? Slice.empty : oldSlice // Don't track empty deleted mcCentralContainers
          });

          // If the old selection is within this step's slice then store info about it for setting the new selection
          if (this.oldState.selection.from > step.from && this.oldState.selection.from < step.to) {
            selectionAppliedTrackedChange = appliedTrackedChange;
          }

          // Create a tracked addition for the content doing the replacing
          newSliceAppliedTrackedChange = this.applyTrackedChange(tr, {
            changeType: ChangeType.Add,
            newRange: step,
            slice: this.newState.doc.slice(step.from, step.to)
          });
        }
      });
    }

    // Update the selection
    if (newSliceAppliedTrackedChange && selectionAppliedTrackedChange) {
      // If the applied slice is empty then the selection should stay the same
      if (selectionAppliedTrackedChange.slice.size === 0) {
        tr.setSelection(TextSelection.create(tr.doc, this.oldState.selection.from));
        // Else a new slice was added so set the selection to be in the new slice
      } else {
        tr.setSelection(TextSelection.create(tr.doc, this.oldState.selection.from + newSliceAppliedTrackedChange.slice.content.size));
      }
    }
  }

  /*
   * Lift
   * Handles cases like leaving a list by hitting enter in the last list item when it is empty
   */
  applyChangeToLift(tr: Transaction) {
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const lastDelSpan = this.changeDetector.lastDeletedSpan;

    // Take a slice from the start of the first deleted span to the start of the second deleted span (the content being lifted and its old container)
    const removeFrom = firstDelSpan.from;
    const removeTo = lastDelSpan.to - lastDelSpan.toDepth;
    const removeSlice = this.oldState.doc.slice(removeFrom, removeTo);
    // Insert the slice over the deleted range
    const newRemoveRange = { from: firstDelSpan.from, to: lastDelSpan.from };

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: newRemoveRange,
      slice: removeSlice
    });

    // Take a slice from between the two deleted spans (the lifted content)
    const addedFrom = firstDelSpan.to;
    const addedTo = lastDelSpan.from;
    const addedSlice = this.oldState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      // Insert the new slice directly after the tracked remove slice
      newRange: { from: newRemoveRange.to, to: newRemoveRange.to + 1 },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove)
    });

    // Update the selection
    this.applyListItemSelection(tr, addedFrom, 0, removeFrom + 1);
  }

  /*
   * Backwards Lift
   * Handles cases like hitting backspace at the beginning of a list
   */
  applyChangeToBackwardsLift(tr: Transaction) {
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const lastDelSpan = this.changeDetector.lastDeletedSpan;
    const insSpan = this.changeDetector.firstInsertedSpan;

    const removedFrom = firstDelSpan.from + 1;
    const removedTo = lastDelSpan.to;
    const removedSlice = this.oldState.doc.slice(removedFrom, removedTo);

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      // Insert the slice just after the new node boundary
      newRange: { from: insSpan.from + 1, to: insSpan.from + 1 },
      // Take a slice from the content start of the first deleted span to the start of the second deleted span (the content being lifted)
      slice: removedSlice
    });

    const addedFrom = firstDelSpan.from;
    const addedTo = insSpan.from;
    const addedSlice = this.newState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addedFrom, to: addedTo },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    // Grab the depth count of the middle slices that were removed because it is equal to the inner size reduction of the lifted items
    let middleDepthCount = 0;
    for (let i = 1; i < this.changeDetector.deleted.length - 1; i += 1) {
      middleDepthCount += this.changeDetector.deleted[i].slice.openStart + this.changeDetector.deleted[i].slice.openEnd;
    }

    // Update the selection
    this.applyListItemSelection(tr, addedFrom - 1, middleDepthCount, removedFrom);
  }

  /*
   * Middle (Sub) List Item Lift
   * Handles outdenting the middle sub list item(s) of a list
   */
  applyChangeToMiddleListItemLift(tr: Transaction) {
    const firstInsSpan = this.changeDetector.firstInsertedSpan;
    const $liftStart = this.oldState.doc.resolve(firstInsSpan.to);

    // Find the parent list
    const listResolvedPosInfo = resolvedPosFind($liftStart, node => node.type.spec.list);

    // Grab the resolved position of the parent list to then get the end position of the list for grabbing the removed slice
    const $listPos = listResolvedPosInfo.$pos.doc.resolve(listResolvedPosInfo.$pos.start(listResolvedPosInfo.depth));
    const removedSlice = this.oldState.doc.slice(firstInsSpan.from, $listPos.end() + 1);

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: firstInsSpan.from, to: firstInsSpan.from },
      slice: removedSlice
    });

    const lastInsSpan = this.changeDetector.lastInsertedSpan;
    let addedFrom: number;
    let addedTo: number;
    let posAtStartOfAddedSlice: number;
    let startOfAddedSliceOffset: number;

    // If this is a top level item being lifted
    if (firstInsSpan.slice.openStart === 1) {
      // firstInsSpan is the list being closed above the item
      // lastInsSpan is the new list being opened below the item
      const $newEnd = this.newState.doc.resolve(lastInsSpan.to);
      addedFrom = firstInsSpan.to;
      addedTo = $newEnd.end() + 2;
      posAtStartOfAddedSlice = addedFrom;
    } else {
      // Crawl through the newly added nodes in order to find their range for the added slice
      const oldSlice = this.oldState.doc.slice(firstInsSpan.from, lastInsSpan.from - 1);
      const liftedNodeCount = oldSlice.content.childCount;

      const liftedNodeStartPos = firstInsSpan.to;
      let liftedNodeEndPos = liftedNodeStartPos;

      for (let i = 0; i < liftedNodeCount; i += 1) {
        const liftedNode = this.newState.doc.nodeAt(liftedNodeEndPos);
        liftedNodeEndPos += liftedNode.nodeSize;
      }

      addedFrom = liftedNodeStartPos;
      addedTo = liftedNodeEndPos;
      posAtStartOfAddedSlice = addedFrom;
      // Offset the slice for text selections
      startOfAddedSliceOffset = this.oldState.selection instanceof NodeSelection ? 0 : 2;
    }

    const addedSlice = this.newState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addedFrom, to: addedTo },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    // Grab the depth count of the middle slices that were removed because it is equal to the inner size reduction of the lifted items
    let middleDepthCount = 0;
    for (let i = 1; i < this.changeDetector.deleted.length - 1; i += 1) {
      middleDepthCount += this.changeDetector.deleted[i].slice.openStart + this.changeDetector.deleted[i].slice.openEnd;
    }

    // Update the selection
    this.applyListItemSelection(tr, posAtStartOfAddedSlice, middleDepthCount, $liftStart.pos, startOfAddedSliceOffset);
  }

  /*
   * Last List Item Lift
   * Handles outdenting the last list items of a list
   */
  applyChangeToLastListItemLift(tr: Transaction) {
    const firstInsSpan = this.changeDetector.firstInsertedSpan;
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const lastDelSpan = this.changeDetector.lastDeletedSpan;
    const removedSlice = this.oldState.doc.slice(firstDelSpan.from, lastDelSpan.from + 1);

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: firstDelSpan.from, to: firstDelSpan.from },
      slice: removedSlice
    });

    // Crawl through the newly added nodes in order to find their range for the added slice
    let liftedNodeCount = this.changeDetector.deleted.length - 1;
    const liftedNodeStartPos = firstInsSpan.to;
    let liftedNodeEndPos = liftedNodeStartPos;

    for (let i = 0; i < liftedNodeCount; i += 1) {
      const liftedNode = this.newState.doc.nodeAt(liftedNodeEndPos);
      liftedNodeEndPos += liftedNode.nodeSize;

      // If a list is encountered that means it was a sub list and is now a top level list
      if (liftedNode.type.spec.list) {
        // Since a new list was encountered then it is another lifted node that could not be foreseen
        liftedNodeCount += 1;
      }
    }

    const addedSlice = this.newState.doc.slice(liftedNodeStartPos, liftedNodeEndPos);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: liftedNodeStartPos, to: liftedNodeEndPos },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    // Grab the depth count of the middle slices that were removed because it is equal to the inner size reduction of the lifted items
    let middleDepthCount = 0;
    for (let i = 1; i < this.changeDetector.deleted.length - 1; i += 1) {
      middleDepthCount += this.changeDetector.deleted[i].slice.openStart + this.changeDetector.deleted[i].slice.openEnd;
    }

    // Update the selection
    this.applyListItemSelection(tr, liftedNodeStartPos, middleDepthCount, firstDelSpan.to);
  }

  /*
   * Last Sub List Item Lift
   * Handles outdenting the last sub list item(s) of a list
   */
  applyChangeToLastSubListItemLift(tr: Transaction) {
    const firstInsSpan = this.changeDetector.firstInsertedSpan;
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const removedSlice = this.oldState.doc.slice(firstInsSpan.from, firstDelSpan.from);

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: firstInsSpan.from, to: firstInsSpan.from },
      slice: removedSlice
    });

    const addedSlice = this.newState.doc.slice(firstInsSpan.to, firstDelSpan.to);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: firstInsSpan.to, to: firstDelSpan.to },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    // Update the selection
    this.applyListItemSelection(tr, firstInsSpan.to);
  }

  /*
   * All List Items Lift
   * Handles outdenting an entire top level list
   */
  applyChangeToAllListItemsLift(tr: Transaction) {
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const lastDelSpan = this.changeDetector.lastDeletedSpan;

    // Grab the depth count because it is equal to the size reduction of the lifted list
    let depthCount = 0;
    for (let i = 0; i < this.changeDetector.deleted.length; i += 1) {
      depthCount += this.changeDetector.deleted[i].slice.openStart + this.changeDetector.deleted[i].slice.openEnd;
    }

    const addedFrom = firstDelSpan.from;
    const addedTo = lastDelSpan.to - depthCount;
    const addedSlice = this.newState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addedFrom, to: addedTo },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    const removedSlice = this.oldState.doc.slice(firstDelSpan.from, lastDelSpan.to);
    const removePos = lastDelSpan.to - depthCount;

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: removePos, to: removePos },
      slice: removedSlice
    });

    // Update selection
    const startAndEndDepthCount = firstDelSpan.slice.openEnd + lastDelSpan.slice.openStart;
    this.applyListItemSelection(tr, addedFrom - 1, depthCount - startAndEndDepthCount);
  }

  /*
   * applyChangeToListItemSink
   * Handles indenting list items
   */
  applyChangeToListItemSink(tr: Transaction) {
    const firstInsSpan = this.changeDetector.firstInsertedSpan;
    const lastInsSpan = this.changeDetector.lastInsertedSpan;
    const firstDelSpan = this.changeDetector.firstDeletedSpan;

    // Figure out if this is the last item in the list being sunk which will be used for grabbing the added/removed slices
    const $oldListItemPos = this.oldState.doc.resolve(firstDelSpan.to + 1 - firstDelSpan.slice.openEnd);
    const lastItemOfTheList = $oldListItemPos.index(-1) === $oldListItemPos.node(-1).childCount - 1;

    const addedFrom = firstInsSpan.from;
    const addedTo = lastInsSpan.to;
    const addedSlice = this.newState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addedFrom, to: addedTo },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    const removedFrom = firstDelSpan.to - firstDelSpan.slice.openEnd;
    let removedTo = lastInsSpan.from;

    // If this is a deep sink or the last item of the list
    if (firstInsSpan.slice.openEnd > 1 || lastItemOfTheList) {
      removedTo += 1;
    }

    // Subtract the inner slices that wrapped inner nodes
    removedTo -= (2 * (this.changeDetector.inserted.length - 2));

    let removedSlice = this.oldState.doc.slice(removedFrom, removedTo);
    const removePos = lastInsSpan.to; // Insert this slice right after the added slice

    // This is a real kludge to work around an issue where an indent and a wrap look identical for some cases yet the transactions have a different 'to' position for lastInsSpan.
    // This results in one case having a slice that correctly has no open end and the other case with an open end.
    // The content in the slice is correct for both cases but the openEnd differs.
    // The openEnd and openStart properties shouldn't be set directly so create a new slice.
    removedSlice = new Slice(removedSlice.content, removedSlice.openStart, 0);

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: removePos, to: removePos },
      slice: removedSlice
    });

    // Update selection
    this.applyListItemSelection(tr, addedFrom, 0, addedFrom + 1, 1);
  }

  /*
   * applyChangeToListItemSinkJoin
   * Handles indenting list items that are the first items after a sub list
   */
  applyChangeToListItemSinkJoin(tr: Transaction) {
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const firstInsSpan = this.changeDetector.lastInsertedSpan;

    const addedFrom = firstDelSpan.from;
    const addedTo = firstInsSpan.from;
    const addedSlice = this.newState.doc.slice(addedFrom, addedTo);

    // Create a tracked addition for the content being added
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addedFrom, to: addedTo },
      slice: copySliceWithoutChanges(this.schema, addedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
    });

    const removedFrom = firstDelSpan.to;
    const removedTo = firstInsSpan.to;
    const removedSlice = this.oldState.doc.slice(removedFrom, removedTo);
    const removePos = firstInsSpan.to; // Insert this slice right after the added slice

    // Create a tracked removal for the content being removed
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: removePos, to: removePos },
      slice: removedSlice
    });

    // Update selection
    this.applyListItemSelection(tr, addedFrom, 0, removedFrom);
  }

  /**
   * applyChangeToNodeExit
   * Handles exiting a definition list or dropdown body.
   * For example, hitting enter from the last empty dt item in a dl or hitting enter from the last node in a dropdown body when its empty.
   * @param tr The transaction to apply the changes to.
   */
  applyChangeToNodeExit(tr: Transaction) {
    const firstDelSpan = this.changeDetector.firstDeletedSpan;
    const firstInsSpan = this.changeDetector.lastInsertedSpan;

    // Create a tracked add for the new content that was created after the definition list
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: firstInsSpan,
      slice: firstInsSpan.slice
    });

    // Set the selection in the new content
    tr.setSelection(Selection.near(tr.doc.resolve(firstInsSpan.from + 1)));

    // Create a tracked removal for the item that was removed during the exit
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: {
        from: firstDelSpan.from,
        to: firstDelSpan.from
      },
      slice: firstDelSpan.slice
    });
  }

  /*
   * applyChangeToCodeSnippetBody
   * Handles a change to a code snippet body
   */
  applyChangeToCodeSnippetBody(tr: Transaction, range: { from: number, to: number }) {
    const $bodyStartPos: ResolvedPos = this.newState.doc.resolve(range.from);
    if ($bodyStartPos.parent.attrs['MadCap:changes']) {
      return;
    }

    const oldSnippetBody = this.oldState.doc.resolve(range.from).parent;
    const codeSnippetStart = $bodyStartPos.before();
    const newCodeSnippetEnd = $bodyStartPos.after();
    const newSlice = this.newState.doc.slice(codeSnippetStart, newCodeSnippetEnd);
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Replace,
      changeContent: this.schema.nodeToCode(oldSnippetBody),
      newRange: {
        from: codeSnippetStart,
        to: newCodeSnippetEnd
      },
      slice: newSlice
    });
  }

  /*
   * SplitText
   * Handles cases like hitting enter inside of a text node
   * Used on text that is inside of an mcCentralContainer tag
   */
  applyChangeToSplitText(tr: Transaction) {
    const replaceStep = this.transaction.steps.find(step => step instanceof ReplaceStep);

    // Make slices of the changed content
    const removeFrom = this.oldState.doc.resolve(replaceStep.from).before();
    const removeTo = this.oldState.doc.resolve(replaceStep.to).after();
    const removeSlice = this.oldState.doc.slice(removeFrom, removeTo);

    // Create a tracked removal for the content being removed
    if (removeSlice !== Slice.empty) {
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Remove,
        newRange: { from: removeFrom, to: removeFrom },
        slice: removeSlice
      });
    }

    // Create a tracked addition for the content being added
    this.transaction.steps.forEach(step => {
      if (step instanceof ReplaceAroundStep) {
        this.applyTrackedChange(tr, {
          changeType: ChangeType.Add,
          newRange: step,
          slice: this.newState.doc.slice(step.from, step.to)
        });
      }
    });

    // Update the selection
    tr.setSelection(Selection.findFrom(tr.doc.resolve(this.newState.selection.to + removeSlice.size), 1, true));
  }

  /*
   * Split
   * Handles cases like hitting enter inside of a node
   */
  applyChangeToSplit(tr: Transaction) {
    const delSpan = this.changeDetector.firstDeletedSpan;
    const insSpan = this.changeDetector.firstInsertedSpan;

    // Make slices of the changed content
    const $pos = this.oldState.doc.resolve(insSpan.from);
    let removedSlice = this.oldState.doc.slice(insSpan.from, $pos.end()); // The removed slice is the position at the split to the end of the node that was split.
    let removedRange = { from: insSpan.from, to: insSpan.from }; // Insert the slice in the same place the split was performed

    // The moved slice is everything after the selection if there was one. Otherwise its everything after the split position.
    let movedSlice = this.oldState.doc.slice(delSpan ? delSpan.to : insSpan.from, $pos.end());
    let movedRange;
    let selPos: number;
    let isDeepSplit = false;

    // If the moved slice is empty then we have no content to work with so use the new slice instead
    if (movedSlice === Slice.empty) {
      const newNodeFromSplit = this.newState.doc.nodeAt(insSpan.to - insSpan.slice.openEnd);
      const sizeOfEmptyNodeFromSplit = insSpan.slice.openEnd * 2;

      // If the new node after the split has content then this is a deep split and needs to be treated differently
      if (newNodeFromSplit.nodeSize > sizeOfEmptyNodeFromSplit) {
        isDeepSplit = true;
        // The moved slice includes the entire new node after the split
        movedSlice = new Slice(Fragment.from(newNodeFromSplit), 0, 0);
        // The tracked moved slice needs to replace the entire new slice
        movedRange = { from: insSpan.to - insSpan.slice.openStart, to: insSpan.to + newNodeFromSplit.nodeSize };
        // The new selected position is at the start of the new slice
        selPos = movedRange.from;

        // The removed slice is everything from the split start to the end of the node that was split (but not including the split node itself)
        removedSlice = this.oldState.doc.slice(insSpan.from + (insSpan.slice.openStart - 1), $pos.end(-(insSpan.slice.openStart - 1)));
        // The tracked remove slice needs to be inserted just before the tracked add
        removedRange = { from: insSpan.from, to: insSpan.from };
        // removedRange = { from: insSpan.from, to: insSpan.to - insSpan.slice.openEnd };
      } else {
        // Use only the last child (the second child) from the new slice
        movedSlice = new Slice(Fragment.from(insSpan.slice.content.lastChild), 0, 0);
        movedRange = { from: insSpan.to, to: insSpan.to };
        selPos = movedRange.from - insSpan.toDepth - 1;
      }
      // Else create a new slice that wraps the moved content in a new node from the split
    } else {
      movedSlice = new Slice(Fragment.from(copyAndFillNode(insSpan.slice.content.lastChild, movedSlice.content)), 0, 0);
      movedRange = { from: insSpan.from, to: insSpan.from + insSpan.slice.openEnd + movedSlice.content.size }; // Insert the slice over the inserted span and its new content

      // The new selection is the location of the removed slice + the size of the removed slice + the depth of the block node being split + the depth into the new block node
      selPos = removedRange.from + removedSlice.size + insSpan.slice.openEnd + depthAtStartOfFragment(movedSlice.content) - 1;
    }

    // If this is a deep split then insert the tracked add first
    if (isDeepSplit) {
      // Create a tracked addition for the content being added
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Add,
        newRange: movedRange,
        slice: copySliceWithoutChanges(this.schema, movedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
      });

      // Set the selection now since we know the position but won't after the tracked removal is inserted
      tr.setSelection(Selection.near(tr.doc.resolve(selPos)));
    }

    // Create a tracked removal for the content being removed
    if (removedSlice !== Slice.empty) {
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Remove,
        newRange: removedRange,
        slice: removedSlice
      });
    }

    // If this is not a deep split then insert the tracked add last
    if (!isDeepSplit) {
      // Create a tracked addition for the content being added
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Add,
        newRange: movedRange,
        slice: copySliceWithoutChanges(this.schema, movedSlice, ChangeType.Remove) // Do not include removed changes in the new slice
      });

      // Update the selection
      tr.setSelection(Selection.findFrom(tr.doc.resolve(selPos), 1, true));
    }
  }

  /*
   * Wrap
   * Handles cases like making text bold or creating a list (without changing the wrapped node's type)
   */
  applyChangeToWrap(tr: Transaction) {
    const inserted = this.changeDetector.inserted;

    let containsAddRemoveChanges = false;
    let lastAppliedTrackedChange: AppliedTrackedChange;
    let startSpanIndex: number;
    let endSpanIndex: number;
    let startSpan: Span;
    let endSpan: Span;

    // Loop through the inserted spans and find the groups of bound spans to place the bind around them
    for (let i = 0; i < inserted.length; i += 1) {
      const span = inserted[i];

      // If there isn't a start span yet then make this span the start span
      if (!startSpan) {
        startSpan = span;
        startSpanIndex = i;
        // Else if this span's depth matches the start span's depth then we have an end span
      } else if (span.toDepth === startSpan.fromDepth) {
        endSpan = span;
        endSpanIndex = i;
      }

      // If there is a start span and an end span then create a tracked bind change around them
      if (startSpan && endSpan) {
        // If this is a deep wrap (more than one node is being wrapped around the content) THEN treat this like an add/remove change
        if (startSpan.fromDepth > 0) {
          // Account for the added nodes when calculating the end of the slice to grab from the old state
          // added nodes = number of nodes deep + all their children
          // Multiply by 2 to account for the nodes taking up two spots (one for start of the node and one for end of the node)
          const endOffset = 2 * (1 + endSpanIndex - startSpanIndex);

          // Create a tracked removal for the content being wrapped
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: { from: startSpan.from, to: startSpan.from },
            slice: this.oldState.doc.slice(startSpan.from, endSpan.to - endOffset)
          });

          // Create a tracked add for the new content that is now wrapped
          lastAppliedTrackedChange = this.applyTrackedChange(tr, {
            changeType: ChangeType.Add,
            newRange: { from: startSpan.from, to: endSpan.to }, // Replace the entirety of the newly wrapped content
            slice: this.newState.doc.slice(startSpan.from, endSpan.to) // The added slice is the newly wrapped content
          });

          containsAddRemoveChanges = true;
          // Else this is a shallow wrap (text being wrapped) so treat this like a bind change
        } else {
          // Create a tracked bind change
          lastAppliedTrackedChange = this.applyTrackedChange(tr, {
            changeType: ChangeType.Bind,
            newRange: { from: startSpan.from, to: endSpan.to },
            slice: this.newState.doc.slice(startSpan.from, endSpan.to)
          });
        }

        // Clear out the spans now that they have been used
        startSpan = null;
        endSpan = null;
        startSpanIndex = null;
        endSpanIndex = null;
      }
    }

    // Update the selection
    if (containsAddRemoveChanges) {
      tr.setSelection(TextSelection.between(tr.doc.resolve(lastAppliedTrackedChange.from), tr.doc.resolve(lastAppliedTrackedChange.to)));
    } else {
      const newStateSelection = this.newState.selection;
      tr.setSelection(TextSelection.create(tr.doc, newStateSelection.anchor, newStateSelection.head));
    }
  }

  /*
   * WrapAndReplace
   * Handles cases like creating a list.
   */
  applyChangeToWrapAndReplace(tr: Transaction) {
    // Grab the first set of ReplaceAround steps which are all at the start of the steps. This represents the steps that do the actual list wrapping
    const replaceAroundStepsForWrap = this.transaction.steps.slice(0, this.transaction.steps.findIndex(step => step instanceof ReplaceStep));

    // Find the replace around step that occurs earliest in the steps. This is the start of the wrap
    const firstReplaceAroundStep = replaceAroundStepsForWrap.reduce((firstStep, step) => {
      if (step instanceof ReplaceAroundStep && (!firstStep || step.from < firstStep.from)) {
        return step;
      } else {
        return firstStep;
      }
    }, null);

    // Find the replace around step that occurs last in the steps. This is the end of the wrap
    const lastReplaceAroundStep = replaceAroundStepsForWrap.reduce((lastStep, step) => {
      if (step instanceof ReplaceAroundStep && (!lastStep || step.to > lastStep.to)) {
        return step;
      } else {
        return lastStep;
      }
    }, null);

    // Create a tracked removal for the content that was wrapped
    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: firstReplaceAroundStep.from, to: firstReplaceAroundStep.from },
      slice: this.oldState.doc.slice(firstReplaceAroundStep.from, lastReplaceAroundStep.to)
    });

    const firstInsSpan = this.changeDetector.firstInsertedSpan;
    const lastInsSpan = this.changeDetector.lastInsertedSpan;

    // Create a tracked add for the new content that is now wrapped
    const lastAppliedTrackedChange = this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: firstInsSpan.from, to: lastInsSpan.to }, // Replace the entirety of the newly wrapped content
      slice: this.newState.doc.slice(firstInsSpan.from, lastInsSpan.to) // The added slice is the newly wrapped content
    });

    // Simply select the entire new list. This might not be the best course of action but its simple
    tr.setSelection(TextSelection.between(tr.doc.resolve(lastAppliedTrackedChange.from), tr.doc.resolve(lastAppliedTrackedChange.to)));
  }

  /*
   * Unwrap
   * Handles cases like unbolding text or deleting the only item in a list
   */
  applyChangeToUnwrap(tr: Transaction) {
    for (let i = 0; i < this.changeDetector.spans.length; i += 2) {
      const firstSpan = this.changeDetector.spans[i];
      const lastSpan = this.changeDetector.spans[i + 1];

      // Determine the from/to positions based on the span type
      const from = firstSpan.isDelete ? (firstSpan as DeletedSpan).pos : firstSpan.to;
      const to = lastSpan.isDelete ? (lastSpan as DeletedSpan).pos : lastSpan.from;

      // If the unwrap is only one node deep then treat it like an unbind change
      if (firstSpan.fromDepth === 0) {
        const unboundNode = copySliceWithoutChanges(this.schema, firstSpan.slice).content.firstChild;
        if (!unboundNode) {
          // If there is no node then the content that was unwrapped was a change so do not add a new tracked change
          continue;
        }

        const unboundNodeXml = this.schema.nodeToCode(unboundNode);

        // Apply an unbind change
        this.applyTrackedChange(tr, {
          changeType: ChangeType.Unbind,
          changeContent: unboundNodeXml,
          newRange: { from: from, to: to },
          slice: this.newState.doc.slice(from, to)
        });
        // Else the lift is more than one node deep so treat it like a delete and add (Flare cannot handle unbind changes with more than one node level)
        // Handles deleting the only item in a list or unbolding text that is also underlined or italicized
      } else {
        // If both spans are a delete (like a list being unwrapped)
        if (firstSpan.isDelete && lastSpan.isDelete) {
          // Create a tracked addition for the content being lifted
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Add,
            newRange: { from: from, to: to }, // Insert the slice over the lifted content
            slice: this.newState.doc.slice(from, to)
          });

          // Create a tracked removal for the content being lifted and the nodes it is being lifted from
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: { from: to, to: to }, // Insert the slice after the added change
            slice: this.oldState.doc.slice(firstSpan.from, lastSpan.from - 1)
          });
          // Handles cases like unbolding text
        } else {
          const addedRangeTo = lastSpan.from + lastSpan.slice.openStart;

          // Create a tracked addition for the content being lifted
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Add,
            newRange: { from: firstSpan.to - firstSpan.slice.openEnd, to: addedRangeTo }, // Insert the slice over the lifted content
            slice: this.newState.doc.slice(firstSpan.to - firstSpan.slice.openEnd, lastSpan.from + lastSpan.slice.openStart)
          });

          // The removed content is reconstructed from the added content's first node (which closes the content that is before the unwrapped content)
          const innerContent = this.oldState.doc.slice(firstSpan.from, lastSpan.from - firstSpan.rangeSize).content;
          const removedContent = Fragment.from(copyAndFillNode(firstSpan.fromNode, innerContent));

          // Create a tracked removal for the content being lifted and the nodes it is being lifted from
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: { from: addedRangeTo, to: addedRangeTo }, // Insert the slice after the added change
            slice: new Slice(removedContent, 0, 0)
          });
        }


        //  		// Create a tracked addition for the content being lifted
        // this.applyTrackedChange(tr, {
        // 	changeType: 'add',
        // 	newRange: { from: from, to: to }, // Insert the slice over the lifted content
        // 	slice: this.newState.doc.slice(from, to)
        // });

        //  		// Create a tracked removal for the content being lifted and the nodes it is being lifted from
        //  		this.applyTrackedChange(tr, {
        // 	changeType: 'remove',
        // 	// newRange: { from: to, to: to }, // Insert the slice after the added change
        // 	// slice: this.oldState.doc.slice(firstSpan.from, lastSpan.to) // Take a slice from the start of the first span to the end of the second span
        // 	// newRange: { from: to + firstSpan.rangeSize, to: to + firstSpan.rangeSize }, // Insert the slice after the added change
        //  // Take a slice from the start of the first span to the end of the second span
        //  // BUT subtract away the range size of the first span because the positions were shifted that much
        // 	// slice: this.oldState.doc.slice(firstSpan.from, lastSpan.from - firstSpan.rangeSize)
        // 	newRange: { from: to, to: to }, // Insert the slice after the added change
        // 	slice: this.oldState.doc.slice(firstSpan.from, lastSpan.from - 1)
        // });
      }
    }
  }

  /*
   * Replace Join
   * Handles cases like joining lists backwards into parent lists or other nodes.
   */
  applyChangeToReplaceJoin(tr: Transaction) {
    const delSpan = this.changeDetector.firstDeletedSpan;
    const insSpan = this.changeDetector.firstInsertedSpan;
    const lastDelSpan = this.changeDetector.lastDeletedSpan;

    // Deleting all the text in a codeSnippetBody and no codeSnippetCaption
    if (delSpan.fromNode.type.name === 'madcapcodesnippetbody' && delSpan.toNode.type.name === 'madcapcodesnippetbody' && delSpan.isDeletingEntireToNode) {
      if (delSpan.fromNode.attrs['MadCap:changes'] !== null) {
        return;
      }
      const bodyTextStart = delSpan.from + 1;
      const bodyTextEnd = delSpan.to - 1;
      this.applyChangeToCodeSnippetBody(tr, { from: bodyTextStart, to: bodyTextEnd });
    } else {
      // Grab the added slice
      let addedSlice = Slice.empty;
      if (this.changeDetector.inserted.length === 1 && this.changeDetector.deleted.length === 1) {
        // This join can occur from a top-level list item being joined with a node just before a list
        addedSlice = this.newState.doc.slice(insSpan.from, insSpan.to - Math.max(0, delSpan.slice.openStart + delSpan.slice.openEnd));
      } else if (this.changeDetector.inserted.length === 1 && this.changeDetector.deleted.length === 2) {
        // This join can occur when replacing text from the middle of a list item to the middle of the last list item in a sub list
        if (insSpan.slice.openStart !== 0 || insSpan.slice.openEnd !== 1) {
          addedSlice = insSpan.slice;
        }
      } else if (this.changeDetector.inserted.length > 1) {
        // The insertion only includes the new stuff added so just use it
        addedSlice = insSpan.slice;
      }

      // Grab the range to insert the removed slice at
      let removedRange;
      if (this.changeDetector.inserted.length === 1 && this.changeDetector.deleted.length === 1) {
        removedRange = { from: insSpan.from, to: insSpan.to };
      } else {
        removedRange = { from: delSpan.from, to: delSpan.from + addedSlice.size };
      }

      // Replace the newly inserted content with a tracked removal of the old content
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Remove,
        newRange: removedRange,
        slice: delSpan.slice
      });

      // If this join has more than one delete AND that delete is not in the last child node of a node being deleted in the join (eg the last item of a sub list being joined with its parent item)
      if (this.changeDetector.deleted.length > 1 && lastDelSpan.slice.openStart < 2) {
        // The join closed the tag here. Open it back up by re-inserting the deleted span
        tr.replaceRange(lastDelSpan.from, lastDelSpan.to + (delSpan.slice.openEnd - delSpan.slice.openStart), lastDelSpan.slice);
      }

      // Update the selection. Put the cursor at the start of the tracked deletion
      tr.setSelection(Selection.near(tr.doc.resolve(tr.mapping.map(removedRange.from, -1))));

      if (addedSlice !== Slice.empty) {
        const addedFrom = tr.mapping.map(insSpan.from, -1);

        // Insert the new content in the same place it was originally inserted
        this.applyTrackedChange(tr, {
          changeType: ChangeType.Add,
          newRange: { from: addedFrom, to: addedFrom },
          slice: addedSlice
        });
      }
    }
  }

  /*
   * Replace
   */
  applyChangeToReplace(tr: Transaction) {
    const delSpan = this.changeDetector.firstDeletedSpan;
    const insSpan = this.changeDetector.firstInsertedSpan;

    // There is a special case for deleting the char in front of a space char that has a tracked change
    // Typically a simple deletion like that would be detected as a generic change but for this case there is a delete and insert span so it gets detected as a replace
    if (
      // If the deleted span has two nodes
      delSpan.slice.content.childCount === 2 &&
      // Where the first node is inline with a single char
      delSpan.slice.content.firstChild.isInline && delSpan.slice.content.firstChild.textContent.length === 1 &&
      // And the second node is a text node starting with a single space with a change
      delSpan.slice.content.lastChild.isText && delSpan.slice.content.lastChild.text.startsWith(' ') && nodeHasChange(this.schema, delSpan.slice.content.lastChild) &&
      // And the inserted span's first leaf node starts with a text node that starts with a single space with a change
      predicate(getFirstLeafNodeInFragment(insSpan.slice.content), node => node && node.isText && node.text.startsWith(' ') && nodeHasChange(this.schema, node))
    ) {
      // Create a tracked removal for the deleted char
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Remove,
        newRange: { from: delSpan.from, to: delSpan.from },
        // The deleted span slice includes the space char but we only want to track the char before the space
        slice: new Slice(Fragment.from(delSpan.slice.content.firstChild), 0, 0)
      });

      // Update the selection. Put the cursor at the start of the deleted char
      tr.setSelection(Selection.near(tr.doc.resolve(delSpan.from)));
    } else if (delSpan.$from.parent.type.name === 'madcapcodesnippetbody' &&
      insSpan.$from.parent.type.name === 'madcapcodesnippetbody') {
      this.applyChangeToCodeSnippetBody(tr, delSpan);
      this.applyChangeToCodeSnippetBody(tr, insSpan);
      // Update the selection. Put the cursor at the end of the tracked addition.
      // Don't map since the change is applied to the whole codeSnippetBody
      tr.setSelection(Selection.findFrom(tr.doc.resolve(insSpan.to), 1, true));
    } else {
      // Insert the new content in the same place it was originally inserted
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Add,
        newRange: { from: insSpan.from, to: insSpan.from },
        slice: insSpan.isPlaceholder ? Slice.empty : this.newState.doc.slice(insSpan.from, insSpan.to - Math.max(0, delSpan.slice.openStart - delSpan.slice.openEnd)) // Adjust for the open start/end
      });

      // Replace the newly inserted content with a tracked removal of the old content
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Remove,
        newRange: { from: insSpan.from, to: insSpan.to - insSpan.slice.openEnd }, // Adjust for the open end
        slice: delSpan.isPlaceholder ? Slice.empty : delSpan.slice
      });

      // Update the selection. Put the cursor at the end of the tracked addition
      tr.setSelection(Selection.near(tr.doc.resolve(tr.mapping.map(insSpan.from)), -1));
    }
  }

  /*
   * Table replacement
   */
  applyChangeToTable(tr: Transaction) {
    // Find the table node in the old state
    const oldResolvedPosInfo = resolvedPosFind(this.oldState.selection.$head, node => node.type.name === this.schema.nodes.table.name);

    if (oldResolvedPosInfo) {
      const oldTableNode = oldResolvedPosInfo.node;

      // Check for an existing replace change on the table
      const replaceChange = getNodeChange(this.schema, oldTableNode, ChangeType.Replace);
      // Use existing change otherwise use the entire table tag and its children as the content of the replace change
      const replaceContent = replaceChange?.changeContent || this.schema.nodeToCode(oldTableNode);

      // Find the table node in the new state
      const newResolvedPosInfo = tr.doc.resolve(oldResolvedPosInfo.$nodePos.pos);
      // Apply a replace change
      this.applyTrackedChange(tr, {
        changeType: ChangeType.Replace,
        changeContent: replaceContent,
        newRange: { from: newResolvedPosInfo.pos, to: newResolvedPosInfo.pos + newResolvedPosInfo.nodeAfter.nodeSize }, // The range of the new table
        slice: new Slice(Fragment.from(newResolvedPosInfo.nodeAfter), 0, 0) // A slice of the new table
      });
    }
  }

  /*
   * applyChangeToNodeReplace
   * Handles applying tracked changes to a node's attributes being changed.
   */
  applyChangeToNodeReplace(tr: Transaction) {
    // Node replace changes only show up as transaction steps so loop through the steps to apply the changes
    for (let i = 0; i < this.transaction.steps.length; i += 1) {
      const step = this.transaction.steps[i];

      // Check for and grab any attributes that have changed on the replaced node
      const oldNode = this.oldState.doc.nodeAt(step.from);
      const oldAttrsSpec = oldNode.type.spec.attrs;
      const oldAttrs = oldNode.attrs;
      const newAttrs = step.slice.content.firstChild.attrs;
      const skippedAttrs = step.slice.content.firstChild.type.spec.skippedTrackingAttributes;
      const diffAttrNames = Object.keys(newAttrs)
        .filter(key =>
          !skippedAttrs.includes(key) &&
          !isEqual(newAttrs[key], oldAttrs[key]));

      // Grab a slice of the new node
      let newSlice = this.newState.doc.slice(step.from, step.to);

      // Loop through each attribute that changed and add a tracked change for each one
      diffAttrNames.forEach(attrName => {
        // Add an attribute change to the updated node
        const result = this.applyTrackedChange(tr, {
          changeType: ChangeType.Attributes,
          changeAttribute: attrName,
          changeValue: oldAttrsSpec?.[attrName]?.toFlareXML?.(oldAttrs[attrName], null, oldNode) ?? oldAttrs[attrName],
          newRange: step,
          slice: newSlice
        });

        // Grab a slice of the node with the new tracked change on it (to be used in the next loop)
        newSlice = result.slice;
      });
    }
  }

  /*
   * applyChangeToSplitAndWrap
   * Handles cases such as wrapping part of a inline node to a new node.
   */
  applyChangeToSplitAndWrap(tr: Transaction) {
    const $oldFrom = this.oldState.selection.$from;
    const $oldTo = this.oldState.selection.$to;

    const parentDepth = $oldFrom.sharedDepth($oldTo.pos);

    //Finds the range for the removal change. If "from" or "to" are inside an inline node, they are moved outside the node.
    const removeFrom = $oldFrom.depth > parentDepth ?
      $oldFrom.start(parentDepth + 1) - 1 :
      $oldFrom.pos;
    const removeTo = $oldTo.depth > parentDepth ?
      $oldTo.end(parentDepth + 1) + 1 :
      $oldTo.pos;

    // If removal range are moved, then move adding range
    const fromOffset = removeFrom !== $oldFrom.pos ?
      ($oldFrom.pos - removeFrom) :
      0;
    const toOffset = removeTo !== $oldTo.pos ?
      (removeTo - $oldTo.pos) :
      0;
    const $newFrom = this.newState.doc.resolve(this.newState.selection.from - fromOffset);
    const $newTo = this.newState.doc.resolve(this.newState.selection.to + toOffset);

    // Finds the range for the adding change.
    const addFrom = $newFrom.depth > parentDepth ?
      $newFrom.start(parentDepth + 1) - 1 :
      $newFrom.pos;
    const addTo = $newTo.depth > parentDepth ?
      $newTo.end(parentDepth + 1) + 1 :
      $newTo.pos;

    this.applyTrackedChange(tr, {
      changeType: ChangeType.Add,
      newRange: { from: addFrom, to: addTo },
      slice: this.newState.doc.slice(addFrom, addTo),
    })

    this.applyTrackedChange(tr, {
      changeType: ChangeType.Remove,
      newRange: { from: addTo, to: addTo },
      slice: this.oldState.doc.slice(removeFrom, removeTo),
    });

    // Update the selection. Put the cursor at the end of the tracked addition
    tr.setSelection(Selection.near(tr.doc.resolve(addTo), -1));
  }

  /*
   * Generic
   * Handles simple cases like text input and deletion
   */
  applyChangeGenerically(tr: Transaction): AppliedTrackedChange[] {
    const appliedTrackedChanges: AppliedTrackedChange[] = [];

    // Process deletions
    if (Array.isArray(this.changeDetector.deleted)) {
      this.changeDetector.deleted.forEach(span => {
        // Deletion in codeSnippetBody
        if (span.$from.parent.type.name === 'madcapcodesnippetbody') {
          this.applyChangeToCodeSnippetBody(tr, span);
          tr.setSelection(Selection.near(tr.doc.resolve(span.from)));
        }
        // Joins
        else if (span.isJoin) {
          const oldNodeInfo = getNodeInfoFromFragmentAtPos(span.slice.content, span.to, this.oldState.doc);
          const newNodeInfo = getNodeInfoFromFragmentAtPos(span.slice.content, span.from, this.newState.doc);

          // Create a tracked removal for the content being moved out of its old place
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: { from: newNodeInfo.to, to: newNodeInfo.to },
            slice: this.oldState.doc.slice(oldNodeInfo.from, oldNodeInfo.to)
          });

          // Grab a slice of the content that was moved
          const sliceMoved = oldNodeInfo.node.slice(0);

          // Make a new slice of the content without removed changes in it. This will replace the moved content.
          const newSlice = nodeHasChange(this.schema, oldNodeInfo.node, ChangeType.Remove) ? Slice.empty : copySliceWithoutChanges(this.schema, sliceMoved, ChangeType.Remove);

          // Create a tracked addition for the content being moved into its new place
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Add,
            newRange: { from: span.from, to: span.from + sliceMoved.content.size },
            slice: newSlice
          });
        }
        // Empty joins
        else if (span.isEmptyJoin) {
          // Create a tracked removal for the empty node being joined into
          this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: {
              from: span.pos,
              to: span.pos
            },
            slice: span.isPlaceholder ? Slice.empty : span.slice
          });

          // Update the selection
          let newSelection;

          // If this empty join deleted the element the cursor was in
          if (!this.oldState.selection.empty || span.pos >= this.newState.selection.head) {
            // An atom node has no depth because it has no content so do not try to find the depth
            const findDepth = this.oldState.selection.empty && span.$from.nodeBefore && !span.$from.nodeBefore.isAtom;
            // Calculate the depth of the node being joined to. We want the selection to be at the end of the deepest node at the end of the joined node
            const depth = findDepth ? depthInLastChildOfFragment(span.$from.nodeBefore.content) + 1 : 0; // Add 1 because we want to include the node before as well
            // Move the selection to the end of the node being joined to
            newSelection = Selection.near(tr.doc.resolve(span.from - 1 - depth)); // Subtract 1 to move before the node that was removed and then subtract the depth of the node being joined to
          }
          // Else this empty join deleted the element before
          else {
            newSelection = Selection.near(tr.doc.resolve(tr.mapping.map(span.from)));
          }

          if (newSelection && !this.changeDetector.isCellSelection) {
            tr.setSelection(newSelection);
          }
          // Simple deletions
        } else {
          appliedTrackedChanges.push(this.applyTrackedChange(tr, {
            changeType: ChangeType.Remove,
            newRange: {
              from: span.isDeletingEntireFromNode ? (span.pos - 1 - span.fromDepth) : span.pos,
              to: span.pos
            },
            slice: span.isPlaceholder ? Slice.empty : span.slice
          }));
        }
      });
    }

    // Process insertions
    if (Array.isArray(this.changeDetector.inserted)) {
      const lastDelSpan = this.changeDetector.lastDeletedSpan;

      this.changeDetector.inserted.forEach(span => {
        let newRange;
        let newSlice: Slice;
        // Insertion in codeSnippetBody
        if (span.$from.parent.type.name === 'madcapcodesnippetbody') {
          this.applyChangeToCodeSnippetBody(tr, span);
          return;
        } else if (lastDelSpan && lastDelSpan.isDeletingEntireToNode) {
          // Remove the inserted span
          tr.replaceRange(tr.mapping.map(span.from), tr.mapping.map(span.to), Slice.empty);

          newRange = {
            from: span.from + 2 + lastDelSpan.toDepth,
            to: span.to + 2 + lastDelSpan.toDepth // Add 2 to move outside of the inserted node AND then add the depth of the deleted span itself
          };
          newSlice = this.newState.doc.slice(span.from, span.to + 1 + lastDelSpan.toDepth); // Add the depth of the deleted span to include all the nodes in the deleted span
        } else {
          newRange = span;
          newSlice = span.isPlaceholder ? Slice.empty : span.slice;
        }

        // Insert the span outside the last deleted node making sure to include the entire node hierarchy and not just the deepest node
        appliedTrackedChanges.push(this.applyTrackedChange(tr, {
          changeType: ChangeType.Add,
          newRange: newRange,
          slice: newSlice
        }));
      });
    }

    return appliedTrackedChanges;
  }

  applyGenericSelection(tr: Transaction, appliedTrackedChanges: AppliedTrackedChange[]) {
    // Update the selection to take into account the tracked changes that were inserted
    const oldStateSelection = this.oldState.selection;
    const newStateSelection = this.newState.selection;
    let selectionAppliedTrackedChange;

    // Grab the applied tracked change that the old selection falls in
    if (Array.isArray(appliedTrackedChanges)) {
      selectionAppliedTrackedChange = appliedTrackedChanges.find(appliedTrackedChange => {
        return oldStateSelection.from >= appliedTrackedChange.from && oldStateSelection.from <= appliedTrackedChange.to;
      });
    }

    // If the entire document was selected
    if (oldStateSelection instanceof AllSelection) {
      // Then move to the front of the new selection
      tr.setSelection(TextSelection.create(tr.doc, newStateSelection.from));
      // If table cell(s) were selected
    } else if (oldStateSelection instanceof CellSelection) {
      if (appliedTrackedChanges && appliedTrackedChanges.length > 0) {
        // If content was added
        if (appliedTrackedChanges[appliedTrackedChanges.length - 1].type === 'add') {
          // So move to the end of the new selection. Use a bias of -1 so that the selection stays in the first cell
          tr.setSelection(Selection.near(tr.doc.resolve(tr.mapping.map(newStateSelection.from)), -1));
        } else {
          // So move to the front of the new selection
          tr.setSelection(TextSelection.create(tr.doc, appliedTrackedChanges[0].from));
        }
      } else {
        // Table command was called. Revert to the previous selection
        tr.setSelection(TextSelection.create(tr.doc, newStateSelection.from));
      }
      // Else if the selections are equal before and after the transaction
    } else if (newStateSelection.eq(oldStateSelection)) {
      // Then do nothing and use the selection prosemirror came up with
      // Else if the selection moved forward
    } else if (newStateSelection.from > oldStateSelection.from) {
      // Then move the selection to the old selection + (the change in the selection position between the new and old state)

      let start;
      // If the applied slice is empty then the selection should be calculated starting at the beginning of the old selection
      if (selectionAppliedTrackedChange && selectionAppliedTrackedChange.type === 'remove' && selectionAppliedTrackedChange.slice.size === 0) {
        start = oldStateSelection.from;
        // Else a new slice was added so calculate the selection from the end of the old selection
      } else {
        start = oldStateSelection.to;
      }

      // Clamp the new position to the end of the document
      tr.setSelection(TextSelection.create(tr.doc, Math.min(tr.doc.content.size, start + (newStateSelection.from - oldStateSelection.from))));
      // Else the selection moved backwards
    } else {
      // So move to the front of the new selection
      tr.setSelection(TextSelection.create(tr.doc, newStateSelection.from));
    }
  }

  applyListItemSelection(tr: Transaction, posAtstartOfListItem: number, selectionLengthReduction: number = 0, oldPosAtStartOfListItem?: number, startOfListItemOffset: number = 0) {
    const oldSelection = this.oldState.selection;
    const startOfNode = tr.mapping.map(posAtstartOfListItem) + startOfListItemOffset;

    if (oldSelection instanceof TextSelection) {
      // Grab the start of the list item in its new position
      const selectionLength = Math.abs(oldSelection.head - oldSelection.anchor) - selectionLengthReduction;
      let selectionOffset = 0;

      // Find the list item that is being lifted and calculate the offset from it
      const $selectionPos = oldSelection.head > oldSelection.anchor ? oldSelection.$anchor : oldSelection.$head;

      // Determine the start of the first old list item
      if (typeof oldPosAtStartOfListItem !== 'number') {
        const resolvedListItemPosInfo = resolvedPosFind($selectionPos, node => node.type.spec.listItem);
        if (resolvedListItemPosInfo) {
          oldPosAtStartOfListItem = $selectionPos.before(resolvedListItemPosInfo.depth - $selectionPos.depth);
        }
      }

      if (typeof oldPosAtStartOfListItem === 'number') {
        // The offset is the position of the cursor MINUS the position of the list item
        selectionOffset = $selectionPos.pos - oldPosAtStartOfListItem;
      }

      // Set the selection taking into account which position the head is on relative to the anchor
      if (oldSelection.head > oldSelection.anchor) {
        tr.setSelection(TextSelection.create(tr.doc, startOfNode + selectionOffset, startOfNode + selectionOffset + selectionLength));
      } else {
        tr.setSelection(TextSelection.create(tr.doc, startOfNode + selectionOffset + selectionLength, startOfNode + selectionOffset));
      }
    } else if (oldSelection instanceof NodeSelection) {
      // Grab the start of the list item in its new position
      let selectionOffset = 0;

      // If the selected node is not the list item then find the list item and calculate the offset from it
      if (!oldSelection.node.type.spec.listItem) {
        const $anchor = oldSelection.$anchor;
        const resolvedPosInfo = resolvedPosFind($anchor, node => node.type.spec.listItem);

        if (resolvedPosInfo) {
          // The offset is the position of the cursor MINUS the position of the list item
          selectionOffset = $anchor.pos - $anchor.before(resolvedPosInfo.depth !== $anchor.depth ? resolvedPosInfo.depth - $anchor.depth : null);
        }
      }

      tr.setSelection(NodeSelection.create(tr.doc, startOfNode + selectionOffset));
    }
  }

  /*
   * applyChange
   * Determines the type of change made and applies tracked changes to the affected nodes.
   */
  applyChange(tr: Transaction): Transaction {
    let appliedTrackedChanges;

    // Change with cell selected
    if (this.changeDetector.isCellSelection) {
      appliedTrackedChanges = this.applyChangeGenerically(tr);
      // Block Type Change
    } else if (this.changeDetector.isBlockTypeChange) {
      this.applyChangeToBlockTypeChange(tr);
      // Lift Change
    } else if (this.changeDetector.isLift) {
      this.applyChangeToLift(tr);
      // Backwards Lift Change
    } else if (this.changeDetector.isBackwardsLift) {
      this.applyChangeToBackwardsLift(tr);
      // Inline Lift Change (an inline lift is like an unwrap)
    } else if (this.changeDetector.isInlineLift) {
      this.applyChangeToUnwrap(tr);
      // Middle list item lift change
    } else if (this.changeDetector.isMiddleListItemLift) {
      this.applyChangeToMiddleListItemLift(tr);
      // Middle sub list item lift change
    } else if (this.changeDetector.isMiddleSubListItemLift) {
      this.applyChangeToMiddleListItemLift(tr);
      // Middle sub definition list item lift change
    } else if (this.changeDetector.isMiddleSubDefinitionListItemLift) {
      this.applyChangeToMiddleListItemLift(tr);
      // Last list item lift change
    } else if (this.changeDetector.isLastListItemLift) {
      this.applyChangeToLastListItemLift(tr);
      // Last sub list item lift change
    } else if (this.changeDetector.isLastSubListItemLift) {
      this.applyChangeToLastSubListItemLift(tr);
      // All list items lift change
    } else if (this.changeDetector.isAllListItemsLift) {
      this.applyChangeToAllListItemsLift(tr);
      // List item sink change
    } else if (this.changeDetector.isListItemSink) {
      this.applyChangeToListItemSink(tr);
      // List item sink join change
    } else if (this.changeDetector.isListItemSinkJoin) {
      this.applyChangeToListItemSinkJoin(tr);
      // Definition list exit change
    } else if (this.changeDetector.isNodeExit) {
      this.applyChangeToNodeExit(tr);
      // Split Change
    } else if (this.changeDetector.isSplit) {
      this.applyChangeToSplit(tr);
      // Text Split Change
    } else if (this.changeDetector.isSplitText) {
      this.applyChangeToSplitText(tr);
      // Table Row Insert Change
    } else if (this.changeDetector.isTableRowInsertion) {
      this.applyChangeToTable(tr);
      // Table Column Insert Change
      /* isWrap gives a false positive, needs to check prior */
    } else if (this.changeDetector.isTableColumnInsertion) {
      this.applyChangeToTable(tr);
      // Table Row Delete Change
    } else if (this.changeDetector.isTableRowDeletion) {
      this.applyChangeToTable(tr);
      // Table Column Delete Change
    } else if (this.changeDetector.isTableColumnDeletion) {
      this.applyChangeToTable(tr);
    } else if (this.changeDetector.isTableDeletion) {
      this.applyChangeGenerically(tr);
      // Wrap Change
    } else if (this.changeDetector.isWrap) {
      this.applyChangeToWrap(tr);
      // Wrap and Replace Change
    } else if (this.changeDetector.isWrapAndReplace) {
      this.applyChangeToWrapAndReplace(tr);
      // Unwrap Change
    } else if (this.changeDetector.isUnwrap) {
      this.applyChangeToUnwrap(tr);
      // Multi Depth Replace Join Change
    } else if (this.changeDetector.isMultiDepthReplaceJoin) {
      this.applyChangeToReplaceJoin(tr);
      // Replace Join Change
    } else if (this.changeDetector.isReplaceJoin) {
      this.applyChangeToReplaceJoin(tr);
      // Replace Change
    } else if (this.changeDetector.isReplace) {
      this.applyChangeToReplace(tr);
      // Node Replace Change
    } else if (this.changeDetector.isNodeReplace) {
      this.applyChangeToNodeReplace(tr);
      // Split and Wrap Change
    } else if (this.changeDetector.isSplitAndWrap) {
      this.applyChangeToSplitAndWrap(tr);
      // Generic Change
    } else {
      appliedTrackedChanges = this.applyChangeGenerically(tr);
    }

    // If changes were made and the selection was not updated
    if (tr.docChanged && !tr.selectionSet) {
      this.applyGenericSelection(tr, appliedTrackedChanges);
    }

    return tr;
  }
}
