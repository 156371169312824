import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MadCapImageUrlModule } from '@portal-core/general/pipes/madcap-image-url/madcap-image-url.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { TeamAvatarComponent } from './team-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    InlineButtonModule,
    MadCapImageUrlModule,
    MatDialogModule,
    MatTooltipModule
  ],
  declarations: [
    TeamAvatarComponent
  ],
  exports: [
    TeamAvatarComponent
  ]
})
export class TeamAvatarModule { }
