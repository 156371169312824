import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(private router: Router) { }

  /** Returns the query string parameters for the current route. */
  getCurrentQueryParams(): Params {
    return this.router.routerState.snapshot.root.queryParams;
  }

  getCurrentFragment(): string {
    return this.router.routerState.snapshot.root.fragment;
  }

  getRoutePath(route: ActivatedRouteSnapshot): string[] {
    const paths: string[] = [];

    while (route) {
      const segment = route.url[0];

      if (segment) {
        paths.push(segment.path);
      }

      route = route.firstChild;
    }

    return paths;
  }

  /** Returns the value for a param looking first in the route and then each child all the way down the tree. */
  getChildParam(route: ActivatedRouteSnapshot, paramName: string): string {
    while (route) {
      if (paramName in route.params) {
        return route.params[paramName];
      }

      route = route.firstChild;
    }
  }

  /** Returns an observable that observes a child's params at a given depth. */
  childParams$(route: ActivatedRoute, depth: number): Observable<Params> {
    while (route && depth > 0) {
      route = route.firstChild;
      depth -= 1;
    }

    return route?.params ?? of(undefined);
  }
}
