import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { SoloFileTextEditorComponent } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.component';
import { TextEditorApiService } from '@portal-core/text-editor/services/text-editor-api.service';
import { NodeViewBase } from '@portal-core/text-editor/util/node-view.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Plugin } from 'prosemirror-state';
import { NodeViewConstructor } from 'prosemirror-view';

@Component({
  selector: 'mc-snippet-node-view',
  templateUrl: './snippet-node-view.component.html',
  styleUrls: ['./snippet-node-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SnippetNodeViewComponent extends NodeViewBase implements OnChanges {
  @ViewChild(SoloFileTextEditorComponent) soloFileTextEditorComponent: SoloFileTextEditorComponent;

  @Input() inline: boolean;
  @Input() nodeViews: Dictionary<NodeViewConstructor>;
  @Input() plugins: Plugin[];
  @Input() schema: CollabSchema;
  @Input() src: string;
  @Input() isRecursive: boolean = false;
  @Input() isOuterSnippet: boolean = false;

  @HostBinding('class')
  get changeClass(): string {
    return this.inline ? 'mc-node-view-inline-pane' : 'mc-node-view-pane';
  }
  @HostBinding('class.mc-snippet-node-view-inline')
  get inlineClass(): boolean {
    return this.inline;
  }
  @HostBinding('class.mc-snippet-node-view-block')
  get blockClass(): boolean {
    return !this.inline;
  }

  content: string;
  loadingState: LoadingState<string> = new LoadingState<string>();

  public get parentClassNameSuffix(): string {
    return this.inline ? `snippet-node-view-inline${this.isOuterSnippet ? '-outer' : ''}` : 'snippet-node-view-block';
  }

  public get showRemovalMark(): boolean {
    return !this.inline;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected elementRef: ElementRef, private textEditorApiService: TextEditorApiService, private errorService: ErrorService) {
    super(changeDetectorRef, elementRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (!this.isRecursive && changes.src) {
      this.loadingState.update(true);
      if (this.src) {
        this.textEditorApiService.getFlareSnippet$(this.src)
          .subscribe(content => {
            this.content = content;
            this.loadingState.update(false);
            this.changeDetectorRef.detectChanges();
          }, error => {
            this.loadingState.update(false, 'Unable to load snippet', this.errorService.getErrorMessages(error));
            this.changeDetectorRef.detectChanges();
          });
      } else {
        this.loadingState.update(false);
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  onSoloTextFileEditorLoaded() {
    this.load.emit();
  }
}
