import { NgModule } from '@angular/core';
import { TaskAssetIconComponent } from '@portal-core/tasks/components/task-asset-icon/task-asset-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
   ],
  declarations: [
    TaskAssetIconComponent
  ],
  exports: [
    TaskAssetIconComponent
  ]
})
export class TaskAssetIconModule { }
