import { Injectable } from '@angular/core';
import { DataList } from '@portal-core/data/collection/models/data-list.model';
import { GetDataListMethods } from '@portal-core/data/collection/models/get-data-list-methods.model';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataMethods } from '@portal-core/data/common/models/get-data-methods.model';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { McModel } from '@portal-core/data/common/models/mc-model.model';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { ResolvePageItemOptions } from '@portal-core/data/page/models/resolve-page-item-options.model';
import { PageServiceBase } from '@portal-core/data/page/services/page.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { catchError, concatMap, finalize, first, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  static DefaultDataMaxAgeMS: number = 10 * 60 * 1000; // 10 minutes

  constructor(private errorService: ErrorService) { }

  /*
   * Basic data models
   */
  getData<T>(methods: GetDataMethods<T>, options?: GetDataOptions): Observable<T> {
    // Initialize the options
    options = Object.assign({
      allowApiRequest: true,
      forceApiRequest: false,
      maxAgeMS: DataService.DefaultDataMaxAgeMS
    }, options);

    let apiRequestCount = 0;

    // Get the observable straight from the redux state
    return methods.get(options).pipe(
      concatMap(data => {
        let fetch$: Observable<T>;

        if (methods.fetch && options.allowApiRequest) {
          const dataDoesExist = this.dataDoesExist<T>(data, methods.exists, options);
          const cacheHasExpired = this.cacheHasExpired<T>(data, methods.expired, options);

          if ((options.forceApiRequest && apiRequestCount === 0) || (!dataDoesExist && apiRequestCount === 0) || cacheHasExpired) {
            // Update the getData state values
            apiRequestCount += 1;

            // Fetch the data
            fetch$ = methods.fetch(options).pipe(
              tap(newData => {
                if (methods.set) {
                  methods.set(newData, options);
                }
              })
            );
          }
        }

        return fetch$ || of(data);
      })
    );
  }

  /*
   * Lists
   * A data-list is stored in a collection data service. It is keyed by the list name and list id.
   * The list name is shared between data-lists of the same type while the list id is unique to that list name.
   * For example, if you want a data-list of projects by license id. The collection data service is ProjectsDataService because the data-list contains projects.
   * The list name could be 'Licenses' to indicate these are data-lists filtered by license. And the list id can be the license id since that is what makes each data-list unique.
   */
  getDataList$<T extends McModel>(
    listName: string,
    listId: ModelId,
    collectionDataService: CollectionDataServiceBase<T>,
    methods: GetDataListMethods<T>,
    options?: GetDataOptions
  ): Observable<DataList> {
    options = options || {};

    return this.getData<DataList>({
      get: () => {
        return collectionDataService.getListById$(listName, listId);
      },
      fetch: (fetchOptions) => {
        return methods.fetch(fetchOptions).pipe(
          switchMap(items => {
            return collectionDataService.setListById$(listName, listId, items).pipe(
              map(() => {
                return {
                  Id: listId,
                  Items: items.map(item => item.Id)
                };
              })
            );
          })
        );
      }
    }, options);
  }

  getDataListItems$<T extends McModel>(
    listName: string,
    listId: ModelId,
    collectionDataService: CollectionDataServiceBase<T>,
    methods: GetDataListMethods<T>,
    options?: GetDataOptions
  ): Observable<T[]> {
    return this.getDataList$<T>(listName, listId, collectionDataService, methods, options).pipe(
      map(dataList => {
        if (dataList && Array.isArray(dataList.Items)) {
          const collectionItems = collectionDataService.getItems();
          return dataList.Items.map(itemId => collectionItems[itemId]).filter(item => !!item);
        } else {
          return [];
        }
      })
    );
  }

  /*
   * Resolvers
   */
  resolvePageItem$<T extends McModel, S extends McModel>(
    itemId: ModelId,
    pageService: PageServiceBase<T, S>,
    collectionService: CollectionServiceBase<T>,
    options: ResolvePageItemOptions = { throwErrors: false }
  ): Observable<T> {
    pageService.setLoading$(true);
    pageService.setErrorLoading$(null);

    const obs = options.fetch ? options.fetch(itemId, options) : collectionService.getItemById$(itemId, options);

    return obs.pipe(
      first(item => !!item),
      tap(() => pageService.setItemId$(itemId)),
      catchError(error => {
        pageService.setErrorLoading$(error);
        pageService.setItemId$(null);

        return options.throwErrors ? throwError(error) : of(null);
      }),
      finalize(() => pageService.setLoading$(false))
    );
  }

  /*
   * Returns true if two items are the same or have the same Id.
   * Good to use with the RXJS distinctUntilChanged operator.
   */
  sameIdentity<T extends McModel = McModel>(itemA: T, itemB: T): boolean {
    if (itemA === itemB) {
      return true;
    }

    if (itemA && itemB) {
      return itemA.Id === itemB.Id;
    }

    return false;
  }

  /*
   * Helpers
   */
  // Returns whether the data's cached value has expired. The logic to determine if the data has expired can optionally be overridden.
  cacheHasExpired<T extends McModel>(data: T, expired?: boolean | McModel | ((data: any, options: GetDataOptions) => boolean), options?: GetDataOptions): boolean {
    let maxAgeMS, createdAt;

    // Check for custom expiration values
    if (typeof expired === 'function') {
      return expired(data, options);
    } else if (typeof expired === 'boolean') {
      return expired;
    } else if (typeof expired === 'object' && expired !== null) {
      data = expired as T;
    }

    // Grab the max age of the data
    if (options && typeof options.maxAgeMS === 'number') {
      maxAgeMS = options.maxAgeMS;
    } else if (data && typeof data.mcMaxAgeMS === 'number') {
      maxAgeMS = data.mcMaxAgeMS;
    } else {
      return false; // There is no max age so the cache cannot expire
    }

    // Grab the date the data was cached at
    if (data && typeof data.mcCachedAt) {
      createdAt = data.mcCachedAt;
    }

    // Return whether the data's age is greater than its expiration age
    return createdAt ? maxAgeMS < dayjs().diff(createdAt) : false;
  }

  // Returns whether data exists for the getData method. Data exists if it is not falsy. The logic to determine if data exists can optionally be overridden.
  dataDoesExist<T extends McModel>(data: T, exists?: string | string[] | ((data: any, options: GetDataOptions) => boolean), options?: GetDataOptions): boolean {
    // Check the requires option. This does not replace the exists parameter check but instead is an additional check
    if (options && Array.isArray(options.requires) && data) {
      if (!options.requires.every(property => !isNil(data[property]))) {
        return false;
      }
    }

    if (typeof exists === 'function') {
      return exists(data, options);
    } else if (typeof exists === 'string') {
      return data && !isNil(data[exists]);
    } else if (Array.isArray(exists)) {
      return data && exists.every(property => !isNil(data[property]));
    }

    return !!data;
  }
}
