import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { StorageUsageBarComponent } from './storage-usage-bar.component';

@NgModule({
  declarations: [
    StorageUsageBarComponent
  ],
  imports: [
    CommonModule,
    FileSizeModule,
    MatProgressBarModule
  ],
  exports: [
    StorageUsageBarComponent
  ]
})
export class StorageUsageBarModule { }
