import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationPackageStatusTextPipe } from './translation-package-status-text.pipe';

@NgModule({
  declarations: [TranslationPackageStatusTextPipe],
  imports: [CommonModule],
  exports: [TranslationPackageStatusTextPipe],
  providers: [TranslationPackageStatusTextPipe]
})
export class TranslationPackageStatusTextModule { }
