import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileStatusColorPipe } from './review-file-status-color.pipe';

@NgModule({
  declarations: [ReviewFileStatusColorPipe],
  imports: [CommonModule],
  exports: [ReviewFileStatusColorPipe],
  providers: [ReviewFileStatusColorPipe]
})
export class ReviewFileStatusColorModule { }
