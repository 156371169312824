import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';

@Pipe({
  name: 'mcReviewFileUserStatusLicenseUserTooltip'
})
export class ReviewFileUserStatusLicenseUserTooltipPipe implements PipeTransform {
  constructor(private licenseUsersService: LicenseUsersService) { }

  transform(status: ReviewFileUserStatus | null | undefined, userStatus: LicenseUserStatus, userIsDeleted: boolean, userSeatType: LicenseUserSeatType, userName: string): string {
    let reviewFileStatusText: string = '';

    switch (status) {
      case ReviewFileUserStatus.Todo:
        reviewFileStatusText = 'To Do';
        break;
      case ReviewFileUserStatus.Submitted:
        reviewFileStatusText = 'Submitted';
        break;
      case ReviewFileUserStatus.InProgress:
        reviewFileStatusText = 'In Progress';
        break;
    }

    let userStatusText: string = '';

    if (userIsDeleted) {
      userStatusText = 'This user has been deleted.';
    } else {
      switch (userStatus) {
        case LicenseUserStatus.Deactivated:
          userStatusText = 'This user has been deactivated.';
          break;
        case LicenseUserStatus.Invited:
          userStatusText = 'This user has a pending invite.';
          break;
        case LicenseUserStatus.Paused:
          userStatusText = 'This user has been paused.';
          break;
      }
    }

    return `${userName} (${this.licenseUsersService.getLicenseUserSeatTypeShortDisplayText(userSeatType)})${reviewFileStatusText ? '\n' + reviewFileStatusText : ''}${userStatusText ? '\n\n' + userStatusText : ''}`;
  }
}
