<mc-select-list [dataListId]="dataListId" listClass="mc-list-selectable" [listControl]="listControl">
  <ng-template mcListItem let-build>
    <mat-list-item>
      <mc-build-avatar *ngIf="build.Id !== 0" [build]="build" [disabled]="true" [showCompletedDate]="true" size="small" [truncate]="true"></mc-build-avatar>
      <mc-build-avatar *ngIf="build.Id === 0" [disabled]="true" [latest]="true" size="small" [truncate]="true"></mc-build-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>No builds found.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All builds loaded.</p>
  </ng-template>
</mc-select-list>