<form novalidate *ngIf="licenseStorage$ | async as licenseStorage; else loading" (ngSubmit)="saveGeneralSettings()" [formGroup]="changeGeneralSettingsForm">
  <mc-loader *ngIf="awaitingResponse" class="mc-loader-semi-transparent"></mc-loader>
  <div class="mc-spring-board" #selectionView [class.mc-hidden]="!hideConfirmPurchase">
    <div class="mc-spring-board-form-body">
      <p class="mc-form-text">
        <span class="text-medium-weight">Tip: </span>Want to adjust your seats and/or storage space before purchasing?
        <button class="mc-link-button mc-inline-button" type="button" [matMenuTriggerFor]="settingsTip">Click here</button>.
      </p>

      <div class="mc-purchase-detail-container">
        <span class="mc-purchase-detail-header">Storage</span>
        <i *ngIf="isTrial" class="text-muted text-light-weight mc-purchase-detail-sub-header">first {{trialFreeGb}} GB free</i>
        <span class="mc-fill-remaining-flex-space"></span>
        <span class="mc-purchase-capacity mc-text-muted">{{licenseStorage.UsedStorageSpace | mcFileSize}} of
          <span *ngIf="isTrial">Unlimited</span>
          <span *ngIf="!isTrial">{{licenseStorage.MaxStorageSpace + (changeGeneralSettingsForm.value.storage * OneGBInBytes) | mcFileSize}} Used</span>
        </span>
        <ng-container *ngIf="(userCanPurchase$ | async) && !license?.PurchaseInProgress">
          <mat-form-field color="primary-vivid" class="mc-license-purchasing-form-number-form-field">
            <input matInput type="number" [step]="storageStepSize" [min]="0" formControlName="storage">
            <span matPrefix>Add:&nbsp;</span>
            <span matSuffix>GB</span>
          </mat-form-field>
        </ng-container>
      </div>
      <mat-progress-bar mode="determinate" color="usage-storage" [value]="isTrial ? 0 : (licenseStorage.UsedStorageSpace / (licenseStorage.MaxStorageSpace + (changeGeneralSettingsForm.value.storage * OneGBInBytes))) * 100"></mat-progress-bar>

      <div class="mc-purchase-detail-container">
        <span class="mc-purchase-detail-header">Authors</span>
        <span class="mc-fill-remaining-flex-space"></span>
        <span class="mc-purchase-capacity mc-text-muted">{{licenseStorage.UsedAuthorSeats}} of
          <span *ngIf="isTrial">Unlimited</span>
          <span *ngIf="!isTrial">{{licenseStorage.AuthorSeats + changeGeneralSettingsForm.value.authors}} Used</span>
        </span>
        <ng-container *ngIf="(userCanPurchase$ | async) && !license?.PurchaseInProgress">
          <mat-form-field color="primary-vivid" class="mc-license-purchasing-form-number-form-field">
            <input matInput type="number" formControlName="authors" min="0">
            <span matPrefix>Add:&nbsp;</span>
            <span matSuffix>seats</span>
          </mat-form-field>
        </ng-container>

      </div>
      <mat-progress-bar mode="determinate" color="usage-authors" [value]="isTrial ? 0 : (licenseStorage.UsedAuthorSeats / (licenseStorage.AuthorSeats + changeGeneralSettingsForm.value.authors)) * 100"></mat-progress-bar>

      <div class="mc-purchase-detail-container">
        <span class="mc-purchase-detail-header">Subject Matter Experts</span>
        <span class="mc-fill-remaining-flex-space"></span>
        <span class="mc-purchase-capacity mc-text-muted">{{licenseStorage.UsedSmeSeats}} of
          <span *ngIf="isTrial">Unlimited</span>
          <span *ngIf="!isTrial">{{licenseStorage.SmeSeats + changeGeneralSettingsForm.value.smes}} Used</span>
        </span>
        <ng-container *ngIf="(userCanPurchase$ | async) && !license?.PurchaseInProgress">
          <mat-form-field color="primary-vivid" class="mc-license-purchasing-form-number-form-field">
            <input matInput type="number" formControlName="smes" min="0">
            <span matPrefix>Add:&nbsp;</span>
            <span matSuffix>seats</span>
          </mat-form-field>
        </ng-container>
      </div>
      <mat-progress-bar mode="determinate" color="usage-smes" [value]="isTrial ? 0 : (licenseStorage.UsedSmeSeats / (licenseStorage.SmeSeats + changeGeneralSettingsForm.value.smes)) * 100"></mat-progress-bar>

      <ng-container *ngIf="userCanPurchase$ | async">
        <ng-container *ngIf="!license?.PurchaseInProgress; else showPurchaseProcessing">
          <h4 *ngIf="orderItems" class="mc-form-title mc-summary">Summary:</h4>
          <mat-list>
            <mat-list-item *ngFor="let orderItem of orderItems">
              <div class="mc-order-item">
                <span>{{orderItem?.ItemDesc}}</span>
                <span>{{orderItem?.Quantity}}</span>
                <span>{{orderItem?.SaleTotal | currency:"US $"}}</span>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
          <div class="mc-purchase-price-container" [ngSwitch]="priceError">
            <p *ngSwitchCase="SettingsPurchasePriceError.Calculation" class="mc-error-message">There was a problem calculating the total cost.</p>
            <p *ngSwitchCase="SettingsPurchasePriceError.SeatSize" class="mc-error-message">Seats must be 99,999 or less.</p>
            <p *ngSwitchDefault>Total: {{price | currency:"US $"}}</p>
          </div>
        </ng-container>
        <ng-template #showPurchaseProcessing>
          <p class="mc-processing-purchase">Processing Purchase...</p>
        </ng-template>
      </ng-container>

    </div>
    <div class="mc-spring-board-footer">
      <ng-container *ngIf="userCanPurchase$ | async">
        <span class="mc-contact-label"> For additional purchase options, please contact us at <a href="mailto:sales@madcapsoftware.com?subject=Purchasing Options">sales@madcapsoftware.com</a> or call <span class="text-medium-weight mc-phone-number">+1 (858) 320-0387</span></span>
        <button mat-stroked-button type="button" (click)="closeDialog.emit(true)">Cancel</button>
        <button mat-raised-button [disabled]="!changeGeneralSettingsForm.valid || priceError || price == 0 || license?.PurchaseInProgress" class="mc-dialog-card-action-button" type="button" color="primary" (click)="hideConfirmPurchase = false">Continue</button>
      </ng-container>
    </div>
  </div>
  <div class="mc-spring-board" #confirmPurchaseView [class.mc-hidden]="hideConfirmPurchase">
    <div class="mc-spring-board-form-body">
      <h4 class="mc-form-title text-x-large">Confirm Purchase</h4>

      <div class="mc-form-text">
        <p>Card ending in: <span class="text-medium-weight">{{license?.ContactInfo?.creditCard?.last4Digits}}</span></p>
        <p>Expires: <span class="text-medium-weight">{{license?.ContactInfo?.creditCard?.expirationDate?.substring(0, 2)}}/{{license?.ContactInfo?.creditCard?.expirationDate?.substring(2, 4)}}</span></p>
        <p>Name on card: <span class="text-medium-weight">{{license?.ContactInfo?.creditCard?.cardholder}}</span></p>
        <p class="mc-edit-card-link"><a class="mc-link" (click)="openBillingTab()">Edit</a> card on file</p>
      </div>

      <div class="mc-form-text">
        <p><span class="text-medium-weight">Expiration Date:</span> {{(expirationDate$ | async) | date:'shortDate'}}</p>
        <p><span class="text-medium-weight">Central Key: </span>{{license?.ShortKey}}<span *ngIf="isTrial"> - Trial</span></p>
        <p><span class="text-medium-weight">Billing Cycle: </span>12 Months</p>
      </div>

      <h4 class="mc-form-title">Summary:</h4>
      <mat-list>
        <mat-list-item *ngFor="let orderItem of orderItems">
          <div class="mc-order-item">
            <span>{{orderItem?.ItemDesc}}</span>
            <span>{{orderItem?.Quantity}}</span>
            <span>{{orderItem?.SaleTotal | currency:"US $"}}</span>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>

      <div class="mc-purchase-price-container" [ngSwitch]="priceError">
        <p *ngSwitchCase="'calculationError'" class="mc-error-message">There was a problem calculating the total cost.</p>
        <p *ngSwitchCase="'seatSizeError'" class="mc-error-message">Seats must be 99,999 or less.</p>
        <p *ngSwitchDefault>Total: {{price | currency:"US $"}}</p>
      </div>

      <div class="mc-contact-label-container">
        <span class="mc-contact-label"> For additional purchase options, please contact us at <a href="mailto:sales@madcapsoftware.com?subject=Purchasing Options">sales@madcapsoftware.com</a> or call <span class="text-medium-weight mc-phone-number">+1 (858) 320-0387</span></span>
      </div>
    </div>
    <div class="mc-spring-board-footer">
      <button mat-stroked-button type="button" (click)="hideConfirmPurchase = true">Back</button>
      <button mat-raised-button [disabled]="!changeGeneralSettingsForm.valid || priceError || !license?.ContactInfo?.creditCard" class="mc-dialog-card-action-button" type="submit" color="primary">Purchase</button>
    </div>
  </div>
</form>

<ng-template #loading>
  <mc-loader></mc-loader>
</ng-template>
<mat-menu class="mc-settings-tip" #settingsTip="matMenu" [overlapTrigger]="false">
  <h4 class="mc-settings-tip-header">Tip</h4>

  <p>For authors or SMEs, you can open the Users page, then delete or deactivate excess users.</p>
  <p>Projects and builds typically use more space than other types of data, so you can open the Projects or Builds page to remove the files that you don't need.</p>
</mat-menu>