import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CommentBoxComponent } from '@portal-core/tasks/components/comment-box/comment-box.component';
import { TaskCommentComponent } from '@portal-core/tasks/components/task-comment/task-comment.component';
import { TaskCommentLevel } from '@portal-core/tasks/enums/task-comment-level.enum';
import { TaskCommentDelete } from '@portal-core/tasks/models/task-comment-delete.model';
import { TaskCommentGroup } from '@portal-core/tasks/models/task-comment-group.model';
import { TaskCommentsDialogService } from '@portal-core/tasks/services/task-comments-dialog.service';
import dayjs from 'dayjs';

@Component({
  selector: 'mc-task-comment-group',
  templateUrl: './task-comment-group.component.html',
  styleUrls: ['./task-comment-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskCommentGroupComponent implements OnInit {

  @ViewChild(CommentBoxComponent) commentComp: CommentBoxComponent;
  @ViewChildren(TaskCommentComponent) taskComments: TaskCommentComponent[];
  @Input() commentGroup: TaskCommentGroup;
  @Input() licenseId: number;
  @Input() canEditAllTasks: boolean;
  @Input() checkIsDirty: boolean = false;
  @Input() showDelete: boolean = false;
  @Output() isDirtyChanged: EventEmitter<any> = new EventEmitter();
  taskCommentLevel: any = TaskCommentLevel;
  discussionHeader: string;
  isDirty: boolean = false;
  private isDeleted: boolean;

  constructor(private taskCommentDialogService: TaskCommentsDialogService) { }

  ngOnInit() {
    const date = new Date(this.commentGroup.Date);
    const currentDate = new Date();
    const diff = currentDate.valueOf() - date.valueOf();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (diffDays <= 1) {
      this.discussionHeader = 'Today';
    } else if (diffDays === 2) {
      this.discussionHeader = 'Yesterday';
    } else if (currentDate.getFullYear === date.getFullYear) {
      this.discussionHeader = dayjs(date).format('dddd, MMMM Do');
    } else {
      this.discussionHeader = dayjs(date).format('MMMM Do, YYYY');
    }
  }

  onReplyCommentCreated(replyComment: HTMLElement, commentBox: CommentBoxComponent) {
    // delay needed to make sure control will scroll to the comment
    setTimeout(() => {
      replyComment.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => { commentBox.setFocus(); }, 100);
    }, 250);
  }

  onCommentDelete($event: TaskCommentDelete) {
    if ($event === undefined) {
      return;
    }

    this.isDeleted = true;
    const comment = $event.Comment;
    this.taskCommentDialogService.deleteComment(comment);
    this.onCommentDirtyChanged();
  }

  onCommentDirtyChanged() {
    this.isDirty = this.isDeleted
      || (this.taskComments
        ? this.taskComments.some(c => c.isDirty)
        : false)
      || (this.commentComp
        ? this.commentComp.isDirty
        : false);
    this.isDirtyChanged.emit();
  }
}
