<button type = "button" mat-icon-button class = "mc-dialog-floating-close-button" tabindex = "-1" (click) = "closeDialog()">
  <mat-icon fontSet = "project-titan" fontIcon = "icon-close"></mat-icon>
</button>

<mc-switch-board color = "secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf = "site$ | async as site">
      <div class="mc-spring-board-header">
        <mc-site-avatar [site]="site" [disabled] = "true"></mc-site-avatar>
      </div>
      <div class="mc-spring-board-body">
        <a [href] = "site.LiveOutputUrl || site.PrivateOutputUrl" class="text-light-weight mc-site-profile-dialog-url" target="_blank">{{site.LiveOutputUrl || site.PrivateOutputUrl}}</a>
        <ul class="mc-property-list">
          <li *ngIf = "site.BuildId || site.LastCompletedBuildId" class="mc-property-list-item-truncate">
            <span class="mc-property-name"> Build: </span>
            <mc-build-avatar class="mc-property-value" [build]="(site.BuildId || site.LastCompletedBuildId) | mcBuildAsync | async" [latest]="!!site.LastCompletedBuildId" [showAvatar]="false" [truncate]="true"></mc-build-avatar>
          </li>
          <li *ngIf = "site.LiveOutputUrl">
            <span class="mc-property-name">Live Date: </span>
            <span class="mc-property-value">
              {{ site.SetLiveOn | date: 'MMM dd, yyyy h:mm a' }}
            </span>
          </li>
        </ul>
      </div>
    </ng-container>
    <div class="mc-spring-board-body">
      <!-- Empty to fill the space between the header and footer -->
    </div>
    <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
      <a mat-list-item mcPreventDefault="click"  [class.mc-list-item-selected]="siteProfileTab === SiteProfileTab.Settings" (click)="siteProfileTab = SiteProfileTab.Settings">
        <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-gear" color="primary"></mat-icon> Settings
      </a>
      <a mat-list-item mcPreventDefault="click"  [class.mc-list-item-selected]="siteProfileTab === SiteProfileTab.Teams" (click)="siteProfileTab = SiteProfileTab.Teams">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-team'" color="primary"></mat-icon> Teams
      </a>
      <a mat-list-item mcPreventDefault="click"  [class.mc-list-item-selected]="siteProfileTab === SiteProfileTab.Delete" (click)="siteProfileTab = SiteProfileTab.Delete">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'" color="primary"></mat-icon> Delete
      </a>
    </mat-nav-list>
  </mc-switch-board-bar>
  <mc-switch-board-content class="mc-spring-board" [ngSwitch]="siteProfileTab">
    <mat-tab-group *ngSwitchCase="SiteProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-site-settings-form *ngIf="site$ | async as site" #settingsForm [licenseId]="site.LicenseId" [site]="site" [liveSiteUrl]="liveSiteUrl$ | async" (cancel)="closeDialog()" (saved)="onSaved()"></mc-site-settings-form>
      </mat-tab>
    </mat-tab-group>
    <mat-tab-group *ngSwitchCase="SiteProfileTab.Teams" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Teams">
        <mc-site-teams-form #teamsForm class="mc-switch-board-header-slim mc-switch-board-body-slim" [site]="site$ | async" (saved)="onSaved()"></mc-site-teams-form>
      </mat-tab>
    </mat-tab-group>
    <mat-tab-group *ngSwitchCase="SiteProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete">
        <mc-site-deletion-form [site]="site$ | async" (cancel)="closeDialog()" (deleted)="closeDialog(true)"></mc-site-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ |  async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError] = "generalError" [detailedErrors] = "loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>