export enum UserProfileTab {
  Settings,
  Security,
  Access,
  Activity,
  Permissions,
  Notifications,
  Deactivate,
  Delete,
  ResetPassword
}
