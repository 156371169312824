import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseBulkAddUserResponse } from '@portal-core/license-users/models/license-bulk-add-user-response.model';
import { LicenseUserClientSideInfo } from '@portal-core/license-users/models/license-user-client-side-info.model';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { ProcessReport } from '@portal-core/processes/models/process-report.model';
import { BulkUploadUserRequest } from '@portal-core/users/models/bulk-upload-user-request.model';
import { User } from '@portal-core/users/models/user.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseUsersApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getLicenseUserById$(licenseUserId: number): Observable<LicenseUser> {
    return this.http.get<LicenseUser>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${licenseUserId}`);
  }

  @SharePendingRequest()
  getLicenseUserByUserId$(userId: string, licenseId: number): Observable<LicenseUser> {
    return this.http.get<LicenseUser>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/License/${licenseId}/User/${userId}`);
  }

  @SharePendingRequest()
  getLicenseUsersById$(licenseUserIds: number[]): Observable<LicenseUser[]> {
    return this.http.post<LicenseUser[]>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/getBatchLicenseUsersByIds`, licenseUserIds);
  }

  @SharePendingRequest()
  getLicenseUsersPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<LicenseUser>> {
    return this.http.post<Page<LicenseUser>>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${licenseId}/getLicenseUsersList`, filter);
  }

  @SharePendingRequest()
  getAllLicenseUsersByLicenseId$(licenseId: number, getViewers: boolean): Observable<LicenseUser[]> {
    return this.http.get<LicenseUser[]>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/users?getViewers=${getViewers}`);
  }

  @SharePendingRequest()
  getLicenseUsersByUserIds$(licenseId: number, userIds: string[]) {
    return this.http.post<LicenseUser[]>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/License/${licenseId}/UserList`, userIds);
  }

  @SharePendingRequest()
  getLicenseUsersAdministrations$(licenseId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/userAdministrations`);
  }

  @SharePendingRequest()
  getBulkImportProcessReport$(processId: number): Observable<ProcessReport> {
    return this.http.get<ProcessReport>(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${processId}/BulkImportStatus`);
  }

  removeUserFromLicense$(licenseId: number, userId: string): Observable<any> {
    return this.http.delete(`${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/user/${userId}`);
  }

  updateLicenseUser$(licenseId: number, userId: string, isActive: boolean, updateLicenseModel = null): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/User/${userId}?isActive=${isActive}`, updateLicenseModel);
  }

  reinviteUser$(licenseId: number, userId: string): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/User/${userId}/reinvite`, {});
  }

  addUsersToLicense$(licenseId: number, seatType: LicenseUserSeatType, bulkUploadUserRequest: BulkUploadUserRequest, siteId?: number): Observable<LicenseBulkAddUserResponse> {
    const siteIdParam = siteId ? `&siteId=${siteId}` : '';

    return this.http.post<LicenseBulkAddUserResponse>(
      `${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/addUsers?seatType=${seatType}${siteIdParam}`, bulkUploadUserRequest
    );
  }

  bulkDeleteLicenseUsers$(licenseId: number, licenseUserIds: number[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${licenseId}/BulkDeleteLicenseUsers`, licenseUserIds);
  }

  bulkSetLicenseUsersSeatType$(licenseId: number, seatType: LicenseUserSeatType, licenseUserIds: number[]): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${licenseId}/BulkSetLicenseUsersSeatType/${seatType}`, licenseUserIds);
  }

  bulkSetLicenseUsersStatus$(licenseId: number, status: LicenseUserStatus, licenseUserIds: number[]): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/LicenseUsers/${licenseId}/BulkSetLicenseUsersStatus/${status}`, licenseUserIds);
  }

  @SharePendingRequest()
  getClientSideSettings$(licenseId: number, key: LicenseUserClientSideInfoKey): Observable<MadCloudResult<LicenseUserClientSideInfo>> {
    return this.http.get<MadCloudResult<LicenseUserClientSideInfo>>(`${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/getClientSideSettings/${key}`);
  }

  saveClientSideSettings$(licenseId: number, key: LicenseUserClientSideInfoKey, settings: any): Observable<MadCloudResult> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const settingsAsStr = !settings ? settings : `=${JSON.stringify(settings)}`;
    return this.http.post<any>(
      `${this.apiService.centralApiBaseUri}/api/licenseusers/${licenseId}/saveClientSideSettings/${key}`,
      settingsAsStr,
      { headers });
  }
}
