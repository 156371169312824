import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewPackageAvatarTooltipPipe } from './review-package-avatar-tooltip.pipe';

@NgModule({
  declarations: [ReviewPackageAvatarTooltipPipe],
  imports: [CommonModule],
  exports: [ReviewPackageAvatarTooltipPipe]
})
export class ReviewPackageAvatarTooltipModule { }
