import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { isEnumValue } from '@common/util/enum';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ProjectProfile } from '@portal-core/profiles/models/project-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { ProjectProfileComponent, ProjectProfileForm } from '@portal-core/projects/components/project-profile/project-profile.component';
import { ProjectProfileTab } from '@portal-core/projects/enums/project-profile-tab.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, filter, first, switchMap } from 'rxjs';

export interface ProjectProfileDialogData {
  projectId: number;
  projectProfileTab?: ProjectProfileTab;
}

@Component({
  selector: 'mc-project-profile-dialog',
  templateUrl: './project-profile-dialog.component.html',
  styleUrls: ['./project-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '64rem',
    autoFocus: false
  };

  @ViewChild(ProjectProfileComponent, { static: true }) projectProfileComponent: ProjectProfileComponent;

  public get requirePromptOnClose(): boolean {
    return this.projectProfileComponent?.requirePromptOnClose;
  }

  loadingState: LoadingState<string> = new LoadingState<string>();
  projectProfile$: Observable<ProjectProfile>;
  projectProfileTab: ProjectProfileTab;
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProjectProfileDialogData,
    dialog: MatDialog,
    dialogRef: MatDialogRef<ProjectProfileDialogComponent>,
    private router: Router,
    private errorService: ErrorService,
    private profilesService: ProfilesService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);

    // Create an observable of the project profile. Start with reload$ so that the data can be reloaded as needed
    this.projectProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getProjectProfile$(this.data.projectId)),
      cache()
    );

    this.projectProfile$.pipe(first()).subscribe(() => {
      // Set the initial tab
      if (isEnumValue(ProjectProfileTab, this.data.projectProfileTab)) {
        this.projectProfileTab = this.data.projectProfileTab;
      } else {
        this.projectProfileTab = ProjectProfileTab.Overview;
      }

      this.loadingState.update(false);
      this.onLoaded();
    }, error => {
      this.loadingState.update(false, 'Unable to load the project\'s profile.', this.errorService.getErrorMessages(error))
    });

    // Subscribes to the navigation change events for closing the dialog when there is a route change.
    // This Dialog panel contains some links which redirect to other routes so we need to close the Dialog when the route changes.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.closeDialog(null, true);
    });
  }

  onSaved(projectProfileForm: ProjectProfileForm) {
    switch (projectProfileForm) {
      case ProjectProfileForm.Settings:
      case ProjectProfileForm.Access:
        this.reload();
        break;
      case ProjectProfileForm.Delete:
        this.closeDialog();
        break;
    }
  }

  onCancel() {
    this.closeDialog();
  }

  reload() {
    this.reload$.next();
  }
}
