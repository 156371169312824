import { Pipe, PipeTransform } from '@angular/core';
import { Branch } from '@portal-core/branches/models/branch.model';
import { BranchesService } from '@portal-core/branches/services/branches.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcBranchAsync'
})
export class BranchAsyncPipe implements PipeTransform {
  constructor(private branchesService: BranchesService) { }

  transform(branchId: number | null | undefined, allowApiRequest: boolean = false): Observable<Branch | null> {
    if (!branchId) {
      return of(null);
    }

    return this.branchesService.getItemById$(branchId, {
      allowApiRequest
    });
  }
}
