import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { TaskAssetDelete } from '@portal-core/tasks/models/task-asset-delete.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { TaskCommentsDialogService } from '@portal-core/tasks/services/task-comments-dialog.service';
import { map } from 'rxjs';

@Component({
  selector: 'mc-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentBoxComponent implements OnInit, AfterViewInit {

  @Input() reduceSize: boolean = false;
  @Input() comment: TaskComment;
  @Input() isNewComment: boolean = true;
  @Input() canEditAllTasks: boolean = true;
  @Input() isReplyText: boolean = false;
  @Input() checkIsDirty: boolean = false;
  @ViewChild('textArea', { static: true }) textArea: ElementRef;
  @Output() created: EventEmitter<any> = new EventEmitter();
  @Output() isDirtyChanged: EventEmitter<any> = new EventEmitter();
  isDirty: boolean;
  private commentCopy: string;

  constructor(
    private dialog: MatDialog,
    private taskCommentsDialogService: TaskCommentsDialogService,
    private fileSizeService: FileSizeService) { }

  ngOnInit(): void {
    if (this.checkIsDirty && this.comment.Id !== 0) {
      this.commentCopy = this.comment.Comment;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResizeTextArea();
      this.created.emit(this);
    });
  }

  setFocus() {
    this.textArea.nativeElement.focus();
  }

  onResizeTextArea() {
    if (this.isNewComment === false) {
      this.textArea.nativeElement.style.height = '';
      this.textArea.nativeElement.style.height = this.textArea.nativeElement.scrollHeight + 'px';
    }
  }

  onAssetDelete($event: TaskAssetDelete) {
    this.taskCommentsDialogService.deleteAsset($event.Asset);
    this.updateDirty();
  }

  onAddAttachmentsChanged(event: any) {
    let displayWarning = false;
    let totalSize = 0;
    this.taskCommentsDialogService.commentAssets$.pipe(map(commentAssets => commentAssets.filter(ca => ca.Asset.Id === 0).reduce((total, ca) => total + ca.Asset.SizeInBytes, 0)))
      .subscribe(total => totalSize = total);
    for (const file of event.target.files) {
      totalSize += file.size;
      // nothing larger than 15MB
      if (file.size >= MaxAttachmentSizeBytes || totalSize >= MaxAttachmentSizeBytes) {
        displayWarning = true;
      } else {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        this.taskCommentsDialogService.addAsset(this.comment, file, formData);
      }
    }

    // resetting input to allow for attaching the same file name
    event.currentTarget.value = null;

    this.updateDirty();

    if (displayWarning) {
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Warning File Size Limit',
          message: `Cannot attach files whose sum is larger than ${this.fileSizeService.format(MaxAttachmentSizeBytes, 0)}.`
        }
      });
    }
  }

  onInputChanged() {
    this.updateDirty();
  }

  private updateDirty() {
    this.isDirty = (this.commentCopy
      ? this.commentCopy !== this.comment.Comment
      : this.comment.Comment !== null && this.comment.Comment !== '');
    this.isDirtyChanged.emit();
  }
}
