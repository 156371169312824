<div class="mc-notification-source">
  <mc-license-user-avatar *ngIf="showUserAvatar()" [licenseUser]="invokerLicenseUser$ | async" [showName]="false" size="small"></mc-license-user-avatar>
  <mc-project-avatar *ngIf="showProjectAvatar()" [project]="project$ | async" [showName]="false" size="small"></mc-project-avatar>
  <mc-team-avatar *ngIf="showTeamAvatar()" [team]="team$ | async" [showName]="false" size="small"></mc-team-avatar>
  <mc-review-file-avatar *ngIf="showReviewAvatar()" [reviewFile]="reviewFile$ | async" [showName]="false" size="small"></mc-review-file-avatar>
  <mc-license-avatar *ngIf="showLicenseAvatar()" [license]="license$ | async" [showName]="false" size="small"></mc-license-avatar>
</div>

<div class="mc-notification-content">
  <mc-notification-message [notification]="notification"></mc-notification-message>
  <p class="mc-notification-datetime">
    <span [matTooltip]="notification.CreatedOn | mcDate: 'MMM D, YYYY h:mm a'">{{ notification.CreatedOn | mcDate: 'relative' }}</span>
    <span *ngIf="notificationPage">
      in <a [routerLink]="notificationPage.Route">{{ notificationPage.Text }}</a>
    </span>
  </p>
</div>