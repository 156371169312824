<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Delete <b>{{project?.Name}}?</b>
    </p>
    <p class="mc-form-text">
      Deleting a project will permanently remove it from the license. All access will be denied to it on this license, and data associated with it will be deleted - including any associated tasks. References to the project will be preserved in logs.
    </p>
    <p class="mc-form-text">
      Instead of deleting projects you might consider archiving them, which will preserve all data. All access will be denied to archived projects on this license. An archived project can be reactivated later if necessary.
    </p>
    <p class="mc-form-title">
      Type "<b>{{project?.Name}}</b>" in the text box below to delete the project.
    </p>
    <mat-form-field class="mc-form-field" color="primary-vivid">
      <input type="text" matInput name="confirm" #confirmInput placeholder="Confirm Delete">
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="button" mat-raised-button color="warn" [disabled]="confirmInput.value != project?.Name || (savingState.loading$ | async)" (click)="onDeleteClicked()">Delete</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Archive</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>