<mat-toolbar #toolbar class="mc-toolbar-large" color="basic" [ngClass]="{ 'mc-editor-toolbar-calculating-layout': calculatingLayout$ | async }">
  <ng-template ngFor let-item [ngForOf]="items$ | async">
    <ng-container [ngSwitch]="item.type">
      <div *ngSwitchCase="'button'" class="mc-disabled-button-tooltip" [matTooltip]="item.tooltip">
        <button mat-icon-button type="button" [disabled]="item.disabled" [ngClass]="{ 'mc-bg-active-button': item.active }" (click)="onItemClicked(item)">
          <mat-icon *ngIf="item.icon" fontSet="project-titan" [fontIcon]="item.icon"></mat-icon>
          <ng-container *ngIf="item.text">{{ item.text }}</ng-container>
        </button>
      </div>

      <ng-container *ngSwitchCase="'custom'">
        <ng-container *ngIf="item.template" [ngTemplateOutlet]="item.template" [ngTemplateOutletContext]="{ $implicit: item, dropdown: false }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'detector'">
        <span mcLifeCycle (afterViewInit)="onDetectorAfterViewInit()"></span>
      </ng-container>

      <mat-divider *ngSwitchCase="'divider'" [vertical]="true"></mat-divider>

      <ng-container *ngSwitchCase="'dropdown'">
        <button #menuTrigger="matMenuTrigger" mat-button type="button" [matMenuTriggerFor]="dropdownMenu" [matMenuTriggerData]="{ dropdown: item, trigger: menuTrigger }" [matMenuTriggerRestoreFocus]="false" [ngClass]="{ 'mc-icon-button-dropdown': (item.icon && !item.dropdownText && !item.text) }" class="button-dropdown mc-editor-toolbar-button-dropdown" (menuOpened)="onDropdownMenuOpened()">
          <mat-icon *ngIf="item.icon" class="text-large" fontSet="project-titan" [fontIcon]="item.icon"></mat-icon>
          <ng-container *ngIf="item.text">{{ item.text }}</ng-container>
          <span class="dropdown-arrow"></span>
        </button>
      </ng-container>

      <span *ngSwitchCase="'fill'" class="mc-fill-remaining-flex-space"></span>
    </ng-container>
  </ng-template>
</mat-toolbar>

<mat-menu #dropdownMenu="matMenu" [overlapTrigger]="false" class="mc-menu" (closed)="onDropdownMenuClosed($event)">
  <ng-template matMenuContent let-dropdown="dropdown" let-trigger="trigger">
    <div *ngFor="let item of dropdown.items" (keydown.escape)="onDropdownMenuEscapePressed($event, trigger)">
      <ng-container [ngSwitch]="item.type">
        <button *ngSwitchCase="'button'" mat-menu-item [disabled]="item.disabled" [ngClass]="{ 'mc-menu-item-selected': item.active }" (click)="onItemClicked(item)">
          <mat-icon *ngIf="item.icon" fontSet="project-titan" [fontIcon]="item.icon"></mat-icon>
          <span>{{ item.dropdownText || item.text }}</span>
        </button>

        <ng-container *ngSwitchCase="'custom'">
          <ng-container *ngIf="item.template" [ngTemplateOutlet]="item.template" [ngTemplateOutletContext]="{ $implicit: item, dropdown: true }"></ng-container>
        </ng-container>

        <mat-divider *ngSwitchCase="'divider'"></mat-divider>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>