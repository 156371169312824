export enum ChangeAttrName {
  Attribute = 'MadCap:attribute',
  CreatorCentralUserId = 'MadCap:creatorCentralUserId',
  ChangeContent = 'MadCap:changeContent',
  ChangeIds = 'MadCap:changes',
  ChangeList = 'MadCap:changeList',
  ChangeType = 'MadCap:changeType',
  Initials = 'MadCap:initials',
  Timestamp = 'MadCap:timestamp',
  UserName = 'MadCap:userName',
  Value = 'MadCap:value'
}
