export enum TranslationPackageStatus {
  New,
  Sent,
  InProgress,
  Uploading,
  Downloading,
  Committing,
  Received,
  Approved,
  Canceled
}
