import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Attachment } from '@portal-core/data/common/models/attachment.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextEditorApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  uploadImage$(fileId: number, image: FormData): Observable<Attachment> {
    return this.http.post<Attachment>(`${this.apiService.centralApiBaseUri}/api/Reviews/${fileId}/Attachment`, image);
  }

  @SharePendingRequest()
  getFlareSnippet$(snippetContentApiUri: string): Observable<string> {
    return this.http.get(snippetContentApiUri, { responseType: 'text' });
  }
}
