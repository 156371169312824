import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { propertyValue } from '@common/util/properties';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicenseActivationType } from '@portal-core/licenses/enums/license-activation-type.enum';
import { LicenseType } from '@portal-core/licenses/enums/license-type.enum';
import { LicenseSamlAuthenticationSettings } from '@portal-core/licenses/models/license-saml-authentication-settings.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AuditCategory } from '@portal-core/notifications/models/audit-category.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { LicenseProfile } from '@portal-core/profiles/models/license-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { WebhookChannel } from '@portal-core/slack/models/webhook-channel.model';
import { WebhookSubscriber } from '@portal-core/slack/models/webhook-subscriber.model';
import { SlackWebhookChannelsService } from '@portal-core/slack/services/slack-webhooks-channels.service';
import { SlackWebhooksService } from '@portal-core/slack/services/slack-webhooks.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { User } from '@portal-core/users/models/user.model';
import { LoadingState } from '@portal-core/util/loading-state';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, catchError, combineLatest, distinctUntilChanged, first, map, of, switchMap, tap } from 'rxjs';

export interface LicenseProfileOptions {
  showSettingsTab?: boolean | (() => boolean);
  showPurchasingAndBillingTabs?: boolean | (() => boolean);
  showSlackTab?: boolean | (() => boolean);
  showSecurityTab?: boolean | (() => boolean);
  showAssistAITab?: boolean | (() => boolean);
  showSsoTab?: boolean | (() => boolean);
}
export const MC_LICENSE_PROFILE_OPTIONS = new InjectionToken<LicenseProfileOptions>('LicenseProfileOptions');

export enum LicenseProfileTab {
  Billing,
  Overview,
  Purchasing,
  Security,
  Settings,
  Slack,
  OpenAI,
  SSO
}

export interface LicenseProfileDialogData {
  licenseId: number;
  licenseUserId: number;
  sysAdminUser?: User;
  licenseProfileTab?: LicenseProfileTab;
}

/*
 * Parent component housing and sending data to all setting components.
*/
@Component({
  selector: 'mc-license-profile-dialog',
  templateUrl: './license-profile-dialog.component.html',
  styleUrls: ['./license-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '96rem',
    height: '75rem'
  };

  LicenseProfileTab: typeof LicenseProfileTab = LicenseProfileTab;
  LicenseType: typeof LicenseType = LicenseType;

  auditCategoryTree$: Observable<AuditCategory[]>;
  channels$: Observable<WebhookChannel[]>;
  expirationDate$: Observable<Date>;
  licenseId: number;
  licenseProfile$: Observable<LicenseProfile>;
  licenseProfileTab: LicenseProfileTab;
  licenseStorage$: Observable<LicenseStorage>;
  licenseUser$: Observable<LicenseUser>;
  license$: Observable<License>;
  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  ssoSettings$: Observable<LicenseSamlAuthenticationSettings>;
  subscribers$: Observable<WebhookSubscriber[]>;
  subscriptionNotExpired: boolean = true;
  userCanManageServer$: Observable<boolean>;
  userCanManageSlack$: Observable<boolean>;
  userCanPurchase$: Observable<boolean>;
  userIsAuthor$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;
  showPurchasingAndBillingTabs$: Observable<boolean>;
  showSlackTab$: Observable<boolean>;
  showSecurityTab$: Observable<boolean>;
  showAssistAITab$: Observable<boolean>;
  showSsoTab$: Observable<boolean>;

  constructor(
    @Optional() @Inject(MC_LICENSE_PROFILE_OPTIONS) private licenseProfileOptions: LicenseProfileOptions,
    @Inject(MAT_DIALOG_DATA) private data: LicenseProfileDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<LicenseProfileDialogComponent>,
    private licensesService: LicensesService,
    private licenseUsersService: LicenseUsersService,
    private licenseStorageService: LicenseStorageService,
    private slackWebhooksService: SlackWebhooksService,
    private slackWebhookChannelsService: SlackWebhookChannelsService,
    private notificationsService: NotificationsService,
    private permissionsService: PermissionsService,
    private errorService: ErrorService,
    private profilesService: ProfilesService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);
    this.licenseId = this.data.licenseId;

    // Create an observable of the license profile. Start with reload$ so that the data can be reloaded as needed
    this.licenseProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getLicenseProfile$(this.data.licenseId)),
      cache()
    );

    this.ssoSettings$ = this.licenseProfile$.pipe(
      map(licenseProfile => licenseProfile?.LicenseSamlAuthenticationSettings)
    );

    // Create an observable for the license
    this.license$ = this.licenseProfile$.pipe(
      map(licenseProfile => licenseProfile?.License),
      tap(license => this.subscriptionNotExpired = license?.LicenseActivationStatus === LicenseActivationType.Active)
    );

    // Create an observable for the license expiration date
    this.expirationDate$ = this.licenseStorageService.getItemById$(this.licenseId).pipe(
      map(licenseStorage => {
        if (licenseStorage) {
          // If not date specified, assume it expires in a month
          return licenseStorage.CentralEndDate ? licenseStorage.CentralEndDate : dayjs(licenseStorage.CentralStartDate).add(1, 'month').toDate();
        } else {
          return null;
        }
      })
    );

    // Create an observable for the license user
    this.licenseUser$ = this.data.sysAdminUser ?
      // sys admin request
      this.licenseUsersService.buildSysAdminLicenseUser$(this.data.sysAdminUser, this.licenseId)
      // else user request
      : this.licenseUsersService.getItemById$(this.data.licenseUserId, { forceApiRequest: true });

    // Create an observable for the license user's seat type
    this.userIsAuthor$ = this.licenseUser$.pipe(
      map(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.Author),
      distinctUntilChanged()
    );

    // Create an observable for the slack subscribers BUT only for authors
    this.subscribers$ = this.userIsAuthor$.pipe(
      switchMap(userIsAuthor => {
        if (userIsAuthor) {
          return this.slackWebhooksService.getSubscribers$(this.licenseId, { forceApiRequest: true });
        } else {
          return of(null);
        }
      })
    );

    // Create an observable for the slack channels BUT only for authors
    this.channels$ = this.userIsAuthor$.pipe(
      switchMap(userIsAuthor => {
        if (userIsAuthor) {
          return this.slackWebhookChannelsService.getChannels$(this.licenseId, { forceApiRequest: true });
        } else {
          return of(null);
        }
      })
    );

    // Create an observable for notifications default categories
    this.auditCategoryTree$ = this.notificationsService.getAuditCategoryTree$().pipe(
      catchError((errorResponse: HttpErrorResponse) => this.handleError$(errorResponse))
    );

    // Create observables for the user's permissions
    this.userCanPurchase$ = this.permissionsService.licenseUserHasPermission$(this.licenseUser$, CentralPermissions.Purchasing);
    this.userCanManageServer$ = this.permissionsService.licenseUserHasPermission$(this.licenseUser$, CentralPermissions.ServerManagement);
    this.userCanManageSlack$ = this.permissionsService.licenseUserHasPermission$(this.licenseUser$, CentralPermissions.SlackIntegration);

    // Create an observable for the contact info which the purchasing and billing tabs need
    const contactInfo$ = combineLatest(
      this.userIsAuthor$,
      this.userCanPurchase$
    ).pipe(
      switchMap(([userIsAuthor, userCanPurchase]) => {
        if (userIsAuthor && userCanPurchase) {
          return this.licensesService.getContactInfo$(this.licenseId);
        } else {
          return of(null);
        }
      })
    );

    // The dialog is done loading after the license user, license, optionally the license contact info, and permissions have loaded
    combineLatest(this.userCanPurchase$, this.userCanManageServer$, this.userCanManageSlack$, this.licenseProfile$, this.licenseUser$, this.expirationDate$, contactInfo$).pipe(
      first()
    ).subscribe(([userCanPurchase, userCanManageServer, userCanManageSlack]) => {
      this.setLicenseTab(this.data.licenseProfileTab, userCanPurchase, userCanManageServer, userCanManageSlack);

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the license\'s profile.', this.errorService.getErrorMessages(error))
    });

    // Create observables for which tabs to show in the sidebar
    this.showSettingsTab$ = this.userCanManageServer$.pipe(
      map(userCanManageServer => userCanManageServer || propertyValue(this.licenseProfileOptions, 'showSettingsTab'))
    );
    this.showPurchasingAndBillingTabs$ = this.userCanPurchase$.pipe(
      map(userCanPurchase => userCanPurchase || propertyValue(this.licenseProfileOptions, 'showPurchasingAndBillingTabs'))
    );
    this.showSlackTab$ = this.userCanManageSlack$.pipe(
      map(userCanManageSlack => (this.subscriptionNotExpired && userCanManageSlack) || propertyValue(this.licenseProfileOptions, 'showSlackTab'))
    );
    this.showSecurityTab$ = this.userCanManageServer$.pipe(
      map(userCanManageServer => userCanManageServer || propertyValue(this.licenseProfileOptions, 'showSecurityTab'))
    );
    this.showSsoTab$ = this.userCanManageServer$.pipe(
      map(userCanManageServer => userCanManageServer || propertyValue(this.licenseProfileOptions, 'showSsoTab'))
    );
    this.showAssistAITab$ = this.userCanManageServer$.pipe(
      map(userCanManageServer => userCanManageServer || propertyValue(this.licenseProfileOptions, 'showAssistAITab'))
    );
  }

  onSecuritySaved() {
    this.reload();
  }

  onSettingsSaved() {
    this.reload();
  }

  onSSoSettingsSaved() {
    this.reload();
  }

  private handleError$(errorResponse: HttpErrorResponse): Observable<any> {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: 'Unexpected error',
        message: 'An error occurred either from pulling subscriptions from the user service or retrieving audit category trees',
        errors: this.errorService.getErrorMessages(errorResponse)
      }
    });

    return of(null);
  }

  private setLicenseTab(preferredLicenseProfileTab: LicenseProfileTab, userCanPurchase: boolean, userCanManageServer: boolean, userCanManageSlack: boolean) {
    if ((preferredLicenseProfileTab === LicenseProfileTab.Billing || preferredLicenseProfileTab === LicenseProfileTab.Purchasing) && userCanPurchase) {
      this.licenseProfileTab = preferredLicenseProfileTab;
    } else if ((preferredLicenseProfileTab === LicenseProfileTab.Security || preferredLicenseProfileTab === LicenseProfileTab.Settings || preferredLicenseProfileTab === LicenseProfileTab.SSO) && userCanManageServer) {
      this.licenseProfileTab = preferredLicenseProfileTab;
    } else if (preferredLicenseProfileTab === LicenseProfileTab.Slack && userCanManageSlack) {
      this.licenseProfileTab = preferredLicenseProfileTab;
    } else {
      this.licenseProfileTab = LicenseProfileTab.Overview;
    }
  }

  private reload() {
    this.reload$.next();
  }
}
