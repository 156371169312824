import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { MarkSpec } from 'prosemirror-model';

export class UnderlineSchemaPlugin extends SchemaPlugin {
  marks: Dictionary<MarkSpec> = {
    u: {
      parseDOM: [{ tag: 'u' }],
      toDOM() { return ['u', 0]; }
    }
  };
}
