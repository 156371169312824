import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mc-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionSelectComponent implements OnInit, OnChanges {
  regions: any[] = [];
  @Input() countryCode: string;
  @Input() isRequired: boolean;
  @Input() initialValue: string;
  @Input() regionSelect: UntypedFormControl;
  @Output() regionSelected: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    this.loadRegions();
    if (!this.regionSelect) {
      const isInitialValueValid = this.initialValue && this.regions.some(region => region.code === this.initialValue);
      this.regionSelect = new UntypedFormControl(isInitialValueValid ? this.initialValue : '', [Validators.required]);
    }
  }

  onChange(newValue) {
    this.regionSelected.emit(newValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryCode) {
      this.loadRegions();
    }
  }

  loadRegions() {
    if (this.countryCode === 'US') {
      this.regions = [
        { 'code': 'AL', 'name': 'Alabama' },
        { 'code': 'AK', 'name': 'Alaska' },
        { 'code': 'AZ', 'name': 'Arizona' },
        { 'code': 'AR', 'name': 'Arkansas' },
        { 'code': 'CA', 'name': 'California' },
        { 'code': 'CO', 'name': 'Colorado' },
        { 'code': 'CT', 'name': 'Connecticut' },
        { 'code': 'DE', 'name': 'Delaware' },
        { 'code': 'DC', 'name': 'District of Columbia' },
        { 'code': 'FL', 'name': 'Florida' },
        { 'code': 'GA', 'name': 'Georgia' },
        { 'code': 'HI', 'name': 'Hawaii' },
        { 'code': 'ID', 'name': 'Idaho' },
        { 'code': 'IL', 'name': 'Illinois' },
        { 'code': 'IN', 'name': 'Indiana' },
        { 'code': 'IA', 'name': 'Iowa' },
        { 'code': 'KS', 'name': 'Kansas' },
        { 'code': 'KY', 'name': 'Kentucky' },
        { 'code': 'LA', 'name': 'Louisiana' },
        { 'code': 'ME', 'name': 'Maine' },
        { 'code': 'MD', 'name': 'Maryland' },
        { 'code': 'MA', 'name': 'Massachusetts' },
        { 'code': 'MI', 'name': 'Michigan' },
        { 'code': 'MN', 'name': 'Minnesota' },
        { 'code': 'MS', 'name': 'Mississippi' },
        { 'code': 'MO', 'name': 'Missouri' },
        { 'code': 'MT', 'name': 'Montana' },
        { 'code': 'NE', 'name': 'Nebraska' },
        { 'code': 'NV', 'name': 'Nevada' },
        { 'code': 'NH', 'name': 'New Hampshire' },
        { 'code': 'NJ', 'name': 'New Jersey' },
        { 'code': 'NM', 'name': 'New Mexico' },
        { 'code': 'NY', 'name': 'New York' },
        { 'code': 'NC', 'name': 'North Carolina' },
        { 'code': 'ND', 'name': 'North Dakota' },
        { 'code': 'OH', 'name': 'Ohio' },
        { 'code': 'OK', 'name': 'Oklahoma' },
        { 'code': 'OR', 'name': 'Oregon' },
        { 'code': 'PA', 'name': 'Pennsylvania' },
        { 'code': 'RI', 'name': 'Rhode Island' },
        { 'code': 'SC', 'name': 'South Carolina' },
        { 'code': 'SD', 'name': 'South Dakota' },
        { 'code': 'TN', 'name': 'Tennessee' },
        { 'code': 'TX', 'name': 'Texas' },
        { 'code': 'UT', 'name': 'Utah' },
        { 'code': 'VT', 'name': 'Vermont' },
        { 'code': 'VA', 'name': 'Virginia' },
        { 'code': 'WA', 'name': 'Washington' },
        { 'code': 'WV', 'name': 'West Virginia' },
        { 'code': 'WI', 'name': 'Wisconsin' },
        { 'code': 'WY', 'name': 'Wyoming' },
      ];
    } else if (this.countryCode === 'CA') {
      this.regions = [
        { 'code': 'AB', 'name': 'Alberta' },
        { 'code': 'BC', 'name': 'British Columbia' },
        { 'code': 'MB', 'name': 'Manitoba' },
        { 'code': 'NB', 'name': 'New Brunswick' },
        { 'code': 'NL', 'name': 'Newfoundland and Labrador' },
        { 'code': 'NS', 'name': 'Nova Scotia' },
        { 'code': 'NT', 'name': 'Northern Territories' },
        { 'code': 'NN', 'name': 'Nunavit' },
        { 'code': 'ON', 'name': 'Ontario' },
        { 'code': 'PE', 'name': 'Prince Edward Island' },
        { 'code': 'QC', 'name': 'Quebec' },
        { 'code': 'SK', 'name': 'Saskatchewan' },
        { 'code': 'YU', 'name': 'Yukon Territory' },
      ];
    } else {
      this.regions = [];
    }
  }

  getEmptyMessage(): string {
    return 'Mandatory field';
  }

  validate() {
    this.regionSelect.markAsTouched();
  }
}
