import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { CommitAvatarModule } from '@portal-core/commits/components/commit-avatar/commit-avatar.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { HasPermissionModule } from '@portal-core/permissions/pipes/has-permission/has-permission.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { ReviewFileUserStatusColorModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-color/review-file-user-status-color.module';
import { ReviewFileUserStatusIconModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-icon/review-file-user-status-icon.module';
import { ReviewFileUserStatusLicenseUserTooltipClassModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-license-user-tooltip-class/review-file-user-status-license-user-tooltip-class.module';
import { ReviewFileUserStatusLicenseUserTooltipModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-license-user-tooltip/review-file-user-status-license-user-tooltip.module';
import { ReviewFileUserStatusTextModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-text/review-file-user-status-text.module';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { ReviewFileProfileDialogModule } from '@portal-core/reviews/review-files/components/review-file-profile-dialog/review-file-profile-dialog.module';
import { ReviewFileStatusTextModule } from '@portal-core/reviews/review-files/pipes/review-file-status-text/review-file-status-text.module';
import { ReviewPackageAvatarModule } from '@portal-core/reviews/review-packages/components/review-package-avatar/review-package-avatar.module';
import { ReviewPackageAsyncModule } from '@portal-core/reviews/review-packages/pipes/review-package-async/review-package-async.module';
import { GridModule } from '@portal-core/ui/grid/grid.module';
import { AvatarsListModule } from '@portal-core/ui/list/components/avatars-list/avatars-list.module';
import { ListItemModule } from '@portal-core/ui/list/directives/list-item/list-item.module';
import { ReviewFilesGridComponent } from './review-files-grid.component';

@NgModule({
  declarations: [ReviewFilesGridComponent],
  imports: [
    BranchAvatarModule,
    CommitAvatarModule,
    CommonModule,
    GridModule,
    HasPermissionModule,
    MatMenuModule,
    ProjectAvatarModule,
    ReviewFileAvatarModule,
    AvatarsListModule,
    ListItemModule,
    ReviewPackageAvatarModule,
    ReviewPackageAsyncModule,
    ProjectAsyncModule,
    LicenseUserForLicenseAsyncModule,
    LicenseUserAvatarModule,
    MatTooltipModule,
    MatIconModule,
    ReviewFileProfileDialogModule,
    ReviewFileUserStatusColorModule,
    ReviewFileUserStatusIconModule,
    ReviewFileUserStatusLicenseUserTooltipModule,
    ReviewFileUserStatusLicenseUserTooltipClassModule,
    ReviewFileUserStatusTextModule,
    ReviewFileStatusTextModule
  ],
  exports: [ReviewFilesGridComponent]
})
export class ReviewFilesGridModule { }
