import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoughnutChartHoleDirective } from './doughnut-chart-hole.directive';

@NgModule({
  declarations: [
    DoughnutChartHoleDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DoughnutChartHoleDirective
  ]
})
export class DoughnutChartHoleModule { }
