<form class="mc-spring-board" [formGroup]="linkForm" (ngSubmit)="onSubmit()">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{data.link ? 'Edit' : 'Insert'}} Link
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" type="button" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Text</mat-label>
      <input matInput type="text" formControlName="text" placeholder="Text">
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Link</mat-label>
      <input matInput type="text" formControlName="link" placeholder="Link">
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!linkForm.valid">Save</button>
    </div>
  </div>
</form>