<div class="mc-avatar-tooltip-container" [matTooltip]="site?.Name" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !site">
  <button *ngIf="showAvatar" type="button" class="mc-avatar-container mc-text-avatar-invert" [disabled]="isImageDisabled()" (click)="onAvatarClicked()">
    <svg class="mc-avatar-svg mc-avatar-svg-active-fill" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path class="mc-site-avatar-svg-bg mc-fill-avatar-invert" d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
      <path fill-rule="evenodd" d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
      <path fill-rule="evenodd" d="M15 6H1V5h14v1z"/>
      <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
    </svg>

    <span class="mc-avatar-initials">
      <ng-container *ngIf="site?.Name">
        {{ site.Name | slice : 0 : 3 | uppercase }}
      </ng-container>
    </span>
  </button>

  <mc-inline-button *ngIf="showName" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">
    {{ site?.Name }}
  </mc-inline-button>
</div>
