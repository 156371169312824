import { normalizeNodeSelectionToParentNode } from '@common/prosemirror/state/transaction';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';

export const SelectionNormalizerPluginKey: PluginKey = new PluginKey('selection-normalizer');

export interface SelectionNormalizerPluginOptions {
  selectParentForNodeNames: string[]
}

/**
 * Creates a ProseMirror plugin that normalizes the selection to the parent node for the configured node names.
 * @param options The plugin options to configure the node names to normalize the selection for.
 * @returns The ProseMirror plugin.
 */
export function selectionNormalizerPlugin(options: SelectionNormalizerPluginOptions): Plugin {
  return new Plugin({
    key: SelectionNormalizerPluginKey,

    appendTransaction(transactions: Transaction[], oldState: EditorState, newState: EditorState): Transaction {
      const tr = normalizeNodeSelectionToParentNode(newState.tr, options.selectParentForNodeNames);
      if (tr.selectionSet) {
        return tr;
      }
    }
  });
}
