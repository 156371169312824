import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrentService } from '@portal-core/current/services/current.service';
import { Project } from '@portal-core/projects/models/project.model';
import { RoutingService } from '@portal-core/routing/services/routing.service';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-branch-avatar',
  templateUrl: './branch-avatar.component.html',
  styleUrls: ['./branch-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class BranchAvatarComponent extends AvatarBase implements OnInit {
  @Input() branchName: string;
  @Input() project?: Project;

  currentUserIsAuthor: boolean = false;
  private isAuthorSubscription: Subscription;

  get routeCommands(): any[] {
    if (this.project && this.branchName) {
      const path = this.routingService.getBranchPath(this.project.LicenseId, this.project.Id, this.branchName);
      return typeof path === 'undefined' ? ['/', this.project.LicenseId, 'projects', this.project.Id, 'files', 'home', this.branchName] : path;
    }
  }

  constructor(private cdr: ChangeDetectorRef, private currentService: CurrentService, private routingService: RoutingService) {
    super();
  }

  ngOnInit() {
    this.isAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      this.currentUserIsAuthor = isAuthor;
      this.cdr.markForCheck();
    });
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return this.currentUserIsAuthor;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.branchName;
  }

  isLink(): boolean {
    return super.isLink() && !!this.routeCommands;
  }
}
