import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { NgxMatColorPickerInputEvent } from '@portal-core/ui/color-picker/components/color-picker/color-input.component';

@Component({
  selector: 'mc-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MCColorPickerComponent {
  @Input() color: ThemePalette;
  @Input() placeholder: string;
  @Input() touchUi: boolean = false;
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() showRGBA: boolean = false;
  @Input() readonly?: boolean = false;

  /** The material form field appearance. */
  @Input() appearance: string = '';
  /** Form control validation type. */
  @Input() validationType?: string = 'mc_color_picker';
  /** Enables or disables tooltip for label. */
  @Input() enableLabelTooltip?: boolean = false;

  /** Text of the input.
   * Setting this property allows to use its value instead of the 'value' property.
   * Sometimes it's necessary to use raw input text because the 'value' property provides only valid Color instance.
   * WARNING: Once the property is set, changing the 'value' property won't change the input text.
   */
  @Input() text?: string;

  /** Emits when an `input` event is fired on this `<input>`. */
  @Output() readonly colorInput: EventEmitter<NgxMatColorPickerInputEvent> = new EventEmitter<NgxMatColorPickerInputEvent>();
}




