import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserForLicenseAsyncModule } from '@portal-core/license-users/pipes/license-user-for-license-async/license-user-for-license-async.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { ReviewPackageUserListComponent } from './review-package-user-list.component';

@NgModule({
  declarations: [ReviewPackageUserListComponent],
  exports: [ReviewPackageUserListComponent],
  imports: [
    CommonModule,
    LicenseUserAvatarModule,
    LicenseUserForLicenseAsyncModule,
    ListModule,
    MatListModule
  ]
})
export class ReviewPackageUserListModule { }
