import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuildLog } from '@portal-core/build-logs/models/build-log.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ProcessLogMessageType } from '@portal-core/processes/enums/process-log-message-type.enum';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class BuildLogsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getBuildLogsPageByMessageType$(buildId: number, logMessageType: ProcessLogMessageType, filter: PageFilter): Observable<Page<ProcessNotification>> {
    return this.http.post<Page<ProcessNotification>>(`${this.apiService.centralApiBaseUri}/api/BuildNotification/${buildId}/logMessages?logMessageType=${logMessageType}`, filter);
  }

  @SharePendingRequest()
  getBuildLog$(buildId: number): Observable<BuildLog> {
    return this.http.get<BuildLog>(`${this.apiService.centralApiBaseUri}/api/BuildNotification/BuildLog/${buildId}`);
  }
}
