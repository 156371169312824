import { FlareMadCapXMLNSName, FlareMadCapXMLNSValue } from '@common/flare/constants/flare-madcap-xmlns.constant';
import { FlareSchema } from '@common/flare/flare-schema';
import { DOMSerializer, Fragment } from 'prosemirror-model';

/**
 * A Prosemirror DOM serializer that uses the Flare schema to serialize the document using toFlareXML.
 * This is designed only to be used as a clipboard serializer which is used when putting content onto the clipboard.
 * ProseMirror creates an HTML document in the browser so we have to do the same when running in the browser.
 * That means not using SimpleDom and using text/html as the parser.
 * As described in the Prosemirror documentation, the clipboard serializer only needs to define the serializeFragment method.
 * https://prosemirror.net/docs/ref/#view.EditorProps.clipboardSerializer
 */
export class ProsemirrorFlareXMLSerializer extends DOMSerializer {
  constructor(public schema: FlareSchema) {
    super(null, null);
  }

  /**
   * Serializes the content of a Prosemirror fragment to a DOM fragment.
   * When not in the browser, the document option, containing a DOM document, should be passed so that the serializer can create nodes.
   * @param fragment The fragment to serialize.
   * @param options The options to use when serializing the fragment.
   * @returns The serialized DOM fragment.
   */
  serializeFragment(fragment: Fragment, options: { document?: Document } = {}): HTMLElement | DocumentFragment {
    // Use the document passed in falling back to the window.document if available
    let doc: Document;
    if (options?.document) {
      doc = options.document;
    } else if (typeof window !== 'undefined') {
      doc = window.document;
    }

    // We want to use an XML document so that tag and attribute name casing is preserved. So create an XMLDocument if the document provided is not an XMLDocument.
    if (doc.contentType !== 'text/xml') {
      doc = doc.implementation.createDocument(null, 'html', null);
    }

    // Making a DocumentFragment requires creating a temporary template element and setting its content equal to the fragment's XML
    // And then appending the template's child nodes to a document fragment
    // A template is used because minimal processing is done on its content

    // Serialized the ProseMirror fragment to XML and put it into a template node
    const template = doc.createElement('template');
    // Set the MadCap XML namespace on the template element so that it recognizes MadCap prefixed attributes
    template.setAttributeNS('http://www.w3.org/2000/xmlns/', FlareMadCapXMLNSName, FlareMadCapXMLNSValue);
    template.innerHTML = this.schema.fragmentToCode(fragment, { exportForClipboard: true });

    // Create a document fragment and append the template's child nodes to it
    const domFragment = doc.createDocumentFragment();
    domFragment.append(...template.childNodes);
    return domFragment;
  }
}
