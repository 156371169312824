import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslationPackageServicesDescriptions } from '@portal-core/translation-packages/enums/translation-package-services.enum';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
interface UnflattenedVariableSets {
  name: string;
  variableNamesStr: string;
}
@Component({
  selector: 'mc-translation-packages-summary',
  templateUrl: './translation-packages-summary.component.html',
  styleUrls: ['./translation-packages-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TranslationPackagesSummaryComponent implements OnChanges {

  @Input() translationPackage: TranslationPackage;
  @Input() disabledAvatars: boolean = false;
  translationPackageServicesDescriptions: typeof TranslationPackageServicesDescriptions = TranslationPackageServicesDescriptions;

  variableSets: UnflattenedVariableSets[];
  packageLanguageStr: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.translationPackage && this.translationPackage) {
      this.setVariableSets(this.translationPackage.FlattenedVariables);
    }
  }

  setVariableSets(flattenedVariables: string) {
    if (!flattenedVariables) {
      this.variableSets = [];
      return;
    };
    const flattenedVariablesArr = flattenedVariables.split(',');

    let currentSetName: string;
    const variableSets: UnflattenedVariableSets[] = [];

    flattenedVariablesArr.forEach((variable, index) => {
      const variableSplit = variable.split('.');

      if (currentSetName !== variableSplit[0]) {
        if (variableSets.length) {
          const variableNamesStr = variableSets[variableSets.length - 1].variableNamesStr;
          variableSets[variableSets.length - 1].variableNamesStr = variableNamesStr.substring(0, variableNamesStr.length - 2);
        }
        currentSetName = variableSplit[0];
        variableSets.push({ name: currentSetName, variableNamesStr: variableSplit[1] + ', ' });
      } else {
        variableSets[variableSets.length - 1].variableNamesStr += variableSplit[1] + ', ';
      }
    });

    if (variableSets.length) {
      const variableNamesStr = variableSets[variableSets.length - 1].variableNamesStr;
      variableSets[variableSets.length - 1].variableNamesStr = variableNamesStr.substring(0, variableNamesStr.length - 2);
    }

    this.variableSets = variableSets;

  }
}
