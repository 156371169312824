import { Component, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, Output, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { McModel } from '@portal-core/data/common/models/mc-model.model';
import { ListItemDirective } from '@portal-core/ui/list/directives/list-item/list-item.directive';

@Component({
  selector: 'mc-avatars-list',
  templateUrl: './avatars-list.component.html',
  styleUrls: ['./avatars-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarsListComponent implements OnChanges {
  @Input() avatars: McModel[];
  @Input() avatarSize?: 'x-small' | 'small' | 'normal' | 'large' = 'small';
  @Input() maxAvatars?: number = 5;
  @Input() tooltip?: string;

  @Output() viewAvatars: EventEmitter<void> = new EventEmitter();
  @ContentChild(ListItemDirective) listItemDirective: ListItemDirective;

  numAvatars: number;
  showMoreButton: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.avatars) {
      this.numAvatars = !this.avatars || this.avatars.length <= this.maxAvatars
        ? this.maxAvatars
        : this.maxAvatars - 1;
      this.showMoreButton = this.numAvatars === this.maxAvatars - 1;
    }
  }

  onMoreClicked() {
    this.viewAvatars.emit();
  }
}
